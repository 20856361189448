import React, { useEffect, useState } from 'react';
import { isSameDay, setHours, setMinutes } from 'date-fns';
import { Backdrop, Box, Dialog, Popover } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import classNames from 'classnames';
import Button from '../Forms/Button';
import { format } from '../../utils/date';
import Calendar from '../Calendar';
import styles from './AdminCancellationEdit.module.sass';
import ContentCard from '../ContentCard';
import { getPatientServiceCancellation } from '../../api/getPatientServiceCancellation';
import SelectInput from '../Forms/SelectInput';
import { getFieldErrors as getFieldErrorsUtil, validateFieldOnChange } from '../../utils';
import InputField from '../Forms/InputField';
import FormValidator from '../Forms/FormValidator';

interface PopsInterface {
  data?: AdminCancellationEditModel;
  loading?: boolean;
  onClose: () => void;
  onCancel: (model: any) => void;
}
export interface AdminCancellationEditModel {
  canceledAt?: Date;
  cancellationReasonDescription?: string;
  cancellationReasonType?: string;
  status?: string;
}
const AdminCancellationEdit = (props: PopsInterface) => {
  const { loading = false, onCancel = (model: any) => {}, onClose = () => {}, data = {} } = props;
  const [anchorCalendar, setOpenCalendar] = useState<HTMLButtonElement | null>(null);
  const isOpenCalendar = Boolean(anchorCalendar);
  const [anchorTime, setOpenTime] = useState<{ anchor: HTMLButtonElement; minutes?: boolean } | null>(null);
  const isOpenTime = Boolean(anchorTime);
  const [model, setModel] = useState<AdminCancellationEditModel>({ ...data, status: 'CANCELED' });
  const [options, setOptions] = useState<any>([]);
  const [formErrors, setFormErrors] = useState<any>([]);
  useEffect(() => {
    getPatientServiceCancellation()
      .then((data) => {
        const opt = (data?.data?.data?.items || []).map((opt) => ({ label: opt.name, value: opt.value }));
        setOptions(opt);
        !model?.cancellationReasonType &&
          setModel({
            ...model,
            cancellationReasonType: opt?.[0]?.value,
          });
      })
      .catch();
  }, []);
  const validateOnChange = (name: string, value: any, event, element?: any) => {
    validateFieldOnChange(name, value, event, model, setModel, formErrors, setFormErrors, element);
  };
  const getFieldErrors = (field: string) => getFieldErrorsUtil(field, formErrors);
  const onChangeDate = (data: Date): void => {
    const { canceledAt } = model;
    setModel({
      ...model,
      canceledAt: canceledAt ? setHours(setMinutes(data, canceledAt.getMinutes()), canceledAt.getHours()) : data,
    });
    setOpenCalendar(null);
  };
  const onChangeTime = (data: any): void => {
    const { canceledAt } = model;
    const canceledAtDate = canceledAt || new Date();
    canceledAtDate.setTime(data.getTime());
    setModel({
      ...model,
      canceledAt: canceledAtDate,
    });
  };
  const submit = (e) => {
    e.preventDefault();
    const form = e.target;
    const inputs = [...form.elements].filter((i) => ['INPUT', 'SELECT', 'TEXTAREA'].includes(i.nodeName));

    const { errors, hasError } = FormValidator.bulkValidate(inputs);

    setFormErrors([...errors]);

    if (!hasError) {
      onCancel(model);
    }
  };
  return (
    <Dialog
      PaperProps={{ className: classNames(styles.Dialog) }}
      BackdropComponent={(props) => <Backdrop className={styles.Backdrop} {...props} />}
      onClose={onClose}
      open
    >
      <ContentCard className={styles.AdminCancellationEdit}>
        <ContentCard.Header className={styles.AdminCancellationEdit_Header}>
          <h6 className="page-header-title">Причина отмены</h6>
          <CloseIcon onClick={onClose} className={styles.AdminCancellationEdit_CloseIcon} />
        </ContentCard.Header>
        <ContentCard.Main className={styles.AdminCancellationEdit_Content}>
          <form onSubmit={submit}>
            <Box width="100%" className="flex-column align-items-start justify-content-start d-flex p-3">
              <Box width="100%">
                <SelectInput
                  disabled={loading}
                  name="cancellationReasonType"
                  value={options.find((opt) => opt?.value === model.cancellationReasonType)}
                  onChange={(option, e) => {
                    validateOnChange('cancellationReasonType', option.value, e);
                  }}
                  errors={getFieldErrors('cancellationReasonType')}
                  data-validate='["required"]'
                  options={options}
                  block
                  label="Выберите причину отмены"
                  placeholder="Выберите причину отмены"
                />
              </Box>
              <Box mt={2} width="100%">
                <InputField
                  disabled={loading}
                  name="cancellationReasonDescription"
                  type="textarea"
                  value={model.cancellationReasonDescription}
                  onChange={(e) => validateOnChange('cancellationReasonDescription', e.target.value, e)}
                  block
                  placeholder="Дополнительная информация о причине отмены"
                  label="Описание отмены"
                />
              </Box>
            </Box>
            <Box width="100%" className="align-items-center justify-content-start d-flex p-3">
              <Box width="50%" className="align-items-center justify-content-start d-flex">
                <AccessTimeIcon className={styles.AdminCancellationEdit_AccessTimeIcon} />
                <Box
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                    !loading && setOpenCalendar(event.currentTarget);
                  }}
                  className={classNames(
                    styles.AdminCancellationEdit_Time,
                    styles.AdminCancellationEdit_Time_Toggle,
                    isSameDay(new Date(), model.canceledAt || new Date()) && styles.AdminCancellationEdit_Time_Current
                  )}
                >
                  {model.canceledAt ? format(model.canceledAt, 'd MMMM yyyy') : '-'}
                </Box>
                <Popover
                  PaperProps={{
                    className: classNames(
                      styles.AdminCancellationEdit_Popover,
                      styles.AdminCancellationEdit_Popover_Date
                    ),
                  }}
                  open={isOpenCalendar}
                  anchorEl={anchorCalendar}
                  onClose={() => setOpenCalendar(null)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <Box p={2}>
                    <Calendar maxDate={new Date()} onChangeDate={onChangeDate} date={model.canceledAt || null} />
                  </Box>
                </Popover>
              </Box>
              <Box className="align-items-center justify-content-start d-flex">
                <Box
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                    !loading && setOpenTime({ anchor: event.currentTarget });
                  }}
                  className={classNames(
                    styles.AdminCancellationEdit_Time,
                    styles.AdminCancellationEdit_Time_Toggle,
                    anchorTime && !anchorTime?.minutes && styles.AdminCancellationEdit_Time_Current
                  )}
                >
                  {model.canceledAt ? format(model.canceledAt, 'HH') : '-'}
                </Box>
                <Box className={styles.AdminCancellationEdit_Time}>:</Box>
                <Box
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                    !loading && setOpenTime({ anchor: event.currentTarget, minutes: true });
                  }}
                  className={classNames(
                    styles.AdminCancellationEdit_Time,
                    styles.AdminCancellationEdit_Time_Toggle,
                    anchorTime && anchorTime?.minutes && styles.AdminCancellationEdit_Time_Current
                  )}
                >
                  {model.canceledAt ? format(model.canceledAt, 'mm') : '-'}
                </Box>
                <Popover
                  PaperProps={{
                    className: classNames(
                      styles.AdminCancellationEdit_Popover,
                      styles.AdminCancellationEdit_Popover_Time
                    ),
                  }}
                  open={isOpenTime}
                  anchorEl={anchorTime && anchorTime.anchor}
                  onClose={() => setOpenTime(null)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <Box className="d-flex flex-column align-items-center justify-content-start p-2">
                    {Array.from(Array(anchorTime && anchorTime?.minutes ? 60 : 24).keys()).map((h: number) => (
                      <Box
                        key={h}
                        onClick={() => {
                          onChangeTime(
                            anchorTime?.minutes
                              ? setMinutes(model?.canceledAt || new Date(), h)
                              : setHours(model?.canceledAt || new Date(), h)
                          );
                          setOpenTime(null);
                        }}
                        className={classNames(
                          styles.AdminCancellationEdit_Popover_Time_SetTime,
                          anchorTime &&
                            model.canceledAt &&
                            (anchorTime?.minutes ? format(model.canceledAt, 'mm') : format(model.canceledAt, 'HH')) ===
                              h.toString().padStart(2, '0') &&
                            styles.AdminCancellationEdit_Popover_Time_SetTime_Active
                        )}
                      >
                        {h.toString().padStart(2, '0')}
                      </Box>
                    ))}
                  </Box>
                </Popover>
              </Box>
            </Box>
            <Box width="100%" className="d-flex justify-content-between align-items-center p-3">
              <Button type="button" block size="md" className="mr-1" onClick={onClose} color="default">
                Закрыть
              </Button>
              <Button
                type="submit"
                block
                size="md"
                className="ml-1"
                disabled={loading}
                isLoading={loading}
                onClick={() => onCancel(model)}
                color="primary"
              >
                Отменить
              </Button>
            </Box>
          </form>
        </ContentCard.Main>
      </ContentCard>
    </Dialog>
  );
};
export default AdminCancellationEdit;
