import { AxiosResponse } from 'axios';
import { findReportPatient } from '../../api/findReportPatient';
import {findReportCDSummary} from "../../api/findReportCDSummary";

export const MANAGE_REPORT_CD_SUMMARY_LOADING = 'MANAGE_REPORT_CD_SUMMARY_LOADING';
export const MANAGE_REPORT_CD_SUMMARY_SUCCESS = 'MANAGE_REPORT_CD_SUMMARY_SUCCESS';
export const MANAGE_REPORT_CD_SUMMARY_ERRORS = 'MANAGE_REPORT_CD_SUMMARY_ERRORS';

export const setManageReportCDSummaryLoading = (loading: boolean, reset?: boolean) => ({
  type: MANAGE_REPORT_CD_SUMMARY_LOADING,
  loading,
  reset,
});

export const setManageReportCDSummaryErrors = (errors: any) => ({ type: MANAGE_REPORT_CD_SUMMARY_ERRORS, errors });

export const setManageReportCDSummary = (items: any, page: number, pageSize: number, totalItems: number) => ({
  type: MANAGE_REPORT_CD_SUMMARY_SUCCESS,
  items,
  page,
  pageSize,
  totalItems,
});

export const fetchManageReportCDSummary = (
  page?: number,
  pageSize?: number,
  reset?: boolean
): any => {
  return (dispatch) => {
    dispatch(setManageReportCDSummaryLoading(true, reset));
    findReportCDSummary(page, pageSize)
      .then((response: AxiosResponse<any>) => {
        dispatch(setManageReportCDSummaryLoading(false));
        if (response.data.code === 'success') {
          dispatch(
            setManageReportCDSummary(
              response.data.data.items,
              response.data.data.page,
              response.data.data.pageSize,
              response.data.data.totalItems
            )
          );
        } else {
          dispatch(setManageReportCDSummaryErrors(response.data));
        }
      })
      .catch((error: any) => {
        dispatch(setManageReportCDSummaryErrors(error));
        dispatch(setManageReportCDSummaryLoading(false));
      });
  };
};
