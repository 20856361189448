import { AxiosResponse } from 'axios';
import Patient from '../../types/patient';
import { getMyPatientsInfo } from '../../api/getMyPatientsInfo';
import { findDoctorAppointments } from '../../api/findDoctorAppointments';
import DoctorAppointments from '../../types/doctorAppointments';
import { getPatientAppointments } from '../../api/getPatientAppointments';

export const DOCTOR_PATIENTS_LOADING = 'DOCTOR_PATIENTS_LOADING';
export const DOCTOR_PATIENTS_SUCCESS = 'DOCTOR_PATIENTS_SUCCESS';
export const DOCTOR_PATIENTS_ERRORS = 'DOCTOR_PATIENTS_ERRORS';
export const DOCTOR_PATIENT_APPOINTMENTS_LOADING = 'DOCTOR_PATIENT_APPOINTMENTS_LOADING';
export const DOCTOR_PATIENT_APPOINTMENTS_SUCCESS = 'DOCTOR_PATIENT_APPOINTMENTS_SUCCESS';
export const DOCTOR_PATIENT_APPOINTMENTS_ERRORS = 'DOCTOR_PATIENT_APPOINTMENTS_ERRORS';

export const setPatientsLoading = (loading: boolean, reset?: boolean) => ({
  type: DOCTOR_PATIENTS_LOADING,
  loading,
  reset,
});

export const setPatientsErrors = (errors: any) => ({ type: DOCTOR_PATIENTS_ERRORS, errors });

export const setPatients = (patients: Patient.Data[], page: number, pageSize: number, totalItems: number) => ({
  type: DOCTOR_PATIENTS_SUCCESS,
  patients,
  page,
  pageSize,
  totalItems,
});

export const setPatientAppointmentsLoading = (loading: boolean, reset?: boolean) => ({
  type: DOCTOR_PATIENT_APPOINTMENTS_LOADING,
  loading,
  reset,
});

export const setPatientAppointmentsErrors = (errors: any) => ({ type: DOCTOR_PATIENT_APPOINTMENTS_ERRORS, errors });

export const setPatientAppointments = (
  appointments: Patient.PatientAppointments.Item[],
  page: number,
  pageSize: number,
  totalItems: number
) => ({
  type: DOCTOR_PATIENT_APPOINTMENTS_SUCCESS,
  appointments,
  page,
  pageSize,
  totalItems,
});

export const fetchMyPatients = (query: string, page?: number, pageSize?: number, reset?: boolean): any => {
  return (dispatch) => {
    dispatch(setPatientsLoading(true, reset));
    getMyPatientsInfo(query, page, pageSize)
      .then((response: AxiosResponse<Patient.MyPatients.Response>) => {
        dispatch(setPatientsLoading(false));
        if (response.data.code === 'success') {
          dispatch(
            setPatients(
              response.data.data.items,
              response.data.data.page,
              response.data.data.pageSize,
              response.data.data.totalItems
            )
          );
        } else {
          dispatch(setPatientsErrors(response.data));
        }
      })
      .catch((error: any) => {
        dispatch(setPatientsErrors(error));
        dispatch(setPatientsLoading(false));
      });
  };
};

export const fetchPatientAppointments = (patientId: string, page?: number, pageSize?: number, reset?: boolean): any => {
  return (dispatch) => {
    dispatch(setPatientAppointmentsLoading(true, reset));
    getPatientAppointments(patientId, page, pageSize)
      .then((response: AxiosResponse<Patient.PatientAppointments.Response>) => {
        dispatch(setPatientAppointmentsLoading(false));
        if (response.data.code === 'success') {
          dispatch(
            setPatientAppointments(
              response.data.data.items,
              response.data.data.page,
              response.data.data.pageSize,
              response.data.data.totalItems
            )
          );
        } else {
          dispatch(setPatientAppointmentsErrors(response.data));
        }
      })
      .catch((errors: any) => {
        dispatch(setPatientAppointmentsLoading(false));
        dispatch(setPatientAppointmentsErrors(errors));
      });
  };
};
