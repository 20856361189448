import { format as _format } from 'date-fns';
import ru from 'date-fns/locale/ru';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { getMonthName } from './helpers';

export const PATTERN_TIME = 'HH:mm';

export const format = (date: number | Date, format: string): string => {
  return _format(date, format, { locale: ru });
};

export const isBeforeOrEquals = (first: Date, second: Date): boolean => {
  return first.getTime() <= second.getTime();
};

export const isSameDay = (first: Date, second: Date): boolean => {
  return (
    first.getDate() === second.getDate() &&
    first.getMonth() === second.getMonth() &&
    first.getFullYear() === second.getFullYear()
  );
};

export const isSameTime = (first: Date, second: Date): boolean => {
  return first.getHours() === second.getHours() && first.getMinutes() === second.getMinutes();
};

export const getFullDateFormat = (date: Date) => {
  return `${format(date, 'dd')} ${getMonthName(date.getMonth())} ${date.getFullYear()}, ${format(date, PATTERN_TIME)}`;
};

export interface DateTimeUnits {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

export const getSecondsSince = (date): number => {
  const first = new Date();
  const second = new Date(date);
  const diff = first.getTime() - second.getTime();
  return diff / 1000;
};

export const getTimeBetween = (first: Date, second: Date): DateTimeUnits => {
  const diff = first.getTime() - second.getTime();
  const days = parseInt(`${diff / (1000 * 60 * 60 * 24)}`);
  const hours = parseInt(`${(Math.abs(diff) / (1000 * 60 * 60)) % 24}`);
  const minutes = parseInt(`${(Math.abs(diff) / (1000 * 60)) % 60}`);
  const seconds = parseInt(`${(Math.abs(diff) / 1000) % 60}`);
  return {
    days,
    hours,
    minutes,
    seconds,
  };
};

export const datePlurals = {
  hours: ['час', 'часа', 'часов'],
  minutes: ['минута', 'минуты', 'минутов'],
};

export const toHHMMSS = (seconds: number) => {
  const time = new Date();
  // create Date object and set to today's date and time
  time.setHours((seconds / 3600) % 24);
  time.setMinutes((seconds / 60) % 60);
  time.setSeconds(seconds % 60);
  return time.toTimeString().split(' ')[0];
};
export const convertUtcToZonedTime = (date?: Date, timeZone?: string | 'UTC'): Date => {
  timeZone = timeZone || window?.Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || 'UTC';
  const isUTC = timeZone === 'UTC';
  const convertor = isUTC ? zonedTimeToUtc : utcToZonedTime;
  return convertor.call(null, date || new Date(), ...(isUTC ? [] : [timeZone]));
};
