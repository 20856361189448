import React, { useState } from 'react';
import { Backdrop, Box, Dialog } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import { AxiosResponse } from 'axios';
import styles from './AdminServicePackageView.module.sass';
import ContentCard from '../ContentCard';
import Button from '../Forms/Button';
import { updatePatientServicePackageStatus } from '../../api/updatePatientServicePackageStatus';
import Manage from '../../types/manage';
import { displayErrors, handleHttpError } from '../../utils/handleHttpError';

interface PropsInterface {
  name: string;
  packageId?: string;
  options?: { key: string; value: string | any; column?: boolean }[];
  onClose: (item: any) => void;
  onPurchased?: (servicePackageId?: any, status?: any) => void;
  isPurchased?: boolean;
  onClosed?: (servicePackageId?: any, status?: any) => void;
  isCanClosed?: boolean;
  onActivated?: (servicePackageId?: any, status?: any) => void;
  isCanActivated?: boolean;
  disabled?: boolean;
}

const AdminServicePackageView = (props: PropsInterface) => {
  const {
    onClose = () => {},
    options = [],
    name,
    disabled = false,
    onPurchased = null,
    packageId,
    isPurchased = false,
    onClosed = null,
    isCanClosed = false,
    onActivated = null,
    isCanActivated = false,
  } = props;
  const [statusChangingProgress, setStatusChangingProgress] = useState<boolean>(false);

  const onChangeStatus = (
    status: Manage.ServicePackages.Mfo.PatientServicePackageStatus,
    callback?: (servicePackageId?: any, status?: any) => void
  ) => {
    setStatusChangingProgress(true);
    updatePatientServicePackageStatus(packageId, status).then(
      (response: AxiosResponse<any>) => {
        if (response.data.code === 'success') {
          setStatusChangingProgress(false);
          callback && callback(packageId, status);
        } else {
          setStatusChangingProgress(false);
          displayErrors(handleHttpError(response));
        }
      },
      (error) => {
        setStatusChangingProgress(false);
        displayErrors(handleHttpError(error));
      }
    );
  };

  const purchased = (): void => {
    onChangeStatus('PURCHASED', onPurchased);
  };

  const closed = (): void => {
    onChangeStatus('CLOSED', onClosed);
  };

  const activate = (): void => {
    onChangeStatus('ACTIVATED', onActivated);
  };

  return (
    <Dialog
      PaperProps={{ className: classNames(styles.Dialog) }}
      BackdropComponent={(props) => <Backdrop className={styles.Backdrop} {...props} />}
      onClose={onClose}
      open
    >
      <ContentCard className={styles.AdminServicePackageView}>
        <ContentCard.Header className={styles.AdminServicePackageView_Header}>
          <h6 className="page-header-title">{name}</h6>
          <CloseIcon onClick={onClose} className={styles.AdminServicePackageView_CloseIcon} />
        </ContentCard.Header>
        <ContentCard.Main className={styles.AdminServicePackageView_Content}>
          {options.map((opt, index: number) => (
            <Box
              key={index}
              className={classNames(
                styles.AdminServicePackageView_Content_Item,
                opt.column && styles.AdminServicePackageView_Content_Item_Column
              )}
            >
              {opt.key && <Box className={classNames(styles.AdminServicePackageView_Content_Item_Key)}>{opt.key}</Box>}
              {opt.value && (
                <Box className={classNames(styles.AdminServicePackageView_Content_Item_Value)}>{opt.value}</Box>
              )}
            </Box>
          ))}
          {!disabled && isPurchased && (
            <>
              <Box p={2} className={classNames(styles.AdminServicePackageView_Content_Item)}>
                <Button block color="primary" isLoading={statusChangingProgress} onClick={() => purchased()}>
                  Продать
                </Button>
              </Box>
            </>
          )}
          {!disabled && isCanActivated && (
            <>
              <Box p={2} className={classNames(styles.AdminServicePackageView_Content_Item)}>
                <Button block color="primary" isLoading={statusChangingProgress} onClick={() => activate()}>
                  Активировать
                </Button>
              </Box>
            </>
          )}
          {!disabled && isCanClosed && (
            <>
              <Box p={2} className={classNames(styles.AdminServicePackageView_Content_Item)}>
                <Button block color="alert" isLoading={statusChangingProgress} onClick={() => closed()}>
                  Закрыть
                </Button>
              </Box>
            </>
          )}
        </ContentCard.Main>
      </ContentCard>
    </Dialog>
  );
};
export default AdminServicePackageView;
