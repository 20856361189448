import React from 'react';

import DropdownContent from 'components/DropdownMenu/DropdownContent';

import useFileUploader from 'components/hooks/useFileUploader';

import styles from './AvatarMenu.module.sass';

type Props = {
  employeeId: string;
  onLoad: (avatarId: string, file: File) => void;
  onRemove: () => void;
  onSelect: () => void;
  onFailed: () => void;
  beforeUpload: (data: any) => Promise<any>;
  croppedFile: File
};

export default ({
  employeeId,
  onLoad,
  onRemove,
  onSelect,
  onFailed,
  beforeUpload,
  croppedFile
}: Props) => {
  const [avatarFile, setFile] = React.useState<File>();
  const handleDrop = React.useCallback(
    ([droppedFile]: File[]) => {
      setFile(droppedFile);
      onSelect();
    },
    []
  );

  const { open, getInputProps, files } = useFileUploader({
    accept: 'image/*',
    multiple: false,
    onDropAccepted: handleDrop,
    fileName: `employee/${employeeId}`,
    beforeUpload
  });

  React.useEffect(
    () => {
      if (!files.length) {
        return;
      }

      const file = files[files.length - 1];

      if (file.isLoaded) {
        onLoad(file.id, avatarFile);
      }

      if (file.isFailed) {
        onFailed();
      }
    },
    [files]
  );

  React.useEffect(
    () => {
      if (croppedFile && croppedFile !== avatarFile) {
        setFile(croppedFile);
      }
    },
    [croppedFile]
  );

  return (
    <>
      <DropdownContent>
        <ul className={styles.employee_Avatar_EditMenu_List}>
          <li className={styles.employee_Avatar_EditMenu_List_Item}>
            <button
              className={styles.employee_Avatar_EditMenu_List_Item_Btn}
              type="button"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();

                open();
              }}
            >
              Редактировать
            </button>
          </li>

          <li className={styles.employee_Avatar_EditMenu_List_Item}>
            <button
              className={styles.employee_Avatar_EditMenu_List_Item_Btn}
              type="button"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();

                onRemove();
              }}
            >
              Удалить
            </button>
          </li>
        </ul>
      </DropdownContent>
      <input {...getInputProps()} />
    </>
  );
};
