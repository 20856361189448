import { createEffect } from 'effector';
import { toast } from 'react-toastify';
import { deleteStoriesApi, getStoriesApi, postStoriesApi, putSortStoriesApi, putStoriesApi } from '$api';
import { serializeStoryData } from '../../views/AdminStories/AdminStoriesSearch/helpers';

export const originGetStoriesFx = createEffect(async (): Promise<Array<any>> => {
  try {
    return await getStoriesApi().then((data) => data?.data?.data || []);
  } catch (e) {
    toast.error('Ошибка при запросе');
    throw new Error(e);
  }
});

export const originEditStoriesFx = createEffect(async (data): Promise<any> => {
  try {
    const { id, ...other } = data || {};
    const restData = serializeStoryData(other);
    return await (id ? putStoriesApi(id, restData) : postStoriesApi(restData)).then((data) => data?.data?.data);
  } catch (e) {
    toast.error('Ошибка при запросе');
    throw new Error(e);
  }
});
export const originDeleteStoriesFx = createEffect(async (id: any): Promise<any> => {
  try {
    return await deleteStoriesApi(id).then(() => id);
  } catch (e) {
    toast.error('Ошибка при запросе');
    throw new Error(e);
  }
});

export const originSortStoriesFx = createEffect(async (items: Array<any>): Promise<Array<any>> => {
  try {
    return await putSortStoriesApi(items.map((data: any) => ({ id: data?.id, weight: data?.weight || 0 }))).then(
      (data) => data?.data?.data || []
    );
  } catch (e) {
    toast.error('Ошибка при запросе');
    throw new Error(e);
  }
});

export const originToggleActiveStoriesFx = createEffect(async (item: any): Promise<any> => {
  try {
    return await putStoriesApi(item?.id, serializeStoryData(item)).then(() => item);
  } catch (e) {
    toast.error('Ошибка при запросе');
    throw new Error(e);
  }
});
