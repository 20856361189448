import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import classNames from 'classnames';
import { AxiosResponse } from 'axios';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import InputField from '../Forms/InputField';
import styles from './SearchPatients.module.sass';
import Patient from '../../types/patient';
import { decodeAvatarResource, getDisplayName } from '../../utils';
import { getPatientsAutocomplete } from '../../api/getPatientsAutocomplete';

interface SearchPatientsInterface {
  value: Patient.Data;
  placeholder?: string;
  onChange: (item: Patient.Data) => void;
}
const SearchPatients = (props: SearchPatientsInterface) => {
  const { value, placeholder, onChange } = props;
  const ref: MutableRefObject<any> = useRef();
  const [search, onChangeSearch] = useState('');
  const [data, setData] = useState<Patient.PatientAutocomplete.Data>({
    items: [],
    page: 0,
    pageSize: 50,
    totalItems: 0,
  });
  const [timeoutSearch, setTimeoutSearch] = useState<NodeJS.Timeout>(null);
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  useEffect(() => {
    if (timeoutSearch) {
      clearTimeout(timeoutSearch);
    }
    setTimeoutSearch(
      setTimeout(() => {
        search && search.length && onSearch();
      }, 500)
    );
    return () => {
      if (timeoutSearch) {
        clearTimeout(timeoutSearch);
      }
    };
  }, [search.length]);
  const onSearch = (): void => {
    getPatientsAutocomplete(search, data.page, data.pageSize)
      .then((response: AxiosResponse<Patient.PatientAutocomplete.Response>) => {
        if (response.data.code === 'success') {
          setData(response.data.data);
        }
      })
      .catch((err) => {});
  };
  const reset = (): void => {
    onChangeSearch('');
    setData({
      items: [],
      page: 0,
      pageSize: 50,
      totalItems: 0,
    });
  };
  const handleClickOutside = (event: any): void => {
    if (!event?.path?.includes(ref?.current)) {
      reset();
    }
  };
  return (
    <>
      <div className={styles.SearchPatients}>
        <SearchIcon className={styles.SearchPatients_Icon} />
        <InputField
          {...props}
          value={search}
          onChange={(e) => {
            onChangeSearch(e.target.value);
          }}
          block
          placeholder={value ? getDisplayName(value.firstName, value.lastName) : placeholder}
        />
        {value && (
          <ClearIcon
            onClick={() => {
              onChange(null);
              reset();
            }}
            className={classNames(styles.SearchPatients_Icon, styles.SearchPatients_Icon_Clear)}
          />
        )}
        {data.items.length > 0 && (
          <div ref={ref} className={classNames(styles.SearchPatients_Popover)}>
            <List className={classNames(styles.SearchPatients_Popover_List)}>
              {data.items &&
                data.items.map((item: Patient.PatientAutocomplete.Item, index: number) => (
                  <ListItem
                    key={index}
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      onChange(item);
                      reset();
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar src={decodeAvatarResource(item.avatar, 360, null)} />
                    </ListItemAvatar>
                    <ListItemText primary={getDisplayName(item.firstName, item.lastName)} />
                  </ListItem>
                ))}
            </List>
          </div>
        )}
      </div>
    </>
  );
};

export default SearchPatients;
