export const serializeStoryData = (item: any): any => ({
  name: item?.name,
  description: item?.description || '',
  previewId: item?.preview?.id,
  contentId: item?.content?.id,
  deeplinkWeb: item?.deeplinkWeb || '',
  deeplinkMobile: item?.deeplinkMobile || '',
  weight: item?.weight || 0,
  isActive: item?.active === true,
});
