import React from 'react';
import classNames from 'classnames';

import styles from './ContentCard.module.sass';

const ContentCardMain = (props: any) => (
  <div className={classNames(styles.contentCard_main, {
    [props.className]: props.className
  })}>
    {props.children}
  </div>
);

export default ContentCardMain;
