export enum AgentMetricsDateTypeType {
  NOW = 'NOW',
  TOMORROW = 'TOMORROW',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  CUSTOM = 'CUSTOM',
}
export type AgentDashboardData = {
  name: string;
  date: Date;
  count: number;
  price: number;
  sum: number;
};
export type Metrics<T> = {
  allCount: number;
  allSum: number;
  agentDashboardData: Array<T>;
};
