import { createStore, forward } from 'effector';
import { authCodesEsiaFx } from './effects';
import { onAuthCodesEsia } from './events';

export interface AuthStateInterface {}
const defaultState: AuthStateInterface = {};

const $store = createStore(defaultState);

forward({ from: onAuthCodesEsia, to: authCodesEsiaFx });

export default $store;
