import React, { useCallback, useMemo } from 'react';
import { Box } from '@material-ui/core';
import { onSetJournalEdit, onSetJournalReset } from 'views/JournalPage/store/events';
import { JournalTitle, JournalType, JournalUnitTypeTitle } from 'views/JournalPage/types';
import Button from 'components/Forms/Button';
import classNames from 'classnames';
import styles from './JournalView.module.sass';
import useRootSelector from '../../../hooks/useRootSelector';
import { selectJournal, selectJournalCurrent, selectJournalType, selectJournalTypeUnit } from '../store/selectors';
import JournalViewFilter from '../JournalViewFilter';
import { convertUtcToZonedTime, format } from '../../../utils/date';
import { getJournalColor } from '../helpers';
import HasRole from '../../../components/HasRole';
import { UserRole } from '../../../types';

const JournalView = (): JSX.Element => {
  const state = useRootSelector(selectJournal);
  const type = useRootSelector(selectJournalType);
  const unit = useRootSelector(selectJournalTypeUnit);
  const current = useRootSelector(selectJournalCurrent);
  const handleClick = useCallback(() => {
    onSetJournalReset({ ...state, loading: false });
    onSetJournalEdit();
  }, [state]);
  const isFeeling = useMemo(() => type === JournalType.Feeling, [type]);
  const title = useMemo(() => JournalTitle[type], [type]);
  const value = useMemo(() => {
    const currentValues = current.find(({ type: cType = null }) => cType === type)?.values || {};
    return unit.map((k) => (currentValues?.[k] || Number(currentValues?.[k]) === 0 ? currentValues?.[k] : '-'));
  }, [current, unit, type]);
  const date = useMemo(() => {
    const currentDate = current.find(({ type: cType = null }) => cType === type)?.values?.observationTime || null;
    return currentDate ? format(convertUtcToZonedTime(currentDate), 'dd.MM.yyyy HH:mm') : null;
  }, [current, type]);
  const renderValues = useCallback(() => {
    return (
      <>
        {value.map((v, i) => (
          <Box key={i} className={styles.root_last_data_values}>
            <Box className={classNames(styles.root_last_data_value)} style={{ color: getJournalColor(unit[i]) }}>
              {v}
            </Box>
            {value.length === 4 && (
              <Box mr={2} className={styles.root_last_data_unit} style={{ color: getJournalColor(unit[i]) }}>
                {JournalUnitTypeTitle[unit[i]]}
              </Box>
            )}
            {value.length !== i + 1 && value.length === 2 && (
              <Box className={styles.root_last_data_value_dot} style={{ color: getJournalColor(unit[i]) }}>
                /
              </Box>
            )}
          </Box>
        ))}
        {unit.length === 1 && (
          <Box className={styles.root_last_data_unit}>{unit.map((v) => JournalUnitTypeTitle[v]).join('/')}</Box>
        )}
      </>
    );
  }, [unit, value]);
  return (
    <>
      <Box className={styles.root}>
        <Box className={styles.root_header}>
          <Box className={styles.root_title}>{title}</Box>
          <JournalViewFilter />
        </Box>
        <Box className={styles.root_toolbar}>
          <Box className={styles.root_last}>
            {date && !isFeeling && (
              <>
                <Box className={styles.root_last_title}>Последний</Box>
                <Box className={classNames(styles.root_last_data, value?.length === 2 && styles.root_last_data_line)}>
                  {renderValues()}
                </Box>
                <Box className={styles.root_last_date}>{date}</Box>
              </>
            )}
          </Box>
          <HasRole target={UserRole.PATIENT}>
            <Button onClick={handleClick} size="sm" type="submit" color="primary">
              <Box className="d-flex align-items-center">
                <Box>Добавить</Box>
                <Box className="ml-2">+</Box>
              </Box>
            </Button>
          </HasRole>
        </Box>
      </Box>
    </>
  );
};

export default JournalView;
