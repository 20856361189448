import React from 'react';
import { useSelector } from 'react-redux';
import HasRole from 'components/HasRole/HasRole';
import { UserRole } from 'types';
import Logo from 'components/Logo';
import { Box } from '@material-ui/core';
import UserProfileBar from '../UserProfileBar';

import styles from './Header.module.sass';
import GoogleStoreLink from '../GoogleStoreLink';
import AppStoreLink from '../AppStoreLink';
import { INFO_MAIL } from '../../constants/common';

const Header = () => {
  const isAuthenticated = useSelector((state: any) => state.auth.isAuthenticated);
  return (
    <Box component="header" className={styles.root}>
      <Logo />
      <div className={styles.root_apps}>
        <HasRole target={UserRole.PATIENT}>
          <AppStoreLink className={styles.root_apps_item} />
          <GoogleStoreLink className={styles.root_apps_item} />
          <a className={styles.root_apps_email} href={`mailto:${INFO_MAIL}`}>
            <span>{INFO_MAIL}</span>
          </a>
        </HasRole>
      </div>
      {isAuthenticated && <UserProfileBar />}
    </Box>
  );
};

export default Header;
