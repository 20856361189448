import { createStore, forward } from 'effector';
import { DoctorFilterRequest } from 'types';
import { addPersonalDoctorFx, onGetDoctorsFx } from './effects';
import { onAddPersonalDoctor, onGetDoctors, onResetDoctors, onSetDoctorFilter } from './events';
import { onGetProfilePersonalsInteractionsMeta } from '../personal/events';

export interface DoctorsStateInterface {
  doctors: Array<any>;
  loading: boolean;
  error: any;
  loadingAddPersonal: string;
  errorAddPersonal: any;
  params: DoctorFilterRequest;
}
const defaultState: DoctorsStateInterface = {
  doctors: [],
  loading: false,
  error: null,
  loadingAddPersonal: null,
  errorAddPersonal: null,
  params: {
    page: 0,
    pageSize: 100,
  },
};

const $store = createStore(defaultState);
forward({
  from: onGetDoctors,
  to: onGetDoctorsFx,
});
forward({
  from: onAddPersonalDoctor,
  to: addPersonalDoctorFx,
});
forward({
  from: addPersonalDoctorFx.doneData,
  to: onGetProfilePersonalsInteractionsMeta,
});
$store.on(onSetDoctorFilter, (_, params) => ({ ..._, params }));
$store.on(onResetDoctors, (_) => ({ ..._, ...defaultState }));
$store.on(onGetDoctorsFx.pending, (_, loading) => ({ ..._, loading, error: null }));
$store.on(onGetDoctorsFx.doneData, (_, data) => ({ ..._, ...data, doctors: _.doctors.concat(data?.doctors || []) }));
$store.on(onGetDoctorsFx.fail, (_, error) => ({ ..._, loading: false, error }));
$store.on(onAddPersonalDoctor, (_, loadingAddPersonal) => ({
  ..._,
  loadingAddPersonal,
  errorAddPersonal: null,
}));
$store.on(addPersonalDoctorFx.doneData, (_) => ({ ..._, loadingAddPersonal: null, errorAddPersonal: null }));
$store.on(addPersonalDoctorFx.fail, (_, errorAddPersonal) => ({ ..._, loadingAddPersonal: null, errorAddPersonal }));

export default $store;
