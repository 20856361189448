import { createStore, forward } from 'effector';
import { onGetMyAvailablePersonalDoctorServicePackagesFx, onMyBuyServicePackagesFx } from './effects';
import {
  onGetMyAvailablePersonalDoctorServicePackages,
  onGetMyBuyServicePackages,
  onResetServicePackages,
} from './events';

export interface ServicePackegasStateInterface {
  packages: Array<any>;
  loading: boolean;
  error: any;
  url: string;
  loadingUrl: string;
  errorUrl: any;
}
const defaultState: ServicePackegasStateInterface = {
  packages: [],
  loading: false,
  url: null,
  loadingUrl: null,
  errorUrl: null,
  error: null,
};

const $store = createStore(defaultState);
forward({
  from: onGetMyAvailablePersonalDoctorServicePackages,
  to: onGetMyAvailablePersonalDoctorServicePackagesFx,
});
forward({
  from: onGetMyBuyServicePackages,
  to: onMyBuyServicePackagesFx,
});
$store.on(onResetServicePackages, (_) => ({ ..._, ...defaultState }));
$store.on(onGetMyBuyServicePackages, (_, loadingUrl) => ({ ..._, loadingUrl, errorUrl: null }));
$store.on(onMyBuyServicePackagesFx.doneData, (_, url) => ({ ..._, url, loadingUrl: null, errorUrl: null }));
$store.on(onMyBuyServicePackagesFx.fail, (_, errorUrl) => ({ ..._, loadingUrl: null, errorUrl }));
$store.on(onGetMyAvailablePersonalDoctorServicePackagesFx.pending, (_, loading) => ({ ..._, loading, error: null }));
$store.on(onGetMyAvailablePersonalDoctorServicePackagesFx.doneData, (_, packages) => ({ ..._, packages }));
$store.on(onGetMyAvailablePersonalDoctorServicePackagesFx.fail, (_, error) => ({ ..._, loading: false, error }));

export default $store;
