import React, { useCallback, useEffect, useState } from 'react';
import { Avatar, Box } from '@material-ui/core';
import classNames from 'classnames';
import { connect, useDispatch } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import { parseISO } from 'date-fns';
import InfiniteScroll from 'react-infinite-scroller';
import TuneIcon from '@material-ui/icons/Tune';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ImageIcon from '@material-ui/icons/Image';
import axios from 'utils/axios';
import EventIcon from '@material-ui/icons/Event';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import styles from './ServicePackagesAdminPatients.module.sass';
import Button from '../../../../components/Forms/Button';
import {
  updateManageServicePackagesPatientsDept,
  updateManageServicePackagesPatients,
  updateAccrualStatusPackagesMfo,
  updatePromotionStatusPackagesMfo,
  fetchManageServicePackagesPatients,
  fetchManageServicePackagesMfo,
} from '../../../../store/actions';
import Manage from '../../../../types/manage';
import ContentLoading from '../../../../components/ContentLoading';
import InputField from '../../../../components/Forms/InputField';
import { ManageServicePackagesPatientsInitialSettings } from '../../../../store/reducers/manageServicePackagesPatientsReducer.reducer';
import { decodeAvatarResource } from '../../../../utils';
import { ManageServicePackagesMfoInitialSettings } from '../../../../store/reducers/manageServicePackagesMfoReducer.reducer';
import { format } from '../../../../utils/date';
import DropdownMenu from '../../../../components/DropdownMenu';
import DropdownToggle from '../../../../components/DropdownMenu/DropdownToggle';
import DropdownContent from '../../../../components/DropdownMenu/DropdownContent';
import { ReactComponent as CheckIcon } from '../../../../assets/img/icons/check-icon.svg';
import { getUploadDocuments } from '../../../../api/servicePackage/getUploadDocuments';
import { displayErrors, handleHttpError } from '../../../../utils/handleHttpError';
import { deleteUploadPatientServicePackageAttachment } from '../../../../api/servicePackage/deleteUploadPatientServicePackageAttachment';
import { onlineSignPatientServicePackageDocs } from '../../../../api/servicePackage/onlineSignPatientServicePackageDocs';
import CategoryBenefitsSelect from '../../../../components/CategoryBenefitsSelect';
import CalendarRange from '../../../../components/CalendarRange';
import { getServicePackageDebp } from '../../../../api/servicePackage/getServicePackageDebp';
import SelectInput from '../../../../components/Forms/SelectInput';
import ServicePackageGroupSelector from '../ServicePackagesAdminGroups/GroupSelector/ServicePackageGroupSelector';
import { updatePatientServicePackageStatusWithData } from '../../../../api/updatePatientServicePackageStatus';
import { AdminCancellationEditModel } from '../../../../components/AdminCancellationEdit/AdminCancellationEdit';
import { getPromotionStatus } from '../../../../api/servicePackage/getPromotionStatus';
import { updatePromotionStatus } from '../../../../api/servicePackage/updatePromotionStatus';
import { getAccrualStatus } from '../../../../api/servicePackage/getAccrualStatus';
import { updatePatientServicePackageAccrualStatus } from '../../../../api/servicePackage/updatePatientServicePackageAccrualStatus';
import Spinner from '../../../../components/Spinner';
import HasRole from '../../../../components/HasRole';
import { UserRole } from '../../../../types';
import AdminServicePackageView from '../../../../components/AdminServicePackageView';
import useIsAdminRole from '../../../../hooks/useIsAdminRole';
import AdminServicePackageTransactionsView from '../../../../components/AdminServicePackageTransactionsView';
import AdminCancellationEdit from '../../../../components/AdminCancellationEdit';

interface PropsInterface {
  loading: boolean;
  page?: number;
  pageSize?: number;
  hasMore?: boolean;
  items: Manage.ServicePackages.Patient.Item[];
  mfo: ManageServicePackagesMfoInitialSettings;
  updateManageServicePackagesPatients: (servicePackageId?: any, status?: any) => void;
  fetchManageServicePackagesMfo: (query?: string, status?: string) => void;
  fetchManageServicePackagesPatients: (
    query?: string,
    status?: string,
    categoryBenefitsId?: string,
    createdFrom?: Date,
    createdTo?: Date,
    signOnline?: any,
    group?: string,
    page?: number,
    pageSize?: number,
    reset?: boolean
  ) => void;
  errors: any;
  updateManageServicePackagesPatientsDept: any;
}

const ServicePackagesAdminPatients = (props: PropsInterface) => {
  const {
    loading,
    items,
    page,
    pageSize,
    hasMore,
    mfo,
    fetchManageServicePackagesPatients,
    fetchManageServicePackagesMfo,
    updateManageServicePackagesPatients,
    updateManageServicePackagesPatientsDept,
    errors,
  } = props;
  const dispatch = useDispatch();
  const [toolbarOpen, toggleToolbarOpen] = useState<boolean>(false);
  const { items: itemsMfo, errors: errorsMfo, loading: loadingMfo } = mfo;
  const [search, onChangeSearch] = useState<string>(null);
  const [status, onChangeStatus] = useState<string>(null);
  const [tariff, setTariff] = useState<any>(null);
  const [sales, setSales] = useState<any>([]);
  const [accrualStatus, setAccrualStatus] = useState<any>([]);
  const [debtPatient, setDebtPatient] = useState<any>(null);
  const [viewDocs, setViewDocs] = useState<any>(null);
  const [viewSales, setViewSales] = useState<any>(null);
  const [cancelDoc, setCancelDoc] = useState<any>(null);
  const [docs, setDocs] = useState<any>(null);
  const [updatePromotionLoading, setUpdatePromotionLoading] = useState<any>(false);
  const [updateAccrualStatusLoading, setUpdateAccrualStatusLoading] = useState<any>(false);
  const [docsLoading, setDocsLoading] = useState<any>(false);
  const [isOnlineSignLoading, setOnlineSignLoading] = useState<boolean>(false);
  const [deletingDoc, setDeletingDoc] = useState<string | null>(null);
  const [docsErrors, setDocsErrors] = useState<any>(null);
  const [docContentLoading, setDocContentLoading] = useState<any>(null);
  const [categoryBenefits, setCategoryBenefits] = useState<string>(null);
  const [date, onChangeDate] = useState<{ start: Date; end: Date }>({ start: null, end: null });
  const [toolbarOpenDate, toggleToolbarOpenDate] = useState<boolean>(false);
  const [toolbarOpenSales, toggleToolbarOpenSales] = useState<boolean>(false);
  const [toolbarAccrualStatus, toggleToolbarAccrualStatus] = useState<boolean>(false);
  const [group, setGroup] = useState<string>(null);
  const [signOnline, setSignOnline] = useState<boolean>(null);
  const [loadingCancellation, setLoadingCancellation] = useState<any>(false);
  const [signOnlineOptions] = useState([
    { label: 'Все', value: null },
    { label: 'Онлайн', value: true },
    { label: 'Офлайн', value: false },
  ]);
  useEffect(() => {
    fetchManageServicePackagesMfo();
    fetchPromotionStatus();
    fetchAccrualStatus();
  }, []);
  const isAdminRole = useIsAdminRole();
  const fetchPromotionStatus = () => {
    getPromotionStatus()
      .then((data) => {
        setSales(data?.data?.data?.items || []);
      })
      .catch();
  };

  const fetchAccrualStatus = () => {
    getAccrualStatus()
      .then((data) => {
        setAccrualStatus(data?.data?.data?.items || []);
      })
      .catch();
  };

  const updatePromotion = useCallback(
    (id: string, promotionId: string, status: any) => {
      toggleToolbarOpenSales(false);
      setUpdatePromotionLoading(id);
      updatePromotionStatus(promotionId, status?.value)
        .then((data) => {
          if (data.data.code === 'success') {
            dispatch(updatePromotionStatusPackagesMfo(id, promotionId, status));
          }
          setUpdatePromotionLoading(false);
        })
        .catch(() => {
          setUpdatePromotionLoading(false);
          toast.error('Ошибка при запросе');
        });
    },
    [viewSales]
  );

  const updateAccrualStatus = useCallback(
    (id: string, status: any) => {
      toggleToolbarAccrualStatus(null);
      setUpdateAccrualStatusLoading(id);
      updatePatientServicePackageAccrualStatus(id, status?.value)
        .then((data) => {
          if (data.data.code === 'success') {
            dispatch(updateAccrualStatusPackagesMfo(id, status));
          }
          setUpdateAccrualStatusLoading(false);
        })
        .catch(() => {
          setUpdateAccrualStatusLoading(false);
          toast.error('Ошибка при запросе');
        });
    },
    [dispatch]
  );

  useEffect(() => {
    const timeout = search ? setTimeout(() => getTariffs(true), 500) : null;
    !search && getTariffs(true);
    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [search, status, categoryBenefits, date, signOnline, group]);
  const getTariffs = (reset?: boolean): void => {
    fetchManageServicePackagesPatients(
      search,
      status,
      categoryBenefits,
      date.start,
      date.end,
      signOnline,
      group,
      reset ? 0 : reset ? 0 : page + 1,
      pageSize,
      reset
    );
  };

  useEffect(() => {
    if (viewDocs) {
      getDocuments(viewDocs);
    } else {
      setDocsErrors(null);
      setDocs([]);
      setDocsLoading(false);
    }
  }, [viewDocs]);

  const getCancelDoc = useCallback((): AdminCancellationEditModel => {
    if (!cancelDoc) {
      return {};
    }
    const { cancellationReasonType, cancellationReasonDescription, canceledAt } =
      items?.find((v) => v?.id === cancelDoc) || ({} as any);
    return {
      cancellationReasonDescription,
      cancellationReasonType: cancellationReasonType?.value,
      canceledAt: canceledAt ? new Date(canceledAt) : null,
    };
  }, [cancelDoc]);

  const getDocuments = (id): void => {
    setDocsLoading(true);
    getUploadDocuments(id)
      .then((data) => {
        setDocsLoading(false);
        setDocs(data?.data?.data?.items || []);
      })
      .catch((errors) => {
        setDocsLoading(false);
        setDocsErrors(errors);
      });
  };

  const deleteDoc = (docId: string) => {
    setDeletingDoc(docId);
    deleteUploadPatientServicePackageAttachment(viewDocs, docId)
      .then((data) => {
        if (data.data.code === 'success') {
          getDocuments(viewDocs);
        }
        setDeletingDoc(null);
      })
      .catch((error) => {
        setDeletingDoc(null);
        displayErrors(handleHttpError(error));
      });
  };

  const onlineSign = (patientServicePackageId: string) => {
    console.log('onlineSign', patientServicePackageId);
    setOnlineSignLoading(true);
    onlineSignPatientServicePackageDocs(patientServicePackageId)
      .then((data) => {
        if (data.data.code === 'success') {
          getDocuments(viewDocs);
        }
        setOnlineSignLoading(false);
      })
      .catch((error) => {
        setOnlineSignLoading(false);
        displayErrors(handleHttpError(error));
      });
  };

  const updateDept = (servicePackageId: string) => {
    getServicePackageDebp(servicePackageId).then((data) => {
      if (data.data?.code === 'success') {
        updateManageServicePackagesPatientsDept(servicePackageId, data.data?.data);
      }
    });
  };

  const getLabelStatus = (status: string): string => {
    switch (status) {
      case 'NEW':
        return 'Новый';
      case 'SENT':
        return 'Отправлен';
      case 'PURCHASED':
        return 'Куплен';
      case 'ACTIVATED':
        return 'Активирован';
      case 'CLOSED':
        return 'Закрыт';
      case 'CANCELED':
        return 'Отменен';
      default:
        return null;
    }
  };
  const getTariffOptions = (): { key: string; value: string; column?: boolean }[] => {
    const tariffMfo: any = itemsMfo.find((i: Manage.ServicePackages.Mfo.Item) => i.id === tariff.servicePackageId);
    if (!tariff || !tariffMfo) {
      return [];
    }
    const canceledOptions = [
      {
        key: 'Отменен',
        value: tariff?.canceledAt ? format(parseISO(tariff.canceledAt.toString()), 'dd.MM.yyyy HH:mm') : '',
      },
      {
        key: 'Причина отмены',
        column: true,
        value: tariff?.cancellationReasonType?.name || '-',
      },
      {
        key: 'Описание отмены ',
        column: true,
        value: tariff?.cancellationReasonDescription || '-',
      },
    ];
    const addTariffOptions = tariff?.status?.value === 'CANCELED' ? canceledOptions : [];
    return [
      {
        key: 'Создан',
        value: tariff.createdAt ? format(parseISO(tariff.createdAt.toString()), 'dd.MM.yyyy') : '',
      },
      {
        key: 'Продан',
        value: tariff.purchasedAt ? format(parseISO(tariff.purchasedAt.toString()), 'dd.MM.yyyy') : '',
      },
      {
        key: 'Активирован',
        value: tariff.activatedAt ? format(parseISO(tariff.activatedAt.toString()), 'dd.MM.yyyy') : '',
      },
      {
        key: 'Закрыт',
        value: tariff.closedAt ? format(parseISO(tariff.closedAt.toString()), 'dd.MM.yyyy') : '',
      },
      {
        key: 'Истекает',
        value: tariff.expiredAt ? format(parseISO(tariff.expiredAt.toString()), 'dd.MM.yyyy HH:mm') : '-',
      },
      {
        key: 'Cтоимость',
        value: (tariff.price || 0).toString(),
      },
      {
        key: 'Доступные типы специализации',
        column: true,
        value: (tariffMfo.specializations || [])
          .map((spec) => spec.name)
          .filter((v) => v)
          .join(', '),
      },
      {
        key: 'Доступные типы связи',
        column: true,
        value: (tariffMfo.channel ? [tariffMfo.channel] : [])
          .map((type) => {
            switch (type.value) {
              case 'CHAT':
                return 'Чат';
              case 'PHONE':
                return 'Чат/Аудио';
              case 'VIDEO':
                return 'Чат/Аудио/Видео';
              default:
                return null;
            }
          })
          .filter((v) => v)
          .join(', '),
      },
      {
        key: 'Доступные типы консультаций',
        column: true,
        value: (tariffMfo?.serviceTypes || [])
          .map((type) => type.name)
          .filter((v) => v)
          .join(', '),
      },
      {
        key: 'Консультаций в день',
        value: (tariffMfo.appointmentsPerDay || 0).toString(),
      },
      {
        key: 'Дополнительные услуги',
        value:
          (tariff.additionalServices || [])
            .map(
              (value) => `${value.type.name} (${value.availableLimitPerPeriod || 0} из ${value.limitPerPeriod || '-'})`
            )
            .join(', ') || '-',
      },
      {
        key: 'Прикрепление файлов',
        value: tariffMfo.withFiles ? 'Да' : 'Нет',
      },
      {
        key: 'Срок действия (дней)',
        value: tariffMfo.expirationDays ? tariffMfo.expirationDays.toString() : 'Бессрочный',
      },
      {
        key: 'Требует активации промокодом',
        value: tariffMfo.promoActivation ? 'Да' : 'Нет',
      },
      {
        key: 'Статус начислений',
        value: tariff.accrualStatus?.name || '',
      },
    ].concat(addTariffOptions);
  };
  const onPurchased = useCallback((servicePackageId?: any, status?: any): void => {
    updateManageServicePackagesPatients(servicePackageId, status);
    setTariff(null);
  }, []);

  const onClosed = useCallback((servicePackageId?: any, status?: any) => {
    updateManageServicePackagesPatients(servicePackageId, status);
    setTariff(null);
  }, []);

  const downloadDoc = (item: any): void => {
    setDocContentLoading(item?.id);
    axios
      .get(item?.resourceUrl, { responseType: 'blob' })
      .then((data) => {
        const fileURL = URL.createObjectURL(data?.data);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = `${item?.name}`;
        link.click();
        setTimeout(() => {
          link.remove();
          setDocContentLoading(null);
        }, 300);
      })
      .catch((e) => {
        setDocContentLoading(null);
        displayErrors(handleHttpError(e));
      });
  };

  const onActivated = useCallback((servicePackageId: any, status: any) => {
    updateManageServicePackagesPatients(servicePackageId, status);
    setTariff(null);
  }, []);

  const onCancellation = useCallback(
    (model: any) => {
      setLoadingCancellation(true);
      updatePatientServicePackageStatusWithData(cancelDoc, model).then(
        (response: AxiosResponse<any>) => {
          if (response.data.code === 'success') {
            setLoadingCancellation(false);
            updateManageServicePackagesPatients(cancelDoc, 'CANCELED');
            updateDept(cancelDoc);
            setCancelDoc(null);
          } else {
            displayErrors(handleHttpError(response));
          }
        },
        (error) => displayErrors(handleHttpError(error))
      );
    },
    [cancelDoc]
  );

  useEffect(() => {
    toggleToolbarOpenSales(false);
  }, [viewSales]);

  return (
    <>
      <Box className={classNames(styles.ServicePackagesAdminPatients)}>
        <Box
          className={classNames(
            styles.ServicePackagesAdminPatients_Section,
            styles.ServicePackagesAdminPatients_Toolbar
          )}
        >
          <Box className={styles.ServicePackagesAdminPatients_Toolbar_Search}>
            <SearchIcon className={styles.ServicePackagesAdminPatients_Toolbar_Search_Icon} />
            <InputField
              value={search}
              onChange={(e) => {
                onChangeSearch(e.target.value);
              }}
              block
              placeholder="Введите ФИО пациента"
            />
            {search && (
              <ClearIcon
                onClick={() => {
                  onChangeSearch('');
                }}
                className={classNames(
                  styles.ServicePackagesAdminPatients_Toolbar_Search_Icon,
                  styles.ServicePackagesAdminPatients_Toolbar_Search_Icon_Clear
                )}
              />
            )}
          </Box>
          <Box className={styles.ServicePackagesAdminPatients_Toolbar_Buttons}>
            <DropdownMenu isOpen={toolbarOpen} toggle={() => toggleToolbarOpen(!toolbarOpen)}>
              <DropdownToggle>
                <Button color="primary" size="md">
                  <TuneIcon />
                  {status && <Box className={styles.ServicePackagesAdminPatients_Toolbar_Buttons_Status} />}
                </Button>
              </DropdownToggle>
              <DropdownContent className={styles.ServicePackagesAdminPatients_Toolbar_DropdownContent}>
                <ul>
                  {['NEW', 'SENT', 'PURCHASED', 'ACTIVATED', 'CLOSED', 'CANCELED'].map((s: string, index: number) => (
                    <li
                      key={index}
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        onChangeStatus(s);
                      }}
                    >
                      <Box>
                        <Box
                          className={classNames(
                            styles.ServicePackagesAdminPatients_Toolbar_DropdownContent_Status,
                            styles[`ServicePackagesAdminPatients_Toolbar_DropdownContent_Status_${s}`]
                          )}
                        />
                        <Box display="flex" flex="1 1 auto" width="100%" pl={2}>
                          {getLabelStatus(s)}
                        </Box>
                      </Box>
                      <CheckIcon
                        className={classNames(
                          styles.ServicePackagesAdminPatients_Toolbar_DropdownContent_Icon,
                          status !== s && styles.ServicePackagesAdminPatients_Toolbar_DropdownContent_Icon_Hidden
                        )}
                      />
                    </li>
                  ))}
                </ul>
                {status != null && (
                  <Box className={styles.ServicePackagesAdminPatients_Toolbar_DropdownContent_Reset}>
                    <Button
                      color="default"
                      block
                      size="md"
                      onClick={(event) => {
                        event.preventDefault();
                        onChangeStatus(null);
                      }}
                    >
                      Сбросить фильтр
                    </Button>
                  </Box>
                )}
              </DropdownContent>
            </DropdownMenu>
            <DropdownMenu isOpen={toolbarOpenDate} toggle={() => toggleToolbarOpenDate(!toolbarOpenDate)}>
              <DropdownToggle>
                <Button color="primary" size="md">
                  <EventIcon />
                  {(date.start || date.end) && (
                    <Box className={styles.ServicePackagesAdminPatients_Toolbar_Buttons_Status} />
                  )}
                </Button>
              </DropdownToggle>
              <DropdownContent className={styles.ServicePackagesAdminPatients_Toolbar_DropdownContent}>
                <CalendarRange date={date} onChangeDate={(date: { start: Date; end: Date }) => onChangeDate(date)} />
                {(date.start || date.end) && (
                  <Box className={styles.ServicePackagesAdminPatients_Toolbar_DropdownContent_Reset}>
                    <Button
                      color="default"
                      block
                      size="md"
                      onClick={(event) => {
                        event.preventDefault();
                        onChangeDate({ start: null, end: null });
                      }}
                    >
                      Сбросить фильтр
                    </Button>
                  </Box>
                )}
              </DropdownContent>
            </DropdownMenu>
          </Box>
        </Box>
        <Box
          className={classNames(
            styles.ServicePackagesAdminPatients_Section,
            styles.ServicePackagesAdminPatients_Toolbar
          )}
        >
          <Box display="flex" justifyContent="center" width="100%">
            <Box mr={2}>
              <ServicePackageGroupSelector
                isEmpty
                value={group}
                onChange={(option) => setGroup(option.value ? String(option.value) : null)}
                placeholder="Группа"
              />
            </Box>
            <Box mr={2}>
              <SelectInput
                value={signOnline}
                onChange={(option) => {
                  setSignOnline(option.value);
                }}
                options={signOnlineOptions}
                placeholder={signOnlineOptions.find((s) => (s.value as any) === signOnline)?.label}
              />
            </Box>
            <Box>
              <CategoryBenefitsSelect
                value={categoryBenefits}
                onChange={(option) => {
                  setCategoryBenefits(option.value);
                }}
                placeholder="Льгота"
              />
            </Box>
          </Box>
        </Box>
        <Box
          className={classNames(
            styles.ServicePackagesAdminPatients_Section,
            styles.ServicePackagesAdminPatients_Content
          )}
        >
          <InfiniteScroll
            className={styles.ServicePackagesAdminPatients_InfiniteScroll}
            pageStart={0}
            hasMore={loading || loadingMfo ? false : hasMore}
            initialLoad={false}
            threshold={pageSize}
            loadMore={() => getTariffs()}
            loader={null}
          >
            <>
              {items.length === 0 && (!search || !search.length) && !(loading || loadingMfo) && (
                <Box>У вас пока нет созданных тарифов</Box>
              )}
              {items.length === 0 && search && search.length > 0 && !(loading || loadingMfo) && (
                <Box>По вашему запросу ничего не найдено</Box>
              )}
              {items.length > 0 && (
                <Box className={classNames(styles.ServicePackagesAdminPatients_Items)}>
                  {items.map((item: any, index: number) => {
                    const isOnlineSubscription = item.verificationCode !== undefined && item.verificationCode !== null;

                    return (
                      <Box
                        width="100%"
                        key={index}
                        className={classNames(styles.ServicePackagesAdminPatients_Items_Wrapper)}
                      >
                        <Box
                          className={classNames(styles.ServicePackagesAdminPatients_Items_Item)}
                          onClick={() =>
                            setTariff(
                              itemsMfo.filter((i: Manage.ServicePackages.Mfo.Item) => i.id === item.servicePackageId)
                                .length
                                ? item
                                : null
                            )
                          }
                        >
                          <Box className={classNames(styles.ServicePackagesAdminPatients_Items_Item_Data)}>
                            <Avatar
                              className={styles.ServicePackagesAdminPatients_Items_Item_Data_ProfileImg}
                              src={decodeAvatarResource(item.patient.avatar, 360, 'MALE')}
                            />
                            <Box className={classNames(styles.ServicePackagesAdminPatients_Items_Item_Data_Profile)}>
                              <Box
                                className={classNames(styles.ServicePackagesAdminPatients_Items_Item_Data_Profile_Name)}
                              >
                                {item.patient.firstName} {item.patient.lastName} {item.patient.middleName}
                              </Box>
                              <Box
                                className={classNames(styles.ServicePackagesAdminPatients_Items_Item_Data_Profile_Item)}
                              >
                                {item?.name}
                              </Box>
                              <Box
                                className={classNames(styles.ServicePackagesAdminPatients_Items_Item_Data_Profile_Item)}
                              >
                                Телефон: {item?.phone}
                              </Box>
                              <Box
                                className={classNames(styles.ServicePackagesAdminPatients_Items_Item_Data_Profile_Item)}
                              >
                                Промокод: {item.promo || ''}
                              </Box>
                              <Box
                                className={classNames(styles.ServicePackagesAdminPatients_Items_Item_Data_Profile_Item)}
                              >
                                Кол-во купленных консультаций: {item.usedCount || 0}
                              </Box>
                              {item.expiredAt && (
                                <Box
                                  className={classNames(
                                    styles.ServicePackagesAdminPatients_Items_Item_Data_Profile_Item
                                  )}
                                >
                                  Истекает : {format(parseISO(item.expiredAt.toString()), 'dd.MM.yyyy HH:mm')}
                                </Box>
                              )}
                              <Box
                                className={classNames(styles.ServicePackagesAdminPatients_Items_Item_Data_Profile_Item)}
                              >
                                Онлайн подписание: {isOnlineSubscription ? 'Да' : 'Нет'}
                              </Box>
                              <Box
                                className={classNames(styles.ServicePackagesAdminPatients_Items_Item_Data_Profile_Item)}
                              >
                                Услуги: {item.additionalServices?.map((value) => value.type.name).join(', ') || '-'}
                              </Box>
                              {item.status && (
                                <Box
                                  className={classNames(
                                    styles.ServicePackagesAdminPatients_Items_Item_Data_Profile_Item,
                                    styles[
                                      `ServicePackagesAdminPatients_Items_Item_Data_Profile_Status_${item.status.value}`
                                    ]
                                  )}
                                >
                                  {getLabelStatus(item.status.value as string)}
                                </Box>
                              )}
                              {item.accrualStatus && (
                                <Box
                                  className={classNames(
                                    styles.ServicePackagesAdminPatients_Items_Item_Data_Profile_Item
                                  )}
                                >
                                  Статус начислений:&nbsp;
                                  <DropdownMenu
                                    isOpen={toolbarAccrualStatus === item?.id}
                                    toggle={() => toggleToolbarAccrualStatus(!!toolbarAccrualStatus)}
                                  >
                                    <DropdownToggle>
                                      <Box
                                        borderBottom="none"
                                        style={{ textDecoration: 'none' }}
                                        className={classNames(updateAccrualStatusLoading !== item?.id ? 'link' : null)}
                                        component="a"
                                        display="flex"
                                        alignItems="center"
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          updateAccrualStatusLoading !== item?.id &&
                                            toggleToolbarAccrualStatus(item?.id);
                                        }}
                                      >
                                        <Box
                                          mr={2}
                                          color={updateAccrualStatusLoading !== item?.id ? 'inherit' : '#B3BABD'}
                                        >
                                          {item.accrualStatus.name}
                                        </Box>
                                        {updateAccrualStatusLoading === item?.id && <Spinner />}
                                      </Box>
                                    </DropdownToggle>
                                    <DropdownContent
                                      className={styles.ServicePackagesAdminPatients_Toolbar_DropdownContent}
                                    >
                                      <ul>
                                        {(accrualStatus || []).map((aS: any, index: number) => (
                                          <li
                                            key={index}
                                            onClick={(event) => {
                                              event.preventDefault();
                                              event.stopPropagation();
                                              if (item.accrualStatus?.value === aS?.value) {
                                                toggleToolbarAccrualStatus(false);
                                                return;
                                              }
                                              updateAccrualStatus(item?.id, aS);
                                            }}
                                          >
                                            <Box>
                                              <Box
                                                className={classNames(
                                                  styles.ServicePackagesAdminPatients_Toolbar_DropdownContent_Status,
                                                  item.accrualStatus?.value === aS?.value &&
                                                    styles.ServicePackagesAdminPatients_Toolbar_DropdownContent_Status_SENT
                                                )}
                                              />
                                              <Box display="flex" flex="1 1 auto" width="100%" pl={2}>
                                                {aS?.name}
                                              </Box>
                                            </Box>
                                          </li>
                                        ))}
                                      </ul>
                                    </DropdownContent>
                                  </DropdownMenu>
                                </Box>
                              )}
                              {item?.debt && (
                                <Box
                                  width="100%"
                                  display="flex"
                                  flexDirection="row"
                                  className={styles.ServicePackagesAdminPatients_Items_Item_Data_Profile_Item}
                                >
                                  <Box mr={1}>Долг:</Box>{' '}
                                  <Box color={item.debt?.debtSum > 0 ? '#FF341A' : '#00C12F'}>
                                    {item.debt?.debtSum} &#8381;
                                  </Box>
                                </Box>
                              )}
                            </Box>
                          </Box>
                          <Box width="30%" justifyContent="flex-end">
                            <Box display="flex" flexDirection="column">
                              <Button
                                style={{ paddingLeft: 10, paddingRight: 10 }}
                                className={classNames(
                                  styles.ServicePackagesAdminMfo_Button,
                                  styles.ServicePackagesAdminMfo_Button_Remove
                                )}
                                size="sm"
                                color="default"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setDebtPatient(item);
                                }}
                              >
                                История операций
                              </Button>
                              <Button
                                style={{ marginTop: 10, paddingLeft: 10, paddingRight: 10 }}
                                className={classNames(styles.ServicePackagesAdminMfo_Button)}
                                size="sm"
                                color={viewDocs === item?.id ? 'primary' : 'default'}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setViewDocs(viewDocs === item?.id ? null : item?.id);
                                }}
                              >
                                Загруженные документы
                              </Button>
                              <HasRole target={UserRole.ADMIN}>
                                {(item?.promotions || []).length > 0 && (
                                  <Button
                                    style={{ marginTop: 10, paddingLeft: 10, paddingRight: 10 }}
                                    className={classNames(styles.ServicePackagesAdminMfo_Button)}
                                    size="sm"
                                    color={viewSales === item?.id ? 'primary' : 'default'}
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      setViewSales(viewSales?.id === item?.id ? null : item);
                                    }}
                                  >
                                    Акции
                                  </Button>
                                )}
                                <Button
                                  style={{ marginTop: 10, paddingLeft: 10, paddingRight: 10 }}
                                  className={classNames(styles.ServicePackagesAdminMfo_Button)}
                                  size="sm"
                                  color="alert"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    setCancelDoc(item?.id);
                                  }}
                                >
                                  Отменить
                                </Button>
                              </HasRole>
                            </Box>
                          </Box>
                        </Box>
                        {item.id === viewDocs && (
                          <>
                            <ContentLoading
                              isLoading={docsLoading}
                              isError={docsErrors}
                              fetchData={() => getDocuments(viewDocs)}
                            >
                              <Box className={classNames(styles.Docs)}>
                                {docs.map((item: any, index: number) => (
                                  <Box className={classNames(styles.Docs_Item)} key={index}>
                                    <Box className={classNames(styles.Docs_Item_Data)}>
                                      <Box className={classNames(styles.Docs_Item_Data_Value)}>
                                        {item?.contentType === 'application/pdf' ? (
                                          <PictureAsPdfIcon className={classNames(styles.Docs_Item_Data_Icon)} />
                                        ) : (
                                          <ImageIcon className={classNames(styles.Docs_Item_Data_Icon)} />
                                        )}
                                      </Box>
                                      <Box className={classNames(styles.Docs_Item_Data_Value)}>{item.name}</Box>
                                    </Box>
                                    <Box className={classNames(styles.Docs_Item_Actions)}>
                                      <Button
                                        style={{
                                          paddingLeft: 10,
                                          paddingRight: 10,
                                          marginRight: 5,
                                        }}
                                        size="sm"
                                        color="default"
                                        disabled={!!docContentLoading}
                                        isLoading={docContentLoading === item?.id}
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          downloadDoc(item);
                                        }}
                                      >
                                        Просмотреть
                                      </Button>
                                      <HasRole target={UserRole.ADMIN}>
                                        <Button
                                          style={{
                                            paddingLeft: 10,
                                            paddingRight: 10,
                                            margiLeft: 5,
                                          }}
                                          size="sm"
                                          disabled={!!deletingDoc}
                                          isLoading={deletingDoc != null && deletingDoc === item?.id}
                                          color="alert"
                                          onClick={(event) => {
                                            event.stopPropagation();
                                            deleteDoc(item?.id);
                                          }}
                                        >
                                          Удалить
                                        </Button>
                                      </HasRole>
                                    </Box>
                                  </Box>
                                ))}
                              </Box>
                            </ContentLoading>
                            <HasRole target={UserRole.ADMIN}>
                              <Box width="100%" display="flex" justifyContent="center" style={{ marginBottom: 10 }}>
                                <Button
                                  size="md"
                                  color="primary"
                                  isLoading={isOnlineSignLoading}
                                  disabled={!!isOnlineSignLoading}
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    onlineSign(item.id);
                                  }}
                                >
                                  Сформировать документы
                                </Button>
                              </Box>
                            </HasRole>
                          </>
                        )}
                        {item.id === viewSales?.id && (
                          <>
                            <Box className={classNames(styles.Sales)}>
                              {((items?.find((i) => i?.id === viewSales?.id) as any)?.promotions || []).map(
                                (promo: any, index: number) => (
                                  <Box className={classNames(styles.Sales_Item)} key={index}>
                                    <Box className={classNames(styles.Sales_Item_Data)}>
                                      <Box className={classNames(styles.Sales_Item_Data_Value)}>{promo.name}</Box>
                                      <Box className={classNames(styles.Sales_Item_Data_Value)}>
                                        №&nbsp;
                                        <Box className={classNames(styles.Sales_Item_Data_Value_Sub)}>{promo.id}</Box>
                                      </Box>
                                      {(promo.startDate || promo.endDate) && (
                                        <>
                                          <Box className={classNames(styles.Sales_Item_Data_Value)}>
                                            Даты:&nbsp;
                                            {promo.startDate && (
                                              <Box className={classNames(styles.Sales_Item_Data_Value_Sub)}>
                                                c {format(parseISO(promo.startDate.toString()), 'dd.MM.yyyy')}
                                              </Box>
                                            )}
                                            {promo.endDate && (
                                              <Box className={classNames(styles.Sales_Item_Data_Value_Sub)}>
                                                &nbsp; по {format(parseISO(promo.endDate.toString()), 'dd.MM.yyyy')}
                                              </Box>
                                            )}
                                          </Box>
                                        </>
                                      )}
                                      <Box width="100%" alignItems="center" display="flex" justifyContent="center">
                                        <DropdownMenu
                                          isOpen={toolbarOpenSales}
                                          toggle={() => toggleToolbarOpenSales(!toolbarOpenSales)}
                                        >
                                          <DropdownToggle>
                                            <Button color="primary" size="md" isLoading={updatePromotionLoading}>
                                              {promo?.status?.name}
                                            </Button>
                                          </DropdownToggle>
                                          <DropdownContent
                                            className={styles.ServicePackagesAdminPatients_Toolbar_DropdownContent}
                                          >
                                            <ul>
                                              {(sales || []).map((s: any, index: number) => (
                                                <li
                                                  key={index}
                                                  onClick={(event) => {
                                                    event.preventDefault();
                                                    event.stopPropagation();
                                                    if (promo?.status?.value === s?.value) {
                                                      toggleToolbarOpenSales(false);
                                                      return;
                                                    }
                                                    updatePromotion(item?.id, promo?.id, s);
                                                  }}
                                                >
                                                  <Box>
                                                    <Box
                                                      className={classNames(
                                                        styles.ServicePackagesAdminPatients_Toolbar_DropdownContent_Status
                                                      )}
                                                    />
                                                    <Box display="flex" flex="1 1 auto" width="100%" pl={2}>
                                                      {s?.name}
                                                    </Box>
                                                  </Box>
                                                </li>
                                              ))}
                                            </ul>
                                          </DropdownContent>
                                        </DropdownMenu>
                                      </Box>
                                    </Box>
                                  </Box>
                                )
                              )}
                            </Box>
                          </>
                        )}
                      </Box>
                    );
                  })}
                </Box>
              )}
              {(loading || loadingMfo || errors || errorsMfo) && (
                <Box width="100%" p={2}>
                  <ContentLoading
                    isLoading={loading || loadingMfo}
                    isError={errors || errorsMfo}
                    fetchData={() => getTariffs()}
                  />
                </Box>
              )}
            </>
          </InfiniteScroll>
        </Box>
      </Box>
      {tariff && (
        <AdminServicePackageView
          disabled={!isAdminRole}
          name={itemsMfo.find((i: Manage.ServicePackages.Mfo.Item) => i.id === tariff.servicePackageId).name}
          packageId={tariff.id}
          isPurchased={['NEW', 'SENT'].indexOf(tariff.status.value as string) > -1}
          isCanClosed={
            ['CLOSED', 'CANCELED'].indexOf(
              tariff.status.value as Manage.ServicePackages.Mfo.PatientServicePackageStatus
            ) < 0
          }
          isCanActivated={
            ['PURCHASED'].indexOf(tariff.status.value as Manage.ServicePackages.Mfo.PatientServicePackageStatus) > -1
          }
          onClosed={onClosed}
          onPurchased={onPurchased}
          onActivated={onActivated}
          options={getTariffOptions()}
          onClose={() => setTariff(null)}
        />
      )}
      {debtPatient && (
        <AdminServicePackageTransactionsView
          patient={debtPatient}
          onClose={() => {
            updateDept(debtPatient?.id);
            setDebtPatient(null);
          }}
        />
      )}
      {cancelDoc && (
        <AdminCancellationEdit
          data={getCancelDoc()}
          loading={loadingCancellation}
          onClose={() => setCancelDoc(null)}
          onCancel={onCancellation}
        />
      )}
    </>
  );
};
export default connect(
  (state: any) => ({
    ...(state.manageServicePackagesPatients as ManageServicePackagesPatientsInitialSettings),
    mfo: state.manageServicePackagesMfo as ManageServicePackagesMfoInitialSettings,
  }),
  {
    fetchManageServicePackagesMfo,
    fetchManageServicePackagesPatients,
    updateManageServicePackagesPatients,
    updateManageServicePackagesPatientsDept,
  }
)(ServicePackagesAdminPatients);
