import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import classNames from 'classnames';
import { connect, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import { parseISO } from 'date-fns';
import TuneIcon from '@material-ui/icons/Tune';
import EventIcon from '@material-ui/icons/Event';
import { toast } from 'react-toastify';
import styles from './ReportsPageAdminAppointmentItems.module.sass';
import { fetchManageAppointmentsItems, fetchManageServicePackagesMfo } from '../../../../store/actions';
import Manage from '../../../../types/manage';
import ContentLoading from '../../../../components/ContentLoading';
import { ManageAppointmentsItemsInitialSettings } from '../../../../store/reducers/manageAppointmentsItemsReducer.reducer';
import SearchPatients from '../../../../components/SearchPatients';
import Patient from '../../../../types/patient';
import Button from '../../../../components/Forms/Button';
import { format } from '../../../../utils/date';
import AdminServicePackageView from '../../../../components/AdminServicePackageView';
import DropdownMenu from '../../../../components/DropdownMenu';
import DropdownToggle from '../../../../components/DropdownMenu/DropdownToggle';
import DropdownContent from '../../../../components/DropdownMenu/DropdownContent';
import { ReactComponent as CheckIcon } from '../../../../assets/img/icons/check-icon.svg';
import CalendarRange from '../../../../components/CalendarRange';
import { RootState } from '../../../../store/reducers';
import SelectInput from '../../../../components/Forms/SelectInput';
import SearchDoctors from '../../../../components/SearchDoctors';
import { getAdminAppointmentReport } from '../../../../api/getAdminAppointmentReport';
import { AppointmentConclusionLinkView } from '../../../../components/AppointmentCoclusion/AppointmentConclusionLinkView';

type ReportsPageAdminAppointmentItemsFilter = 'all' | 'payedWithPromo';
interface PropsInterface {
  loading: boolean;
  errors: any;
  page: number;
  pageSize: number;
  hasMore: boolean;
  items: Manage.AppointmentItem.Item[];
  fetchManageServicePackagesMfo: () => void;
  fetchManageAppointmentsItems: (
    patientIds: string[],
    doctorIds: string[],
    statuses?: string[],
    types?: string[],
    payedWithPromo?: boolean,
    timeFrom?: Date,
    timeTo?: Date,
    page?: number,
    pageSize?: number,
    reset?: boolean
  ) => void;
  type: any;
  types: any;
  onChangeType: (type: any) => void;
}
const ReportsPageAdminAppointmentItems = (props: PropsInterface) => {
  const {
    loading,
    items,
    fetchManageAppointmentsItems,
    fetchManageServicePackagesMfo,
    errors,
    page,
    pageSize,
    hasMore,
    type,
    types,
    onChangeType,
  } = props;
  const mfoItems: Manage.ServicePackages.Mfo.Item[] = useSelector(
    (state: RootState) => state.manageServicePackagesMfo.items || []
  );
  const serviceTypes = [
    { label: 'Срочная консультация', value: 'ON_DEMAND' },
    { label: 'По времени ', value: 'IN_TIME' },
    { label: 'Ежедневная консультация', value: 'DAILY' },
    { label: 'Второе мнение', value: 'SECOND_OPINION' },
  ];
  const _statuses = ['AWAITS', 'CANCELED', 'DONE', 'FAILED', 'FINISHED', 'IN_PROGRESS'];
  const [loadingReport, setLoadingReport] = useState(false);
  const [serviceType, setServiceTypes] = useState(['ON_DEMAND', 'IN_TIME']);
  const [patient, setPatient] = useState<Patient.Data>(null);
  const [doctor, setDoctor] = useState<any>(null);
  const [date, setDate] = useState<{ start: Date; end: Date }>({
    start: new Date(new Date().setHours(0, 0, 0, 0)),
    end: null,
  });
  const [statuses, setStatuses] = useState<string[]>([]);
  const [filter, setFilter] = useState<ReportsPageAdminAppointmentItemsFilter>('all');
  const [tariff, setTariff] = useState<Manage.ServicePackages.Mfo.Item>(null);
  const [toolbarOpenServiceTypes, toggleToolbarOpenServiceTypes] = useState<boolean>(false);
  const [toolbarOpenStatus, toggleToolbarOpenStatus] = useState<boolean>(false);
  const [toolbarDateOpen, toggleToolbarDateOpen] = useState<boolean>(false);
  useEffect(() => fetchManageServicePackagesMfo(), []);
  useEffect(() => {
    getAppointmentItems(true);
  }, [patient, date, statuses, filter, doctor, serviceType]);
  const onGetAdminAppointmentReport = () => {
    setLoadingReport(true);
    getAdminAppointmentReport(
      patient ? [patient.id] : [],
      doctor ? [doctor?.id] : [],
      statuses,
      serviceType,
      filter === 'payedWithPromo' ? true : null,
      date.start,
      date.end,
      page,
      pageSize
    )
      .then((data) => {
        setLoadingReport(false);
        const fileURL = URL.createObjectURL(data.data);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = `AppointmentReport.xlsx`;
        link.click();
        setTimeout(() => link.remove(), 300);
      })
      .catch(() => {
        setLoadingReport(false);
        toast.error('Ошибка при запросе');
      });
  };
  const getLabelStatus = (status: string): string => {
    switch (status) {
      case 'AWAITS':
        return 'Ожидает начала';
      case 'FINISHED':
        return 'Ожидает заключения';
      case 'DONE':
        return 'Завершена';
      case 'FAILED':
        return 'Технические проблемы';
      case 'CANCELED':
        return 'Отменена';
      case 'IN_PROGRESS':
        return 'Сейчас идет';
      default:
        return null;
    }
  };
  const getAppointmentItems = (reset?: boolean): void => {
    fetchManageAppointmentsItems(
      patient ? [patient.id] : [],
      doctor ? [doctor?.id] : [],
      statuses,
      serviceType,
      filter === 'payedWithPromo' ? true : null,
      date.start,
      date.end,
      reset ? 0 : page + 1,
      pageSize,
      reset
    );
  };
  const getTariffOptions = (): { key: string; value: string | any; column?: boolean }[] => {
    if (!tariff) {
      return [];
    }
    return [
      {
        key: 'Cтоимость',
        value: (tariff.price || 0).toString(),
      },
      {
        key: 'Доступные типы специализации',
        column: true,
        value: (tariff.specializations || [])
          .map((spec) => spec.name)
          .filter((v) => v)
          .join(', '),
      },
      {
        key: 'Доступные типы связи',
        column: true,
        value: (tariff.channel ? [tariff.channel] : [])
          .map((type) => {
            switch (type.value) {
              case 'CHAT':
                return 'Чат';
              case 'PHONE':
                return 'Чат/Аудио';
              case 'VIDEO':
                return 'Чат/Аудио/Видео';
              default:
                return null;
            }
          })
          .filter((v) => v)
          .join(', '),
      },
      {
        key: 'Консультаций в день',
        value: (tariff.appointmentsPerDay || 0).toString(),
      },
      {
        key: 'Прикрепление файлов',
        value: tariff.withFiles ? 'Да' : 'Нет',
      },
    ];
  };
  return (
    <>
      <Box className={classNames(styles.ReportsPageAdminAppointmentItems)}>
        <Box
          className={classNames(
            styles.ReportsPageAdminAppointmentItems_Section,
            styles.ReportsPageAdminAppointmentItems_Toolbar
          )}
        >
          <Box className={classNames(styles.ReportsPageAdminAppointmentItems_Toolbar_Types)}>
            <Box width="100%">
              <SelectInput
                block
                value={types.find((t) => t.value === type)}
                options={types}
                placeholder="Тип документа"
                onChange={(event) => onChangeType(event.value)}
              />
            </Box>
            <Button
              size="md"
              isLoading={loadingReport}
              disabled={loadingReport}
              className="ml-5"
              color="primary"
              onClick={onGetAdminAppointmentReport}
            >
              Скачать в Excel
            </Button>
          </Box>
          <Box className={classNames(styles.ReportsPageAdminAppointmentItems_Toolbar_Filters)}>
            <Box className={classNames(styles.ReportsPageAdminAppointmentItems_Toolbar_Search)}>
              <SearchPatients placeholder="Введите ФИО пациента" value={patient} onChange={setPatient} />
            </Box>
            <Box className={classNames(styles.ReportsPageAdminAppointmentItems_Toolbar_Divider)}>&nbsp;</Box>
            <Box className={classNames(styles.ReportsPageAdminAppointmentItems_Toolbar_Search)}>
              <SearchDoctors placeholder="Введите ФИО врача" value={doctor} onChange={setDoctor} />
            </Box>
            <Box className={styles.ReportsPageAdminAppointmentItems_Toolbar_Buttons}>
              <DropdownMenu
                isOpen={toolbarOpenServiceTypes}
                toggle={() => toggleToolbarOpenServiceTypes(!toolbarOpenServiceTypes)}
              >
                <DropdownToggle>
                  <Button color="primary" size="md">
                    <Box display="flex" alignItems="center">
                      Типы ({serviceType.length})
                    </Box>
                    {serviceType.length > 0 && (
                      <Box className={styles.ReportsPageAdminAppointmentItems_Toolbar_Buttons_Status} />
                    )}
                  </Button>
                </DropdownToggle>
                <DropdownContent className={styles.ReportsPageAdminAppointmentItems_Toolbar_DropdownContent}>
                  <ul>
                    {serviceTypes.map((_status: any, index: number) => (
                      <li
                        key={index}
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          if (serviceType.indexOf(_status.value) > -1) {
                            setServiceTypes(serviceType.filter((s) => s !== _status.value));
                          } else {
                            setServiceTypes([...serviceType, _status.value]);
                          }
                        }}
                      >
                        <Box>{_status.label}</Box>
                        <CheckIcon
                          className={classNames(
                            styles.ReportsPageAdminAppointmentItems_Toolbar_DropdownContent_Icon,
                            serviceType.indexOf(_status.value) === -1 &&
                              styles.ReportsPageAdminAppointmentItems_Toolbar_DropdownContent_Icon_Hidden
                          )}
                        />
                      </li>
                    ))}
                  </ul>
                  {serviceType.length > 0 && (
                    <Box className={styles.ReportsPageAdminAppointmentItems_Toolbar_DropdownContent_Reset}>
                      <Button
                        color="default"
                        block
                        size="md"
                        onClick={(event) => {
                          event.preventDefault();
                          setServiceTypes([]);
                        }}
                      >
                        Сбросить фильтр
                      </Button>
                    </Box>
                  )}
                </DropdownContent>
              </DropdownMenu>
              <DropdownMenu isOpen={toolbarOpenStatus} toggle={() => toggleToolbarOpenStatus(!toolbarOpenStatus)}>
                <DropdownToggle>
                  <Button color="primary" size="md">
                    <Box display="flex" alignItems="center">
                      <TuneIcon />
                    </Box>
                    {statuses.length > 0 && (
                      <Box className={styles.ReportsPageAdminAppointmentItems_Toolbar_Buttons_Status} />
                    )}
                  </Button>
                </DropdownToggle>
                <DropdownContent className={styles.ReportsPageAdminAppointmentItems_Toolbar_DropdownContent}>
                  <ul>
                    {_statuses.map((_status: Manage.Doctor.AvailabilityType, index: number) => (
                      <li
                        key={index}
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          if (statuses.indexOf(_status) > -1) {
                            setStatuses(statuses.filter((s) => s !== _status));
                          } else {
                            setStatuses([...statuses, _status]);
                          }
                        }}
                      >
                        <Box>
                          <Box
                            className={classNames(
                              styles.ReportsPageAdminAppointmentItems_Toolbar_DropdownContent_Status,
                              styles[`ReportsPageAdminAppointmentItems_Toolbar_DropdownContent_Status_${_status}`]
                            )}
                          />
                          <Box display="flex" flex="1 1 auto" style={{ width: 'calc(100% - 12px)' }} pl={2}>
                            {getLabelStatus(_status)}
                          </Box>
                        </Box>
                        <CheckIcon
                          className={classNames(
                            styles.ReportsPageAdminAppointmentItems_Toolbar_DropdownContent_Icon,
                            statuses.indexOf(_status) === -1 &&
                              styles.ReportsPageAdminAppointmentItems_Toolbar_DropdownContent_Icon_Hidden
                          )}
                        />
                      </li>
                    ))}
                  </ul>
                  {statuses.length > 0 && (
                    <Box className={styles.ReportsPageAdminAppointmentItems_Toolbar_DropdownContent_Reset}>
                      <Button
                        color="default"
                        block
                        size="md"
                        onClick={(event) => {
                          event.preventDefault();
                          setStatuses([]);
                        }}
                      >
                        Сбросить фильтр
                      </Button>
                    </Box>
                  )}
                </DropdownContent>
              </DropdownMenu>
              <DropdownMenu isOpen={toolbarDateOpen} toggle={() => toggleToolbarDateOpen(!toolbarDateOpen)}>
                <DropdownToggle>
                  <Button color="primary" size="md">
                    <EventIcon />
                    {(date.start || date.end) &&
                      [date.start, date.end]
                        .filter((v) => v)
                        .map((v) => format(v, 'dd.MM.yyyy'))
                        .join(' - ')}
                    {(date.start || date.end) && (
                      <Box className={styles.ReportsPageAdminAppointmentItems_Toolbar_Buttons_Status} />
                    )}
                  </Button>
                </DropdownToggle>
                <DropdownContent
                  className={classNames(
                    styles.ReportsPageAdminAppointmentItems_Toolbar_DropdownContent,
                    styles.ReportsPageAdminAppointmentItems_Toolbar_DropdownContent_Calendar
                  )}
                >
                  <CalendarRange date={date} onChangeDate={(date: { start: Date; end: Date }) => setDate(date)} />
                  {(date.start || date.end) && (
                    <Box className={styles.ReportsPageAdminAppointmentItems_Toolbar_DropdownContent_Reset}>
                      <Button
                        color="default"
                        block
                        size="md"
                        onClick={(event) => {
                          event.preventDefault();
                          setDate({ start: null, end: null });
                        }}
                      >
                        Сбросить фильтр
                      </Button>
                    </Box>
                  )}
                </DropdownContent>
              </DropdownMenu>
            </Box>
          </Box>
        </Box>

        <Box className={classNames(styles.ReportsPageAdminAppointmentItems_Toolbar_Filters_Buttons)}>
          <Button
            className="mr-2"
            size="sm"
            color={filter === 'all' ? 'primary' : 'default'}
            onClick={() => {
              filter !== 'all' && setFilter('all');
            }}
          >
            Все
          </Button>
          <Button
            size="sm"
            color={filter === 'payedWithPromo' ? 'primary' : 'default'}
            onClick={() => {
              filter !== 'payedWithPromo' && setFilter('payedWithPromo');
            }}
          >
            Тариф МФО
          </Button>
        </Box>
        <Box
          className={classNames(
            styles.ReportsPageAdminAppointmentItems_Section,
            styles.ReportsPageAdminAppointmentItems_Content
          )}
        >
          <InfiniteScroll
            className={styles.ReportsPageAdminAppointmentItems_InfiniteScroll}
            pageStart={0}
            hasMore={loading ? false : hasMore}
            initialLoad={false}
            threshold={pageSize}
            loadMore={() => getAppointmentItems()}
            loader={null}
          >
            <>
              {items.length === 0 && !(loading || errors) && <Box>Консультаций не найдено</Box>}
              {items.length > 0 && (
                <Box className={classNames(styles.ReportsPageAdminAppointmentItems_Items)}>
                  {items.map((item: Manage.AppointmentItem.Item, index: number) => (
                    <Box className={classNames(styles.ReportsPageAdminAppointmentItems_Items_Item)} key={index}>
                      <Box className={classNames(styles.ReportsPageAdminAppointmentItems_Items_Item_Name)}>
                        {item.patient.firstName} {item.patient.lastName} {item.patient.middleName}
                      </Box>
                      {item.type && (
                        <Box className={classNames(styles.ReportsPageAdminAppointmentItems_Items_Item_Label)}>
                          Тип консультации: {item.type.name || '-'}
                        </Box>
                      )}
                      {item.payedWithPromo === true && item.servicePackage && (
                        <Box className={classNames(styles.ReportsPageAdminAppointmentItems_Items_Item_Tariff)}>
                          Тариф:{' '}
                          <a
                            href="#"
                            onClick={(event) => {
                              event.preventDefault();
                              setTariff(mfoItems.find((t) => t.id === item.servicePackage.id));
                            }}
                          >
                            {item.servicePackage.name}
                          </a>
                        </Box>
                      )}
                      {item.payedWithPromo === false && item.tariff && (
                        <Box className={classNames(styles.ReportsPageAdminAppointmentItems_Items_Item_Tariff)}>
                          Внесенные средства: {`${item.price}`} &#8381; {`(${item.tariff.name})`}
                        </Box>
                      )}
                      <Box className={classNames(styles.ReportsPageAdminAppointmentItems_Items_Item_Info)}>
                        Информация о консультации
                      </Box>
                      {item.startTime && (
                        <Box className={classNames(styles.ReportsPageAdminAppointmentItems_Items_Item_Label)}>
                          Дата: {format(parseISO(item.startTime.toString()), 'dd.MM.yyyy')}
                        </Box>
                      )}
                      {item.doctor && (
                        <Box className={classNames(styles.ReportsPageAdminAppointmentItems_Items_Item_Label)}>
                          Врач: {item.doctor.firstName} {item.doctor.lastName} {item.doctor.middleName}
                        </Box>
                      )}
                      {item.specialization && (
                        <Box className={classNames(styles.ReportsPageAdminAppointmentItems_Items_Item_Label)}>
                          Специализация: {item.specialization.name}
                        </Box>
                      )}
                      {item.factDurationSeconds >= 0 && (
                        <Box className={classNames(styles.ReportsPageAdminAppointmentItems_Items_Item_Label)}>
                          Продолжительность: {Math.round(item.factDurationSeconds / 60)} мин.
                        </Box>
                      )}
                      {item.status && (
                        <Box className={classNames(styles.ReportsPageAdminAppointmentItems_Items_Item_Label)}>
                          Статус:{' '}
                          <Box
                            className={classNames(
                              styles.ReportsPageAdminAppointmentItems_Items_Item_Status,
                              styles[`ReportsPageAdminAppointmentItems_Items_Item_Status_${item.status.value}`]
                            )}
                          >
                            {getLabelStatus(item.status.value as string)}
                          </Box>
                        </Box>
                      )}
                      {item.conclusionUrl && (
                        <Box className={classNames(styles.ReportsPageAdminAppointmentItems_Items_Item_Label)}>
                          <AppointmentConclusionLinkView
                            url={item.conclusionUrl}
                            numberOfDay={item.numberOfDay}
                            isDaily={item.serviceType?.value === 'DAILY'}
                          />
                        </Box>
                      )}
                    </Box>
                  ))}
                </Box>
              )}
              {(loading || errors) && (
                <Box width="100%" p={2}>
                  <ContentLoading isLoading={loading} isError={errors} fetchData={() => getAppointmentItems()} />
                </Box>
              )}
            </>
          </InfiniteScroll>
        </Box>
      </Box>
      {tariff && (
        <AdminServicePackageView name={tariff.name} options={getTariffOptions()} onClose={() => setTariff(null)} />
      )}
    </>
  );
};

export default connect(
  (state: any) => ({
    ...(state.manageAppointmentsItems as ManageAppointmentsItemsInitialSettings),
  }),
  {
    fetchManageAppointmentsItems,
    fetchManageServicePackagesMfo,
  }
)(ReportsPageAdminAppointmentItems);
