import React, { useEffect, useState } from 'react';
import { Avatar, Backdrop, Box, Dialog, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import { AxiosResponse } from 'axios';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import InfiniteScroll from 'react-infinite-scroller';
import styles from './AdminDoctorSelect.module.sass';
import ContentCard from '../ContentCard';
import InputField from '../Forms/InputField';
import { decodeAvatarResource } from '../../utils';
import ContentLoading from '../ContentLoading';
import Button from '../Forms/Button';
import Patient from '../../types/patient';
import CustomCheckbox from '../Forms/CustomCheckbox';
import { findDoctors } from '../../api';

interface AppointmentDoctorViewInterface {
  onSelect: (data: any) => void;
  onClose: () => void;
}

const AdminDoctorSelect = (props: AppointmentDoctorViewInterface) => {
  const { onClose = () => {}, onSelect = (data: any) => {} } = props;
  const [loading, setLoading] = useState<boolean>(true);
  const [errors, setErrors] = useState<any>(null);
  const [query, setQuery] = useState<string>(null);
  const [doctor, setDoctor] = useState<any>(null);
  const [doctors, setDoctors] = useState<any>([]);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(50);
  const [hasMore, setHasMore] = useState(false);
  useEffect(() => {
    if (!query) {
      getDoctors(true);
    }
    const timer: NodeJS.Timeout = !query ? null : setTimeout(() => getDoctors(true), 500);
    return () => {
      timer && clearTimeout(timer);
    };
  }, [query]);
  const submit = (e) => {
    e.preventDefault();
    doctor && send();
  };
  const getDoctors = (reset?: boolean): void => {
    setLoading(true);
    findDoctors(query, null, reset ? 0 : page + 1, pageSize)
      .then((response: AxiosResponse<Patient.PatientAutocomplete.Response>) => {
        setLoading(false);
        if (response.data.code === 'success') {
          setDoctors(response.data.data.items);
          setPage(response.data.data.page);
          setPageSize(response.data.data.pageSize);
          setHasMore(
            (response.data.data.page + 1) * response.data.data.pageSize < response.data.data.totalItems &&
              response.data.data.totalItems > 0
          );
        } else {
          setErrors(response.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        setErrors(err);
      });
  };
  const send = (): void => {
    onSelect(doctor);
  };
  return (
    <Dialog
      PaperProps={{ className: classNames(styles.Dialog) }}
      BackdropComponent={(props) => <Backdrop className={styles.Backdrop} {...props} />}
      onClose={onClose}
      open
    >
      <ContentCard className={styles.AdminMfoSend}>
        <ContentCard.Header className={styles.AdminMfoSend_Header}>
          <h6 className="page-header-title">Добавить врача</h6>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            top="0"
            right="0"
            width="46px"
            height="46px"
            lineHeight="46px"
            style={{ cursor: 'pointer' }}
            position="absolute"
            onClick={onClose}
          >
            <CloseIcon className={styles.AdminMfoSend_CloseIcon} />
          </Box>
        </ContentCard.Header>
        <ContentCard.Main className={styles.AdminMfoSend_Content}>
          <Box className={classNames(styles.AdminMfoSend_Content_Item)}>
            <form onSubmit={() => getDoctors(true)} className={styles.AdminMfoSend_Content_Search}>
              <SearchIcon className={styles.AdminMfoSend_Content_Search_Icon} />
              <InputField
                value={query}
                onChange={(e) => {
                  setQuery(e.target.value);
                }}
                block
                placeholder="Введите ФИО врача или специализацию"
              />
              {query && (
                <ClearIcon
                  onClick={() => {
                    setQuery('');
                  }}
                  className={classNames(
                    styles.AdminMfoSend_Content_Search_Icon,
                    styles.AdminMfoSend_Content_Search_Icon_Clear
                  )}
                />
              )}
            </form>
          </Box>
          <InfiniteScroll
            className={classNames(styles.AdminMfoSend_Content_Item, styles.AdminMfoSend_Content_Patients)}
            pageStart={0}
            hasMore={loading ? false : hasMore}
            initialLoad={false}
            threshold={pageSize}
            loadMore={() => getDoctors()}
            loader={null}
          >
            <>
              {!(loading && errors) && query && query.length && doctors.length === 0 && (
                <Box className={styles.AdminMfoSend_Label}>По вашему запросу ничего не найдено</Box>
              )}
              {doctors.map((patient: any, index: number) => (
                <Box key={index} className={classNames(styles.AdminMfoSend_Content_Patients_Patient)}>
                  <Box className={classNames(styles.AdminMfoSend_Content_Patients_Patient_Profile)}>
                    <Avatar
                      className={styles.AdminMfoSend_Content_Patients_Patient_Profile_Img}
                      src={decodeAvatarResource(patient.avatar, 360, 'MALE')}
                    />
                    <Box className={classNames(styles.AdminMfoSend_Content_Patients_Patient_Profile_Name)}>
                      <Box component="span">
                        {patient.firstName} {patient.lastName}
                      </Box>
                      {patient.specialization?.name && (
                        <Box component="span" color="#B3BABD">
                          {patient.specialization?.name}
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <CustomCheckbox
                    className={styles.AdminMfoSend_Content_Patients_Patient_CustomCheckbox}
                    checked={doctor?.id === patient.id}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setDoctor(patient);
                      } else {
                        setDoctor(null);
                      }
                    }}
                  />
                </Box>
              ))}
              {(loading || errors) && (
                <ContentLoading isLoading={loading} isError={errors} fetchData={() => getDoctors()} />
              )}
            </>
          </InfiniteScroll>
          <Box width="100%" display="flex" justifyContent="space-between" alignItems="center" p={2}>
            <Grid container item spacing={1} xs={12} lg={6}>
              <Button color="default" block onClick={onClose}>
                Отмена
              </Button>
            </Grid>
            <Grid container item spacing={1} xs={12} lg={6}>
              <Button type="submit" disabled={!doctor} color="primary" onClick={submit} block>
                Добавить
              </Button>
            </Grid>
          </Box>
        </ContentCard.Main>
      </ContentCard>
    </Dialog>
  );
};
export default AdminDoctorSelect;
