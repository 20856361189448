import React, { useEffect, useState } from 'react';
import ContentCard from 'components/ContentCard';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Box } from '@material-ui/core';
import {
  isAfter,
  isBefore,
  addDays,
  endOfWeek,
  startOfWeek,
  endOfMonth,
  startOfMonth,
  startOfDay,
  setMinutes,
  setSeconds,
  differenceInHours,
  endOfDay,
  addHours,
  isSameDay,
  isWithinInterval,
  isSameHour,
  parseISO,
} from 'date-fns';

import { toast } from 'react-toastify';
import { ru } from 'date-fns/locale';
import { AxiosResponse } from 'axios';
import Button from '../../../../components/Forms/Button';
import { ViewSchedulerDoctor } from '../../../../store/actions';
import Calendar from '../../../../components/Calendar';
import { ReactComponent as AngleLeftIcon } from '../../../../assets/img/icons/angle-left-icon.svg';
import { ReactComponent as AngleRightIcon } from '../../../../assets/img/icons/angle-right-icon.svg';
import Scheduler from '../../../../components/Scheduler';
import { format } from '../../../../utils/date';
import SchedulerEdit from '../../../../components/SchedulerEdit';
import Manage from '../../../../types/manage';
import styles from './SchedulerDoctorPageAdmin.module.sass';
import { getDoctorDetails } from '../../../../api';
import ContentLoading from '../../../../components/ContentLoading';
import { findWorkShiftsAdmin } from '../../../../api/findWorkShiftsAdmin';
import SchedulerDelete from '../../../../components/SchedulerDelete';
import { deleteWorkShift } from '../../../../api/deleteWorkShift';
import { createWorkShift } from '../../../../api/createWorkShift';
import { repeatWorkShiftWeekly } from '../../../../api/repeatWorkShiftWeekly';
import { getDisplayName } from '../../../../utils';
import { getDoctorFutureSchedule } from '../../../../api/getDoctorFutureSchedule';

interface PropsInterface {
  match: any;
  history: any;
}
const SchedulerDoctorPageAdmin = (props: PropsInterface): JSX.Element => {
  const { match, history } = props;
  const [loading, setLoading] = useState<boolean>(true);
  const [errors, setErrors] = useState<any>(null);
  const [loadingScheduler, setLoadingScheduler] = useState<boolean>(true);
  const [errorsScheduler, setErrorsScheduler] = useState<any>(null);
  const [loadingSchedulerAction, setLoadingSchedulerAction] = useState<boolean>(false);
  const [view, setView] = useState<ViewSchedulerDoctor>(ViewSchedulerDoctor.WEEK);
  const [date, setDate] = useState<Date>(new Date());
  const [modelAdd, setModelAdd] = useState<any>(null);
  const [modelDel, setModelDel] = useState<any>(null);
  const [schedules, setSchedules] = useState<Manage.Schedule.Item[]>([]);
  const [appointments, setAppointments] = useState<Manage.Appointment.Item[]>([]);
  const [doctor, setDoctor] = useState<Manage.Doctor.Item>(null);
  useEffect(() => {
    init();
  }, []);
  useEffect(() => {
    doctor && loadData();
  }, [view, date, doctor]);

  const loadData = (): void => {
    setLoadingScheduler(true);
    let timeFrom: Date = startOfDay(date);
    let timeTo: Date = endOfDay(date);
    if (view === ViewSchedulerDoctor.WEEK) {
      timeFrom = startOfDay(startOfWeek(date, { locale: ru, weekStartsOn: 1 }));
      timeTo = endOfDay(endOfWeek(date, { locale: ru, weekStartsOn: 1 }));
    }
    Promise.all([
      findWorkShiftsAdmin(doctor.id, timeFrom, timeTo),
      getDoctorFutureSchedule(doctor.id, timeFrom, timeTo),
    ])
      .then((response: AxiosResponse<any>[]) => {
        if (response[0].data.code === 'success') {
          setSchedules(response[0].data.data.items);
        } else {
          throw new Error();
        }
        if (response[1].data.code === 'success') {
          setAppointments(
            (response[1].data.data.items || []).map((a) => ({
              ...a,
              startTime: parseISO(a.startTime),
            }))
          );
        } else {
          throw new Error();
        }
        setLoadingScheduler(false);
      })
      .catch((err) => {
        setLoadingScheduler(false);
        setErrorsScheduler(err);
      });
  };

  const init = (): void => {
    setLoading(true);
    getDoctorDetails(match.params.doctorId, true)
      .then((response: AxiosResponse<any>) => {
        if (response.data.code === 'success') {
          setDoctor(response.data.data);
        } else {
          throw new Error();
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setErrors(err);
      });
  };

  const onCloseEditSchedulerDialog = (): void => {
    setModelAdd(null);
  };

  const onCloseDeleteSchedulerDialog = (): void => {
    setModelDel(null);
  };

  const onChangeView = (view: ViewSchedulerDoctor) => {
    setView(view);
  };

  const onChangeDate = (date: Date) => {
    setDate(date);
  };

  const changeMonth = (amount: number): void => {
    amount = view === ViewSchedulerDoctor.TODAY ? amount : amount * 7;
    setDate(addDays(date, amount));
  };

  const getLabel = (): string => {
    if (view === ViewSchedulerDoctor.WEEK) {
      const _startOdDay = startOfDay(date);
      const _endOfWeek: Date = endOfWeek(_startOdDay, { weekStartsOn: 1 });
      const _startOfWeek: Date = startOfWeek(_startOdDay, { weekStartsOn: 1 });
      if (isAfter(startOfMonth(_startOdDay), _startOfWeek)) {
        return `${format(_startOfWeek, 'MMMM')} - ${format(date, 'MMMM')} ${format(date, 'yyyy')}`;
      }
      if (isBefore(endOfMonth(_startOdDay), _endOfWeek)) {
        return `${format(date, 'MMMM')} - ${format(_endOfWeek, 'MMMM')} ${format(date, 'yyyy')}`;
      }
    }
    return format(date, 'MMMM yyyy');
  };

  const onSaveScheduler = (scheduler: any, allMonth?: boolean): any => {
    setLoadingSchedulerAction(true);
    if (
      schedules
        .map((item: Manage.Schedule.Item) => ({
          id: item.id,
          doctorId: item.doctor.id,
          endTime: parseISO(item.endTime.toString()),
          startTime: parseISO(item.startTime.toString()),
        }))
        .filter((s: any) => isSameDay(s.startTime, scheduler.startTime))
        .map((s: any) => ({
          ...s,
          startTime: setSeconds(s.startTime, 0),
          endTime: setSeconds(s.startTime, 0),
        }))
        .some(
          (s: any) =>
            (isWithinInterval(s.startTime, {
              start: scheduler.startTime,
              end: scheduler.endTime,
            }) &&
              !isSameHour(s.startTime, scheduler.startTime) &&
              !isSameHour(s.startTime, scheduler.endTime)) ||
            (isWithinInterval(s.endTime, {
              start: scheduler.startTime,
              end: scheduler.endTime,
            }) &&
              !isSameHour(s.endTime, scheduler.startTime) &&
              !isSameHour(s.endTime, scheduler.endTime))
        )
    ) {
      setLoadingSchedulerAction(false);
      toast.error(
        'Выбранный интервал имеет пересечение. Попробуйте изменить дату начала и дату завершения или выбрать другой день'
      );
    } else {
      createWorkShift(doctor.id, scheduler.startTime, scheduler.endTime)
        .then((response: AxiosResponse<any>) => {
          if (response.data.code === 'success') {
            if (allMonth && response.data.data.id) {
              return repeatWorkShiftWeekly(response.data.data.id);
            }
          } else {
            throw new Error();
          }
        })
        .then(() => {
          setLoadingSchedulerAction(false);
          setModelAdd(null);
          loadData();
        })
        .catch(() => {
          setLoadingSchedulerAction(false);
          toast.error('Ошибка при запросе');
        });
    }
  };

  const onDeleteScheduler = (scheduler: any): void => {
    setLoadingSchedulerAction(true);
    deleteWorkShift(scheduler.id)
      .then((response: AxiosResponse<any>) => {
        if (response.data.code === 'success') {
          setLoadingSchedulerAction(false);
          setModelDel(null);
          loadData();
        } else {
          throw new Error();
        }
      })
      .catch(() => {
        setLoadingSchedulerAction(false);
        toast.error('Ошибка при запросе');
      });
  };

  const addScheduler = (): void => {
    const startTime: Date = addHours(setMinutes(setSeconds(new Date(), 0), 0), 1);
    setModelAdd({
      id: null,
      startTime,
      endTime: differenceInHours(startTime, endOfDay(new Date())) >= -1 ? endOfDay(startTime) : addHours(startTime, 2),
    });
  };

  return (
    <>
      <ContentCard className={styles.SchedulerDoctorPageAdmin}>
        <ContentCard.Header>
          <div className="row">
            <div className="col-xs-4 text-center text-xs-left mb-2 mb-xs-0">
              <div className="page-header-back-link" onClick={() => history.push('/doctors')}>
                <AngleLeftIcon className="page-header-back-link-icon" />
                Назад
              </div>
            </div>
            <div className="col-xs-8 col-md-4 text-center text-xs-right text-md-center">
              <h6 className="page-header-title">
                Расписание {doctor ? `для ${getDisplayName(doctor.firstName, doctor.lastName, doctor.middleName)}` : ''}
              </h6>
            </div>
          </div>
        </ContentCard.Header>
        <ContentCard.Main className={styles.SchedulerDoctorPageAdmin_Content}>
          <Box
            width="100%"
            p={loading || errors ? 5 : 0}
            className={`d-flex flex-column flex-lg-row ${
              loading || errors
                ? 'justify-content-center align-items-center'
                : 'justify-content-between align-items-stretch'
            }`}
          >
            <ContentLoading isLoading={loading} isError={errors} fetchData={() => init()}>
              {doctor && (
                <>
                  <div className="col-12 col-lg-4 col-md-3 p-0">
                    <Box
                      className={classNames(
                        styles.SchedulerDoctorPageAdmin_Content_Grid,
                        styles.SchedulerDoctorPageAdmin_Sidebar
                      )}
                    >
                      <Box width="100%" p={1}>
                        <Calendar
                          className={styles.SchedulerDoctorPageAdmin_calendar}
                          date={date}
                          onChangeDate={onChangeDate}
                        />
                      </Box>
                      <Box
                        width="100%"
                        borderBottom={1}
                        borderTop={1}
                        p={1}
                        pt={2}
                        pb={2}
                        className={styles.SchedulerDoctorPageAdmin_Content_Grid}
                      >
                        <Button type="button" block color="primary" onClick={addScheduler}>
                          Создать
                        </Button>
                      </Box>
                    </Box>
                  </div>
                  <div className="col-12 col-lg-8 col-md-9 d-flex align-items-start flex-column p-0">
                    <Box
                      className={classNames(
                        styles.SchedulerDoctorPageAdmin_Content_Grid,
                        styles.SchedulerDoctorPageAdmin_Toolbar
                      )}
                      width="100%"
                      p={2}
                      borderBottom={1}
                      flexDirection="row"
                      justifyContent="space-between"
                      alignContent="center"
                      display="flex"
                    >
                      <Button
                        type="button"
                        size="sm"
                        onClick={() => !isSameDay(new Date(), date) && onChangeDate(new Date())}
                        color="primary"
                      >
                        Сегодня
                      </Button>
                      <Box
                        className={styles.SchedulerDoctorPageAdmin_Toolbar_Date}
                        flexDirection="row"
                        justifyContent="space-between"
                        alignContent="center"
                        display="flex"
                      >
                        <button
                          type="button"
                          className={styles.SchedulerDoctorPageAdmin_Toolbar_Date_Nav}
                          onClick={() => changeMonth(-1)}
                        >
                          <AngleLeftIcon className={styles.SchedulerDoctorPageAdmin_Toolbar_Date_Nav_Icon} />
                        </button>
                        <Box
                          className={styles.SchedulerDoctorPageAdmin_Toolbar_Date_Nav_Label}
                          alignSelf="center"
                          ml={2}
                          mr={2}
                        >
                          {getLabel()}
                        </Box>
                        <button
                          type="button"
                          className={styles.SchedulerDoctorPageAdmin_Toolbar_Date_Nav}
                          onClick={() => changeMonth(1)}
                        >
                          <AngleRightIcon className={styles.SchedulerDoctorPageAdmin_Toolbar_Date_Nav_Icon} />
                        </button>
                      </Box>
                      <Button
                        type="button"
                        size="sm"
                        onClick={() =>
                          onChangeView(
                            view === ViewSchedulerDoctor.WEEK ? ViewSchedulerDoctor.TODAY : ViewSchedulerDoctor.WEEK
                          )
                        }
                        color={view === ViewSchedulerDoctor.WEEK ? 'default' : 'primary'}
                      >
                        {view === ViewSchedulerDoctor.WEEK ? 'День' : 'Неделя'}
                      </Button>
                    </Box>
                    <Box
                      width="100%"
                      display="flex"
                      flex="1 1 auto"
                      justifyContent="center"
                      alignItems="center"
                      flexDirection="row"
                    >
                      <ContentLoading
                        isLoading={loadingScheduler}
                        isError={errorsScheduler}
                        fetchData={() => loadData()}
                      >
                        <Scheduler
                          isAdmin
                          appointments={appointments}
                          scheduler={schedules.map((item: Manage.Schedule.Item) => ({
                            id: item.id,
                            doctorId: item.doctor.id,
                            endTime: parseISO(item.endTime.toString()),
                            startTime: parseISO(item.startTime.toString()),
                          }))}
                          onClickCell={(data: { id: string; endTime: Date; startTime: Date }) => {
                            setModelAdd({
                              id: null,
                              startTime: data.startTime,
                              endTime: data.endTime,
                              doctorId: doctor.id,
                            });
                          }}
                          onDelete={(data: { id: string; endTime: Date; startTime: Date }) => {
                            data.id &&
                              setModelDel({
                                id: data.id,
                                startTime: data.startTime,
                                endTime: data.endTime,
                                doctorId: doctor.id,
                              });
                          }}
                          date={date}
                          onChangeDate={(date: Date) => onChangeDate(date)}
                          view={view === ViewSchedulerDoctor.WEEK ? 'WEEK' : 'TODAY'}
                        />
                      </ContentLoading>
                    </Box>
                  </div>
                </>
              )}
            </ContentLoading>
          </Box>
        </ContentCard.Main>
      </ContentCard>
      {modelAdd !== null && (
        <SchedulerEdit
          isAdmin
          loading={loadingSchedulerAction}
          scheduler={modelAdd}
          onSave={onSaveScheduler}
          onClose={onCloseEditSchedulerDialog}
        />
      )}
      {modelDel !== null && (
        <SchedulerDelete
          isAdmin
          loading={loadingSchedulerAction}
          scheduler={modelDel}
          onDelete={onDeleteScheduler}
          onClose={onCloseDeleteSchedulerDialog}
        />
      )}
    </>
  );
};
export default SchedulerDoctorPageAdmin;
