import { createEffect, Effect } from 'effector';
import { useMemo } from 'react';

const useApiFx = <P = any, D = any, E = Error>(promise: (...args: any[]) => Promise<D>): Effect<P, D, E> => {
  return useMemo(
    (): Effect<P, D, E> =>
      createEffect<P, D, E>(async (args: P) => {
        const [arg = null, ...rest] = Object.values(args || {});
        return await promise.call(promise, arg, ...rest);
      }),
    [promise]
  );
};
export default useApiFx;
