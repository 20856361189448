import {
  SET_PHONE_LOGIN_FORM,
  SET_PHONE_LOGIN_ERRORS,
  SET_CODE_CONFIRM_FORM,
  SET_CODE_CONFIRM_ERRORS,
  USER_LOGIN,
  USER_LOGOUT,
  SET_AUTH_CHECKING,
  SET_USER_DATA,
  SET_LOGIN_SCREEN,
  LOGIN_LOADING,
  SET_EMAIL_LOGIN_FORM,
  SET_EMAIL_LOGIN_ERRORS,
  INVITE_LOADING,
} from '../actions';
import { UserRole } from '../../types';

const initialState = {
  phoneLoginForm: {
    phone: '',
  },
  emailLoginForm: {
    email: '',
    password: '',
  },
  phoneLoginFormErrors: [],
  emailLoginFormErrors: [],
  codeConfirmForm: {
    code: '',
  },
  codeConfirmFormErrors: [],
  loginLoading: false,
  inviteLoading: false,
  isAuthenticated: false,
  userToken: '',
  role: '',
  isDoctor: false,
  permissions: [],
  userData: {},
  loginScreen: 'LOGIN',
  isAuthChecking: true, // default true when app loads
};

const authenticationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PHONE_LOGIN_FORM:
      return {
        ...state,
        phoneLoginForm: { ...action.form },
      };

    case SET_PHONE_LOGIN_ERRORS:
      return {
        ...state,
        phoneLoginFormErrors: action.errors ? [...action.errors] : action.errors,
      };

    case SET_EMAIL_LOGIN_FORM:
      return {
        ...state,
        emailLoginForm: { ...action.form },
      };

    case SET_EMAIL_LOGIN_ERRORS:
      return {
        ...state,
        emailLoginFormErrors: action.errors ? [...action.errors] : action.errors,
      };

    case SET_CODE_CONFIRM_FORM:
      return {
        ...state,
        codeConfirmForm: { ...action.form },
      };

    case SET_CODE_CONFIRM_ERRORS:
      return {
        ...state,
        codeConfirmFormErrors: action.errors ? [...action.errors] : action.errors,
      };

    case SET_AUTH_CHECKING:
      return {
        ...state,
        isAuthChecking: action.isAuthChecking ? action.isAuthChecking : false,
      };

    case USER_LOGIN:
      return {
        ...state,
        isAuthenticated: true,
        userToken: action.token,
        role: action.role,
        isDoctor: action.role === UserRole.DOCTOR,
        permissions: action.permissions || state.permissions,
      };

    case USER_LOGOUT:
      return {
        ...initialState,
        isAuthenticated: false,
        loginPhoneCodeConfirm: false,
        loginScreen: 'LOGIN',
        isAuthChecking: false,
      };

    case SET_USER_DATA:
      return {
        ...state,
        userData: {
          ...state.userData,
          ...action.userData,
        },
      };

    case SET_LOGIN_SCREEN:
      return {
        ...state,
        loginScreen: action.screen,
      };

    case LOGIN_LOADING:
      return {
        ...state,
        loginLoading: action.isLoading,
      };
    case INVITE_LOADING:
      return {
        ...state,
        inviteLoading: action.isLoading,
      };
    default:
      return state;
  }
};

export default authenticationReducer;
