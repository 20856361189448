import React from 'react';
import classNames from 'classnames';
import { Backdrop, Dialog } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ContentCard from '../ContentCard';
import styles from './BaseDialog.module.sass';

export interface BaseDialogProps {
  onClosed: () => void;
  title: string;
}

const BaseDialog: React.FC<BaseDialogProps> = (props) => {
  const { title, onClosed, children } = props;

  const onClose = () => {
    onClosed();
  };

  return (
    <>
      <Dialog
        PaperProps={{ className: `${classNames(styles.Dialog)}` }}
        BackdropComponent={(props) => <Backdrop className={styles.Backdrop} {...props} />}
        onClose={onClose}
        open
      >
        <ContentCard className={styles.BaseDialog}>
          <ContentCard.Header className={styles.BaseDialog_Header}>
            <h6 className="page-header-title">{title}</h6>
            <CloseIcon onClick={onClose} className={styles.BaseDialog_CloseIcon} />
          </ContentCard.Header>
          <ContentCard.Main className={styles.BaseDialog_Content}>{children}</ContentCard.Main>
        </ContentCard>
      </Dialog>
    </>
  );
};

export default BaseDialog;
