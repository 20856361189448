import { AxiosResponse } from 'axios';
import Manage from '../../types/manage';
import { findTariffsAdmin } from '../../api/findTariffsAdmin';

export const MANAGE_TARIFFS_LOADING = 'MANAGE_TARIFFS_LOADING';
export const MANAGE_TARIFFS_SUCCESS = 'MANAGE_TARIFFS_SUCCESS';
export const MANAGE_TARIFFS_ERRORS = 'MANAGE_TARIFFS_ERRORS';

export const setManageTariffsLoading = (loading: boolean) => ({
  type: MANAGE_TARIFFS_LOADING,
  loading,
});

export const setManageTariffsErrors = (errors: any) => ({ type: MANAGE_TARIFFS_ERRORS, errors });

export const setManageTariffs = (tariffs: Manage.Tariff.Item[]) => ({
  type: MANAGE_TARIFFS_SUCCESS,
  tariffs,
});

export const fetchManageTariffs = (active?: boolean): any => {
  return (dispatch) => {
    dispatch(setManageTariffsLoading(true));
    findTariffsAdmin(active)
      .then((response: AxiosResponse<Manage.Tariff.Response>) => {
        dispatch(setManageTariffsLoading(false));
        if (response.data.code === 'success') {
          dispatch(setManageTariffs(response.data.data.items));
        } else {
          dispatch(setManageTariffsErrors(response.data));
        }
      })
      .catch((error: any) => {
        dispatch(setManageTariffsErrors(error));
        dispatch(setManageTariffsLoading(false));
      });
  };
};
