import { createStore, forward } from 'effector';
import { AppointmentDoctorViewType } from '../types';
import {
  onCloseAppointment,
  onGetAppointment,
  onGetIemk,
  onRestAppointment,
  onSetAppointmentStatus,
  onSetCloseModal,
  onSetViewTab,
} from './events';
import {
  originCloseAppointmentFx,
  originGetAppointmentFx,
  originGetIemkFx,
  originSetAppointmentStatusFx,
} from './effects';

export interface AppointmentDoctorStateInterface {
  loading: boolean;
  error: any;
  loadingStatus: boolean;
  errorStatus: any;
  loadingReport: boolean;
  errorReport: any;
  appointment: any;
  view: AppointmentDoctorViewType;
  showCloseModal: boolean;
  dictionaries: { [key: string]: Array<any> };
}

const defaultState: AppointmentDoctorStateInterface = {
  loading: true,
  error: null,
  loadingStatus: false,
  errorStatus: null,
  loadingReport: false,
  errorReport: null,
  appointment: null,
  dictionaries: {},
  view: AppointmentDoctorViewType.REPORT,
  showCloseModal: false,
};

const $store = createStore(defaultState);
forward({ from: onGetAppointment, to: originGetAppointmentFx });
forward({ from: onSetAppointmentStatus, to: originSetAppointmentStatusFx });
forward({ from: onCloseAppointment, to: originCloseAppointmentFx });
forward({ from: onGetIemk, to: originGetIemkFx });
$store.on(onSetViewTab, (_, view) => ({ ..._, view }));
$store.on(onRestAppointment, (_, data) => ({ ...defaultState, ...(data || {}) }));
$store.on(onSetCloseModal, (_, showCloseModal) => ({ ..._, showCloseModal }));
$store.on(originGetAppointmentFx.pending, (_, loading) => ({ ..._, loading, error: loading ? null : _.error }));
$store.on(originGetAppointmentFx.fail, (_, error) => ({ ..._, error }));
$store.on(originGetIemkFx.doneData, (_, dictionaries) => {
  return {
    ..._,
    dictionaries,
  };
});
$store.on(originGetAppointmentFx.doneData, (_, appointment) => {
  return {
    ..._,
    appointment,
  };
});
$store.on(originCloseAppointmentFx.pending, (_, loadingReport) => ({
  ..._,
  loadingReport,
  error: loadingReport ? null : _.errorReport,
}));
$store.on(originCloseAppointmentFx.fail, (_, errorReport) => ({ ..._, errorReport }));
$store.on(originCloseAppointmentFx.doneData, (_, appointment) => {
  return {
    ..._,
    appointment,
  };
});
$store.on(originSetAppointmentStatusFx.pending, (_, loadingStatus) => ({
  ..._,
  loadingStatus,
  errorStatus: loadingStatus ? null : _.errorStatus,
}));
$store.on(originSetAppointmentStatusFx.fail, (_, errorStatus) => ({ ..._, errorStatus }));
$store.on(originSetAppointmentStatusFx.doneData, (_, value) => {
  return {
    ..._,
    appointment: { ..._.appointment, status: { value } },
    showCloseModal: false,
  };
});
export default $store;
