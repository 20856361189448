import Manage from "../../types/manage";
import manageSpecGroups from "../../api/manageSpecGroups";
import {AxiosResponse} from "axios";
import {ResponseData} from "../../types";


export const DS_GROUP_LOADING = 'DS_GROUP_LOADING';
export const DS_GROUP_SUCCESS = 'DS_GROUP_SUCCESS';
export const DS_GROUP_ERRORS = 'DS_GROUP_ERRORS';
export const DS_GROUP_UPDATE = 'DS_GROUP_UPDATE';

const setLoading = (loading: boolean, reset?: boolean) => ({
  type: DS_GROUP_LOADING,
  loading,
  reset
})

const setError = (errors: any) => ({type: DS_GROUP_ERRORS, errors})

const setSpecGroups = (
  items: Array<Manage.Spec.Group>,
  page: number,
  pageSize: number,
  totalItems: number
) => ({
  type: DS_GROUP_SUCCESS,
  items,
  page,
  pageSize,
  totalItems,
});

const updateSpecGroup = (item: Manage.Spec.Group) => ({
  type: DS_GROUP_UPDATE,
  item
})

export const fetchSpecGroups = (page?: number,
                                            pageSize?: number,
                                            statuses?: Array<Manage.Spec.GroupStatus>,
                                            reset?: boolean,): any => {
  return (dispatch) => {
    dispatch(setLoading(true, reset))
    manageSpecGroups.findSpecGroups({
      page: page,
      pageSize,
      statuses,
    }).then((response: AxiosResponse<ResponseData<Manage.Spec.GroupsResponse>>) => {
      dispatch(setLoading(false));
      if (response.data.code === 'success') {
        const data = response.data.data
        dispatch(setSpecGroups(data.items, data.page, data.pageSize, data.totalItems));
      } else {
        dispatch(setError(response.data));
      }
    })
      .catch((error: any) => {
        dispatch(setError(error));
        dispatch(setLoading(false));
      });
  }
}

export const fetchSpecGroup = (id: string) => {
  return (dispatch) => {
    manageSpecGroups.getSpecGroup(id)
      .then((response: AxiosResponse<ResponseData<Manage.Spec.Group>>) => {
        if (response.data.code === 'success') {
          const data = response.data.data
          dispatch(updateSpecGroup(data));
        }
      })
  }
}
