import styles from '../AppointmentPage.module.sass';
import {format, parseISO} from "date-fns";
import {decodeAvatarResource} from "../../../utils";
import {Link} from "react-router-dom";
import Button from "../../../components/Forms/Button";
import React from "react";
import FeedbackView from "../FeedbackView";
import {AppointmentConclusionLinkView} from "../../../components/AppointmentCoclusion/AppointmentConclusionLinkView";

export type ConclusionViewProps = {
  appointment: {
    id: string,
    conclusionUrl?: string
    recommendations?: string
    feedback: { feedbackUser: any }
    doctor: {
      firstName: string,
      lastName: string,
      middleName: string,
      avatar: any
    }
    specialization: {
      id: string,
      name: string
    }
    serviceType?: {
      name: string,
      value: string
    },
    type?: {
      name: string,
      value: string
    },
    lastModifiedAt?: Date,
    rule?: {
      id: string,
      order: number
    },
    numberOfDay?: number
  }
}

const ConclusionView = (props: ConclusionViewProps) => {
  const {appointment} = props
  const {recommendations, doctor, serviceType, type, lastModifiedAt } = props.appointment

  const isDaily = type?.value === 'DAILY'

  return (
    <div className={styles.appointmentFeedback}>
      <div className={styles.appointmentFeedback_date}>Завершена {format(lastModifiedAt ? parseISO(lastModifiedAt.toString()) : new Date(), 'dd.MM.yyyy HH:mm')}</div>

      <div className={styles.appointmentInfo}>
        <div className={styles.doctorDetailsInfo_avatar}
             style={{backgroundImage: `url(${decodeAvatarResource(doctor?.avatar, 360)})`}}/>
        <div className={styles.doctorDetailsInfo_name}>{doctor?.lastName} {doctor?.firstName} {doctor?.middleName}</div>
        <div className={styles.doctorDetailsInfo_position}>{appointment?.specialization?.name}</div>

        <div className={styles.appointmentFeedback_conclusion}>
          <div className={styles.appointmentFeedback_conclusion_recommendations}>
            <AppointmentConclusionLinkView url={appointment.conclusionUrl} numberOfDay={appointment.numberOfDay} isDaily={isDaily}/>
          </div>
          <div className={styles.appointmentFeedback_conclusion_recommendations}>{recommendations}</div>
          <div className={styles.appointmentFeedback_conclusion_footer}>Врач оставил заключение и завершил
            консультацию
          </div>
        </div>

        {!isDaily && <React.Fragment>
          <Link to={`/appointments/${appointment.id}/chat`}>
            <Button
              className={styles.appointmentInfo_btn}
              color="primary"
              block
            >
              {'Открыть консультацию'}
            </Button>
          </Link>

          <FeedbackView appointment={appointment}/>
        </React.Fragment>}

      </div>
    </div>
  )

}

export default ConclusionView
