import {EnumDictionary} from "../../types/EnumDictionary";
import React, {useCallback, useEffect, useState} from "react";
import DropdownToggle from "../DropdownMenu/DropdownToggle";
import Button from "../Forms/Button";
import {Box} from "@material-ui/core";
import styles
  from "./ReportDropdownMenu.module.sass";
import DropdownContent from "../DropdownMenu/DropdownContent";
import {ReactComponent as CheckIcon} from "../../assets/img/icons/check-icon.svg";
import classNames from "classnames";
import DropdownMenu from "../DropdownMenu";

export interface ReportDropdownMenuProps {
  items: Array<EnumDictionary<string>>
  selectIndexes?: Array<number>
  isOpen: boolean
  title: string
  onToggle: (open: boolean) => void
  onSelectChanged: (values: Array<string>) => void
}

const ReportDropdownMenuComponent: React.FC<ReportDropdownMenuProps> = (props) => {

  const {items, selectIndexes = [], title, onToggle, onSelectChanged} = props

  const [isOpen, setOpen] = useState<boolean>(props.isOpen)
  const [selectedOptions, setSelectedOptions] = useState<Array<string>>(selectIndexes.map(value => items[value]?.value))

  useEffect(() => {
    setOpen(props.isOpen)
  }, [props.isOpen])


  const toggle = useCallback(() => {
    onToggle(!isOpen)
  }, [isOpen])


  return (
    <DropdownMenu
      isOpen={isOpen}
      toggle={toggle}
    >
      <DropdownToggle>
        <Button color="primary" size="md">
          <Box display="flex" alignItems="center">
            {title} ({selectedOptions.length})
          </Box>
          {selectedOptions.length > 0 && (
            <Box className={styles.ReportDropdownMenu_Buttons_Status}/>
          )}
        </Button>
      </DropdownToggle>
      <DropdownContent className={styles.ReportDropdownMenu_DropdownContent}>
        <ul>
          {items.map((value, index: number) => (
            <li
              key={`dropdown-item-${value.value}-${index}`}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                let modifyOptions
                if (selectedOptions.indexOf(value.value) > -1) {
                  modifyOptions = selectedOptions.filter((s) => s !== value.value)
                } else {
                  modifyOptions = [...selectedOptions, value.value]
                }
                onSelectChanged(modifyOptions)
                setSelectedOptions(modifyOptions)
              }}
            >
              <Box>{value.name}</Box>
              <CheckIcon
                className={classNames(
                  styles.ReportDropdownMenu_DropdownContent_Icon,
                  selectedOptions.indexOf(value.value) === -1 &&
                  styles.ReportDropdownMenu_DropdownContent_Icon_Hidden
                )}
              />
            </li>
          ))}
        </ul>
        {selectedOptions.length > 0 && (
          <Box className={styles.ReportDropdownMenu_DropdownContent_Reset}>
            <Button
              color="default"
              block
              size="md"
              onClick={(event) => {
                event.preventDefault();
                onSelectChanged([])
                setSelectedOptions([]);
              }}
            >
              Сбросить фильтр
            </Button>
          </Box>
        )}
      </DropdownContent>
    </DropdownMenu>
  )

}

export default ReportDropdownMenuComponent
