export interface CheckerFilterRequest {
  type?: CheckerType;
  parentId?: number;
}
export enum CheckerType {
  PROBLEM_AREA = 'PROBLEM_AREA',
  MAIN_SYMPTOM = 'MAIN_SYMPTOM',
  SIDE_SYMPTOM = 'SIDE_SYMPTOM',
  SYMPTOM_CHARACTERISTIC = 'SYMPTOM_CHARACTERISTIC',
  DISEASE_NAME = 'DISEASE_NAME',
}
export enum ValueResult {
  DiseaseName = 'diseaseName',
  Definition = 'definition',
  Reasons = 'reasons',
  Symptoms = 'symptoms',
  SymptomCharacteristic = 'symptomCharacteristic',
  Therapy = 'therapy',
  WhoToContact = 'whoToContact',
  MainSymptom = 'mainSymptom',
  Diagnostics = 'diagnostics',
}
export const CheckerTypeTranslate = {
  [CheckerType.PROBLEM_AREA]: 'Проблемная область',
  [CheckerType.MAIN_SYMPTOM]: 'Главный симптом',
  [CheckerType.SIDE_SYMPTOM]: 'Побочные симптомы',
  [CheckerType.SYMPTOM_CHARACTERISTIC]: 'Характеристики симптома',
};
export const CheckerTypeDescriptionTranslate = {
  [CheckerType.PROBLEM_AREA]: 'Выберите один вариант',
  [CheckerType.MAIN_SYMPTOM]: 'Выберите один вариант',
  [CheckerType.SIDE_SYMPTOM]: 'Выберите один или несколько вариантов',
  [CheckerType.SYMPTOM_CHARACTERISTIC]: 'Выберите один или несколько вариантов',
};
export interface CheckerInterface {
  [key: string]: string;
}
export interface CheckerDataInterface {
  createdAt?: Date;
  id: number;
  parentId: number;
  type: CheckerType;
  value: string;
}
export const CheckerTypeKey = {
  [CheckerType.PROBLEM_AREA]: 'problemArea',
  [CheckerType.MAIN_SYMPTOM]: 'mainSymptom',
  [CheckerType.SIDE_SYMPTOM]: 'sideSymptom',
  [CheckerType.SYMPTOM_CHARACTERISTIC]: 'symptomCharacteristic',
  [CheckerType.DISEASE_NAME]: 'diseaseName',
};
