import React, { useState } from 'react';
import ContentLoading from '../../../components/ContentLoading';
import styles from './AgentPasswordDialog.module.sass';
import InputField from '../../../components/Forms/InputField';
import Button from '../../../components/Forms/Button';
import BaseDialog from '../../../components/BaseDialog';
import FormValidator from '../../../components/Forms/FormValidator';
import { useFormValidation } from '../../../utils/hooks/useFormValidation';
import Manage from '../../../types/manage';
import manageAgent from '../../../api/agent/manageAgent';
import { displayErrors, handleHttpError } from '../../../utils/handleHttpError';

interface AgentPasswordDialogProps {
  agentId: string;
  onClosed: () => void;
}

const AgentPasswordDialog: React.FC<AgentPasswordDialogProps> = (props) => {
  const { onClosed, agentId } = props;

  const [isLoading, setLoading] = useState<boolean>(false);

  const [fields, validateOnChange, getFieldErrors, _, setFormErrors] = useFormValidation<Manage.Agent.PasswordRequest>({
    password: null,
  });

  const onSave = (e: any) => {
    e.preventDefault();

    const form = e.target;
    const inputs = [...form.elements].filter((i) => ['INPUT', 'SELECT', 'TEXTAREA'].includes(i.nodeName));

    const { errors, hasError } = FormValidator.bulkValidate(inputs);

    setFormErrors([...errors]);

    if (!hasError) {
      setLoading(true);
      const action = manageAgent.updatePassword(agentId, fields);
      action
        .then((res) => {
          onClosed();
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          const e = handleHttpError(error);
          displayErrors(e);
        });
    }
  };

  return (
    <BaseDialog onClosed={onClosed} title="Создайть пароль для агента">
      <ContentLoading isLoading={isLoading} isError={false} fetchData={() => null}>
        <div className={styles.DoctorInvitePage}>
          <p className={styles.DoctorInvitePage_title_sub}>
            Пароль должен содержать цифры, заглавные и строчные буквы, и быть не менее 8 символов
          </p>
          <form onSubmit={onSave} className={styles.DoctorInvitePage_form}>
            <InputField
              id="password"
              name="password"
              type="password"
              autocomplate="current-password"
              data-validate='["required", "password"]'
              errors={getFieldErrors('password')}
              value={fields.password}
              onChange={(e) => validateOnChange('password', e.target.value, e)}
              placeholder="Введите пароль"
              block
            />
            <Button className="mt-4" type="submit" block color="primary" isLoading={isLoading}>
              Сохранить
            </Button>
          </form>
        </div>
      </ContentLoading>
    </BaseDialog>
  );
};

export default AgentPasswordDialog;
