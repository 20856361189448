import React from 'react';
import classNames from 'classnames';

// Components
import Header from './Header';
import Main from './Main';

import styles from './ContentCard.module.sass';

const ContentCard = (props: any) => (
  <div className={classNames(styles.contentCard, {
    [props.className]: props.className
  })}>
    {props.children}
  </div>
);

ContentCard.Header = Header;
ContentCard.Main = Main;

export default ContentCard;
