import { parseISO } from 'date-fns';
import { DoctorAppointments, DoctorsEventStatus, Patient } from '../../types';
import { findDoctorAppointments } from '../../api/findDoctorAppointments';

export const DOCTOR_EVENTS_LOADING = 'DOCTOR_EVENTS_LOADING';
export const DOCTOR_EVENTS_ERROR = 'DOCTOR_EVENTS_ERROR';
export const DOCTOR_EVENTS_SUCCESS = 'DOCTOR_EVENTS_SUCCESS';
export const DOCTOR_EVENTS_RESET = 'DOCTOR_EVENTS_RESET';

export const setEventsLoading = (loading: boolean, reset?: boolean) => ({
  type: DOCTOR_EVENTS_LOADING,
  loading,
  reset,
});

export const resetEvents = () => ({
  type: DOCTOR_EVENTS_RESET,
});

export const setEventsError = (errors: any) => ({ type: DOCTOR_EVENTS_ERROR, errors });

export const setEvents = (events: DoctorAppointments[], page: number, pageSize: number, totalItems: number) => ({
  type: DOCTOR_EVENTS_SUCCESS,
  events,
  page,
  pageSize,
  totalItems,
});

export const fetchDoctorEvents = (
  isPersonal: boolean,
  patientIds: string[],
  timeFrom: Date,
  timeTo: Date,
  statuses?: DoctorsEventStatus[],
  reset?: boolean
): any => {
  return (dispatch, state) => {
    dispatch(setEventsLoading(true, reset));
    findDoctorAppointments(
      isPersonal,
      patientIds,
      timeFrom,
      timeTo,
      statuses,
      reset ? 0 : state()?.doctorEvents?.page + 1,
      state()?.doctorEvents?.pageSize
    )
      .then((response: any) => {
        dispatch(setEventsLoading(false));
        if (response.data.code === 'success') {
          dispatch(
            setEvents(
              (response.data.data.items || []).map((evt: any) => ({
                startTime: parseISO(evt.startTime),
                patient: evt.patient as Patient,
                subject: evt.subject,
                status: evt.status && evt.status.value ? (evt.status.value as DoctorsEventStatus) : null,
                id: evt.id,
                type: evt.type,
              })),
              response.data.data.page,
              response.data.data.pageSize,
              response.data.data.totalItems
            )
          );
        } else {
          dispatch(setEventsError(response.data));
        }
      })
      .catch((error: any) => {
        dispatch(setEventsError(error));
        dispatch(setEventsLoading(false));
      });
  };
};
