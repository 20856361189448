import axios from 'axios';
import {getBaseApiUrl} from "./api";

export const baseURL = getBaseApiUrl();

const instance = axios.create({
  baseURL
});

export default instance;
