import { getDoctorDetails, getDoctorFeedback, getDoctorCerts, getDoctorSchedule } from 'api';

export const DOCTOR_DETAILS_LOADING = 'DOCTOR_DETAILS_LOADING';
export const DOCTOR_DETAILS_ERROR = 'DOCTOR_DETAILS_ERROR';
export const DOCTOR_DETAILS_SUCCESS = 'DOCTOR_DETAILS_SUCCESS';

export const DOCTOR_FEEDBACK_LOADING = 'DOCTOR_FEEDBACK_LOADING';
export const DOCTOR_FEEDBACK_ERROR = 'DOCTOR_FEEDBACK_ERROR';
export const DOCTOR_FEEDBACK_SUCCESS = 'DOCTOR_FEEDBACK_SUCCESS';

export const DOCTOR_CERTS_LOADING = 'DOCTOR_CERTS_LOADING';
export const DOCTOR_CERTS_ERROR = 'DOCTOR_CERTS_ERROR';
export const DOCTOR_CERTS_SUCCESS = 'DOCTOR_CERTS_SUCCESS';

export const DOCTOR_SCHEDULE_LOADING = 'DOCTOR_SCHEDULE_LOADING';
export const DOCTOR_SCHEDULE_ERROR = 'DOCTOR_SCHEDULE_ERROR';
export const DOCTOR_SCHEDULE_SUCCESS = 'DOCTOR_SCHEDULE_SUCCESS';

export const doctorDetailsLoading = (isLoading: boolean) => ({ type: DOCTOR_DETAILS_LOADING, isLoading });

export const doctorDetailsSuccess = (result: any) => {
  let doctor = null;

  if (result && result.data) {
    doctor = { ...result.data };
  }

  return { type: DOCTOR_DETAILS_SUCCESS, doctor };
};

export const doctorDetailsError = (isError: boolean) => ({ type: DOCTOR_DETAILS_ERROR, isError });

export const doctorFeedbackLoading = (isLoading: boolean) => ({ type: DOCTOR_FEEDBACK_LOADING, isLoading });

export const doctorFeedbackSuccess = (result: any) => {
  let feedback = [];

  if (result && result.data && result.data.items) {
    feedback = result.data.items || [];
  }

  return { type: DOCTOR_FEEDBACK_SUCCESS, feedback };
};

export const doctorFeedbackError = (isError: boolean) => ({ type: DOCTOR_FEEDBACK_ERROR, isError });

export const doctorCertsLoading = (isLoading: boolean) => ({ type: DOCTOR_CERTS_LOADING, isLoading });

export const doctorCertsSuccess = (result: any) => {
  let certs = [];

  if (result && result.data && result.data.items) {
    certs = [...result.data.items];
  }

  return { type: DOCTOR_CERTS_SUCCESS, certs };
};

export const doctorCertsError = (isError: boolean) => ({ type: DOCTOR_CERTS_ERROR, isError });

export const doctorScheduleLoading = (isLoading: boolean) => ({ type: DOCTOR_SCHEDULE_LOADING, isLoading });

export const doctorScheduleSuccess = (result: any) => {
  let schedule = [];

  if (result && result.data && result.data.items) {
    schedule = [...result.data.items];
  }

  return { type: DOCTOR_SCHEDULE_SUCCESS, schedule };
};

export const doctorScheduleError = (isError: boolean) => ({ type: DOCTOR_SCHEDULE_ERROR, isError });

export const fetchDoctorDetails = (id: string) => {
  return (dispatch) => {
    dispatch(doctorDetailsLoading(true));
    dispatch(doctorDetailsError(false));

    getDoctorDetails(id)
      .then((response) => {
        dispatch(doctorDetailsLoading(false));
        dispatch(doctorDetailsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(doctorDetailsLoading(false));
        dispatch(doctorDetailsError(true));
      });
  };
};

export const fetchDoctorFeedback = (id: string) => {
  return (dispatch) => {
    dispatch(doctorFeedbackLoading(true));
    dispatch(doctorFeedbackError(false));

    getDoctorFeedback(id)
      .then((response) => {
        dispatch(doctorFeedbackLoading(false));
        dispatch(doctorFeedbackSuccess(response.data));
      })
      .catch((error) => {
        dispatch(doctorFeedbackLoading(false));
        dispatch(doctorFeedbackError(true));
      });
  };
};

export const fetchDoctorCerts = (id: string) => {
  return (dispatch) => {
    dispatch(doctorCertsLoading(true));
    dispatch(doctorCertsError(false));

    getDoctorCerts(id)
      .then((response) => {
        dispatch(doctorCertsLoading(false));
        dispatch(doctorCertsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(doctorCertsLoading(false));
        dispatch(doctorCertsError(true));
      });
  };
};

export const fetchDoctorSchedule = (id: string) => {
  return (dispatch) => {
    dispatch(doctorScheduleLoading(true));
    dispatch(doctorScheduleError(false));

    getDoctorSchedule(id)
      .then((response) => {
        dispatch(doctorScheduleLoading(false));
        dispatch(doctorScheduleSuccess(response.data));
      })
      .catch((error) => {
        dispatch(doctorScheduleLoading(false));
        dispatch(doctorScheduleError(true));
      });
  };
};
