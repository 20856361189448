import axios from 'utils/axios';
import { loadFile } from '../components/hooks/useFileUploader/utils';

export const addResource = (file: File) => {
  const data: FormData = new FormData();
  data.append('file', file);
  return axios
    .post(`/api/v1/resources`, {
      contentType: file.type,
      name: file.name,
      secured: true,
    })
    .then((response) => {
      if (response.data.code === 'success' && response.data.data.uploadUrl) {
        return loadFile(file, response.data.data.uploadUrl)
          .then(() => axios.get(`/api/v1/resources/${response.data.data.id}`))
          .then((response) => response.data.data);
      }
      throw new Error();
    });
};
export const createResource = (file: File) => {
  const data: FormData = new FormData();
  data.append('file', file);
  return axios
    .post(`/api/v1/resources`, {
      contentType: file.type,
      name: file.name,
      secured: true,
    })
    .then((response) => {
      if (response.data.code === 'success' && response.data.data.uploadUrl) {
        return loadFile(file, response.data.data.uploadUrl).then(() => response.data.data.id);
      }
      throw new Error();
    });
};

export const createResourceWithUrl = (file: File) => {
  const data: FormData = new FormData();
  data.append('file', file);
  return axios
    .post(`/api/v1/resources`, {
      contentType: file.type,
      name: file.name,
      secured: true,
    })
    .then((response) => {
      if (response.data.code === 'success' && response.data.data.uploadUrl) {
        return loadFile(file, response.data.data.uploadUrl).then(() => response.data?.data?.uploadUrl);
      }
      throw new Error();
    });
};
