import React, { useState } from 'react';
import { Backdrop, Box, Dialog, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import styles from './AdminTariffView.module.sass';
import ContentCard from '../ContentCard';
import Manage from '../../types/manage';
import InputField from '../Forms/InputField';
import Button from '../Forms/Button';
import { getFieldErrors as getFieldErrorsUtil, validateFieldOnChange } from '../../utils';
import FormValidator from '../Forms/FormValidator';
import { updateTariffAdmin } from '../../api/updateTariffAdmin';
import { createTariffAdmin } from '../../api/createTariffAdmin';
import { updateTariffStatus } from '../../api/updateTariffStatus';

interface AdminPatientAppointmentViewInterface {
  tariff: Manage.Tariff.Item;
  onSave: () => void;
  onClose: () => void;
}

const AdminTariffView = (props: AdminPatientAppointmentViewInterface) => {
  const { onClose = () => {}, onSave = () => {}, tariff } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>(null);
  const [formErrors, setFormErrors] = useState<any>([]);
  const [model, setModel] = useState<Manage.Tariff.Item>(tariff);
  const validateOnChange = (name: string, value: any, event, element?: any) => {
    validateFieldOnChange(name, value, event, model, setModel, formErrors, setFormErrors, element);
  };
  const getFieldErrors = (field: string) => getFieldErrorsUtil(field, formErrors);
  const submit = (e) => {
    e.preventDefault();
    const form = e.target;
    const inputs = [...form.elements].filter((i) => ['INPUT', 'SELECT', 'TEXTAREA'].includes(i.nodeName));

    const { errors, hasError } = FormValidator.bulkValidate(inputs);

    setFormErrors([...errors]);

    if (!hasError) {
      storeTariff();
    }
  };
  const storeTariff = (): void => {
    setLoading(true);
    // @ts-ignore
    (model.id
      ? updateTariffAdmin(model.id, model.name, model.price, model.active)
      : createTariffAdmin(model.name, model.price)
    )
      .then((response: AxiosResponse<any>) => {
        if (response && response.data.code === 'success') {
          if (model.id && tariff.active !== model.active) {
            return updateTariffStatus(model.id, model.active);
          }
        } else {
          throw new Error();
        }
      })
      .then(() => {
        onSave();
        setLoading(false);
      })
      .catch((err) => {
        toast.error('Ошибка при запросе');
        setLoading(false);
      });
  };
  return (
    <Dialog
      PaperProps={{ className: classNames(styles.Dialog) }}
      BackdropComponent={(props) => <Backdrop className={styles.Backdrop} {...props} />}
      onClose={onClose}
      open
    >
      <ContentCard className={styles.AdminTariffView}>
        <ContentCard.Header className={styles.AdminTariffView_Header}>
          <h6 className="page-header-title">{tariff.id ? 'Редактировать тариф' : 'Создать тариф'}</h6>
          <CloseIcon onClick={onClose} className={styles.AdminTariffView_CloseIcon} />
        </ContentCard.Header>
        <ContentCard.Main className={styles.AdminTariffView_Content}>
          <form onSubmit={submit}>
            <Grid container direction="column" justify="space-between" alignItems="stretch" spacing={2}>
              <Grid item xs={12}>
                <InputField
                  name="name"
                  value={model.name || ''}
                  onChange={(e) => validateOnChange('name', e.target.value, e)}
                  data-validate='["required"]'
                  errors={getFieldErrors('name')}
                  block
                  label="Название"
                  placeholder="Введите название тарифа"
                />
              </Grid>
              {!tariff.id && (
                <Grid item xs={12}>
                  <InputField
                    type="number"
                    name="price"
                    onBlur={(event) =>
                      setModel({
                        ...model,
                        price:
                          event.target.value !== '' && Number(event.target.value) >= 0
                            ? Number(Number(event.target.value).toFixed(2))
                            : model.price,
                      })
                    }
                    value={model.price ? model.price.toString() : ''}
                    onChange={(event) => validateOnChange('price', event.target.value, event)}
                    data-validate='["required", "number"]'
                    errors={getFieldErrors('price')}
                    block
                    label="Стоимость"
                    placeholder="Введите стоимость тарифа"
                  />
                </Grid>
              )}
              {tariff.id && (
                <Grid item xs={12} container justify="space-between" alignItems="center">
                  <Box className={styles.AdminTariffView_Label}>Стоимость</Box>
                  <Box className={styles.AdminTariffView_Label}>{model.price} &#8381;</Box>
                </Grid>
              )}
              {tariff.id && (
                <Grid item xs={12} container justify="space-between" alignItems="center">
                  <Box>Активный тариф</Box>
                  <label className="toggle-checkbox-label">
                    <input
                      type="checkbox"
                      checked={model.active === true}
                      onChange={(event) => {
                        setModel({ ...model, active: event.target.checked });
                      }}
                    />

                    <span className="checkmark">
                      <span className="check" />
                    </span>
                  </label>
                </Grid>
              )}
              {!tariff.id && (
                <Grid item xs={12}>
                  <Button type="submit" color="primary" isLoading={loading} block>
                    Сохранить
                  </Button>
                </Grid>
              )}
              {tariff.id && (
                <Grid item xs={12} container justify="space-between" alignItems="center" spacing={1}>
                  <Grid item xs={12} lg={6}>
                    <Button className="mr-2" color="default" block onClick={onClose}>
                      Отмена
                    </Button>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Button type="submit" color="primary" isLoading={loading} block>
                      Сохранить
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </form>
        </ContentCard.Main>
      </ContentCard>
    </Dialog>
  );
};
export default AdminTariffView;
