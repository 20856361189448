import React from 'react';
import { toast } from 'react-toastify';

import { ReactComponent as IconPencil } from 'assets/img/icons/pencil.svg';

import DropdownMenu from 'components/DropdownMenu';
import DropdownToggle from 'components/DropdownMenu/DropdownToggle';
import Spinner from 'components/Spinner';

import { decodeAvatarResource } from 'utils';

import AvatarMenu from './AvatarMenu';
import AvatarCropper from './AvatarCropper';

import styles from './Avatar.module.sass';

type Props = {
  sex: 'MALE' | 'FEMALE';
  avatar: any;
  permissions: any[];
  id: string;
  onUpdateAvatar: (avatarId: null | string) => any;
};

export default ({ sex, avatar, permissions, id, onUpdateAvatar }: Props) => {
  const avatarCropper = React.useRef<any>();
  const [isAvatarLoading, setAvatarLoadingState] = React.useState<boolean>(false);
  const [isMenuOpen, setMenuState] = React.useState<boolean>(false);
  const [isAvatarRemoved, setAvatarStateRemoved] = React.useState<boolean>(false);
  const [loadedAvatar, setLoadedAvatar] = React.useState<ArrayBuffer>();
  const [croppedFile, setCroppedFile] = React.useState<File>();

  const handleUpdateAvatar = React.useCallback(
    async (avatarId: string, file: File) => {
      setMenuState(false);
      setAvatarStateRemoved(false);
      try {
        await onUpdateAvatar(avatarId);
      } catch {
        toast.error('Не удалось обновить изображение');
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        // @ts-ignore
        setLoadedAvatar(reader.result);
        setAvatarLoadingState(false);
      };
    },
    [id]
  );

  const handleRemoveAvatar = React.useCallback(async () => {
    setMenuState(false);

    try {
      await onUpdateAvatar(null);
    } catch {
      toast.error('Не удалось обновить изображение');
    }

    setAvatarStateRemoved(true);
  }, [id]);

  const handleSelectAvatar = React.useCallback(() => {
    setAvatarLoadingState(true);
  }, []);

  const avatarUrl: string = React.useMemo(() => {
    if (isAvatarRemoved) {
      return decodeAvatarResource(null, 360, sex);
    }

    if (loadedAvatar) {
      return `${loadedAvatar}`;
    }

    return decodeAvatarResource(avatar, 360, sex);
  }, [avatar, loadedAvatar, sex, isAvatarRemoved]);

  const handleFailed = React.useCallback(() => {
    setAvatarLoadingState(false);
    toast.error('Не удалось обновить изображение');
  }, []);

  const handleCrop = React.useCallback(async (data: File): Promise<File> => {
    setMenuState(false);

    if (avatarCropper && avatarCropper.current) {
      // @ts-ignore
      try {
        const file = await avatarCropper.current.handleShowModal(data);

        setCroppedFile(file);

        return file;
      } catch {
        setAvatarLoadingState(false);

        throw 'closed';
      }
    }

    return data;
  }, []);

  return (
    <div
      className={styles.avatar}
      style={{
        backgroundImage: `url(${avatarUrl})`,
      }}
    >
      {isAvatarLoading && (
        <div className={styles.spinner}>
          <Spinner width={25} height={25} color="#1473ff" />
        </div>
      )}
      <div className={styles.menu}>
        <DropdownMenu isOpen={isMenuOpen} toggle={() => setMenuState(!isMenuOpen)}>
          <DropdownToggle className={styles.btn}>
            <IconPencil className={styles.icon} />
          </DropdownToggle>

          <AvatarMenu
            onRemove={handleRemoveAvatar}
            onLoad={handleUpdateAvatar}
            onSelect={handleSelectAvatar}
            onFailed={handleFailed}
            employeeId={id}
            beforeUpload={handleCrop}
            croppedFile={croppedFile}
          />
        </DropdownMenu>
      </div>
      <AvatarCropper
        // @ts-ignore
        ref={avatarCropper}
      />
    </div>
  );
};
