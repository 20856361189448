import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { validateFieldOnChange, getFieldErrors as getFieldErrorsUtil } from 'utils';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import { localStringToNumber } from 'utils/helpers';
import {
  authPhoneLogin,
  setPhoneLoginForm,
  setPhoneLoginErrors,
  authEmailPasswordLogin,
  setEmailLoginForm,
  setEmailLoginErrors,
} from 'store/actions';

import InputField from 'components/Forms/InputField';
import Button from 'components/Forms/Button';
import FormValidator from 'components/Forms/FormValidator';

import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Box } from '@material-ui/core';
import DropdownToggle from 'components/DropdownMenu/DropdownToggle';
import DropdownContent from 'components/DropdownMenu/DropdownContent';
import DropdownMenu from 'components/DropdownMenu';
import CustomCheckbox from 'components/Forms/CustomCheckbox';
import { ReactComponent as AngleUpIcon } from 'assets/img/icons/angle-up-icon.svg';
import ReCAPTCHA from 'react-google-recaptcha';
import styles from './LoginScreen.module.sass';
import { ESIA_AUTH_URL } from '../../../constants/common';
import FormInvalidMessage from '../../../components/Forms/FormInvalidMessage';

const reCAPTCHA = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY;

const LoginScreen = (): JSX.Element => {
  const dispatch = useDispatch();
  const { phoneLoginForm, emailLoginForm, phoneLoginFormErrors, emailLoginFormErrors, loginLoading } = useSelector(
    (state: any) => ({
      phoneLoginForm: state.auth.phoneLoginForm,
      emailLoginForm: state.auth.emailLoginForm,
      phoneLoginFormErrors: state.auth.phoneLoginFormErrors,
      emailLoginFormErrors: state.auth.emailLoginFormErrors,
      loginLoading: state.auth.loginLoading,
    })
  );

  const [reCAPTCHAValue, setReCAPTCHAValue] = useState(null);
  const history = useHistory();
  const handleAuthPhoneLogin = useCallback(
    (phone: string, gRecaptcha: string) => dispatch(authPhoneLogin(phone, gRecaptcha)),
    [dispatch]
  );
  const handleSetPhoneLoginForm = useCallback((args: any) => dispatch(setPhoneLoginForm(args)), [dispatch]);
  const handleSetPhoneLoginErrors = useCallback((args: any) => dispatch(setPhoneLoginErrors(args)), [dispatch]);
  const handleAuthEmailPasswordLogin = useCallback(
    (email: string, password: string, history?: any) => dispatch(authEmailPasswordLogin(email, password, history)),
    [dispatch]
  );
  const handleSetEmailLoginForm = useCallback((args: any) => dispatch(setEmailLoginForm(args)), [dispatch]);
  const handleSetEmailLoginErrors = useCallback((args: any) => dispatch(setEmailLoginErrors(args)), [dispatch]);
  const [isLoginForDoctor, setLoginForDoctor] = useState(false);
  const [chosenMenuOpen, setChosenMenuOpen] = useState(false);

  const [isAgreementChecked, setAgreementChecked] = useState(false);
  const [agreementRequiredError, setAgreementRequiredError] = useState(false);

  const [reCaptchaRequiredError, setReCaptchaRequiredError] = useState(false);

  const toggleChosenMenuOpen = useCallback(() => {
    setChosenMenuOpen(!chosenMenuOpen);
  }, [chosenMenuOpen]);

  useEffect(() => {
    setAgreementChecked(false);
    setAgreementRequiredError(false);
    setReCaptchaRequiredError(false);
  }, [isLoginForDoctor]);

  useEffect(() => {
    reCAPTCHAValue && setAgreementRequiredError(false);
  }, [reCAPTCHAValue]);

  const validateOnChange = useCallback(
    (name: string, value: any, event, element?) => {
      validateFieldOnChange(
        name,
        value,
        event,
        isLoginForDoctor ? emailLoginForm : phoneLoginForm,
        isLoginForDoctor ? handleSetEmailLoginForm : handleSetPhoneLoginForm,
        isLoginForDoctor ? emailLoginFormErrors : phoneLoginFormErrors,
        isLoginForDoctor ? handleSetEmailLoginErrors : handleSetPhoneLoginErrors,
        element
      );
    },
    [
      isLoginForDoctor,
      emailLoginForm,
      phoneLoginForm,
      handleSetEmailLoginForm,
      handleSetPhoneLoginForm,
      emailLoginFormErrors,
      phoneLoginFormErrors,
      handleSetEmailLoginErrors,
      handleSetPhoneLoginErrors,
    ]
  );

  const getFieldErrors = useCallback(
    (field: string) => getFieldErrorsUtil(field, isLoginForDoctor ? emailLoginFormErrors : phoneLoginFormErrors),
    [emailLoginFormErrors, isLoginForDoctor, phoneLoginFormErrors]
  );

  const phoneLoginSubmit = useCallback(
    (e: any) => {
      e.preventDefault();
      if (reCAPTCHA && !reCAPTCHAValue) {
        toast.error('Мы должны убедиться что вы не робот');
        setReCaptchaRequiredError(true);
        return false;
      }
      if (!isAgreementChecked) {
        toast.error('Вы не согласились с условиями сервиса');
        setAgreementRequiredError(true);
        return false;
      }

      const form = e.target;
      const inputs = [...form.elements].filter((i) => ['INPUT', 'SELECT', 'TEXTAREA'].includes(i.nodeName));

      const { errors, hasError } = FormValidator.bulkValidate(inputs);

      handleSetPhoneLoginErrors([...errors]);

      if (!hasError) {
        handleAuthPhoneLogin(`${localStringToNumber(phoneLoginForm?.phone)}`, reCAPTCHAValue);
      }
    },
    [reCAPTCHAValue, isAgreementChecked, handleSetPhoneLoginErrors, handleAuthPhoneLogin, phoneLoginForm?.phone]
  );

  const emailLoginSubmit = useCallback(
    (e: any) => {
      e.preventDefault();

      if (!isAgreementChecked) {
        toast.error('Вы не согласились с условиями сервиса');
        setAgreementRequiredError(true);
        return false;
      }

      const form = e.target;
      const inputs = [...form.elements].filter((i) => ['INPUT', 'SELECT', 'TEXTAREA'].includes(i.nodeName));

      const { errors, hasError } = FormValidator.bulkValidate(inputs);

      handleSetEmailLoginErrors([...errors]);

      if (!hasError) {
        handleAuthEmailPasswordLogin(emailLoginForm.email, emailLoginForm.password, history);
      }
    },
    [isAgreementChecked, handleSetEmailLoginErrors, handleAuthEmailPasswordLogin, emailLoginForm, history]
  );

  const beforeMaskedValueChange = useCallback((newState: any) => {
    let { value, selection } = newState;
    if (value?.length > 1 && value[1] === '8') {
      value = value.replace('+8', '+7');
    }
    if (value?.length > 1 && value[1] !== '7' && value[1] !== '_') {
      const { index = 1 } = value.match(/[^+0-9]/) || {};
      selection = { start: index + 3, end: index + 3 };
      value = value.replace('+', '+7').substr(0, 18);
    }
    return {
      ...newState,
      value,
      selection,
    };
  }, []);

  const acceptLabel = useMemo(
    () => (
      <>
        Я принимаю{' '}
        <Link className="link" to="/agreements" target="_blank">
          условия Соглашений
        </Link>
      </>
    ),
    []
  );

  const canAuthESIA = useMemo(
    () =>
      !!process.env.REACT_APP_ESIA_URL &&
      !!process.env.REACT_APP_ESIA_CLIENT_ID &&
      !!process.env.REACT_APP_ESIA_REDIRECT_URL,
    []
  );

  const ESIAAuth = useCallback(() => {
    window.location.href = `${ESIA_AUTH_URL}&state=${uuidv4()}`;
  }, []);

  const loginPersonal = useMemo(
    () => (
      <form onSubmit={phoneLoginSubmit}>
        <InputField
          name="phone"
          data-validate='["required", "phone"]'
          errors={getFieldErrors('phone')}
          value={phoneLoginForm.phone}
          onChange={(e) => {
            validateOnChange('phone', e.target.value, e);
          }}
          beforeMaskedValueChange={beforeMaskedValueChange}
          placeholder="+7 (111) 111-11-11"
          block
          mask="+9 (999) 999-99-99"
        />
        <Box mt={2} display="flex" flexWrap="nowrap">
          <CustomCheckbox
            onChange={(e) => {
              setAgreementChecked(e.target.checked);

              if (e.target.checked === false) {
                setAgreementRequiredError(true);
              } else {
                setAgreementRequiredError(false);
              }
            }}
            invalid={agreementRequiredError}
          />
          <Box>{acceptLabel}</Box>
        </Box>

        <Button className="mt-4" type="submit" block color="primary" isLoading={loginLoading}>
          Продолжить
        </Button>
        {reCAPTCHA && (
          <Box mt={2} className="d-flex flex-column align-items-stretch justify-content-center">
            <ReCAPTCHA onChange={setReCAPTCHAValue} sitekey={reCAPTCHA} />
            {reCaptchaRequiredError && <FormInvalidMessage>Мы должны убедиться что вы не робот</FormInvalidMessage>}
          </Box>
        )}
        {canAuthESIA && (
          <Button className="mt-4" type="button" onClick={ESIAAuth} block color="default" isLoading={loginLoading}>
            <Box display="flex" flexDirection="column">
              <Box mb={1} fontWeight="500">
                Войти через
              </Box>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 220 34.7">
                <path
                  fill="#EE3F58"
                  d="M143.6.8h-18c-.2 0-.3.1-.3.3-.5 8.1-2 16.9-4.2 24v.3c.1.1.2.1.3.1h6.2c.1 0 .3-.1.3-.2 1.7-5.5 3.1-12.7 3.6-18.8h5.9v18.7c0 .2.1.3.3.3h5.9c.2 0 .3-.1.3-.3v-24c0-.2-.1-.4-.3-.4M219.7.8h-5.9c-.2 0-.3.1-.3.3v18.6c-1.3.4-2.6.5-4 .5-3.9 0-4.8-1.2-4.8-6.4V1.2c0-.2-.1-.3-.3-.3h-5.9c-.2 0-.3.1-.3.3v13.4c0 8.4 2.8 11.7 10.1 11.7 4.1 0 8.8-1.1 11.5-2.1.1 0 .2-.2.2-.3V1.2c0-.2-.1-.4-.3-.4M95.2.9h-6c-.2 0-.3.1-.3.2-.9 3.7-2.8 9.9-5.4 16.4L77.5 1c0-.1-.2-.2-.3-.2h-6c-.1 0-.2.1-.3.1-.1.1-.1.2 0 .3l9 24.6c-.9 1.9-1.8 3.5-2.6 5-.6 1.1-1.2 2.2-1.8 3.4-.1.1 0 .2 0 .3.1.1.2.2.3.2h6.5c.1 0 .2-.1.3-.2 1.1-2.1 2.5-5.1 3.8-8.1 3.8-9 6.9-17.4 9.2-25.1 0-.1 0-.2-.1-.3-.1-.1-.2-.1-.3-.1M116.6 19.8c0-.1-.1-.2-.2-.2h-.3c-1.4.5-4.1 1-6 1-4.1 0-6-1.1-6-7.4 0-5 .6-7.4 6-7.4 1.5 0 3 .2 4.8.8.2 0 .3 0 .4-.2.7-1.3 1.5-2.8 2.4-4.8v-.3c0-.1-.1-.2-.2-.2-2.4-.8-5.3-1.2-7.8-1.2-8.6 0-12.4 4-12.4 13.1 0 9.2 3.8 13.3 12.4 13.3 2.1 0 6.4-.5 8.4-1.3.2-.1.2-.2.2-.4l-1.7-4.8zM172.5.9h-6c-.1 0-.3.1-.3.2-.9 3.7-2.8 9.9-5.4 16.4l-6-16.5c0-.1-.2-.2-.3-.2h-6c-.1 0-.2.1-.3.1-.1.1-.1.2 0 .3l9 24.6c-.9 1.9-1.8 3.4-2.6 5-.6 1.1-1.2 2.2-1.8 3.4-.1.1 0 .2 0 .3.1.1.2.2.3.2h6.5c.1 0 .2-.1.3-.2 1.1-2.1 2.5-5.1 3.8-8.1 3.8-9 6.9-17.4 9.1-25.1 0-.1 0-.2-.1-.3 0-.1-.1-.1-.2-.1M194.5.8h-17.4c-.2 0-.3.1-.3.3v24.1c0 .2.1.3.3.3h5.9c.2 0 .3-.1.3-.3V6.6h9.1c.1 0 .3-.1.3-.2.7-1.6 1.4-3.4 2.1-5.1V1c-.1-.1-.2-.2-.3-.2"
                />
                <path
                  fill="#0065B1"
                  d="M31.3 20.8c-3.9 0-5.1-1.1-5.1-7.6 0-7 1.3-7.6 5.1-7.6s5.2.6 5.2 7.6c0 6.6-1.3 7.6-5.2 7.6m0-20.7c-8.5 0-11.8 3.6-11.8 13 0 9.5 3.3 13.2 11.8 13.2s11.9-3.7 11.9-13.2c0-9.3-3.4-13-11.9-13M66.6 19.8c0-.1-.1-.2-.2-.2h-.3c-1.4.5-4.1 1-6 1-4.1 0-6-1.1-6-7.4 0-5 .6-7.4 6-7.4 1.5 0 3 .2 4.8.8.1 0 .3 0 .4-.2.7-1.3 1.5-2.8 2.4-4.8v-.3c0-.1-.1-.2-.2-.2C65.2.4 62.3 0 59.8 0c-8.6 0-12.4 4-12.4 13.1 0 9.2 3.8 13.3 12.4 13.3 2.1 0 6.4-.5 8.4-1.3.2-.1.2-.2.2-.4l-1.8-4.9zM17.7.8H.3C.1.8 0 1 0 1.2v24.1c0 .2.1.3.3.3h5.9c.2 0 .3-.1.3-.3V6.6h9.1c.1 0 .2-.1.3-.2.7-1.6 1.4-3.4 2.1-5.1V1c-.1-.1-.2-.2-.3-.2"
                />
              </svg>
            </Box>
          </Button>
        )}
      </form>
    ),
    [
      phoneLoginSubmit,
      getFieldErrors,
      phoneLoginForm.phone,
      beforeMaskedValueChange,
      agreementRequiredError,
      acceptLabel,
      loginLoading,
      reCaptchaRequiredError,
      canAuthESIA,
      ESIAAuth,
      validateOnChange,
    ]
  );

  const loginDoctor = useMemo(
    () => (
      <form onSubmit={emailLoginSubmit}>
        <InputField
          name="email"
          data-validate='["required", "email"]'
          errors={getFieldErrors('email')}
          value={emailLoginForm.email}
          onChange={(e) => validateOnChange('email', e.target.value, e)}
          placeholder="Логин или эл. почта"
          block
        />

        <InputField
          name="password"
          className="mt-3"
          type="password"
          autocomplate="current-password"
          data-validate='["required"]'
          errors={getFieldErrors('password')}
          value={emailLoginForm.password}
          onChange={(e) => validateOnChange('password', e.target.value, e)}
          placeholder="Введите пароль"
          block
        />

        <CustomCheckbox
          label={acceptLabel}
          onChange={(e) => {
            setAgreementChecked(e.target.checked);

            if (e.target.checked === false) {
              setAgreementRequiredError(true);
            } else {
              setAgreementRequiredError(false);
            }
          }}
          invalid={agreementRequiredError}
        />

        <Button className="mt-4" type="submit" block color="primary" isLoading={loginLoading}>
          Войти
        </Button>
      </form>
    ),
    [
      emailLoginSubmit,
      getFieldErrors,
      emailLoginForm,
      acceptLabel,
      agreementRequiredError,
      loginLoading,
      validateOnChange,
    ]
  );

  const selectLogin = useMemo(
    () => (
      <DropdownMenu
        className={styles.loginScreen_chosen_DropdownMenu}
        isOpen={chosenMenuOpen}
        toggle={toggleChosenMenuOpen}
      >
        <DropdownToggle className={styles.loginScreen_chosen_DropdownMenu_DropdownToggle}>
          <div className={styles.loginScreen_chosen_DropdownMenu_DropdownToggle_title}>
            {isLoginForDoctor ? 'Для врачей' : 'Для пациентов'}
          </div>
          <AngleUpIcon className={styles.loginScreen_chosen_DropdownMenu_DropdownToggle_icon} />
        </DropdownToggle>
        {chosenMenuOpen && (
          <DropdownContent className={styles.loginScreen_chosen_DropdownMenu_DropdownContent}>
            <div
              onClick={() => setLoginForDoctor(false)}
              className={classNames(
                styles.loginScreen_chosen_DropdownMenu_DropdownContent_item,
                !isLoginForDoctor && styles.loginScreen_chosen_DropdownMenu_DropdownContent_item_active
              )}
            >
              Для пациентов
            </div>
            <div
              onClick={() => setLoginForDoctor(true)}
              className={classNames(
                styles.loginScreen_chosen_DropdownMenu_DropdownContent_item,
                isLoginForDoctor && styles.loginScreen_chosen_DropdownMenu_DropdownContent_item_active
              )}
            >
              Для врачей
            </div>
          </DropdownContent>
        )}
      </DropdownMenu>
    ),
    [setLoginForDoctor, isLoginForDoctor, chosenMenuOpen, toggleChosenMenuOpen]
  );

  return (
    <div className={styles.loginScreen}>
      <h3 className={styles.loginScreen_title}>Вход в личный кабинет</h3>
      {selectLogin}
      {!isLoginForDoctor && loginPersonal}
      {isLoginForDoctor && loginDoctor}
    </div>
  );
};

export default LoginScreen;
