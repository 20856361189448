import React from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';

import Spinner from 'components/Spinner';
import useFileUploader, { FileType } from 'components/hooks/useFileUploader';

import { FileDataType } from 'types/file';

import { ReactComponent as ClipIcon } from 'assets/img/icons/clip-icon.svg';
import styles from './ChatFileAttach.module.sass';

type ChildrenProps = {
  open: () => void;
  addFile: (file: Partial<FileType>) => void;
  isDisabled: boolean;
};

export type AttachedFileInfo = {
  id: string | null;
  type: string | null;
};

type Props = {
  onLoadFiles: (files: AttachedFileInfo[]) => void;
  initFiles?: FileDataType[];
  additionalFiles?: FileDataType[];
  disabled?: boolean;
  readOnly?: boolean;
  usePasteEvent?: boolean;
  maxFiles?: number;
  children?: (props: ChildrenProps) => void;
  rawStyle?: boolean;
  fileIds?: string[];
  accept?: string | string[];
  dropElement?: HTMLDivElement | null;
};

const ChatFileAttach = ({
  onLoadFiles,
  initFiles = [],
  additionalFiles = [],
  disabled,
  readOnly,
  rawStyle,
  maxFiles,
  children,
  fileIds = [],
  usePasteEvent = false,
  dropElement,
  accept,
}: Props) => {
  const isDisabled = disabled || readOnly;

  const onFailedLoadFile = React.useCallback(
    (fileName: string) => {
      toast.error(`Не удалось загрузить файл: ${fileName}`);
    },
    [null]
  );

  const { files, isLoading, getRootProps, getInputProps, isDragActive, removeFile, open, addFile } = useFileUploader({
    dropElement,
    usePasteEvent,
    onFailedLoadFile,
    initFiles,
    additionalFiles,
    disabled: isDisabled,
    multiple: false,
    accept: '.jpg, .jpeg, .png, .pdf',
  });

  const loadedFiles = React.useMemo(
    () => files.filter((file) => file.isLoaded).slice(0, (maxFiles = files.filter((file) => file.isLoaded).length)),
    [files]
  );

  React.useEffect(() => {
    onLoadFiles(
      loadedFiles.map((item) => {
        return {
          id: item.id,
          type: item.contentType,
        };
      })
    );
  }, [loadedFiles]);

  const handleOpenFile = React.useCallback(
    (url: string) => (): void => {
      if (!url) {
        return;
      }

      window.open(url);
    },
    [loadedFiles]
  );

  const fieldfiles = loadedFiles.filter((file) => fileIds.includes(file.id));
  const rootProps = fileIds.length > 0 || isLoading || isDisabled ? {} : getRootProps();

  return (
    <div
      {...rootProps}
      className={classNames(styles.chatFile_Attach, {
        [styles.chatFile_Attach_HasFiles]: fieldfiles.length > 0,
      })}
    >
      {isLoading ? <Spinner width={25} height={25} color="#1473ff" /> : <ClipIcon className={styles.clipIcon} />}

      <input {...getInputProps()} />
    </div>
  );
};

export default ChatFileAttach;
