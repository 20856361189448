import React from "react";
import styles from './AppointmentConclusion.module.sass';
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import {Box} from "@material-ui/core";

export type AppointmentConclusionLinkViewProps = {
  url?: string
  isDaily?: boolean
  numberOfDay?: number
}

export const AppointmentConclusionLinkView: React.FC<AppointmentConclusionLinkViewProps> = props => {

  const {url, numberOfDay, isDaily = false} = props

  return (
    <Box>
      {url && (
        <a
          href={url}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            window.open(url, '_blank');
          }}
          className={styles.PatientsDoctor_Conclusion}
        >
          <PictureAsPdfIcon className={styles.PatientsDoctor_Conclusion_Icon}/>
          {` ${isDaily ? 'Профилактическое заключение.' + (numberOfDay ? ' День ' + numberOfDay : '') : 'Заключение'}`}
        </a>
      )}
    </Box>
  )

}
