import { createEffect } from 'effector';
import { deleteJournal, getJournal, getJournalAveraged, postJournal, putJournal } from 'views/JournalPage/api';
import { JournalFilterRequest } from 'views/JournalPage/types';
import { toast } from 'react-toastify';
import { getProfilePersonalsMeta } from '$api';
import { getMyPatientsById } from 'api/getMyPatientsInfo';

const getData = async (data: JournalFilterRequest) => {
  const { userId } = data;
  try {
    const {
      data: {
        data: { items, totalItems, page, pageSize },
      },
    } = await getJournal(data);

    const {
      data: {
        data: { lastObservations, ...patient },
      },
    } = await (userId
      ? getMyPatientsById(userId).then((data) => ({ data: { data: data?.data?.data?.items?.[0] || {} } }))
      : getProfilePersonalsMeta());
    const {
      data: { data: averaged = [] },
    } = await getJournalAveraged(data);
    return {
      items,
      totalItems,
      page,
      pageSize,
      averaged,
      current: lastObservations || [],
      patient,
    };
  } catch (e) {
    throw new Error(e);
  }
};
export const originGetJournalFx = createEffect(async (data: JournalFilterRequest): Promise<any> => {
  try {
    return await getData(data);
  } catch (e) {
    throw new Error(e);
  }
});

export const originPostJournalFx = createEffect(async (data: any): Promise<any> => {
  try {
    const { params, _ } = data;
    await (_?.id ? putJournal(_) : postJournal(_));
    return await getData(params);
  } catch (e) {
    toast.error('Ошибка при запросе');
    throw new Error(e);
  }
});
export const originDeleteJournalFx = createEffect(async (data: any): Promise<any> => {
  try {
    const { params, _ } = data;
    await deleteJournal(_?.id)
      .then()
      .catch(() => ({ data: { code: null } }));
    return await getData(params);
  } catch (e) {
    toast.error('Ошибка при запросе');
    throw new Error(e);
  }
});
