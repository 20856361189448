import Manage from "../../types/manage";
import {ASP_ERRORS, ASP_LOADING, ASP_SUCCESS, ASP_UPDATE} from "../actions/agent/agentServicePackage.actions";

export interface AgentServicePackageSettings {
  items?: Array<Manage.Agent.ServicePackage.Item>;
  page?: number;
  pageSize?: number;
  totalItems?: number;
  hasMore?: boolean;
  errors?: any;
  loading?: boolean;
}

const initState: AgentServicePackageSettings = {
  errors: null, hasMore: false, items: [], loading: false, page: 0, pageSize: 50, totalItems: 0
}

export const agentServicePackagesReducer = (state = initState, action): AgentServicePackageSettings => {
  switch (action.type) {
    case ASP_LOADING:
      return {
        ...state,
        items: action.loading && action.reset ? [] : state.items,
        loading: action.loading,
      }
    case ASP_ERRORS:
      return {
        ...state,
        errors: action.errors,
      }
    case ASP_SUCCESS:
      return {
        ...state,
        items: action.page > 0 ? state.items.concat(action.items) : action.items,
        page: action.page,
        pageSize: action.pageSize,
        totalItems: action.totalItems,
        hasMore: (action.page + 1) * action.pageSize + action.pageSize < action.totalItems && action.totalItems > 0,
        errors: null,
      }
    case ASP_UPDATE:
      return {
        ...state,
        items: state.items.map((value) => {
          return value.id === action.item.id
            ? action.item
            : value;
        }),
      }
    default:
      return state
  }
}
