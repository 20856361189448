import React from 'react';
import PageCard from 'components/PageCard';
import { Box } from '@material-ui/core';
import { format } from 'utils/date';
import styles from './JournalPage.module.sass';
import JournalItemList from './JournalItemList';
import Routes from './Routes';
import JournalTitle from './JournalTitle';

const JournalPage = (): JSX.Element => {
  return (
    <>
      <PageCard>
        <Box className={styles.root}>
          <JournalTitle />
          <Box className={styles.root_date}>{format(new Date(), 'dd LLLL yyyy')}</Box>
          <Box>
            <JournalItemList />
          </Box>
          <Routes />
        </Box>
      </PageCard>
    </>
  );
};

export default JournalPage;
