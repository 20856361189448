import { createEffect } from 'effector';
import { toast } from 'react-toastify';
import { getCheckerApi, setCheckerApi } from '../api';
import { CheckerDataInterface, CheckerFilterRequest, CheckerInterface, CheckerType } from '../types';

export const originSetCheckerFx = createEffect(
  async (items: Array<CheckerDataInterface>): Promise<CheckerInterface> => {
    try {
      return await setCheckerApi(items).then((data) => data?.data);
    } catch (e) {
      toast.error('Ошибка при запросе. Попробуйте еще раз');
      throw new Error(e);
    }
  }
);
export const originGetCheckerFx = createEffect(
  async (params: CheckerFilterRequest): Promise<{ [key: string]: Array<CheckerDataInterface> }> => {
    try {
      const parentId = params?.parentId || 0;
      const type = params?.type || CheckerType.PROBLEM_AREA;
      const key = [parentId, type].join('_');
      params = parentId ? params : {};
      return await getCheckerApi(params).then((data) => ({
        [key]: data?.data?.data || [],
      }));
    } catch (e) {
      toast.error('Ошибка при запросе. Попробуйте еще раз');
      throw new Error(e);
    }
  }
);
