import { AxiosResponse } from 'axios';
import axios from 'utils/axios';
import { ResponseDataInterface } from 'types';

export const getStoriesApi = (): Promise<AxiosResponse<ResponseDataInterface<Array<any>>>> =>
  axios.get(`/api/v1/story`);

export const putStoriesApi = (id: string, data: any): Promise<AxiosResponse<ResponseDataInterface<any>>> =>
  axios.put(`/api/v1/story/${id}`, data);

export const putSortStoriesApi = (stories: Array<any>): Promise<AxiosResponse<ResponseDataInterface<any>>> =>
  axios.put(`/api/v1/story/stories`, { stories });

export const postStoriesApi = (data: any): Promise<AxiosResponse<ResponseDataInterface<any>>> =>
  axios.post(`/api/v1/story`, data);

export const deleteStoriesApi = (id: string): Promise<AxiosResponse<ResponseDataInterface<any>>> =>
  axios.delete(`/api/v1/story/${id}`);
