import React, {useEffect, useState} from "react";
import {SelectInputProps} from "../Forms/SelectInput/SelectInput";
import SelectInput from "../Forms/SelectInput";
import {getSpecializations} from "../../api/getSpecializations";
import {AxiosResponse} from "axios";

interface AdminSpecSelectorView extends SelectInputProps {
  onlyActive?: boolean
}

const AdminSpecSelectorView: React.FC<AdminSpecSelectorView> = (props) => {

  const {value, options, onlyActive = false,...rest} = props

  const [specializations, setSpecializations] = useState([]);

  useEffect(() => init(), [])

  const init = (): void => {
    getSpecializations(null, onlyActive)
      .then((response: AxiosResponse<any>) => {
        if (response.data.code === 'success') {
          setSpecializations(response.data.data.items);
        } else {
          setSpecializations([])
        }
      })
      .catch((err) => {
        console.error(err)
        setSpecializations([])
      });
  };

  return (
    <SelectInput
      value={value?.map((s) => ({
        label: (specializations?.find((_s) => _s.id === s) || {}).name,
        value: s,
      }))}
      options={specializations?.map((s) => ({ label: s.name, value: s.id }))}
      {...rest}
    />
  )
}

export default AdminSpecSelectorView
