import { MANAGE_REVIEWS_LOADING, MANAGE_REVIEWS_SUCCESS, MANAGE_REVIEWS_ERRORS } from '../actions';
import Manage from '../../types/manage';

export interface ManageReviewsInitialSettings {
  reviews?: Manage.Review.Item[];
  page?: number;
  pageSize?: number;
  totalItems?: number;
  hasMore?: boolean;
  errors?: any;
  loading?: boolean;
}
const initialSettings: ManageReviewsInitialSettings = {
  reviews: [],
  page: 0,
  pageSize: 50,
  totalItems: 0,
  hasMore: false,
  loading: false,
  errors: null,
};

const manageReviewsReducer = (state = initialSettings, action): ManageReviewsInitialSettings => {
  switch (action.type) {
    case MANAGE_REVIEWS_LOADING:
      return {
        ...state,
        reviews: action.reviews && action.reset ? [] : state.reviews,
        loading: action.loading,
      };
    case MANAGE_REVIEWS_SUCCESS:
      return {
        ...state,
        reviews: action.page > 0 ? state.reviews.concat(action.reviews) : action.reviews,
        page: action.page,
        pageSize: action.pageSize,
        totalItems: action.totalItems,
        hasMore: (action.page + 1) * action.pageSize < action.totalItems && action.totalItems > 0,
        errors: null,
      };
    case MANAGE_REVIEWS_ERRORS:
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
};

export default manageReviewsReducer;
