import Manage from "../../types/manage";
import {AGENTS_ERROR, AGENTS_LOADING, AGENTS_SUCCESS} from "../actions";


export interface ManageAgentsSettings {
  items?: Array<Manage.Agent.Item>,
  page?: number;
  pageSize?: number;
  totalItems?: number;
  hasMore?: boolean;
  errors?: any;
  loading?: boolean;
}

const initState: ManageAgentsSettings = {
  errors: null, hasMore: false, items: [], loading: false, page: 0, pageSize: 50, totalItems: 0
}

export const manageAgentsReducer = (state = initState, action): ManageAgentsSettings => {
  switch (action.type) {
    case AGENTS_LOADING:
      return {
        ...state,
        items: action.loading && action.reset ? [] : state.items,
        loading: action.loading,
      }
    case AGENTS_ERROR:
      return {
        ...state,
        errors: action.errors,
      }
    case AGENTS_SUCCESS:
      return {
        ...state,
        items: action.page > 0 ? state.items.concat(action.items) : action.items,
        page: action.page,
        pageSize: action.pageSize,
        totalItems: action.totalItems,
        hasMore: (action.page + 1) * action.pageSize + action.pageSize < action.totalItems && action.totalItems > 0,
        errors: null,
      }
    default:
      return state
  }
}
