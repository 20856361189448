import axios from 'utils/axios';
import { AxiosResponse } from 'axios';

export const getSpecializations = (query?: string, active?: boolean): Promise<AxiosResponse> => {
  return axios.get(`/api/v1/specializations`, {
    params: {
      query,
      active: active === true ? true : active === false ? false : null,
    },
  });
};
