import React, { SVGProps, useCallback, useEffect, useMemo, useState } from 'react';
import { Box } from '@material-ui/core';
import VideocamIcon from '@material-ui/icons/Videocam';
import classNames from 'classnames';
import PhoneIcon from '@material-ui/icons/Phone';
import * as VoxImplant from 'voximplant-websdk';
import IconButton from '@material-ui/core/IconButton';
import { toast } from 'react-toastify';
import md5 from 'md5-ts';
import voximplantManager from 'utils/VoximplantManager';
import ReactDOM from 'react-dom';
import styles from './CallToolbar.module.sass';
import Appointment from '../../types/appointment';
import { CallDialog } from '.';
import { CallCredentials } from '../../types/call/CallCredentials';
import { getMyCredentials } from '../../api/getMyCredentials';

const AttachFileIcon = (props: SVGProps<any>) => (
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M18.9 16.8H16.8C16.6143 16.8 16.4363 16.7263 16.305 16.595C16.1737 16.4637 16.1 16.2857 16.1 16.1C16.1 15.9144 16.1737 15.7363 16.305 15.605C16.4363 15.4738 16.6143 15.4 16.8 15.4H18.9C19.0857 15.4 19.2637 15.3263 19.395 15.195C19.5263 15.0637 19.6 14.8857 19.6 14.7V6.30001C19.6 6.11436 19.5263 5.93631 19.395 5.80504C19.2637 5.67376 19.0857 5.60001 18.9 5.60001H2.1C1.91435 5.60001 1.7363 5.67376 1.60503 5.80504C1.47375 5.93631 1.4 6.11436 1.4 6.30001V14.7C1.4 14.8857 1.47375 15.0637 1.60503 15.195C1.7363 15.3263 1.91435 15.4 2.1 15.4H4.2C4.38565 15.4 4.5637 15.4738 4.69497 15.605C4.82625 15.7363 4.9 15.9144 4.9 16.1C4.9 16.2857 4.82625 16.4637 4.69497 16.595C4.5637 16.7263 4.38565 16.8 4.2 16.8H2.1C1.54305 16.8 1.0089 16.5788 0.615076 16.1849C0.221249 15.7911 0 15.257 0 14.7V6.30001C0 5.74306 0.221249 5.20891 0.615076 4.81509C1.0089 4.42126 1.54305 4.20001 2.1 4.20001H18.9C19.457 4.20001 19.9911 4.42126 20.3849 4.81509C20.7788 5.20891 21 5.74306 21 6.30001V14.7C21 15.257 20.7788 15.7911 20.3849 16.1849C19.9911 16.5788 19.457 16.8 18.9 16.8Z"
      fill="#1473FF"
    />
    <path
      d="M16.8 21H4.2C4.01435 21 3.8363 20.9262 3.70503 20.795C3.57375 20.6637 3.5 20.4856 3.5 20.3V13.3C3.5 13.1143 3.57375 12.9363 3.70503 12.805C3.8363 12.6737 4.01435 12.6 4.2 12.6H16.8C16.9857 12.6 17.1637 12.6737 17.295 12.805C17.4263 12.9363 17.5 13.1143 17.5 13.3V20.3C17.5 20.4856 17.4263 20.6637 17.295 20.795C17.1637 20.9262 16.9857 21 16.8 21ZM4.9 19.6H16.1V14H4.9V19.6Z"
      fill="#1473FF"
    />
    <path
      d="M16.8 5.6C16.6143 5.6 16.4363 5.52625 16.305 5.39497C16.1737 5.2637 16.1 5.08565 16.1 4.9V1.4H4.9V4.9C4.9 5.08565 4.82625 5.2637 4.69497 5.39497C4.5637 5.52625 4.38565 5.6 4.2 5.6C4.01435 5.6 3.8363 5.52625 3.70503 5.39497C3.57375 5.2637 3.5 5.08565 3.5 4.9V0.7C3.5 0.514348 3.57375 0.336301 3.70503 0.205025C3.8363 0.0737498 4.01435 0 4.2 0H16.8C16.9857 0 17.1637 0.0737498 17.295 0.205025C17.4263 0.336301 17.5 0.514348 17.5 0.7V4.9C17.5 5.08565 17.4263 5.2637 17.295 5.39497C17.1637 5.52625 16.9857 5.6 16.8 5.6Z"
      fill="#1473FF"
    />
    <path d="M17.4996 7H16.0996V8.4H17.4996V7Z" fill="#1473FF" />
    <path d="M14.7004 7H11.9004V8.4H14.7004V7Z" fill="#1473FF" />
  </svg>
);

interface PropsInterface {
  appointment: Appointment.Data;
  callTo: Opponent;
  toggleDrawer?: (event: React.KeyboardEvent | React.MouseEvent) => any;
  showFiles?: boolean;
  showVideo?: boolean;
  showPhone?: boolean;
}

export interface Opponent {
  id: string;
  avatar: any;
  firstName: string;
  lastName: string;
}

const CallToolbar = (props: PropsInterface) => {
  const [callCredentials, setCallCredentials] = useState<CallCredentials>(null);
  const [voximplant] = useState<any>(voximplantManager.getClient());
  const [isLoggedIn, setLoggedIn] = useState<boolean>(voximplantManager.isLoggedIn());
  const [loading, setLoading] = useState<boolean>(true);
  const { callTo, appointment, toggleDrawer, showFiles = false, showVideo = false, showPhone = false } = props;
  const [callState, setCallState] = useState<{ showCall: boolean; isVideo: boolean; incomingCall: any }>({
    showCall: false,
    isVideo: false,
    incomingCall: null,
  });

  const { showCall, isVideo, incomingCall } = useMemo(() => callState, [callState]);

  const handleOpen = useCallback((isVideo: boolean) => {
    setCallState({
      showCall: true,
      isVideo,
      incomingCall: null,
    });
  }, []);

  const callAuthWithOneTimeKey = useCallback(
    async (e) => {
      try {
        const hashMd5 = md5(`${e.key}|${callCredentials.hash}`);
        await voximplant.loginWithOneTimeKey(callCredentials.userName, hashMd5);
        setLoggedIn(true);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    },
    [callCredentials, voximplant]
  );

  const getVideoCredentials = useCallback((forceUpdate?: boolean) => {
    getMyCredentials(forceUpdate).then(
      (value) => {
        setCallCredentials(value);
      },
      (reason) => toast.error(reason)
    );
  }, []);

  const isNeedVoximplantAuth = useMemo(
    () =>
      appointment?.type?.value !== 'PERSONAL' &&
      (appointment?.channel?.value === 'VIDEO' || appointment?.channel?.value === 'PHONE'),
    [appointment]
  );
  const onAuthHandle = useCallback(
    async (e) => {
      if (e.result) {
        setLoggedIn(true);
        setLoading(false);
      } else if (e.code === 302) {
        await callAuthWithOneTimeKey(e);
      }
      if (e.code === 401) {
        await voximplant.disconnect();
        getVideoCredentials(true);
      }
    },
    [callAuthWithOneTimeKey, getVideoCredentials, voximplant]
  );

  const onIncomingCall = useCallback((e) => {
    const incomingCall = e.call;
    incomingCall.on(VoxImplant.CallEvents.Disconnected, () => {
      setCallState({
        showCall: false,
        isVideo: false,
        incomingCall: null,
      });
    });
    setCallState({
      showCall: true,
      isVideo: incomingCall.settings.video === true,
      incomingCall,
    });
  }, []);

  const onIncomingCallDisconnected = useCallback(() => {
    setCallState({
      showCall: false,
      isVideo: false,
      incomingCall: null,
    });
  }, []);

  useEffect(() => {
    incomingCall && incomingCall.on(VoxImplant.CallEvents.Disconnected, onIncomingCallDisconnected);
  }, [incomingCall, onIncomingCallDisconnected]);

  useEffect(() => {
    isNeedVoximplantAuth && getVideoCredentials();
  }, [getVideoCredentials, isNeedVoximplantAuth]);
  useEffect(() => {
    if (callCredentials) {
      if (!voximplantManager.isLoggedIn()) {
        voximplant.connect().then(() => voximplant.requestOneTimeLoginKey(callCredentials.userName));
      }

      voximplant.on(VoxImplant.Events.AuthResult, onAuthHandle);
      voximplant.on(VoxImplant.Events.IncomingCall, onIncomingCall);

      return () => {
        voximplant.off(VoxImplant.Events.AuthResult, onAuthHandle);
        voximplant.off(VoxImplant.Events.IncomingCall, onIncomingCall);
        setCallState({
          showCall: false,
          isVideo: false,
          incomingCall: null,
        });
        if (isLoggedIn) {
          voximplant.disconnect();
        }
      };
    }
  }, [callCredentials, isLoggedIn, onAuthHandle, onIncomingCall, voximplant]);

  const isVideoCall = useMemo((): boolean => {
    return showCall && isVideo && showVideo;
  }, [isVideo, showCall, showVideo]);
  const isPhoneCall = useMemo((): boolean => {
    return showCall && !isVideo && showVideo;
  }, [isVideo, showCall, showVideo]);
  const canCall = useMemo((): boolean => !loading && isLoggedIn, [isLoggedIn, loading]);

  const onCloseCallDialog = useCallback((message?: string, error?: any) => {
    setCallState({
      showCall: false,
      isVideo: false,
      incomingCall: null,
    });
    if (error) {
      toast.error(error);
    } else if (message) {
      toast.success(message);
    }
  }, []);

  return (
    <Box className={classNames(styles.CallToolbar)}>
      {showCall && (
        <CallDialog
          callTo={callTo}
          onClose={onCloseCallDialog}
          customData={appointment?.id}
          isVideo={isVideo}
          incomingCall={incomingCall}
        />
      )}
      {isLoggedIn && showVideo && appointment.channel.value === 'VIDEO' && (
        <Box mr={1}>
          <IconButton
            className={classNames(styles.CallToolbar_IconButton)}
            size="medium"
            color="primary"
            component="span"
            onClick={() => handleOpen(true)}
            disabled={!canCall || isPhoneCall}
          >
            {isVideoCall && <Box className={classNames(styles.CallToolbar_Status)} />}
            <VideocamIcon className={classNames(styles.CallToolbar_Icon)} />
          </IconButton>
        </Box>
      )}
      {isLoggedIn &&
        (showPhone || showVideo) &&
        (appointment.channel.value === 'VIDEO' || appointment.channel.value === 'PHONE') && (
          <Box mr={1}>
            <IconButton
              className={classNames(styles.CallToolbar_IconButton)}
              size="medium"
              color="primary"
              component="span"
              onClick={() => handleOpen(false)}
              disabled={!canCall || isVideoCall}
            >
              {isPhoneCall && <Box className={classNames(styles.CallToolbar_Status)} />}
              <PhoneIcon className={classNames(styles.CallToolbar_Icon)} />
            </IconButton>
          </Box>
        )}
      {appointment?.withFiles && showFiles && (
        <IconButton size="medium" color="primary" component="span" onClick={toggleDrawer}>
          <AttachFileIcon className={styles.CallToolbar_Icon} />
        </IconButton>
      )}
    </Box>
  );
};

export default CallToolbar;
