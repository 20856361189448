import React, { useCallback, useEffect, useMemo } from 'react';
import { connect, RootStateOrAny, useDispatch, useSelector } from 'react-redux';

import { useLocation } from 'react-router-dom';
import LoginScreen from './LoginScreen';
import RegisterScreen from './RegisterScreen';
import PhoneConfirmCode from './PhoneConfirmCode';
import { setLoginScreen } from '../../store/actions';
import LoginScreenAdmin from './LoginScreenAdmin/LoginScreenAdmin';

type LoginPageScreen = 'LOGIN' | 'PHONE_CONFIRM' | 'REGISTER' | 'ADMIN' | 'PHONE_CONFIRM_ESIA';
const LoginPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { loginScreen } = useSelector((state: RootStateOrAny) => state?.auth || {});
  const handleSetLoginScreen = useCallback(
    (screen: LoginPageScreen) => {
      dispatch(setLoginScreen(screen));
    },
    [dispatch]
  );
  useEffect(() => {
    location?.pathname.search('/admin') > -1 && handleSetLoginScreen('ADMIN');
  }, [location, handleSetLoginScreen]);
  return useMemo(() => {
    switch (loginScreen) {
      case 'LOGIN':
        return <LoginScreen />;

      case 'ADMIN':
        return <LoginScreenAdmin />;
      case 'PHONE_CONFIRM_ESIA':
      case 'PHONE_CONFIRM':
        return <PhoneConfirmCode />;

      case 'REGISTER':
        return <RegisterScreen />;

      default:
        return <></>;
    }
  }, [loginScreen]);
};

export default connect(
  (state: any) => ({
    loginPhoneCodeConfirm: state.auth.loginPhoneCodeConfirm,
    loginScreen: state.auth.loginScreen,
  }),
  {
    setLoginScreen,
  }
)(LoginPage);
