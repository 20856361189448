import {  
  DOCTOR_DETAILS_LOADING,
  DOCTOR_DETAILS_ERROR,
  DOCTOR_DETAILS_SUCCESS,

  DOCTOR_FEEDBACK_LOADING,
  DOCTOR_FEEDBACK_ERROR,
  DOCTOR_FEEDBACK_SUCCESS,

  DOCTOR_CERTS_LOADING,
  DOCTOR_CERTS_ERROR,
  DOCTOR_CERTS_SUCCESS,

  DOCTOR_SCHEDULE_LOADING,
  DOCTOR_SCHEDULE_ERROR,
  DOCTOR_SCHEDULE_SUCCESS
} from '../actions';


const initialSettings = {
  doctorDetails: null,
  doctorDetailsLoading: false,
  doctorDetailsLoadingError: false,

  doctorFeedbackList: [],
  doctorFeedbackLoading: false,
  doctorFeedbackLoadingError: false,

  doctorCertsList: [],
  doctorCertsLoading: false,
  doctorCertsLoadingError: false,

  doctorScheduleList: [],
  doctorScheduleLoading: false,
  doctorScheduleLoadingError: false
};

const doctorDetailsReducer = (state = initialSettings, action) => {
  switch (action.type) {
    case DOCTOR_DETAILS_LOADING:
      return {
        ...state,
        doctorDetailsLoading: action.isLoading
      };

    case DOCTOR_DETAILS_ERROR:
      return {
        ...state,
        doctorDetailsLoadingError: action.isError
      };

    case DOCTOR_DETAILS_SUCCESS:
      return {
        ...state,
        doctorDetails: action.doctor ? {...action.doctor} : action.doctor
      };

    case DOCTOR_FEEDBACK_LOADING:
      return {
        ...state,
        doctorFeedbackLoading: action.isLoading
      };

    case DOCTOR_FEEDBACK_ERROR:
      return {
        ...state,
        doctorFeedbackLoadingError: action.isError
      };

    case DOCTOR_FEEDBACK_SUCCESS:
      return {
        ...state,
        doctorFeedbackList: [...action.feedback]
      };

    case DOCTOR_CERTS_LOADING:
      return {
        ...state,
        doctorCertsLoading: action.isLoading
      };

    case DOCTOR_CERTS_ERROR:
      return {
        ...state,
        doctorCertsLoadingError: action.isError
      };

    case DOCTOR_CERTS_SUCCESS:
      return {
        ...state,
        doctorCertsList: [...action.certs]
      };

    case DOCTOR_SCHEDULE_LOADING:
      return {
        ...state,
        doctorScheduleLoading: action.isLoading
      };

    case DOCTOR_SCHEDULE_ERROR:
      return {
        ...state,
        doctorScheduleLoadingError: action.isError
      };

    case DOCTOR_SCHEDULE_SUCCESS:
      return {
        ...state,
        doctorScheduleList: [...action.schedule]
      };
      
    default:
      return state;
  }
};

export default doctorDetailsReducer;
