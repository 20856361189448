import { RESET_REDIRECT, UPDATE_REDIRECT } from '../actions/redirect.actions';

const initialSettings = {
  pathname: '',
};

const redirectReducer = (state = initialSettings, action) => {
  switch (action.type) {
    case UPDATE_REDIRECT:
      return {
        ...state,
        pathname: action.pathname,
      };
    case RESET_REDIRECT:
      return {
        ...state,
        pathname: null,
      };
    default:
      return state;
  }
};

export default redirectReducer;
