import axios from 'utils/axios';

export const findWorkShiftsAdmin = (doctorId: string, timeFrom: Date, timeTo: Date) => {
  return axios.get(`/api/v1/workshifts`, {
    params: {
      doctorIds: doctorId,
      timeFrom,
      timeTo,
    },
  });
};
