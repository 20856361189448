import React from 'react';
import { UserRole } from 'types';
import useIsAuth from 'hooks/useIsAuth';
import classNames from 'classnames';
import useIsAdminRole from 'hooks/useIsAdminRole';
import styles from './Sidebar.module.sass';
import SidebarMenu from '../SidebarMenu';
import SidebarProfile from '../SidebarProfile';
import SidebarPersonalDoctor from '../SidebarPersonalDoctor';
import HasRole from '../HasRole';

const Sidebar = (): JSX.Element => {
  const isAuthenticated = useIsAuth();
  const isAdmin = useIsAdminRole();
  if (!isAuthenticated) {
    return <></>;
  }
  return (
    <aside className={classNames(styles.root, isAdmin && styles.root_admin)}>
      <SidebarProfile />
      <HasRole target={UserRole.PATIENT}>
        <SidebarPersonalDoctor />
      </HasRole>
      <SidebarMenu />
    </aside>
  );
};

export default Sidebar;
