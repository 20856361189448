import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import classNames from 'classnames';
import ContentLoading from '../../../../components/ContentLoading';
import styles from './ServicePackagesDocuments.module.sass';
import HasRole from '../../../../components/HasRole';
import { UserRole } from '../../../../types';
import Button from '../../../../components/Forms/Button';
import AdminMfoTariffDocEdit from '../../../../components/AdminMfoTariffDocEdit';
import useIsAdminRole from '../../../../hooks/useIsAdminRole';

interface PropsInterface {
  id?: string;
  loading?: boolean;
  tariffDocs?: Array<any>;
  error?: any;
  getData?: (...args: any) => any;
  onDelete?: (...args: any) => any;
  isGroup?: boolean;
}
const ServicePackagesDocuments = (props: PropsInterface): JSX.Element => {
  const {
    id,
    loading,
    tariffDocs = [],
    error = null,
    getData = () => {},
    onDelete = () => {},
    isGroup = false,
  } = props;
  const [tariffDocEdit, setTariffDocEdit] = useState(null);
  const isAdminRole = useIsAdminRole();
  return (
    <>
      <ContentLoading isLoading={loading} isError={error} fetchData={getData}>
        <Box className={styles.root}>
          {tariffDocs.map((item: any, index: number) => (
            <Box
              className={styles.root_item}
              onClick={(event) => {
                event.stopPropagation();
                setTariffDocEdit(item);
              }}
              key={index}
            >
              <Box className={classNames(styles.root_item_data)}>
                <Box fontWeight="bold" className={styles.root_item_data_name}>
                  {item.name}
                </Box>
                <Box className={classNames(styles.root_item_data_text)}>{item?.docType?.name || 'HTML'}</Box>
                {item?.type === 'HTML' && item?.html && (
                  <Box className={classNames(styles.root_item_data_text)}>
                    <a
                      href="#"
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        const winUrl = URL.createObjectURL(new Blob([item.html], { type: 'text/html' }));
                        window.open(winUrl, '');
                      }}
                    >
                      посмотреть
                    </a>
                  </Box>
                )}
                {item?.type === 'FILE' && item?.resource && (
                  <Box className={classNames(styles.root_items_item_data_name)}>
                    <a
                      href="#"
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        window.open(item?.resource?.originalUrl, '');
                      }}
                    >
                      {item?.resource?.fileName}
                    </a>
                  </Box>
                )}
              </Box>
              <HasRole target={UserRole.ADMIN}>
                <Box className={classNames(styles.root_items_item_actions)}>
                  <Box display="flex" flexDirection="column">
                    <Button
                      className={classNames(styles.root_button, styles.root_button_remove)}
                      size="sm"
                      isLoading={loading}
                      color="default"
                      onClick={(event) => {
                        event.stopPropagation();
                        onDelete(item);
                      }}
                    >
                      Удалить
                    </Button>
                  </Box>
                </Box>
              </HasRole>
            </Box>
          ))}
        </Box>
      </ContentLoading>
      <HasRole target={UserRole.ADMIN}>
        <Box className={styles.root_action}>
          <Button
            size="md"
            color="primary"
            onClick={(event) => {
              event.stopPropagation();
              setTariffDocEdit({});
            }}
          >
            Создать новый документ
          </Button>
        </Box>
      </HasRole>
      {tariffDocEdit !== null && (
        <AdminMfoTariffDocEdit
          data={tariffDocEdit}
          disabled={!isAdminRole}
          tariffDocId={id}
          isGroup={isGroup}
          onClose={() => {
            setTariffDocEdit(null);
          }}
          onSave={() => {
            setTariffDocEdit(null);
            getData();
          }}
        />
      )}
    </>
  );
};
export default ServicePackagesDocuments;
