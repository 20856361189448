import React, { ReactElement, useCallback, useMemo } from 'react';
import PageTitle from 'components/PageTitle';
import useRootSelector from 'hooks/useRootSelector';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { selectCheckerSuccess } from '../../store/selectors';
import styles from './CheckerSuccess.module.sass';
import { ValueResult } from '../../types';
import Button from '../../../../components/Forms/Button';

const CheckerSuccess = (): ReactElement => {
  const success = useRootSelector(selectCheckerSuccess);
  const history = useHistory();
  const displayName = useSelector<any, string>((state) => state?.auth?.userData?.displayName);
  const handleGoMain = useCallback(() => {
    history.push('/');
  }, [history]);
  return (
    <Box className={styles.root}>
      <PageTitle className={styles.root_title}>Здравствуйте, {displayName}!</PageTitle>
      {success?.[ValueResult.DiseaseName] && (
        <Box className={styles.root_text}>
          <Box component="p">
            Ваш предположительный диагноз:
            <Box component="span" ml={1} className={styles.root_value}>
              {success?.[ValueResult.DiseaseName]}
            </Box>
            .
          </Box>
        </Box>
      )}
      {success?.[ValueResult.Definition] && (
        <Box className={styles.root_text}>
          <Box component="p">{success?.[ValueResult.Definition]}</Box>
        </Box>
      )}
      <Box className={styles.root_text}>
        <Box component="ul">
          {success?.[ValueResult.Reasons] && (
            <Box component="li">
              <Box component="span" mr={1} className={styles.root_value}>
                Прчиина.
              </Box>
              {success?.[ValueResult.Reasons]}
            </Box>
          )}
          {success?.[ValueResult.Symptoms] && (
            <Box component="li">
              <Box component="span" mr={1} className={styles.root_value}>
                Основные симптомы:
              </Box>
              {success?.[ValueResult.Symptoms]}
            </Box>
          )}
          {success?.[ValueResult.Diagnostics] && (
            <Box component="li">
              <Box component="span" mr={1} className={styles.root_value}>
                Диагностика.
              </Box>
              {success?.[ValueResult.Diagnostics]}
            </Box>
          )}
          {success?.[ValueResult.Therapy] && (
            <Box component="li">
              <Box component="span" mr={1} className={styles.root_value}>
                Лечение.
              </Box>
              {success?.[ValueResult.Therapy]}
            </Box>
          )}
        </Box>
      </Box>
      {success?.[ValueResult.WhoToContact] && (
        <Box className={styles.root_text}>
          <Box component="p">{success?.[ValueResult.WhoToContact]}</Box>
        </Box>
      )}
      <Box className={styles.root_actions}>
        <Button className="mb-3" onClick={handleGoMain} size="lg" type="button" color="primary">
          Обратиться
        </Button>
        <Button className="mb-3" onClick={handleGoMain} size="lg" type="button" color="default">
          Позже
        </Button>
      </Box>
      <Box className={classNames(styles.root_text, styles.root_text_mute)}>
        <Box component="p">*«Не является медицинским документом. Необходима консультация специалиста»</Box>
      </Box>
    </Box>
  );
};
export default CheckerSuccess;
