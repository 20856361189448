import { AxiosResponse } from 'axios';
import Manage from '../../types/manage';
import { getSpecializations } from '../../api/getSpecializations';

export const MANAGE_SPEC_LOADING = 'MANAGE_SPEC_LOADING';
export const MANAGE_SPEC_SUCCESS = 'MANAGE_SPEC_SUCCESS';
export const MANAGE_SPEC_ERRORS = 'MANAGE_SPEC_ERRORS';

export const setManageSpecLoading = (loading: boolean) => ({
  type: MANAGE_SPEC_LOADING,
  loading,
});

export const setManageSpecErrors = (errors: any) => ({ type: MANAGE_SPEC_ERRORS, errors });

export const setManageSpec = (spec: Manage.Spec.Item[]) => ({
  type: MANAGE_SPEC_SUCCESS,
  spec,
});

export const fetchManageSpec = (active?: boolean): any => {
  return (dispatch) => {
    dispatch(setManageSpecLoading(true));
    getSpecializations(null, active)
      .then((response: AxiosResponse<Manage.Spec.Response>) => {
        dispatch(setManageSpecLoading(false));
        if (response.data.code === 'success') {
          dispatch(setManageSpec(response.data.data.items));
        } else {
          dispatch(setManageSpecErrors(response.data));
        }
      })
      .catch((error: any) => {
        dispatch(setManageSpecErrors(error));
        dispatch(setManageSpecLoading(false));
      });
  };
};
