import { AxiosResponse } from 'axios';
import Manage from '../../types/manage';
import { getServicePackages } from '../../api/getServicePackages';

export const MANAGE_SERVICE_PACKAGES_MFO_LOADING = 'MANAGE_SERVICE_PACKAGES_MFO_LOADING';
export const MANAGE_SERVICE_PACKAGES_MFO_SUCCESS = 'MANAGE_SERVICE_PACKAGES_MFO_SUCCESS';
export const MANAGE_SERVICE_PACKAGES_MFO_ERRORS = 'MANAGE_SERVICE_PACKAGES_MFO_ERRORS';
export const MANAGE_SERVICE_PACKAGES_MFO_UPDATE = 'MANAGE_SERVICE_PACKAGES_MFO_UPDATE';

export const updateManageServicePackagesMfo = (item: Manage.ServicePackages.Mfo.Item) => ({
  type: MANAGE_SERVICE_PACKAGES_MFO_UPDATE,
  item,
});

export const setManageServicePackagesMfoLoading = (loading: boolean) => ({
  type: MANAGE_SERVICE_PACKAGES_MFO_LOADING,
  loading,
});

export const setManageServicePackagesMfoErrors = (errors: any) => ({
  type: MANAGE_SERVICE_PACKAGES_MFO_ERRORS,
  errors,
});

export const setManageServicePackagesMfo = (items: Manage.ServicePackages.Mfo.Item[]) => ({
  type: MANAGE_SERVICE_PACKAGES_MFO_SUCCESS,
  items,
});
export const fetchManageServicePackagesMfo = (
  query?: string,
  group?: string,
  status?: string,
  categoryBenefitsId?: string
): any => {
  return (dispatch) => {
    dispatch(setManageServicePackagesMfoLoading(true));
    getServicePackages(query, group, status, categoryBenefitsId)
      .then((response: AxiosResponse<Manage.ServicePackages.Mfo.Response>) => {
        dispatch(setManageServicePackagesMfoLoading(false));
        if (response.data.code === 'success') {
          dispatch(setManageServicePackagesMfo(response.data.data.items));
        } else {
          dispatch(setManageServicePackagesMfoErrors(response.data));
        }
      })
      .catch((error: any) => {
        dispatch(setManageServicePackagesMfoErrors(error));
        dispatch(setManageServicePackagesMfoLoading(false));
      });
  };
};
