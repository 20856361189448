import {
  APPOINTMENT_VIEW_LOADING,
  APPOINTMENT_VIEW_ERROR,
  APPOINTMENT_VIEW_SUCCESS,
  APPOINTMENT_VIEW_STATUS_UPDATE_SUCCESS,
} from '../actions';

const initialState = {
  appointmentView: null,
  appointmentViewLoading: false,
  appointmentViewLoadingError: false,
};

const appointmentViewReducer = (state = initialState, action) => {
  switch (action.type) {
    case APPOINTMENT_VIEW_LOADING:
      return {
        ...state,
        appointmentViewLoading: action.isLoading,
      };

    case APPOINTMENT_VIEW_ERROR:
      return {
        ...state,
        appointmentViewLoadingError: action.isError,
      };

    case APPOINTMENT_VIEW_SUCCESS:
      return {
        ...state,
        appointmentView: action.appointment ? { ...action.appointment } : action.appointment,
      };
    case APPOINTMENT_VIEW_STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        appointmentView: {
          ...state.appointmentView,
          status: action.status,
        },
      };
    default:
      return state;
  }
};

export default appointmentViewReducer;
