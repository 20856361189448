import React, {useState, useEffect} from 'react';
import {validateFieldOnChange, getFieldErrors as getFieldErrorsUtil, hasFieldError, decodeAvatarResource} from 'utils';
import {format} from 'date-fns';
import classNames from 'classnames';
import {toast} from 'react-toastify';
import {setAppointmentsFeedback} from 'api';

// Components
import FormGroup from 'components/Forms/FormGroup';
import InputField from 'components/Forms/InputField';
import FormValidator from 'components/Forms/FormValidator';
import Button from 'components/Forms/Button';

import {ReactComponent as StarFillIcon} from 'assets/img/icons/star-fill-icon.svg';
import {Link, withRouter} from 'react-router-dom';
import styles from '../AppointmentPage.module.sass';
import CustomCheckbox from '../../../components/Forms/CustomCheckbox';

const FeedbackView = (props: any) => {
  const {
    appointment: {feedback: feedbackUser = null, id: id = null}
  } = props;


  const [feedbackForm, setFeedbackForm] = useState({
    feedback: '',
    rate: 0,
    publication: undefined,
  });

  useEffect(() => {
    setFeedbackForm({
      feedback: feedbackUser ? feedbackUser.subject : '',
      rate: feedbackUser ? feedbackUser.rating : 0,
      publication: feedbackUser ? feedbackUser.publication : undefined,
    })
  }, [feedbackUser])


  const [feedbackFormErrors, setFeedbackFormErrors] = useState([]);

  const [feedbackSubmitLoading, setFeedbackSubmitLoading] = useState(false);

  const [rateHover, setRateHover] = useState(0);

  const validateOnChange = (name: string, value: any, event, element?) => {
    if (feedbackForm.publication === value) {
      value = value === 'on' ? undefined : 'on'
    }
    validateFieldOnChange(
      name,
      value,
      event,
      feedbackForm,
      setFeedbackForm,
      feedbackFormErrors,
      setFeedbackFormErrors,
      element
    );
  };

  const hasError = (field: string, code?: string) => hasFieldError(feedbackFormErrors, field, code);

  const getFieldErrors = (field: string) => getFieldErrorsUtil(field, feedbackFormErrors);

  const feedbackFormSubmit = (e) => {
    e.preventDefault();

    const form = e.target;
    const inputs = [...form.elements].filter((i) => ['INPUT', 'SELECT', 'TEXTAREA'].includes(i.nodeName));

    const {errors, hasError} = FormValidator.bulkValidate(inputs);

    setFeedbackFormErrors([...errors]);

    if (!hasError) {
      setFeedbackSubmitLoading(true);

      const isPublish = !!feedbackForm.publication

      setAppointmentsFeedback(props.appointment.id, {
        rating: feedbackForm.rate,
        subject: feedbackForm.feedback,
        publication: isPublish,
      })
        .then((response) => {
          setFeedbackSubmitLoading(false);

          props.history.push('/');
        })
        .catch((error) => {
          setFeedbackSubmitLoading(false);
          console.error(error)
          toast.error('Ошибка при попытке оставить отзыв');
        });
    }
  };

  const rateStars = [1, 2, 3, 4, 5];

  return (
    <div className={styles.appointmentFeedback_patientFeedback}>
      <h6 className={styles.appointmentFeedback_patientFeedback_title}>Оцените качество консультации</h6>

      <div className={`${styles.appointmentFeedback_patientFeedback_rate} rate-stars-list`}>
        {rateStars.map((rate, rateIndex) => (
          <StarFillIcon
            key={rateIndex}
            className={classNames('rate-star', {
              [styles.rateActive]: feedbackForm.rate >= rateIndex + 1 || rateHover >= rateIndex + 1,
            })}
            onClick={(e) => {
              !feedbackUser &&
              setFeedbackForm({
                ...feedbackForm,
                rate: rateIndex + 1,
              });
            }}
            onMouseOver={(e) => {
              !feedbackUser && setRateHover(rateIndex + 1);
            }}
            onMouseOut={(e) => {
              !feedbackUser && setRateHover(0);
            }}
          />
        ))}
      </div>

      <p className={styles.appointmentFeedback_patientFeedback_subtitle}>
        Пожалуйста, оцените работу врача и оставьте Ваш отзыв. Это поможем нам постоянно улучшать качество работы
        сервиса.
      </p>

      <form className={styles.appointmentFeedback_patientFeedback_form} onSubmit={(e) => feedbackFormSubmit(e)}>
        <FormGroup>
          <InputField
            type="textarea"
            name="feedback"
            rows={4}
            disabled={feedbackUser}
            readOnly={feedbackUser}
            value={feedbackForm.feedback}
            onChange={(e) => validateOnChange('feedback', e.target.value, e)}
            data-validate='["required"]'
            errors={getFieldErrors('feedback')}
            block
            placeholder="Оставьте ваш отзыв"
          />
        </FormGroup>

        <CustomCheckbox
          name="publication"
          checked={feedbackForm.publication}
          disabled={feedbackUser}
          label="Я согласен опубликовать отзыв"
          onChange={(e) => validateOnChange('publication', e.target.value, e)}
          invalid={getFieldErrors('publication').length > 0}
        />

        <Button
          className={`mt-4`}
          color="primary"
          type="submit"
          isLoading={feedbackSubmitLoading}
          disabled={!feedbackForm.rate || feedbackForm.rate <= 0 || feedbackUser}
        >
          Оставить отзыв и завершить
        </Button>
      </form>
    </div>
  );
};

export default withRouter(FeedbackView);
