import { AxiosResponse } from 'axios';
import { PageItemsResponseInterface, ResponseDataInterface } from '../types';
import axios from '../utils/axios';

export const getMyServicePackages = (): Promise<
  AxiosResponse<ResponseDataInterface<PageItemsResponseInterface<any>>>
> => axios.get(`/api/v1/servicepackages/my`);

export const getMyPersonalDoctorServicePackages = (): Promise<
  AxiosResponse<ResponseDataInterface<PageItemsResponseInterface<any>>>
> =>
  getMyServicePackages().then((data) => ({
    ...data,
    data: {
      ...data.data,
      data: {
        ...data?.data?.data,
        items: data?.data?.data?.items?.filter(({ isPersonalized }) => isPersonalized === true),
      },
    },
  }));

export const getMyAvailableServicePackages = (): Promise<
  AxiosResponse<ResponseDataInterface<PageItemsResponseInterface<any>>>
> => axios.get(`/api/v1/servicepackages/my/available`);

export const getMyAvailablePersonalDoctorServicePackages = (): Promise<
  AxiosResponse<ResponseDataInterface<PageItemsResponseInterface<any>>>
> =>
  getMyAvailableServicePackages().then((data) => {
    return {
      ...data,
      data: {
        ...data.data,
        data: {
          ...data?.data?.data,
          items: data?.data?.data?.items?.filter(({ isPersonalized }) => isPersonalized === true),
        },
      },
    };
  });

export const buyMyServicePackages = (packageId: string): Promise<AxiosResponse<ResponseDataInterface<string>>> => {
  return axios.post(`/api/v1/servicepackages/my/buy/${packageId}`);
};
