import React, { useState, useEffect } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { Link } from 'react-router-dom';
import format from 'date-fns/format';
import classNames from 'classnames';
import ruLocale from 'date-fns/locale/ru';
import { Calendar, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { connect } from 'react-redux';
import { fetchDoctorSchedule, changeSetting } from 'store/actions';

// Components
import ContentCard from 'components/ContentCard';
import ContentLoading from 'components/ContentLoading';
import Button from 'components/Forms/Button';

import { ReactComponent as AngleLeftIcon } from 'assets/img/icons/angle-left-icon.svg';
import styles from './DoctorSchedulePage.module.sass';
import { isSameTime, isSameDay, PATTERN_TIME } from '../../utils/date';
import { EnumDictionary } from '../../types/EnumDictionary';

const DoctorSchedulePage = (props: any) => {
  const [doctorScheduleDate, setDoctorScheduleDate] = useState(new Date());
  const [doctorScheduleTime, setDoctorScheduleTime] = useState(null);

  useEffect(() => {
    props.fetchDoctorSchedule(props.newAppointment?.doctor?.id);
  }, []);

  useEffect(() => {
    if (props.doctorScheduleList.length > 0) {
      setDoctorScheduleDate(new Date(props.doctorScheduleList[0]));
      setDoctorScheduleTime(props.doctorScheduleList[0]);
    }
  }, [props.doctorScheduleList]);

  const { doctorScheduleList, doctorScheduleLoading, doctorScheduleError, newAppointment } = props;

  const doctor = newAppointment.doctor;

  const additionalServiceTypes = (doctor?.additionalServiceTypes || []) as Array<EnumDictionary<string>>;

  const onSelectAppointment = (serviceType?: EnumDictionary<string>) => {
    props.changeSetting('newAppointment', {
      ...newAppointment,
      date: doctorScheduleTime,
      additionalServiceType: serviceType,
    });

    props.history.push(`/doctor/payment`);
  };

  return (
    <ContentCard>
      <ContentCard.Header>
        <div className="row">
          <div className="col-xs-4 text-center text-xs-left mb-2 mb-xs-0">
            <div className="page-header-back-link" onClick={() => props.history.push('/')}>
              <AngleLeftIcon className="page-header-back-link-icon" />
              Назад
            </div>
          </div>
          <div className="col-xs-8 col-md-4 text-center text-xs-right text-md-center">
            <h6 className="page-header-title">Запись на консультацию</h6>
          </div>
        </div>
      </ContentCard.Header>

      <ContentCard.Main>
        {doctor ? (
          <ContentLoading
            isLoading={doctorScheduleLoading}
            isError={doctorScheduleError}
            fetchData={() => props.fetchDoctorSchedule(doctor.id)}
          >
            <div className={styles.doctorSchedule}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
                <div className="text-center">
                  <div className="customCalendarWrap">
                    <Calendar
                      date={doctorScheduleDate}
                      onChange={(date) => {
                        setDoctorScheduleDate(date);

                        const selectedScheduleDate = doctorScheduleList.find((scheduleDate) =>
                          isSameDay(new Date(scheduleDate), date)
                        );

                        if (selectedScheduleDate) {
                          setDoctorScheduleTime(selectedScheduleDate);
                        } else {
                          setDoctorScheduleTime(null);
                        }
                      }}
                      disablePast
                      shouldDisableDate={(date) =>
                        !doctorScheduleList.find((scheduleDate) => isSameDay(new Date(scheduleDate), date))
                      }
                    />
                  </div>
                </div>
              </MuiPickersUtilsProvider>

              {doctorScheduleList.filter((date) => {
                return isSameDay(doctorScheduleDate, new Date(date));
              }).length === 0 && <div className="mt-3 text-center">Нет времени для записи</div>}

              <div className={`${styles.doctorSchedule_timeList} mt-3`}>
                {doctorScheduleList
                  .filter((date) => {
                    return isSameDay(doctorScheduleDate, new Date(date));
                  })
                  .map((time, i) => (
                    <div
                      key={i}
                      className={classNames(styles.doctorSchedule_timeList_time, {
                        [styles.doctorSchedule_timeList_time_active]: isSameTime(
                          new Date(doctorScheduleTime),
                          new Date(time)
                        ),
                      })}
                      onClick={() => setDoctorScheduleTime(time)}
                    >
                      {format(new Date(time), PATTERN_TIME)}
                    </div>
                  ))}
              </div>

              {additionalServiceTypes.length > 0 &&
                additionalServiceTypes.map((value) => {
                  return (
                    <Button
                      className="mt-4"
                      color="primary"
                      block
                      disabled={!doctorScheduleTime}
                      onClick={(e) => {
                        e.preventDefault();

                        onSelectAppointment(value);
                      }}
                    >
                      {value.name} &nbsp;
                      {format(new Date(doctorScheduleDate), 'dd.MM.yyyy')}
                      {doctorScheduleTime ? ` в ${format(new Date(doctorScheduleTime), PATTERN_TIME)}` : ''}
                    </Button>
                  );
                })}

              <Button
                className="mt-4"
                color="primary"
                block
                disabled={!doctorScheduleTime}
                onClick={(e) => {
                  e.preventDefault();

                  onSelectAppointment();
                }}
              >
                Запись на &nbsp;
                {format(new Date(doctorScheduleDate), 'dd.MM.yyyy')}
                {doctorScheduleTime ? ` в ${format(new Date(doctorScheduleTime), PATTERN_TIME)}` : ''}
              </Button>
            </div>
          </ContentLoading>
        ) : (
          <div className="text-center">
            <h5>Для записи выберите пожалуйста услугу:</h5>
            <Link to="/">
              <Button size="sm">Услуги</Button>
            </Link>
          </div>
        )}
      </ContentCard.Main>
    </ContentCard>
  );
};

export default connect(
  (state: any) => ({
    newAppointment: state.storage.newAppointment,

    doctorScheduleList: state.doctorDetails.doctorScheduleList,
    doctorScheduleLoading: state.doctorDetails.doctorScheduleLoading,
    doctorScheduleError: state.doctorDetails.doctorScheduleError,
  }),
  {
    fetchDoctorSchedule,
    changeSetting,
  }
)(DoctorSchedulePage);
