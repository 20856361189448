import React, { useMemo, useRef } from 'react';
import { CartesianGrid, Line, ComposedChart, XAxis, YAxis, Area } from 'recharts';
import { Box } from '@material-ui/core';
import useRootSelector from 'hooks/useRootSelector';
import {
  selectJournalAveraged,
  selectJournalDomainXAxis,
  selectJournalDomainYAxis,
  selectJournalLoading,
  selectJournalMaxMin,
  selectJournalTypeUnit,
} from 'views/JournalPage/store/selectors';
import { getJournalColor } from 'views/JournalPage/helpers';
import styles from './JournalChart.module.sass';

const margin = { top: 5, right: 20, left: 0, bottom: 5 };
const JournalChart = (): JSX.Element => {
  const averaged = useRootSelector(selectJournalAveraged);
  const loading = useRootSelector(selectJournalLoading);
  const unit = useRootSelector(selectJournalTypeUnit);
  const domainX = useRootSelector(selectJournalDomainXAxis);
  const domainY = useRootSelector(selectJournalDomainYAxis);
  const minMax = useRootSelector(selectJournalMaxMin);
  const ref = useRef<HTMLDivElement>();
  const data = useMemo(
    () =>
      domainX.map((time) => {
        let res = { time };
        if (minMax) {
          minMax.forEach(({ min, max }, index) => {
            res = { ...res, [`range_${index}`]: [min, max] };
          });
          return res;
        }
        return res;
      }),
    [domainX, minMax]
  );
  if (loading) {
    return <></>;
  }
  return (
    <Box component={(props) => <div ref={ref} {...props} />} className={styles.root}>
      <ComposedChart
        className={styles.root_area}
        width={ref?.current?.clientWidth - 10}
        height={ref?.current?.clientHeight}
        margin={margin}
        data={data}
      >
        <XAxis domain={domainX} style={{ display: 'none' }} stroke="#9DA9CF" dataKey="time" />
        <YAxis domain={domainY} style={{ display: 'none' }} stroke="#9DA9CF" yAxisId="left" />
        {minMax &&
          minMax.map(({ color }, index) => (
            <Area type="monotone" yAxisId="left" key={index} dataKey={`range_${index}`} stroke={color} fill={color} />
          ))}
      </ComposedChart>
      <ComposedChart
        data={averaged}
        width={ref?.current?.clientWidth - 10}
        height={ref?.current?.clientHeight}
        margin={margin}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis domain={domainX} stroke="#9DA9CF" dataKey="time" allowDuplicatedCategory={false} />
        <YAxis domain={domainY} stroke="#9DA9CF" yAxisId="left" />
        {unit.map((k) => (
          <Line
            key={k}
            yAxisId="left"
            type="monotone"
            dataKey={k}
            stroke={getJournalColor(k)}
            dot={{ r: 10, strokeWidth: '3px' }}
          />
        ))}
      </ComposedChart>
    </Box>
  );
};

export default JournalChart;
