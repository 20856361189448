import { createEffect } from 'effector';
import { ResponseCode } from 'types';
import { toast } from 'react-toastify';
import { buyMyServicePackages, getMyAvailablePersonalDoctorServicePackages } from '$api';

export const onGetMyAvailablePersonalDoctorServicePackagesFx = createEffect(
  async (): Promise<Array<any>> => {
    const res = await getMyAvailablePersonalDoctorServicePackages().catch((res) => {
      return res;
    });
    if (res?.data?.code === ResponseCode.SUCCESS) {
      return res?.data?.data?.items || [];
    }
    toast.error('Ошибка при запросе');
    throw new Error(res?.data?.code);
  }
);

export const onMyBuyServicePackagesFx = createEffect(
  async (id: string): Promise<string> => {
    const res = await buyMyServicePackages(id)
      .then()
      .catch((res) => {
        return res;
      });
    if (res?.data?.code === ResponseCode.SUCCESS) {
      return res?.data?.data || null;
    }
    toast.error('Ошибка при запросе');
    throw new Error(res?.data?.code);
  }
);
