import React, { useState } from 'react';
import classNames from 'classnames';
import ContentCard from '../../../components/ContentCard';
import styles from './ReportsPageAdmin.module.sass';
import ReportsPageAdminAppointmentItems from './ReportsPageAdminAppointmentItems/ReportsPageAdminAppointmentItems';
import ReportsPageAdminPatientItems from './ReportsPageAdminPatientItems/ReportsPageAdminPatientItems';
import ReportsPageAdminServicePackagesItems from './ReportsPageAdminServicePackagesItems/ReportsPageAdminServicePackagesItems';
import ReportPageAdminServicePackageTransactionItems from './ReportsPageAdminServicePackageTransactionItems/ReportPageAdminServicePackageTransactionItems';
import ReportsPageAdminCloseDaySummaryItems from './ReportsPageAdminCloseDaySummaryItems';

type ReportsPageAdminTab =
  | 'reports.appointments'
  | 'reports.patients'
  | 'reports.servicePackages'
  | 'reports.servicePackageTransactions'
  | 'reports.closeDaySummary';
const ReportsPageAdmin = (props: any) => {
  const [view, setView] = useState<ReportsPageAdminTab>('reports.appointments');
  const [types] = useState([
    { label: 'Отчет по консультациям', value: 'reports.appointments' },
    { label: 'Отчет по пациентам', value: 'reports.patients' },
    { label: 'Отчет по пользовательским тарифам', value: 'reports.servicePackages' },
    { label: 'Отчет по платежам по тарифам', value: 'reports.servicePackageTransactions' },
    { label: 'Отчет о закрытии операционного дня', value: 'reports.closeDaySummary' },
  ]);
  const renderView = (): JSX.Element => {
    switch (view) {
      case 'reports.appointments':
        return <ReportsPageAdminAppointmentItems type={view} types={types} onChangeType={setView} />;
      case 'reports.patients':
        return <ReportsPageAdminPatientItems type={view} types={types} onChangeType={setView} />;
      case 'reports.servicePackages':
        return <ReportsPageAdminServicePackagesItems type={view} types={types} onChangeType={setView} />;
      case 'reports.servicePackageTransactions':
        return <ReportPageAdminServicePackageTransactionItems type={view} types={types} onChangeType={setView} />;
      case 'reports.closeDaySummary':
        return <ReportsPageAdminCloseDaySummaryItems type={view} types={types} onChangeType={setView} />;
      default:
        return <></>;
    }
  };

  const renderTitle = (): string => {
    switch (view) {
      case 'reports.appointments':
        return types[0].label;
      case 'reports.patients':
        return types[1].label;
      case 'reports.servicePackages':
        return types[2].label;
      case 'reports.servicePackageTransactions':
        return types[3].label;
      case 'reports.closeDaySummary':
        return types[4].label;
      default:
        return 'Неизвестный отчет';
    }
  };

  return (
    <>
      <ContentCard>
        <ContentCard.Header>
          <div className="row">
            <div className="col-12 text-center text-xs-right text-md-center">
              <h6 className="page-header-title">{renderTitle()}</h6>
            </div>
          </div>
        </ContentCard.Header>
        <ContentCard.Main className={styles.ReportsPageAdminTab_Content}>{renderView()}</ContentCard.Main>
      </ContentCard>
    </>
  );
};

export default ReportsPageAdmin;
