import React, { useEffect, useState } from 'react';
import { Backdrop, Box, Dialog, Grid, Avatar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import { formatDistanceStrict, parseISO } from 'date-fns';
import { AxiosResponse } from 'axios';
import { ru } from 'date-fns/locale';
import styles from './AdminPatientView.module.sass';
import ContentCard from '../ContentCard';
import ContentLoading from '../ContentLoading';
import Manage from '../../types/manage';
import InputField from '../Forms/InputField';
import { decodeAvatarResource } from '../../utils';
import { format } from '../../utils/date';
import { getAdminPatientInfo } from '../../api';

interface AppointmentDoctorViewInterface {
  patientId: string;
  onClose: () => void;
}

const AdminPatientView = (props: AppointmentDoctorViewInterface) => {
  const { onClose = () => {}, patientId } = props;
  const [patient, setPatient] = useState<Manage.Patient.PatientInfo>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>(null);
  useEffect(() => {
    getPatient();
  }, [patientId]);
  const getPatient = (): void => {
    setLoading(true);
    getAdminPatientInfo(patientId)
      .then((response: AxiosResponse<any>) => {
        if (response.data.code === 'success') {
          setPatient(response.data.data);
        } else {
          setErrors(response.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        setErrors(err);
        setLoading(false);
      });
  };
  return (
    <Dialog
      PaperProps={{ className: classNames(styles.Dialog) }}
      BackdropComponent={(props) => <Backdrop className={styles.Backdrop} {...props} />}
      onClose={onClose}
      open
    >
      <ContentCard className={styles.AdminPatientView}>
        <ContentCard.Header className={styles.AdminPatientView_Header}>
          <h6 className="page-header-title">Карточка пациента</h6>
          <CloseIcon onClick={onClose} className={styles.AdminPatientView_CloseIcon} />
        </ContentCard.Header>
        <ContentCard.Main className={styles.AdminPatientView_Content}>
          <ContentLoading isLoading={loading} isError={errors} fetchData={() => getPatient()}>
            {patient && (
              <form onSubmit={() => {}}>
                <Box
                  className={classNames(
                    styles.AdminPatientView_Content_Item,
                    styles.AdminPatientView_Content_Item_Profile
                  )}
                >
                  <Avatar
                    className={styles.AdminPatientView_Content_Item_Profile_Img}
                    src={decodeAvatarResource(patient.profile.avatar, 360, patient.profile.sex)}
                  />
                  <Box className={styles.AdminPatientView_Content_Item_Profile_Data}>
                    <Box className={styles.AdminPatientView_Content_Item_Profile_Name}>
                      {patient.profile.firstName} {patient.profile.lastName}
                    </Box>
                    <Box className={styles.AdminPatientView_Content_Item_Profile_Birthday}>
                      {patient.profile.birthday
                        ? `${format(parseISO(patient.profile.birthday), 'dd.MM.yyyy')} (${formatDistanceStrict(
                            parseISO(patient.profile.birthday),
                            new Date(),
                            {
                              locale: ru,
                              addSuffix: false,
                            }
                          )})`
                        : ''}
                    </Box>
                    <Box className={styles.AdminPatientView_Content_Item_Profile_Sex}>
                      {patient.profile.sex === 'MALE' ? 'Мужской' : patient.profile.sex === 'FEMALE' ? 'Женский' : ''}
                    </Box>
                  </Box>
                </Box>
                <Box className={classNames(styles.AdminPatientView_Content_Wrapper)}>
                  <Box className={classNames(styles.AdminPatientView_Content_Item)}>
                    <Box component="h4" mb={3}>
                      Персональные данные
                    </Box>
                    <Grid
                      className={classNames(styles.AdminPatientView_Content_Item_Container)}
                      container
                      justify="center"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item md={4} xs={12}>
                        <InputField
                          name="phone"
                          value={patient.profile.phone}
                          readOnly
                          onChange={(e) => {}}
                          block
                          label="Телефон"
                          placeholder="Телефон"
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <InputField
                          name="email"
                          value={patient.profile.email}
                          readOnly
                          onChange={(e) => {}}
                          block
                          label="Электронная почта"
                          placeholder="Электронная почта"
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <InputField
                          name="regDate"
                          value={
                            patient.profile.regDate
                              ? format(parseISO(patient.profile.regDate.toString()), 'dd.MM.yyyy')
                              : ''
                          }
                          readOnly
                          onChange={(e) => {}}
                          block
                          label="Дата регистрации"
                          placeholder="Дата регистрации"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <InputField
                          name="address"
                          value={patient.profile?.address}
                          type="textarea"
                          readOnly
                          onChange={(e) => {}}
                          block
                          label="Адрес регистрации"
                          placeholder="Адрес регистрации"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box className={classNames(styles.AdminPatientView_Content_Item)}>
                    <Box component="h4" mb={3}>
                      Баланс
                    </Box>
                    <Grid
                      className={classNames(styles.AdminPatientView_Content_Item_Container)}
                      container
                      justify="center"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item md={6} xs={12}>
                        <InputField
                          name="paymentTotal"
                          value={patient.paymentTotal ? patient.paymentTotal.toString() : '0'}
                          readOnly
                          onChange={(e) => {}}
                          block
                          label="Внесенные средства"
                          placeholder="Внесенные средства"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <InputField
                          name="balance"
                          value={patient.profile.balance ? patient.profile.balance.toString() : `0`}
                          readOnly
                          onChange={(e) => {}}
                          block
                          label="Текущий баланс"
                          placeholder="Текущий баланс"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </form>
            )}
          </ContentLoading>
        </ContentCard.Main>
      </ContentCard>
    </Dialog>
  );
};
export default AdminPatientView;
