import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { Avatar, Backdrop, Box, Dialog, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import { AxiosResponse } from 'axios';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ru } from 'date-fns/locale';
import { addYears, isValid, parseISO, subYears } from 'date-fns';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { Lightbox } from 'react-modal-image';
import { getDoctorCerts } from 'api';
import { createResource } from 'api/createResource';
import { createDoctor } from 'api/createDoctor';
import { updateDoctor } from 'api/updateDoctor';
import { deleteDoctorsCert } from 'api/deleteDoctorsCert';
import { getSpecializations } from 'api/getSpecializations';
import { getCategories } from 'api/getCategories';
import { updateDoctorSignature } from 'api/updateDoctorsSignature';
import { deleteDoctorSignature } from 'api/deleteDoctorsSignature';
import { addDoctorsCert } from 'api/addDoctorsCert';
import AdminAdditionalServicesSelectorView from 'components/AdminAdditionalServicesSelector';
import ContentCard from 'components/ContentCard';
import ContentLoading from 'components/ContentLoading';
import Button from 'components/Forms/Button';
import InputField from 'components/Forms/InputField';
import SelectInput from 'components/Forms/SelectInput';
import { format } from 'utils/date';
import { findTariffsAdmin } from 'api/findTariffsAdmin';
import {
  decodeAvatarResource,
  getFieldErrors as getFieldErrorsUtil,
  localStringToNumber,
  prependToTelegram,
  validateFieldOnChange,
} from 'utils';
import FormLabel from 'components/Forms/FormLabel';
import FormValidator from 'components/Forms/FormValidator';
import FormInvalidMessage from 'components/Forms/FormInvalidMessage';
import useApiFx from 'hooks/useApiFx';
import useStoreApi from 'hooks/useStoreApi';
import styles from './AdminDoctorView.module.sass';
import HasRole from '../HasRole';
import { UserRole } from '../../types';

interface PropsInterface {
  doctor: any;
  onClose?: (...args: any) => any;
  onSaved?: (...args: any) => any;
  disabled?: boolean;
}

const AdminDoctorView = (props: PropsInterface) => {
  const { onClose = () => {}, doctor, onSaved = () => {}, disabled = false } = props;
  const [inputFileAvatar, setInputFileAvatar] = useState<HTMLInputElement>(null);
  const [inputFileSign, setInputFileSign] = useState<HTMLInputElement>(null);
  const [fileAvatar, addFileAvatar] = useState<File>(null);
  const [fileSignature, addFileSignature] = useState<File>(null);
  const [fileAvatarBlob, addFileAvatarBlob] = useState<string>(null);
  const [fileSignatureBlob, addFileSignatureBlob] = useState<string>(doctor.signature?.originalUrl);
  const [inputFileDocs, setInputFileDocs] = useState<HTMLInputElement>(null);
  const [fileDocs, addFileDocs] = useState<File[]>([]);
  const [fileDocsBlob, addFileDocsBlob] = useState<string[]>([]);
  const [categories, setCategories] = useState<any>([]);
  const [specializations, setSpecializations] = useState<any>([]);
  const [tariffs, setTariffs] = useState<any>([]);
  const [docs, setDocs] = useState<any>([]);
  const [removedDocs, setRemovedDocs] = useState<any>([]);
  const [isPreviewOpen, setPreviewOpen] = useState<string>(null);
  const [formErrors, setFormErrors] = useState<any>([]);
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const [fields, setFields] = useState<any>({
    avatarId: doctor?.avatar?.id || null,
    firstName: doctor?.firstName,
    lastName: doctor?.lastName,
    middleName: doctor?.middleName,
    phone: doctor?.phone ? String(localStringToNumber(doctor?.phone)) : null,
    email: doctor?.email,
    tgUsername: doctor?.tgUsername,
    education: doctor?.education,
    workingSinceYear: doctor?.workingSinceYear || 0,
    description: doctor?.description,
    additionalInfo: doctor?.additionalInfo,
    durationMinutes: doctor?.durationMinutes || null,
    sex: doctor?.sex?.value || 'MALE',
    birthday: doctor?.birthday,
    specializationId: doctor?.specialization?.id || null,
    categoryId: doctor?.category?.id || null,
    tariffId: doctor?.tariff?.id || null,
    serviceTypes: doctor?.additionalServiceTypes?.map((value) => value?.value) || [],
    isPersonal: doctor?.isPersonal || false,
  });
  const isNew = useMemo(() => !doctor?.id, [doctor]);
  const init = useCallback(
    () =>
      Promise.all([
        getCategories().then((response) => response?.data?.data.items),
        getSpecializations().then((response) => response?.data?.data.items),
        findTariffsAdmin().then((response) => response.data?.data.items),
        !doctor.id ? Promise.resolve([]) : getDoctorCerts(doctor.id).then((response) => response.data?.data.items),
      ]).then(),
    [doctor]
  );
  const initFx = useApiFx(init);
  const { loading, error, data } = useStoreApi(initFx);
  useEffect(() => {
    if (data) {
      setCategories(data?.[0]);
      setSpecializations(data?.[1]);
      setTariffs(data?.[2]);
      setDocs(data?.[3]);
    }
  }, [data]);
  useEffect(() => {
    initFx({}).then();
  }, [initFx]);
  const specializationSelected = useMemo(
    () =>
      fields.specializationId
        ? {
            value: fields.specializationId,
            label: (specializations.find(({ id }) => id === fields.specializationId) || {}).name,
          }
        : null,
    [fields, specializations]
  );
  const categorySelected = useMemo(
    () =>
      fields.categoryId
        ? {
            value: fields.categoryId,
            label: (categories.find(({ id }) => id === fields.categoryId) || {}).name,
          }
        : null,
    [fields, categories]
  );
  const tariffSelected = useMemo(
    () =>
      fields.tariffId
        ? {
            value: fields.tariffId,
            label: (tariffs.find(({ id }) => id === fields.tariffId) || {}).name,
          }
        : null,
    [fields, tariffs]
  );
  const onChangeAvatar = useCallback(
    (event: any) => {
      const f: File = (event.target.files as FileList).item(0);
      addFileAvatar(f);
      try {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          addFileAvatarBlob(e.target.result);
        };
        reader.readAsDataURL(f);
      } catch (e) {
        addFileAvatarBlob(null);
      }
      inputFileAvatar.value = '';
    },
    [inputFileAvatar]
  );
  const onChangeSignature = useCallback(
    (event: any) => {
      const f: File = (event.target.files as FileList).item(0);
      addFileSignature(f);
      try {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          addFileSignatureBlob(e.target.result);
        };
        reader.readAsDataURL(f);
      } catch (e) {
        addFileSignatureBlob(null);
      }
      inputFileSign.value = '';
    },
    [inputFileSign]
  );
  const onChangeDocs = useCallback(
    (event: any) => {
      const fs: FileList = event.target.files as FileList;
      const files: File[] = [];
      for (let i = 0; i < fs.length; i++) {
        const f: File = fs.item(i);
        files.push(f);
      }
      addFileDocs([...fileDocs, ...files]);
      Promise.all(
        files.map(
          (f: File) =>
            new Promise((resolve) => {
              try {
                const reader = new FileReader();
                reader.onload = (e: any) => {
                  resolve(e.target.result);
                };
                reader.readAsDataURL(f);
              } catch (e) {
                resolve(null);
              }
            })
        )
      ).then((filesBlob: string[]) => addFileDocsBlob([...fileDocsBlob, ...filesBlob]));
      inputFileDocs.value = '';
    },
    [fileDocs, fileDocsBlob, inputFileDocs]
  );
  const validateOnChange = useCallback(
    (name: string, value: any, event, element?: any) => {
      validateFieldOnChange(name, value, event, fields, setFields, formErrors, setFormErrors, element);
    },
    [fields, formErrors]
  );
  const getFieldErrors = useCallback((field: string) => getFieldErrorsUtil(field, formErrors), [formErrors]);
  const store = useCallback(() => {
    setLoadingSave(true);
    (fileAvatar ? createResource(fileAvatar) : Promise.resolve())
      .then((resourceId) =>
        isNew
          ? createDoctor({
              ...fields,
              phone: String(localStringToNumber(fields.phone)),
              tgUsername: fields.tgUsername,
              avatarId: resourceId || fields.avatarId,
            })
          : updateDoctor(doctor.id, {
              ...fields,
              phone: String(localStringToNumber(fields.phone)),
              tgUsername: fields.tgUsername,
              avatarId: resourceId || fields.avatarId,
            })
      )
      .then((response: AxiosResponse<any>) => {
        if (response.data.code === 'success' && (!isNew || (isNew && response.data.data.id))) {
          if (!isNew && removedDocs.length) {
            return Promise.all(removedDocs.map(({ id }) => deleteDoctorsCert(doctor.id, id))).then(() => doctor.id);
          }
          return Promise.resolve(isNew ? response.data.data.id : doctor.id);
        }
        throw new Error(response.data);
      })
      .then(async (doctorId) => {
        if (fileDocs.length) {
          await Promise.all(fileDocs.map((f: File) => createResource(f))).then((res: string[]) =>
            Promise.all(res.map((r: string) => addDoctorsCert(doctorId, r)))
          );
        }
        if (fileSignature) {
          await createResource(fileSignature).then((res) => updateDoctorSignature(doctorId, res));
        } else if (!fileSignatureBlob && doctor.signature) {
          await deleteDoctorSignature(doctorId);
        }
      })
      .then(() => {
        setLoadingSave(false);
        onSaved();
      })
      .catch(() => {
        toast.error('Ошибка при запросе');
        setLoadingSave(false);
      });
  }, [doctor, fields, fileAvatar, fileDocs, fileSignature, fileSignatureBlob, isNew, onSaved, removedDocs]);
  const submit = useCallback(
    (e) => {
      if (disabled) {
        return;
      }
      e.preventDefault();
      const form = e.target;
      const inputs = [...form.elements].filter((i) => ['INPUT', 'SELECT', 'TEXTAREA'].includes(i.nodeName));

      const { errors, hasError } = FormValidator.bulkValidate(inputs);
      console.log(errors);
      setFormErrors(errors);

      if (!hasError) {
        store();
      }
    },
    [disabled, store]
  );
  const beforeMaskedValueChange = useCallback((newState) => {
    let { value, selection } = newState;
    if (value?.length > 1 && value[1] === '8') {
      value = value.replace('+8', '+7');
    }
    if (value?.length > 1 && value[1] !== '7' && value[1] !== '_') {
      const { index = 1 } = value.match(/[^+0-9]/) || {};
      selection = { start: index + 3, end: index + 3 };
      value = value.replace('+', '+7').substr(0, 18);
    }
    return {
      ...newState,
      value,
      selection,
    };
  }, []);
  return (
    <Dialog
      PaperProps={{ className: classNames(styles.Dialog) }}
      BackdropComponent={(props) => <Backdrop className={styles.Backdrop} {...props} />}
      onClose={onClose}
      open
    >
      <ContentCard className={styles.AdminDoctorView}>
        <ContentCard.Header className={styles.AdminDoctorView_Header}>
          <h6 className="page-header-title">
            {isNew
              ? 'Добавить доктора'
              : [doctor.firstName, doctor.middleName, doctor.lastName].filter((s) => s).join(' ')}
          </h6>
          <CloseIcon onClick={onClose} className={styles.AdminDoctorView_CloseIcon} />
        </ContentCard.Header>
        <ContentCard.Main className={styles.AdminDoctorView_Content}>
          <ContentLoading isLoading={loading} isError={error} fetchData={() => initFx({}).then()}>
            <form onSubmit={submit}>
              <Box className={classNames(styles.AdminDoctorView_Content_Item)}>
                <Avatar
                  className={styles.AdminDoctorView_Content_Item_ProfileImg}
                  src={fileAvatarBlob || decodeAvatarResource(doctor.avatar, 360, doctor.sex)}
                />
                {!disabled && (
                  <Box className={styles.AdminDoctorView_Content_Item_ProfileImg_Add}>
                    Добавить фото
                    <input ref={setInputFileAvatar} type="file" onChange={onChangeAvatar} />
                  </Box>
                )}
              </Box>
              <Box className={classNames(styles.AdminDoctorView_Content_Wrapper)}>
                <Box className={classNames(styles.AdminDoctorView_Content_Item)}>
                  <Box component="h4" mb={3}>
                    Персональные данные
                  </Box>
                  <Grid
                    className={classNames(styles.AdminDoctorView_Content_Item_Container)}
                    container
                    justify="flex-start"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item md={4} xs={12}>
                      <InputField
                        name="firstName"
                        disabled={disabled}
                        value={fields.firstName}
                        onChange={(e) => validateOnChange('firstName', e.target.value, e)}
                        data-validate='["required", "name", "maxlen"]'
                        data-param={36}
                        errors={getFieldErrors('firstName')}
                        block
                        placeholder="Имя"
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <InputField
                        name="lastName"
                        disabled={disabled}
                        value={fields.lastName}
                        onChange={(e) => validateOnChange('lastName', e.target.value, e)}
                        data-validate='["required", "name", "maxlen"]'
                        data-param={36}
                        errors={getFieldErrors('lastName')}
                        block
                        placeholder="Фамилия"
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <InputField
                        name="middleName"
                        value={fields.middleName}
                        disabled={disabled}
                        onChange={(e) => validateOnChange('middleName', e.target.value, e)}
                        data-validate='["required", "name", "maxlen"]'
                        data-param={36}
                        errors={getFieldErrors('middleName')}
                        block
                        placeholder="Отчество"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
                        <FormLabel invalid={getFieldErrors('birthday') && getFieldErrors('birthday').length}>
                          День рождения
                        </FormLabel>
                        <KeyboardDatePicker
                          autoOk
                          disabled={disabled}
                          maxDate={addYears(new Date(), -18)}
                          disableToolbar
                          value={fields.birthday ? parseISO(fields.birthday) : null}
                          onChange={(date) => {
                            if (getFieldErrors('birthday') && getFieldErrors('birthday').length) {
                              setFormErrors(formErrors.filter((err) => getFieldErrors('birthday').indexOf(err) === -1));
                            }
                            if (!date) {
                              setFields({
                                ...fields,
                                birthday: null,
                              });
                            } else {
                              isValid(date) &&
                                setFields({
                                  ...fields,
                                  birthday: date.toISOString(),
                                });
                            }
                          }}
                          format="dd-MM-yyyy"
                          views={['year', 'month', 'date']}
                          disableFuture
                          animateYearScrolling
                          openTo="year"
                          className={classNames({
                            'MuiInputBase-input-error': getFieldErrors('birthday').length > 0,
                          })}
                          inputProps={{ name: 'birthday' }}
                          cancelLabel="Отменить"
                          placeholder="День рождения"
                          invalidDateMessage="Неверный формат"
                          maxDateMessage="Возраст должен быть 18+"
                          okLabel="OK"
                        />
                      </MuiPickersUtilsProvider>
                      {getFieldErrors('birthday') && getFieldErrors('birthday')[0] && (
                        <FormInvalidMessage>{getFieldErrors('birthday')[0].message}</FormInvalidMessage>
                      )}
                      <input
                        name="birthday"
                        /* data-validate='["required"]' */
                        type="hidden"
                        disabled={disabled}
                        value={fields.birthday ? fields.birthday : ''}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <SelectInput
                        name="sex"
                        disabled={disabled}
                        value={
                          fields.sex
                            ? { value: fields.sex, label: fields.sex === 'MALE' ? 'Мужской' : 'Женский' }
                            : null
                        }
                        onChange={(option, e) => validateOnChange('sex', option.value, e)}
                        data-validate='["required"]'
                        options={[
                          { value: 'MALE', label: 'Мужской' },
                          { value: 'FEMALE', label: 'Женский' },
                        ]}
                        errors={getFieldErrors('sex')}
                        block
                        label="Пол"
                        placeholder="Пол"
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <SelectInput
                        disabled={disabled}
                        name="specializationId"
                        value={specializationSelected}
                        onChange={(option, e) => validateOnChange('specializationId', option.value, e)}
                        data-validate='["required"]'
                        errors={getFieldErrors('specializationId')}
                        options={specializations
                          .filter(({ active }) => (isNew ? active === true : true))
                          .map(({ name, id, active }) => ({ label: name, value: id, isDisabled: active === false }))}
                        block
                        label="Специализация"
                        placeholder="Специализация"
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <SelectInput
                        disabled={disabled}
                        name="categoryId"
                        value={categorySelected}
                        onChange={(option, e) => validateOnChange('categoryId', option.value, e)}
                        data-validate='["required"]'
                        errors={getFieldErrors('categoryId')}
                        options={categories
                          .filter(({ active }) => (isNew ? active === true : true))
                          .map(({ name, id, active }) => ({ label: name, value: id, isDisabled: active === false }))}
                        block
                        label="Категория"
                        placeholder="Категория"
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <SelectInput
                        disabled={disabled}
                        name="tariffId"
                        value={tariffSelected}
                        onChange={(option, e) => validateOnChange('tariffId', option.value, e)}
                        data-validate='["required"]'
                        errors={getFieldErrors('tariffId')}
                        options={tariffs
                          .filter(({ active }) => (isNew ? active === true : true))
                          .map(({ name, id, active }) => ({
                            label: name,
                            value: id,
                            isDisabled: active === false,
                          }))}
                        block
                        label="Тариф"
                        placeholder="Тариф"
                      />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <InputField
                        disabled={disabled}
                        name="durationMinutes"
                        type="number"
                        min={1}
                        value={fields.durationMinutes ? fields.durationMinutes.toString() : null}
                        onChange={(e) => validateOnChange('durationMinutes', e.target.value, e)}
                        data-validate='["number"]'
                        errors={getFieldErrors('middleName')}
                        block
                        label="Длина консультации"
                        placeholder="Длина консультации"
                      />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      {/* todo need common style */}
                      <Box className={styles.AdminDoctorView_CheckBox}>Персональный доктор</Box>
                      <label className="toggle-checkbox-label">
                        <input
                          name="isPersonal"
                          type="checkbox"
                          checked={fields.isPersonal}
                          value={fields.isPersonal}
                          onChange={(e) => validateOnChange('isPersonal', e.target.checked, e)}
                        />
                        <span className="checkmark">
                          <span className="check" />
                        </span>
                      </label>
                    </Grid>
                  </Grid>
                </Box>
                <Box className={classNames(styles.AdminDoctorView_Content_Item)}>
                  <Box component="h4" mb={3}>
                    Контактные данные
                  </Box>
                  <Grid
                    className={classNames(styles.AdminDoctorView_Content_Item_Container)}
                    container
                    justify="center"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item md={4} xs={12}>
                      <InputField
                        disabled={disabled}
                        name="phone"
                        value={fields.phone}
                        onChange={(e) => validateOnChange('phone', e.target.value, e)}
                        errors={getFieldErrors('phone')}
                        data-validate='["required", "phone"]'
                        block
                        placeholder="Телефон"
                        beforeMaskedValueChange={beforeMaskedValueChange}
                        mask="+9 (999) 999-99-99"
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <InputField
                        name="email"
                        disabled={!isNew || disabled}
                        value={fields.email}
                        onChange={(e) => validateOnChange('email', e.target.value, e)}
                        data-validate={isNew ? '["required", "email", "maxlen"]' : ''}
                        data-param={36}
                        block
                        errors={getFieldErrors('email')}
                        placeholder="E-mail"
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <InputField
                        disabled={disabled}
                        name="tgUsername"
                        value={fields.tgUsername}
                        onChange={(e) => validateOnChange('tgUsername', prependToTelegram(e.target.value), e)}
                        data-validate='["required", "maxlen"]'
                        data-param={20}
                        block
                        errors={getFieldErrors('tgUsername')}
                        placeholder="Telegram профиль"
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box className={classNames(styles.AdminDoctorView_Content_Item)}>
                  <Box component="h4" mb={3}>
                    Информация о враче
                  </Box>
                  <Grid
                    className={classNames(styles.AdminDoctorView_Content_Item_Container)}
                    container
                    justify="center"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item xs={12}>
                      <InputField
                        disabled={disabled}
                        name="description"
                        type="textarea"
                        value={fields.description}
                        onChange={(e) => validateOnChange('description', e.target.value, e)}
                        data-validate='["required"]'
                        errors={getFieldErrors('description')}
                        block
                        placeholder="Информация о враче"
                        label="Информация о враче"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputField
                        disabled={disabled}
                        name="additionalInfo"
                        type="textarea"
                        value={fields.additionalInfo}
                        onChange={(e) => validateOnChange('additionalInfo', e.target.value, e)}
                        data-validate='["required"]'
                        errors={getFieldErrors('additionalInfo')}
                        block
                        placeholder="Дополнительная информация"
                        label="Дополнительная информация"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <AdminAdditionalServicesSelectorView
                        name="serviceTypes"
                        value={fields.serviceTypes}
                        isMulti
                        onChange={(option, e) => {
                          validateOnChange(
                            'serviceTypes',
                            option?.map((v) => v.value),
                            e
                          );
                        }}
                        disabled={disabled}
                        errors={getFieldErrors('serviceTypes')}
                        block
                        label="Дополнительные услуги"
                        placeholder="Дополнительные услуги"
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box className={classNames(styles.AdminDoctorView_Content_Item)}>
                  <Box component="h4" mb={3}>
                    Информация об образовании врача
                  </Box>
                  <Grid
                    className={classNames(styles.AdminDoctorView_Content_Item_Container)}
                    container
                    justify="center"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item md={4} xs={12}>
                      <SelectInput
                        disabled={disabled}
                        name="workingSinceYear"
                        value={
                          fields.workingSinceYear
                            ? { value: fields.workingSinceYear, label: fields.workingSinceYear.toString() }
                            : null
                        }
                        onChange={(option, e) => validateOnChange('workingSinceYear', option.value, e)}
                        errors={getFieldErrors('workingSinceYear')}
                        data-validate='["required"]'
                        options={Array.from(Array(100).keys()).map((index: number) => {
                          const year: Date = subYears(new Date(), index);
                          return { value: +format(year, 'yyyy'), label: format(year, 'yyyy') };
                        })}
                        block
                        placeholder="Стаж"
                      />
                    </Grid>
                    <Grid item md={8} xs={12}>
                      <InputField
                        disabled={disabled}
                        name="education"
                        value={fields.education}
                        onChange={(e) => validateOnChange('education', e.target.value, e)}
                        data-validate='["required"]'
                        errors={getFieldErrors('education')}
                        block
                        placeholder="Информация об образовании врача"
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box className={classNames(styles.AdminDoctorView_Content_Item)}>
                  <Box component="h4" mb={3}>
                    Сертификаты
                  </Box>
                  <Box className={classNames(styles.AdminDoctorView_Content_Item_Docs)}>
                    {docs
                      .filter((doc: any) => !removedDocs.some((d) => d.id === doc.id))
                      .map((doc: any, index: number) => (
                        <Box key={index} className={classNames(styles.AdminDoctorView_Content_Item_Docs_Box)}>
                          <Box
                            onClick={() => {
                              doc.originalUrl && setPreviewOpen(doc.originalUrl);
                            }}
                            className={classNames(styles.AdminDoctorView_Content_Item_Docs_Box_Item)}
                            style={{ backgroundImage: `url(${doc.originalUrl})` }}
                          />
                          <Box
                            onClick={() => {
                              setRemovedDocs([...removedDocs, doc]);
                            }}
                            className={classNames(styles.AdminDoctorView_Content_Item_Docs_Box_Action)}
                          >
                            Удалить
                          </Box>
                        </Box>
                      ))}
                    {fileDocs.map((f: File, index: number) => (
                      <Box key={index} className={classNames(styles.AdminDoctorView_Content_Item_Docs_Box)}>
                        <Box
                          onClick={() => {
                            fileDocsBlob[index] && setPreviewOpen(fileDocsBlob[index]);
                          }}
                          className={classNames(styles.AdminDoctorView_Content_Item_Docs_Box_Item)}
                          style={{ backgroundImage: `url(${fileDocsBlob[index] ? fileDocsBlob[index] : ''})` }}
                        />
                        <Box
                          onClick={() => {
                            addFileDocs(fileDocs.filter((f, i) => i !== index));
                            addFileDocsBlob(fileDocsBlob.filter((f, i) => i !== index));
                          }}
                          className={classNames(styles.AdminDoctorView_Content_Item_Docs_Box_Action)}
                        >
                          Удалить
                        </Box>
                      </Box>
                    ))}
                  </Box>
                  {!disabled && (
                    <Box className={classNames(styles.AdminDoctorView_Content_Item_Docs_Add)}>
                      <AddCircleOutlineIcon className={classNames(styles.AdminDoctorView_Content_Item_Docs_Add_Icon)} />
                      <input ref={setInputFileDocs} type="file" multiple onChange={onChangeDocs} />
                    </Box>
                  )}
                </Box>
                <Box className={classNames(styles.AdminDoctorView_Content_Item)}>
                  <Box component="h4" mb={3}>
                    Подпись
                  </Box>
                  <Box className={classNames(styles.AdminDoctorView_Content_Item_Docs)}>
                    {fileSignatureBlob && (
                      <Box key="signatureFile" className={classNames(styles.AdminDoctorView_Content_Item_Docs_Box)}>
                        <Box
                          onClick={() => {
                            setPreviewOpen(fileSignatureBlob);
                          }}
                          className={classNames(styles.AdminDoctorView_Content_Item_Docs_Box_Item)}
                          style={{ backgroundImage: `url(${fileSignatureBlob})` }}
                        />
                        <Box
                          onClick={() => {
                            addFileSignature(null);
                            addFileSignatureBlob(null);
                          }}
                          className={classNames(styles.AdminDoctorView_Content_Item_Docs_Box_Action)}
                        >
                          Удалить
                        </Box>
                      </Box>
                    )}
                  </Box>
                  {!disabled && (
                    <Box className={classNames(styles.AdminDoctorView_Content_Item_Docs_Add)}>
                      <AddCircleOutlineIcon className={classNames(styles.AdminDoctorView_Content_Item_Docs_Add_Icon)} />
                      <input ref={setInputFileSign} type="file" onChange={onChangeSignature} />
                    </Box>
                  )}
                </Box>
              </Box>
              <HasRole target={UserRole.ADMIN}>
                <Box className={classNames(styles.AdminDoctorView_Content_Item)} pb={2} pt={2}>
                  <Button
                    size="md"
                    color="primary"
                    type="submit"
                    isLoading={loadingSave}
                    className={classNames(styles.AdminDoctorView_Content_Item_Save)}
                  >
                    Сохранить
                  </Button>
                </Box>
              </HasRole>
              {isPreviewOpen && (
                <Lightbox
                  medium={isPreviewOpen}
                  large={isPreviewOpen}
                  onClose={() => setPreviewOpen(null)}
                  imageBackgroundColor="#fff"
                />
              )}
            </form>
          </ContentLoading>
        </ContentCard.Main>
      </ContentCard>
    </Dialog>
  );
};
export default AdminDoctorView;
