import { Maybe } from 'types';

export enum AgentSaleType {
  NEW = 'NEW',
  CURRENT = 'CURRENT',
}

export type AgentSalePsp = {
  phone?: Maybe<string>;
  internalTariffId?: Maybe<string | number>;
  confirmCode?: Maybe<string>;
};

export type AgentSaleUserPsp = {
  firstName?: Maybe<string>;
  lastName?: Maybe<string>;
  middleName?: Maybe<string>;
  email?: Maybe<string>;
  sex?: Maybe<string>;
  birthday?: Maybe<Date>;
} & AgentSalePsp;
