import axios from 'utils/axios';
import { AppointmentDisplayType, getAppointmentStatuses } from 'types';

export const getAppointments = (status: AppointmentDisplayType, appointmentType, page: number, pageSize: string) => {
  return axios.get('/api/v1/appointments', {
    params: {
      statuses: `${getAppointmentStatuses(status)}`,
      page,
      pageSize,
      appointmentType,
    },
  });
};
