import React, { PropsWithChildren } from 'react';

import Sidebar from 'components/Sidebar';
import styles from './MainContent.module.sass';

interface PropsInterface extends PropsWithChildren<any> {
  showSidebar?: boolean;
}
const MainContent = (props: PropsInterface): JSX.Element => {
  const { showSidebar = true, children } = props;
  return (
    <div className={styles.root}>
      {showSidebar && <Sidebar />}
      <main className={styles.root_wrapper}>
        <>{children}</>
      </main>
    </div>
  );
};

export default MainContent;
