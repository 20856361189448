import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import classNames from 'classnames';
import { connect } from 'react-redux';
import styles from './SettingsPageAdminSpec.module.sass';
import Button from '../../../../components/Forms/Button';
import { fetchManageSpec } from '../../../../store/actions';
import Manage from '../../../../types/manage';
import ContentLoading from '../../../../components/ContentLoading';
import { ManageSpecReducerInitialSettings } from '../../../../store/reducers/manageSpecReducer.reducer';
import AdminSpecView from '../../../../components/AdminSpecView';

type SettingsPageAdminSpecFilter = 'all' | 'active' | 'archive';
interface PropsInterface {
  loading: boolean;
  spec: Manage.Spec.Item[];
  fetchManageSpec: (active: boolean) => void;
  errors: any;
}
const SettingsPageAdminSpec = (props: PropsInterface) => {
  const { loading, spec, fetchManageSpec, errors } = props;
  const [filter, setFilter] = useState<SettingsPageAdminSpecFilter>('all');
  const [specialization, setSpecialization] = useState<Manage.Spec.Item>(null);
  useEffect(() => {
    getSpec();
  }, [filter]);
  const getSpec = (): void => {
    fetchManageSpec(filter === 'active' ? true : filter === 'archive' ? false : null);
  };
  return (
    <>
      <Box className={classNames(styles.SettingsPageAdminSpec)}>
        <Box className={classNames(styles.SettingsPageAdminSpec_Section, styles.SettingsPageAdminSpec_Toolbar)}>
          <Button
            className="mr-4"
            size="sm"
            color={filter === 'all' ? 'primary' : 'default'}
            onClick={() => {
              filter !== 'all' && setFilter('all');
            }}
          >
            Все
          </Button>
          <Button
            className="mr-4"
            size="sm"
            color={filter === 'active' ? 'primary' : 'default'}
            onClick={() => {
              filter !== 'active' && setFilter('active');
            }}
          >
            Активные
          </Button>
          <Button
            size="sm"
            color={filter === 'archive' ? 'primary' : 'default'}
            onClick={() => {
              filter !== 'archive' && setFilter('archive');
            }}
          >
            В архиве
          </Button>
        </Box>
        <Box className={classNames(styles.SettingsPageAdminSpec_Section, styles.SettingsPageAdminSpec_Content)}>
          <ContentLoading isLoading={loading} isError={errors} fetchData={() => getSpec()}>
            {spec.length === 0 && <Box>У вас пока нет созданных специализаций</Box>}
            {spec.length > 0 && (
              <Box className={classNames(styles.SettingsPageAdminSpec_Specializations)}>
                {spec.map((item: Manage.Spec.Item, index: number) => (
                  <Box className={classNames(styles.SettingsPageAdminSpec_Specializations_Specialization)} key={index}>
                    <Box className={classNames(styles.SettingsPageAdminSpec_Specializations_Specialization_Data)}>
                      <Box
                        className={classNames(styles.SettingsPageAdminSpec_Specializations_Specialization_Data_Name)}
                      >
                        {item.name}
                      </Box>
                      <Box className={classNames(styles.SettingsPageAdminSpec_Specializations_Specialization_Data_Id)}>
                        {item.id}
                      </Box>
                      {item.tariff && item.tariff.name && (
                        <Box
                          className={classNames(
                            styles.SettingsPageAdminSpec_Specializations_Specialization_Data_Tariff
                          )}
                        >
                          {item.tariff.name}
                        </Box>
                      )}
                      {item.durationMinutes && (
                        <Box
                          className={classNames(
                            styles.SettingsPageAdminSpec_Specializations_Specialization_Data_Duration
                          )}
                        >
                          Продолжительность консультации: {item.durationMinutes} (мин)
                        </Box>
                      )}
                      <Box
                        className={classNames(
                          styles.SettingsPageAdminSpec_Specializations_Specialization_Data_Status,
                          item.active === true &&
                            styles.SettingsPageAdminSpec_Specializations_Specialization_Data_Status_Active
                        )}
                      >
                        {item.active === true ? 'Активный' : 'В архиве'}
                      </Box>
                    </Box>
                    <Box className={classNames(styles.SettingsPageAdminSpec_Specializations_Specialization_Actions)}>
                      <Button size="sm" color="default" onClick={() => setSpecialization(item)}>
                        Редактировать
                      </Button>
                    </Box>
                  </Box>
                ))}
              </Box>
            )}
          </ContentLoading>
        </Box>
        <Box className={classNames(styles.SettingsPageAdminSpec_Section, styles.SettingsPageAdminSpec_Action)}>
          <Button size="md" color="primary" onClick={() => setSpecialization({})}>
            Создать новую специализацию
          </Button>
        </Box>
      </Box>
      {specialization && (
        <AdminSpecView
          spec={specialization}
          onSave={() => {
            getSpec();
            setSpecialization(null);
          }}
          onClose={() => setSpecialization(null)}
        />
      )}
    </>
  );
};
export default connect(
  (state: any) => ({
    ...(state.manageSpec as ManageSpecReducerInitialSettings),
  }),
  {
    fetchManageSpec,
  }
)(SettingsPageAdminSpec);
