import axios from 'utils/axios';

export const authEmailLogin = (email: string, password: string) => {
  return axios.post('/api/v1/auth/emails/token', {
    email,
    password,
  });
};

export const authEsiaLogin = (code: string) => {
  return axios.post('/api/v1/auth/esia', {
    code,
  });
};
