import React from 'react';
import { useDropzone, DropzoneOptions } from 'react-dropzone';

import { FileDataType } from 'types/file';

import { loadFile, getUrlForUploading, validateFile } from './utils';

export type FileType = {
  file: File;
  id: string | null;
  isLoaded: boolean;
  isFailed: boolean;
  originalUrl: string | undefined;
  contentType: string | null;
};

type Options = {
  disabled?: boolean;
  usePasteEvent?: boolean;
  initFiles?: FileDataType[];
  additionalFiles?: FileDataType[];
  onFailedLoadFile?: (fileName: string) => void;
  dropElement?: any;
  fileName?: string;
  beforeUpload?: (data: File) => Promise<File>;
} & DropzoneOptions;

export default ({
  initFiles,
  additionalFiles,
  onFailedLoadFile,
  disabled,
  accept,
  usePasteEvent,
  dropElement,
  fileName,
  multiple,
  beforeUpload,
  ...options
}: Options) => {
  let screenCount = 0;
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [files, setFiles] = React.useState<FileType[]>([]);

  React.useEffect(() => {
    if (!dropElement) {
      return;
    }

    if (disabled) {
      dropElement.removeEventListener('drop', handleDrop);

      return;
    }

    dropElement.addEventListener('drop', handleDrop);

    return () => {
      dropElement.removeEventListener('drop', handleDrop);
    };
  }, [files, disabled, dropElement]);

  React.useEffect(() => {
    if (!usePasteEvent || disabled) {
      window.removeEventListener('paste', handlePaste, false);

      return;
    }

    window.addEventListener('paste', handlePaste, false);

    return () => {
      window.removeEventListener('paste', handlePaste, false);
    };
  }, [usePasteEvent, disabled]);

  React.useEffect(() => {
    if (!initFiles) {
      return;
    }

    const filesToAdd = files.length
      ? initFiles.filter((file) => files.every((item) => item.id !== file.id))
      : initFiles;
    const initData = filesToAdd.map((item) => {
      const file = new File([''], item.fileName);

      return {
        file,
        id: item.id,
        isLoaded: true,
        isFailed: false,
        fileName: item.fileName,
        formatUrls: item.formatUrls,
        contentType: item.contentType,
        originalUrl: item.originalUrl,
      };
    });

    if (initData.length) {
      setFiles(files.concat(...initData));
    }
  }, [initFiles]);

  React.useEffect(() => {
    if (!additionalFiles) {
      return;
    }

    const filesToAdd = files.length
      ? additionalFiles.filter((file) => files.every((item) => item.id !== file.id))
      : additionalFiles;
    const initData = filesToAdd.map((item) => {
      const file = new File([''], item.fileName);

      return {
        file,
        id: item.id,
        isLoaded: true,
        isFailed: false,
        fileName: item.fileName,
        formatUrls: item.formatUrls,
        contentType: item.contentType,
        originalUrl: item.originalUrl,
      };
    });

    if (initData.length) {
      setFiles(files.concat(...initData));
    }
  }, [additionalFiles]);

  const handlePaste = async (e: ClipboardEvent) => {
    const { items } = e.clipboardData;
    const addFiles = [];

    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf('image') === -1) {
        continue; // eslint-disable-line
      }

      const file = items[i].getAsFile();

      if (file) {
        addFiles.push(file);
      }
    }

    if (addFiles.length) {
      handleDropFiles(addFiles, [], {}, true);

      e.preventDefault();
    }
  };

  const uploadFile = React.useCallback(
    async (file: File, name?: string): Promise<void> => {
      let processedFile;

      if (beforeUpload) {
        try {
          processedFile = await beforeUpload(file);
        } catch {
          return;
        }
      }

      try {
        const resourceData = await getUrlForUploading(processedFile || file, name);
        console.log('resourceData: ', resourceData);

        await loadFile(processedFile || file, resourceData.uploadUrl);

        setFiles((state) => {
          const loadedFile = processedFile || file;

          return multiple
            ? [
                ...state,
                {
                  file: {
                    ...loadedFile,
                    name: name || file.name,
                  },
                  isLoaded: true,
                  isFailed: false,
                  id: resourceData.id,
                  fileName: name || file.name,
                  contentType: resourceData.contentType,
                  originalUrl: resourceData.uploadUrl,
                },
              ]
            : [
                {
                  file: {
                    ...loadedFile,
                    name: name || file.name,
                  },
                  isLoaded: true,
                  isFailed: false,
                  id: resourceData.id,
                  fileName: name || file.name,
                  contentType: resourceData.contentType,
                  originalUrl: resourceData.uploadUrl,
                },
              ];
        });
      } catch (error) {
        if (onFailedLoadFile) {
          onFailedLoadFile(name || file.name);
        }

        setFiles((state) =>
          multiple
            ? [
                ...state,
                {
                  file: {
                    ...file,
                    name: name || file.name,
                  },
                  id: null,
                  isLoaded: false,
                  isFailed: true,
                  originalUrl: undefined,
                  contentType: null,
                },
              ]
            : [
                {
                  file: {
                    ...file,
                    name: name || file.name,
                  },
                  id: null,
                  isLoaded: false,
                  isFailed: true,
                  originalUrl: undefined,
                  contentType: null,
                },
              ]
        );
      }
    },
    [files]
  );

  const handleDropFiles = React.useCallback(
    async (
      droppedFiles: File[],
      rejectedFiles: File[] = [], // eslint-disable-line
      e: any = {}, // eslint-disable-line
      isScreenShots = false
    ) => {
      setIsLoading(true);

      let nameCountArray;

      if (isScreenShots) {
        nameCountArray = Array(droppedFiles.length)
          .fill(null)
          .map(() => ++screenCount);
      }

      await Promise.all(
        droppedFiles.map((file, index) => {
          if (isScreenShots) {
            const name = `screen-${nameCountArray[index]}.png`;

            return uploadFile(file, name);
          }

          let customName;

          if (fileName) {
            const fileExt = file.name.split('.').pop();

            customName = `${fileName}.${fileExt}`;
          }

          return uploadFile(file, customName);
        })
      );

      setIsLoading(false);
    },
    [screenCount, files, fileName]
  );

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    ...options,
    multiple,
    accept,
    disabled,
    onDrop: handleDropFiles,
  });

  const handleRemoveFile = React.useCallback(
    (fileData: FileType) => {
      const filteredFiles = files.filter((data) => data.id !== fileData.id);

      setFiles(filteredFiles);
    },
    [files]
  );

  const handleAddFile = React.useCallback(
    (file: Partial<FileType>) => {
      setFiles(
        files.concat({
          ...file,
          isLoaded: true,
          isFailed: false,
        } as FileType)
      );
    },
    [files]
  );

  const handleDrop = (e) => {
    e.preventDefault();

    const { files: droppedFiles } = e.dataTransfer;
    const filesArray = Array.from(droppedFiles).filter((item: File) => validateFile(item, accept));

    if (filesArray.length) {
      handleDropFiles(filesArray as File[]);
    }
  };

  const handleReset = React.useCallback(() => {
    if (files.length) {
      setFiles([]);

      return;
    }

    if (!initFiles || !initFiles.length) {
      return;
    }

    const initData = initFiles.map((item) => {
      const file = new File([''], item.fileName);

      return {
        file,
        id: item.id,
        isLoaded: true,
        isFailed: false,
        fileName: item.fileName,
        formatUrls: item.formatUrls,
        contentType: item.contentType,
        originalUrl: item.originalUrl,
      };
    });

    setFiles(initData);
  }, [initFiles, files]);

  return {
    files,
    isLoading,
    getRootProps,
    getInputProps,
    isDragActive,
    open,
    reset: handleReset,
    addFile: handleAddFile,
    removeFile: handleRemoveFile,
  };
};
