import {
  PROFILE_LOADING,
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  PACKAGES_MY_LOADING,
  PACKAGES_MY_ERROR,
  PACKAGES_MY_SUCCESS,
  DISABILITIES_FETCH_SUCCESS,
  BLOOD_TYPES_FETCH_SUCCESS,
  NOTIFICATIONS_SMS_FETCH_SUCCESS,
  NOTIFICATIONS_EMAIL_FETCH_SUCCESS,
  NOTIFICATIONS_LOADING,
  NOTIFICATIONS_ERROR,
  MY_DEBT_SUCCESS,
  MY_DEBT_LOADING,
  MY_DEBT_ERROR,
  PACKAGES_TRANSACTIONS_LOADING,
  PACKAGES_TRANSACTIONS_SUCCESS,
  PACKAGES_TRANSACTIONS_ERRORS,
  PACKAGES_MY_RESET,
} from '../actions';

const initialSettings = {
  profile: null,
  profileLoading: false,
  profileLoadingError: false,

  bloodTypes: [],
  disabilities: [],

  notificationsSmsList: [],
  notificationsEmailList: [],
  notificationsLoading: false,
  notificationsLoadingError: false,

  packgesMyList: [],
  packgesMyLoading: false,
  packgesMyLoadingError: false,

  debt: {},
  loadingDebt: false,
  errorDebt: null,

  transactions: null,
  pageTransactions: 0,
  pageSizeTransactions: 50,
  totalItemsTransactions: 0,
  hasMoreTransactions: false,
  loadingTransactions: false,
  errorsTransactions: null,
};

const profileReducer = (state = initialSettings, action) => {
  switch (action.type) {
    case PROFILE_LOADING:
      return {
        ...state,
        profileLoading: action.isLoading,
      };

    case PROFILE_ERROR:
      return {
        ...state,
        profileLoadingError: action.isError,
      };

    case PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.profile ? { ...action.profile } : action.profile,
      };
    case PACKAGES_MY_RESET:
      return {
        ...state,
        packgesMyList: [],
        packgesMyLoadingError: null,
        packgesMyLoading: false,
      };
    case PACKAGES_MY_LOADING:
      return {
        ...state,
        packgesMyLoading: action.isLoading,
      };

    case PACKAGES_MY_ERROR:
      return {
        ...state,
        packgesMyLoadingError: action.isError,
      };

    case PACKAGES_MY_SUCCESS:
      return {
        ...state,
        packgesMyList: [...action.packages],
      };

    case DISABILITIES_FETCH_SUCCESS:
      return {
        ...state,
        disabilities: [...action.disabilities],
      };

    case BLOOD_TYPES_FETCH_SUCCESS:
      return {
        ...state,
        bloodTypes: [...action.bloodTypes],
      };

    case NOTIFICATIONS_SMS_FETCH_SUCCESS:
      return {
        ...state,
        notificationsSmsList: { ...action.notifications },
      };

    case NOTIFICATIONS_EMAIL_FETCH_SUCCESS:
      return {
        ...state,
        notificationsEmailList: { ...action.notifications },
      };

    case NOTIFICATIONS_LOADING:
      return {
        ...state,
        notificationsLoading: action.isLoading,
      };

    case NOTIFICATIONS_ERROR:
      return {
        ...state,
        notificationsLoadingError: action.isError,
      };
    case MY_DEBT_SUCCESS:
      return {
        ...state,
        debt: action.debt,
      };
    case MY_DEBT_LOADING:
      return {
        ...state,
        loadingDebt: action.loadingDebt,
      };
    case MY_DEBT_ERROR:
      return {
        ...state,
        errorDebt: action.errorDebt,
      };
    case PACKAGES_TRANSACTIONS_LOADING:
      return {
        ...state,
        transactions: action.loading && action.reset ? [] : state.transactions,
        loadingTransactions: action.loading,
      };
    case PACKAGES_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactions: action.page > 0 ? state.transactions.concat(action.transactions) : action.transactions,
        pageTransactions: action.page,
        pageSizeTransactions: action.pageSize,
        totalItemsTransactions: action.totalItems,
        hasMoreTransactions: (action.page + 1) * action.pageSize < action.totalItems && action.totalItems > 0,
        errorsTransactions: null,
      };
    case PACKAGES_TRANSACTIONS_ERRORS:
      return {
        ...state,
        errorsTransactions: action.errors,
      };
    default:
      return state;
  }
};

export default profileReducer;
