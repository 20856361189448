import { createEvent } from 'effector';
import { JournalFilterRequest } from 'views/JournalPage/types';

export const onSetJournalType = createEvent<string>('onSetJournalType');
export const onSetJournalUserId = createEvent<string>('onSetJournalUserId');
export const onSetJournalPatient = createEvent<any>('onSetJournalPatient');
export const onSetJournalView = createEvent<string>('onSetJournalView');
export const onSetJournalEdit = createEvent<any>('onSetJournalEdit');
export const onSetJournalModel = createEvent<any>('onSetJournalModel');
export const onSetJournalDate = createEvent<string>('onSetJournalDate');
export const onSetJournalDateCustom = createEvent<Date>('onSetJournalDateCustom');
export const onSetJournalReset = createEvent<any>('onSetJournalReset');
export const onGetJournal = createEvent<JournalFilterRequest>('onGetJournal');
export const onSetJournalCurrent = createEvent<Array<any>>('onSetJournalCurrent');
export const onEditJournal = createEvent<any>('onEditJournal');
export const onDeleteJournal = createEvent<any>('onDeleteJournal');
export const onShowMore = createEvent<any>('onShowMore');
export const onSetJournalDelete = createEvent<any>('onSetJournalDelete');
