import { combine } from 'effector';
import personal, { PersonalStateInterface } from './personal';
import packages, { ServicePackegasStateInterface } from './packages';
import doctors, { DoctorsStateInterface } from './doctors';
import appointments, { AppointmentsStateInterface } from './appointments';
import auth, { AuthStateInterface } from './auth';
import journal, { JournalStateInterface } from '../views/JournalPage/store';
import stories, { StoriesStateInterface } from './stories';
import checker, { CheckerStateInterface } from '../views/CheckerPage/store';
import appointmentDoctor, { AppointmentDoctorStateInterface } from '../views/AppointmentDoctor/store';

export interface StateInterface {
  personal: PersonalStateInterface;
  packages: ServicePackegasStateInterface;
  doctors: DoctorsStateInterface;
  appointments: AppointmentsStateInterface;
  auth: AuthStateInterface;
  journal: JournalStateInterface;
  stories: StoriesStateInterface;
  checker: CheckerStateInterface;
  appointmentDoctor: AppointmentDoctorStateInterface;
}
const $store = combine<StateInterface>({
  personal,
  packages,
  doctors,
  appointments,
  auth,
  journal,
  stories,
  checker,
  appointmentDoctor,
});
export default $store;
