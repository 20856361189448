import React, { useState, useEffect, useCallback } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { changeSetting, fetchPackagesMy, servicePackagesReset } from 'store/actions';

import ContentLoading from 'components/ContentLoading';
import Button from 'components/Forms/Button';

import { Box } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import styles from '../ServicePackagesPage.module.sass';
import { createPatientServicePackagePaymentUrl } from '../../../api/createPatientServicePackagePaymentUrl';
import { updateRedirect } from '../../../store/actions/redirect.actions';
import { displayErrors, handleHttpError } from '../../../utils/handleHttpError';
import { ReactComponent as TariffIcon } from '../../../assets/img/icons/tariff.svg';
import PackageItem from '../../../components/PackageItem';

const PackagesMy = (props: any) => {
  const dispatch = useDispatch();
  const reset = useCallback(() => dispatch(servicePackagesReset()), [dispatch]);

  const init = useCallback(() => dispatch(fetchPackagesMy()), [dispatch]);

  const [paymentLoading, setPaymentLoading] = useState(false);

  useEffect(() => {
    init();
  }, [init]);

  const { packagesMyList, packgesMyLoading, packgesMyLoadingError } = useSelector((state: any) => ({
    packagesMyList: state.profile.packgesMyList || [],
    packgesMyLoading: state.profile.packgesMyLoading,
    packgesMyLoadingError: state.profile.packgesMyLoadingError,
  }));

  const patientServicePackagePayment = useCallback((servicePackageId, debtSum: number) => {
    dispatch(changeSetting('newAppointment', {}));
    createPatientServicePackagePaymentUrl(servicePackageId, debtSum || 0)
      .then((response) => {
        setPaymentLoading(false);

        if (response.data.data) {
          const win = window;
          dispatch(updateRedirect(window.location.pathname));
          win.open(response.data.data, '_self');
          win.focus();
        }
      })
      .catch((error) => {
        setPaymentLoading(null);
        displayErrors(handleHttpError(error));
      });
  }, []);

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  return (
    <>
      <ContentLoading
        isLoading={packgesMyLoading}
        isError={packgesMyLoadingError}
        fetchData={() => props.fetchPackagesMy()}
      >
        <div className={styles.packagesView}>
          <div className={styles.packagesList}>
            {packagesMyList.map((packageItem) => (
              <PackageItem
                key={packageItem?.id}
                item={packageItem}
                loading={paymentLoading}
                onBuy={patientServicePackagePayment}
              />
            ))}
          </div>
        </div>
      </ContentLoading>
    </>
  );
};

export default connect(
  (state: any) => ({
    packgesMyList: state.profile.packgesMyList,
    packgesMyLoading: state.profile.packgesMyLoading,
    packgesMyLoadingError: state.profile.packgesMyLoadingError,
  }),
  { fetchPackagesMy }
)(withRouter(PackagesMy));
