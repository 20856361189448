import {
  DOCTOR_SCHEDULER_APPOINTMENTS_ERROR,
  DOCTOR_SCHEDULER_APPOINTMENTS_LOADING,
  DOCTOR_SCHEDULER_APPOINTMENTS_SUCCESS,
  DOCTOR_SCHEDULER_CHANGE_CURRENT_DATE,
  DOCTOR_SCHEDULER_CHANGE_VIEW,
  DOCTOR_SCHEDULER_CREATE_SCHEDULE_ERROR,
  DOCTOR_SCHEDULER_CREATE_SCHEDULE_LOADING,
  DOCTOR_SCHEDULER_CREATE_SCHEDULE_SUCCESS,
  DOCTOR_SCHEDULER_DELETE_SCHEDULE_ERROR,
  DOCTOR_SCHEDULER_DELETE_SCHEDULE_LOADING,
  DOCTOR_SCHEDULER_DELETE_SCHEDULE_SUCCESS,
  DOCTOR_SCHEDULER_SCHEDULES_ERROR,
  DOCTOR_SCHEDULER_SCHEDULES_LOADING,
  DOCTOR_SCHEDULER_SCHEDULES_SUCCESS,
  ViewSchedulerDoctor,
} from '../actions';
import { Appointment, Scheduler } from '../../types';

export interface DoctorSchedulerInitialSettings {
  date?: Date;
  view?: ViewSchedulerDoctor;
  schedules?: Scheduler[];
  appointments?: Appointment[];
  loadingAppointments?: boolean;
  errorsAppointments?: any;
  loading?: boolean;
  errors?: any;
  createdSchedule?: any;
  loadingCreateSchedule?: boolean;
  errorsCreateSchedule?: any;
  deletedSchedule?: any;
  loadingDeleteSchedule?: boolean;
  errorsDeleteSchedule?: any;
}
const initialSettings: DoctorSchedulerInitialSettings = {
  date: new Date(),
  view: ViewSchedulerDoctor.WEEK,
  schedules: [],
  appointments: [],
  loading: false,
  errors: null,
  createdSchedule: null,
  loadingCreateSchedule: false,
  errorsCreateSchedule: null,
  deletedSchedule: null,
  loadingAppointments: false,
  errorsAppointments: null,
  loadingDeleteSchedule: false,
  errorsDeleteSchedule: null,
};

const doctorSchedulerReducer = (state = initialSettings, action): DoctorSchedulerInitialSettings => {
  switch (action.type) {
    case DOCTOR_SCHEDULER_CHANGE_CURRENT_DATE:
      return {
        ...state,
        date: action.date,
      };
    case DOCTOR_SCHEDULER_CHANGE_VIEW:
      return {
        ...state,
        view: action.view,
      };
    case DOCTOR_SCHEDULER_SCHEDULES_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case DOCTOR_SCHEDULER_SCHEDULES_SUCCESS:
      return {
        ...state,
        schedules: action.schedules,
        errors: null,
      };
    case DOCTOR_SCHEDULER_SCHEDULES_ERROR:
      return {
        ...state,
        errors: action.errors,
      };
    case DOCTOR_SCHEDULER_APPOINTMENTS_LOADING:
      return {
        ...state,
        loadingAppointments: action.loading,
      };
    case DOCTOR_SCHEDULER_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        appointments: action.appointments,
        errorsAppointments: null,
      };
    case DOCTOR_SCHEDULER_APPOINTMENTS_ERROR:
      return {
        ...state,
        errorsAppointments: action.errors,
      };
    case DOCTOR_SCHEDULER_CREATE_SCHEDULE_LOADING:
      return {
        ...state,
        loadingCreateSchedule: action.loading,
      };
    case DOCTOR_SCHEDULER_CREATE_SCHEDULE_SUCCESS:
      return {
        ...state,
        createdSchedule: action.schedule,
        errorsCreateSchedule: null,
      };
    case DOCTOR_SCHEDULER_CREATE_SCHEDULE_ERROR:
      return {
        ...state,
        errorsCreateSchedule: action.errors,
      };
    case DOCTOR_SCHEDULER_DELETE_SCHEDULE_LOADING:
      return {
        ...state,
        loadingDeleteSchedule: action.loading,
      };
    case DOCTOR_SCHEDULER_DELETE_SCHEDULE_SUCCESS:
      return {
        ...state,
        deletedSchedule: action.schedule,
        errorsDeleteSchedule: null,
      };
    case DOCTOR_SCHEDULER_DELETE_SCHEDULE_ERROR:
      return {
        ...state,
        errorsDeleteSchedule: action.errors,
      };
    default:
      return state;
  }
};

export default doctorSchedulerReducer;
