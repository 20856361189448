import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import classNames from 'classnames';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import BaseDialog from '../BaseDialog';
import styles from './AdminServicePackageLimitEdit.module.sass';
import Button from '../Forms/Button';
import SelectInput2 from '../Forms/SelectInput2';
import InputField from '../Forms/InputField';
import manageSpecGroupsApi from '../../api/manageSpecGroups';
import manageServicePackageLimits from '../../api/manageServicePackageLimits';
import Manage from '../../types/manage';
import { displayErrors, handleHttpError } from '../../utils/handleHttpError';
import { EnumDictionary } from '../../types/EnumDictionary';

interface AdminServicePackageLimitEditProps {
  onClosed: () => void;
  onUpdated: () => void;
  servicePackageId: string;
}

interface LimitModel {
  index: number;
  group?: Option;
  limit?: string;
  status?: EnumDictionary<Manage.Spec.GroupStatus>;
}

interface Option {
  label: string;
  value: string;
}

const AdminServicePackageLimitEdit: React.FC<AdminServicePackageLimitEditProps> = (props) => {
  const { onClosed, onUpdated, servicePackageId } = props;

  const [isLoading, setLoading] = useState<boolean>(false);
  const [limits, setLimits] = useState<Array<LimitModel>>([]);
  const [lastIndex, setLastIndex] = useState<number>(0);
  const [groups, setGroups] = useState<Array<Option>>([]);

  useEffect(() => reloadGroups(), []);

  const reloadGroups = () => {
    setLoading(true);
    manageSpecGroupsApi
      .getAvailableSpecGroups()
      .then((res) => {
        setGroups(
          res.data?.data?.items.map((value) => {
            return { label: value.name, value: value.id };
          }) || []
        );
        return manageServicePackageLimits.getLimits(servicePackageId);
      })
      .then((res) => {
        const storeLimits: Array<LimitModel> =
          res.data?.data?.items?.map((value, index) => {
            return {
              index,
              limit: value.limitPerPeriod || value.limitPerPeriod === 0 ? String(value.limitPerPeriod) : null,
              group: { value: value.id, label: value.name },
              status: value.status,
            };
          }) || [];
        setLimits(storeLimits);
        setLastIndex(storeLimits.length);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.error(e);
        setGroups([]);
      });
  };

  const onSave = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const notNullLimits: Array<Manage.ServicePackages.Mfo.GroupLimitItem> = limits
      .filter((value) => !!value.group)
      .map((value) => {
        return {
          limitPerPeriod: value.limit ? parseInt(value.limit) : null,
          groupId: value.group.value,
        };
      });
    setLoading(true);
    manageServicePackageLimits
      .updateLimits(servicePackageId, {
        items: notNullLimits,
      })
      .then((_) => {
        setLoading(false);
        onUpdated();
      })
      .catch((e) => {
        setLoading(false);
        displayErrors(handleHttpError(e));
      });
  };

  const onAddLimit = () => {
    const newIndex = lastIndex + 1;
    setLimits([...limits, { limit: null, group: null, index: newIndex }]);
    setLastIndex(newIndex);
  };

  const onDeleteLimit = (index: number) => {
    setLimits(limits.filter((value) => value.index !== index));
  };

  const onUpdateGroup = (index: number, groupId: string) => {
    const find = groups.findIndex((value) => value.value === groupId);
    setLimits(
      limits.map((value) => {
        return value.index === index
          ? {
              ...value,
              group: groups[find] || { label: '-', value: groupId },
            }
          : value;
      })
    );
  };

  const onLimitChanged = (index: number, value: string) => {
    setLimits(
      limits.map((item) => {
        return item.index === index
          ? {
              ...item,
              limit: value,
            }
          : item;
      })
    );
  };

  const renderItem = (value: LimitModel) => {
    const isArchive = value.status?.value === 'ARCHIVE';

    return (
      <Box key={`item-${value.index}`} display="flex" justifyItems="space-between" alignItems="flex-center" mb={2}>
        <Box width="90%" display="flex" alignItems="stretch" justifyItems="space-between">
          <Box width="40%" mr={1}>
            <SelectInput2
              disabled={isArchive}
              value={value.group?.value || ''}
              onChange={(option) => {
                onUpdateGroup(value.index, option.target.value);
              }}
              options={isArchive ? [value.group] : groups}
              label={`Группа${isArchive ? '(архив)' : ''}`}
              placeholder="Группа"
            />
          </Box>
          <Box width="50%" mr={1}>
            <InputField
              disabled={isArchive}
              type="number"
              min={0}
              max={999}
              value={value.limit || ''}
              onChange={(e) => {
                onLimitChanged(value.index, e.target.value);
              }}
              label="Лимит"
              placeholder="Лимит"
            />
          </Box>
        </Box>
        <Box width="10%" display="flex" alignItems="end" justifyItems="flex-end">
          <Box
            mb={1}
            onClick={() => {
              onDeleteLimit(value.index);
            }}
            className={classNames(styles.Remove)}
          >
            <DeleteOutlineIcon />
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <BaseDialog onClosed={onClosed} title="Редактировать лимиты">
      <Box
        component={(props) => <form {...props} onSubmit={onSave} />}
        className={classNames(styles.AdminServicePackageLimitEdit_Content)}
      >
        <Box display="flex" flexDirection="column" alignItems="stretch">
          {limits.map((value) => {
            return renderItem(value);
          })}
          <Box width="100%" alignItems="center">
            <Button onClick={onAddLimit} color="primary" block>
              Добавить лимит
            </Button>
          </Box>
        </Box>
        <Box className={styles.AdminServicePackageLimitEdit_Content_Actions}>
          <Button className="mr-2" color="default" onClick={onClosed}>
            Отмена
          </Button>
          <Button type="submit" color="primary" isLoading={isLoading}>
            Обновить
          </Button>
        </Box>
      </Box>
    </BaseDialog>
  );
};

export default AdminServicePackageLimitEdit;
