import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import AuthRoute from './components/AuthRoute';
import BasePage from './components/BasePage';
import Layout from './components/Layout';
import RawPage from './components/RawPage';
import LoginPage from './views/LoginPage';
import HomePage from './views/HomePage';
import DoctorDetailsPage from './views/DoctorDetailsPage';
import AppointmentsPage from './views/AppointmentsPage';
import AppointmentPage from './views/AppointmentPage';
import PaymentPage from './views/PaymentPage';
import DoctorSchedulePage from './views/DoctorSchedulePage';
import ProfilePage from './views/ProfilePage';
import AddBalancePage from './views/AddBalancePage';
import AppointmentChatPage from './views/AppointmentChatPage';
import PaymentSuccessPage from './views/PaymentSuccessPage';
import NotFound from './views/NotFound';
import HasRole from './components/HasRole/HasRole';
import { UserRole } from './types';
import CurrentDoctor from './views/CurrentDoctor';
import PatientsDoctor from './views/PatientsDoctor';
import SchedulerDoctor from './views/SchedulerDoctor';
import NotificationsDoctor from './views/NotificationsDoctor';
import EventsDoctor from './views/EventsDoctor';
import ProfileDoctor from './views/ProfileDoctor';
import DoctorInvitePage from './views/DoctorInvitePage/DoctorInvitePage';
import ServicePackagesPage from './views/ServicePackagesPage';
import PackageView from './views/PackageView/PackageView';
import AppointmentDoctor from './views/AppointmentDoctor/AppointmentDoctor';
import HomePageAdmin from './views/Admin/HomePageAdmin';
import DoctorsPageAdmin from './views/Admin/DoctorsPageAdmin';
import PatientsPageAdmin from './views/Admin/PatientsPageAdmin';
import ServicePackagesAdmin from './views/Admin/ServicePackagesAdmin';
import SettingsPageAdmin from './views/Admin/SettingsPageAdmin';
import ReportsPageAdmin from './views/Admin/ReportsPageAdmin';
import SchedulerDoctorPageAdmin from './views/Admin/DoctorsPageAdmin/SchedulerDoctorPageAdmin';
import ReviewsPageAdmin from './views/Admin/ReviewsPageAdmin/ReviewsPageAdmin';
import DailyConclusionStrategies from './views/Admin/DailyConclusion/DailyConclusionStrategies';
import TransactionsPage from './views/TransactionsPage';
import AgreementsPage from './views/AgreementsPage';
import PackagesDocuments from './views/ServicePackagesPage/PackagesDocuments/PackagesDocuments';
import PartnersPageAdmin from './views/Admin/PartnersPageAdmin';
import AgentsPageAdmin from './views/Admin/AgentsPageAdmin/AgentsPageAdmin';
import PackageViewAll from './views/PackageViewAll';
import DocumentsPage from './views/DocumentsPage';
import PersonalAppointment from './views/PersonalAppointment';
import RedirectEsia from './views/RedirectEsia';
import HomePageSupport from './views/HomePageSupport';
import PatientsSupport from './views/PatientSupportPage';
import JournalPage from './views/JournalPage';
import AdminStoriesSearch from './views/AdminStories/AdminStoriesSearch';
import CheckerPage from './views/CheckerPage';
import AgentSale from './views/Agent/AgentSale';
import AgentMetrics from './views/Agent/AgentMetrics';

const Routes = () => (
  <>
    <Switch>
      <PrivateRoute
        path="/"
        roles={[UserRole.PATIENT, UserRole.DOCTOR, UserRole.ADMIN, UserRole.AGENT, UserRole.SUPPORT]}
        component={(props) => (
          <Layout>
            <HasRole target={UserRole.PATIENT}>
              <HomePage {...props} />
            </HasRole>
            <HasRole target={UserRole.DOCTOR}>
              <ProfileDoctor />
            </HasRole>
            <HasRole target={UserRole.ADMIN}>
              <HomePageAdmin {...props} />
            </HasRole>
            <HasRole target={UserRole.AGENT}>
              <AgentSale />
            </HasRole>
            <HasRole target={UserRole.SUPPORT}>
              <HomePageSupport {...props} />
            </HasRole>
          </Layout>
        )}
        exact
      />
      <PrivateRoute
        roles={[UserRole.AGENT]}
        path="/metrics"
        component={() => (
          <Layout>
            <AgentMetrics />
          </Layout>
        )}
        exact
      />
      <PrivateRoute
        roles={[UserRole.PATIENT]}
        path="/doctor/:doctorId/profile"
        component={(props) => (
          <Layout>
            <DoctorDetailsPage {...props} />
          </Layout>
        )}
        exact
      />
      <PrivateRoute
        roles={[UserRole.PATIENT]}
        path="/appointments"
        component={(props) => (
          <Layout>
            <AppointmentsPage {...props} />
          </Layout>
        )}
        exact
      />
      <PrivateRoute
        path="/appointments/:appointmentId"
        component={() => (
          <Layout>
            <HasRole target={UserRole.DOCTOR}>
              <AppointmentDoctor />
            </HasRole>
            <HasRole target={UserRole.PATIENT}>
              <AppointmentPage />
            </HasRole>
          </Layout>
        )}
        exact
      />
      <PrivateRoute
        roles={[UserRole.PATIENT, UserRole.DOCTOR]}
        path="/appointments/:appointmentId/chat"
        component={(props) => (
          <Layout>
            <AppointmentChatPage {...props} />
          </Layout>
        )}
        exact
      />
      <PrivateRoute
        roles={[UserRole.PATIENT]}
        path="/balance/add"
        component={(props) => (
          <Layout>
            <AddBalancePage {...props} />
          </Layout>
        )}
        exact
      />
      <PrivateRoute
        roles={[UserRole.PATIENT]}
        path="/doctor/payment"
        component={() => (
          <Layout>
            <PaymentPage />
          </Layout>
        )}
        exact
      />
      <PrivateRoute
        roles={[UserRole.PATIENT]}
        path="/payment/success"
        component={() => (
          <Layout>
            <PaymentSuccessPage />
          </Layout>
        )}
        exact
      />
      <PrivateRoute
        roles={[UserRole.PATIENT]}
        path="/payment/fail"
        component={() => (
          <Layout>
            <PaymentSuccessPage isFail />
          </Layout>
        )}
        exact
      />
      <PrivateRoute
        roles={[UserRole.PATIENT]}
        path="/doctor/schedule"
        component={(props) => (
          <Layout>
            <DoctorSchedulePage {...props} />
          </Layout>
        )}
        exact
      />
      <PrivateRoute
        roles={[UserRole.PATIENT]}
        path="/packages"
        component={(props) => (
          <Layout>
            <ServicePackagesPage {...props} />
          </Layout>
        )}
        exact
      />
      <PrivateRoute
        roles={[UserRole.PATIENT, UserRole.AGENT]}
        path="/packages/:servicePackageId"
        component={() => (
          <Layout>
            <PackageView />
          </Layout>
        )}
        exact
      />
      <PrivateRoute
        roles={[UserRole.PATIENT]}
        path="/packages/all/:servicePackageId"
        component={() => (
          <Layout>
            <PackageViewAll />
          </Layout>
        )}
        exact
      />
      <PrivateRoute
        roles={[UserRole.PATIENT]}
        path="/packages/:servicePackageId/documents"
        component={(props) => (
          <Layout>
            <PackagesDocuments {...props} />
          </Layout>
        )}
        exact
      />
      <PrivateRoute
        roles={[UserRole.PATIENT]}
        path="/packages/:servicePackageId/transactions"
        component={(props) => (
          <Layout>
            <TransactionsPage {...props} />
          </Layout>
        )}
        exact
      />
      <PrivateRoute
        path="/profile"
        component={(props) => (
          <Layout>
            <HasRole target={UserRole.PATIENT}>
              <ProfilePage {...props} />
            </HasRole>
            <HasRole target={UserRole.DOCTOR}>
              <ProfileDoctor {...props} />
            </HasRole>
          </Layout>
        )}
        exact
      />
      <PrivateRoute
        roles={[UserRole.DOCTOR]}
        path="/current"
        component={(props) => (
          <Layout>
            <CurrentDoctor {...props} />
          </Layout>
        )}
        exact
      />
      <PrivateRoute
        roles={[UserRole.DOCTOR]}
        path="/scheduler"
        component={() => (
          <Layout>
            <SchedulerDoctor />
          </Layout>
        )}
        exact
      />
      <PrivateRoute
        roles={[UserRole.DOCTOR]}
        path="/events"
        component={() => (
          <Layout>
            <EventsDoctor />
          </Layout>
        )}
        exact
      />
      <PrivateRoute
        roles={[UserRole.DOCTOR]}
        path="/personal"
        component={() => (
          <Layout>
            <EventsDoctor isPersonal />
          </Layout>
        )}
        exact
      />
      <PrivateRoute
        path="/patients"
        component={(props) => (
          <Layout>
            <HasRole target={UserRole.DOCTOR}>
              <PatientsDoctor {...props} />
            </HasRole>
            <HasRole target={UserRole.ADMIN}>
              <PatientsPageAdmin {...props} />
            </HasRole>
            <HasRole target={UserRole.SUPPORT}>
              <PatientsSupport {...props} />
            </HasRole>
          </Layout>
        )}
        exact
      />
      <PrivateRoute
        path="/personal-appointments"
        component={() => (
          <Layout>
            <HasRole target={UserRole.PATIENT}>
              <PersonalAppointment />
            </HasRole>
          </Layout>
        )}
        exact
      />
      <PrivateRoute
        roles={[UserRole.DOCTOR]}
        path="/notifications"
        component={(props) => (
          <Layout>
            <NotificationsDoctor {...props} />
          </Layout>
        )}
        exact
      />
      <PrivateRoute
        path="/doctors"
        roles={[UserRole.ADMIN, UserRole.SUPPORT]}
        component={(props) => (
          <Layout>
            <DoctorsPageAdmin {...props} />
          </Layout>
        )}
        exact
      />
      <PrivateRoute
        roles={[UserRole.ADMIN]}
        path="/doctors/:doctorId/scheduler"
        component={(props) => (
          <Layout>
            <SchedulerDoctorPageAdmin {...props} />
          </Layout>
        )}
        exact
      />
      <PrivateRoute
        path="/services"
        roles={[UserRole.ADMIN, UserRole.SUPPORT]}
        component={() => (
          <Layout>
            <ServicePackagesAdmin />
          </Layout>
        )}
        exact
      />
      <PrivateRoute
        roles={[UserRole.ADMIN]}
        path="/reports"
        component={(props) => (
          <Layout>
            <ReportsPageAdmin {...props} />
          </Layout>
        )}
        exact
      />
      <PrivateRoute
        roles={[UserRole.ADMIN]}
        path="/settings"
        component={(props) => (
          <Layout>
            <SettingsPageAdmin {...props} />
          </Layout>
        )}
        exact
      />
      <PrivateRoute
        roles={[UserRole.ADMIN]}
        path="/dailyconclusionstrategies"
        component={(props) => (
          <Layout>
            <DailyConclusionStrategies {...props} />
          </Layout>
        )}
        exact
      />
      <PrivateRoute
        roles={[UserRole.ADMIN]}
        path="/reviews"
        component={(props) => (
          <Layout>
            <ReviewsPageAdmin {...props} />
          </Layout>
        )}
        exact
      />
      <PrivateRoute
        roles={[UserRole.ADMIN]}
        path="/partners"
        component={(props) => (
          <Layout>
            <PartnersPageAdmin {...props} />
          </Layout>
        )}
        exact
      />
      <PrivateRoute
        roles={[UserRole.PATIENT]}
        path="/journal"
        component={() => (
          <Layout>
            <JournalPage />
          </Layout>
        )}
      />
      <PrivateRoute
        roles={[UserRole.PATIENT]}
        path="/checker"
        component={() => (
          <Layout>
            <CheckerPage />
          </Layout>
        )}
      />
      <PrivateRoute
        roles={[UserRole.DOCTOR]}
        path="/patients/:patientId/journal"
        component={() => (
          <Layout>
            <JournalPage />
          </Layout>
        )}
      />
      <PrivateRoute
        roles={[UserRole.ADMIN]}
        path="/partners/:partnerId/agents"
        component={(props) => (
          <Layout>
            <AgentsPageAdmin {...props} />
          </Layout>
        )}
        exact
      />
      <PrivateRoute
        roles={[UserRole.ADMIN]}
        path="/stories"
        component={() => (
          <Layout>
            <AdminStoriesSearch />
          </Layout>
        )}
        exact
      />
      <AuthRoute
        path="/login"
        component={(props) => (
          <BasePage>
            <LoginPage {...props} />
          </BasePage>
        )}
        exact
      />
      <AuthRoute
        path="/admin"
        component={(props) => (
          <BasePage>
            <LoginPage {...props} />
          </BasePage>
        )}
        exact
      />
      <AuthRoute path="/redirect_esia" component={() => <RedirectEsia />} />
      <AuthRoute
        path="/doctor/invite/:code"
        component={(props) => (
          <BasePage>
            <DoctorInvitePage {...props} />
          </BasePage>
        )}
        exact
      />
      <Route
        path="/agreements"
        component={(props) => (
          <Layout showSidebar={false} showPersonalDoctor={false} showFooter {...props}>
            <AgreementsPage {...props} />
          </Layout>
        )}
      />
      <Route
        path="/documents"
        component={(props) => (
          <Layout showSidebar={false} showPersonalDoctor={false} showFooter {...props}>
            <DocumentsPage {...props} />
          </Layout>
        )}
      />
      <Route
        component={() => (
          <RawPage>
            <NotFound />
          </RawPage>
        )}
      />
    </Switch>
  </>
);

export default withRouter(Routes);
