import React, { ReactNode, useCallback } from 'react';
import { Box } from '@material-ui/core';
import classNames from 'classnames';
import { SortableContainer, SortEnd, SortEvent } from 'react-sortable-hoc';
import useRootSelector from 'hooks/useRootSelector';
import { selectStoriesError, selectStoriesItems, selectStoriesLoading } from 'store/stories/selectors';
import ContentLoading from 'components/ContentLoading';
import { onGetStories, onSortStoriesItems } from 'store/stories/events';
import { arrayMoveImmutable, arrayMoveMutable } from 'array-move';
import AdminStoriesListItem from '../AdminStoriesListItem';
import styles from './AdminStoriesList.module.sass';

const SortableList = SortableContainer(({ children }) => (
  <>
    <Box>{children}</Box>
  </>
));
interface PropsInterface {
  header?: ReactNode;
}

const AdminStoriesList = (props: PropsInterface): JSX.Element => {
  const { header } = props;
  const items = useRootSelector(selectStoriesItems);
  const loading = useRootSelector(selectStoriesLoading);
  const error = useRootSelector(selectStoriesError);
  const getData = useCallback(() => {
    onGetStories();
  }, []);
  const handleSortEnd = useCallback(
    (sort: SortEnd) => {
      sort.oldIndex !== sort.newIndex &&
        onSortStoriesItems(
          arrayMoveImmutable(items, sort.oldIndex, sort.newIndex).map((item, index) => ({
            ...item,
            weight: items.length - index,
          }))
        );
    },
    [items]
  );
  return (
    <>
      <Box className={styles.root}>{header}</Box>
      <Box className={styles.root_list}>
        {items?.length > 0 && (
          <SortableList onSortEnd={handleSortEnd} useDragHandle>
            {items.map((item, index) => (
              <AdminStoriesListItem key={item?.id} item={item} index={index} />
            ))}
          </SortableList>
        )}
        {(loading || (error && items?.length === 0)) && (
          <Box className={styles.root_list_loading} style={{ opacity: items?.length > 0 ? 0.4 : 1 }}>
            <ContentLoading isLoading={loading} isError={error} fetchData={getData} />
          </Box>
        )}
      </Box>
    </>
  );
};

export default AdminStoriesList;
