import React, { memo, ReactElement, useCallback, useRef } from 'react';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import { Box } from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import { createEffect } from 'effector';
import styles from './styles.module.sass';
import { getAgentExampleApi, uploadAgentRegistryApi } from './api';
import useStoreApi from '../../hooks/useStoreApi';
import Spinner from '../Spinner';

const getAgentFx = createEffect(async () => await getAgentExampleApi());
const uploadAgentFx = createEffect(async (file: File) => {
  await uploadAgentRegistryApi(file);
});
interface PropsInterface {
  userProfile?: boolean;
}
const SidebarAgentRegistryMenu = memo((props: PropsInterface): ReactElement => {
  const { userProfile } = props;
  const { loading: loadingDownload } = useStoreApi(getAgentFx);
  const { loading: loadingUpload } = useStoreApi(uploadAgentFx);
  const ref = useRef<HTMLInputElement>();
  const handleDownload = useCallback(async () => {
    await getAgentFx();
  }, []);
  const handleUpload = useCallback(async (file: File) => {
    ref.current?.value && (ref.current.value = '');
    await uploadAgentFx(file);
  }, []);
  if (userProfile) {
    return (
      <>
        <li className="d-block d-xl-none">
          <button
            type="button"
            disabled={loadingDownload}
            className={styles.root_item_userProfile}
            onClick={handleDownload}
          >
            {loadingDownload ? (
              <Spinner className={styles.root_item_userProfile_icon} />
            ) : (
              <SystemUpdateAltIcon className={styles.root_item_userProfile_icon} />
            )}
            <Box>Скачать реестр</Box>
          </button>
        </li>
        <li className="d-block d-xl-none">
          <button type="button" disabled={loadingUpload} className={styles.root_item_userProfile}>
            {loadingUpload ? (
              <Spinner className={styles.root_item_userProfile_icon} />
            ) : (
              <PublishIcon className={styles.root_item_userProfile_icon} />
            )}
            <Box>Загрузить реестр</Box>
            <input type="file" onChange={(event) => handleUpload(event.target.files.item(0))} ref={ref} />
          </button>
        </li>
      </>
    );
  }
  return (
    <>
      <button type="button" disabled={loadingDownload} className={styles.root_item} onClick={handleDownload}>
        {loadingDownload ? (
          <Spinner className={styles.root_item_icon} />
        ) : (
          <SystemUpdateAltIcon className={styles.root_item_icon} />
        )}
        <Box>Скачать реестр</Box>
      </button>
      <button className={styles.root_item}>
        {loadingUpload ? (
          <Spinner className={styles.root_item_icon} />
        ) : (
          <PublishIcon className={styles.root_item_icon} />
        )}
        <Box>Загрузить реестр</Box>
        <input type="file" onChange={(event) => handleUpload(event.target.files.item(0))} ref={ref} />
      </button>
    </>
  );
});

export default SidebarAgentRegistryMenu;
