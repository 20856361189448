import React from 'react';
import { connect } from 'react-redux';
import { validateFieldOnChange, getFieldErrors as getFieldErrorsUtil } from 'utils';
import { localStringToNumber } from 'utils/helpers';
import {
  authPhoneCodeConfirm,
  setPhoneLoginForm,
  setPhoneLoginErrors,
  setCodeConfirmForm,
  setCodeConfirmFormErrors,
} from 'store/actions';

// Components
import InputField from 'components/Forms/InputField';
import Button from 'components/Forms/Button';
import FormValidator from 'components/Forms/FormValidator';

import { useHistory, useLocation } from 'react-router-dom';
import styles from './PhoneConfirmCode.module.sass';

const PhoneConfirmCode = (props: any): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const validateOnChange = (name: string, value: any, event, element?) => {
    validateFieldOnChange(
      name,
      value,
      event,
      props.codeConfirmForm,
      props.setCodeConfirmForm,
      props.codeConfirmFormErrors,
      props.setCodeConfirmFormErrors,
      element
    );
  };

  const getFieldErrors = (field: string) => getFieldErrorsUtil(field, props.codeConfirmFormErrors);

  const codeConfirmSubmit = (e) => {
    e.preventDefault();

    const form = e.target;
    const inputs = [...form.elements].filter((i) => ['INPUT', 'SELECT', 'TEXTAREA'].includes(i.nodeName));

    const { errors, hasError } = FormValidator.bulkValidate(inputs);

    props.setCodeConfirmFormErrors([...errors]);

    if (!hasError) {
      props.authPhoneCodeConfirm(
        `${localStringToNumber(props.phoneLoginForm.phone)}`,
        codeConfirmForm.code,
        history,
        location
      );
    }
  };

  const { codeConfirmForm } = props;

  return (
    <div className={styles.loginPhoneConfirmCode}>
      <h3 className={styles.loginPhoneConfirmCode_title}>Подтверждение телефона</h3>
      <h3 className={styles.loginPhoneConfirmCode_descr}>Введите 4-х значный код для подтверждения телефона</h3>
      <h3 className={styles.loginPhoneConfirmCode_msg}>
        Код отправлен: <span className={styles.loginPhoneConfirmCode_msg_phone}>{props.phoneLoginForm.phone}</span>
      </h3>

      <form className={styles.loginPhoneConfirmCode_form} onSubmit={(e) => codeConfirmSubmit(e)}>
        <InputField
          className={styles.loginPhoneConfirmCode_code}
          name="code"
          data-param={4}
          data-validate='["required", "len"]'
          value={codeConfirmForm.code}
          errors={getFieldErrors('code')}
          onChange={(e) => validateOnChange('code', e.target.value, e)}
          placeholder="0000"
          block
          mask="9999"
        />

        <Button
          className={`mt-5 ${styles.loginPhoneConfirmCode_btn}`}
          type="submit"
          color="primary"
          isLoading={props.loginLoading}
        >
          Далее
        </Button>
      </form>
    </div>
  );
};

export default connect(
  (state: any) => ({
    phoneLoginForm: state.auth.phoneLoginForm,
    phoneLoginFormErrors: state.auth.phoneLoginFormErrors,

    codeConfirmForm: state.auth.codeConfirmForm,
    codeConfirmFormErrors: state.auth.codeConfirmFormErrors,

    loginLoading: state.auth.loginLoading,
  }),
  {
    authPhoneCodeConfirm,
    setPhoneLoginForm,
    setPhoneLoginErrors,
    setCodeConfirmForm,
    setCodeConfirmFormErrors,
  }
)(PhoneConfirmCode);
