import { getProfile, getDisabilities, getBloodTypes, getNotificationsSettings, getServicePackagesMy } from 'api';
import { AxiosResponse } from 'axios';
import { getDebt } from '../../api/my/getDebt';
import Manage from '../../types/manage';
import { getTransactions } from '../../api/my/getTransactions';

export const PROFILE_LOADING = 'PROFILE_LOADING';
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS';

export const PACKAGES_MY_LOADING = 'PACKAGES_MY_LOADING';
export const PACKAGES_MY_ERROR = 'PACKAGES_MY_ERROR';
export const PACKAGES_MY_SUCCESS = 'PACKAGES_MY_SUCCESS';
export const PACKAGES_MY_RESET = 'PACKAGES_MY_RESET';

export const MY_DEBT_LOADING = 'MY_DEBT_LOADING';
export const MY_DEBT_ERROR = 'MY_DEBT_ERROR';
export const MY_DEBT_SUCCESS = 'MY_DEBT_SUCCESS';

export const PACKAGES_TRANSACTIONS_LOADING = 'PACKAGES_TRANSACTIONS_LOADING';
export const PACKAGES_TRANSACTIONS_SUCCESS = 'PACKAGES_TRANSACTIONS_SUCCESS';
export const PACKAGES_TRANSACTIONS_ERRORS = 'PACKAGES_TRANSACTIONS_ERRORS';

export const DISABILITIES_FETCH_SUCCESS = 'DISABILITIES_FETCH_SUCCESS';
export const BLOOD_TYPES_FETCH_SUCCESS = 'BLOOD_TYPES_FETCH_SUCCESS';

export const NOTIFICATIONS_SMS_FETCH_SUCCESS = 'NOTIFICATIONS_SMS_FETCH_SUCCESS';
export const NOTIFICATIONS_EMAIL_FETCH_SUCCESS = 'NOTIFICATIONS_EMAIL_FETCH_SUCCESS';

export const NOTIFICATIONS_ERROR = 'NOTIFICATIONS_ERROR';
export const NOTIFICATIONS_LOADING = 'NOTIFICATIONS_LOADING';

export const profileLoading = (isLoading: boolean) => ({ type: PROFILE_LOADING, isLoading });

export const profileSuccess = (result: any) => {
  let profile = null;

  if (result && result.data) {
    profile = { ...result.data };
  }

  return { type: PROFILE_SUCCESS, profile };
};

export const profileError = (isError: boolean) => ({ type: PROFILE_ERROR, isError });

export const packagesMyLoading = (isLoading: boolean) => ({ type: PACKAGES_MY_LOADING, isLoading });

export const packagesMySuccess = (result: any) => {
  let packages = [];

  if (result && result.data && result.data.items) {
    packages = [...result.data.items];
  }

  return { type: PACKAGES_MY_SUCCESS, packages };
};

export const packagesMyError = (isError: boolean) => ({ type: PACKAGES_MY_ERROR, isError });

export const notificationsLoading = (isLoading: boolean) => ({ type: NOTIFICATIONS_LOADING, isLoading });

export const notificationsSmsSuccess = (result: any) => {
  let notifications = {};

  if (result && result.data) {
    notifications = { ...result.data };
  }

  return { type: NOTIFICATIONS_SMS_FETCH_SUCCESS, notifications };
};

export const notificationsEmailSuccess = (result: any) => {
  let notifications = {};

  if (result && result.data) {
    notifications = { ...result.data };
  }

  return { type: NOTIFICATIONS_EMAIL_FETCH_SUCCESS, notifications };
};

export const notificationsError = (isError: boolean) => ({ type: NOTIFICATIONS_ERROR, isError });

export const disabilitiesFetchSuccess = (result: any) => {
  let disabilities = [];

  if (result && result.data && result.data.items) {
    disabilities = [...result.data.items];
  }

  return { type: DISABILITIES_FETCH_SUCCESS, disabilities };
};

export const bloodTypesFetchSuccess = (result: any) => {
  let bloodTypes = [];

  if (result && result.data && result.data.items) {
    bloodTypes = [...result.data.items];
  }

  return { type: BLOOD_TYPES_FETCH_SUCCESS, bloodTypes };
};

export const fetchProfile = () => {
  return (dispatch) => {
    dispatch(profileLoading(true));
    dispatch(profileError(false));

    getProfile()
      .then((response) => {
        dispatch(profileLoading(false));
        dispatch(profileSuccess(response.data));
      })
      .catch(() => {
        dispatch(profileLoading(false));
        dispatch(profileError(true));
      });
  };
};

export const fetchPackagesMy = (available?: boolean) => {
  return (dispatch) => {
    dispatch(packagesMyLoading(true));
    dispatch(packagesMyError(false));

    getServicePackagesMy(available)
      .then((response) => {
        dispatch(packagesMyLoading(false));
        dispatch(packagesMySuccess(response.data));
      })
      .catch(() => {
        dispatch(packagesMyLoading(false));
        dispatch(packagesMyError(true));
      });
  };
};

export const fetchDisabilities = () => {
  return (dispatch) => {
    getDisabilities()
      .then((response) => {
        dispatch(disabilitiesFetchSuccess(response.data));
      })
      .catch(() => {});
  };
};

export const fetchBloodTypes = () => {
  return (dispatch) => {
    getBloodTypes()
      .then((response) => {
        dispatch(bloodTypesFetchSuccess(response.data));
      })
      .catch(() => {});
  };
};

export const fetchNotifications = () => {
  return (dispatch) => {
    dispatch(notificationsLoading(true));
    dispatch(notificationsError(false));

    getNotificationsSettings('EMAIL')
      .then((response) => {
        dispatch(notificationsLoading(false));
        dispatch(notificationsEmailSuccess(response.data));
      })
      .catch(() => {
        dispatch(notificationsLoading(false));
        dispatch(notificationsError(true));
      });

    /* TODO: uncomment if need SMS */
    /* getNotificationsSettings('SMS').then(response => {
      dispatch(notificationsSmsSuccess(response.data));

      getNotificationsSettings('EMAIL').then(response => {
        dispatch(notificationsLoading(false));
        dispatch(notificationsEmailSuccess(response.data));
      }).catch(error => {
        dispatch(notificationsLoading(false));
        dispatch(notificationsError(true));
      });
    }).catch(error => {
      dispatch(notificationsLoading(false));
      dispatch(notificationsError(true));
    }); */
  };
};

export const myDebtError = (errorDebt: boolean) => ({ type: MY_DEBT_ERROR, errorDebt });

export const myDebtLoading = (loadingDebt: boolean) => ({ type: MY_DEBT_LOADING, loadingDebt });

export const myDebt = (debt: any) => ({ type: MY_DEBT_SUCCESS, debt });

export const fetchMyDebt = () => {
  return (dispatch) => {
    dispatch(myDebtLoading(true));
    dispatch(myDebtError(null));

    getDebt()
      .then((response) => {
        dispatch(myDebtLoading(false));
        if (response.data.code === 'success') {
          dispatch(myDebt(response.data.data));
        } else {
          dispatch(myDebtError(response.data));
        }
      })
      .catch((error) => {
        dispatch(myDebtLoading(false));
        dispatch(myDebtError(error));
      });
  };
};

export const setTransactionsLoading = (loading: boolean, reset?: boolean) => ({
  type: PACKAGES_TRANSACTIONS_LOADING,
  loading,
  reset,
});

export const setTransactionsErrors = (errors: any) => ({ type: PACKAGES_TRANSACTIONS_ERRORS, errors });
export const servicePackagesReset = () => ({
  type: PACKAGES_MY_RESET,
});
export const setTransactions = (
  transactions: Manage.Doctor.Item[],
  page: number,
  pageSize: number,
  totalItems: number
) => ({
  type: PACKAGES_TRANSACTIONS_SUCCESS,
  transactions,
  page,
  pageSize,
  totalItems,
});

export const fetchTransactions = (
  patientServicePackageId: number,
  page?: number,
  pageSize?: number,
  reset?: boolean
): any => {
  return (dispatch) => {
    dispatch(setTransactionsLoading(true, reset));
    getTransactions(patientServicePackageId, page, pageSize)
      .then((response: AxiosResponse<Manage.Doctor.Response>) => {
        dispatch(setTransactionsLoading(false));
        if (response.data.code === 'success') {
          dispatch(
            setTransactions(
              response.data.data.items,
              response.data.data.page,
              response.data.data.pageSize,
              response.data.data.totalItems
            )
          );
        } else {
          dispatch(setTransactionsErrors(response.data));
        }
      })
      .catch((error: any) => {
        dispatch(setTransactionsErrors(error));
        dispatch(setTransactionsLoading(false));
      });
  };
};

export const setTabState = (tab?: any, isAdmin?: boolean) => {
  let tabs = {};
  try {
    tabs = JSON.parse(localStorage.getItem(`${isAdmin ? 'admin::' : ''}tabs`));
  } catch (e) {}
  tabs = { ...tabs, ...tab };
  localStorage.setItem(`${isAdmin ? 'admin::' : ''}tabs`, JSON.stringify(tabs));
  return tabs;
};

export const resetTabState = (isAdmin?: boolean) => {
  const tabs = {};
  localStorage.setItem(`${isAdmin ? 'admin::' : ''}tabs`, JSON.stringify(tabs));
  return tabs;
};

export const getTabState = (isAdmin?: boolean): any => {
  let tabs = {};
  try {
    tabs = JSON.parse(localStorage.getItem(`${isAdmin ? 'admin::' : ''}tabs`) || `{}`);
  } catch (e) {
    tabs = {};
  }
  return tabs;
};
