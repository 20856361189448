import {
  MANAGE_SERVICE_PACKAGES_PATIENTS_LOADING,
  MANAGE_SERVICE_PACKAGES_PATIENTS_SUCCESS,
  MANAGE_SERVICE_PACKAGES_PATIENTS_ERRORS,
  MANAGE_SERVICE_PACKAGES_PATIENTS_UPDATE,
  MANAGE_SERVICE_PACKAGES_PATIENTS_UPDATE_DEBT,
  MANAGE_SERVICE_PACKAGES_PATIENTS_PROMOTION_UPDATE,
  MANAGE_SERVICE_PACKAGES_PATIENTS_ACCRUAL_STATUS_UPDATE,
} from '../actions';
import Manage from '../../types/manage';

export interface ManageServicePackagesPatientsInitialSettings {
  items?: Manage.Doctor.Item[];
  page?: number;
  pageSize?: number;
  totalItems?: number;
  hasMore?: boolean;
  errors?: any;
  loading?: boolean;
}
const initialSettings: ManageServicePackagesPatientsInitialSettings = {
  items: [],
  page: 0,
  pageSize: 50,
  totalItems: 0,
  hasMore: false,
  loading: false,
  errors: null,
};

const updatePromotionStatus = (i, action) => ({
  ...i,
  promotions: (i?.promotions || []).map((p) => {
    if (p?.id === action?.payload?.promotionId) {
      return { ...p, status: action.payload?.status };
    }
    return p;
  }),
});

const manageServicePackagesPatientsReducer = (
  state = initialSettings,
  action
): ManageServicePackagesPatientsInitialSettings => {
  switch (action.type) {
    case MANAGE_SERVICE_PACKAGES_PATIENTS_UPDATE:
      return {
        ...state,
        items: (state.items || []).map((v: any) => {
          if (v.id === action.servicePackageId) {
            return {
              ...v,
              status: {
                ...v.status,
                value: action.status,
              },
            };
          }
          return v;
        }),
      };
    case MANAGE_SERVICE_PACKAGES_PATIENTS_UPDATE_DEBT:
      return {
        ...state,
        items: (state.items || []).map((v: any) => {
          if (v.id === action.servicePackageId) {
            return {
              ...v,
              debt: {
                ...v.debt,
                ...action?.debt,
              },
            };
          }
          return v;
        }),
      };
    case MANAGE_SERVICE_PACKAGES_PATIENTS_PROMOTION_UPDATE:
      return {
        ...state,
        items: state.items.map((i: any) => {
          return i.id === action?.payload?.id ? updatePromotionStatus(i, action) : i;
        }),
      };
    case MANAGE_SERVICE_PACKAGES_PATIENTS_ACCRUAL_STATUS_UPDATE:
      return {
        ...state,
        items: state.items.map((i: any) => {
          return i.id === action?.payload?.id ? { ...i, accrualStatus: action?.payload?.status } : i;
        }),
      };
    case MANAGE_SERVICE_PACKAGES_PATIENTS_LOADING:
      return {
        ...state,
        items: action.loading && action.reset ? [] : state.items,
        loading: action.loading,
      };
    case MANAGE_SERVICE_PACKAGES_PATIENTS_SUCCESS:
      return {
        ...state,
        items: action.page > 0 ? state.items.concat(action.items) : action.items,
        page: action.page,
        pageSize: action.pageSize,
        totalItems: action.totalItems,
        hasMore: (action.page + 1) * action.pageSize < action.totalItems && action.totalItems > 0,
        errors: null,
      };
    case MANAGE_SERVICE_PACKAGES_PATIENTS_ERRORS:
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
};

export default manageServicePackagesPatientsReducer;
