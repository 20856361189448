import React from 'react';
import ContentCard from 'components/ContentCard';
import { connect } from 'react-redux';
import { Box } from '@material-ui/core';
import styles from './CurrentDoctor.module.sass';

const CurrentDoctor = (props: any) => {
  const { appointment, history } = props;
  const renderAppointment = (): JSX.Element => {
    if (appointment) {
      history.push(`/appointments/${appointment.id}`);
    }
    return (
      <Box display="flex" flex="1 1 auto" width="100%" justifyContent="center" alignItems="center">
        Активных консультаций нет
      </Box>
    );
  };
  return (
    <>
      <ContentCard>
        <ContentCard.Header>
          <div className="row">
            <div className="col-12 text-center text-xs-right text-md-center">
              <h6 className="page-header-title">Текущий прием</h6>
            </div>
          </div>
        </ContentCard.Header>
        <ContentCard.Main className={styles.CurrentDoctor}>{renderAppointment()}</ContentCard.Main>
      </ContentCard>
    </>
  );
};
export default connect(
  (state: any) => ({
    appointment: state.doctor.activeAppointment,
  }),
  {}
)(CurrentDoctor);
