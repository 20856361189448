import {combineReducers} from 'redux';

import authReducer from './authentication.reducer';
import storageReducer from './storage.reducer';
import doctorsReducer from './doctors.reducer';
import doctorDetailsReducer from './doctorDetails.reducer';
import appointmentsReducer from './appointments.reducer';
import profileReducer from './profile.reducer';
import appointmentReducer from './appointment.reducer';
import servicePackageReducer from './servicePackage.reducer';
import doctorSchedulerReducer from './doctorScheduler.reducer';
import redirectReducer from './redirect.reducer';
import doctorEventsReducer from './doctorEvents.reducer';
import doctorReducer from './doctor.reducer';
import chatReducer from './chat.reducer';
import doctorPatientsReducer from './doctorPatientsReducer.reducer';
import manageDoctorsReducer from './manageDoctorsReducer.reducer';
import managePatientsReducer from './managePatientsReducer.reducer';
import manageTariffsReducer from './manageTariffsReducer.reducer';
import manageSpecReducer from './manageSpecReducer.reducer';
import manageCategoriesReducer from './manageCategoriesReducer.reducer';
import manageServicePackagesMfoReducer from './manageServicePackagesMfoReducer.reducer';
import manageServicePackagesPatientsReducer from './manageServicePackagesPatientsReducer.reducer';
import manageReviewsReducer from './manageReviewsReducer.reducer';
import manageAppointmentsItemsReducer from './manageAppointmentsItemsReducer.reducer';
import manageDailyConclusionStrategiesReducer from './manageDailyConclusionStrategies.reducer';
import manageTransactionsReducer from './manageTransactions.reducer';
import manageReportPatientReducer from './manageReportPatientReducer.reducer';
import manageReportServicePackagesReducer from './manageReportServicePackagesReducer.reducer';
import manageReportPatientServicePackageTransactionsReducer
  from './manageReportServicePackageTransactionsReducer.reducer';
import manageReportCDSummaryReducer from "./manageReportCloseDaySummaryReducer.reducer";
import {agentServicePackagesReducer} from "./agentServicePackage.reducer";
import {manageSpecGroupsReducer} from "./manageSpecGroups.reducer";
import {managePartnersReducer} from "./managePartners.reducer";
import {manageAgentsReducer} from "./manageAgents.reducer";

const rootReducer = combineReducers({
  auth: authReducer,
  storage: storageReducer,
  redirect: redirectReducer,
  doctors: doctorsReducer,
  doctorDetails: doctorDetailsReducer,
  appointments: appointmentsReducer,
  profile: profileReducer,
  appointment: appointmentReducer,
  servicePackage: servicePackageReducer,
  doctorScheduler: doctorSchedulerReducer,
  doctorEvents: doctorEventsReducer,
  doctor: doctorReducer,
  chat: chatReducer,
  doctorPatients: doctorPatientsReducer,
  manageDoctors: manageDoctorsReducer,
  managePatients: managePatientsReducer,
  manageTariffs: manageTariffsReducer,
  manageSpec: manageSpecReducer,
  manageCategories: manageCategoriesReducer,
  manageServicePackagesMfo: manageServicePackagesMfoReducer,
  manageServicePackagesPatients: manageServicePackagesPatientsReducer,
  manageReviews: manageReviewsReducer,
  manageAppointmentsItems: manageAppointmentsItemsReducer,
  manageDailyConclusionStrategies: manageDailyConclusionStrategiesReducer,
  manageTransactions: manageTransactionsReducer,
  manageReportPatient: manageReportPatientReducer,
  manageReportServicePackages: manageReportServicePackagesReducer,
  manageReportPatientServicePackageTransactions: manageReportPatientServicePackageTransactionsReducer,
  manageReportCDSummaryReducer: manageReportCDSummaryReducer,
  agentServicePackageReducer: agentServicePackagesReducer,
  manageSpecGroupsReducer: manageSpecGroupsReducer,
  managePartnersReducer: managePartnersReducer,
  manageAgents: manageAgentsReducer
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
