import React, { JSX } from 'react';
import PageTitle from '../../../components/PageTitle';
import PageCard from '../../../components/PageCard';
import AgentMetricsFilter from './components/AgentMetricsFilter';
import AgentMetricsToolbar from './components/AgentMetricsToolbar';
import AgentMetricsList from './components/AgentMetricsList';
import AgentMetricsCount from './components/AgentMetricsCount';
import AgentMetricsTitle from './components/AgentMetricsTitle';

const AgentMetrics = (): JSX.Element => {
  return (
    <>
      <PageCard showHeader={false}>
        <PageTitle center={false}>Аналитика</PageTitle>
        <AgentMetricsTitle />
        <AgentMetricsFilter />
        <AgentMetricsCount />
        <AgentMetricsList />
        <AgentMetricsToolbar />
      </PageCard>
    </>
  );
};

export default AgentMetrics;
