import React, { useState, useEffect, useCallback, FormEvent, useMemo } from 'react';
import classNames from 'classnames';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { validateFieldOnChange, getFieldErrors as getFieldErrorsUtil, convertToUserData } from 'utils';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import format from 'date-fns/format';
import ruLocale from 'date-fns/locale/ru';

import timeZones from 'utils/timeZones';

import { fetchProfile, fetchDisabilities, fetchBloodTypes, setUserData } from 'store/actions';
import { updateProfile, authProfileMeta } from 'api';
import { toast } from 'react-toastify';

import ContentLoading from 'components/ContentLoading';
import InputField from 'components/Forms/InputField';
import FormValidator from 'components/Forms/FormValidator';
import Button from 'components/Forms/Button';
import SelectInput from 'components/Forms/SelectInput';
import FormInvalidMessage from 'components/Forms/FormInvalidMessage';
import { addYears } from 'date-fns';
import { Box } from '@material-ui/core';
import Avatar from './Avatar';

import styles from '../ProfilePage.module.sass';

const sexOptions = [
  {
    value: 'MALE',
    label: 'Мужской',
  },
  {
    value: 'FEMALE',
    label: 'Женский',
  },
];

const ProfileView = (): JSX.Element => {
  const dispatch = useDispatch();
  const [profileForm, setProfileForm] = useState({
    id: '',
    avatar: null,
    avatarId: null,
    firstName: '',
    middleName: '',
    lastName: '',
    sex: null,
    birthday: null,
    isBirthdayError: false,
    heightCm: null,
    weightKilo: null,
    shared: false,
    disability: '',
    blood: '',
    allergy: '',
    allergyEdit: false,
    chronicIllness: '',
    chronicEdit: false,
    zoneId: '',
    isTimezoneAuto: true,
    phone: '',
    email: '',
  });

  const { userToken, profile, profileLoading, profileLoadingError, bloodTypes, disabilities } = useSelector(
    (state: RootStateOrAny) => ({
      userToken: state.auth.userToken,

      profile: state.profile.profile,
      profileLoading: state.profile.profileLoading,
      profileLoadingError: state.profile.profileLoadingError,

      bloodTypes: state.profile.bloodTypes,
      disabilities: state.profile.disabilities,
    })
  );

  const [profileFormErrors, setProfileFormErrors] = useState([]);

  const [profileUpdateLoading, setProfileUpdateLoading] = useState(false);

  useEffect(() => {
    dispatch(fetchProfile());

    if (bloodTypes.length === 0) {
      dispatch(fetchBloodTypes());
    }

    if (disabilities.length === 0) {
      dispatch(fetchDisabilities());
    }
  }, [disabilities.length, dispatch, bloodTypes.length]);

  const fillData = useCallback(() => {
    const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    setProfileForm((profileForm) => ({
      ...profileForm,
      ...profile,
      blood: profile?.blood?.id || null,
      disability: profile?.disability?.id || null,
      avatarId: profile?.avatar?.id || null,
      birthday: profile?.birthday ? new Date(profile?.birthday) : null,
      chronicEdit: !!profile?.chronicIllness,
      allergyEdit: !!profile?.allergy,
      shared: profile?.shared,
      zoneId: profile?.zoneId || clientTimezone,
    }));
  }, [profile]);

  useEffect(() => {
    !profileForm?.id && profile && fillData();
  }, [fillData, profile, profileForm]);

  const disabilityOptions = useMemo(
    () =>
      disabilities.map((item) => ({
        value: item.id,
        label: item.name,
      })),
    [disabilities]
  );

  const bloodOptions = useMemo(
    () =>
      bloodTypes.map((item) => ({
        value: item.id,
        label: item.name,
      })),
    [bloodTypes]
  );

  const validateOnChange = (name: string, value: any, event, element?) => {
    validateFieldOnChange(
      name,
      value,
      event,
      profileForm,
      setProfileForm,
      profileFormErrors,
      setProfileFormErrors,
      element
    );
  };

  const getFieldErrors = (field: string) => getFieldErrorsUtil(field, profileFormErrors);

  const profileUpdateSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();

      const form: any = e.target;
      const inputs = [...form.elements].filter((i) => ['INPUT', 'SELECT', 'TEXTAREA'].includes(i.nodeName));
      const bulkValidate = FormValidator.bulkValidate(inputs);
      const { errors } = bulkValidate;
      let { hasError } = bulkValidate;

      if (!profileForm.birthday || profileForm.isBirthdayError) {
        errors.push({
          field: 'birthday',
        });

        hasError = true;
      }

      setProfileFormErrors([...errors]);

      if (!hasError) {
        setProfileUpdateLoading(true);

        updateProfile({
          firstName: profileForm.firstName,
          middleName: profileForm.middleName,
          lastName: profileForm.lastName,
          birthday: profileForm.birthday ? format(new Date(profileForm.birthday), 'yyyy-MM-dd') : null,
          sex: profileForm.sex,
          weightKilo: profileForm.weightKilo,
          heightCm: profileForm.heightCm,
          email: profileForm.email,
          shared: profileForm.shared,
          allergy: profileForm.allergyEdit ? profileForm.allergy : null,
          chronicIllness: profileForm.chronicEdit ? profileForm.chronicIllness : null,

          bloodTypeId: profileForm.blood,
          disabilityTypeId: profileForm.disability,

          avatarId: profileForm.avatarId,
          zoneId: profileForm.zoneId,
        })
          .then(() => {
            setProfileUpdateLoading(false);

            authProfileMeta(userToken)
              .then((response) => {
                if (response.data.data) {
                  setUserData(convertToUserData(response.data.data));
                }
              })
              .catch(() => {});

            toast.success('Профиль успешо обновлен!');
          })
          .catch(() => {
            setProfileUpdateLoading(false);
            toast.error('Ошибка при поптке обновления профиля');
          });
      }
    },
    [profileForm, userToken]
  );

  const resetProfileData = useCallback(() => {
    profile && fillData();
  }, [fillData, profile]);

  const handleUpdateAvatar = useCallback(
    (avatarId: string | null) => {
      setProfileForm({
        ...profileForm,
        avatarId,
      });
    },
    [profileForm]
  );

  const beforeMaskedValueChange = useCallback((newState) => {
    let { value, selection } = newState;
    if (value?.length > 1 && value[1] === '8') {
      value = value.replace('+8', '+7');
    }
    if (value?.length > 1 && value[1] !== '7' && value[1] !== '_') {
      const { index = 1 } = value.match(/[^+0-9]/) || {};
      selection = { start: index + 3, end: index + 3 };
      value = value.replace('+', '+7').substr(0, 18);
    }
    return {
      ...newState,
      value,
      selection,
    };
  }, []);

  const getData = useCallback(() => {
    dispatch(fetchProfile());
  }, [dispatch]);

  return (
    <ContentLoading isLoading={profileLoading} isError={profileLoadingError} fetchData={getData}>
      <div className={styles.profileDetails}>
        <form onSubmit={profileUpdateSubmit}>
          <div className="text-center">
            <Avatar
              sex={profileForm.sex}
              avatar={profileForm.avatar}
              permissions={[]}
              id={profileForm.id}
              onUpdateAvatar={handleUpdateAvatar}
            />
          </div>

          <div className="row mt-3">
            <div className="col-12 mb-3 text-center">
              <h4>Персональные данные</h4>
            </div>
            <div className="col-sm-4 mb-3">
              <InputField
                name="lastName"
                value={profileForm.lastName}
                onChange={(e) => validateOnChange('lastName', e.target.value, e)}
                data-validate='["required", "name", "maxlen"]'
                data-param={36}
                errors={getFieldErrors('lastName')}
                block
                placeholder="Фамилия"
              />
            </div>
            <div className="col-sm-4 mb-3">
              <InputField
                name="firstName"
                value={profileForm.firstName}
                onChange={(e) => validateOnChange('firstName', e.target.value, e)}
                data-validate='["required", "name", "maxlen"]'
                data-param={36}
                errors={getFieldErrors('firstName')}
                block
                placeholder="Имя"
              />
            </div>
            <div className="col-sm-4 mb-3">
              <InputField
                name="middleName"
                value={profileForm.middleName}
                onChange={(e) => validateOnChange('middleName', e.target.value, e)}
                data-validate='["required", "name", "maxlen"]'
                data-param={36}
                errors={getFieldErrors('middleName')}
                block
                placeholder="Отчество"
              />
            </div>
            <div className="col-sm-6 mb-3">
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
                <KeyboardDatePicker
                  autoOk
                  maxDate={addYears(new Date(), -18)}
                  className={classNames({
                    'MuiInputBase-input-error': getFieldErrors('birthday').length > 0,
                  })}
                  disableToolbar
                  value={profileForm.birthday}
                  onChange={(date) => {
                    setProfileForm({
                      ...profileForm,
                      birthday: date,
                    });

                    const profileErrorsTemp = [...profileFormErrors];

                    const errorIndex = profileErrorsTemp.findIndex((error) => error.field === 'birthday');

                    if (errorIndex >= 0) {
                      profileErrorsTemp.splice(errorIndex, 1);
                      setProfileFormErrors([...profileErrorsTemp]);
                    }
                  }}
                  onError={(error) => {
                    const isError = !!error;

                    if (isError !== profileForm.isBirthdayError) {
                      setProfileForm({
                        ...profileForm,
                        isBirthdayError: isError,
                      });
                    }
                  }}
                  format="dd-MM-yyyy"
                  views={['year', 'month', 'date']}
                  disableFuture
                  animateYearScrolling
                  openTo="year"
                  cancelLabel="Отменить"
                  placeholder="День рождения"
                  inputProps={{ name: 'birthday' }}
                  invalidDateMessage="Неверный формат"
                  maxDateMessage="Возраст должен быть 18+"
                  okLabel="OK"
                />
              </MuiPickersUtilsProvider>

              {getFieldErrors('birthday').length > 0 && (
                <FormInvalidMessage>Поле обязательно для ввода</FormInvalidMessage>
              )}
            </div>
            <div className="col-sm-6 mb-3">
              <SelectInput
                name="sex"
                value={
                  profileForm.sex
                    ? {
                        value: sexOptions.find((option) => option.value === profileForm.sex).value,
                        label: sexOptions.find((option) => option.value === profileForm.sex).label,
                      }
                    : null
                }
                onChange={(option, e) => validateOnChange('sex', option ? option.value : option, e)}
                data-validate='["required"]'
                errors={getFieldErrors('sex')}
                options={sexOptions}
                block
                isClearable
                placeholder="Пол"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 offset-md-3 text-center">
              <h4 className="mt-4 mb-3">Медицинские данные</h4>
            </div>
            <div className="col-md-3" />
            <div className="col-sm-6 col-md-3 mb-3">
              <InputField
                type="number"
                min={0}
                name="heightCm"
                value={profileForm.heightCm}
                onChange={(e) => validateOnChange('heightCm', e.target.value, e)}
                data-validate='["required"]'
                errors={getFieldErrors('heightCm')}
                block
                placeholder="Рост"
              />
            </div>
            <div className="col-sm-6 col-md-3 mb-3">
              <InputField
                type="number"
                min={0}
                name="weightKilo"
                value={profileForm.weightKilo}
                onChange={(e) => validateOnChange('weightKilo', e.target.value, e)}
                data-validate='["required"]'
                errors={getFieldErrors('weightKilo')}
                block
                placeholder="Вес"
              />
            </div>
            <div className="col-sm-6 col-md-3 mb-3">
              <SelectInput
                value={
                  profileForm.disability && disabilityOptions.find((option) => option.value === profileForm.disability)
                    ? {
                        value: disabilityOptions.find((option) => option.value === profileForm.disability).value,
                        label: disabilityOptions.find((option) => option.value === profileForm.disability).label,
                      }
                    : null
                }
                onChange={(option, e) => validateOnChange('disability', option ? option.value : option, e)}
                options={disabilityOptions}
                isClearable
                block
                placeholder="Инвалидность"
              />
            </div>
            <div className="col-sm-6 col-md-3 mb-3">
              <SelectInput
                name="blood"
                value={
                  profileForm.blood && bloodOptions.find((option) => option.value === profileForm.blood)
                    ? {
                        value: bloodOptions.find((option) => option.value === profileForm.blood).value,
                        label: bloodOptions.find((option) => option.value === profileForm.blood).label,
                      }
                    : null
                }
                onChange={(option, e) => validateOnChange('blood', option ? option.value : option, e)}
                options={bloodOptions}
                isClearable
                block
                placeholder="Группа крови"
              />
            </div>
            <div className="col-sm-6 mb-3">
              <div className={styles.profileDetails_editInput}>
                <InputField
                  name="allergy"
                  value={profileForm.allergy}
                  onChange={(e) => validateOnChange('allergy', e.target.value, e)}
                  block
                  disabled={!profileForm.allergyEdit}
                  placeholder="Аллергические реакции"
                />

                <span
                  className={classNames(styles.profileDetails_editInput_checkmark, {
                    [styles.profileDetails_editInput_checkmark_active]: profileForm.allergyEdit,
                  })}
                  onClick={() =>
                    setProfileForm({
                      ...profileForm,
                      allergyEdit: !profileForm.allergyEdit,
                    })
                  }
                />
              </div>
            </div>
            <div className="col-sm-6 mb-3">
              <div className={styles.profileDetails_editInput}>
                <InputField
                  name="chronicIllness"
                  value={profileForm.chronicIllness}
                  onChange={(e) => validateOnChange('chronicIllness', e.target.value, e)}
                  block
                  disabled={!profileForm.chronicEdit}
                  placeholder="Хронические заболевания"
                />

                <span
                  className={classNames(styles.profileDetails_editInput_checkmark, {
                    [styles.profileDetails_editInput_checkmark_active]: profileForm.chronicEdit,
                  })}
                  onClick={() =>
                    setProfileForm({
                      ...profileForm,
                      chronicEdit: !profileForm.chronicEdit,
                    })
                  }
                />
              </div>
            </div>
            <div className="col-12 mb-3 d-flex flex-column">
              <div className={classNames(styles.profileDetails_editInput, styles.profileDetails_editInput_shared)}>
                <label className={classNames('mb-2', styles.profileDetails_editInput_shared_label)}>
                  Поможет врачу больше узнать о вашем здоровье
                </label>
                <Box className={styles.profileDetails_editInput_shared_wrapper}>
                  <Box>Делиться журналом самонаблюдений с врачом</Box>

                  <label className="toggle-checkbox-label">
                    <input
                      type="checkbox"
                      checked={profileForm?.shared}
                      onChange={() => {
                        setProfileForm((profileForm) => ({ ...profileForm, shared: !profileForm?.shared }));
                      }}
                    />

                    <span className="checkmark">
                      <span className="check" />
                    </span>
                  </label>
                </Box>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 offset-md-3 text-center">
              <h4 className="mt-4 mb-3">Контактные данные</h4>
            </div>
            <div className="col-md-3" />
            <div className="col-sm-4 mb-3">
              <InputField
                name="phone"
                value={profileForm.phone}
                onChange={(e) => validateOnChange('phone', e.target.value, e)}
                data-validate='["required", "phone"]'
                errors={getFieldErrors('phone')}
                disabled
                block
                placeholder="Телефон"
                beforeMaskedValueChange={beforeMaskedValueChange}
                mask="+9 (999) 999-99-99"
              />
            </div>
            <div className="col-sm-4 mb-3">
              <InputField
                name="email"
                value={profileForm.email}
                onChange={(e) => validateOnChange('email', e.target.value, e)}
                data-validate='["required", "email", "maxlen"]'
                data-param={36}
                errors={getFieldErrors('email')}
                block
                placeholder="Эл. почта"
              />
            </div>
            <div className="col-sm-4">
              <SelectInput
                name="timezone"
                value={timeZones.find((zone) => zone.value === profileForm.zoneId)}
                onChange={(option, e) => validateOnChange('zoneId', option ? option.value : option, e)}
                options={timeZones}
                block
                placeholder="Часовой пояс"
              />
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-md-6 mb-3">
              <Button block onClick={resetProfileData} disabled={profileUpdateLoading}>
                Отменить
              </Button>
            </div>
            <div className="col-md-6">
              <Button type="submit" color="primary" block isLoading={profileUpdateLoading}>
                Сохранить изменения
              </Button>
            </div>
          </div>
        </form>
      </div>
    </ContentLoading>
  );
};

export default ProfileView;
