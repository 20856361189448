import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box } from '@material-ui/core';
import ContentLoading from 'components/ContentLoading';
import Button from 'components/Forms/Button';
import AdminServicePackageGroupEditView from 'components/AdminServicePackageGroupEditView/AdminServicePackageGroupEditView';
import SearchString from 'components/SearchString';
import styles from './ServicePackagesAdminGroups.module.sass';
import useApiFx from '../../../../hooks/useApiFx';
import { getServicePackageGroups } from '../../../../$api';
import useStoreApi from '../../../../hooks/useStoreApi';
import { getServicePackageAttachments } from '../../../../api/servicePackage/getServicePackageAttachments';
import ServicePackagesDocuments from '../ServicePackagesDocuments';
import { deleteServicePackageAttachment } from '../../../../api/servicePackage/deleteServicePackageAttachment';

const ServicePackagesAdminGroups = (): JSX.Element => {
  const [query, setQuery] = useState(null);
  const [editGroup, onGroupSelected] = useState<any>();
  const [tariffDocId, setTariffDocId] = useState<string>(null);
  const [params, setParams] = useState({
    query: '',
    deleted: false,
  });

  const getServicePackageGroupsFx = useApiFx(getServicePackageGroups);

  const { loading, error, data } = useStoreApi(getServicePackageGroupsFx, { loading: true });

  const getServicePackageAttachmentsFx = useApiFx(getServicePackageAttachments);

  const deleteServicePackageAttachmentFx = useApiFx(deleteServicePackageAttachment);

  const {
    loading: tariffDocLoading,
    error: tariffDocError,
    data: tariffDocsData,
  } = useStoreApi(getServicePackageAttachmentsFx, { loading: true });

  const { loading: tariffDocDeleteLoading, error: tariffDocDeleteError } = useStoreApi(
    deleteServicePackageAttachmentFx,
    { loading: true }
  );

  const tariffDocs = useMemo(() => tariffDocsData?.data?.data?.items || [], [tariffDocsData]);

  const onOpenEditDialog = (item: any) => {
    onGroupSelected(item);
  };

  const onEditCanceled = useCallback(() => {
    onGroupSelected(null);
  }, []);

  useEffect(() => {
    const action = () => setParams((params) => ({ ...params, query }));
    const timer = query !== null ? setTimeout(action, 500) : query === '' ? action() : null;
    return () => {
      timer && clearTimeout(timer);
    };
  }, [query]);

  const onRefresh = useCallback(() => {
    getServicePackageGroupsFx({ params }).then();
  }, [getServicePackageGroupsFx, params]);

  const getTariffDocs = useCallback(() => {
    getServicePackageAttachmentsFx({ tariffDocId, isGroup: true });
  }, [getServicePackageAttachmentsFx, tariffDocId]);
  const deleteTariffDocs = useCallback(
    (item: any) => {
      deleteServicePackageAttachmentFx({ item }).finally(getTariffDocs);
    },
    [deleteServicePackageAttachmentFx, getTariffDocs]
  );

  const onEditFinished = useCallback(() => {
    onGroupSelected(null);
    onRefresh();
  }, [onRefresh]);

  useEffect(() => {
    onRefresh();
  }, [onRefresh]);
  useEffect(() => {
    tariffDocId && getTariffDocs();
  }, [tariffDocId, getTariffDocs]);
  const items = useMemo(() => data?.data?.data?.items || [], [data]);
  const isEmptyList = useMemo(() => items.length === 0, [items]);
  const isSearch = useMemo(() => isEmptyList && query?.length > 0, [isEmptyList, query]);
  const isNoGroups = useMemo(() => isEmptyList && !isSearch, [isEmptyList, query]);
  return (
    <>
      <Box className={styles.root}>
        <Box className={styles.root_toolbar}>
          <SearchString value={query} onChange={setQuery} placeholder="Название группы или внешний идентификатор" />
          <Box>
            <Button size="md" color="primary" onClick={() => onOpenEditDialog({})}>
              Создать новую группу
            </Button>
          </Box>
        </Box>
        <Box className={styles.root_items}>
          <ContentLoading isLoading={loading} isError={error} fetchData={onRefresh}>
            {isNoGroups && !loading && <Box textAlign="center">У вас пока нет созданных тарифов</Box>}
            {isSearch && !loading && <Box textAlign="center">По вашему запросу ничего не найдено</Box>}
            {!isEmptyList &&
              items?.map((item: any, index: number) => (
                <Box className={styles.root_item} onClick={() => onGroupSelected(item)} key={index}>
                  <Box className={styles.root_item_wrapper}>
                    <Box className={styles.root_item_data}>
                      <Box className={styles.root_item_name}>{item.name}</Box>
                      {item.description && <Box className={styles.root_item_text}>{item.description}</Box>}
                      {item.externalKey && (
                        <Box className={styles.root_item_text}>{`Внешний ключ: ${item.externalKey}`}</Box>
                      )}
                      {item.tech && <Box className={styles.root_item_tech}>Техническая группа</Box>}
                    </Box>
                    <Box className={styles.root_item_action}>
                      <Button
                        className={styles.ServicePackagesAdminMfo_Button}
                        size="sm"
                        color={item.id === tariffDocId ? 'primary' : 'default'}
                        onClick={(event) => {
                          event.stopPropagation();
                          if (item.id === tariffDocId) {
                            setTariffDocId(null);
                          } else {
                            setTariffDocId(item.id);
                          }
                        }}
                      >
                        Шаблоны документов
                      </Button>
                    </Box>
                  </Box>
                  {item.id === tariffDocId && (
                    <>
                      <ServicePackagesDocuments
                        id={tariffDocId}
                        loading={tariffDocLoading || tariffDocDeleteLoading}
                        error={tariffDocError || tariffDocDeleteError}
                        isGroup
                        tariffDocs={tariffDocs}
                        onDelete={deleteTariffDocs}
                        getData={getTariffDocs}
                      />
                    </>
                  )}
                </Box>
              ))}
          </ContentLoading>
        </Box>
      </Box>
      {editGroup && (
        <AdminServicePackageGroupEditView onUpdated={onEditFinished} onCancel={onEditCanceled} data={editGroup} />
      )}
    </>
  );
};

export default ServicePackagesAdminGroups;
