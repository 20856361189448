import React, { useEffect, useState } from 'react';
import { Backdrop, Box, Dialog, Grid, Avatar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import { formatDistanceStrict, parseISO } from 'date-fns';
import { AxiosResponse } from 'axios';
import { ru } from 'date-fns/locale';
import styles from './AdminPatientAppointmentView.module.sass';
import ContentCard from '../ContentCard';
import ContentLoading from '../ContentLoading';
import Manage from '../../types/manage';
import InputField from '../Forms/InputField';
import { decodeAvatarResource } from '../../utils';
import { format } from '../../utils/date';
import { getAdminPatientInfo } from '../../api';
import { DoctorsEventStatus } from '../../types';
import { ReactComponent as StarFillIcon } from '../../assets/img/icons/star-fill-icon.svg';
import { AppointmentConclusionLinkView } from '../AppointmentCoclusion/AppointmentConclusionLinkView';

interface AdminPatientAppointmentViewInterface {
  appointment: Manage.Patient.Appointments.Item;
  onClose: () => void;
}

const AdminPatientAppointmentView = (props: AdminPatientAppointmentViewInterface) => {
  const { onClose = () => {}, appointment } = props;
  const getTypeOfAppointment = () => {
    return appointment.type.name;
  };

  const getChannelOfAppointment = () => {
    return appointment.channel.value === 'VIDEO'
      ? ['чат', 'видео', 'аудио'].join('/')
      : appointment.channel.value === 'AUDIO'
      ? ['чат', 'аудио'].join('/')
      : 'чат';
  };

  const stars = [];

  for (let i = 0; i < 5; i++) {
    const isFilled: boolean = true;
    stars.push(
      <StarFillIcon
        key={i}
        className={classNames('rate-star', {
          'rate-star-active': isFilled,
        })}
      />
    );
  }
  const getLabelStatus = (status: DoctorsEventStatus): string => {
    switch (status) {
      case DoctorsEventStatus.AWAITS:
        return 'Ожидает ответа';
      case DoctorsEventStatus.CANCELED:
        return 'Отменена';
      case DoctorsEventStatus.DONE:
        return 'Завершена';
      case DoctorsEventStatus.IN_PROGRESS:
        return 'Сейчас идет';
      case DoctorsEventStatus.FINISHED:
        return 'Ожидает заключение';
      case DoctorsEventStatus.FAILED:
        return 'Техническая проблема';
      default:
        return null;
    }
  };
  return (
    <Dialog
      PaperProps={{ className: classNames(styles.Dialog) }}
      BackdropComponent={(props) => <Backdrop className={styles.Backdrop} {...props} />}
      onClose={onClose}
      open
    >
      <ContentCard className={styles.AdminPatientAppointmentView}>
        <ContentCard.Header className={styles.AdminPatientAppointmentView_Header}>
          <h6 className="page-header-title">Информация о консультации</h6>
          <CloseIcon onClick={onClose} className={styles.AdminPatientAppointmentView_CloseIcon} />
        </ContentCard.Header>
        <ContentCard.Main className={styles.AdminPatientAppointmentView_Content}>
          <form onSubmit={() => {}}>
            <Box
              className={classNames(
                styles.AdminPatientAppointmentView_Content_Item,
                styles.AdminPatientAppointmentView_Content_Item_Profile
              )}
            >
              <Box>
                <Avatar
                  className={styles.AdminPatientAppointmentView_Content_Item_Profile_Img}
                  src={decodeAvatarResource(appointment.patient.avatar, 360, appointment.patient.sex)}
                />
                <Box className={styles.AdminPatientAppointmentView_Content_Item_Profile_Data}>
                  <Box className={styles.AdminPatientAppointmentView_Content_Item_Profile_Name}>
                    {appointment.patient.firstName} {appointment.patient.lastName}
                  </Box>
                  <Box className={styles.AdminPatientAppointmentView_Content_Item_Profile_Type}>
                    Тип консультации: {getTypeOfAppointment()}
                  </Box>
                  <Box className={styles.AdminPatientAppointmentView_Content_Item_Profile_Type}>
                    Тип связи: {getChannelOfAppointment()}
                  </Box>
                  <Box className={styles.AdminPatientAppointmentView_Content_Item_Profile_Id}>ID: {appointment.id}</Box>
                </Box>
              </Box>
              <Box>{stars}</Box>
            </Box>
            <Box className={classNames(styles.AdminPatientAppointmentView_Content_Wrapper)}>
              <Box className={classNames(styles.AdminPatientAppointmentView_Content_Item)}>
                <Box component="h4" mb={3}>
                  Детали консультации
                </Box>
                <Grid
                  className={classNames(styles.AdminPatientAppointmentView_Content_Item_Container)}
                  container
                  justify="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid container item md={4} xs={12} justify="center" direction="column" alignItems="flex-start">
                    <Box mb={1} className={classNames(styles.AdminPatientAppointmentView_Content_Label)}>
                      Статус
                    </Box>
                    <Box
                      className={classNames(
                        styles[
                          `AdminPatientAppointmentView_Content_Status_${appointment.status.value as DoctorsEventStatus}`
                        ]
                      )}
                    >
                      {getLabelStatus(appointment.status.value as DoctorsEventStatus)}
                    </Box>
                  </Grid>
                  <Grid container item md={4} xs={12} justify="center" direction="column" alignItems="flex-start">
                    <Box mb={1} className={classNames(styles.AdminPatientAppointmentView_Content_Label)}>
                      Дата
                    </Box>
                    <Box>{format(parseISO(appointment.startTime.toString()), 'dd.MM.yyyy | HH:mm')}</Box>
                  </Grid>
                  <Grid container item md={4} xs={12} justify="center" direction="column" alignItems="flex-start">
                    <Box mb={1} className={classNames(styles.AdminPatientAppointmentView_Content_Label)}>
                      Продолжительность (мин)
                    </Box>
                    <Box>
                      {appointment.factDurationSeconds ? Math.round(appointment.factDurationSeconds / 60) : '0'}
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <AppointmentConclusionLinkView
                      url={appointment.conclusionUrl}
                      numberOfDay={appointment.numberOfDay}
                      isDaily={appointment.serviceType?.value === 'DAILY'}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputField
                      value=""
                      type="textarea"
                      readOnly
                      onChange={(e) => {}}
                      block
                      disabled
                      label="Отзыв клиента"
                      placeholder="Отзыв клиента"
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </form>
        </ContentCard.Main>
      </ContentCard>
    </Dialog>
  );
};
export default AdminPatientAppointmentView;
