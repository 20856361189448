import React, { useMemo } from 'react';
import { Box, styled } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

type CertificatePdfProps = {
  firstName?: string;
  lastName?: string;
  middleName?: string;
  spName?: string;
  limit?: number;
  appointmentPerDay?: number;
  spDuration?: number;
};
const Pdf = styled(Box)(() => ({
  backgroundColor: '#f4f9fd',
  width: '100%',
  height: '100%',
  margin: '0 auto',
  padding: '20px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
}));
const Line = styled((props) => <Box {...props} component="p" />)(() => ({
  margin: '5px 0',
}));
const Block = styled((props) => <Box {...props} />)(() => ({
  display: 'inline-flex',
  alignItems: 'center',
  '& > svg': {
    width: '10px',
    height: '10px',
    marginRight: '10px',
  },
}));
const CertificatePdf = (props: CertificatePdfProps) => {
  const { firstName, lastName, middleName, spName, limit, appointmentPerDay, spDuration } = props;
  const fio = useMemo(
    () => [lastName, firstName, middleName].filter((v) => v).join(' '),
    [firstName, lastName, middleName]
  );
  return (
    <>
      <Pdf id="certificatePdf">
        <img width="100%" src={require('../../assets/img/certificate/part-1.png')} />
        <div>
          <div
            style={{
              backgroundColor: '#2e5da8',
              padding: '10px 30px',
              color: 'white',
              fontSize: '18px',
              borderRadius: '20px',
              margin: '0 10px',
            }}
          >
            <Line>
              Уважаемый <strong>{fio}</strong>,
            </Line>
            <Line>поздравляем с приобретением подписки на сервис Док.Онлайн.</Line>
            <Line>
              Теперь Вам доступен тариф <strong>{spName}</strong>, с помощью которого
            </Line>
            <Line>Вы можете консультироваться с врачом из любой точки </Line>
            <Line>земного шара и в любое удобное время. </Line>
          </div>
          <div
            style={{
              backgroundColor: '#2e5da8',
              padding: '10px 30px',
              color: 'white',
              fontSize: '18px',
              borderRadius: '20px',
              margin: '10px 10px 0 10px',
            }}
          >
            <Line>
              <strong>В ваш тарифный план входят:</strong>
            </Line>
            {!!appointmentPerDay && (
              <Block>
                <FiberManualRecordIcon />
                <Line>
                  Консультации врача-терапевта | Лимит консультаций в день <strong>{appointmentPerDay}</strong>
                </Line>
              </Block>
            )}
            {!!limit && (
              <Block>
                <FiberManualRecordIcon />
                <Line>
                  Консультации узкоспециализированных врачей | Доступно консультаций <strong>{limit}</strong>
                </Line>
              </Block>
            )}
            {!!spDuration && (
              <Block>
                <FiberManualRecordIcon />
                <Line>
                  Длительность тарифа (дней) <strong>{spDuration}</strong>
                </Line>
              </Block>
            )}
          </div>
        </div>
        <img width="100%" src={require('../../assets/img/certificate/part-2.png')} />
      </Pdf>
    </>
  );
};

export default CertificatePdf;
