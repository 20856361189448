import React, { ReactElement, useCallback } from 'react';
import { Box, styled } from '@material-ui/core';
import Button from '../../../../../components/Forms/Button';
import { getAgentReportFx } from '../../store/effects';
import useStoreApi from '../../../../../hooks/useStoreApi';
import saveFileInBrowser from '../../../../../utils/saveFile';

const BoxStyled = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
}));
const AgentSaleReport = (): ReactElement => {
  const { loading } = useStoreApi(getAgentReportFx, { loading: false });
  const saveFile = useCallback(saveFileInBrowser, []);
  const handleSubmit = useCallback(() => {
    getAgentReportFx().then(saveFile);
  }, [saveFile]);
  return (
    <>
      <BoxStyled>
        <Button
          isLoading={loading}
          size="md"
          onClick={handleSubmit}
          disabled={loading}
          className="mb-5"
          type="button"
          color="primary"
        >
          Скачать
        </Button>
      </BoxStyled>
    </>
  );
};

export default AgentSaleReport;
