import React, { PropsWithChildren, useCallback, useEffect, useMemo } from 'react';
import Header from 'components/Header';
import MainContent from 'components/MainContent';
import { RootStateOrAny, shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { UserRole } from 'types';
import { updateDoctorMeta } from 'store/actions';
import { RootState } from 'store/reducers';
import SidebarPersonalDoctor from 'components/SidebarPersonalDoctor';
import { AUDIO_SIGNAL } from 'constants/common';
import useIsDoctorRole from 'hooks/useIsDoctorRole';
import HasRole from '../HasRole';
import styles from './Layout.module.sass';

export interface PropsInterface extends PropsWithChildren<any> {
  showSidebar?: boolean;
  showFooter?: boolean;
  showPersonalDoctor?: boolean;
}

const audio = new Audio(AUDIO_SIGNAL);
const Layout = (props: PropsInterface): JSX.Element => {
  const dispatch = useDispatch();
  const isDoctor: boolean = useIsDoctorRole();
  const handleUpdateDoctorMeta = useCallback(() => {
    dispatch(updateDoctorMeta());
  }, [dispatch]);

  const { children, showSidebar = true, showFooter = false, showPersonalDoctor = true } = props;
  const activeAppointment = useSelector(
    (state: RootState) => state.doctor.activeAppointment,
    (p1, p2) => p1?.id === p2?.id && p1?.status?.value === p2?.status?.value
  );
  const isAwaitsDoctor = useMemo(
    () => isDoctor && activeAppointment?.status?.value === 'AWAITS',
    [activeAppointment?.status?.value, isDoctor]
  );
  useEffect(() => {
    const interval = isDoctor ? setInterval(() => handleUpdateDoctorMeta(), 20000) : null;
    return () => {
      interval && clearInterval(interval);
    };
  }, [handleUpdateDoctorMeta, isDoctor]);

  useEffect(() => {
    const interval = isAwaitsDoctor ? setInterval(() => audio.play().then().catch(), 5000) : null;
    return () => {
      interval && clearInterval(interval);
    };
  }, [isAwaitsDoctor]);
  return (
    <>
      <div className={styles.root}>
        <div className={styles.root_wrapper}>
          <Header />
          {showPersonalDoctor && (
            <HasRole target={UserRole.PATIENT}>
              <SidebarPersonalDoctor onlyMobile />
            </HasRole>
          )}
          <MainContent showSidebar={showSidebar}>{children}</MainContent>
        </div>
        {showFooter && (
          <Box className={styles.Footer}>
            <Box component="p">
              Общество с ограниченной ответственностью «МЕДЭСТ», ОГРН 1167746687987, ИНН 9717034553, КПП 771401001,
              адрес место нахождения: г. Москва, Муниципальный округ Хорошевский, ул. Викторенко, д. 16, стр. 1,
              помещение 13.
            </Box>
          </Box>
        )}
      </div>
    </>
  );
};
export default Layout;
