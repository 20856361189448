import React, { useState } from 'react';
import { Backdrop, Box, Dialog, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import styles from './AdminDailyConclusionDelete.module.sass';
import ContentCard from '../ContentCard';
import Button from '../Forms/Button';
import { removeDailyConclusionStrategy } from '../../api/dailyconclusionstrategies/removeDailyConclusionStrategy';
import { removeDailyConclusionStrategyRules } from '../../api/dailyconclusionstrategies/removeDailyConclusionStrategyRules';

interface PropsInterface {
  item: any;
  isDailyConclusion?: boolean;
  onDelete: () => void;
  onClose: () => void;
}

const AdminDailyConclusionDelete = (props: PropsInterface) => {
  const { onClose = () => {}, onDelete = () => {}, item, isDailyConclusion = true } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const submit = (): void => {
    setLoading(true);
    // @ts-ignore
    (isDailyConclusion ? removeDailyConclusionStrategy(item.id) : removeDailyConclusionStrategyRules(item.id))
      .then((response: AxiosResponse<any>) => {
        if (response && response?.data?.code === 'success') {
          return item;
        }
        throw new Error();
      })
      .then(() => {
        onDelete();
        setLoading(false);
      })
      .catch((err) => {
        toast.error('Ошибка при запросе');
        setLoading(false);
      });
  };
  return (
    <Dialog
      PaperProps={{ className: classNames(styles.Dialog) }}
      BackdropComponent={(props) => <Backdrop className={styles.Backdrop} {...props} />}
      onClose={onClose}
      open
    >
      <ContentCard className={styles.AdminDailyConclusionDelete}>
        <ContentCard.Header className={styles.AdminDailyConclusionDelete_Header}>
          <h6 className="page-header-title">{isDailyConclusion ? 'Удалить генератор' : 'Удалить правило'}</h6>
          <CloseIcon onClick={onClose} className={styles.AdminDailyConclusionDelete_CloseIcon} />
        </ContentCard.Header>
        <ContentCard.Main className={styles.AdminDailyConclusionDelete_Content}>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              submit();
            }}
          >
            <Grid container direction="column" justify="space-between" alignItems="stretch" spacing={2}>
              <Grid item xs={12}>
                <Box textAlign="center" mt={2} mb={2}>
                  {isDailyConclusion ? `Удалить генератор "${item.name}"?` : 'Удалить правило?'}
                </Box>
              </Grid>
              <Grid item xs={12} container justify="space-between" alignItems="center" spacing={1}>
                <Grid item xs={12} lg={6}>
                  <Button className="mr-2" color="default" block onClick={onClose}>
                    Отмена
                  </Button>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Button type="submit" color="primary" isLoading={loading} block>
                    Удалить
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </ContentCard.Main>
      </ContentCard>
    </Dialog>
  );
};
export default AdminDailyConclusionDelete;
