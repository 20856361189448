import React, { memo, ReactElement } from 'react';
import { Box } from '@material-ui/core';
import styles from './styles.module.sass';

const AgentMetricsListHeader = (): ReactElement => {
  return (
    <>
      <Box className={styles.root}>
        <Box className={styles.root_item}>Дата</Box>
        <Box className={styles.root_item}>Название тарифа</Box>
        <Box className={styles.root_item}>Кол-во проданных</Box>
        <Box className={styles.root_item}>Стоимость</Box>
        <Box className={styles.root_item}>Сумма</Box>
      </Box>
    </>
  );
};

export default AgentMetricsListHeader;
