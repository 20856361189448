import React from 'react';
import classNames from 'classnames';

import styles from './ContentCard.module.sass';

const ContentCardHeader = (props: any) => (
  <div className={classNames(styles.contentCard_header, {
    [props.className]: props.className
  })}>
    {props.children}
  </div>
);

export default ContentCardHeader;
