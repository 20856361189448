import React, { useEffect, useState } from 'react';
import SelectInput from '../Forms/SelectInput';
import { getCategoryBenefits } from '../../api';

interface PropsInterface {
  [key: string]: any;
}

const CategoryBenefitsSelect = (props: PropsInterface) => {
  const { isDisabled, onChange, errors, label, placeholder, block, value, name, ...other } = props;
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    getCategoryBenefits()
      .then((data) => {
        if (data?.data?.code === 'success') {
          setOptions(
            [{ label: 'Без льгот', value: null }].concat(
              (data?.data?.data?.items || []).map((d) => ({ label: d.name, value: d.id }))
            )
          );
        }
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, []);
  return (
    <SelectInput
      disabled={isDisabled}
      name={name}
      value={value ? options.find((v) => v?.value === value) : { label: 'Без льгот', value: null }}
      onChange={onChange}
      errors={errors}
      options={options}
      block={block}
      label={label}
      placeholder={loading ? 'Загрузка...' : placeholder}
      {...other}
    />
  );
};

export default CategoryBenefitsSelect;
