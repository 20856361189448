import React, { memo, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { AxiosResponse } from 'axios';
import useApiFx from 'hooks/useApiFx';
import useStoreApi from 'hooks/useStoreApi';
import ContentLoading from 'components/ContentLoading';
import PackageItem from 'components/PackageItem';
import Button from 'components/Forms/Button';
import { Box } from '@material-ui/core';
import { ResponseDataInterface } from '../../../../../types';
import { getAgentSp } from '../../api';
import { Fields } from '../../../../../constants/fields';
import useHasMore from '../../../../../hooks/useHasMore';

const Actions = memo(
  ({
    internalTariffId,
    handelSelect,
    selected = false,
  }: {
    internalTariffId: any;
    selected: boolean;
    handelSelect: (...args: any) => any;
  }) => (
    <>
      <Button
        type="button"
        size="md"
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          handelSelect(internalTariffId);
        }}
        block
        color={selected ? 'primary' : 'default'}
      >
        Выбрать
      </Button>
    </>
  )
);
interface PropsInterface {
  internalTariffId?: any;
  onSelect?: (args: any) => any;
}
const AgentSaleSp = memo((props: PropsInterface): ReactElement => {
  const { onSelect = () => {}, internalTariffId } = props;
  const [page, setPage] = useState(1);
  const [pageSize] = useState(100);
  const getAgentSpFx = useApiFx<void>(getAgentSp);
  const getData = useCallback(() => getAgentSpFx.call(getAgentSpFx), [getAgentSpFx]);
  useEffect(() => {
    getData();
  }, [getData]);
  const { loading, data, error } = useStoreApi<AxiosResponse<ResponseDataInterface<Array<any>>>>(getAgentSpFx);
  const items = useMemo(() => data?.data?.data || [], [data?.data?.data]);
  const targetItems = useMemo(() => items.slice(0, page * pageSize), [items, page, pageSize]);
  const hasMore = useHasMore(items.length, page, pageSize);
  const handelSelect = useCallback(
    (internalTariffId: any) => {
      onSelect(internalTariffId);
    },
    [onSelect]
  );
  const renderAction = useCallback(
    (item: any) => (
      <Actions
        internalTariffId={item?.[Fields.Id]}
        handelSelect={handelSelect}
        selected={internalTariffId === item?.[Fields.Id]}
      />
    ),
    [handelSelect, internalTariffId]
  );
  return (
    <ContentLoading isLoading={loading} isError={error} fetchData={getData}>
      {targetItems.map((item) => (
        <PackageItem item={item} all={false} key={item?.id} actions={renderAction(item)} />
      ))}
      {hasMore && (
        <Box className="d-flex justify-content-center align-items-center">
          <Button
            size="md"
            color="default"
            onClick={() => {
              setPage((page) => page + 1);
            }}
          >
            Показать еще
          </Button>
        </Box>
      )}
    </ContentLoading>
  );
});

export default AgentSaleSp;
