import React, { useCallback, useEffect, useMemo } from 'react';
import { Box } from '@material-ui/core';
import {
  selectJournalError,
  selectJournalLoading,
  selectJournalPage,
  selectJournalPageSize,
  selectJournalUserId,
  selectJournalTimeFrom,
  selectJournalTimeTo,
  selectJournalTotalItems,
  selectJournalType,
  selectJournalView,
} from 'views/JournalPage/store/selectors';
import { JournalType, JournalViewType } from 'views/JournalPage/types';
import { onGetJournal, onSetJournalUserId, onSetJournalType, onShowMore } from 'views/JournalPage/store/events';
import ContentLoading from 'components/ContentLoading';
import styles from './JournalPageWrapper.module.sass';
import useRootSelector from '../../../hooks/useRootSelector';
import JournalView from '../JournalView';
import JournalChart from '../JournalChart';
import JournalViewList from '../JournalViewList';
import Button from '../../../components/Forms/Button';
import JournalEdit from '../JournalEdit';
import useIsDoctorRole from '../../../hooks/useIsDoctorRole';

interface PropsInterface {
  type: JournalViewType | string;
}
const JournalPageWrapper = (props: PropsInterface): JSX.Element => {
  const { type } = props;
  const isDoctor = useIsDoctorRole();
  const userId = useRootSelector(selectJournalUserId);
  const journalType = useRootSelector(selectJournalType);
  const loading = useRootSelector(selectJournalLoading);
  const error = useRootSelector(selectJournalError);
  useEffect(() => {
    onSetJournalType(type);
  }, [type]);
  const view = useRootSelector(selectJournalView);
  const timeFrom = useRootSelector(selectJournalTimeFrom);
  const timeTo = useRootSelector(selectJournalTimeTo);
  const totalItems = useRootSelector(selectJournalTotalItems);
  const page = useRootSelector(selectJournalPage);
  const pageSize = useRootSelector(selectJournalPageSize);
  const showMore = useMemo(() => (page + 1) * pageSize < totalItems, [page, pageSize, totalItems]);
  const handleShowMore = useCallback(() => {
    onShowMore();
  }, []);
  const getData = useCallback(
    (force?: boolean) => {
      (force || (journalType && type === journalType && (!isDoctor || (isDoctor && userId)))) &&
        onGetJournal({
          page,
          pageSize,
          timeFrom,
          timeTo,
          types: journalType.toUpperCase(),
          userId,
        });
    },
    [journalType, type, isDoctor, userId, page, pageSize, timeFrom, timeTo]
  );
  useEffect(() => {
    getData();
  }, [getData]);
  return (
    <Box className={styles.wrapper}>
      <Box className={styles.root}>
        {view === JournalViewType.VIEW && (
          <>
            <JournalView />
            {journalType !== JournalType.Feeling && <JournalChart />}
            {(page > 0 || (!loading && !error)) && (
              <>
                <JournalViewList />
                {showMore && (
                  <Box className={styles.root_more}>
                    <Button
                      disabled={loading}
                      isLoading={loading}
                      size="sm"
                      type="submit"
                      onClick={handleShowMore}
                      color="primary"
                    >
                      Показать еще
                    </Button>
                  </Box>
                )}
              </>
            )}
            {(loading || error) && (
              <Box pb={5}>
                <ContentLoading isLoading={loading} isError={error} fetchData={() => getData()} />
              </Box>
            )}
          </>
        )}
        {view === JournalViewType.EDIT && <JournalEdit />}
      </Box>
    </Box>
  );
};

export default JournalPageWrapper;
