import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { patientPurchasedOfTariff } from 'api';
import { toast } from 'react-toastify';

import ContentCard from 'components/ContentCard';
import ContentLoading from 'components/ContentLoading';
import Button from 'components/Forms/Button';

import { ReactComponent as AngleLeftIcon } from 'assets/img/icons/angle-left-icon.svg';
import { useHistory, useLocation } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import styles from './PackageViewAll.module.sass';
import { getServicePackagesMyInfo } from '../../api/getServicePackagesMyInfo';

const PackageViewAll = () => {
  const [packageViewLoading, setPackageViewLoading] = useState(true);
  const [packageViewError, setPackageViewError] = useState(null);

  const location = useLocation();

  const history = useHistory();

  const [packageView, setPackageView] = useState(null);
  const id = useMemo(() => location?.pathname.replace('/packages/all/', ''), [location]);
  const init = useCallback(() => {
    if (!id) return;
    setPackageViewLoading(true);
    getServicePackagesMyInfo(id)
      .then((data) => {
        if (data.data?.code === 'success') {
          setPackageView(data.data?.data);
        } else {
          setPackageViewError(data);
          toast.error('Ошибка при запросе');
        }
        setPackageViewLoading(false);
      })
      .catch((error) => {
        setPackageViewLoading(false);
        setPackageViewError(error);
        toast.error('Ошибка при запросе');
      });
  }, [setPackageViewLoading, setPackageViewError, id]);

  useEffect(() => {
    init();
  }, []);

  const handleBuy = useCallback(() => {
    patientPurchasedOfTariff(packageView?.id)
      .then((data) => {
        if (data?.data?.data) {
          window.location.href = data?.data?.data;
        } else {
          toast.error('Ошибка при запросе');
        }
      })
      .catch(() => toast.error('Ошибка при запросе'));
  }, [packageView]);

  return (
    <ContentCard>
      <ContentCard.Header>
        <div className="row">
          <div className="col-xs-4 text-center text-xs-left mb-2 mb-xs-0">
            <div className="page-header-back-link" onClick={() => history.push('/packages')}>
              <AngleLeftIcon className="page-header-back-link-icon" />
              Назад
            </div>
          </div>
          <div className="col-xs-8 col-md-4 text-center text-xs-right text-md-center">
            <h6 className="page-header-title">Информация о тарифе</h6>
          </div>
        </div>
      </ContentCard.Header>
      <ContentCard.Main>
        <ContentLoading isLoading={packageViewLoading} isError={packageViewError} fetchData={() => init()}>
          {packageView && (
            <div className={styles.servicePackageView}>
              <div className={styles.servicePackageView_package}>
                <div className={styles.servicePackageView_package_title}>{packageView.name}</div>
                <div className={styles.servicePackageView_package_row}>
                  <h6 className={styles.servicePackageView_package_row_title}>Описание</h6>
                  <div>{packageView?.description || null}</div>
                </div>
                <div className={styles.servicePackageView_package_row}>
                  <h6 className={styles.servicePackageView_package_row_key}>Длительность</h6>
                  <span className={styles.servicePackageView_package_row_value}>
                    {packageView?.expirationDays || 0} дней
                  </span>
                </div>
                <div className={styles.servicePackageView_package_row}>
                  <h6 className={styles.servicePackageView_package_row_key}>Стоимость</h6>
                  <span className={styles.servicePackageView_package_row_value}>{packageView?.price} &#8381;</span>
                </div>
              </div>
              <Button color="primary" block className={styles.servicePackageView_btn} onClick={handleBuy}>
                Купить
              </Button>
            </div>
          )}
        </ContentLoading>
      </ContentCard.Main>
    </ContentCard>
  );
};

export default PackageViewAll;
