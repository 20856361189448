import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import classNames from 'classnames';
import { connect } from 'react-redux';
import styles from './SettingsPageAdminCategories.module.sass';
import Button from '../../../../components/Forms/Button';
import { fetchManageCategories } from '../../../../store/actions';
import Manage from '../../../../types/manage';
import ContentLoading from '../../../../components/ContentLoading';
import AdminCategoryView from '../../../../components/AdminCategoryView';
import { ManageCategoriesReducerInitialSettings } from '../../../../store/reducers/manageCategoriesReducer.reducer';

interface PropsInterface {
  loading: boolean;
  categories: Manage.Category.Item[];
  fetchManageCategories: (active?: boolean) => void;
  errors: any;
}
const SettingsPageAdminCategories = (props: PropsInterface) => {
  const { loading, categories, fetchManageCategories, errors } = props;
  const [active, setActive] = useState<boolean>(null);
  const [category, setCategory] = useState<Manage.Category.Item>(null);
  useEffect(() => {
    getCats();
  }, [active]);
  const getCats = (): void => {
    fetchManageCategories(active);
  };
  return (
    <>
      <Box className={classNames(styles.SettingsPageAdminCategories)}>
        <Box
          className={classNames(styles.SettingsPageAdminCategories_Section, styles.SettingsPageAdminCategories_Toolbar)}
        >
          <Button
            className="mr-4"
            size="sm"
            color={active === null ? 'primary' : 'default'}
            onClick={() => {
              active !== null && setActive(null);
            }}
          >
            Все
          </Button>
          <Button
            className="mr-4"
            size="sm"
            color={active === true ? 'primary' : 'default'}
            onClick={() => {
              active !== true && setActive(true);
            }}
          >
            Активные
          </Button>
          <Button
            size="sm"
            color={active === false ? 'primary' : 'default'}
            onClick={() => {
              active !== false && setActive(false);
            }}
          >
            В архиве
          </Button>
        </Box>
        <Box
          className={classNames(styles.SettingsPageAdminCategories_Section, styles.SettingsPageAdminCategories_Content)}
        >
          <ContentLoading isLoading={loading} isError={errors} fetchData={() => getCats()}>
            {categories.length === 0 && <Box>У вас пока нет созданных категории</Box>}
            {categories.length > 0 && (
              <Box className={classNames(styles.SettingsPageAdminCategories_Cats)}>
                {categories.map((item: Manage.Category.Item, index: number) => (
                  <Box className={classNames(styles.SettingsPageAdminCategories_Cats_Cat)} key={index}>
                    <Box className={classNames(styles.SettingsPageAdminCategories_Cats_Cat_Data)}>
                      <Box className={classNames(styles.SettingsPageAdminCategories_Cats_Cat_Data_Name)}>
                        {item.name}
                      </Box>
                      <Box
                        className={classNames(
                          styles.SettingsPageAdminCategories_Cats_Cat_Data_Status,
                          item.active === true && styles.SettingsPageAdminCategories_Cats_Cat_Data_Status_Active
                        )}
                      >
                        {item.active === true ? 'Активная' : 'В архиве'}
                      </Box>
                    </Box>
                    <Box className={classNames(styles.SettingsPageAdminCategories_Cats_Cat_Actions)}>
                      <Button size="sm" color="default" onClick={() => setCategory(item)}>
                        Редактировать
                      </Button>
                    </Box>
                  </Box>
                ))}
              </Box>
            )}
          </ContentLoading>
        </Box>
        <Box
          className={classNames(styles.SettingsPageAdminCategories_Section, styles.SettingsPageAdminCategories_Action)}
        >
          <Button size="md" color="primary" onClick={() => setCategory({})}>
            Создать новую категорию
          </Button>
        </Box>
      </Box>
      {category && (
        <AdminCategoryView
          category={category}
          onSave={() => {
            getCats();
            setCategory(null);
          }}
          onClose={() => setCategory(null)}
        />
      )}
    </>
  );
};
export default connect(
  (state: any) => ({
    ...(state.manageCategories as ManageCategoriesReducerInitialSettings),
  }),
  {
    fetchManageCategories,
  }
)(SettingsPageAdminCategories);
