export enum AppointmentDoctorViewType {
  CHAT = 'CHAT',
  REPORT = 'REPORT',
}
export const AppointmentDoctorViewTypeTranslate = {
  [AppointmentDoctorViewType.CHAT]: 'Чат',
  [AppointmentDoctorViewType.REPORT]: 'Карта консультации',
};

export enum AppointmentStatus {
  AWAITS = 'AWAITS',
  DONE = 'DONE',
  CANCELED = 'CANCELED',
  IN_PROGRESS = 'IN_PROGRESS',
  FAILED = 'FAILED',
  FINISHED = 'FINISHED',
  WAITING_FILLING = 'WAITING_FILLING',
  WAITING_SENDING = 'WAITING_SENDING',
}
export const AppointmentStatusTranslate = {
  [AppointmentStatus.AWAITS]: 'Ожидает начала',
  [AppointmentStatus.DONE]: 'Завершена',
  [AppointmentStatus.CANCELED]: 'Отменена',
  [AppointmentStatus.IN_PROGRESS]: 'Идет консультация',
  [AppointmentStatus.FAILED]: 'Технические проблемы',
  [AppointmentStatus.FINISHED]: 'Закончена',
};
export enum EIMKKey {
  IdCaseAidType = 'idCaseAidType',
  IdPaymentType = 'IdPaymentType',
  Confidentiality = 'confidentiality',
  IdCaseResult = 'IdCaseResult',
  IdSpeciality = 'IdSpeciality',
  DoctorIdPosition = 'doctorIdPosition',
  AdmissionCondition = 'admissionCondition',
  CaseVisitType = 'caseVisitType',
  IdVisitPlace = 'IdVisitPlace',
  IdVisitPurpose = 'IdVisitPurpose',
  IdDiseaseType = 'IdDiseaseType',
  IdDiagnosisType = 'IdDiagnosisType',
  DiagnosisChangeReason = 'diagnosisChangeReason',
  IdDispensaryState = 'IdDispensaryState',
  IdTraumaType = 'IdTraumaType',
  MedicalStandard = 'medicalStandard',
  mkbCode = 'mkbCode',
}

export const EIMKKeyTranslate = {
  [EIMKKey.IdCaseAidType]: 'Вид медицинского обслуживания',
  [EIMKKey.IdPaymentType]: 'Источники финансирования',
  [EIMKKey.Confidentiality]: 'Уровни конфиденциальности',
  [EIMKKey.IdCaseResult]: 'Исход заболевания',
  [EIMKKey.IdSpeciality]:
    'Номенклатура специальностей специалистов, имеющих медицинское и фармацевтическое образование',
  [EIMKKey.DoctorIdPosition]: 'Должности медицинских и фармацевтических работников',
  [EIMKKey.AdmissionCondition]: 'Степень тяжести состояния пациента',
  [EIMKKey.CaseVisitType]: 'Вид случая госпитализации или обращения (первичный, повторный)',
  [EIMKKey.IdVisitPlace]: 'Классификатор мест обслуживания',
  [EIMKKey.IdVisitPurpose]: 'Классификатор целей посещения амбулаторно-поликлинической организации',
  [EIMKKey.IdDiseaseType]: 'Классификатор характера заболевания',
  [EIMKKey.IdDiagnosisType]: 'Статус диагноза',
  [EIMKKey.DiagnosisChangeReason]: 'Причина изменения диагноза',
  [EIMKKey.IdDispensaryState]: 'Классификатор состояния диспансерного учета по диагнозу (заболеванию)',
  [EIMKKey.IdTraumaType]: 'Справочник "Классификатор травм по способу получения"',
  [EIMKKey.MedicalStandard]: 'Стандарты учета оказания медицинской помощи',
  [EIMKKey.mkbCode]: 'МКБ10 ред.СПб КЗ',
};

export const EIMKDictionary = {
  [EIMKKey.IdCaseAidType]: '1.2.643.2.69.1.1.1.16',
  [EIMKKey.IdPaymentType]: '1.2.643.2.69.1.1.1.32',
  [EIMKKey.Confidentiality]: '1.2.643.5.1.13.2.7.1.9',
  [EIMKKey.IdCaseResult]: '1.2.643.5.1.13.2.1.1.688',
  [EIMKKey.IdSpeciality]: '1.2.643.5.1.13.13.11.1066',
  [EIMKKey.DoctorIdPosition]: '1.2.643.5.1.13.13.11.1002',
  [EIMKKey.AdmissionCondition]: '1.2.643.5.1.13.2.1.1.111',
  [EIMKKey.CaseVisitType]: '1.2.643.5.1.13.13.11.1007',
  [EIMKKey.IdVisitPlace]: '1.2.643.2.69.1.1.1.18',
  [EIMKKey.IdVisitPurpose]: '1.2.643.2.69.1.1.1.19',
  [EIMKKey.IdDiseaseType]: '1.2.643.2.69.1.1.1.8',
  [EIMKKey.IdDiagnosisType]: '1.2.643.2.69.1.1.1.26',
  [EIMKKey.DiagnosisChangeReason]: '1.2.643.2.69.1.1.1.9',
  [EIMKKey.IdDispensaryState]: '1.2.643.2.69.1.1.1.11',
  [EIMKKey.IdTraumaType]: '1.2.643.2.69.1.1.1.12',
  [EIMKKey.MedicalStandard]: '1.2.643.2.69.1.1.1.29',
  [EIMKKey.mkbCode]: '1.2.643.2.69.1.1.1.2',
};
