import axios from 'utils/axios';
import {AxiosResponse} from "axios";
import {ResponseData} from "../types";
import {EnumDictionary} from "../types/EnumDictionary";

export class dictionaryApi {

  static getAdditionalServiceTypes = (): Promise<AxiosResponse<ResponseData<{items: Array<EnumDictionary<string>>}>>> => {
    return axios.get('/api/v1/additionalservicetypes')
  }

}
