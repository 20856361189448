export enum Fields {
  Code = 'code',
  Id = 'id',
  Phone = 'phone',
  InternalTariffId = 'internalTariffId',
  ConfirmCode = 'confirmCode',
  FirstName = 'firstName',
  LastName = 'lastName',
  MiddleName = 'middleName',
  Email = 'email',
  Sex = 'sex',
  Birthday = 'birthday',
}
