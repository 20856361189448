import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { UserRole } from 'types';
import { Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import styles from './SidebarPersonalDoctor.module.sass';
import HasRole from '../HasRole';
import { onGetProfilePersonalsInteractionsMeta } from '../../store/personal/events';
import useRootSelector from '../../hooks/useRootSelector';
import Spinner from '../Spinner';
import { decodeAvatarResource, toDisplayName } from '../../utils';

interface PropsInterface {
  onlyMobile?: boolean;
}
const SidebarPersonalDoctor = (props: PropsInterface): JSX.Element => {
  const { onlyMobile = false } = props;
  const loading = useRootSelector<boolean>((state) => state.personal.loading);
  const doctor = useRootSelector<any | null>((state) => state.personal.doctor);
  const role = useSelector<any, UserRole>((state) => state?.auth?.role as UserRole);
  const showBlock = useMemo(() => [UserRole.DOCTOR, UserRole.PATIENT].indexOf(role) > -1, [role]);
  useEffect(() => {
    onGetProfilePersonalsInteractionsMeta();
  }, []);

  const avatar = useMemo(() => doctor?.avatar, [doctor]);

  const avatarBackground = useMemo(() => ({ backgroundImage: `url(${decodeAvatarResource(avatar, 360)})` }), [avatar]);
  if (!showBlock) {
    return <></>;
  }
  if (role === UserRole.PATIENT && loading) {
    return (
      <div className={classNames(styles.root, onlyMobile && styles.root_mobile)}>
        <Spinner className={styles.loadingSpinner} width={26} height={26} color="#1473ff" />
      </div>
    );
  }
  return (
    <Box
      component={(props) => (loading ? <Box {...props} /> : <Link to="/personal-appointments" {...props} />)}
      className={classNames(styles.root, onlyMobile && styles.root_mobile)}
    >
      <div className={styles.root_icon}>
        {doctor && <div className={styles.root_icon_avatar} style={avatarBackground} />}
        {!doctor && (
          <Box
            component={() => (
              <svg width="78" height="73" viewBox="0 0 78 73" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M52.2561 39.4146C52.2561 38.0811 53.3372 37 54.6707 37H64.3293C65.6628 37 66.7439 38.0811 66.7439 39.4146V67.5854C66.7439 68.9189 65.6628 70 64.3293 70H54.6707C53.3372 70 52.2561 68.9189 52.2561 67.5854V39.4146Z"
                  fill="#D9F4FF"
                />
                <path
                  d="M73.5854 46.2561C74.9189 46.2561 76 47.3372 76 48.6707V58.3293C76 59.6628 74.9189 60.7439 73.5854 60.7439H45.4146C44.0811 60.7439 43 59.6628 43 58.3293V48.6707C43 47.3372 44.0811 46.2561 45.4146 46.2561H73.5854Z"
                  fill="#D9F4FF"
                />
                <path
                  d="M43.9197 35.7972C43.3665 35.5329 42.8003 35.2965 42.2233 35.089C44.3796 33.6621 46.0183 31.5795 46.8968 29.1497C47.7753 26.7198 47.8469 24.072 47.1008 21.5984C46.3548 19.1248 44.8308 16.957 42.7547 15.416C40.6787 13.8751 38.1608 13.043 35.5742 13.043C32.9876 13.043 30.4698 13.8751 28.3937 15.416C26.3176 16.957 24.7937 19.1248 24.0476 21.5984C23.3016 24.072 23.3731 26.7198 24.2516 29.1497C25.1301 31.5795 26.7689 33.6621 28.9251 35.089C25.2243 36.4473 22.0296 38.9064 19.7717 42.1346C17.5138 45.3629 16.3013 49.205 16.2979 53.1429H19.5106C19.5135 48.8902 21.2062 44.8125 24.2171 41.8053C27.228 38.7982 31.3108 37.1076 35.5688 37.1047C37.9838 37.0927 40.3698 37.6312 42.5451 38.6791L43.9197 35.7972ZM26.7269 25.0597C26.7259 23.3139 27.2432 21.6071 28.2135 20.1551C29.1838 18.703 30.5635 17.571 32.178 16.9022C33.7925 16.2334 35.5694 16.0579 37.2839 16.3978C38.9983 16.7377 40.5733 17.5778 41.8096 18.8118C43.046 20.0459 43.8881 21.6184 44.2295 23.3305C44.5709 25.0426 44.3962 26.8173 43.7276 28.4303C43.0589 30.0432 41.9263 31.4219 40.4731 32.3919C39.0198 33.3619 37.3112 33.8796 35.5633 33.8796C33.2211 33.8782 30.9752 32.9486 29.3186 31.295C27.6619 29.6415 26.7298 27.3989 26.7269 25.0597Z"
                  fill="#458FF6"
                />
                <path
                  d="M50.4494 36.5C48.7834 36.5054 47.1564 37.0035 45.7736 37.9315C44.3908 38.8595 43.3142 40.1758 42.6796 41.7143C42.0451 43.2528 41.881 44.9445 42.2081 46.576C42.5352 48.2075 43.3389 49.7057 44.5177 50.8815C45.6965 52.0573 47.1975 52.858 48.8315 53.1826C50.4655 53.5072 52.1591 53.3412 53.6987 52.7055C55.2382 52.0697 56.5548 50.9927 57.4821 49.6105C58.4095 48.2282 58.9061 46.6025 58.9094 44.9386C58.9108 43.8291 58.6929 42.7303 58.2681 41.7051C57.8433 40.6799 57.2201 39.7486 56.4341 38.9646C55.648 38.1805 54.7148 37.5592 53.6878 37.1363C52.6608 36.7134 51.5603 36.4972 50.4494 36.5ZM50.4494 50.9311C49.2627 50.9311 48.1027 50.5797 47.116 49.9212C46.1293 49.2628 45.3602 48.3268 44.9061 47.2319C44.452 46.1369 44.3332 44.932 44.5647 43.7695C44.7962 42.6071 45.3676 41.5393 46.2067 40.7012C47.0459 39.8632 48.115 39.2924 49.2788 39.0612C50.4427 38.83 51.6491 38.9487 52.7455 39.4022C53.8418 39.8558 54.7789 40.6239 55.4382 41.6093C56.0975 42.5948 56.4494 43.7534 56.4494 44.9386C56.4523 45.7274 56.2992 46.509 55.9989 47.2385C55.6987 47.9681 55.2572 48.6313 54.6998 49.1901C54.1423 49.7488 53.4799 50.1922 52.7505 50.4947C52.0212 50.7972 51.2392 50.9529 50.4494 50.9529V50.9311Z"
                  fill="#458FF6"
                />
                <path
                  d="M51.6546 40.8636H49.2437V43.7401H46.3691V46.1425H49.2437V49.0189H51.6546V46.1425H54.5346V43.7401H51.6546V40.8636Z"
                  fill="#458FF6"
                />
              </svg>
            )}
          />
        )}
      </div>
      <div className={styles.root_about}>
        <div className={styles.root_about_title}>Личный врач</div>
        <div className={styles.root_about_title_sub}>
          <HasRole target={UserRole.PATIENT}>
            {doctor
              ? `${toDisplayName([doctor.lastName, doctor.firstName, doctor.middleName], true)}`
              : `У вас пока нет личного врача`}
          </HasRole>
        </div>
      </div>
    </Box>
  );
};

export default SidebarPersonalDoctor;
