import { StateInterface } from 'store';
import { CheckerStateInterface } from './index';
import { CheckerType } from '../types';

export const selectChecker = (state: StateInterface): CheckerStateInterface => state?.checker;

export const selectCheckerStep = (state: StateInterface) => selectChecker(state)?.step;
export const selectCheckerNextStep = (state: StateInterface) => {
  const step = selectCheckerStep(state);
  switch (step) {
    case CheckerType.PROBLEM_AREA:
      return CheckerType.MAIN_SYMPTOM;
    case CheckerType.MAIN_SYMPTOM:
      return CheckerType.SIDE_SYMPTOM;
    case CheckerType.SIDE_SYMPTOM:
      return CheckerType.SYMPTOM_CHARACTERISTIC;
    default:
      return null;
  }
};
export const selectCheckerStepValue = (state: StateInterface) => {
  const checker = selectCheckerChecker(state);
  const step = selectCheckerStep(state);
  return checker.filter(({ type }) => type === step) || [];
};

export const selectCheckerStepIsValid = (state: StateInterface) => {
  const checker = selectCheckerChecker(state);
  const step = selectCheckerStep(state);
  return (
    [CheckerType.SYMPTOM_CHARACTERISTIC, CheckerType.SIDE_SYMPTOM].indexOf(step) > -1 ||
    !!checker.find(({ type }) => type === step)
  );
};

export const selectCheckerStepIsLast = (state: StateInterface) => {
  const step = selectCheckerStep(state);
  return [CheckerType.SYMPTOM_CHARACTERISTIC].indexOf(step) > -1;
};

export const selectCheckerStepTotalValue = (state: StateInterface) => {
  const step = selectCheckerStep(state);
  const success = selectCheckerSuccess(state);
  if (success) {
    return 100;
  }
  switch (step) {
    case CheckerType.MAIN_SYMPTOM:
      return 25;
    case CheckerType.SIDE_SYMPTOM:
      return 50;
    case CheckerType.SYMPTOM_CHARACTERISTIC:
      return 75;
    default:
      return 0;
  }
};

export const selectCheckerBackStep = (state: StateInterface) => {
  const step = selectCheckerStep(state);
  switch (step) {
    case CheckerType.MAIN_SYMPTOM:
      return CheckerType.PROBLEM_AREA;
    case CheckerType.SIDE_SYMPTOM:
      return CheckerType.MAIN_SYMPTOM;
    case CheckerType.SYMPTOM_CHARACTERISTIC:
      return CheckerType.SIDE_SYMPTOM;
    default:
      return null;
  }
};
export const selectCheckerLoading = (state: StateInterface) => selectChecker(state)?.loading;
export const selectCheckerLoadingOrder = (state: StateInterface) => selectChecker(state)?.loadingOrder;
export const selectCheckerError = (state: StateInterface) => selectChecker(state)?.error;
export const selectCheckerErrorOrder = (state: StateInterface) => selectChecker(state)?.errorOrder;
export const selectCheckerSuccess = (state: StateInterface) => selectChecker(state)?.success;
export const selectCheckerChecker = (state: StateInterface) => selectChecker(state)?.checker;
export const selectCheckerDictionary = (state: StateInterface) => selectChecker(state)?.dictionaries;
export const selectCheckerParentId = (state: StateInterface): number => {
  const step = selectCheckerStep(state);
  const checker = selectCheckerChecker(state);
  return step === CheckerType.PROBLEM_AREA
    ? 0
    : checker.find(
        ({ type }) => type === (step === CheckerType.MAIN_SYMPTOM ? CheckerType.PROBLEM_AREA : CheckerType.MAIN_SYMPTOM)
      )?.id || 0;
};

export const selectCheckerDictionaryByParentIdAndStep = (state: StateInterface): Array<any> => {
  const parentId = selectCheckerParentId(state);
  const step = selectCheckerStep(state);
  const dictionaries = selectCheckerDictionary(state);
  const key = `${parentId}_${step}`;
  return Array.isArray(dictionaries?.[key]) ? dictionaries?.[key] : null;
};
