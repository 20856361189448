import axios from 'utils/axios';

export const findPatientServicePackageTransactionsReport = (
  statuses?: string[],
  types?: string[],
  createdFrom?: Date,
  createdTo?: Date,
  page?: number,
  pageSize?: number
) => {
  page = page || 0;
  pageSize = pageSize || 10;
  statuses = statuses || [];
  types = types || []
  return axios.get(`/api/v1/admin/reports/patientservicepackagetransaction`, {
    params: {
      statuses: statuses.length ? statuses.join(',') : null,
      types: types.length ? types.join(',') : null,
      createdFrom,
      createdTo,
      page,
      pageSize,
    },
  });
};
