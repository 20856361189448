import { getAppointments } from 'api';
import { AppointmentDisplayType } from 'types';

export const APPOINTMENTS_LOADING = 'APPOINTMENTS_LOADING';
export const APPOINTMENTS_ERROR = 'APPOINTMENTS_ERROR';
export const APPOINTMENTS_SUCCESS = 'APPOINTMENTS_SUCCESS';

export const appointmentsLoading = (isLoading: boolean) => ({ type: APPOINTMENTS_LOADING, isLoading });

export const appointmentsSuccess = (result: any, reset?: boolean) => {
  let appointments = [];

  if (result && result.data && result.data.items) {
    appointments = [...result.data.items];
  }

  return { type: APPOINTMENTS_SUCCESS, appointments, reset };
};

export const appointmentsError = (isError: boolean) => ({ type: APPOINTMENTS_ERROR, isError });

export const fetchAppointments = (status: AppointmentDisplayType, page: number, pageSize: string, reset?: boolean) => {
  return (dispatch) => {
    dispatch(appointmentsLoading(true));
    dispatch(appointmentsError(false));

    getAppointments(status, '', page, pageSize)
      .then((response) => {
        dispatch(appointmentsLoading(false));
        dispatch(appointmentsSuccess(response.data, reset));
      })
      .catch(() => {
        dispatch(appointmentsLoading(false));
        dispatch(appointmentsError(true));
      });
  };
};
