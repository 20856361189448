import Manage from "../../../types/manage";
import {AxiosResponse} from "axios";
import managePartnersApi from "../../../api/partner/managePartners";
import {ResponseData} from "../../../types";

export const AGENTS_LOADING = 'AGENTS_LOADING'
export const AGENTS_SUCCESS = 'AGENTS_SUCCESS'
export const AGENTS_ERROR = 'AGENTS_ERROR'

const setLoading = (loading: boolean, reset?: boolean) => ({
  type: AGENTS_LOADING,
  loading,
  reset
})

const setError = (errors: any) => ({type: AGENTS_ERROR, errors})

const setAgents = (response: Manage.Agent.AgentsResponse) => ({
  type: AGENTS_SUCCESS,
  items: response.items,
  page: response.page,
  pageSize: response.pageSize,
  totalItems: response.totalItems,
});

export const fetchAgents = (partnerId: string,
                            page?: number,
                            pageSize?: number,
                            reset?: boolean,): any => {
  return (dispatch) => {
    dispatch(setLoading(true, reset))
    managePartnersApi.getAgents(partnerId,{
      page: page,
      pageSize
    }).then((response: AxiosResponse<ResponseData<Manage.Agent.AgentsResponse>>) => {
      dispatch(setLoading(false));
      if (response.data.code === 'success') {
        const data = response.data.data
        dispatch(setAgents(data));
      } else {
        dispatch(setError(response.data));
      }
    })
      .catch((error: any) => {
        dispatch(setError(error));
        dispatch(setLoading(false));
      });
  }
}
