import { useEffect, useState } from 'react';
import { Effect } from 'effector';
import { create } from 'lodash';
import { de } from 'date-fns/locale';
import { Maybe } from '../types';

interface StoreInterface<D = any, E = Error> {
  loading?: boolean;
  data?: Maybe<D>;
  error?: Maybe<E>;
}
const defaultState = {
  loading: false,
  data: null,
  error: null,
};
const useStoreApi = <D = any, E = any>(
  effect: Effect<any, D, E>,
  _default?: StoreInterface<D, E>
): StoreInterface<D, E> => {
  const [store, setStore] = useState({ ...defaultState, ..._default });
  useEffect(() => {
    effect.pending.watch((loading: boolean) => {
      setStore((_) => ({ ..._, loading, error: loading === true ? null : _.error || null }));
    });
    effect.done.watch(({ result: data }: { result: any }) => {
      setStore((_) => ({ ..._, data }));
    });
    effect.failData.watch((error) => {
      setStore((_) => ({ ..._, error }));
    });
  }, [effect]);
  return store;
};
export default useStoreApi;
