import axios from 'utils/axios';

export const findReportPatient = (
  query?: string,
  external?: boolean,
  regFrom?: Date,
  regTo?: Date,
  page?: number,
  pageSize?: number
) => {
  page = page || 0;
  pageSize = pageSize || 10;
  query = query || null;
  external = external === true ? true : external === false ? false : null;
  return axios.get(`/api/v1/admin/reports/patient`, {
    params: {
      external,
      regFrom,
      regTo,
      query,
      page,
      pageSize,
    },
  });
};
