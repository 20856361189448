import axios from 'utils/axios';

type appointmentType = {
  doctorId: string;
  startTime: string;
  patientServicePackageId: string;
  additionalServiceType?: string
};

export const payAppointmentsPlanned = (appointment: appointmentType) => {
  return axios.post('/api/v1/appointments', {
    ...appointment,
  });
};
