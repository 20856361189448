import axios from 'utils/axios';

export const getServicePackagesMySuitable = (
  serviceType: 'PLANNED' | 'IMMEDIATE',
  specializationId: string,
  startTime: string,
  additionalServiceType?: string
) => {
  return axios.get('/api/v1/servicepackages/my/suitable', {
    params: {
      serviceType,
      specializationId,
      startTime,
      additionalServiceType
    }
  });
};
