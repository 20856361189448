import React, { useCallback, useEffect, useState } from 'react';
import { Backdrop, Box, Dialog } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import { AxiosResponse } from 'axios';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { Lightbox } from 'react-modal-image';
import { formatDistanceStrict, parseISO } from 'date-fns';
import { ru } from 'date-fns/locale';
import styles from './AppointmentDoctorView.module.sass';
import ContentCard from '../ContentCard';
import Appointment from '../../types/appointment';
import ContentLoading from '../ContentLoading';
import { getAppointment } from '../../api';
import { decodeAvatarResource, getDisplayName } from '../../utils';
import Button from '../Forms/Button';

interface AppointmentDoctorViewInterface {
  appointmentId: string;
  withAction?: boolean;
  onClose: () => void;
  history?: any;
}

const AppointmentDoctorView = (props: AppointmentDoctorViewInterface) => {
  const { onClose = () => {}, appointmentId, withAction = false, history } = props;
  const [appointment, setAppointment] = useState<Appointment.Data>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [errors, setErrors] = useState<any>(null);
  const [attachPreview, setAttachPreview] = useState<Appointment.AttachmentsIn>(null);
  const getAppointmentById = useCallback(() => {
    setLoading(true);
    getAppointment(appointmentId)
      .then((response: AxiosResponse<Appointment.Response>) => {
        if (response.data.code === 'success') {
          setAppointment(response.data.data);
        } else {
          setErrors(response.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setErrors(err);
      });
  }, [appointmentId]);
  useEffect(() => {
    getAppointmentById();
  }, [getAppointmentById]);
  return (
    <Dialog
      PaperProps={{ className: classNames(styles.Dialog) }}
      BackdropComponent={(props) => <Backdrop className={styles.Backdrop} {...props} />}
      onClose={onClose}
      open
    >
      <ContentCard className={classNames(['Modal', styles.AppointmentDoctorView])}>
        <ContentCard.Header className={classNames(['Modal-header'])}>
          <h6 className="page-header-title">Карточка консультации</h6>
          <CloseIcon onClick={onClose} className={classNames(['Modal-close'])} />
        </ContentCard.Header>
        <ContentCard.Main className={classNames(['Modal-content', styles.AppointmentDoctorView_Content])}>
          <ContentLoading isLoading={loading} isError={errors} fetchData={() => getAppointmentById()}>
            <Box className={classNames(styles.AppointmentDoctorView_Content_Item)}>
              {appointment && appointment.patient && (
                <Box className={classNames(styles.AppointmentDoctorView_Content_Patient)}>
                  <Box
                    className={classNames(styles.AppointmentDoctorView_Content_Patient_Avatar)}
                    style={{ backgroundImage: `url(${decodeAvatarResource(appointment.patient.avatar, 360, null)})` }}
                  />
                  <Box className={classNames(styles.AppointmentDoctorView_Content_Patient_Profile)}>
                    <Box className={classNames(styles.AppointmentDoctorView_Content_Patient_Profile_Fio)}>
                      {getDisplayName(appointment.patient.firstName, appointment.patient.lastName)}
                    </Box>
                    <Box className={classNames(styles.AppointmentDoctorView_Content_Patient_Profile_Age)}>
                      {appointment.patient.birthday &&
                        formatDistanceStrict(parseISO(appointment.patient.birthday.toString()), new Date(), {
                          locale: ru,
                          addSuffix: false,
                        })}
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
            <Box className={classNames(styles.AppointmentDoctorView_Content_Item)}>
              <Box component="h6" className={classNames(styles.AppointmentDoctorView_Content_Item_Header)}>
                Тип консультации
              </Box>
              <Box className={classNames(styles.AppointmentDoctorView_Content_Box)}>
                {appointment?.type?.name || ' '}
              </Box>
            </Box>
            <Box className={classNames(styles.AppointmentDoctorView_Content_Item)}>
              <Box component="h6" className={classNames(styles.AppointmentDoctorView_Content_Item_Header)}>
                Жалоба
              </Box>
              <Box className={classNames(styles.AppointmentDoctorView_Content_Box)}>
                {appointment && appointment.subject ? appointment.subject : ' '}
              </Box>
            </Box>
            <Box className={classNames(styles.AppointmentDoctorView_Content_Item)}>
              <Box component="h6" className={classNames(styles.AppointmentDoctorView_Content_Item_Header)}>
                Вложенные файлы
              </Box>
              {appointment && (
                <Box className={classNames(styles.AppointmentDoctorView_Content_Attachments)}>
                  {[
                    ...(appointment.attachmentsIn || []),
                    ...(appointment.attachmentsOut || []),
                    ...(appointment.conclusions || []),
                  ].map((attach: Appointment.AttachmentsIn, index: number) => (
                    <Box
                      key={index}
                      onClick={() =>
                        !attach.contentType || attach.contentType.search('image') === -1
                          ? window.open(attach.originalUrl, '_blank').focus()
                          : setAttachPreview(attach)
                      }
                      className={classNames(styles.AppointmentDoctorView_Content_Attachments_Attachment)}
                      style={
                        !attach.contentType || attach.contentType.search('image') === -1
                          ? {}
                          : { backgroundImage: `url(${attach.originalUrl})` }
                      }
                    >
                      {!attach.contentType ||
                        (attach.contentType.search('image') === -1 && (
                          <PictureAsPdfIcon
                            className={styles.AppointmentDoctorView_Content_Attachments_Attachment_Icon}
                          />
                        ))}
                    </Box>
                  ))}
                  {attachPreview && (
                    <Lightbox
                      medium={attachPreview.originalUrl}
                      large={attachPreview.originalUrl}
                      onClose={() => setAttachPreview(null)}
                    />
                  )}
                </Box>
              )}
            </Box>
            <Box className={classNames(styles.AppointmentDoctorView_Content_Item)}>
              <Box component="h6" className={classNames(styles.AppointmentDoctorView_Content_Item_Header)}>
                Рекомендации
              </Box>
              <Box className={classNames(styles.AppointmentDoctorView_Content_Box)}>
                {appointment && appointment.recommendations ? appointment.recommendations : ' '}
              </Box>
              <Box component="h6" className={classNames(styles.AppointmentDoctorView_Content_Item_Header)}>
                Анамнез
              </Box>
              <Box className={classNames(styles.AppointmentDoctorView_Content_Box)}>
                {appointment && appointment.anamnesis ? appointment.anamnesis : ' '}
              </Box>
            </Box>
            {withAction && (
              <Box className={classNames(styles.AppointmentDoctorView_Content_Item)}>
                <Box display="flex" width="100%" justifyContent="center">
                  <Button
                    color="primary"
                    onClick={() =>
                      history &&
                      history.push(
                        `/appointments/${appointment.id}${appointment?.type?.value === 'PERSONAL' ? '/chat' : ''}`
                      )
                    }
                  >
                    Перейти к консультации
                  </Button>
                </Box>
              </Box>
            )}
          </ContentLoading>
        </ContentCard.Main>
      </ContentCard>
    </Dialog>
  );
};
export default AppointmentDoctorView;
