import { createStore, forward } from 'effector';

import { onDeleteStories, onGetStories, onSortStoriesItems, onToggleActiveItemStories } from './events';
import { originDeleteStoriesFx, originGetStoriesFx, originSortStoriesFx, originToggleActiveStoriesFx } from './effects';

export interface StoriesStateInterface {
  items?: Array<any>;
  item?: any;
  model?: any;
  loading?: boolean;
  error?: any;
}
const defaultState: StoriesStateInterface = {
  items: [],
  item: null,
  model: null,
  loading: true,
  error: null,
};

const $store = createStore(defaultState);

forward({ from: onGetStories, to: originGetStoriesFx });
forward({ from: onDeleteStories, to: originDeleteStoriesFx });
forward({ from: onSortStoriesItems, to: originSortStoriesFx });
forward({ from: onToggleActiveItemStories, to: originToggleActiveStoriesFx });
$store.on(originGetStoriesFx.fail, (_, error) => ({ ..._, error }));
$store.on(originGetStoriesFx.pending, (_, loading) => ({ ..._, loading, error: null }));
$store.on(originGetStoriesFx.doneData, (_, data) => ({
  ..._,
  items: data,
}));
$store.on(onSortStoriesItems, (_, data) => ({
  ..._,
  items: data,
}));
$store.on(originSortStoriesFx.fail, (_, error) => ({ ..._, error }));
$store.on(originSortStoriesFx.pending, (_, loading) => ({ ..._, loading, error: null }));
$store.on(originToggleActiveStoriesFx.fail, (_, error) => ({ ..._, error }));
$store.on(originToggleActiveStoriesFx.pending, (_, loading) => ({ ..._, loading, error: null }));
$store.on(originToggleActiveStoriesFx.doneData, (_, data) => ({
  ..._,
  items: (_?.items || []).map((item) => (item?.id === data?.id ? data : item)),
}));
$store.on(originDeleteStoriesFx.pending, (_, loading) => ({ ..._, loading, error: null }));
$store.on(originDeleteStoriesFx.doneData, (_, data) => ({
  ..._,
  items: (_?.items || []).filter((item) => item?.id !== data),
}));
export default $store;
