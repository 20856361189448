import axios from 'utils/axios';
import { DoctorsEventStatus } from '../types';

export const findDoctorAppointments = (
  isPersonal: boolean,
  patientIds: string[],
  timeFrom: Date,
  timeTo: Date,
  statuses?: DoctorsEventStatus[],
  page?: number,
  pageSize?: number
) => {
  statuses = statuses || [];
  page = page || 0;
  pageSize = pageSize || 10;
  patientIds = patientIds || [];
  return axios.get(`/api/v1/appointments/my`, {
    params: {
      patientIds: patientIds.length ? patientIds.join(',') : null,
      isPersonal: isPersonal ? 'true' : 'false',
      timeFrom,
      timeTo,
      statuses: statuses.length ? statuses.join(',') : null,
      page,
      pageSize,
    },
  });
};
