import { useCallback, useEffect, useState } from 'react';
import Manage from '../../../../../types/manage';
import { findServicePackageGroups } from '../../../../../api/findServicePackageGroups';

export const useFetchServicePackageGroups = (query?: string) => {
  const [search, setSearch] = useState<string>(query);
  const [request, setRequest] = useState<any>(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState<Array<Manage.ServicePackages.Groups.Item>>([]);

  useEffect(() => {
    setRequest({
      query: search,
      deleted: false,
    });
  }, [search]);

  const onRefresh = useCallback(async () => {
    try {
      const items = await findServicePackageGroups(request);
      setData(items);
    } catch (e) {
      setError(e);
      setLoading(false);
    }
  }, [request]);

  useEffect(() => {
    const timeout = null;
    if (request) {
      onRefresh().then(() => setLoading(false));
    }
    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [onRefresh, request]);

  return [data, search, setSearch, isLoading, error, onRefresh] as [
    typeof data,
    typeof search,
    typeof setSearch,
    typeof isLoading,
    typeof error,
    typeof onRefresh
  ];
};
