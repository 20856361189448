import { AxiosResponse } from 'axios';
import axios from 'utils/axios';
import { ResponseDataInterface } from '../../../../types';

export const getAgentReport = (): Promise<AxiosResponse> =>
  axios.get(`/api/v1/agent/reports/self`, { responseType: 'blob' });

export const getAgentSp = (): Promise<AxiosResponse<ResponseDataInterface<Array<any>>>> =>
  axios.get(`/api/v1/agent/sp`);

export const getAgentByPhoneSp = (params: Record<any, any>): Promise<AxiosResponse<ResponseDataInterface<any>>> =>
  axios.get(`/api/v1/agent/user`, { params });

export const postUserPsp = (data: Record<any, any>): Promise<AxiosResponse<ResponseDataInterface<any>>> =>
  axios.post(`/api/v1/agent/user_psp`, data);

export const postPsp = (data: Record<any, any>): Promise<AxiosResponse<ResponseDataInterface<any>>> =>
  axios.post(`/api/v1/agent/psp`, data);

export const getCertificate = (id: string): Promise<AxiosResponse> =>
  axios.get(`/api/v1/agent/pdf/${id}`, { responseType: 'blob' });

export const getCertificateData = (id: string): Promise<AxiosResponse> => axios.get(`/api/v1/agent/cert/${id}`);
