import { createEffect } from 'effector';
import { ResponseCode } from 'types';
import { toast } from 'react-toastify';
import { authCodesEsia } from '$api';

export const authCodesEsiaFx = createEffect(
  async (code: string): Promise<string> => {
    const res = await authCodesEsia(code)
      .then()
      .catch((res) => res);
    if (res?.data?.code === ResponseCode.SUCCESS) {
      return res?.data?.data?.authInfo?.displayName || null;
    }
    toast.error('Ошибка при запросе');
    throw new Error(res?.data?.code);
  }
);
