import React from 'react';

import { Box } from '@material-ui/core';
import styles from './AgreementsPage.module.sass';

const Icon = (props?: any) => (
  <Box className={props?.className}>
    <svg width="30" height="40" viewBox="0 0 30 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.81543 0H28.2094C28.6942 0 29.0909 0.418605 29.0909 0.930233V39.0698C29.0909 39.5814 28.6942 40 28.2094 40H0.881543C0.396694 40 0 39.5814 0 39.0698V9.76744L8.81543 0Z"
        fill="#1473FF"
      />
      <path
        opacity="0.5"
        d="M8.16068 9.76744C8.6723 9.76744 9.09091 9.34884 9.09091 8.83721V0L0 9.76744H8.16068Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.7274 25C23.7274 25 24.5455 24.25 24.5455 23.3333V16.6667C24.5455 15.75 23.7274 15 22.7274 15H6.36371C5.36371 15 4.54553 15.75 4.54553 16.6667V23.3333C4.54553 24.25 5.36371 25 6.36371 25H22.7274ZM8.80075 20.7073V22.5H7.67052V17.5H9.98858C10.3101 17.5 10.5837 17.5455 10.8093 17.6366C11.0348 17.7276 11.2184 17.8478 11.36 17.9972C11.5016 18.1466 11.6047 18.317 11.6695 18.5084C11.7343 18.6998 11.7667 18.8982 11.7667 19.1036C11.7667 19.3044 11.7343 19.5016 11.6695 19.6954C11.6047 19.8891 11.5016 20.0607 11.36 20.2101C11.2184 20.3595 11.0348 20.4797 10.8093 20.5707C10.5837 20.6618 10.3101 20.7073 9.98858 20.7073H8.80075ZM8.80072 19.8525H9.67899C9.80857 19.8525 9.93336 19.8431 10.0533 19.8245C10.1733 19.8058 10.2789 19.7696 10.3701 19.7159C10.4613 19.6622 10.5345 19.5864 10.5897 19.4883C10.6449 19.3903 10.6724 19.2619 10.6724 19.1032C10.6724 18.9445 10.6449 18.8161 10.5897 18.718C10.5345 18.62 10.4613 18.5441 10.3701 18.4904C10.2789 18.4367 10.1733 18.4006 10.0533 18.3819C9.93336 18.3632 9.80857 18.3539 9.67899 18.3539H8.80072V19.8525ZM14.6894 22.5C15.083 22.5 15.4249 22.4358 15.7153 22.3074C16.0056 22.179 16.248 22.0028 16.4424 21.7787C16.6367 21.5546 16.7819 21.2885 16.8779 20.9804C16.9739 20.6723 17.0219 20.3361 17.0219 19.972C17.0219 19.5565 16.9631 19.1947 16.8455 18.8866C16.7279 18.5784 16.5647 18.3217 16.356 18.1162C16.1472 17.9108 15.9 17.7568 15.6145 17.6541C15.3289 17.5514 15.0206 17.5 14.6894 17.5H12.4721V22.5H14.6894ZM13.6024 21.5759H14.6102C14.7734 21.5759 14.9318 21.5503 15.0853 21.4989C15.2389 21.4476 15.3757 21.3624 15.4957 21.2433C15.6157 21.1243 15.7116 20.969 15.7836 20.7776C15.8556 20.5862 15.8916 20.3528 15.8916 20.0773C15.8916 19.8252 15.8664 19.5977 15.816 19.3946C15.7656 19.1915 15.6829 19.0176 15.5677 18.8729C15.4525 18.7281 15.3001 18.6173 15.1105 18.5402C14.921 18.4632 14.687 18.4247 14.4086 18.4247H13.6024V21.5759ZM18.9368 22.5V20.4342H21.0893V19.5798H18.9368V18.4244H21.4205V17.5H17.8066V22.5H18.9368Z"
        fill="white"
      />
    </svg>
  </Box>
);
const AgreementsPage = (): JSX.Element => {
  return (
    <Box className={styles.AgreementsPage}>
      <Box>
        <h3>Соглашения ООО «МЕДЭСТ»</h3>
        <Box>
          <Box className={styles.AgreementsPage_List}>
            <a
              className={styles.AgreementsPage_Item}
              href="/agreements/doc1.pdf"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Icon className={styles.icon} />
              <Box component="span">1. Пользовательское соглашение</Box>
            </a>
            <a
              className={styles.AgreementsPage_Item}
              href="/agreements/doc2.pdf"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Icon className={styles.icon} />
              <Box component="span">2. Политика защиты и обработки персональных данных</Box>
            </a>
            <a
              className={styles.AgreementsPage_Item}
              href="/agreements/doc3.pdf"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Icon className={styles.icon} />
              <Box component="span">3. Соглашение об использовании АСП</Box>
            </a>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AgreementsPage;
