import isNumber from 'lodash/isNumber';
import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from 'date-fns';
import { JournalFilterType, JournalUnitType, JournalUnitTypeErrorColors } from '../types';
import { convertUtcToZonedTime } from '../../../utils/date';

export const getJournalUnitTypePattern = (key: string): any => {
  switch (key) {
    case JournalUnitType.Frequency:
      return RegExp(/^\d{1,3}$/);
    case JournalUnitType.Dystolic:
      return RegExp(/^\d{1,3}$/);
    case JournalUnitType.Systolic:
      return RegExp(/^\d{1,3}$/);
    case JournalUnitType.Kg:
      return RegExp(/^\d{1,3}(\.?\d{0,1})$/);
    case JournalUnitType.Degrees:
      return RegExp(/^\d{1,2}(\.?\d{0,1})$/);
    case JournalUnitType.MmolPerLiter:
      return RegExp(/^\d{1,2}(\.?\d{0,1})$/);
    case JournalUnitType.Ldl:
      return RegExp(/^\d{1,4}$/);
    case JournalUnitType.Hdl:
      return RegExp(/^\d{1,4}$/);
    case JournalUnitType.Tg:
      return RegExp(/^\d{1,4}$/);
    case JournalUnitType.Total:
      return RegExp(/^\d{1,2}(\.?\d{0,1})$/);
    default:
      return null;
  }
};

export const getJournalColor = (type: JournalUnitType | string) => {
  return JournalUnitTypeErrorColors[type] || '#7587C5';
};

export const getJournalUnitTypeMask = (key: string, v: any, oldV: any): any => {
  switch (key) {
    case JournalUnitType.Frequency:
      return v === '' || (isNumber(Number(v)) && v.toString().match(getJournalUnitTypePattern(key)))
        ? v.toString()
        : oldV;
    case JournalUnitType.Dystolic:
      return v === '' || (isNumber(Number(v)) && v.toString().match(getJournalUnitTypePattern(key)))
        ? v.toString()
        : oldV;
    case JournalUnitType.Systolic:
      return v === '' || v.toString().match(getJournalUnitTypePattern(key)) ? v.toString() : oldV;
    case JournalUnitType.Kg:
      return v === '' || v.toString().match(getJournalUnitTypePattern(key)) ? v.toString() : oldV;
    case JournalUnitType.Degrees:
      return v === '' || v.toString().match(getJournalUnitTypePattern(key)) ? v.toString() : oldV;
    case JournalUnitType.MmolPerLiter:
      return v === '' || v.toString().match(getJournalUnitTypePattern(key)) ? v.toString() : oldV;
    case JournalUnitType.Ldl:
      return v === '' || v.toString().match(getJournalUnitTypePattern(key)) ? v.toString() : oldV;
    case JournalUnitType.Hdl:
      return v === '' || v.toString().match(getJournalUnitTypePattern(key)) ? v.toString() : oldV;
    case JournalUnitType.Tg:
      return v === '' || (isNumber(Number(v)) && v.toString().match(getJournalUnitTypePattern(key)))
        ? v.toString()
        : oldV;
    case JournalUnitType.Total:
      return v === '' || v.toString().match(getJournalUnitTypePattern(key)) ? v.toString() : oldV;
    case JournalUnitType.Text:
      return v === '' || v.toString().length <= 200 ? v.toString() : oldV;
    default:
      return '';
  }
};

export const isJournalUnitTypeValid = (key: string, v: any): any => {
  switch (key) {
    case JournalUnitType.Frequency:
      return isNumber(Number(v)) && Number(v) >= 1 && Number(v) <= 400;
    case JournalUnitType.Dystolic:
      return isNumber(Number(v)) && Number(v) >= 1 && Number(v) <= 300;
    case JournalUnitType.Systolic:
      return isNumber(Number(v)) && Number(v) >= 1 && Number(v) <= 400;
    case JournalUnitType.Kg:
      return isNumber(Number(v)) && Number(v) > 0 && Number(v) <= 400;
    case JournalUnitType.Degrees:
      return isNumber(Number(v)) && Number(v) >= 34 && Number(v) <= 42;
    case JournalUnitType.MmolPerLiter:
      return isNumber(Number(v)) && Number(v) >= 1.5 && Number(v) <= 10;
    case JournalUnitType.Ldl:
      return isNumber(Number(v)) && Number(v) >= 0 && Number(v) <= 1000;
    case JournalUnitType.Hdl:
      return isNumber(Number(v)) && Number(v) >= 0 && Number(v) <= 1000;
    case JournalUnitType.Tg:
      return isNumber(Number(v)) && Number(v) > 0 && Number(v) <= 1000;
    case JournalUnitType.Total:
      return isNumber(Number(v)) && Number(v) >= 0 && Number(v) <= 10;
    case JournalUnitType.Text:
      return v.toString().length > 0 && v.toString().length <= 200;
    default:
      return true;
  }
};

export const prepareJournalDates = (
  date: string | JournalFilterType | null,
  customDate?: Date
): { timeFrom: Date; timeTo: Date } => {
  const currentDate = convertUtcToZonedTime(customDate || new Date(), 'UTC');
  let timeFrom = startOfDay(currentDate);
  let timeTo = endOfDay(currentDate);
  switch (date) {
    case JournalFilterType.DAY:
      return { timeFrom, timeTo };
    case JournalFilterType.WEEK:
      timeFrom = startOfWeek(currentDate, { weekStartsOn: 1 });
      timeTo = endOfWeek(currentDate, { weekStartsOn: 1 });
      return { timeFrom, timeTo };
    case JournalFilterType.MONTH:
      timeFrom = startOfMonth(currentDate);
      timeTo = endOfMonth(currentDate);
      return { timeFrom, timeTo };
    case JournalFilterType.YEAR:
      timeFrom = startOfYear(currentDate);
      timeTo = endOfYear(currentDate);
      return { timeFrom, timeTo };
    default:
      return { timeFrom, timeTo };
  }
};
