import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Box, Grid } from '@material-ui/core';
import styles from './AdminMarketingInfo.module.sass';
import ContentLoading from '../ContentLoading';
import FormValidator from '../Forms/FormValidator';
import Button from '../Forms/Button';
import { useFormValidation } from '../../utils/hooks/useFormValidation';
import Manage from '../../types/manage';
import manageServicePackageMarketingInfoApi from '../../api/manageServicePackageMarketingInfo';
import { displayErrors, handleHttpError } from '../../utils/handleHttpError';
import InputField from '../Forms/InputField';

export interface AdminMarketingInfoViewProps {
  servicePackageId: string;
  onCancel: () => void;
  positiveBtnText: string;
  disabled?: boolean;
}

const AdminMarketingInfoView: React.FC<AdminMarketingInfoViewProps> = (props) => {
  const { servicePackageId, onCancel, positiveBtnText, disabled = false } = props;

  const [isLoading, setLoading] = useState<boolean>(false);

  const [fields, validateOnChange, getFieldErrors, _, setFormErrors, setFields] =
    useFormValidation<Manage.ServicePackages.MarketingInfo>({});

  useEffect(() => _loadMarketingInfo(), [servicePackageId]);

  const onSave = (e) => {
    if (disabled) {
      return;
    }
    e.preventDefault();
    const form = e.target;
    const inputs = [...form.elements].filter((i) => ['INPUT', 'SELECT', 'TEXTAREA'].includes(i.nodeName));

    const { hasError, errors } = FormValidator.bulkValidate(inputs);

    setFormErrors([...errors]);

    if (!hasError) {
      console.log(fields);
      _onSendData();
    } else {
      console.error(errors);
    }
  };

  const _onSendData = () => {
    setLoading(true);
    manageServicePackageMarketingInfoApi
      .updateMarketingInfo(servicePackageId, fields)
      .then((res) => {
        if (res.data.code === 'success') {
          onCancel();
        } else {
          throw new Error(res.data.code);
        }
        setLoading(false);
      })
      .catch((error) => {
        const e = handleHttpError(error);
        displayErrors(e);
        setLoading(false);
      });
  };

  const _loadMarketingInfo = () => {
    setLoading(true);
    manageServicePackageMarketingInfoApi
      .getMarketingInfo(servicePackageId)
      .then((res) => {
        if (res.data.code === 'success') {
          const info = res.data.data;
          setFields(info || {});
        } else {
          throw new Error(res.data.code);
        }
        setLoading(false);
      })
      .catch((error) => {
        const e = handleHttpError(error);
        displayErrors(e);
        setLoading(false);
      });
  };

  return (
    <ContentLoading isLoading={isLoading} isError={false} fetchData={() => null}>
      <form onSubmit={onSave}>
        <Box className={classNames(styles.AdminMarketingInfo_Content_Wrapper)}>
          <Box className={classNames(styles.AdminMarketingInfo_Content_Item)}>
            <Grid container justify="center" alignItems="center" spacing={2}>
              <Grid item xs={12}>
                <InputField
                  name="cost"
                  disabled={disabled}
                  value={fields.cost}
                  onChange={(e) => validateOnChange('cost', e.target.value, e)}
                  data-validate="[]"
                  errors={getFieldErrors('cost')}
                  block
                  label="Стоимость сертификата"
                  placeholder="Стоимость сертификата"
                />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  name="validity"
                  disabled={disabled}
                  value={fields.validity}
                  onChange={(e) => validateOnChange('validity', e.target.value, e)}
                  data-validate="[]"
                  errors={getFieldErrors('validity')}
                  block
                  label="Срок действия сертификата"
                  placeholder="Срок действия сертификата"
                />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  name="onDemand"
                  disabled={disabled}
                  value={fields.onDemand}
                  onChange={(e) => validateOnChange('onDemand', e.target.value, e)}
                  data-validate="[]"
                  errors={getFieldErrors('onDemand')}
                  block
                  label="Консультации с дежурным врачем"
                  placeholder="Консультации с дежурным врачем"
                />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  name="narrow"
                  disabled={disabled}
                  value={fields.narrow}
                  onChange={(e) => validateOnChange('narrow', e.target.value, e)}
                  data-validate="[]"
                  errors={getFieldErrors('narrow')}
                  block
                  label="Консультация узких специалистов"
                  placeholder="Консультация узких специалистов"
                />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  name="secondOpinion"
                  disabled={disabled}
                  value={fields.secondOpinion}
                  onChange={(e) => validateOnChange('secondOpinion', e.target.value, e)}
                  data-validate="[]"
                  errors={getFieldErrors('secondOpinion')}
                  block
                  label="Второе экспертное мнение"
                  placeholder="Второе экспертное мнение"
                />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  name="medications"
                  disabled={disabled}
                  value={fields.medications}
                  onChange={(e) => validateOnChange('medications', e.target.value, e)}
                  data-validate="[]"
                  errors={getFieldErrors('medications')}
                  block
                  label="Консультации по лекарственным препаратам"
                  placeholder="Консультации по лекарственным препаратам"
                />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  name="psychologist"
                  disabled={disabled}
                  value={fields.psychologist}
                  onChange={(e) => validateOnChange('psychologist', e.target.value, e)}
                  data-validate="[]"
                  errors={getFieldErrors('psychologist')}
                  block
                  label="Консультация с психологом"
                  placeholder="Консультация с психологом"
                />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  name="nutritionist"
                  disabled={disabled}
                  value={fields.nutritionist}
                  onChange={(e) => validateOnChange('nutritionist', e.target.value, e)}
                  data-validate="[]"
                  errors={getFieldErrors('nutritionist')}
                  block
                  label="Консультация диетолога"
                  placeholder="Консультация диетолога"
                />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  name="rehabilitationCovid"
                  disabled={disabled}
                  value={fields.rehabilitationCovid}
                  onChange={(e) => validateOnChange('rehabilitationCovid', e.target.value, e)}
                  data-validate="[]"
                  errors={getFieldErrors('rehabilitationCovid')}
                  block
                  label="Реабилитация после COVID-19"
                  placeholder="Реабилитация после COVID-19"
                />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  name="wellnessLite"
                  disabled={disabled}
                  value={fields.wellnessLite}
                  onChange={(e) => validateOnChange('wellnessLite', e.target.value, e)}
                  data-validate="[]"
                  errors={getFieldErrors('wellnessLite')}
                  block
                  label="Велнес лайт"
                  placeholder="Велнес лайт"
                />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  name="wellnessVip"
                  disabled={disabled}
                  value={fields.wellnessVip}
                  onChange={(e) => validateOnChange('wellnessVip', e.target.value, e)}
                  data-validate="[]"
                  errors={getFieldErrors('wellnessVip')}
                  block
                  label="Велнес vip"
                  placeholder="Велнес vip"
                />
              </Grid>
              <Grid item xs={12} container justify="space-around" alignItems="center" spacing={1}>
                <Grid item xs={disabled ? 12 : 6} lg={disabled ? 12 : 6}>
                  <Button block={disabled} className="mr-2" color="default" onClick={onCancel}>
                    Отмена
                  </Button>
                </Grid>
                {!disabled && (
                  <Grid item xs={6} lg={6}>
                    <Button type="submit" color="primary" isLoading={isLoading}>
                      {positiveBtnText}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </form>
    </ContentLoading>
  );
};

export default AdminMarketingInfoView;
