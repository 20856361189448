import { createStore, forward } from 'effector';
import { omit } from 'lodash';
import { onGetChecker, onResetChecker, onSetOrderChecker, onSetStepChecker, onSetStepDataChecker } from './events';
import { originGetCheckerFx, originSetCheckerFx } from './effects';
import { CheckerDataInterface, CheckerInterface, CheckerType } from '../types';

export interface CheckerStateInterface {
  loading: boolean;
  error: any;
  loadingOrder: boolean;
  errorOrder: any;
  dictionaries: { [key: string]: Array<CheckerDataInterface> };
  step: CheckerType;
  checker: Array<CheckerDataInterface>;
  success: CheckerInterface;
}

const defaultState: CheckerStateInterface = {
  loading: true,
  error: null,
  loadingOrder: false,
  errorOrder: null,
  dictionaries: {},
  step: CheckerType.PROBLEM_AREA,
  checker: [],
  success: null,
};

const $store = createStore(defaultState);
forward({ from: onGetChecker, to: originGetCheckerFx });
forward({ from: onSetOrderChecker, to: originSetCheckerFx });
$store.on(onResetChecker, (_, data) => ({ ...defaultState, ...(data || {}) }));
$store.on(onSetStepChecker, (_, step) => ({ ..._, step }));
$store.on(originGetCheckerFx.pending, (_, loading) => ({ ..._, loading, error: loading ? null : _.error }));
$store.on(originGetCheckerFx.fail, (_, error) => ({ ..._, error, success: null }));
$store.on(originGetCheckerFx.doneData, (_, payload) => {
  return {
    ..._,
    dictionaries: { ..._.dictionaries, ...payload },
  };
});
$store.on(originSetCheckerFx.pending, (_, loadingOrder) => ({
  ..._,
  loadingOrder,
  errorOrder: loadingOrder ? null : _.errorOrder,
}));
$store.on(originSetCheckerFx.fail, (_, errorOrder) => ({ ..._, errorOrder }));
$store.on(originSetCheckerFx.doneData, (_, success) => ({
  ..._,
  success,
}));
$store.on(onSetStepDataChecker, (_, data) => {
  const { id, type } = data || {};
  const dictionaries = omit(
    _.dictionaries,
    Object.values(CheckerType).map((type) => `${id}_${type}`)
  );
  let checker = _.checker;
  switch (type) {
    case CheckerType.PROBLEM_AREA:
      checker = [data];
      break;
    case CheckerType.MAIN_SYMPTOM:
      checker = checker
        .filter(
          ({ type }) =>
            [CheckerType.MAIN_SYMPTOM, CheckerType.SIDE_SYMPTOM, CheckerType.SYMPTOM_CHARACTERISTIC].indexOf(type) ===
            -1
        )
        .concat(data);
      break;
    default:
      const isChecked = checker.find(({ id }) => id === data?.id);
      checker = isChecked ? checker.filter(({ id }) => id !== data?.id) : checker.concat(data);
      break;
  }
  return {
    ..._,
    checker,
    dictionaries,
  };
});
export default $store;
