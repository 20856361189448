import { createEvent } from 'effector';
import { FormError } from 'components/Forms/FormValidator/FormValidator';
import { AgentSaleType, AgentSaleUserPsp } from '../types';

export const onSetAgentSaleType = createEvent<AgentSaleType>('onSetAgentSaleType');
export const onAgentChangeForm = createEvent<AgentSaleUserPsp>('onAgentChangeForm');
export const onAgentResetForm = createEvent<AgentSaleUserPsp>('onAgentResetForm');
export const onAgentReset = createEvent<any>('onAgentReset');
export const onAgentResetCheck = createEvent<void>('onAgentResetCheck');
export const onAgentReplace = createEvent<AgentSaleUserPsp>('onAgentReplace');
export const onSetAgentErrorsForm = createEvent<Array<FormError>>('onSetAgentErrorsForm');
