import React, { useState } from 'react';
import { Backdrop, Box, Dialog, Grid, Avatar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import { parseISO } from 'date-fns';
import { toast } from 'react-toastify';
import styles from './AdminReviewAppointmentView.module.sass';
import ContentCard from '../ContentCard';
import Manage from '../../types/manage';
import InputField from '../Forms/InputField';
import { decodeAvatarResource } from '../../utils';
import { format } from '../../utils/date';
import { DoctorsEventStatus } from '../../types';
import StarRating from '../StarRating';
import Button from '../Forms/Button';
import { updateFeedbackStatus } from '../../api/updateFeedbackStatus';
import { AppointmentConclusionLinkView } from '../AppointmentCoclusion/AppointmentConclusionLinkView';

interface AdminReviewAppointmentViewInterface {
  review: Manage.Review.Item;
  onClose: (reset?: boolean) => void;
}

const AdminReviewAppointmentView = (props: AdminReviewAppointmentViewInterface) => {
  const { onClose = (reset?: boolean) => {}, review } = props;
  const [loading, setLoading] = useState(false);
  const appointment: Manage.Review.AppointmentInfo = review.appointmentInfo;
  const getTypeOfAppointment = () => {
    if (appointment.channel.value === 'AUDIO') {
      return appointment.channel.value === 'VIDEO' ? ['чат', 'видео', 'аудио'].join('/') : ['чат', 'аудио'].join('/');
    }
    return appointment.channel.value === 'VIDEO' ? ['чат', 'видео', 'аудио'].join('/') : 'чат';
  };
  const getLabelStatus = (status: DoctorsEventStatus): string => {
    switch (status) {
      case DoctorsEventStatus.AWAITS:
        return 'Ожидает ответа';
      case DoctorsEventStatus.CANCELED:
        return 'Отменена';
      case DoctorsEventStatus.DONE:
        return 'Завершена';
      case DoctorsEventStatus.IN_PROGRESS:
        return 'Сейчас идет';
      case DoctorsEventStatus.FINISHED:
        return 'Ожидает заключение';
      case DoctorsEventStatus.FAILED:
        return 'Техническая проблема';
      default:
        return null;
    }
  };

  const updateStatus = (status: string): void => {
    setLoading(true);
    updateFeedbackStatus(review.id, status)
      .then((response) => {
        if (response.data.code === 'success') {
          onClose(true);
        } else {
          throw new Error();
        }
      })
      .catch(() => {
        toast.error('Ошибка при запросе');
        setLoading(false);
      });
  };
  return (
    <Dialog
      PaperProps={{ className: classNames(styles.Dialog) }}
      BackdropComponent={(props) => <Backdrop className={styles.Backdrop} {...props} />}
      onClose={onClose}
      open
    >
      <ContentCard className={styles.AdminReviewAppointmentView}>
        <ContentCard.Header className={styles.AdminReviewAppointmentView_Header}>
          <h6 className="page-header-title">Информация о консультации</h6>
          <CloseIcon onClick={() => onClose()} className={styles.AdminReviewAppointmentView_CloseIcon} />
        </ContentCard.Header>
        <ContentCard.Main className={styles.AdminReviewAppointmentView_Content}>
          <form onSubmit={() => {}}>
            <Box
              className={classNames(
                styles.AdminReviewAppointmentView_Content_Item,
                styles.AdminReviewAppointmentView_Content_Item_Profile
              )}
            >
              <Box>
                <Avatar
                  className={styles.AdminReviewAppointmentView_Content_Item_Profile_Img}
                  src={decodeAvatarResource(review.patient.avatar, 360, review.patient.sex)}
                />
                <Box className={styles.AdminReviewAppointmentView_Content_Item_Profile_Data}>
                  <Box className={styles.AdminReviewAppointmentView_Content_Item_Profile_Name}>
                    {review.patient.firstName} {review.patient.lastName}
                  </Box>
                  <Box className={styles.AdminReviewAppointmentView_Content_Item_Profile_Type}>
                    Тип консультации: {getTypeOfAppointment()}
                  </Box>
                  <Box className={styles.AdminReviewAppointmentView_Content_Item_Profile_Id}>ID: {appointment.id}</Box>
                </Box>
              </Box>
              <Box>
                <StarRating rating={review.rating} />
              </Box>
            </Box>
            <Box className={classNames(styles.AdminReviewAppointmentView_Content_Wrapper)}>
              <Box className={classNames(styles.AdminReviewAppointmentView_Content_Item)}>
                <Box component="h4" mb={3}>
                  Детали консультации
                </Box>
                <Grid
                  className={classNames(styles.AdminReviewAppointmentView_Content_Item_Container)}
                  container
                  justify="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid container item md={4} xs={12} justify="center" direction="column" alignItems="flex-start">
                    <Box mb={1} className={classNames(styles.AdminReviewAppointmentView_Content_Label)}>
                      Статус
                    </Box>
                    <Box
                      className={classNames(
                        styles[
                          `AdminReviewAppointmentView_Content_Status_${appointment.status.value as DoctorsEventStatus}`
                        ]
                      )}
                    >
                      {getLabelStatus(appointment.status.value as DoctorsEventStatus)}
                    </Box>
                  </Grid>
                  <Grid container item md={4} xs={12} justify="center" direction="column" alignItems="flex-start">
                    <Box mb={1} className={classNames(styles.AdminReviewAppointmentView_Content_Label)}>
                      Дата
                    </Box>
                    <Box>{format(parseISO(appointment.startTime.toString()), 'dd.MM.yyyy | HH:mm')}</Box>
                  </Grid>
                  <Grid container item md={4} xs={12} justify="center" direction="column" alignItems="flex-start">
                    <Box mb={1} className={classNames(styles.AdminReviewAppointmentView_Content_Label)}>
                      Продолжительность (мин)
                    </Box>
                    <Box>
                      {appointment.factDurationSeconds ? Math.round(appointment.factDurationSeconds / 60) : '0'}
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <AppointmentConclusionLinkView
                      url={appointment.conclusionUrl}
                      numberOfDay={appointment.numberOfDay}
                      isDaily={appointment.serviceType?.value === 'DAILY'}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputField
                      value={review.subject}
                      type="textarea"
                      readOnly
                      onChange={(e) => {}}
                      block
                      disabled
                      label="Отзыв клиента"
                      placeholder="Отзыв клиента"
                    />
                  </Grid>
                  {review.status.value === 'AWAITS' && (
                    <Grid container item xs={12} justify="space-between" direction="row" alignItems="center">
                      <Grid container item xs={6} lg={6} spacing={1}>
                        <Button color="default" onClick={() => updateStatus('REJECTED')} isLoading={loading} block>
                          Запретить
                        </Button>
                      </Grid>
                      <Grid container item xs={6} lg={6} spacing={1}>
                        <Button color="primary" onClick={() => updateStatus('APPROVED')} isLoading={loading} block>
                          Опубликовать
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                  {review.status.value === 'APPROVED' && (
                    <Grid container item xs={12} justify="center" direction="row" alignItems="center">
                      <Grid container item xs={12} lg={6} spacing={1} direction="row" alignItems="center">
                        <Button color="default" onClick={() => updateStatus('REJECTED')} isLoading={loading} block>
                          Запретить
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                  {review.status.value === 'REJECTED' && (
                    <Grid container item xs={12} justify="center" direction="row" alignItems="center">
                      <Grid container item xs={12} lg={6} spacing={1} direction="row" alignItems="center">
                        <Button color="primary" onClick={() => updateStatus('APPROVED')} isLoading={loading} block>
                          Опубликовать
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Box>
          </form>
        </ContentCard.Main>
      </ContentCard>
    </Dialog>
  );
};
export default AdminReviewAppointmentView;
