// https://github.com/chriso/validator.js
import validator from 'validator';
import { isValid, subYears } from 'date-fns';
import { isArray, isString } from 'lodash';

export enum ErrorCode {
  Required = 'required',
  Code = 'code',
  Email = 'email',
  Number = 'number',
  Name = 'name',
  Integer = 'integer',
  Alphanum = 'alphanum',
  Url = 'url',
  Equalto = 'equalto',
  Minlen = 'minlen',
  Maxlen = 'maxlen',
  Len = 'len',
  Min = 'min',
  Max = 'max',
  List = 'list',
  Password = 'password',
  Birthday = 'birthday',
  Phone = 'phone',
}
export const getErrorMsg = (code: ErrorCode, param: any): string => {
  switch (code) {
    case ErrorCode.Required:
      return 'Поле обязательно для ввода';

    case ErrorCode.Email:
      return 'Неверно введён email';

    case ErrorCode.Number:
      return 'Значение поля должно быть число';

    case ErrorCode.Name:
      return 'Поле должно содержать только буквы кириллицы и знак пробела';

    case ErrorCode.Integer:
      return 'Значение поля должно быть целым числом';

    case ErrorCode.Alphanum:
      return 'Поле должно только содержать цифры и буквы';

    case ErrorCode.Url:
      return 'Неверно введён url';

    case ErrorCode.Equalto:
      return `Поле должно совпадать с ${param}`;

    case ErrorCode.Minlen:
      return `Минимальная длина поля ${param}`;

    case ErrorCode.Maxlen:
      return `Максимальная длина поля ${param}`;

    case ErrorCode.Len:
      return `Длина поля должна быть равна ${param}`;

    case ErrorCode.Phone:
      return 'Номер телефона должен содержать 11 символов';

    case ErrorCode.Code:
      return 'Неверный формат кода';

    case ErrorCode.Min:
      return `Значение поля должно быть не меньше ${param}`;

    case ErrorCode.Max:
      return `Значение поля должно быть не больше ${param}`;

    case ErrorCode.List:
      return `${param} не входит в значение поля`;

    case ErrorCode.Password:
      return 'Пароль должен содержать цифры, заглваные и строчные буквы, и быть не менее 8 символов';

    case ErrorCode.Birthday:
      return 'Возраст должен быть 18+';

    default:
      return '';
  }
};
export type FormError = {
  field: string;
  code: ErrorCode;
  message: string;
};
export const getErrorsByFields = (fields: Array<string>, errors: Array<FormError>) =>
  errors.filter(({ field: _ }) => fields.includes(_));

export const validateByFieldValue = (
  data: Record<string, any>,
  validator: Record<string, Array<ErrorCode | [ErrorCode, any]>>,
  _: Array<FormError>
): Array<FormError> =>
  Object.keys(data).reduce(
    (acc, field) =>
      acc
        .filter(({ field: _ }) => _ !== field)
        .concat(
          (validator?.[field] || []).reduce((acc, code) => {
            const param = isArray(code) ? code?.[1] : null;
            return acc.concat(getErrors(isArray(code) ? code?.[0] : code, param, data?.[field], field));
          }, [])
        ),
    _
  );

export const getErrors = (
  code: ErrorCode,
  param: any,
  value: any,
  field: string,
  isCheckbox?: boolean
): Array<FormError> => {
  let result = {};
  switch (code) {
    case ErrorCode.Required:
      result = {
        ...result,
        [code]: isCheckbox ? value === false : validator.isEmpty(isString(value) ? value : value?.toString() || ''),
      };
      break;
    case ErrorCode.Email:
      result = { ...result, [code]: value && value.length > 0 ? !validator.isEmail(value) : false };
      break;
    case ErrorCode.Number:
      result = { ...result, [code]: value && value.length > 0 ? !validator.isNumeric(value) : false };
      break;
    case ErrorCode.Name:
      const regExp = /^([а-яА-ЯёЁ]|[а-яА-ЯёЁ]+(-)?(\s)?[а-яА-ЯёЁ]+((\s)?((-)[а-яА-ЯёЁ]{1,})?[а-яА-ЯёЁ]*)*)?$/g;
      const isValue = value && value.length > 0;
      result = { ...result, [code]: isValue ? value.match(regExp) === null : false };
      break;
    case ErrorCode.Integer:
      result = { ...result, [code]: value && value.length > 0 ? !validator.isInt(value) : false };
      break;
    case ErrorCode.Alphanum:
      result = { ...result, [code]: value && value.length > 0 ? !validator.isAlphanumeric(value) : false };
      break;
    case ErrorCode.Url:
      result = { ...result, [code]: value && value.length > 0 ? !validator.isURL(value) : false };
      break;
    case ErrorCode.Equalto:
      const element: any = document.getElementById(param);
      result = { ...result, [code]: !validator.equals(value, element?.value) };
      break;
    case ErrorCode.Minlen:
      result = { ...result, [code]: !validator.isLength(value, { min: param }) };
      break;
    case ErrorCode.Maxlen:
      result = { ...result, [code]: !validator.isLength(value, { max: param }) };
      break;
    case ErrorCode.Len:
      result = {
        ...result,
        [code]: value && value.length > 0 ? !validator.isLength(value, { min: param, max: param }) : false,
      };
      break;
    case ErrorCode.Phone:
      result = {
        ...result,
        [code]: value && value.length > 0 ? value.replace(/[^0-9]/g, '').length !== 11 : false,
      };
      break;
    case ErrorCode.Code:
      result = {
        ...result,
        [code]: value && value.length > 0 ? value.replace(/[^0-9]/g, '').length !== 4 : false,
      };
      break;
    case ErrorCode.Birthday:
      result = {
        ...result,
        [code]: !value || !isValid(value) || subYears(new Date(), 19).valueOf() < value?.valueOf(),
      };
      break;
    case ErrorCode.Min:
      result = {
        ...result,
        [code]: value && value.length > 0 ? !validator.isInt(value, { min: validator.toInt(param) }) : false,
      };
      break;
    case ErrorCode.Max:
      result = {
        ...result,
        [code]: value && value.length > 0 ? !validator.isInt(value, { max: validator.toInt(param) }) : false,
      };
      break;
    case ErrorCode.List:
      const list = JSON.parse(param);
      result = {
        ...result,
        [code]: !validator.isIn(value, list),
      };
      break;
    case ErrorCode.Password:
      if (!value || value.length < 8) {
        result = {
          ...result,
          [code]: true,
        };
      } else if (
        !value.match(/[0-9]+/) ||
        !value.match(/[A-Z]+/) ||
        !value.match(/[a-z]+/) ||
        value.match(/[^0-9A-Za-z]/)
      ) {
        result = {
          ...result,
          [code]: true,
        };
      } else {
        result = {
          ...result,
          [code]: false,
        };
      }
      break;
    default:
      throw new Error('Unrecognized validator.');
  }

  return Object.keys(result)
    .filter((code) => result[code])
    .map((code: ErrorCode) => ({
      field,
      code,
      message: getErrorMsg(code, param),
    }));
};
const validate = (element: HTMLFormElement): Array<FormError> => {
  if (!element || !element.getAttribute('data-validate')) {
    return [];
  }
  const isCheckbox = element.type === 'checkbox';
  const value = isCheckbox ? element.checked : element.value.replace('_', '');
  const name = element.name;
  if (!name) throw new Error('Input name must not be empty.');
  const param = element.getAttribute('data-param');
  const validations = JSON.parse(element.getAttribute('data-validate'));

  return validations && validations.length
    ? validations
        .map((code: ErrorCode) => getErrors(code, param, value, name, isCheckbox))
        .reduce((acc, errors) => acc.concat(errors), [])
    : [];
};
const bulkValidate = (inputs: Array<HTMLElement>) => {
  const errors: Array<FormError> = inputs
    .filter((input) => !!input.getAttribute('data-validate'))
    .map<Array<FormError>>(validate)
    .reduce((acc, errors) => acc.concat(errors), []);
  const hasError = errors.length > 0;
  return {
    errors,
    hasError,
  };
};
const FormValidator = {
  validate,
  bulkValidate,
};

export default FormValidator;
