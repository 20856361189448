import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from 'date-fns/locale/ru';

import { validateFieldOnChange, getFieldErrors as getFieldErrorsUtil } from 'utils';
import classNames from 'classnames';
import { localStringToNumber } from 'utils/helpers';
import { authRegister } from 'store/actions';

import FormGroup from 'components/Forms/FormGroup';
import InputField from 'components/Forms/InputField';
import FormInvalidMessage from 'components/Forms/FormInvalidMessage';
import Button from 'components/Forms/Button';
import FormValidator from 'components/Forms/FormValidator';

import { addYears, isValid } from 'date-fns';
import { useHistory, useLocation } from 'react-router-dom';
import styles from './RegisterScreen.module.sass';
import SelectInput from '../../../components/Forms/SelectInput';

const RegisterScreen = (): JSX.Element => {
  const [registerForm, setRegisterForm] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    sex: '',
    birthday: null,
    isBirthdayError: false,
  });
  const history = useHistory();
  const location = useLocation();
  const { phoneLoginForm, codeConfirmForm, loginLoading } = useSelector((state: any) => ({
    phoneLoginForm: state.auth.phoneLoginForm,
    codeConfirmForm: state.auth.codeConfirmForm,
    loginLoading: state.auth.loginLoading,
  }));

  const dispatch = useDispatch();

  const handleAuthRegister = useCallback(
    (...args: any) => {
      const [phone, ...other] = Object.values(args);
      dispatch(authRegister.call(null, phone, ...other));
    },
    [dispatch]
  );

  const [registerFormErrors, setRegisterFormErrors] = useState([]);

  const getSexName = useCallback((sex: string) => {
    let sexName = '';

    switch (sex) {
      case 'MALE':
        sexName = 'Мужской';
        break;

      case 'FEMALE':
        sexName = 'Женский';
        break;

      default:
    }

    return sexName;
  }, []);

  const validateOnChange = useCallback(
    (name: string, value: any, event, element?) => {
      validateFieldOnChange(
        name,
        value,
        event,
        registerForm,
        setRegisterForm,
        registerFormErrors,
        setRegisterFormErrors,
        element
      );
    },
    [registerForm, setRegisterForm, registerFormErrors, setRegisterFormErrors]
  );

  const getFieldErrors = useCallback(
    (field: string) => getFieldErrorsUtil(field, registerFormErrors),
    [registerFormErrors]
  );

  const codeConfirmSubmit = useCallback(
    (e) => {
      e.preventDefault();

      const form = e.target;
      const inputs = [...form.elements].filter((i) => ['INPUT', 'SELECT', 'TEXTAREA'].includes(i.nodeName));

      const bulkValidate = FormValidator.bulkValidate(inputs);

      const { errors } = bulkValidate;

      let { hasError } = bulkValidate;
      if (!registerForm.birthday) {
        errors.push({
          field: 'birthday',
          code: 'required',
        });

        hasError = true;
      }

      if (registerForm.isBirthdayError) {
        errors.push({
          field: 'birthday',
          code: 'birthday',
        });

        hasError = true;
      }

      setRegisterFormErrors([...errors]);

      if (!hasError) {
        handleAuthRegister(
          localStringToNumber(phoneLoginForm.phone),
          registerForm.firstName,
          registerForm.middleName,
          registerForm.lastName,
          registerForm.birthday,
          codeConfirmForm.code,
          location,
          history,
          registerForm.sex
        );
      }
    },
    [registerForm, location, history, codeConfirmForm, phoneLoginForm, handleAuthRegister]
  );

  const handleError = useCallback((error: any) => {
    setRegisterForm((registerForm) =>
      error
        ? {
            ...registerForm,
            isBirthdayError: true,
          }
        : { ...registerForm, isBirthdayError: false }
    );
  }, []);
  2;

  const handleChangeData = useCallback(
    (date: any) => {
      setRegisterForm({
        ...registerForm,
        birthday: date,
      });

      const registerFormErrorsTemp = [...registerFormErrors];

      const errorIndex = registerFormErrorsTemp.findIndex((error) => error.field === 'birthday');

      if (errorIndex >= 0) {
        registerFormErrorsTemp.splice(errorIndex, 1);
        setRegisterFormErrors([...registerFormErrorsTemp]);
      }
    },
    [registerForm, registerFormErrors]
  );

  const isInvalidDate = useMemo(
    () =>
      (registerForm.birthday && !isValid(registerForm.birthday)) ||
      getFieldErrors('birthday')?.[0]?.code === 'required' ||
      registerForm.isBirthdayError,
    [getFieldErrors, registerForm.birthday, registerForm.isBirthdayError]
  );

  return (
    <div className={styles.registerScreen}>
      <h3 className={styles.registerScreen_title}>Завершение регистрации</h3>
      <h3 className={styles.registerScreen_descr}>
        Завершите настройку профиля, чтобы открыть доступ ко всем возможностям сервиса
      </h3>

      <form className={styles.registerScreen_form} onSubmit={(e) => codeConfirmSubmit(e)}>
        <FormGroup>
          <InputField
            name="lastName"
            data-validate='["required", "name", "maxlen"]'
            data-param={36}
            value={registerForm.lastName}
            errors={getFieldErrors('lastName')}
            onChange={(e) => validateOnChange('lastName', e.target.value, e)}
            block
            placeholder="Фамилия"
          />
        </FormGroup>

        <FormGroup>
          <InputField
            name="firstName"
            data-validate='["required", "name", "maxlen"]'
            data-param={36}
            value={registerForm.firstName}
            errors={getFieldErrors('firstName')}
            onChange={(e) => validateOnChange('firstName', e.target.value, e)}
            block
            placeholder="Имя"
          />
        </FormGroup>

        <FormGroup>
          <InputField
            name="middleName"
            data-validate='["required", "name", "maxlen"]'
            data-param={36}
            value={registerForm.middleName}
            errors={getFieldErrors('middleName')}
            onChange={(e) => validateOnChange('middleName', e.target.value, e)}
            block
            placeholder="Отчество"
          />
        </FormGroup>
        <FormGroup>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
            <KeyboardDatePicker
              autoOk
              maxDate={addYears(new Date(), -18)}
              className={classNames(isInvalidDate && 'MuiInputBase-input-error')}
              disableToolbar
              value={registerForm.birthday}
              onChange={handleChangeData}
              onError={handleError}
              inputProps={{
                name: 'birthday',
                className: classNames('fg-input input-block', isInvalidDate && 'is-invalid'),
              }}
              format="dd-MM-yyyy"
              views={['year', 'month', 'date']}
              disableFuture
              animateYearScrolling
              openTo="year"
              cancelLabel="Отменить"
              placeholder="День рождения"
              invalidDateMessage="Неверный формат"
              minDateMessage="Неверный формат"
              maxDateMessage="Возраст должен быть 18+"
              okLabel="OK"
            />
          </MuiPickersUtilsProvider>

          {getFieldErrors('birthday')?.[0]?.code === 'required' && (
            <FormInvalidMessage>Поле обязательно для ввода</FormInvalidMessage>
          )}
        </FormGroup>
        <FormGroup>
          <SelectInput
            name="sex"
            value={
              getSexName(registerForm.sex)
                ? {
                    value: registerForm.sex,
                    label: getSexName(registerForm.sex),
                  }
                : null
            }
            onChange={(option, e) => validateOnChange('sex', option.value, e)}
            data-validate='["required"]'
            options={[
              { value: 'MALE', label: 'Мужской' },
              { value: 'FEMALE', label: 'Женский' },
            ]}
            errors={getFieldErrors('sex')}
            block
            placeholder="Пол"
          />
        </FormGroup>

        <Button className="mt-4" type="submit" color="primary" block isLoading={loginLoading}>
          Продолжить
        </Button>
      </form>
    </div>
  );
};

export default RegisterScreen;
