import React, { JSX } from 'react';
import PageTitle from '../../../components/PageTitle';
import PageCard from '../../../components/PageCard';
import AgentSaleHeader from './components/AgentSaleHeader';
import AgentSaleActions from './components/AgentSaleActions';
import AgentSaleUserPsp from './components/AgentSaleUserPsp';
import AgentSaleReport from './components/AgentSaleReport';

const AgentSale = (): JSX.Element => {
  return (
    <>
      <PageCard showHeader={false}>
        <PageTitle center>Агентские продажи</PageTitle>
        <AgentSaleHeader />
        <AgentSaleUserPsp />
        <AgentSaleReport />
        <AgentSaleActions />
      </PageCard>
    </>
  );
};

export default AgentSale;
