import classNames from 'classnames';
import React from 'react';
import { range } from 'lodash';
import { BoxProps } from '@material-ui/core/Box/Box';
import { ReactComponent as StarFillIcon } from '../../assets/img/icons/star-fill-icon.svg';
import styles from './StarRating.module.sass';

export interface PropsInterface extends BoxProps {
  rating: number | null;
}
const stars = range(1, 6);
const StarRating = (props: PropsInterface): JSX.Element => {
  const { rating = null, className, ...rest } = props;
  return (
    <div className={classNames(styles.root, className)} {...rest}>
      {stars.map((r) => (
        <StarFillIcon key={r} className={classNames(styles.root_star, rating && r <= rating && styles.root_active)} />
      ))}
    </div>
  );
};

export default StarRating;
