import axios from 'utils/axios';
import Manage from '../types/manage';

export const findServicePackageGroups = (request: any): Promise<Array<Manage.ServicePackages.Groups.Item>> => {
  return axios
    .get(`/api/v1/servicepackagegroups`, {
      params: {
        query: request.query || null,
        deleted: request.deleted,
      },
    })
    .then((res) => res.data.data.items);
};
