import {toast} from "react-toastify";

export const HttpErrorValues = {
  unknown_error: 'unknown_error',
  security_error: 'security_error',
  invalid_auth_data: 'invalid_auth_data',
  unprocessable_entity: 'unprocessable_entity',
  field_invalid: 'field_invalid',
  login_already_exists: 'login_already_exists',
  access_token_invalid: 'access_token_invalid',
  phone_code_not_found: 'phone_code_not_found',
  service_package_group_is_tech: 'service_package_group_is_tech',
  business_conflict: 'business_conflict',
  create_payment_transaction_invalid: 'create_payment_transaction_invalid',
  patient_service_package_invalid_status_flow: 'patient_service_package_invalid_status_flow',
}
export type HttpErrorCode = keyof typeof HttpErrorValues


export type RawHttpError = {
  code: HttpErrorCode
  field?: string
  message: string
}

export type HttpError = {
  // raw: any
  /**
   * Code error from backend
   */
  code: HttpErrorCode
  title: string
  errors: RawHttpError[]

  /**
   * Request/Response status number
   * 404 | 301 | ...
   */
  status: number
}

const isDev: boolean = process.env.NODE_ENV === 'development'

const ServiceErrorsDictionary = {
  resource_forbidden: 'Доступ к ресурсу запрещен',
  business_conflict: 'Ошибка бизнес логики',
  unknown_error: 'Неизвестная ошибка',
  security_error: 'Ошибка доступа',
  access_token_invalid: 'Пользователь не авторизирован',
  invalid_auth_data: 'Такого пользователя не существует или пароль неверен',
  unprocessable_entity: 'Неверные данные',
  field_invalid: 'Неверно заполнено поле',
  login_already_exists: 'Такой пользователь уже зарегистрирован',
  phone_code_not_valid: 'Неверный код',
  phone_code_not_found: 'Превышено количество попыток ввода кода подтверждения',
  insufficient_funds: 'Недостаточно средств',
  service_package_group_is_tech: 'Группа не может быть удалена, так как она является системной.',
  resource_already_exists: 'Ресурс уже существует или объекты уже связаны.',
  create_payment_transaction_invalid: 'Ошибка при создании платежа. Возможно у пользователя отсутствует долг.',
  time_slot_busy: 'Не возможно поменять статус. У врача назначены консультации в ближайшее время.',
  invalid_doctor_available_status: 'Не возможно применить статус для врача.',
  sign_document_invalid: 'Ошибка подписания документов.',
  patient_service_package_invalid_status_flow: 'Ошибка смены статуса тарифа.',
  overlap_specialization_in_group: 'Есть группы, которые содержат одинаковые специализации.',
  partner_key_already_exists: 'Партнер с таким идентификатором уже существует.'
}

export function handleHttpError(orResponse: any): HttpError {
  let mainError: any = orResponse
  let responseStatus: number = orResponse.status
  if (orResponse && orResponse.response) {
    const res = orResponse.response
    mainError = res?.data
    responseStatus = res?.status
  }
  const code: HttpErrorCode = mainError?.code || HttpErrorValues.unknown_error
  let errors: RawHttpError[] = []
  if (mainError && mainError?.errors?.length > 0) {
    errors = mainError.errors.map((item: RawHttpError & any) => {
      const code: HttpErrorCode = item.code
      const serverMessage = isDev ? `(${item.message})` : ''
      return {
        code: code,
        field: item.field || undefined,
        message: `${ServiceErrorsDictionary[code]} ${serverMessage}`.trim(),
      }
    })
  }
  return {
    code: code,
    title: ServiceErrorsDictionary[code] || 'Ошибка',
    errors: errors || [],
    status: responseStatus,
  }
}

export const displayErrors = (error: HttpError) => {
  if(!error){
    toast.error('Неизвестная ошибка');
  }
  const message = error?.errors?.map(it => it.message).join('\n')
  toast.error(`${error.title}. ${message}`,);
}
