import { MANAGE_SPEC_LOADING, MANAGE_SPEC_SUCCESS, MANAGE_SPEC_ERRORS } from '../actions';
import Manage from '../../types/manage';

export interface ManageSpecReducerInitialSettings {
  spec?: Manage.Spec.Item[];
  errors?: any;
  loading?: boolean;
}
const initialSettings: ManageSpecReducerInitialSettings = {
  spec: [],
  loading: false,
  errors: null,
};

const manageSpecReducer = (state = initialSettings, action): ManageSpecReducerInitialSettings => {
  switch (action.type) {
    case MANAGE_SPEC_LOADING:
      return {
        ...state,
        spec: [],
        loading: action.loading,
      };
    case MANAGE_SPEC_SUCCESS:
      return {
        ...state,
        spec: action.spec,
        errors: null,
      };
    case MANAGE_SPEC_ERRORS:
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
};

export default manageSpecReducer;
