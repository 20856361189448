import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import InputField from '../../../../components/Forms/InputField';
import { getDailyConclusionStrategies } from '../../../../api/dailyconclusionstrategies/getDailyConclusionStrategies';
import ContentLoading from '../../../../components/ContentLoading';
import styles from './DailyStrategySelector.module.sass';

interface PropsInterface {
  errors?: Array<any>;
  label?: string;
  labelId?: string;
  placeholder?: string;
  name?: string;
  disabled?: boolean;
  onChange?: (event: any, element?: any) => void;
  dataValidate?: any;
  value?: any;
}

const DailyStrategySelector = (props: PropsInterface): JSX.Element => {
  const { value, onChange = () => {}, placeholder, ...rest } = props;
  const [search, setSearch] = useState(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [request, setRequest] = useState({
    page: 0,
    pageSize: 100,
    active: true,
    deleted: false,
    query: search,
  });
  const [options, setOptions] = useState<{ id: string; name: string }[]>([]);
  useEffect(() => {
    const options = [];
    options.push({ id: null, name: 'Не указано' });
    setOptions([...options, ...(data || [])]);
  }, [data]);

  useEffect(() => {
    const timeout = setTimeout(() => onRefresh().then(() => setLoading(false)), 500);
    return () => {
      clearTimeout(timeout);
    };
  }, [request]);

  const onRefresh = useCallback(async () => {
    try {
      setLoading(true);
      const items = await getDailyConclusionStrategies(request).then((res) => res.data.data.items);
      setData(items);
    } catch (e) {
      setError(e);
      setLoading(false);
    }
  }, [request]);

  useEffect(() => {
    setRequest({
      ...request,
      query: search,
    });
  }, [search]);

  const handleClose = useCallback(() => {
    setOpen(false);
    setSearch(null);
  }, [setOpen, setSearch]);

  const onSelect = useCallback(
    (event, data) => {
      event.preventDefault();
      event.stopPropagation();
      onChange(data);
      handleClose();
    },
    [onChange, handleClose]
  );

  const handleOpen = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, [setOpen]);

  return (
    <>
      <ClickAwayListener onClickAway={() => handleClose()}>
        <Box style={{ position: 'relative' }}>
          <InputField
            block
            autoComplete="off"
            onFocus={() => {
              setSearch(null);
              handleOpen();
            }}
            value={search}
            placeholder={value?.name || options.find(({ id }) => id === null)?.name || placeholder}
            onChange={(e) => setSearch(e.target.value)}
            {...rest}
          />
          {open && (
            <Box className={styles.wrapper}>
              {isLoading && <ContentLoading isLoading={isLoading} isError={error} fetchData={() => onRefresh()} />}
              {!isLoading && (data || []).length === 0 && (
                <Box mt="5px" textAlign="center" width="100%" p={2} borderRadius="10px" border="1px solid #D8D8D8">
                  Совпадений не найдено
                </Box>
              )}
              {!isLoading && (data || []).length > 0 && (
                <Box component="ul">
                  {options.map((opt, index) => (
                    <li key={index}>
                      <a onClick={(event) => onSelect(event, opt)}>{opt.name}</a>
                    </li>
                  ))}
                </Box>
              )}
            </Box>
          )}
        </Box>
      </ClickAwayListener>
    </>
  );
};

export default DailyStrategySelector;
