import axios from 'utils/axios';

export const findPatientsAdmin = (
  query?: string,
  activeFrom?: Date,
  activeTo?: Date,
  external?: boolean,
  page?: number,
  pageSize?: number
) => {
  query = query || null;
  page = page || 0;
  pageSize = pageSize || 50;
  activeFrom = activeFrom || null;
  activeTo = activeTo || null;
  return axios.get(`/api/v1/patients`, {
    params: {
      query,
      activeFrom,
      activeTo,
      external,
      page,
      pageSize,
    },
  });
};
