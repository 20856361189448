import { createEffect } from 'effector';
import { toast } from 'react-toastify';
import { uniq } from 'lodash';
import {
  addAttachmentByDoctorApi,
  addRecommendationsApi,
  getAppointmentApi,
  getAppointmentDictionaryApi,
  getAppointmentDictionaryByCodeApi,
  setAppointmentsStatusApi,
} from '../api';
import { EIMKDictionary, EIMKKey } from '../types';

const _getAppointmenApi = (appointmentId: string) =>
  getAppointmentApi(appointmentId).then((data) =>
    getAppointmentDictionaryApi(appointmentId)
      .then((data) => data?.data?.data || [])
      .then((dictionaries) => ({ ...data?.data?.data, dictionaries }))
      .catch(() => ({ ...data?.data?.data, dictionaries: [] }))
  );
export const originGetAppointmentFx = createEffect(async (appointmentId: string): Promise<any> => {
  try {
    return await _getAppointmenApi(appointmentId).then();
  } catch (e) {
    toast.error('Ошибка при запросе. Попробуйте еще раз');
    throw new Error(e);
  }
});

export const originGetIemkFx = createEffect(async (): Promise<{ [key: string]: Array<any> }> => {
  try {
    return await Promise.all(
      uniq(Object.values(EIMKKey).map((key) => EIMKDictionary?.[key])).map((key) =>
        getAppointmentDictionaryByCodeApi(key)
          .then((data) => ({ [key]: data?.data || [] }))
          .catch(() => ({ [key]: [] }))
      )
    ).then((data) => data.reduce((acc, value) => ({ ...acc, ...value }), {}));
  } catch (e) {
    toast.error('Ошибка при запросе. Попробуйте еще раз');
    throw new Error(e);
  }
});

export const originSetAppointmentStatusFx = createEffect(
  async (data: { appointmentId: string; status: any }): Promise<any> => {
    try {
      const { appointmentId, status } = data;
      return await setAppointmentsStatusApi(appointmentId, status).then(() => status);
    } catch (e) {
      toast.error('Ошибка при запросе. Попробуйте еще раз');
      throw new Error(e);
    }
  }
);

export const originCloseAppointmentFx = createEffect(
  async (data: {
    appointmentId: string;
    anamnesis: string;
    recommendations: string;
    dictionaries?: Array<any>;
    files?: File[];
  }): Promise<any> => {
    try {
      const { appointmentId, anamnesis, recommendations, dictionaries, files } = data;
      if (files) {
        await addAttachmentByDoctorApi(appointmentId, files);
      }
      await addRecommendationsApi(appointmentId, anamnesis, recommendations, dictionaries || []).then();
      return await _getAppointmenApi(appointmentId).then();
    } catch (e) {
      toast.error('Ошибка при запросе. Попробуйте еще раз');
      throw new Error(e);
    }
  }
);
