export default {
  '3dm': require('assets/img/file-types/3dm.svg'),
  '3ds': require('assets/img/file-types/3ds.svg'),
  '3g2': require('assets/img/file-types/3g2.svg'),
  '3gp': require('assets/img/file-types/3gp.svg'),
  '7z': require('assets/img/file-types/7z.svg'),
  ai: require('assets/img/file-types/ai.svg'),
  aif: require('assets/img/file-types/aif.svg'),
  apk: require('assets/img/file-types/apk.svg'),
  app: require('assets/img/file-types/app.svg'),
  asf: require('assets/img/file-types/asf.svg'),
  asp: require('assets/img/file-types/asp.svg'),
  aspx: require('assets/img/file-types/aspx.svg'),
  asx: require('assets/img/file-types/asx.svg'),
  avi: require('assets/img/file-types/avi.svg'),
  bak: require('assets/img/file-types/bak.svg'),
  bat: require('assets/img/file-types/bat.svg'),
  bin: require('assets/img/file-types/bin.svg'),
  bmp: require('assets/img/file-types/bmp.svg'),
  cab: require('assets/img/file-types/cab.svg'),
  cad: require('assets/img/file-types/cad.svg'),
  cdr: require('assets/img/file-types/cdr.svg'),
  cer: require('assets/img/file-types/cer.svg'),
  cfm: require('assets/img/file-types/cfm.svg'),
  cfg: require('assets/img/file-types/cfg.svg'),
  cgi: require('assets/img/file-types/cgi.svg'),
  class: require('assets/img/file-types/class.svg'),
  com: require('assets/img/file-types/com.svg'),
  cpl: require('assets/img/file-types/cpl.svg'),
  cpp: require('assets/img/file-types/cpp.svg'),
  crx: require('assets/img/file-types/crx.svg'),
  csr: require('assets/img/file-types/csr.svg'),
  css: require('assets/img/file-types/css.svg'),
  csv: require('assets/img/file-types/csv.svg'),
  cue: require('assets/img/file-types/cue.svg'),
  cur: require('assets/img/file-types/cur.svg'),
  dat: require('assets/img/file-types/dat.svg'),
  db: require('assets/img/file-types/db.svg'),
  dbf: require('assets/img/file-types/dbf.svg'),
  dds: require('assets/img/file-types/dds.svg'),
  dem: require('assets/img/file-types/dem.svg'),
  dfx: require('assets/img/file-types/dfx.svg'),
  dll: require('assets/img/file-types/dll.svg'),
  dmg: require('assets/img/file-types/dmg.svg'),
  dmp: require('assets/img/file-types/dmp.svg'),
  doc: require('assets/img/file-types/doc.svg'),
  docx: require('assets/img/file-types/docx.svg'),
  drv: require('assets/img/file-types/drv.svg'),
  dtd: require('assets/img/file-types/dtd.svg'),
  dwg: require('assets/img/file-types/dwg.svg'),
  elf: require('assets/img/file-types/elf.svg'),
  eps: require('assets/img/file-types/eps.svg'),
  exe: require('assets/img/file-types/exe.svg'),
  fla: require('assets/img/file-types/fla.svg'),
  flash: require('assets/img/file-types/flash.svg'),
  flv: require('assets/img/file-types/flv.svg'),
  fnt: require('assets/img/file-types/fnt.svg'),
  fon: require('assets/img/file-types/fon.svg'),
  gam: require('assets/img/file-types/gam.svg'),
  gbr: require('assets/img/file-types/gbr.svg'),
  ged: require('assets/img/file-types/ged.svg'),
  gif: require('assets/img/file-types/gif.svg'),
  gpx: require('assets/img/file-types/gpx.svg'),
  gz: require('assets/img/file-types/gz.svg'),
  gzip: require('assets/img/file-types/gzip.svg'),
  hqz: require('assets/img/file-types/hqz.svg'),
  html: require('assets/img/file-types/html.svg'),
  ibooks: require('assets/img/file-types/ibooks.svg'),
  icns: require('assets/img/file-types/icns.svg'),
  ico: require('assets/img/file-types/ico.svg'),
  ics: require('assets/img/file-types/ics.svg'),
  iff: require('assets/img/file-types/iff.svg'),
  indd: require('assets/img/file-types/indd.svg'),
  iso: require('assets/img/file-types/iso.svg'),
  jar: require('assets/img/file-types/jar.svg'),
  jpg: require('assets/img/file-types/jpg.svg'),
  jpeg: require('assets/img/file-types/jpg.svg'),
  js: require('assets/img/file-types/js.svg'),
  jsp: require('assets/img/file-types/jsp.svg'),
  key: require('assets/img/file-types/key.svg'),
  kml: require('assets/img/file-types/kml.svg'),
  kmz: require('assets/img/file-types/kmz.svg'),
  lnk: require('assets/img/file-types/lnk.svg'),
  log: require('assets/img/file-types/log.svg'),
  lua: require('assets/img/file-types/lua.svg'),
  m3u: require('assets/img/file-types/m3u.svg'),
  m4a: require('assets/img/file-types/m4a.svg'),
  m4v: require('assets/img/file-types/m4v.svg'),
  'mach-o': require('assets/img/file-types/mach-o.svg'),
  max: require('assets/img/file-types/max.svg'),
  mdb: require('assets/img/file-types/mdb.svg'),
  mdf: require('assets/img/file-types/mdf.svg'),
  mid: require('assets/img/file-types/mid.svg'),
  mim: require('assets/img/file-types/mim.svg'),
  mov: require('assets/img/file-types/mov.svg'),
  mp3: require('assets/img/file-types/mp3.svg'),
  mp4: require('assets/img/file-types/mp4.svg'),
  mpa: require('assets/img/file-types/mpa.svg'),
  mpg: require('assets/img/file-types/mpg.svg'),
  msg: require('assets/img/file-types/msg.svg'),
  msi: require('assets/img/file-types/msi.svg'),
  nes: require('assets/img/file-types/nes.svg'),
  obj: require('assets/img/file-types/obj.svg'),
  odb: require('assets/img/file-types/odb.svg'),
  odc: require('assets/img/file-types/odc.svg'),
  odf: require('assets/img/file-types/odf.svg'),
  odg: require('assets/img/file-types/odg.svg'),
  odi: require('assets/img/file-types/odi.svg'),
  odp: require('assets/img/file-types/odp.svg'),
  ods: require('assets/img/file-types/ods.svg'),
  odt: require('assets/img/file-types/odt.svg'),
  odx: require('assets/img/file-types/odx.svg'),
  ogg: require('assets/img/file-types/ogg.svg'),
  otf: require('assets/img/file-types/otf.svg'),
  pages: require('assets/img/file-types/pages.svg'),
  pct: require('assets/img/file-types/pct.svg'),
  pdb: require('assets/img/file-types/pdb.svg'),
  pdf: require('assets/img/file-types/pdf.svg'),
  pif: require('assets/img/file-types/pif.svg'),
  pkg: require('assets/img/file-types/pkg.svg'),
  pl: require('assets/img/file-types/pl.svg'),
  png: require('assets/img/file-types/png.svg'),
  pps: require('assets/img/file-types/pps.svg'),
  ppt: require('assets/img/file-types/ppt.svg'),
  pptx: require('assets/img/file-types/pptx.svg'),
  ps: require('assets/img/file-types/ps.svg'),
  psd: require('assets/img/file-types/psd.svg'),
  pub: require('assets/img/file-types/pub.svg'),
  py: require('assets/img/file-types/py.svg'),
  ra: require('assets/img/file-types/ra.svg'),
  rar: require('assets/img/file-types/rar.svg'),
  raw: require('assets/img/file-types/raw.svg'),
  rm: require('assets/img/file-types/rm.svg'),
  rom: require('assets/img/file-types/rom.svg'),
  rpm: require('assets/img/file-types/rpm.svg'),
  rss: require('assets/img/file-types/rss.svg'),
  rtf: require('assets/img/file-types/rtf.svg'),
  sav: require('assets/img/file-types/sav.svg'),
  sdf: require('assets/img/file-types/sdf.svg'),
  sitx: require('assets/img/file-types/sitx.svg'),
  sql: require('assets/img/file-types/sql.svg'),
  srt: require('assets/img/file-types/srt.svg'),
  svg: require('assets/img/file-types/svg.svg'),
  swf: require('assets/img/file-types/swf.svg'),
  sys: require('assets/img/file-types/sys.svg'),
  tar: require('assets/img/file-types/tar.svg'),
  tex: require('assets/img/file-types/tex.svg'),
  tga: require('assets/img/file-types/tga.svg'),
  thm: require('assets/img/file-types/thm.svg'),
  tiff: require('assets/img/file-types/tiff.svg'),
  tmp: require('assets/img/file-types/tmp.svg'),
  torrent: require('assets/img/file-types/torrent.svg'),
  ttf: require('assets/img/file-types/ttf.svg'),
  txt: require('assets/img/file-types/txt.svg'),
  uue: require('assets/img/file-types/uue.svg'),
  vb: require('assets/img/file-types/vb.svg'),
  vcd: require('assets/img/file-types/vcd.svg'),
  vcf: require('assets/img/file-types/vcf.svg'),
  vob: require('assets/img/file-types/vob.svg'),
  wav: require('assets/img/file-types/wav.svg'),
  wma: require('assets/img/file-types/wma.svg'),
  wmv: require('assets/img/file-types/wmv.svg'),
  wpd: require('assets/img/file-types/wpd.svg'),
  wps: require('assets/img/file-types/wps.svg'),
  wsf: require('assets/img/file-types/wsf.svg'),
  xhtml: require('assets/img/file-types/xhtml.svg'),
  xlr: require('assets/img/file-types/xlr.svg'),
  xls: require('assets/img/file-types/xls.svg'),
  xlsx: require('assets/img/file-types/xlsx.svg'),
  xml: require('assets/img/file-types/xml.svg'),
  yuv: require('assets/img/file-types/yuv.svg'),
  zip: require('assets/img/file-types/zip.svg')
}
