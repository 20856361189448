import axios from 'utils/axios';

export const getDoctorDetails = (doctorId: string, forceToken?: boolean) => {
  return axios.get(
    `/publicapi/v1/doctors/${doctorId}`,
    forceToken
      ? {
          headers: { Authorization: null },
        }
      : {}
  );
};
