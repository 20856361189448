import axios from 'utils/axios';
import Manage from '../types/manage';

export const updatePatientServicePackageStatus = (
  patientServicePackageId: string,
  status: Manage.ServicePackages.Mfo.PatientServicePackageStatus
) => {
  return axios.put(`/api/v1/servicepackages/patient/${patientServicePackageId}/status`, {
    status,
  });
};

export const updatePatientServicePackageStatusWithData = (patientServicePackageId: string, data: any) => {
  return axios.put(`/api/v1/servicepackages/patient/${patientServicePackageId}/status`, { ...data });
};
