import React, { PropsWithChildren } from 'react';
import store from 'store';
import StoreContext from 'context/StoreContext';

interface PropsInterface extends PropsWithChildren<any> {}
const StoreProvider = (props: PropsInterface): JSX.Element => {
  const { children } = props;
  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
};

export default StoreProvider;
