import React, { useEffect, useState } from 'react';
import { ReactComponent as PdfIcon } from 'assets/img/icons/pdf-icon.svg';
import { Lightbox } from 'react-modal-image';

import styles from '../../AppointmentChatPage.module.sass';

const FilesView = (props: any) => {
  const { appointment } = props;

  const [attaches, setAttaches] = useState<Array<any>>([]);
  const [selectedResource, selectResource] = useState(null);

  useEffect(() => {
    const attaches = appointment?.conclusions.concat(appointment?.attachmentsIn).concat(appointment?.attachmentsOut);

    setAttaches(attaches);
  }, [appointment]);

  const onSelectResource = React.useCallback(
    (file) => {
      selectResource(file);
    },
    [appointment]
  );

  const renderFile = (file: any) => {
    const type = file?.contentType;
    if (type === 'application/pdf') {
      return (
        <a href={file.originalUrl} target="_blank" rel="noreferrer">
          <div className={styles.appointmentFiles_list_item}>
            <PdfIcon />
          </div>
        </a>
      );
    }
    return (
      <div
        key={`file-view-${file?.id}`}
        onClick={() => onSelectResource(file)}
        className={styles.appointmentFiles_list_item}
        style={{ backgroundImage: `url(${file.originalUrl})` }}
      />
    );
  };

  const hasAttaches = attaches.length > 0;

  return (
    <div className={styles.appointmentFiles}>
      {hasAttaches ? (
        <div className={styles.appointmentFiles_list}>{attaches.map(renderFile)}</div>
      ) : (
        <p className="text-center">У Вас нет вложенных файлов</p>
      )}
      {selectedResource && (
        <Lightbox
          medium={selectedResource.originalUrl}
          large={selectedResource.originalUrl}
          onClose={() => onSelectResource(null)}
        />
      )}
    </div>
  );
};

export default FilesView;
