import React, { useCallback, useMemo, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { decodeAvatarResource } from 'utils/helpers';

import DropdownMenu from 'components/DropdownMenu';
import DropdownContent from 'components/DropdownMenu/DropdownContent';
import DropdownToggle from 'components/DropdownMenu/DropdownToggle';

import { ReactComponent as AngleUpIcon } from 'assets/img/icons/angle-up-icon.svg';
import { ReactComponent as LogoutIcon } from 'assets/img/icons/logout-icon.svg';
import { ReactComponent as MedCardIcon } from 'assets/img/icons/medcard-icon.svg';
import { ReactComponent as DoctorIcon } from 'assets/img/icons/doctor-icon.svg';
import { ReactComponent as CheckIcon } from 'assets/img/icons/check-icon.svg';
import { ReactComponent as ProfileDoctorIcon } from 'assets/img/icons/profile-doctor-icon.svg';
import { connect, useSelector } from 'react-redux';
import classNames from 'classnames';
import { AvailabilityStatus } from 'types/doctor';
import { UserRole } from 'types';
import { updateDoctorAvailability, userLogout } from 'store/actions';
import { getDisplayName, getNameStatusFromDoctorByStatus } from 'utils';
import { SIDEBAR_MENU } from 'constants/common';
import { Box } from '@material-ui/core';
import DoctorStatusLabel from '../DoctorStatus';
import HasRole from '../HasRole/HasRole';
import styles from './UserProfileBar.module.sass';
import SidebarAgentRegistryMenu from '../SidebarAgentRegistryMenu';

const UserProfileBar = (props: any) => {
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const role = useSelector<any, UserRole>((state) => state?.auth?.role as UserRole);
  const menu = useMemo(() => SIDEBAR_MENU?.[role] || [], [role]);
  const toggleProfileMenuOpen = () => {
    setProfileMenuOpen(!profileMenuOpen);
  };

  const location = useLocation();
  const isActive = useCallback(
    (to: string): boolean =>
      ((location.pathname === to || location.pathname.startsWith(to)) && to !== '/') ||
      (to === '/' && location.pathname === to),
    [location]
  );

  const updateSelfAvailability = (status: AvailabilityStatus): void => {
    const { updateDoctorAvailability } = props;
    updateDoctorAvailability(status);
  };
  return (
    <div className={styles.userProfileBar}>
      <DropdownMenu
        className={styles.profileMenuDropdownMenu}
        isOpen={profileMenuOpen}
        toggle={() => toggleProfileMenuOpen()}
      >
        <DropdownToggle className={styles.profileMenuDropdownToggle}>
          <HasRole target={UserRole.ADMIN}>
            <div className={styles.profileName}>
              {getDisplayName(props.user.firstName, props.user.lastName, props.user.middleName)}
            </div>
            <div
              className={styles.profileImg}
              style={{ backgroundImage: `url(${decodeAvatarResource(props.user.avatar, 360, props.user.sex)})` }}
            />
          </HasRole>
          <HasRole target={UserRole.SUPPORT}>
            <div className={styles.profileName}>
              {getDisplayName(props.user.firstName, props.user.lastName, props.user.middleName)}
            </div>
            <div
              className={styles.profileImg}
              style={{ backgroundImage: `url(${decodeAvatarResource(props.user.avatar, 360, props.user.sex)})` }}
            />
          </HasRole>
          <HasRole target={UserRole.AGENT}>
            <div className={styles.profileName}>{props.user.email}</div>
            <div
              className={styles.profileImg}
              style={{ backgroundImage: `url(${decodeAvatarResource(props.user.avatar, 360, props.user.sex)})` }}
            />
          </HasRole>
          <HasRole target={UserRole.PATIENT}>
            <div className={styles.profileName}>{props.user.displayName}</div>
            <div
              className={styles.profileImg}
              style={{ backgroundImage: `url(${decodeAvatarResource(props.user.avatar, 360, props.user.sex)})` }}
            />
          </HasRole>
          <HasRole target={UserRole.DOCTOR}>
            <div className={styles.profileName}>{props.doctor.displayName}</div>
            <div
              className={styles.profileImg}
              style={{
                backgroundImage: `url(${decodeAvatarResource(props.doctor.avatar, 360, null)})`,
              }}
            />
            <DoctorStatusLabel
              style={{ position: 'absolute', bottom: 20, right: 10 }}
              status={props.doctor.availability}
            />
          </HasRole>
          <HasRole target={UserRole.PATIENT}>
            <AngleUpIcon className={styles.profileMenuIcon} />
          </HasRole>
        </DropdownToggle>
        <HasRole target={UserRole.PATIENT}>
          <DropdownContent className={styles.profileMenuDropdown}>
            <ul className={styles.menuList}>
              {menu.map(({ to, title, icon: Icon, iconType }, index) => (
                <li key={index} className="d-block d-xl-none">
                  <NavLink
                    exact
                    to={to}
                    className={styles.menuItem}
                    isActive={() => isActive(to)}
                    activeClassName={styles.menuItem_active}
                  >
                    <Icon
                      className={
                        iconType === '3'
                          ? styles.menuItemIcon3
                          : iconType === '2'
                          ? styles.menuItemIcon2
                          : styles.menuItemIcon
                      }
                    />
                    {title}
                  </NavLink>
                </li>
              ))}
              <li>
                <div className={styles.menuItem} onClick={() => props.userLogout()}>
                  <LogoutIcon className={styles.menuItemIcon} />
                  Выйти
                </div>
              </li>
            </ul>
          </DropdownContent>
        </HasRole>
        <HasRole target={UserRole.DOCTOR}>
          <DropdownContent className={styles.profileDoctorMenuDropdown}>
            <ul className={styles.menuListDoctor}>
              <li className="d-block">
                <div className={classNames(styles.menuItemDoctor, styles.menuItemDoctorLabel)}>
                  {props.user.displayName}
                </div>
              </li>
              <li className="d-block">
                <div className={classNames(styles.menuItemDoctor, styles.menuItemDoctorLabel)}>Статус</div>
              </li>
              {Object.values(AvailabilityStatus).map((status) => (
                <li key={status}>
                  <div onClick={() => updateSelfAvailability(status)} className={classNames(styles.menuItemDoctor)}>
                    <div className="d-flex justify-content-lg-start align-items-center">
                      <DoctorStatusLabel status={status} className="mr-2" />
                      {getNameStatusFromDoctorByStatus(status)}
                    </div>
                    {props.user.availability === status && <CheckIcon />}
                  </div>
                </li>
              ))}
              {menu.map(({ to, title, icon: Icon, iconType }, index) => (
                <li key={index} className="d-block d-xl-none">
                  <NavLink
                    exact
                    to={to}
                    className={styles.menuItem}
                    isActive={() => isActive(to)}
                    activeClassName={styles.menuItem_active}
                  >
                    <Icon
                      className={
                        iconType === '3'
                          ? styles.menuItemIcon3
                          : iconType === '2'
                          ? styles.menuItemIcon2
                          : styles.menuItemIcon
                      }
                    />
                    {title}
                  </NavLink>
                </li>
              ))}
              <li>
                <div className={classNames(styles.menuItem)} onClick={() => props.userLogout()}>
                  <LogoutIcon className={styles.menuItemIcon} />
                  Выйти
                </div>
              </li>
            </ul>
          </DropdownContent>
        </HasRole>
        <HasRole target={UserRole.ADMIN}>
          <DropdownContent className={styles.profileMenuDropdown}>
            <ul className={styles.menuList}>
              <li>
                <div className={styles.menuItem} onClick={() => props.userLogout()}>
                  <LogoutIcon className={styles.menuItemIcon} />
                  Выйти
                </div>
              </li>
            </ul>
          </DropdownContent>
        </HasRole>
        <HasRole target={UserRole.AGENT}>
          <DropdownContent className={styles.profileMenuDropdown}>
            <ul className={styles.menuList}>
              {menu.map(({ to, title, icon: Icon, iconType }, index) => (
                <li key={index} className="d-block d-xl-none">
                  <NavLink
                    exact
                    to={to}
                    className={styles.menuItem}
                    isActive={() => isActive(to)}
                    activeClassName={styles.menuItem_active}
                  >
                    <Icon
                      className={
                        iconType === '3'
                          ? styles.menuItemIcon3
                          : iconType === '2'
                          ? styles.menuItemIcon2
                          : styles.menuItemIcon
                      }
                    />
                    {title}
                  </NavLink>
                </li>
              ))}
              <SidebarAgentRegistryMenu userProfile />
              <li>
                <div className={styles.menuItem} onClick={() => props.userLogout()}>
                  <LogoutIcon className={styles.menuItemIcon} />
                  Выйти
                </div>
              </li>
            </ul>
          </DropdownContent>
        </HasRole>
        <HasRole target={UserRole.SUPPORT}>
          <DropdownContent className={styles.profileMenuDropdown}>
            <ul className={styles.menuList}>
              <li className="d-block d-xl-none">
                <NavLink
                  to="/doctors"
                  className={styles.menuItem}
                  isActive={() => isActive('/doctors')}
                  activeClassName={styles.menuItem_active}
                >
                  <DoctorIcon className={styles.menuItemIcon} />
                  Врачи
                </NavLink>
              </li>

              <li className="d-block d-xl-none">
                <NavLink to="/patients" className={styles.menuItem} activeClassName={styles.menuItem_active}>
                  <ProfileDoctorIcon className={styles.menuItemIcon} />
                  Пациенты
                </NavLink>
              </li>

              <li className="d-block d-xl-none">
                <NavLink to="/services" className={styles.menuItem} activeClassName={styles.menuItem_active}>
                  <MedCardIcon className={styles.menuItemIcon} />
                  Тарифы
                </NavLink>
              </li>
              <li>
                <div className={styles.menuItem} onClick={() => props.userLogout()}>
                  <LogoutIcon className={styles.menuItemIcon} />
                  Выйти
                </div>
              </li>
            </ul>
          </DropdownContent>
        </HasRole>
      </DropdownMenu>
    </div>
  );
};
export default connect(
  (state: any) => ({
    user: state.auth.userData,
    isDoctor: state.auth.isDoctor,
    doctor: state.doctor,
    permissions: state.auth.permissions,
  }),
  { userLogout, updateDoctorAvailability }
)(UserProfileBar);
