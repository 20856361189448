import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchDoctorsDuties, changeSetting } from 'store/actions';

// Components
import ContentLoading from 'components/ContentLoading';
import Button from 'components/Forms/Button';

import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Box } from '@material-ui/core';
import styles from '../HomePage.module.sass';
import { datePlurals, getFullDateFormat, getTimeBetween } from '../../../utils/date';
import { decodeAvatarResource, getWordNumEnding } from '../../../utils';
import { ReactComponent as HeartIcon } from '../../../assets/img/icons/heart-icon.svg';
import { ReactComponent as CommentIcon } from '../../../assets/img/icons/comment-icon.svg';
import { ReactComponent as CalendarIcon } from '../../../assets/img/icons/calendar-icon.svg';
import { ReactComponent as Avatar } from '../../../assets/img/icons/therapist-icon.svg';

const DoctorsDuties = (props: any) => {
  useEffect(() => {
    if (props.doctorsDuties.length === 0) {
      props.fetchDoctorsDuties();
    }
  }, []);

  const getEstimatedTime = (estimatedStartTime: string) => {
    const dateTimeUnits = getTimeBetween(new Date(estimatedStartTime), new Date());
    return (
      `В течение нескольких минут с Вами свяжется наш специалист. Предварительное время ожидания ` +
      `${dateTimeUnits.hours} ${getWordNumEnding(dateTimeUnits.hours, datePlurals.hours)}` +
      ` ${dateTimeUnits.minutes} ${getWordNumEnding(dateTimeUnits.minutes, datePlurals.minutes)}`
    );
  };

  const {
    doctorsDuties,
    doctorsDutiesLoading,
    doctorsDutiesLoadingError,

    fetchDoctorsDuties,
  } = props;

  return (
    <ContentLoading
      isLoading={doctorsDutiesLoading}
      isError={doctorsDutiesLoadingError}
      fetchData={() => fetchDoctorsDuties()}
    >
      {doctorsDuties.length === 0 && <div className="mb-3 mt-4 text-center">Не найдено</div>}
      <div className={styles.doctorsList}>
        {doctorsDuties.map((duty) => (
          <div
            key={`doctor-item-${duty?.id}`}
            className={classNames(styles.doctorsList_card, styles.doctorsList_card_duty)}
          >
            <div className={styles.doctorsList_card_wrapper}>
              <div className={styles.doctorsList_card_avatar}>
                <Avatar />
              </div>
              <div className={styles.doctorsList_card_about}>
                <div className={styles.doctorsList_card_name}>{duty?.name}</div>
                <div className={styles.doctorsList_card_descr}>{getEstimatedTime(duty?.estimatedStartTime)}</div>
                <div className={styles.doctorsList_card_price}>{duty?.price} ₽</div>
              </div>
            </div>

            <Button
              className={styles.doctorsList_card_btn}
              block
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                props.changeSetting('newAppointment', {
                  ...props.newAppointment,
                  price: duty?.price,
                  type: 'IMMEDIATE',
                  specialization: duty,
                  doctor: null,
                  date: null,
                  paymentViewType: 'PAYMENT',
                  estimatedStartTime: duty?.estimatedStartTime,
                });

                props.history.push(`/doctor/payment`);
              }}
            >
              Начать консультацию
            </Button>
          </div>
        ))}
      </div>
    </ContentLoading>
  );
};

export default connect(
  (state: any) => ({
    newAppointment: state.storage.newAppointment,

    doctorsDuties: state.doctors.doctorsDuties,
    doctorsDutiesLoading: state.doctors.doctorsDutiesLoading,
    doctorsDutiesLoadingError: state.doctors.doctorsDutiesLoadingError,
  }),
  { fetchDoctorsDuties, changeSetting }
)(DoctorsDuties);
