import { useContext, useMemo } from 'react';
import StoreContext from 'context/StoreContext';
import { StateInterface } from 'store';

import { useStore } from 'effector-react';

const useRootSelector = <T>(fn: (store: StateInterface) => T): T => {
  const store = useContext(StoreContext);
  const $store = useStore(store);
  return useMemo(() => fn($store), [fn, $store]);
};
export default useRootSelector;
