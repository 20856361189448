import {
  MANAGE_TRANSACTIONS_LOADING,
  MANAGE_TRANSACTIONS_SUCCESS,
  MANAGE_TRANSACTIONS_ERRORS,
  UPDATE_PSP_TRANSACTION_STATUS_SUCCESS,
  UPDATE_PSP_TRANSACTION_STATUS_ERRORS,
  UPDATE_PSP_TRANSACTION_STATUS_LOADING,
  MANAGE_TRANSACTION_STATUSES_LOADING,
  MANAGE_TRANSACTION_STATUSES_SUCCESS,
  MANAGE_TRANSACTION_STATUSES_ERRORS,
} from '../actions';

export interface ManageTransactionsInitialSettings {
  transactions?: any[];
  page?: number;
  pageSize?: number;
  totalItems?: number;
  hasMore?: boolean;
  errors?: any;
  loading?: boolean;
  transactionId?: string;
  statuses?: any[];
  loadingStatuses?: boolean;
  errorsStatuses?: any;
}

const initialSettings: ManageTransactionsInitialSettings = {
  transactions: [],
  page: 0,
  pageSize: 50,
  totalItems: 0,
  hasMore: false,
  loading: false,
  errors: null,
  transactionId: undefined,
  statuses: [],
  loadingStatuses: false,
  errorsStatuses: null,
};

const manageTransactionsReducer = (state = initialSettings, action): ManageTransactionsInitialSettings => {
  switch (action.type) {
    case MANAGE_TRANSACTIONS_LOADING:
      return {
        ...state,
        transactions: action.loading && action.reset ? [] : state.transactions,
        loading: action.loading,
      };
    case MANAGE_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactions: action.page > 0 ? state.transactions.concat(action.transactions) : action.transactions,
        page: action.page,
        pageSize: action.pageSize,
        totalItems: action.totalItems,
        hasMore: (action.page + 1) * action.pageSize < action.totalItems && action.totalItems > 0,
        errors: null,
      };
    case MANAGE_TRANSACTIONS_ERRORS:
      return {
        ...state,
        errors: action.errors,
      };
    case MANAGE_TRANSACTION_STATUSES_LOADING:
      return {
        ...state,
        loadingStatuses: action.loading,
      };
    case MANAGE_TRANSACTION_STATUSES_SUCCESS:
      return {
        ...state,
        statuses: action.statuses,
        errorsStatuses: null,
      };
    case MANAGE_TRANSACTION_STATUSES_ERRORS:
      return {
        ...state,
        errorsStatuses: action.errors,
      };
    case UPDATE_PSP_TRANSACTION_STATUS_LOADING:
      return {
        ...state,
        loading: action.loading,
        transactionId: action.id,
        errors: null,
      };
    case UPDATE_PSP_TRANSACTION_STATUS_ERRORS:
      return {
        ...state,
        errors: action.errors,
        transactionId: undefined,
      };
    case UPDATE_PSP_TRANSACTION_STATUS_SUCCESS:
      const id = state.transactionId;
      const modifiedIndex = state.transactions.findIndex((value) => value.id === id);
      const transactions = [...state.transactions];
      if (modifiedIndex > -1) {
        transactions[modifiedIndex].status = { name: null, value: action.status };
      }
      return {
        ...state,
        transactions,
        errors: null,
        transactionId: undefined,
      };
    default:
      return state;
  }
};

export default manageTransactionsReducer;
