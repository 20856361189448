import React, { useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import classNames from 'classnames';
import Manage from '../../types/manage';
import BaseDialog, { BaseDialogProps } from '../BaseDialog/BaseDialog';
import styles from './PartnerModifyDialog.module.sass';
import InputField from '../Forms/InputField';
import Button from '../Forms/Button';
import ContentLoading from '../ContentLoading';
import FormValidator from '../Forms/FormValidator';
import { useFormValidation } from '../../utils/hooks/useFormValidation';
import managePartnersApi from '../../api/partner/managePartners';
import { displayErrors, handleHttpError } from '../../utils/handleHttpError';

interface PartnerModifyDialogProps extends BaseDialogProps {
  onUpdated: (id: string) => void;
  editItem?: Manage.Partners.Item;
}

const PartnerModifyDialog: React.FC<PartnerModifyDialogProps> = (props) => {
  const { onClosed, onUpdated, editItem = {} } = props;

  const [isLoading, setLoading] = useState<boolean>(false);

  const [fields, validateOnChange, getFieldErrors, _, setFormErrors] = useFormValidation<Manage.Partners.EditRequest>({
    company: editItem.company,
    email: editItem.email,
    key: editItem.key,
    firstName: editItem.firstName,
    lastName: editItem.lastName,
    middleName: editItem.middleName,
  });

  const _onCreate = (): Promise<string> => {
    return managePartnersApi.createPartner(fields).then((response) => {
      if (response.data.code === 'success') {
        return response.data.data.id;
      }
      throw new Error(response.data.code);
    });
  };

  const _onUpdate = (id: string): Promise<string> => {
    return managePartnersApi.updatePartner(id, fields).then((response) => {
      if (response.data.code === 'success') {
        return id;
      }
      throw new Error(response.data.code);
    });
  };

  const _onSendData = () => {
    setLoading(true);
    const action = isNew ? _onCreate() : _onUpdate(editItem.id);
    action
      .then((res) => {
        onUpdated(res);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        const e = handleHttpError(error);
        displayErrors(e);
      });
  };

  const onSave = (e) => {
    e.preventDefault();
    const form = e.target;
    const inputs = [...form.elements].filter((i) => ['INPUT', 'SELECT', 'TEXTAREA'].includes(i.nodeName));

    const { hasError, errors } = FormValidator.bulkValidate(inputs);

    setFormErrors([...errors]);

    if (!hasError) {
      console.log(fields);
      _onSendData();
    } else {
      console.error(errors);
    }
  };

  const isNew: boolean = !editItem.id;

  return (
    <BaseDialog onClosed={onClosed} title={isNew ? 'Создать партнера' : 'Редактировать партнера'}>
      <ContentLoading isLoading={isLoading} isError={false} fetchData={() => null}>
        <form onSubmit={onSave}>
          <Box className={classNames(styles.PartnerModifyDialog_Content_Wrapper)}>
            <Box className={classNames(styles.PartnerModifyDialog_Content_Item)}>
              <Grid container justify="center" alignItems="center" spacing={2}>
                <Grid item xs={12}>
                  <InputField
                    name="company"
                    maxLength={255}
                    minLength={1}
                    value={fields.company}
                    onChange={(e) => validateOnChange('company', e.target.value, e)}
                    data-validate='["required"]'
                    errors={getFieldErrors('company')}
                    block
                    label="Организация"
                    placeholder="Организация"
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputField
                    disabled={!isNew}
                    name="email"
                    data-validate='["email", "required"]'
                    errors={getFieldErrors('email')}
                    value={fields.email}
                    onChange={(e) => validateOnChange('email', e.target.value, e)}
                    placeholder="эл. почта"
                    label="Эл. почта"
                    block
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputField
                    name="firstName"
                    maxLength={255}
                    minLength={1}
                    value={fields.firstName}
                    onChange={(e) => validateOnChange('firstName', e.target.value, e)}
                    data-validate='["required"]'
                    errors={getFieldErrors('firstName')}
                    block
                    label="Имя контакта"
                    placeholder="Имя"
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputField
                    name="lastName"
                    maxLength={255}
                    minLength={1}
                    value={fields.lastName}
                    onChange={(e) => validateOnChange('lastName', e.target.value, e)}
                    data-validate='["required"]'
                    errors={getFieldErrors('lastName')}
                    block
                    label="Фамилия контакта"
                    placeholder="Фамилия"
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputField
                    name="middleName"
                    maxLength={255}
                    minLength={1}
                    value={fields.middleName}
                    onChange={(e) => validateOnChange('middleName', e.target.value, e)}
                    data-validate='["required"]'
                    errors={getFieldErrors('middleName')}
                    block
                    label="Отчество контакта"
                    placeholder="Отчество"
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputField
                    disabled={!isNew}
                    name="key"
                    maxLength={10}
                    minLength={1}
                    value={fields.key}
                    onChange={(e) => validateOnChange('key', e.target.value, e)}
                    data-validate='["required"]'
                    errors={getFieldErrors('key')}
                    block
                    label="Идентификатор"
                    placeholder="Идентификатор"
                  />
                </Grid>
                <Grid item xs={12} container justify="space-around" alignItems="center" spacing={1}>
                  <Grid item xs={6} lg={6}>
                    <Button className="mr-2" color="default" onClick={onClosed}>
                      Отмена
                    </Button>
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <Button type="submit" color="primary" isLoading={isLoading}>
                      {isNew ? 'Создать' : 'Обновить'}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </form>
      </ContentLoading>
    </BaseDialog>
  );
};

export default PartnerModifyDialog;
