import {  
  APPOINTMENTS_LOADING,
  APPOINTMENTS_ERROR,
  APPOINTMENTS_SUCCESS
} from '../actions';


const initialSettings = {
  appointmentsList: [],
  appointmentsLoading: false,
  appointmentsLoadingError: false
};

const appointmentsReducer = (state = initialSettings, action) => {
  switch (action.type) {
    case APPOINTMENTS_LOADING:
      return {
        ...state,
        appointmentsLoading: action.isLoading
      };

    case APPOINTMENTS_ERROR:
      return {
        ...state,
        appointmentsLoadingError: action.isError
      };

    case APPOINTMENTS_SUCCESS:
      return {
        ...state,
        appointmentsList: action.reset ? [...action.appointments] : [
          ...state.appointmentsList,
          ...action.appointments
        ]
      };
      
    default:
      return state;
  }
};

export default appointmentsReducer;
