import React, { useState, useEffect, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { decodeAvatarResource, getWordNumEnding } from 'utils/helpers';
import { fetchDoctors, changeSetting, setDoctorsFilter } from 'store/actions';
import { doctorAddFavorite, doctorDeleteFavorite } from 'api';

import { toast } from 'react-toastify';
import InfiniteScroll from 'react-infinite-scroller';
import { RootStateOrAny, shallowEqual, useDispatch, useSelector } from 'react-redux';

import ContentLoading from 'components/ContentLoading';
import Button from 'components/Forms/Button';
import PageLoader from 'components/PageLoader';

import { ReactComponent as CommentIcon } from 'assets/img/icons/comment-icon.svg';
import { ReactComponent as CalendarIcon } from 'assets/img/icons/calendar-icon.svg';
import { ReactComponent as HeartIcon } from 'assets/img/icons/heart-icon.svg';
import { Box } from '@material-ui/core';
import { getFullDateFormat } from '../../../utils/date';
import styles from '../HomePage.module.sass';

const DoctorsList = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const handleFetchDoctors = useCallback((data: any) => dispatch(fetchDoctors(data)), [dispatch]);
  const handleChangeSetting = useCallback((value: any, data: any) => dispatch(changeSetting(value, data)), [dispatch]);
  const handleSetDoctorsFilter = useCallback((data: any) => dispatch(setDoctorsFilter(data)), [dispatch]);

  const { doctorsList, doctorsLoading, doctorsLoadingError, doctorsFilter } = useSelector(
    (state: RootStateOrAny) => ({
      doctorsList: state.doctors.doctorsList,
      doctorsLoading: state.doctors.doctorsLoading,
      doctorsLoadingError: state.doctors.doctorsLoadingError,
      doctorsFilter: state.doctors.doctorsFilter,
    }),
    shallowEqual
  );

  const [targetDoctorsList, setTargetDoctorsList] = useState([...doctorsList]);

  useEffect(() => {
    setTargetDoctorsList([...doctorsList]);
  }, [doctorsList]);

  const loadDoctors = useCallback(() => {
    if (!doctorsLoading) {
      handleFetchDoctors({ ...doctorsFilter });
    }
  }, [doctorsFilter, doctorsLoading, handleFetchDoctors]);

  useEffect(() => {
    if (doctorsList.length === 0 && doctorsFilter.initialLoad) {
      loadDoctors();
    }

    return () => {
      setDoctorsFilter({
        ...doctorsFilter,
        initialLoad: true,
      });
    };
  }, [doctorsFilter, doctorsList.length, loadDoctors]);

  useEffect(() => {
    if (doctorsFilter.updateLoad && !doctorsFilter.initialLoad) {
      loadDoctors();
    }
  }, [doctorsFilter, loadDoctors]);

  const changeDoctorFavorite = useCallback(
    (id: string) => {
      const doctorsListTemp = [...targetDoctorsList];

      const doctorIndex = doctorsListTemp.findIndex((doctor) => doctor.id === id);

      if (doctorIndex >= 0) {
        if (doctorsListTemp[doctorIndex].favorite) {
          doctorDeleteFavorite(id)
            .then(() => {
              toast.success('Доктор успешно удален из избранного');

              doctorsListTemp[doctorIndex].favorite = false;

              setTargetDoctorsList([...doctorsListTemp]);
            })
            .catch(() => {
              toast.error('Ошибка при удалении из избранного');
            });
        } else {
          doctorAddFavorite(id)
            .then(() => {
              toast.success('Доктор успешно добавлен в избранное');

              doctorsListTemp[doctorIndex].favorite = true;

              setTargetDoctorsList([...doctorsListTemp]);
            })
            .catch(() => {
              toast.error('Ошибка при добавлении в избранное');
            });
        }
      }
    },
    [targetDoctorsList]
  );

  return (
    <ContentLoading
      isLoading={doctorsFilter.page === 0 && doctorsLoading}
      isError={doctorsLoadingError}
      loadingOverlay
      fetchData={() => {
        handleSetDoctorsFilter({
          ...doctorsFilter,
          page: 0,
          hasMore: true,
          updateLoad: true,
        });

        handleFetchDoctors({
          ...doctorsFilter,
          page: 0,
          hasMore: true,
          updateLoad: true,
        });
      }}
    >
      {!doctorsLoading && doctorsList.length === 0 ? <div className="mb-3 mt-md-4 text-center">Не найдено</div> : null}

      <InfiniteScroll
        key="infinity-scroll"
        className={styles.doctorsList_infinitescroll}
        pageStart={0}
        hasMore={doctorsLoading ? false : doctorsFilter.hasMore}
        initialLoad={false}
        threshold={50}
        loadMore={loadDoctors}
        loader={doctorsFilter.page !== 0 ? <PageLoader key="page-loader" /> : null}
      >
        <div key="doctor-items" className={styles.doctorsList}>
          {targetDoctorsList.map((doctor) => (
            <Link key={`doctor-link-${doctor?.id}`} to={`/doctor/${doctor.id}/profile`}>
              <div key={`doctor-item-${doctor?.id}`} className={styles.doctorsList_card}>
                <div
                  className={classNames(styles.doctorsList_card_favorite, {
                    [styles.doctorsList_card_favorite_active]: doctor.favorite,
                  })}
                >
                  <HeartIcon
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      changeDoctorFavorite(doctor.id);
                    }}
                  />
                </div>
                <div className={styles.doctorsList_card_wrapper}>
                  <div className={styles.doctorsList_card_avatar}>
                    <Box
                      style={{
                        backgroundImage: `url(${decodeAvatarResource(doctor.avatar, 360)})`,
                      }}
                    />
                  </div>
                  <div className={styles.doctorsList_card_about}>
                    <div className={styles.doctorsList_card_name}>
                      {doctor.firstName} {doctor.middleName} {doctor.lastName}
                    </div>
                    <div className={styles.doctorsList_card_descr}>
                      {doctor.specialization && doctor.specialization.name}, {doctor.seniority}{' '}
                      {getWordNumEnding(doctor.seniority, ['год', 'года', 'лет'])} опыта
                    </div>
                    {doctor.additionalServiceTypes?.length > 0 && (
                      <div className={styles.doctorsList_card_descr}>
                        {doctor.additionalServiceTypes.map((value) => value.name).join(',')}
                      </div>
                    )}
                    <div className={styles.doctorsList_card_reviews}>
                      <CommentIcon /> {doctor.publicFeedbackCount}{' '}
                      {getWordNumEnding(doctor.publicFeedbackCount, ['отзыв', 'отзыва', 'отзывов'])}
                    </div>
                  </div>
                </div>

                <Button
                  className={styles.doctorsList_card_btn}
                  block
                  color="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    handleChangeSetting('newAppointment', {
                      price: doctor.price,
                      date: doctor.nearestAppointmentAt,
                      type: 'PLANNED',
                      doctor,
                      specialization: doctor.specialization,
                      paymentViewType: 'PAYMENT',
                    });

                    history.push(`/doctor/schedule`);
                  }}
                >
                  <Box mr={2}>
                    <CalendarIcon />
                  </Box>
                  <Box>
                    Запись на&nbsp;
                    {doctor.nearestAppointmentAt
                      ? getFullDateFormat(new Date(doctor.nearestAppointmentAt))
                      : 'консультацию'}
                  </Box>
                </Button>
              </div>
            </Link>
          ))}
        </div>
      </InfiniteScroll>
    </ContentLoading>
  );
};

export default DoctorsList;
