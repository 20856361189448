import { ClientState, getInstance } from 'voximplant-websdk';

export interface VoxImplantClientConfig {
  enableDebugLogging?: boolean;
  imAutoReconnect?: boolean;
  imReconnectInterval?: number;
  imXSSprotection?: boolean;
  micRequired?: boolean;
  progressTone?: boolean;
  progressToneCountry?: string;
  enableTrace?: boolean;
  showDebugInfo?: boolean;
  showWarnings?: boolean;
  videoConstraints?: any | boolean;
  serverIp?: Array<string> | string;
  videoContainerId?: string;
  remoteVideoContainerId?: string;
  localVideoContainerId?: string;
  videoSupport?: boolean;
  protocolVersion?: string;
  H264first?: boolean;
  rtcStatsCollectionInterval?: number;
  VP8first?: boolean;
  prettyPrint?: boolean;
  experiments?: any;
  tryingServers?: Array<string>;
  queueType?: any;
}

export const voximplantManager = (config: VoxImplantClientConfig) => {
  const _voxImplantInstance = getInstance();
  _voxImplantInstance.init(config);

  const getClient = () => {
    return _voxImplantInstance;
  };

  const isLoggedIn = (): boolean => {
    return _voxImplantInstance.getClientState() === ClientState.LOGGED_IN;
  };
  return {
    getClient,
    isLoggedIn,
  };
};
const manager = voximplantManager({
  micRequired: true,
  videoSupport: true,
  progressTone: true,
});
export default manager;
