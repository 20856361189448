import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import { Backdrop, Box, Dialog, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { toast } from 'react-toastify';
import Manage from '../../types/manage';
import styles from './AdminServicePackageGroupEditView.module.sass';
import ContentCard from '../ContentCard';
import ContentLoading from '../ContentLoading';
import InputField from '../Forms/InputField';
import SelectInput from '../Forms/SelectInput';
import Button from '../Forms/Button';
import { useEditServicePackageGroup } from './hooks/useEditServicePackageGroup';
import { getFieldErrors as getFieldErrorsUtil, getFieldErrors, validateFieldOnChange } from '../../utils';
import FormValidator from '../Forms/FormValidator';
import { displayErrors, HttpError } from '../../utils/handleHttpError';

interface AdminServicePackageGroupEditViewProps {
  data?: Manage.ServicePackages.Groups.Item;
  onUpdated: () => void;
  onCancel: () => void;
}

const AdminServicePackageGroupEditView: React.FC<AdminServicePackageGroupEditViewProps> = (props) => {
  const { data, onCancel, onUpdated } = props;

  const onRequestFailed = useCallback((error: HttpError) => {
    console.error(error);
    displayErrors(error);
  }, []);

  const [model, onRequestChanged, isLoading, onSendRequest, onDelete, error] = useEditServicePackageGroup(
    onUpdated,
    onRequestFailed,
    data
  );

  const [formErrors, setFormErrors] = useState<any>([]);

  const validateOnChange = (name: string, value: any, event, element?: any) => {
    validateFieldOnChange(name, value, event, model, onRequestChanged, formErrors, setFormErrors, element);
  };
  const getFieldErrors = (field: string) => getFieldErrorsUtil(field, formErrors);

  const onSave = (e) => {
    e.preventDefault();
    const form = e.target;
    const inputs = [...form.elements].filter((i) => ['INPUT', 'SELECT', 'TEXTAREA'].includes(i.nodeName));

    const { errors, hasError } = FormValidator.bulkValidate(inputs);

    setFormErrors([...errors]);

    if (!hasError) {
      onSendRequest();
    }
  };

  const onFetch = () => {};

  const onDescriptionChanged = (event) => {
    const text = event.target.value;
    onRequestChanged({
      ...model,
      description: text,
    });
  };

  const onExternalKeyChanged = (event) => {
    const text = event.target.value;
    onRequestChanged({
      ...model,
      externalKey: text,
    });
  };

  const isNew = !data || !data.id;
  const isCanBeDeleted = !isNew && !data.tech;

  return (
    <Dialog
      PaperProps={{ className: classNames(styles.Dialog) }}
      BackdropComponent={(props) => <Backdrop className={styles.Backdrop} {...props} />}
      onClose={onCancel}
      open
    >
      <ContentCard className={styles.AdminServicePackageGroupEditView}>
        <ContentCard.Header className={styles.AdminServicePackageGroupEditView_Header}>
          <h6 className="page-header-title">{isNew ? 'Создайте группу' : 'Редактировать группу'}</h6>
          <CloseIcon onClick={onCancel} className={styles.AdminServicePackageGroupEditView_CloseIcon} />
        </ContentCard.Header>
        <ContentCard.Main className={styles.AdminServicePackageGroupEditView_Content}>
          <ContentLoading isLoading={isLoading} isError={false} fetchData={onFetch}>
            <form onSubmit={onSave}>
              <Box className={classNames(styles.AdminServicePackageGroupEditView_Content_Wrapper)}>
                <Box className={classNames(styles.AdminServicePackageGroupEditView_Content_Item)}>
                  <Grid
                    className={classNames(styles.AdminServicePackageGroupEditView_Content_Item_Container)}
                    container
                    justify="center"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item xs={12}>
                      <InputField
                        name="name"
                        maxLength={255}
                        minLength={1}
                        value={model.name}
                        onChange={(e) => validateOnChange('name', e.target.value, e)}
                        data-validate='["required"]'
                        errors={getFieldErrors('name')}
                        block
                        label="Название"
                        placeholder="Название"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputField
                        type="textarea"
                        name="description"
                        maxLength={2048}
                        rows={3}
                        value={model.description}
                        onChange={onDescriptionChanged}
                        block
                        label="Описание"
                        placeholder="Описание"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputField
                        name="externalKey"
                        maxLength={255}
                        minLength={1}
                        value={model.externalKey}
                        onChange={onExternalKeyChanged}
                        block
                        label="Внешний ключ"
                        placeholder="Внешний ключ"
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container justify="space-around" alignItems="center">
                    <Button type="submit" color="primary" isLoading={isLoading} block={!isCanBeDeleted}>
                      Сохранить
                    </Button>
                    {isCanBeDeleted && !isLoading && (
                      <Button onClick={onDelete} type="button" color="alert" outline>
                        Удалить
                      </Button>
                    )}
                  </Grid>
                </Box>
              </Box>
            </form>
          </ContentLoading>
        </ContentCard.Main>
      </ContentCard>
    </Dialog>
  );
};

export default AdminServicePackageGroupEditView;
