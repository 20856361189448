import axios from 'utils/axios';

export const authPhoneCodes = (phone: string, gRecaptcha: string) => {
  return axios.post('/api/v1/auth/phones/codes', {
    phone,
    gRecaptcha,
  });
};

export const authPhoneCodesAgent = (phone: string) => {
  return axios.post('/api/v1/agent/codes', {
    phone,
  });
};
