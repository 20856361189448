import axios from 'utils/axios';
import Manage from "../types/manage";
import {AxiosResponse} from "axios";
import {ResponseData} from "../types";

class manageServicePackageLimits {

  static  updateLimits = (id: string, request: Manage.ServicePackages.Mfo.GroupLimitsRequest): Promise<AxiosResponse<ResponseData<boolean>>> => {
    return axios.put(`/api/v1/servicepackages/mfo/${id}/limit`, request)
  }

  static getLimits = (id: string): Promise<AxiosResponse<ResponseData<Manage.ServicePackages.Mfo.GroupLimitsResponse>>> => {
    return axios.get(`/api/v1/servicepackages/mfo/${id}/limit`)
  }
}

export default manageServicePackageLimits

