import React, { useCallback } from 'react';
import { Scheduler, MonthView, DayView, WeekView } from '@devexpress/dx-react-scheduler-material-ui';
import {
  isSameDay,
  addHours,
  isWithinInterval,
  isBefore,
  setSeconds,
  setMinutes,
  differenceInMinutes,
  isSameHour,
} from 'date-fns';
import { Box, TableCell, Tooltip } from '@material-ui/core';
import { ViewState } from '@devexpress/dx-react-scheduler';
import classNames from 'classnames';
import DeleteIcon from '@material-ui/icons/Delete';
import { toast } from 'react-toastify';
import { format } from '../../utils/date';
import styles from './Scheduler.module.sass';
import { Scheduler as SchedulerType, Appointment } from '../../types';

export type SchedulerView = 'TODAY' | 'WEEK';
export interface SchedulerPropsInterface {
  className?: any;
  date?: Date;
  view?: SchedulerView;
  scheduler?: SchedulerType[];
  appointments?: Appointment[];
  onClickCell?: (schedule: SchedulerType) => void;
  onDelete?: (schedule: SchedulerType) => void;
  onChangeDate?: (event: Date) => any;
  [key: string]: any;
}
export default (props: SchedulerPropsInterface) => {
  const {
    date = new Date(),
    onChangeDate,
    view = 'TODAY',
    scheduler = [],
    appointments = [],
    onClickCell = () => {},
    onDelete = () => {},
  } = props;
  const isCurrentDay = (day: Date): boolean => {
    return isSameDay(day, date);
  };
  const onChange = (value: Date) => {
    onChangeDate(value);
  };
  const onDeleteScheduler = (startTime: Date, endTime: Date) => {
    const data = getActiveSchedulerCellByTime(startTime, endTime);
    if (data) {
      onDelete(data);
    } else {
      toast.warn('Смена не может быть удалена, так как время начала смены раньше текущего времени.');
    }
  };
  const isActiveSchedulerCell = (startTime: Date, endTime: Date) => {
    return scheduler.some((s: SchedulerType) => {
      const { startTime: start, endTime: end } = s;
      return (
        (isSameHour(startTime, start) || isWithinInterval(startTime, { start, end })) &&
        (isSameHour(endTime, end) || isWithinInterval(endTime, { start, end }))
      );
    });
  };
  const isActiveFirstSchedulerCell = (startTime: Date, endTime: Date) => {
    return (
      isActiveSchedulerCell(startTime, endTime) &&
      scheduler.some((s: SchedulerType) => {
        const { startTime: start } = s;
        return isActiveSchedulerCell(startTime, endTime) && isSameHour(startTime, start);
      })
    );
  };
  const isSchedulerHasAppointments = (scheduler: SchedulerType): boolean => {
    if (!scheduler) {
      return false;
    }
    const { startTime: start, endTime: end } = scheduler;
    return appointments.some(
      ({ startTime, appointment, busy }) =>
        isWithinInterval(startTime, { start, end }) && busy === true && appointment != null
    );
  };
  const getAppointments = (scheduler: SchedulerType): Appointment => {
    if (!scheduler) {
      return null;
    }
    const { startTime: start, endTime: end } = scheduler;
    const _appointments: Appointment[] = appointments.filter(
      ({ startTime, appointment, busy }) =>
        isWithinInterval(startTime, { start, end }) &&
        !isSameHour(startTime, end) &&
        busy === true &&
        appointment != null
    );
    return _appointments.length ? _appointments[0] : null;
  };
  const getActiveSchedulerCellByTime = (startTime: Date, endTime: Date): SchedulerType => {
    return scheduler.find((s: SchedulerType) => {
      const { startTime: start } = s;
      return isActiveSchedulerCell(startTime, endTime) && isSameHour(startTime, start);
    });
  };
  const getCellTitle = (startTime: Date, endTime: Date): JSX.Element => {
    const schedule: SchedulerType = getActiveSchedulerCellByTime(startTime, endTime);
    if (schedule) {
      if (!isSchedulerHasAppointments(schedule) && isActiveFirstSchedulerCell(startTime, endTime)) {
        return (
          <>
            <Box m={0}>Рабочая смена</Box>
            <Box m={0}>
              {format(schedule.startTime, 'HH:mm')} - {format(schedule.endTime, 'HH:mm')}
            </Box>
          </>
        );
      }
      if (isSchedulerHasAppointments(schedule)) {
        const app: Appointment = getAppointments({ startTime, endTime });
        if (!app) {
          return <></>;
        }
        return (
          <>
            <Box m={0}>Консультация под запись</Box>
          </>
        );
      }
    }
    return <></>;
  };
  const renderCell = useCallback(
    (args: DayView.TimeTableCellProps): JSX.Element => {
      const isActive: boolean = isActiveSchedulerCell(args.startDate, args.endDate);
      const isActiveFirst = isActiveFirstSchedulerCell(args.startDate, args.endDate);
      const activeScheduler: SchedulerType = getActiveSchedulerCellByTime(args.startDate, args.endDate);
      return (
        <TableCell
          tabIndex={0}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            if (!isActive && !isBefore(args.startDate, new Date())) {
              onClickCell({
                id: null,
                startTime: setMinutes(setSeconds(args.startDate, 0), 0),
                endTime: addHours(setMinutes(setSeconds(args.startDate, 0), 0), 2),
              });
            }
          }}
          className={classNames(styles.Scheduler_TimeCell, isActive && styles.Scheduler_TimeCell_Active)}
        >
          {isActiveFirst && <Box className={styles.Scheduler_TimeCell_Active_First} />}
          {isWithinInterval(new Date(), {
            start: setMinutes(setSeconds(args.startDate, 0), 0),
            end: setMinutes(setSeconds(args.endDate, 0), 0),
          }) && (
            <Box
              className={classNames(styles.Scheduler_TimeCell_Line)}
              style={{ top: `${Math.abs((differenceInMinutes(args.startDate, new Date()) * 100) / 60)}%` }}
            >
              <Box className={classNames(styles.Scheduler_TimeCell_Line_Left)} />
              <Box className={classNames(styles.Scheduler_TimeCell_Line_Right)} />
            </Box>
          )}
          <Box className={classNames(styles.Scheduler_TimeCell_Content)}>
            <Box className={classNames(styles.Scheduler_TimeCell_Content_Title)}>
              <Box className={classNames(styles.Scheduler_TimeCell_Content_Title_Label)}>
                {getCellTitle(args.startDate, args.endDate)}
              </Box>
            </Box>
            <Box className={classNames(styles.Scheduler_TimeCell_Content_Actions)}>
              {isActiveFirst && !isSchedulerHasAppointments(activeScheduler) && (
                <Box
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    onDeleteScheduler(args.startDate, args.endDate);
                  }}
                  className={classNames(styles.Scheduler_TimeCell_Content_Actions_Button)}
                >
                  <Tooltip title="Удалить смену" placement="top">
                    <DeleteIcon className={classNames(styles.Scheduler_TimeCell_Content_Actions_Button_Icon)} />
                  </Tooltip>
                </Box>
              )}
            </Box>
          </Box>
        </TableCell>
      );
    },
    [date, view]
  );
  const renderWeek = useCallback(
    (props: MonthView.DayScaleCellProps): JSX.Element => {
      return (
        <>
          <TableCell
            className={styles.Scheduler_DayScaleCell}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              !isSameDay(date, props.startDate) && onChange(props.startDate);
            }}
          >
            <Box
              flexDirection="row"
              justifyContent={view === 'WEEK' ? 'center' : 'flex-start'}
              alignItems="center"
              display="flex"
            >
              <Box
                width="auto"
                flexDirection="column"
                justifyContent="space-between"
                alignItems="center"
                display="flex"
              >
                <Box className={classNames(styles.Scheduler_DayScaleCell_Day_Week)}>
                  {format(props.startDate, 'EEEEEE').toUpperCase()}
                </Box>
                <Box
                  className={classNames(
                    styles.Scheduler_DayScaleCell_Day,
                    isCurrentDay(props.startDate) && styles.Scheduler_DayScaleCell_Day_Current
                  )}
                  textAlign="center"
                >
                  {format(props.startDate, 'd')}
                </Box>
              </Box>
            </Box>
          </TableCell>
        </>
      );
    },
    [date, view]
  );
  return (
    <Box className={styles.Scheduler}>
      <Scheduler locale="ru" firstDayOfWeek={1}>
        <ViewState currentDate={date} currentViewName={view} />
        <WeekView
          timeTableCellComponent={renderCell}
          dayScaleCellComponent={renderWeek}
          name="WEEK"
          cellDuration={60}
          startDayHour={0}
          endDayHour={24}
        />
        <DayView timeTableCellComponent={renderCell} name="TODAY" cellDuration={60} startDayHour={0} endDayHour={24} />
      </Scheduler>
    </Box>
  );
};
