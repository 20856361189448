import Manage from "../../../../../types/manage";
import styles from "../SettingsPageAdminSpecGroups.module.sass"
import React, {useCallback} from "react";
import {Box, Grid} from "@material-ui/core";
import classNames from "classnames";
import Button from "../../../../../components/Forms/Button";

interface AdminSpecGroupItemViewProps {
  item: Manage.Spec.Group
  onEdit: (item: Manage.Spec.Group) => void

}

const AdminSpecGroupItemView: React.FC<AdminSpecGroupItemViewProps> = (props) => {

  const {item, onEdit} = props

  const onEditClick = useCallback(() => {
    onEdit(item)
  }, [onEdit])

  const isActive = item.status.value === 'ACTIVE'

  return (
    <Box key={`${item.id}`} className={classNames(styles.SettingsPageAdminSpecGroups_Groups_Group)}>
      <Box className={classNames(styles.SettingsPageAdminSpecGroups_Groups_Group_Data)}>
        <Box className={classNames(styles.SettingsPageAdminSpecGroups_Groups_Group_Data_Name)}>
          {item.name}
        </Box>
        <Box className={classNames(styles.SettingsPageAdminSpecGroups_Groups_Group_Data_Description)}>
          {item.description}
        </Box>
        <Box className={classNames(styles.SettingsPageAdminSpecGroups_Groups_Group_Data_Status,
          isActive && styles.SettingsPageAdminSpecGroups_Groups_Group_Data_Status_Active)}>
          {item.status.name}
        </Box>
        <Box className={classNames(styles.SettingsPageAdminSpecGroups_Groups_Group_Data_Specialization)}>
          {item.specializations.map(value => value.name).join(' ')}
        </Box>
      </Box>
      <Box className={classNames(styles.SettingsPageAdminSpecGroups_Groups_Group_Actions)}>
        <Button size="sm" color="default" onClick={onEditClick}>
          Редактировать
        </Button>
      </Box>
    </Box>
  )

}

export default AdminSpecGroupItemView
