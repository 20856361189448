import React, { forwardRef, useCallback, useState } from 'react';
import { InputError, Maybe } from 'types';
import MaskedInput from 'react-input-mask';
import './styles.module.sass';
import ru from 'date-fns/locale/ru';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import classNames from 'classnames';
import { Box, styled } from '@material-ui/core';
import { DateRangeIcon } from '@material-ui/pickers/_shared/icons/DateRangeIcon';
import { isValid } from 'date-fns';
import FormLabel from '../FormLabel';
import FormInvalidMessage from '../FormInvalidMessage';

type DateFieldProps = {
  value?: Maybe<Date>;
  locale?: Maybe<any>;
  invalid?: boolean;
  label?: Maybe<string>;
  onChange?: (data: Date) => any;
  dateFormat?: Maybe<string | string[]>;
  errors?: InputError[];
  className?: Maybe<string>;
  'data-validate'?: Maybe<any>;
};
const InputBoxStyled = styled(Box)(() => ({
  position: 'relative',
}));
const InputStyled = styled((props) => <MaskedInput mask="99.99.9999" {...props} />)(() => ({
  '&:focus': {
    '& + svg': {
      color: '#1473FF',
    },
  },
}));
const DateRangeIconStyled = styled(DateRangeIcon)(() => ({
  position: 'absolute',
  right: 10,
  color: '#B6E0FF',
}));
const DateField = (props: DateFieldProps & Omit<ReactDatePickerProps, keyof DateFieldProps>) => {
  const {
    value,
    label,
    minDate = new Date('01.01.1900'),
    dateFormat = 'dd.MM.yyyy',
    locale = ru,
    invalid = false,
    onChange = () => {},
    errors = [],
    className,
    showYearDropdown = true,
    dateFormatCalendar = 'MMMM',
    yearDropdownItemNumber = 10000,
    scrollableYearDropdown = true,
    ...rest
  } = props;
  const CustomInput = forwardRef(({ onClick, value, onChange, ...rest }: any, ref) => {
    const [targetValue, setTargetValue] = useState(value);
    const handleChange = useCallback((e) => {
      setTargetValue(e?.target?.value);
    }, []);
    const handleBlur = useCallback(
      (e) => {
        const valid = isValid(new Date(targetValue));
        valid && onChange({ ...e, target: { value: targetValue } });
        !valid && setTargetValue(value);
      },
      [onChange, targetValue, value]
    );
    return (
      <InputBoxStyled innerRef={ref} className="d-flex align-items-center justify-content-between" onClick={onClick}>
        <InputStyled
          {...rest}
          onBlur={handleBlur}
          value={targetValue}
          onChange={handleChange}
          className={classNames('fg-input input-block', { 'is-invalid': invalid || errors?.length > 0 })}
        />
        <DateRangeIconStyled />
      </InputBoxStyled>
    );
  });
  return (
    <>
      {label && <FormLabel invalid={invalid || (errors && errors.length > 0)}>{label}</FormLabel>}
      <DatePicker
        minDate={minDate}
        dateFormat={dateFormat}
        className={classNames(className)}
        selected={value}
        onChange={onChange}
        showYearDropdown={showYearDropdown}
        dateFormatCalendar={dateFormatCalendar}
        yearDropdownItemNumber={yearDropdownItemNumber}
        scrollableYearDropdown={scrollableYearDropdown}
        customInput={<CustomInput />}
        locale={locale}
        {...rest}
      />
      <FormInvalidMessage>{errors?.[0]?.message}</FormInvalidMessage>
    </>
  );
};

export default DateField;
