import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Lightbox } from 'react-modal-image';
import { Box } from '@material-ui/core';
import InputField from 'components/Forms/InputField';
import Button from 'components/Forms/Button';
import SelectInput from 'components/Forms/SelectInput';
import range from 'lodash/range';
import styles from './AppointmentDoctorReport.module.sass';
import { AppointmentStatus, EIMKDictionary, EIMKKey, EIMKKeyTranslate } from '../../types';
import { onCloseAppointment, onGetIemk } from '../../store/events';
import useRootSelector from '../../../../hooks/useRootSelector';
import {
  selectAppointmentDoctorAppointment,
  selectAppointmentDoctorDictionaries,
  selectAppointmentDoctorLoadingReport,
} from '../../store/selectors';

const AppointmentDoctorReport = () => {
  const loading = useRootSelector(selectAppointmentDoctorLoadingReport);
  const appointment = useRootSelector(selectAppointmentDoctorAppointment);
  const [inputFile, setInputFile] = useState(null);
  const doctorDictionaries = useRootSelector(selectAppointmentDoctorDictionaries);
  const [previewAttachment, setAttachmentPreview] = useState(null);
  const [anamnesis, setAnamnesis] = useState('');
  const [dictionaries, setDictionaries] = useState([]);
  const [recommendations, setRecommendations] = useState('');
  const subject = useMemo(() => appointment.subject, [appointment.subject]);
  const status = useMemo(() => appointment?.status?.value, [appointment?.status?.value]);
  const [files, setFiles] = useState<File[]>([]);
  useEffect(() => {
    setRecommendations((recommendations) =>
      recommendations !== appointment?.recommendations && appointment?.recommendations
        ? appointment?.recommendations || ''
        : recommendations
    );
    setAnamnesis((anamnesis) =>
      anamnesis !== appointment?.anamnesis && appointment?.anamnesis ? appointment?.anamnesis || '' : anamnesis
    );
  }, [appointment, recommendations]);
  const attachments = useMemo(
    () => [
      ...(appointment?.attachmentsIn || []),
      ...(appointment?.attachmentsOut || []),
      ...(appointment?.conclusions || []),
    ],
    [appointment.attachmentsIn, appointment.attachmentsOut, appointment.conclusions]
  );
  const isImages = useCallback(
    (attachment: any) => attachment?.contentType && attachment?.contentType?.search('image') > -1,
    []
  );
  const isDone = useMemo((): boolean => {
    return status === AppointmentStatus.DONE;
  }, [status]);
  const isWaitingFilling = useMemo((): boolean => {
    return status === AppointmentStatus.WAITING_FILLING;
  }, [status]);
  const handlePreviewAttachment = useCallback(
    (attachment: any) => {
      if (!isImages(attachment)) {
        window.open(attachment?.originalUrl, '_blank').focus();
      } else {
        setAttachmentPreview(attachment);
      }
    },
    [isImages]
  );
  const handleClosePreviewAttachment = useCallback(() => {
    setAttachmentPreview(null);
  }, []);
  const disabled = useMemo(
    () => [AppointmentStatus.IN_PROGRESS, AppointmentStatus.FINISHED].indexOf(status) === -1,
    [status]
  );
  const onChangeFile = useCallback(
    (event: any): void => {
      const fileList: FileList = event.target.files;
      const count = fileList.length > 5 ? 5 : fileList.length;
      setFiles(files.concat(Array.from(Array(count).keys()).map((i) => fileList.item(i))).slice(0, 5));
      inputFile.value = '';
    },
    [files, inputFile]
  );
  const appointmentId = useMemo(() => appointment?.id, [appointment?.id]);
  const handleClose = useCallback(
    () => onCloseAppointment({ appointmentId, files, anamnesis, recommendations, dictionaries }),
    [anamnesis, appointmentId, dictionaries, files, recommendations]
  );
  const getOptions = useCallback(
    (key: string) =>
      (doctorDictionaries?.[EIMKDictionary?.[key]] || []).map(({ code, display }) => ({ label: display, value: code })),
    [doctorDictionaries]
  );
  const getValue = useCallback(
    (code: string) => {
      const dictionary = dictionaries.find(({ key }) => key === code);
      return dictionary ? { label: dictionary?.value, value: dictionary?.code } : null;
    },
    [dictionaries]
  );
  const handleChange = useCallback(
    (key: string, { label, value }) => {
      setDictionaries((dictionaries) => {
        const newValue = {
          key,
          dictionary: EIMKDictionary?.[key],
          code: value,
          value: label,
          selectedValue: value,
        };
        return getValue(key) ? dictionaries.map((_) => (_.key !== key ? _ : newValue)) : dictionaries.concat(newValue);
      });
    },
    [getValue]
  );
  const showDictionaries = useMemo(() => [AppointmentStatus.WAITING_FILLING].indexOf(status) > -1, [status]);
  useEffect(() => {
    showDictionaries && onGetIemk();
  }, [showDictionaries]);
  const canClose = useMemo(
    () =>
      (!disabled && anamnesis.length > 0 && recommendations.length > 0) ||
      (isWaitingFilling && Object.values(EIMKKey).every((k) => getValue(k))),
    [anamnesis.length, disabled, getValue, isWaitingFilling, recommendations.length]
  );
  const handleDelete = useCallback(
    (index?: number) => {
      setFiles(files.filter((_, i) => i !== index));
    },
    [files]
  );
  const renderSelect = useCallback(
    (key: string) => {
      return (
        <React.Fragment key={key}>
          <Box className={styles.root_label}>{EIMKKeyTranslate?.[key]}</Box>
          <Box className={styles.root_select}>
            <SelectInput
              block
              placeholder={EIMKKeyTranslate?.[key]}
              disabled={loading}
              onChange={(option) => handleChange(key, option)}
              name={key}
              value={getValue(key)}
              options={getOptions(key)}
            />
          </Box>
        </React.Fragment>
      );
    },
    [getOptions, getValue, handleChange, loading]
  );
  return (
    <>
      <form
        className={styles.root}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Box className={styles.root_label}>Жалоба пациента</Box>
        <Box className={styles.root_subject}>{subject}</Box>
        <Box className={styles.root_label}>Вложенные файлы</Box>
        <Box className={styles.root_attachments}>
          <>
            {attachments.map((attachment: any, index: number) => (
              <Box
                key={index}
                onClick={() => handlePreviewAttachment(attachment)}
                className={styles.root_attachments_item}
              >
                <Box className={styles.root_attachments_item_icon}>
                  <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11.868 17.1001H2.13156C1.56644 17.0994 1.02466 16.8746 0.625064 16.475C0.225466 16.0754 0.000675439 15.5336 0 14.9685V2.13155C0.000675439 1.56644 0.225466 1.02466 0.625064 0.625064C1.02466 0.225466 1.56644 0.000675504 2.13156 6.54954e-08H8.51644C8.60024 -3.79048e-05 8.68324 0.0164341 8.76067 0.0484753C8.83811 0.0805164 8.90848 0.127499 8.96776 0.18674L13.8128 5.03091C13.872 5.09019 13.919 5.16056 13.951 5.238C13.9831 5.31543 13.9996 5.39843 13.9995 5.48223V14.9681C13.999 15.5333 13.7742 16.0752 13.3746 16.4749C12.975 16.8745 12.4332 17.0994 11.868 17.1001ZM2.13156 1.27612C1.90475 1.27635 1.6873 1.36655 1.52692 1.52692C1.36655 1.6873 1.27635 1.90475 1.27613 2.13155V14.9685C1.27635 15.1953 1.36655 15.4128 1.52692 15.5731C1.6873 15.7335 1.90475 15.8237 2.13156 15.8239H11.868C12.0948 15.8237 12.3122 15.7335 12.4726 15.5731C12.633 15.4128 12.7232 15.1953 12.7234 14.9685V5.74639L8.25313 1.27612H2.13156Z"
                      fill="#1473FF"
                    />
                  </svg>
                </Box>
                <Box className={styles.root_attachments_item_name}>{attachment?.fileName}</Box>
              </Box>
            ))}
            {previewAttachment && (
              <Lightbox
                medium={previewAttachment.originalUrl}
                large={previewAttachment.originalUrl}
                onClose={handleClosePreviewAttachment}
              />
            )}
          </>
        </Box>
        <Box className={styles.root_label}>Анамнез</Box>
        <InputField
          type="textarea"
          name="anamnesis"
          rows={10}
          value={anamnesis}
          disabled={disabled || loading}
          onChange={(e) => {
            setAnamnesis(e.target.value);
          }}
          block
        />
        <Box className={styles.root_label}>Рекомендации</Box>
        <InputField
          type="textarea"
          name="recommendations"
          rows={10}
          value={recommendations}
          disabled={disabled || loading}
          onChange={(e) => {
            setRecommendations(e.target.value);
          }}
          block
        />
        {!disabled && (
          <Box className={styles.root_files}>
            {files.map((file: File, index) => (
              <Box className={styles.root_file} key={index}>
                <Box className={styles.root_file_wrapper}>
                  <Box className={styles.root_file_icon}>
                    <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M20.0623 3.08323C19.1799 2.16405 17.9622 1.62702 16.6769 1.59028C15.3917 1.55353 14.1442 2.02008 13.209 2.88729L2.63007 12.6967C2.00658 13.2749 1.64231 14.0728 1.61738 14.9149C1.59246 15.757 1.90892 16.5743 2.49716 17.1871C3.0854 17.7999 3.89723 18.1579 4.75406 18.1824C5.61088 18.2069 6.44251 17.8959 7.066 17.3177L16.4695 8.59823C16.7812 8.30916 16.9634 7.91022 16.9758 7.48917C16.9883 7.06811 16.8301 6.65944 16.5359 6.35304C16.2418 6.04665 15.8359 5.86764 15.4075 5.85539C14.9791 5.84314 14.5633 5.99866 14.2515 6.28773L5.43576 14.4623C5.11117 14.7632 4.59978 14.7486 4.29355 14.4296C3.98731 14.1106 4.00219 13.608 4.32677 13.307L13.1425 5.13248C13.766 4.55434 14.5977 4.24331 15.4545 4.26781C16.3113 4.2923 17.1231 4.65032 17.7114 5.26311C18.2996 5.87589 18.6161 6.69324 18.5912 7.53535C18.5662 8.37746 18.202 9.17535 17.5785 9.75348L8.17498 18.473C7.23975 19.3402 5.9923 19.8067 4.70707 19.77C3.42183 19.7333 2.20409 19.1962 1.32173 18.2771C0.439365 17.3579 -0.0353348 16.1318 0.00205232 14.8687C0.0394407 13.6055 0.585854 12.4087 1.52109 11.5415L12.1 1.73204C13.347 0.575761 15.0103 -0.0463047 16.7239 0.0026895C18.4376 0.0516838 20.0612 0.767725 21.2377 1.99329C22.4142 3.21886 23.0471 4.85356 22.9973 6.53778C22.9474 8.22199 22.2189 9.81777 20.9719 10.974L10.3929 20.7835C10.0684 21.0845 9.55698 21.0698 9.25074 20.7508C8.9445 20.4318 8.95938 19.9292 9.28397 19.6282L19.8629 9.8188C20.7981 8.95159 21.3445 7.75476 21.3819 6.49159C21.4193 5.22843 20.9446 4.0024 20.0623 3.08323Z"
                        fill="#1473FF"
                      />
                    </svg>
                  </Box>
                  <Box className={styles.root_file_name}>{file?.name}</Box>
                </Box>
                <Box className={styles.root_file_delete} onClick={() => handleDelete(index)}>
                  <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11.3627 17.3359H3.38664C2.91977 17.3348 2.47235 17.1492 2.14223 16.8199C1.8121 16.4906 1.62614 16.0442 1.625 15.5785V3.18457C1.625 3.01363 1.69307 2.84969 1.81423 2.72882C1.93539 2.60794 2.09973 2.54004 2.27108 2.54004H12.4791C12.6504 2.54004 12.8148 2.60794 12.9359 2.72882C13.0571 2.84969 13.1252 3.01363 13.1252 3.18457V15.5785C13.124 16.0444 12.9379 16.4909 12.6076 16.8202C12.2773 17.1496 11.8297 17.335 11.3627 17.3359ZM2.91715 3.8291V15.5785C2.91727 15.7027 2.96677 15.8217 3.05479 15.9095C3.14281 15.9973 3.26216 16.0467 3.38664 16.0468H11.3627C11.4872 16.0468 11.6067 15.9975 11.6948 15.9097C11.7829 15.8219 11.8325 15.7027 11.8326 15.5785V3.8291H2.91715Z"
                      fill="#FF0099"
                    />
                    <path
                      d="M10.0868 3.82867H4.65975C4.4884 3.82867 4.32407 3.76077 4.2029 3.63989C4.08174 3.51902 4.01367 3.35508 4.01367 3.18414V2.01281C4.01436 1.5032 4.21762 1.01467 4.57887 0.654361C4.94012 0.294053 5.42987 0.0913887 5.9407 0.0908203H8.80584C9.31667 0.0913887 9.80642 0.294053 10.1677 0.654361C10.5289 1.01467 10.7322 1.5032 10.7329 2.01281V3.18414C10.7329 3.35508 10.6648 3.51902 10.5436 3.63989C10.4225 3.76077 10.2581 3.82867 10.0868 3.82867ZM5.30583 2.53961H9.44072V2.01281C9.44049 1.84494 9.37351 1.68402 9.25449 1.56536C9.13546 1.4467 8.97411 1.38 8.80584 1.37988H5.9407C5.77251 1.38011 5.61127 1.44687 5.49233 1.56551C5.3734 1.68416 5.30648 1.84502 5.30626 2.01281L5.30583 2.53961Z"
                      fill="#FF0099"
                    />
                    <path
                      d="M7.37459 14.8677C7.20324 14.8677 7.03891 14.7998 6.91775 14.679C6.79658 14.5581 6.72852 14.3941 6.72852 14.2232V5.65137C6.72852 5.48043 6.79658 5.31649 6.91775 5.19561C7.03891 5.07474 7.20324 5.00684 7.37459 5.00684C7.54594 5.00684 7.71027 5.07474 7.83144 5.19561C7.9526 5.31649 8.02067 5.48043 8.02067 5.65137V14.2232C8.02067 14.3941 7.9526 14.5581 7.83144 14.679C7.71027 14.7998 7.54594 14.8677 7.37459 14.8677Z"
                      fill="#FF0099"
                    />
                    <path
                      d="M5.04061 14.8677C4.86926 14.8677 4.70493 14.7998 4.58376 14.679C4.4626 14.5581 4.39453 14.3941 4.39453 14.2232V5.65137C4.39453 5.48043 4.4626 5.31649 4.58376 5.19561C4.70493 5.07474 4.86926 5.00684 5.04061 5.00684C5.21196 5.00684 5.37629 5.07474 5.49745 5.19561C5.61862 5.31649 5.68668 5.48043 5.68668 5.65137V14.2232C5.68668 14.3941 5.61862 14.5581 5.49745 14.679C5.37629 14.7998 5.21196 14.8677 5.04061 14.8677Z"
                      fill="#FF0099"
                    />
                    <path
                      d="M9.70662 14.8677C9.53527 14.8677 9.37094 14.7998 9.24978 14.679C9.12862 14.5581 9.06055 14.3941 9.06055 14.2232V5.65137C9.06055 5.48043 9.12862 5.31649 9.24978 5.19561C9.37094 5.07474 9.53527 5.00684 9.70662 5.00684C9.87797 5.00684 10.0423 5.07474 10.1635 5.19561C10.2846 5.31649 10.3527 5.48043 10.3527 5.65137V14.2232C10.3527 14.3941 10.2846 14.5581 10.1635 14.679C10.0423 14.7998 9.87797 14.8677 9.70662 14.8677Z"
                      fill="#FF0099"
                    />
                    <path
                      d="M13.4795 3.8291H1.26912C1.09777 3.8291 0.933441 3.7612 0.812278 3.64032C0.691116 3.51945 0.623047 3.35551 0.623047 3.18457C0.623047 3.01363 0.691116 2.84969 0.812278 2.72882C0.933441 2.60794 1.09777 2.54004 1.26912 2.54004H13.4795C13.6509 2.54004 13.8152 2.60794 13.9364 2.72882C14.0575 2.84969 14.1256 3.01363 14.1256 3.18457C14.1256 3.35551 14.0575 3.51945 13.9364 3.64032C13.8152 3.7612 13.6509 3.8291 13.4795 3.8291Z"
                      fill="#FF0099"
                    />
                    <path
                      d="M13.4795 3.8291H1.26912C1.09777 3.8291 0.933441 3.7612 0.812278 3.64032C0.691116 3.51945 0.623047 3.35551 0.623047 3.18457C0.623047 3.01363 0.691116 2.84969 0.812278 2.72882C0.933441 2.60794 1.09777 2.54004 1.26912 2.54004H13.4795C13.6509 2.54004 13.8152 2.60794 13.9364 2.72882C14.0575 2.84969 14.1256 3.01363 14.1256 3.18457C14.1256 3.35551 14.0575 3.51945 13.9364 3.64032C13.8152 3.7612 13.6509 3.8291 13.4795 3.8291Z"
                      fill="#FF0099"
                    />
                  </svg>
                  <Box className={styles.root_file_delete_label}>Удалить</Box>
                </Box>
              </Box>
            ))}
            {files.length < 5 && (
              <Box className={styles.root_file}>
                <Box className={styles.root_file_wrapper}>
                  <Box className={styles.root_file_icon}>
                    <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M20.0623 3.08323C19.1799 2.16405 17.9622 1.62702 16.6769 1.59028C15.3917 1.55353 14.1442 2.02008 13.209 2.88729L2.63007 12.6967C2.00658 13.2749 1.64231 14.0728 1.61738 14.9149C1.59246 15.757 1.90892 16.5743 2.49716 17.1871C3.0854 17.7999 3.89723 18.1579 4.75406 18.1824C5.61088 18.2069 6.44251 17.8959 7.066 17.3177L16.4695 8.59823C16.7812 8.30916 16.9634 7.91022 16.9758 7.48917C16.9883 7.06811 16.8301 6.65944 16.5359 6.35304C16.2418 6.04665 15.8359 5.86764 15.4075 5.85539C14.9791 5.84314 14.5633 5.99866 14.2515 6.28773L5.43576 14.4623C5.11117 14.7632 4.59978 14.7486 4.29355 14.4296C3.98731 14.1106 4.00219 13.608 4.32677 13.307L13.1425 5.13248C13.766 4.55434 14.5977 4.24331 15.4545 4.26781C16.3113 4.2923 17.1231 4.65032 17.7114 5.26311C18.2996 5.87589 18.6161 6.69324 18.5912 7.53535C18.5662 8.37746 18.202 9.17535 17.5785 9.75348L8.17498 18.473C7.23975 19.3402 5.9923 19.8067 4.70707 19.77C3.42183 19.7333 2.20409 19.1962 1.32173 18.2771C0.439365 17.3579 -0.0353348 16.1318 0.00205232 14.8687C0.0394407 13.6055 0.585854 12.4087 1.52109 11.5415L12.1 1.73204C13.347 0.575761 15.0103 -0.0463047 16.7239 0.0026895C18.4376 0.0516838 20.0612 0.767725 21.2377 1.99329C22.4142 3.21886 23.0471 4.85356 22.9973 6.53778C22.9474 8.22199 22.2189 9.81777 20.9719 10.974L10.3929 20.7835C10.0684 21.0845 9.55698 21.0698 9.25074 20.7508C8.9445 20.4318 8.95938 19.9292 9.28397 19.6282L19.8629 9.8188C20.7981 8.95159 21.3445 7.75476 21.3819 6.49159C21.4193 5.22843 20.9446 4.0024 20.0623 3.08323Z"
                        fill="#1473FF"
                      />
                    </svg>
                  </Box>
                  <Box className={styles.root_file_name}>Прикрепить файл заключения</Box>
                </Box>
                {!loading && files.length < 5 && (
                  <input
                    ref={setInputFile}
                    type="file"
                    multiple
                    size={5}
                    onChange={onChangeFile}
                    title="Прикрепить файл заключения"
                  />
                )}
              </Box>
            )}
          </Box>
        )}
        {showDictionaries && Object.values(EIMKKey).map(renderSelect)}
        <Button disabled={!canClose || loading} onClick={handleClose} type="submit" size="md" block color="primary">
          {isDone ? 'Протокол отправлен' : isWaitingFilling ? 'Отправить в ИЭМК' : 'Отправить протокол'}
        </Button>
      </form>
    </>
  );
};

export default AppointmentDoctorReport;
