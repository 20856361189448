import axios from 'utils/axios';

export const findFeedback = (
  query?: string,
  timeFrom?: Date,
  timeTo?: Date,
  statuses?: string[],
  page?: number,
  pageSize?: number
) => {
  query = query || null;
  page = page || 0;
  pageSize = pageSize || 50;
  timeFrom = timeFrom || null;
  timeTo = timeTo || null;
  return axios.get(`/api/v1/admin/feedback`, {
    params: {
      query,
      statuses: statuses && statuses.length ? statuses.join(',') : null,
      timeFrom,
      timeTo,
      page,
      pageSize,
    },
  });
};
