import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Box } from '@material-ui/core';
import classNames from 'classnames';
import InfiniteScroll from 'react-infinite-scroller';
import Button from '../../../../components/Forms/Button';
import styles from './SettingsPageAdminSpecGroups.module.sass';
import Manage from '../../../../types/manage';
import AdminSpecGroupView from '../../../../components/AdminSpecGroupView';
import { ManageSpecGroupSettings } from '../../../../store/reducers/manageSpecGroups.reducer';
import { fetchSpecGroup, fetchSpecGroups } from '../../../../store/actions/manageSpecGroups.actions';
import ContentLoading from '../../../../components/ContentLoading';
import AdminSpecGroupItemView from './views/AdminSpecGroupItemView';
import SpecGroupStatusFilterView from './filters/SpecGroupStatusFilterView';

interface SettingsPageAdminSpecGroupsProps {
  page: number;
  pageSize: number;
  hasMore: boolean;
  loading: boolean;
  errors: any;
  items?: Array<Manage.Spec.Group>;
  fetchSpecGroups: (
    page?: number,
    pageSize?: number,
    statuses?: Array<Manage.Spec.GroupStatus>,
    reset?: boolean
  ) => void;
  fetchSpecGroup: (id: string) => void;
}

const SettingsPageAdminSpecGroups: React.FC<SettingsPageAdminSpecGroupsProps> = (props) => {
  const { page, pageSize, hasMore, loading, errors, items, fetchSpecGroups, fetchSpecGroup } = props;

  const [specGroup, setSpecGroup] = useState<Manage.Spec.Group>(null);
  const [statuses, setStatuses] = useState<Array<Manage.Spec.GroupStatus>>([]);
  const [isStatusOpened, setStatusOpened] = useState<boolean>(false);

  useEffect(() => onLoadMore(true), [statuses]);

  const onCreate = () => {
    setSpecGroup({});
  };

  const onEdit = useCallback(
    (item: Manage.Spec.Group) => {
      setSpecGroup(item);
    },
    [items]
  );

  const onGroupUpdated = (id: string) => {
    fetchSpecGroup(id);
    setSpecGroup(null);
  };

  const onLoadMore = useCallback(
    (reset?: boolean): void => {
      fetchSpecGroups(reset ? 0 : page + 1, pageSize, statuses, reset);
    },
    [page, pageSize, statuses]
  );

  return (
    <>
      <Box className={classNames(styles.SettingsPageAdminSpecGroups)}>
        <Box
          className={classNames(styles.SettingsPageAdminSpecGroups_Section, styles.SettingsPageAdminSpecGroups_Toolbar)}
        >
          <Button className="mr-4" size="sm" color="primary" onClick={onCreate}>
            Создать
          </Button>
        </Box>
        <Box
          className={classNames(styles.SettingsPageAdminSpecGroups_Section, styles.SettingsPageAdminSpecGroups_Filters)}
        >
          <SpecGroupStatusFilterView isOpen={isStatusOpened} onToggle={setStatusOpened} onSelectChanged={setStatuses} />
        </Box>
        <Box
          className={classNames(styles.SettingsPageAdminSpecGroups_Section, styles.SettingsPageAdminSpecGroups_Content)}
        >
          <InfiniteScroll
            className={styles.SettingsPageAdminSpecGroups_InfiniteScroll}
            pageStart={0}
            hasMore={loading ? false : hasMore}
            initialLoad={false}
            threshold={pageSize}
            loadMore={onLoadMore}
            loader={null}
          >
            <>
              {items.length === 0 && !(loading || errors) && (
                <Box className={styles.SettingsPageAdminSpecGroups_EmptyLabel}>
                  Тарифов по вашему запросу не найдено
                </Box>
              )}
              {items.length > 0 && (
                <Box className={classNames(styles.SettingsPageAdminSpecGroups_Groups)}>
                  {items.map((value) => {
                    return <AdminSpecGroupItemView key={`group-${value.id}`} item={value} onEdit={onEdit} />;
                  })}
                </Box>
              )}
              {(loading || errors) && (
                <Box width="100%" p={2}>
                  <ContentLoading isLoading={loading} isError={errors} fetchData={onLoadMore} />
                </Box>
              )}
            </>
          </InfiniteScroll>
        </Box>
      </Box>
      {specGroup && (
        <AdminSpecGroupView
          editItem={specGroup}
          onClosed={() => setSpecGroup(null)}
          onUpdated={onGroupUpdated}
          title="Создать группу"
        />
      )}
    </>
  );
};

export default connect(
  (state: any) => ({
    ...(state.manageSpecGroupsReducer as ManageSpecGroupSettings),
  }),
  {
    fetchSpecGroups,
    fetchSpecGroup,
  }
)(SettingsPageAdminSpecGroups);
