import React, { useState } from 'react';
import { Backdrop, Box, Dialog, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import styles from './AdminCategoryView.module.sass';
import ContentCard from '../ContentCard';
import Manage from '../../types/manage';
import InputField from '../Forms/InputField';
import Button from '../Forms/Button';
import { getFieldErrors as getFieldErrorsUtil, validateFieldOnChange } from '../../utils';
import FormValidator from '../Forms/FormValidator';
import { updateCategory } from '../../api/updateCategory';
import { createCategory } from '../../api/createCategory';
import { updateCategoryStatus } from '../../api/updateCategoryStatus';

interface AdminCategoryViewInterface {
  category: Manage.Category.Item;
  onSave: () => void;
  onClose: () => void;
}

const AdminCategoryView = (props: AdminCategoryViewInterface) => {
  const { onClose = () => {}, onSave = () => {}, category } = props;
  const [loadingStore, setLoadingStore] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<any>([]);
  const [model, setModel] = useState<Manage.Spec.Item>(category);
  const validateOnChange = (name: string, value: any, event, element?: any) => {
    validateFieldOnChange(name, value, event, model, setModel, formErrors, setFormErrors, element);
  };
  const getFieldErrors = (field: string) => getFieldErrorsUtil(field, formErrors);
  const submit = (e) => {
    e.preventDefault();
    const form = e.target;
    const inputs = [...form.elements].filter((i) => ['INPUT', 'SELECT', 'TEXTAREA'].includes(i.nodeName));

    const { errors, hasError } = FormValidator.bulkValidate(inputs);

    setFormErrors([...errors]);

    if (!hasError) {
      storeCategory();
    }
  };
  const storeCategory = (): void => {
    setLoadingStore(true);
    (model.id ? updateCategory(model.id, model.name) : createCategory(model.name))
      .then((response: AxiosResponse<any>) => {
        if (response.data.code === 'success') {
          if (model.id && category.active !== model.active) {
            return updateCategoryStatus(model.id, model.active);
          }
        } else {
          throw new Error();
        }
      })
      .then(() => {
        setLoadingStore(false);
        onSave();
      })
      .catch((err) => {
        toast.error('Ошибка при запросе');
        setLoadingStore(false);
      });
  };

  return (
    <Dialog
      PaperProps={{ className: classNames(styles.Dialog) }}
      BackdropComponent={(props) => <Backdrop className={styles.Backdrop} {...props} />}
      onClose={onClose}
      open
    >
      <ContentCard className={styles.AdminCategoryView}>
        <ContentCard.Header className={styles.AdminCategoryView_Header}>
          <h6 className="page-header-title">{model.id ? 'Редактировать категорию' : 'Создать категорию'}</h6>
          <CloseIcon onClick={onClose} className={styles.AdminCategoryView_CloseIcon} />
        </ContentCard.Header>
        <ContentCard.Main className={styles.AdminCategoryView_Content}>
          <form onSubmit={submit}>
            <Grid container direction="column" justify="space-between" alignItems="stretch" spacing={2}>
              <Grid item xs={12}>
                <InputField
                  name="name"
                  value={model.name || ''}
                  onChange={(e) => validateOnChange('name', e.target.value, e)}
                  data-validate='["required"]'
                  errors={getFieldErrors('name')}
                  block
                  label="Название"
                  placeholder="Введите название категории"
                />
              </Grid>
              {model.id && (
                <Grid item xs={12} container justify="space-between" alignItems="center">
                  <Box>Активная категория</Box>
                  <label className="toggle-checkbox-label">
                    <input
                      type="checkbox"
                      checked={model.active === true}
                      onChange={(event) => {
                        setModel({ ...model, active: event.target.checked });
                      }}
                    />

                    <span className="checkmark">
                      <span className="check" />
                    </span>
                  </label>
                </Grid>
              )}
              <Grid item xs={12} container justify="space-between" alignItems="center" spacing={1}>
                <Grid item xs={12} lg={6}>
                  <Button className="mr-2" color="default" block onClick={onClose}>
                    Отмена
                  </Button>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Button type="submit" color="primary" isLoading={loadingStore} block>
                    Сохранить
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </ContentCard.Main>
      </ContentCard>
    </Dialog>
  );
};
export default AdminCategoryView;
