import React from 'react';
import classNames from 'classnames';
import styles from './DoctorStatusLabel.module.sass';
import { AvailabilityStatus } from '../../types/doctor';

const DoctorStatusLabel = (props: { status: AvailabilityStatus; [key: string]: any }) => {
  const { status, className = null, ...rest } = props;
  return (
    <div
      className={classNames(
        styles.DoctorStatus,
        className,
        status === AvailabilityStatus.DUTY && styles.DoctorStatus_duty,
        status === AvailabilityStatus.BUSY && styles.DoctorStatus_busy,
        status === AvailabilityStatus.NOT_DUTY && styles.DoctorStatus_not_duty,
        status === AvailabilityStatus.AWAY && styles.DoctorStatus_away
      )}
      {...rest}
    >
      &nbsp;
    </div>
  );
};
export default DoctorStatusLabel;
