import { AxiosResponse } from 'axios';
import Manage from '../../types/manage';
import { findFeedback } from '../../api/findFeedback';

export const MANAGE_REVIEWS_LOADING = 'MANAGE_REVIEWS_LOADING';
export const MANAGE_REVIEWS_SUCCESS = 'MANAGE_REVIEWS_SUCCESS';
export const MANAGE_REVIEWS_ERRORS = 'MANAGE_REVIEWS_ERRORS';

export const setManageReviewsLoading = (loading: boolean, reset?: boolean) => ({
  type: MANAGE_REVIEWS_LOADING,
  loading,
  reset,
});

export const setManageReviewsErrors = (errors: any) => ({ type: MANAGE_REVIEWS_ERRORS, errors });

export const setManageReviews = (
  reviews: Manage.Review.Item[],
  page: number,
  pageSize: number,
  totalItems: number
) => ({
  type: MANAGE_REVIEWS_SUCCESS,
  reviews,
  page,
  pageSize,
  totalItems,
});

export const fetchManageReviews = (
  query?: string,
  timeFrom?: Date,
  timeTo?: Date,
  statuses?: string[],
  page?: number,
  pageSize?: number,
  reset?: boolean
): any => {
  return (dispatch) => {
    dispatch(setManageReviewsLoading(true, reset));
    findFeedback(query, timeFrom, timeTo, statuses, page, pageSize)
      .then((response: AxiosResponse<Manage.Review.Response>) => {
        dispatch(setManageReviewsLoading(false));
        if (response.data.code === 'success') {
          dispatch(
            setManageReviews(
              response.data.data.items,
              response.data.data.page,
              response.data.data.pageSize,
              response.data.data.totalItems
            )
          );
        } else {
          dispatch(setManageReviewsErrors(response.data));
        }
      })
      .catch((error: any) => {
        dispatch(setManageReviewsErrors(error));
        dispatch(setManageReviewsLoading(false));
      });
  };
};
