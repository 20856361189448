import Manage from "../../../types/manage";
import {AxiosResponse} from "axios";
import managePartnersApi from "../../../api/partner/managePartners";
import {ResponseData} from "../../../types";

export const PARTNERS_LOADING = 'PARTNERS_LOADING'
export const PARTNERS_SUCCESS = 'PARTNERS_SUCCESS'
export const PARTNERS_ERROR = 'PARTNERS_ERROR'

const setLoading = (loading: boolean, reset?: boolean) => ({
  type: PARTNERS_LOADING,
  loading,
  reset
})

const setError = (errors: any) => ({type: PARTNERS_ERROR, errors})

const setPartners = (response: Manage.Partners.PartnersResponse) => ({
  type: PARTNERS_SUCCESS,
  items: response.items,
  page: response.page,
  pageSize: response.pageSize,
  totalItems: response.totalItems,
});

export const fetchPartners = (page?: number,
                              pageSize?: number,
                              reset?: boolean,): any => {
  return (dispatch) => {
    dispatch(setLoading(true, reset))
    managePartnersApi.getPartners({
      page: page,
      pageSize
    }).then((response: AxiosResponse<ResponseData<Manage.Partners.PartnersResponse>>) => {
      dispatch(setLoading(false));
      if (response.data.code === 'success') {
        const data = response.data.data
        dispatch(setPartners(data));
      } else {
        dispatch(setError(response.data));
      }
    })
      .catch((error: any) => {
        dispatch(setError(error));
        dispatch(setLoading(false));
      });
  }
}
