import React from 'react';
import classNames from 'classnames';

// Components
import Button from 'components/Forms/Button';
import Spinner from 'components/Spinner';

import styles from './ContentLoading.module.sass';

type ContentLoadingProps = {
  isLoading: boolean;
  loadingOverlay?: boolean;
  isError: any;
  fetchData: (any) => void;
  [propName: string]: any;
};

const ContentLoading = (props: ContentLoadingProps) => {
  const { isError, isLoading, fetchData, loadingOverlay, children } = props;

  return isError ? (
    <div className="text-center">
      Ошибка при выполнении запроса <br />
      <Button className="mt-2" size="sm" onClick={fetchData}>
        Повторить запрос
      </Button>
    </div>
  ) : (
    <>
      {loadingOverlay ? (
        <div
          className={classNames(styles.loadingOverlay, {
            [styles.loadingOverlay_is_loading]: isLoading,
          })}
        >
          <Spinner className={styles.loadingSpinner} width={36} height={36} color="#1473ff" />
          {children}
        </div>
      ) : (
        <>
          {isLoading ? (
            <div className="text-center">
              <Spinner width={25} height={25} color="#1473ff" />
            </div>
          ) : (
            children
          )}
        </>
      )}
    </>
  );
};

export default ContentLoading;
