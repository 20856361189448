import React, { useEffect, useState } from 'react';
import { Backdrop, Box, Dialog, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import styles from './AdminSpecView.module.sass';
import ContentCard from '../ContentCard';
import Manage from '../../types/manage';
import InputField from '../Forms/InputField';
import Button from '../Forms/Button';
import { getFieldErrors as getFieldErrorsUtil, validateFieldOnChange } from '../../utils';
import FormValidator from '../Forms/FormValidator';
import { updateSpecialization } from '../../api/updateSpecialization';
import { createSpecialization } from '../../api/createSpecialization';
import { updateSpecializationStatus } from '../../api/updateSpecializationStatus';
import { findTariffsAdmin } from '../../api/findTariffsAdmin';
import ContentLoading from '../ContentLoading';
import SelectInput from '../Forms/SelectInput';

interface AdminSpecViewInterface {
  spec: Manage.Spec.Item;
  onSave: () => void;
  onClose: () => void;
}

const AdminSpecView = (props: AdminSpecViewInterface) => {
  const { onClose = () => {}, onSave = () => {}, spec } = props;
  const [loading, setLoading] = useState<boolean>(true);
  const [errors, setErrors] = useState<any>(null);
  const [tariffs, setTariffs] = useState<Manage.Tariff.Item[]>([]);
  const [loadingStore, setLoadingStore] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<any>([]);
  const [model, setModel] = useState<Manage.Spec.Item>({ ...spec, active: spec.id ? spec.active : true });
  useEffect(() => {
    init();
  }, []);
  const validateOnChange = (name: string, value: any, event, element?: any) => {
    validateFieldOnChange(name, value, event, model, setModel, formErrors, setFormErrors, element);
  };
  const getFieldErrors = (field: string) => getFieldErrorsUtil(field, formErrors);
  const submit = (e) => {
    e.preventDefault();
    const form = e.target;
    const inputs = [...form.elements].filter((i) => ['INPUT', 'SELECT', 'TEXTAREA'].includes(i.nodeName));

    const { errors, hasError } = FormValidator.bulkValidate(inputs);

    setFormErrors([...errors]);

    if (!hasError) {
      storeSpec();
    }
  };
  const init = (): void => {
    setLoading(true);
    findTariffsAdmin()
      .then((response: AxiosResponse<Manage.Tariff.Response>) => {
        setLoading(false);
        if (response.data.code === 'success') {
          setTariffs(response.data.data.items);
        } else {
          setErrors(response.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        setErrors(err);
      });
  };
  const storeSpec = (): void => {
    setLoadingStore(true);
    (model.id
      ? updateSpecialization(model.id, model.name, model.durationMinutes, model.tariff.id)
      : createSpecialization(model.name, model.durationMinutes, model.tariff.id)
    )
      .then((response: AxiosResponse<any>) => {
        if (response.data.code === 'success' && (model.id || (!model.id && response.data.data.id))) {
          if (model.active !== spec.active) {
            return updateSpecializationStatus(model.id ? model.id : response.data.data.id, model.active === true).then(
              (response: AxiosResponse<any>) => {
                if (response.data.code === 'success') {
                  return Promise.resolve();
                }
                throw new Error();
              }
            );
          }
          return Promise.resolve();
        }
        throw new Error();
      })
      .then(() => {
        setLoadingStore(false);
        onSave();
      })
      .catch((err) => {
        toast.error('Ошибка при запросе');
        setLoadingStore(false);
      });
  };

  const tariffSelected = model.tariff ? { label: model.tariff.name, value: model.tariff.id } : null;

  return (
    <Dialog
      PaperProps={{ className: classNames(styles.Dialog) }}
      BackdropComponent={(props) => <Backdrop className={styles.Backdrop} {...props} />}
      onClose={onClose}
      open
    >
      <ContentCard className={styles.AdminSpecView}>
        <ContentCard.Header className={styles.AdminSpecView_Header}>
          <h6 className="page-header-title">{spec.id ? 'Редактировать специализацию' : 'Создать специализацию'}</h6>
          <CloseIcon onClick={onClose} className={styles.AdminSpecView_CloseIcon} />
        </ContentCard.Header>
        <ContentCard.Main className={styles.AdminSpecView_Content}>
          <ContentLoading isLoading={loading} isError={errors} fetchData={() => init()}>
            <form onSubmit={submit}>
              <Grid container direction="column" justify="space-between" alignItems="stretch" spacing={2}>
                <Grid item xs={12}>
                  <InputField
                    name="name"
                    value={model.name || ''}
                    onChange={(e) => validateOnChange('name', e.target.value, e)}
                    data-validate='["required"]'
                    errors={getFieldErrors('name')}
                    block
                    label="Название"
                    placeholder="Введите название специализации"
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputField
                    type="number"
                    min={1}
                    name="durationMinutes"
                    onBlur={(event) =>
                      setModel({
                        ...model,
                        durationMinutes:
                          event.target.value !== '' && Number(event.target.value) > 0
                            ? Math.floor(Number(event.target.value))
                            : 0,
                      })
                    }
                    value={model.durationMinutes ? model.durationMinutes.toString() : ''}
                    onChange={(event) => validateOnChange('durationMinutes', event.target.value, event)}
                    data-param="1"
                    data-validate='["required", "number", "min"]'
                    errors={getFieldErrors('durationMinutes')}
                    block
                    label="Продолжительность (мин)"
                    placeholder="Введите подолжительность специализации"
                  />
                </Grid>
                <Grid item xs={12}>
                  <SelectInput
                    name="tariffId"
                    value={tariffSelected}
                    onChange={({ label, value }, e) => validateOnChange('tariff', { name: label, id: value }, e)}
                    data-validate='["required"]'
                    errors={getFieldErrors('tariffId')}
                    options={tariffs.map(({ name, id }) => ({ label: name, value: id }))}
                    block
                    label="Тариф"
                    placeholder="Тариф"
                  />
                </Grid>
                <Grid item xs={12} container justify="space-between" alignItems="center">
                  <Box>Активная специализация</Box>
                  <label className="toggle-checkbox-label">
                    <input
                      type="checkbox"
                      checked={model.active === true}
                      onChange={(event) => {
                        setModel({ ...model, active: event.target.checked });
                      }}
                    />

                    <span className="checkmark">
                      <span className="check" />
                    </span>
                  </label>
                </Grid>
                <Grid item xs={12} container justify="space-between" alignItems="center" spacing={1}>
                  <Grid item xs={12} lg={6}>
                    <Button className="mr-2" color="default" block onClick={onClose}>
                      Отмена
                    </Button>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Button type="submit" color="primary" isLoading={loadingStore} block>
                      Сохранить
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </ContentLoading>
        </ContentCard.Main>
      </ContentCard>
    </Dialog>
  );
};
export default AdminSpecView;
