import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { UserRole } from 'types';
import classNames from 'classnames';
import { decodeAvatarResource } from 'utils';
import HasRole from 'components/HasRole';
import { getServicePackagesMy } from 'api';
import { Box } from '@material-ui/core';
import styles from './SidebarProfile.module.sass';

const SidebarProfile = (): JSX.Element => {
  const role = useSelector<any, UserRole>((state) => state?.auth?.role as UserRole);
  const avatar = useSelector<any, string | null>((state) =>
    role === UserRole.DOCTOR ? state?.doctor?.avatar : state?.auth?.userData?.avatar
  );
  const sex = useSelector<any, string>((state) =>
    role === UserRole.DOCTOR ? state?.doctor?.sex : state?.auth?.userData?.sex
  );
  const displayName = useSelector<any, string>((state) =>
    role === UserRole.ADMIN
      ? state?.auth?.userData?.firstName
      : role === UserRole.DOCTOR
      ? state?.doctor?.displayName
      : role === UserRole.AGENT
      ? [state?.auth?.userData?.lastName, state?.auth?.userData?.firstName, state?.auth?.userData?.middleName]
          .filter((v) => v)
          .join(' ')
      : state?.auth?.userData?.displayName
  );
  const avatarBackground = useMemo(
    () => ({ backgroundImage: `url(${decodeAvatarResource(avatar, 360, sex)})` }),
    [avatar, sex]
  );

  const [isActive, setIsActive] = useState(null);

  useEffect(() => {
    role === UserRole.PATIENT &&
      getServicePackagesMy()
        .then((response) => {
          setIsActive((response.data?.data?.items || []).some((sP) => sP?.status?.value === 'ACTIVATED'));
        })
        .catch(() => {
          setIsActive(false);
        });
  }, [setIsActive, role]);

  return (
    <div className={styles.root}>
      <Box
        className={styles.root_profile}
        component={(props) =>
          ![UserRole.ADMIN, UserRole.AGENT].includes(role) ? <Link to="/profile" {...props} /> : <div {...props} />
        }
      >
        <div className={styles.root_profile_avatar} style={avatarBackground} />
        <div className={styles.root_profile_name}>{displayName}</div>
      </Box>
      <HasRole target={UserRole.PATIENT}>
        {isActive !== null && (
          <div className={classNames(styles.root_activeTariff, isActive && styles.root_activeTariff_active)}>
            {`${isActive ? 'Тариф активирован' : 'Нет активного тарифа'}`}
          </div>
        )}
      </HasRole>
    </div>
  );
};

export default SidebarProfile;
