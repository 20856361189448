import { forEach } from 'lodash';
import profileImgDefault from '../assets/img/avatarDefault.svg';

export const decodeAvatarResource = (resource: any, format?: number, sex?: any): string => {
  if (!resource) {
    return profileImgDefault;
  }

  if (!format || Object.keys(resource?.formatUrls || {}).length === 0) return resource.originalUrl;

  if (resource?.formatUrls[format]) return resource.formatUrls[format];

  const availableFormats = Object.keys(resource?.formatUrls || []).map((format) => Number(format));
  const compareAvailableFormats = availableFormats.map((avlFormat) => {
    const formatsDiffrence = avlFormat - Number(format);

    return {
      format: avlFormat,
      difference: formatsDiffrence > 0 ? formatsDiffrence : -formatsDiffrence,
    };
  });

  const minDifference = Math.min.apply(
    null,
    compareAvailableFormats.map((compare) => compare.difference)
  );
  const properFormat = compareAvailableFormats.find((compare) => minDifference === compare.difference)?.format;

  return resource.formatUrls[properFormat];
};

export const deepObjectMerge = (destination, source): any => {
  const newDestination = { ...destination };
  const newSource = { ...source };
  forEach(newSource, (_, property) => {
    if (typeof newSource[property] === 'object' && newSource[property] !== null) {
      newDestination[property] = {
        ...newDestination[property],
        ...newSource[property],
      };
    } else {
      newDestination[property] = newSource[property];
    }
  });
  return newDestination;
};

export const getMonthName = (monthNum) => {
  const monthsNames = [
    'Января',
    'Февраля',
    'Марта',
    'Апреля',
    'Мая',
    'Июня',
    'Июля',
    'Августа',
    'Сентября',
    'Октября',
    'Ноября',
    'Декабря',
  ];
  let monthName = '';

  const index = monthNum === 0 ? 0 : monthNum;

  if (monthsNames[index]) {
    monthName = monthsNames[index];
  }

  return monthName;
};

export const localStringToNumber = (s) => {
  return Number(String(s).replace(/[^0-9.]+/g, ''));
};

export const prependToTelegram = (s: string): string => {
  s = s || '';
  return !s?.length || (s?.length && s?.[0] === '@') ? `${s}` : `@${s}`;
};

export const toDisplayName = (value: string[], full?: boolean) => {
  return value
    .filter((v) => v)
    .map((v, i) => {
      switch (i) {
        case 0:
          return v;
        case 1:
          return full ? v : v?.[0]?.toUpperCase();
        case 2:
          return full ? v : v?.[0]?.toUpperCase();
        default:
          return null;
      }
    })
    .filter((v) => v)
    .join(' ');
};

export const getWordNumEnding = (iNumber = 0, endings = ['день', 'дня', 'дней']) => {
  let sEnding;
  let i;
  const aEndings = endings; // 1 4 5

  iNumber = Number(iNumber) % 100;

  if (iNumber >= 11 && iNumber <= 19) {
    sEnding = aEndings[2];
  } else {
    i = iNumber % 10;

    switch (i) {
      case 1:
        sEnding = aEndings[0];
        break;
      case 2:
      case 3:
      case 4:
        sEnding = aEndings[1];
        break;
      default:
        sEnding = aEndings[2];
    }
  }

  return sEnding;
};

export const hasMore = (page: number, pageSize: number, total: number): boolean => (page + 1) * pageSize < total;
