import React, { useState } from 'react';
import classNames from 'classnames';
import ContentCard from '../../../components/ContentCard';
import styles from './SettingsPageAdmin.module.sass';
import SettingsPageAdminTariffs from './SettingsPageAdminTariffs';
import SettingsPageAdminSpec from './SettingsPageAdminSpec/SettingsPageAdminSpec';
import SettingsPageAdminCategories from './SettingsPageAdminCategories/SettingsPageAdminCategories';
import SettingsPageAdminSpecGroups from './SettingsPageAdminSpecGroups/SettingsPageAdminSpecGroups';

type SettingsPageAdminTab = 'tariffs' | 'specializations' | 'ds_groups ' | 'categories';
const SettingsPageAdmin = (props: any) => {
  const [view, setView] = useState<SettingsPageAdminTab>('tariffs');
  const renderView = (): JSX.Element => {
    switch (view) {
      case 'tariffs':
        return (
          <>
            <SettingsPageAdminTariffs />
          </>
        );
      case 'specializations':
        return (
          <>
            <SettingsPageAdminSpec />
          </>
        );
      case 'ds_groups ':
        return (
          <>
            <SettingsPageAdminSpecGroups />
          </>
        );
      case 'categories':
        return (
          <>
            <SettingsPageAdminCategories />
          </>
        );
      default:
        return <></>;
    }
  };
  return (
    <>
      <ContentCard>
        <ContentCard.Header className={styles.SettingsPageAdmin_Header}>
          <div className={`page-header-menu ${styles.SettingsPageAdmin_Header_Menu}`}>
            <div
              className={classNames('page-header-menu-item', styles.SettingsPageAdmin_Header_Menu_Item, {
                'page-header-menu-item-active': view === 'tariffs',
              })}
              onClick={() => {
                view !== 'tariffs' && setView('tariffs');
              }}
            >
              Тарифы
            </div>

            <div
              className={classNames('page-header-menu-item', styles.SettingsPageAdmin_Header_Menu_Item, {
                'page-header-menu-item-active': view === 'specializations',
              })}
              onClick={(e) => {
                view !== 'specializations' && setView('specializations');
              }}
            >
              Специализация
            </div>

            <div
              className={classNames('page-header-menu-item', styles.SettingsPageAdmin_Header_Menu_Item, {
                'page-header-menu-item-active': view === 'ds_groups ',
              })}
              onClick={(e) => {
                view !== 'ds_groups ' && setView('ds_groups ');
              }}
            >
              Группы специализаций
            </div>

            <div
              className={classNames('page-header-menu-item', styles.SettingsPageAdmin_Header_Menu_Item, {
                'page-header-menu-item-active': view === 'categories',
              })}
              onClick={(e) => {
                view !== 'categories' && setView('categories');
              }}
            >
              Категории
            </div>
          </div>
        </ContentCard.Header>
        <ContentCard.Main className={styles.SettingsPageAdmin_Content}>{renderView()}</ContentCard.Main>
      </ContentCard>
    </>
  );
};

export default SettingsPageAdmin;
