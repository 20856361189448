import {
  MANAGE_DOCTORS_LOADING,
  MANAGE_DOCTORS_SUCCESS,
  MANAGE_DOCTORS_ERRORS,
  MANAGE_DOCTOR_UPDATE,
  MANAGE_DOCTOR_UPDATE_ACTIVE,
} from '../actions';
import Manage from '../../types/manage';

export interface ManageDoctorsInitialSettings {
  doctors?: Manage.Doctor.Item[];
  page?: number;
  pageSize?: number;
  totalItems?: number;
  hasMore?: boolean;
  errors?: any;
  loading?: boolean;
}
const initialSettings: ManageDoctorsInitialSettings = {
  doctors: [],
  page: 0,
  pageSize: 50,
  totalItems: 0,
  hasMore: false,
  loading: false,
  errors: null,
};

const manageDoctorsReducer = (state = initialSettings, action): ManageDoctorsInitialSettings => {
  switch (action.type) {
    case MANAGE_DOCTOR_UPDATE:
      return {
        ...state,
        doctors: state.doctors.map((d) => ({
          ...d,
          availability: {
            ...d.availability,
            value: d.id === action.doctorId ? action.status : d.availability.value,
          },
        })),
      };
    case MANAGE_DOCTOR_UPDATE_ACTIVE:
      return {
        ...state,
        doctors: state.doctors.map((d) => ({
          ...d,
          active: (d.id === action.doctorId ? action.active : d.active) === true,
        })),
      };
    case MANAGE_DOCTORS_LOADING:
      return {
        ...state,
        doctors: action.loading && action.reset ? [] : state.doctors,
        loading: action.loading,
      };
    case MANAGE_DOCTORS_SUCCESS:
      return {
        ...state,
        doctors: action.page > 0 ? state.doctors.concat(action.doctors) : action.doctors,
        page: action.page,
        pageSize: action.pageSize,
        totalItems: action.totalItems,
        hasMore: (action.page + 1) * action.pageSize < action.totalItems && action.totalItems > 0,
        errors: null,
      };
    case MANAGE_DOCTORS_ERRORS:
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
};

export default manageDoctorsReducer;
