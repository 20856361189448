import React, { useEffect, useState, useRef, useCallback } from 'react';
import { format } from 'date-fns';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { decodeAvatarResource, getWordNumEnding, getMonthName } from 'utils/helpers';
import { fetchAppointments, getTabState, setTabState } from 'store/actions';
import { setAppointmentsStatus } from 'api';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import InfiniteScroll from 'react-infinite-scroller';

// Components
import ContentLoading from 'components/ContentLoading';
import ContentCard from 'components/ContentCard';
import Button from 'components/Forms/Button';
import PageLoader from 'components/PageLoader';

import styles from './AppointmentsPage.module.sass';
import homeStyles from '../HomePage/HomePage.module.sass';
import { AppointmentDisplayType } from '../../types';
import { datePlurals, getFullDateFormat, getTimeBetween, isBeforeOrEquals } from '../../utils/date';
import { usePrevious } from '../../components/hooks/usePrevious';
import { EnumDictionary } from '../../types/EnumDictionary';

const AppointmentsPage = (props: any) => {
  const isInitialMount = useRef(true);

  const { appointments } = getTabState();

  const PAGE_SIZE = 8;

  const [appointmentsViewType, setAppointmentsViewType] = useState<AppointmentDisplayType>(
    appointments || AppointmentDisplayType.AWAITS
  );
  const [appointmentsList, setAppointmentsList] = useState([...props.appointmentsList]);
  const [appointmentsPage, setAppointmentsPage] = useState(-1);
  const [hasMore, setHasMore] = useState(true);
  const [cancelAppointmentLoading, setCancelAppointmentLoading] = useState<string | boolean>(false);

  const prevViewType = usePrevious(appointmentsViewType);

  useEffect(() => {
    setAppointmentsPage(0);
    setHasMore(true);
    !appointments && setTabState({ appointments: appointmentsViewType });
    // props.fetchAppointments(appointmentsViewType, 0, String(PAGE_SIZE), true)
  }, [appointmentsViewType]);

  useEffect(() => {
    if (prevViewType !== appointmentsViewType) {
      props.fetchAppointments(appointmentsViewType, 0, String(PAGE_SIZE), true);
    } else if (appointmentsPage > 0) {
      props.fetchAppointments(appointmentsViewType, appointmentsPage, String(PAGE_SIZE));
    }
  }, [appointmentsPage, appointmentsViewType, prevViewType]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      if (
        props.appointmentsList.length % PAGE_SIZE !== 0 ||
        props.appointmentsList.length === appointmentsList.length
      ) {
        setHasMore(false);
      }

      setAppointmentsList([...props.appointmentsList]);
    }
  }, [props.appointmentsList]);

  const loadAppointments = () => {
    if (!appointmentsLoading && hasMore) {
      setAppointmentsPage(appointmentsPage + 1);
    }
  };

  const cancelAppointment = (id: string) => {
    setCancelAppointmentLoading(id);

    setAppointmentsStatus(id, 'CANCELED')
      .then((response) => {
        setCancelAppointmentLoading(false);

        const appointmentsListTemp = [...appointmentsList];

        console.log(appointmentsListTemp);
        console.log(appointmentsListTemp.filter((appoint) => appoint.id !== id));

        setAppointmentsList([...appointmentsListTemp.filter((appoint) => appoint.id !== id)]);

        toast.success('Консультация отменена');
      })
      .catch((error) => {
        setCancelAppointmentLoading(false);

        toast.error('Ошибка при отмене консультации');
      });
  };

  const getAppointmentDescr = useCallback((status: string, serviceType: string, startTime: string) => {
    let appointmentDescr = [];

    switch (status) {
      case 'AWAITS':
        if (serviceType === 'PLANNED') {
          appointmentDescr = [
            `Вы успешно записаны. Консультация состоится ${getFullDateFormat(new Date(startTime))}.`,
            `В назначенное время откройте консультацию и дождитесь ответа врача.`,
          ];
        } else if (serviceType === 'IMMEDIATE') {
          const now = new Date();
          const estimatedStartTime = new Date(startTime);
          if (isBeforeOrEquals(now, estimatedStartTime)) {
            const dateTimeUnits = getTimeBetween(new Date(estimatedStartTime), new Date());
            appointmentDescr = [
              `Пожалуйста, подождите. Врач ответит в течение 5-ти минут.`,
              `Предварительное время ожидания ${dateTimeUnits.hours} ${getWordNumEnding(
                dateTimeUnits.hours,
                datePlurals.hours
              )} ${dateTimeUnits.minutes} ${getWordNumEnding(dateTimeUnits.minutes, datePlurals.minutes)}.`,
            ];
          } else {
            appointmentDescr = ['Время ожидания дежурного врача истекло.'];
          }
        }
        break;

      case 'CANCELED':
        appointmentDescr = ['Консультация была отменена без списания средств.'];
        break;

      case 'DONE':
        appointmentDescr = ['Откройте консультацию, чтобы просмотреть протокол.'];
        break;

      case 'IN_PROGRESS':
        appointmentDescr = ['Идет консультация'];
        break;

      case 'FAILED':
        appointmentDescr = ['Консультация отменена из-за возникших технических проблем без списания средств.'];
        break;
      case 'FINISHED':
        appointmentDescr = ['Консультация закрыта. Через некоторое время доктор отправит вам заключение.'];
        break;
      default:
        break;
    }

    return appointmentDescr.join('<br />');
  }, []);

  const { appointmentsLoading, appointmentsLoadingError } = props;

  return (
    <>
      <ContentCard className="mb-4">
        <ContentCard.Header className={styles.appointmentsFilterCardHeader}>
          <div className={`page-header-menu ${styles.contentCard_menu}`}>
            <div
              className={classNames('page-header-menu-item', styles.contentCard_menu_item, {
                'page-header-menu-item-active': appointmentsViewType === AppointmentDisplayType.AWAITS,
              })}
              onClick={(e) => {
                setTabState({ appointments: AppointmentDisplayType.AWAITS });
                setAppointmentsViewType(AppointmentDisplayType.AWAITS);
              }}
            >
              Предстоящие
            </div>

            <div
              className={classNames('page-header-menu-item', styles.contentCard_menu_item, {
                'page-header-menu-item-active': appointmentsViewType === AppointmentDisplayType.DONE,
              })}
              onClick={(e) => {
                setTabState({ appointments: AppointmentDisplayType.DONE });
                setAppointmentsViewType(AppointmentDisplayType.DONE);
              }}
            >
              Завершенные
            </div>

            <div
              className={classNames('page-header-menu-item', styles.contentCard_menu_item, {
                'page-header-menu-item-active': appointmentsViewType === AppointmentDisplayType.CANCELED,
              })}
              onClick={(e) => {
                setTabState({ appointments: AppointmentDisplayType.DONE });
                setAppointmentsViewType(AppointmentDisplayType.CANCELED);
              }}
            >
              Отмененные
            </div>
          </div>
        </ContentCard.Header>
      </ContentCard>

      <ContentLoading
        isLoading={appointmentsLoading}
        isError={appointmentsLoadingError}
        fetchData={() => {
          if (appointmentsPage === 0) {
            props.fetchAppointments(appointmentsViewType, 0, String(PAGE_SIZE), true);
          } else {
            setAppointmentsPage(0);
            setHasMore(true);
          }
        }}
        loadingOverlay
      >
        {appointmentsList.length === 0 && <div className="mb-3 mt-md-4 text-center">Не найдено</div>}

        <InfiniteScroll
          className={styles.appointments_infinitescroll}
          pageStart={0}
          hasMore={appointmentsLoading ? false : hasMore}
          initialLoad={false}
          threshold={50}
          loadMore={loadAppointments}
          loader={appointmentsLoading && appointmentsPage != 0 ? <PageLoader /> : null}
        >
          <div className={homeStyles.doctorsList}>
            {appointmentsList.map((appointment) => (
              <Link
                key={appointment.id}
                className={classNames({
                  'link-disabled': !appointment.doctor && appointment?.serviceType?.value !== 'DAILY',
                })}
                to={`/appointments/${appointment.id}`}
              >
                <div className={homeStyles.doctorsList_card}>
                  <div className={homeStyles.doctorsList_card_wrapper}>
                    <div
                      className={homeStyles.doctorsList_card_avatar}
                      style={{
                        backgroundImage: `url(${decodeAvatarResource(
                          appointment.doctor && appointment.doctor.avatar,
                          360
                        )})`,
                      }}
                    />
                    <div className={homeStyles.doctorsList_card_about}>
                      <div className={homeStyles.doctorsList_card_name}>
                        {appointment.doctor
                          ? `${appointment.doctor.firstName} ${appointment.doctor.lastName}`
                          : 'Доктор'}
                      </div>

                      <div className={homeStyles.doctorsList_card_descr}>
                        {appointment.specialization && appointment.specialization.name}
                      </div>

                      <div className={homeStyles.doctorsList_card_descr}>{appointment.type?.name}</div>
                    </div>
                  </div>
                  <div
                    className={homeStyles.doctorsList_card_descr}
                    dangerouslySetInnerHTML={{
                      __html: getAppointmentDescr(
                        appointment.status && appointment.status.value,
                        appointment.serviceType && appointment.serviceType.value,
                        appointment.startTime
                      ),
                    }}
                  />
                  {appointment.status && appointment.status.value === 'AWAITS' ? (
                    <Button
                      className={homeStyles.doctorsList_card_btn}
                      block
                      color="primary"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        cancelAppointment(appointment.id);
                      }}
                      isLoading={appointment.id === cancelAppointmentLoading}
                    >
                      Отмена
                    </Button>
                  ) : null}
                </div>
              </Link>
            ))}
          </div>
        </InfiniteScroll>
      </ContentLoading>
    </>
  );
};

export default connect(
  (state: any) => ({
    appointmentsList: state.appointments.appointmentsList,
    appointmentsLoading: state.appointments.appointmentsLoading,
    appointmentsLoadingError: state.appointments.appointmentsLoadingError,
  }),
  { fetchAppointments }
)(AppointmentsPage);
