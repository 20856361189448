import axios from 'utils/axios';
import { AxiosResponse } from 'axios';

export const getServicePackageAttachments = (servicePackageId?: string, isGroup?: boolean): Promise<AxiosResponse> => {
  return axios.get(
    isGroup
      ? `/api/v1/servicepackagegroups/attachments/${servicePackageId}`
      : `/api/v1/servicepackages/mfo/${servicePackageId}/attachments`
  );
};
