import { createStore } from 'effector';
import { endOfDay, endOfMonth, endOfWeek, startOfDay, startOfMonth, startOfWeek, subDays } from 'date-fns';
import { AgentDashboardData, AgentMetricsDateTypeType, Metrics } from '../types';
import { onAgentMetricsReset, onAgentMetricsSetDate, onAgentMetricsSetType } from './events';
import { Maybe } from '../../../../types';
import { getAgentDashboardDataFx } from './effects';

export interface AgentMetricsStateInterface {
  type: AgentMetricsDateTypeType;
  fromDate: Date;
  toDate: Date;
  loading: boolean;
  error: any;
  metrics: Maybe<Metrics<AgentDashboardData>>;
}
const currentDate = new Date();
const defaultState: AgentMetricsStateInterface = {
  type: AgentMetricsDateTypeType.NOW,
  fromDate: startOfDay(currentDate),
  toDate: endOfDay(currentDate),
  loading: false,
  error: null,
  metrics: null,
};

const $store = createStore(defaultState);
$store.on(onAgentMetricsReset, () => ({ ...defaultState }));
$store.on(getAgentDashboardDataFx.fail, (_, error) => ({ ..._, error }));
$store.on(getAgentDashboardDataFx.pending, (_, loading) => ({ ..._, loading }));
$store.on(getAgentDashboardDataFx.doneData, (_, metrics) => ({ ..._, metrics }));
$store.on(onAgentMetricsSetType, (_, type) => {
  const date = new Date();
  let from = startOfDay(date);
  let to = endOfDay(date);
  switch (type) {
    case AgentMetricsDateTypeType.TOMORROW: {
      const _ = subDays(date, 1);
      from = startOfDay(_);
      to = endOfDay(_);
      break;
    }
    case AgentMetricsDateTypeType.WEEK: {
      from = startOfWeek(from);
      to = endOfWeek(to);
      break;
    }
    case AgentMetricsDateTypeType.MONTH: {
      from = startOfMonth(from);
      to = endOfMonth(to);
      break;
    }
    default:
      break;
  }
  return { ..._, type, fromDate: from, toDate: to };
});
$store.on(onAgentMetricsSetDate, (_, from) => ({
  ..._,
  type: AgentMetricsDateTypeType.CUSTOM,
  fromDate: startOfDay(from),
  toDate: endOfDay(from),
}));
export default $store;
