import axios from 'utils/axios';
import { AxiosResponse } from 'axios';
import { DoctorFilterRequest, GetDoctorsResponseInterface, ResponseDataInterface } from '../types';

export const getDoctorPersonals = (): Promise<AxiosResponse<ResponseDataInterface<any>>> =>
  axios.get(`/api/v1/doctors/personals`);

export const getDoctors = (
  params: DoctorFilterRequest
): Promise<AxiosResponse<ResponseDataInterface<GetDoctorsResponseInterface>>> =>
  axios.get(`/api/v1/doctors`, { params });

export const addPersonalDoctor = (id: string): Promise<AxiosResponse<ResponseDataInterface<any>>> =>
  axios.post(`/api/v1/doctors/personals/${id}`);
