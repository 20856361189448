import axios from 'utils/axios';

type appointmentType = {
  specializationId: string;
  patientServicePackageId: string;
};

export const payAppointmentsImmediate = (appointment: appointmentType) => {
  return axios.post('/api/v1/appointments/immediate', {
    ...appointment,
  });
};
