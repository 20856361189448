import axios from 'utils/axios';

// type NotificationType = 'APPOINTMENT_NEW' | 'APPOINTMENT_CANCEL' | 'APPOINTMENT_STARTED' | 'APPOINTMENT_CONCLUSION' | 'MONEY_INCOME' | 'MONEY_OUTCOME' | 'NEWS';
// type ChannelType = 'SMS' | 'EMAIL' | 'PUSH'

export const setNotificationsSettings = (
  channel: string, 
  action: string, 
  enabled: boolean
) => {
  return axios.put(`/api/v1/notifications/settings/${channel}/${action}/enabled`, {
    enabled
  });
};
