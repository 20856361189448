import {getAgentServicePackage, getAgentServicePackages} from "../../../api/agent/getAgentServicePackages";
import {AxiosResponse} from "axios";
import Agent from "../../../types/agent";
import Manage from "../../../types/manage";
import {ResponseData} from "../../../types";

export const ASP_LOADING = 'ASP_LOADING';
export const ASP_SUCCESS = 'ASP_SUCCESS';
export const ASP_ERRORS = 'ASP_ERRORS';
export const ASP_UPDATE = 'ASP_UPDATE';

const setLoading = (loading: boolean, reset?: boolean) => ({
  type: ASP_LOADING,
  loading,
  reset
})

const setError = (errors: any) => ({type: ASP_ERRORS, errors})

const setServicePackages = (
  items: Array<Manage.Agent.ServicePackage.Item>,
  page: number,
  pageSize: number,
  totalItems: number
) => ({
  type: ASP_SUCCESS,
  items,
  page,
  pageSize,
  totalItems,
});

const updateAgentServicePackage = (item: Manage.Agent.ServicePackage.Item) => ({
  type: ASP_UPDATE,
  item
})

export const fetchMyAgentServicePackages = (page?: number,
                                            pageSize?: number,
                                            statuses?: Array<string>,
                                            servicePackagesIds?: Array<string>,
                                            reset?: boolean,): any => {
  return (dispatch) => {
    dispatch(setLoading(true, reset))
    getAgentServicePackages({
      page: page,
      pageSize,
      statuses,
      servicePackagesIds
    }).then((response: AxiosResponse<Agent.Response>) => {
      dispatch(setLoading(false));
      if (response.data.code === 'success') {
        const data = response.data.data
        dispatch(setServicePackages(data.items, data.page, data.pageSize, data.totalItems));
      } else {
        dispatch(setError(response.data));
      }
    })
      .catch((error: any) => {
        dispatch(setError(error));
        dispatch(setLoading(false));
      });
  }
}

export const fetchMyAgentServicePackage = (id: string) => {
  return (dispatch) => {
    getAgentServicePackage(id)
      .then((response: AxiosResponse<ResponseData<Manage.Agent.ServicePackage.Item>>) => {
        if (response.data.code === 'success') {
          const data = response.data.data
          dispatch(updateAgentServicePackage(data));
        }
      })
  }
}
