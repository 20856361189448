import FILE_TYPE_ICONS from 'constants/file-type-icons';

export default (fileName: string) => {
  const ext = fileName.match(/\.[0-9a-z]+$/);

  if (ext && ext.length) {
    const fileExt = ext[ext.length - 1].replace('.', '');

    return FILE_TYPE_ICONS[fileExt];
  }

  return FILE_TYPE_ICONS.doc;
};
