import { AxiosResponse } from 'axios';
import axios from 'utils/axios';
import { ResponseData } from '../../../types';
import { createResource } from '../../../api/createResource';

export const setAppointmentsStatusApi = (appointmentId: string, status: any): Promise<AxiosResponse> => {
  return axios.put(`/api/v1/appointments/${appointmentId}/status`, {
    status,
  });
};

export const getAppointmentApi = (appontmentId: string): Promise<AxiosResponse<ResponseData>> => {
  return axios.get(`/api/v1/appointments/${appontmentId}`);
};

export const getAppointmentDictionaryApi = (appontmentId: string): Promise<AxiosResponse<ResponseData>> => {
  return axios.get(`/api/v1/appointments/dictionary/${appontmentId}`);
};
export const getAppointmentDictionaryByCodeApi = (
  dictionary: string,
  value?: string
): Promise<AxiosResponse<Array<any>>> => {
  return axios.get(`/api/v1/appointments/iemk/${dictionary}`, { params: { value } });
};

export const addRecommendationsApi = (
  appointmentId: string,
  anamnesis: string,
  recommendations: string,
  dictionaries?: Array<any>
): Promise<AxiosResponse<ResponseData>> =>
  axios.put(`/api/v1/appointments/${appointmentId}/recommendations`, {
    anamnesis,
    recommendations,
    dictionaries,
  });

export const addAttachmentByDoctorApi = (appointmentId: string, files: File[]): Promise<AxiosResponse<ResponseData>> =>
  Promise.all(files.map((file) => createResource(file))).then((resourceIds: any) =>
    axios.post(`/api/v1/appointments/${appointmentId}/doctorattachment`, {
      resourceIds,
      type: 'CONCLUSION',
    })
  );
