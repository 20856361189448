import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Popover } from '@material-ui/core';
import classNames from 'classnames';
import { ReactComponent as CalendarIcon } from 'assets/img/icons/calendar.svg';
import Calendar from 'components/Calendar';
import { useStore } from 'effector-react';
import { format } from 'utils/date';
import styles from './styles.module.sass';
import store from '../../store';
import { onAgentMetricsSetDate, onAgentMetricsSetType } from '../../store/events';
import { AgentMetricsDateTypeType } from '../../types';
import { AgentMetricsDateTypeTypeTranslation } from '../../constants/translations';

const AgentMetricsFilter = (): JSX.Element => {
  const { type, fromDate } = useStore(store);
  const handleChangeType = useCallback((type: AgentMetricsDateTypeType) => {
    onAgentMetricsSetType(type);
  }, []);
  const [customDate, setCustomDate] = useState(fromDate);
  const [anchorCalendar, setOpenCalendar] = useState<HTMLButtonElement | null>(null);
  const isOpenCalendar = useMemo(() => Boolean(anchorCalendar), [anchorCalendar]);
  const onChangeDate = useCallback((date: Date) => {
    setCustomDate(date);
    onAgentMetricsSetDate(date);
    setOpenCalendar(null);
  }, []);
  const handleReset = useCallback(() => {
    onAgentMetricsSetType(AgentMetricsDateTypeType.NOW);
  }, []);
  useEffect(() => {
    type !== AgentMetricsDateTypeType.CUSTOM && setCustomDate(new Date());
  }, [type]);
  return (
    <>
      <Box className={styles.root}>
        <Box className={styles.root_filters}>
          {Object.values(AgentMetricsDateTypeType)
            .filter((k) => k !== AgentMetricsDateTypeType.CUSTOM)
            .map((k) => (
              <Box
                key={k}
                onClick={() => handleChangeType(k)}
                className={classNames(styles.root_filter, type === k && styles.root_filter_active)}
              >
                {AgentMetricsDateTypeTypeTranslation[k]}
              </Box>
            ))}
        </Box>
        <Box
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            setOpenCalendar(event.currentTarget);
          }}
          className={classNames(
            styles.root_filter,
            styles.root_filter_date,
            type === AgentMetricsDateTypeType.CUSTOM && styles.root_filter_date_active
          )}
        >
          <Box />
          <CalendarIcon />
          <Box>
            {type === AgentMetricsDateTypeType.CUSTOM
              ? format(fromDate, 'dd.MM.yyyy')
              : AgentMetricsDateTypeTypeTranslation[AgentMetricsDateTypeType.CUSTOM]}
          </Box>
        </Box>
        <Box className={styles.root_filters_reset} onClick={handleReset}>
          сбросить
        </Box>
        <Popover
          open={isOpenCalendar}
          anchorEl={anchorCalendar}
          onClose={() => setOpenCalendar(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Box p={2}>
            <Calendar maxDate={new Date()} onChangeDate={onChangeDate} date={customDate} />
          </Box>
        </Popover>
      </Box>
    </>
  );
};

export default AgentMetricsFilter;
