import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { connect } from 'react-redux';
import classNames from 'classnames';
import ContentCard from '../../../components/ContentCard';
import styles from './AgentsPageAdmin.module.sass';
import Button from '../../../components/Forms/Button';
import Manage from '../../../types/manage';
import { fetchAgents } from '../../../store/actions';
import { ManageAgentsSettings } from '../../../store/reducers/manageAgents.reducer';
import ContentLoading from '../../../components/ContentLoading';
import AgentModifyDialog from '../../../components/AgentModifyDialog';
import AgentTokenDialog from '../../../components/AgentTokenDialog';
import AgentCertsGenerateDialog from '../../../components/AgentCertificateGeneratorDialog';
import AgentPasswordDialog from '../../Agent/AgentPasswordDialog';
import useHasMore from '../../../hooks/useHasMore';

interface AgentsPageAdminProps {
  match: any;
  history: any;
  page: number;
  pageSize: number;
  totalItems: number;
  loading: boolean;
  errors: any;
  items: Array<Manage.Agent.Item>;
  fetchAgents: (partnerId: string, page?: number, pageSize?: number, reset?: boolean) => void;
}

const AgentsPageAdmin: React.FC<AgentsPageAdminProps> = (props) => {
  const { totalItems, match, page, pageSize, loading, errors, items = [], fetchAgents } = props;

  const [agent, setAgent] = useState<Manage.Agent.Item>(null);
  const [agentToken, setAgentToken] = useState<string>(null);
  const [generatorByAgentId, setGeneratorAgentId] = useState<string>(null);
  const [passwordAgentId, setPasswordAgentId] = useState<string>(null);
  const hasMore = useHasMore(totalItems, page + 1, pageSize);

  const onCreateAgentClick = () => {
    setAgent({});
  };

  const onLoadMore = useCallback((): void => {
    fetchAgents(match.params.partnerId, page + 1, pageSize);
  }, [fetchAgents, match.params.partnerId, page, pageSize]);
  const getData = useCallback(
    () => fetchAgents(match.params.partnerId, 0, pageSize, true),
    [fetchAgents, match.params.partnerId, pageSize]
  );
  useEffect(() => getData(), [getData]);
  return (
    <>
      <ContentCard>
        <ContentCard.Header>
          <div className="row">
            <div className="col-12 text-center text-xs-right text-md-center">
              <h6 className="page-header-title">Агенты</h6>
            </div>
          </div>
        </ContentCard.Header>
        <Box className={styles.AgentsPageAdmin_Toolbar}>
          <Box className={styles.AgentsPageAdmin_Toolbar_Buttons}>
            <Button color="primary" size="md" onClick={onCreateAgentClick}>
              Создать агента
            </Button>
          </Box>
        </Box>
        <ContentCard.Main>
          <>
            <Box p={0} width="100%" height="100%" display="flex" flexDirection="column" justifyContent="center">
              <>
                {items.length === 0 && !(loading || errors) && (
                  <Box className={styles.AgentsPageAdmin_EmptyLabel}>Агентов по вашему запросу не найдено</Box>
                )}
                {items.map((value) => {
                  return (
                    <Box key={`partner-${value.id}`} className={styles.AgentsPageAdmin_Row}>
                      <Box className={styles.AgentsPageAdmin_Row_Main}>
                        <Box className={styles.AgentsPageAdmin_Row_Cell}>
                          <Box className={styles.AgentsPageAdmin_Profile}>
                            <Box className={classNames(styles.AgentsPageAdmin_Profile_Item)}>
                              {[value.firstName, value.middleName, value.lastName].filter((s) => s).join(' ')}
                            </Box>
                            <Box className={classNames(styles.AgentsPageAdmin_Profile_Item)}>{value.email}</Box>
                            <Box className={classNames(styles.AgentsPageAdmin_Profile_Item)}>
                              {value.groups.map((group) => group.name).join(', ')}
                            </Box>
                          </Box>
                        </Box>
                        <Box className={styles.AgentsPageAdmin_Row_Cell} style={{ justifyContent: 'flex-end' }}>
                          <Box display="flex" flexDirection="column">
                            <Button
                              className={styles.AgentsPageAdmin_Button}
                              onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                setAgent(value);
                              }}
                              size="sm"
                              color="default"
                            >
                              Редактировать
                            </Button>
                            <Button
                              className={styles.AgentsPageAdmin_Button}
                              onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                setGeneratorAgentId(value.id);
                              }}
                              size="sm"
                              color="default"
                            >
                              Генерация
                            </Button>
                            <Button
                              className={styles.AgentsPageAdmin_Button}
                              onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                setAgentToken(value.id);
                              }}
                              size="sm"
                              color="default"
                            >
                              Получить токен
                            </Button>
                            <Button
                              className={styles.AgentsPageAdmin_Button}
                              onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                setPasswordAgentId(value.id);
                              }}
                              size="sm"
                              color="default"
                            >
                              Задать пароль
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
                {(loading || errors) && (
                  <Box width="100%" p={2}>
                    <ContentLoading isLoading={loading} isError={errors} fetchData={getData} />
                  </Box>
                )}
                {hasMore && !loading && !errors && (
                  <Box className="d-flex justify-content-center align-items-center mt-5">
                    <Button size="md" color="default" onClick={onLoadMore}>
                      Показать еще
                    </Button>
                  </Box>
                )}
              </>
            </Box>
          </>
        </ContentCard.Main>
      </ContentCard>
      {agent && (
        <AgentModifyDialog
          editItem={agent}
          onUpdated={() => {
            setAgent(null);
            getData();
          }}
          partnerId={match.params.partnerId}
          onClosed={() => setAgent(null)}
          title={null}
        />
      )}
      {agentToken && <AgentTokenDialog agentId={agentToken} onClosed={() => setAgentToken(null)} />}
      {generatorByAgentId && (
        <AgentCertsGenerateDialog agentId={generatorByAgentId} onClosed={() => setGeneratorAgentId(null)} />
      )}
      {passwordAgentId && <AgentPasswordDialog agentId={passwordAgentId} onClosed={() => setPasswordAgentId(null)} />}
    </>
  );
};

export default connect(
  (state: any) => ({
    ...(state.manageAgents as ManageAgentsSettings),
  }),
  {
    fetchAgents,
  }
)(AgentsPageAdmin);
