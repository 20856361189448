import { AxiosResponse } from 'axios';
import Manage from '../../types/manage';
import { findAdminAppointmentItems } from '../../api/findAdminAppointmentItems';

export const MANAGE_APPOINTMENT_ITEMS_LOADING = 'MANAGE_APPOINTMENT_ITEMS_LOADING';
export const MANAGE_APPOINTMENT_ITEMS_SUCCESS = 'MANAGE_APPOINTMENT_ITEMS_SUCCESS';
export const MANAGE_APPOINTMENT_ITEMS_ERRORS = 'MANAGE_APPOINTMENT_ITEMS_ERRORS';

export const setManageAppointmentsItemsLoading = (loading: boolean, reset?: boolean) => ({
  type: MANAGE_APPOINTMENT_ITEMS_LOADING,
  loading,
  reset,
});

export const setManageAppointmentsItemsErrors = (errors: any) => ({ type: MANAGE_APPOINTMENT_ITEMS_ERRORS, errors });

export const setManageAppointmentsItems = (
  items: Manage.AppointmentItem.Item[],
  page: number,
  pageSize: number,
  totalItems: number
) => ({
  type: MANAGE_APPOINTMENT_ITEMS_SUCCESS,
  items,
  page,
  pageSize,
  totalItems,
});

export const fetchManageAppointmentsItems = (
  patientIds?: string[],
  doctorIds?: string[],
  statuses?: string[],
  types?: string[],
  payedWithPromo?: boolean,
  timeFrom?: Date,
  timeTo?: Date,
  page?: number,
  pageSize?: number,
  reset?: boolean
): any => {
  return (dispatch) => {
    dispatch(setManageAppointmentsItemsLoading(true, reset));
    findAdminAppointmentItems(patientIds, doctorIds, statuses, types, payedWithPromo, timeFrom, timeTo, page, pageSize)
      .then((response: AxiosResponse<Manage.AppointmentItem.Response>) => {
        dispatch(setManageAppointmentsItemsLoading(false));
        if (response.data.code === 'success') {
          dispatch(
            setManageAppointmentsItems(
              response.data.data.items,
              response.data.data.page,
              response.data.data.pageSize,
              response.data.data.totalItems
            )
          );
        } else {
          dispatch(setManageAppointmentsItemsErrors(response.data));
        }
      })
      .catch((error: any) => {
        dispatch(setManageAppointmentsItemsErrors(error));
        dispatch(setManageAppointmentsItemsLoading(false));
      });
  };
};
