import {
  MANAGE_DAILY_CONCLUSION_STRATEGIES_ERRORS,
  MANAGE_DAILY_CONCLUSION_STRATEGIES_LOADING,
  MANAGE_DAILY_CONCLUSION_STRATEGIES_RULES_ERRORS,
  MANAGE_DAILY_CONCLUSION_STRATEGIES_RULES_LOADING,
  MANAGE_DAILY_CONCLUSION_STRATEGIES_RULES_SUCCESS,
  MANAGE_DAILY_CONCLUSION_STRATEGIES_SUCCESS,
} from '../actions';

export interface ManageDailyConclusionStrategiesInitialSettings {
  strategies?: any;
  page?: number;
  pageSize?: number;
  totalItems?: number;
  hasMore?: boolean;
  errors?: any;
  loading?: boolean;
  rules?: any;
  pageRules?: number;
  pageSizeRules?: number;
  totalItemsRules?: number;
  hasMoreRules?: boolean;
  errorsRules?: any;
  loadingRules?: boolean;
}
const initialSettings: ManageDailyConclusionStrategiesInitialSettings = {
  strategies: null,
  page: 0,
  pageSize: 50,
  totalItems: 0,
  hasMore: false,
  loading: false,
  errors: null,
};

const manageDailyConclusionStrategiesReducer = (
  state = initialSettings,
  action
): ManageDailyConclusionStrategiesInitialSettings => {
  switch (action.type) {
    case MANAGE_DAILY_CONCLUSION_STRATEGIES_LOADING:
      return {
        ...state,
        strategies: action.loading && action.reset ? [] : state.strategies,
        loading: action.loading,
      };
    case MANAGE_DAILY_CONCLUSION_STRATEGIES_SUCCESS:
      return {
        ...state,
        strategies: action.page > 0 ? state.strategies.concat(action.strategies) : action.strategies,
        page: action.page,
        pageSize: action.pageSize,
        totalItems: action.totalItems,
        hasMore: (action.page + 1) * action.pageSize < action.totalItems && action.totalItems > 0,
        errors: null,
      };
    case MANAGE_DAILY_CONCLUSION_STRATEGIES_ERRORS:
      return {
        ...state,
        errors: action.errors,
      };
    case MANAGE_DAILY_CONCLUSION_STRATEGIES_RULES_LOADING:
      return {
        ...state,
        rules: action.loading && action.reset ? null : state.rules,
        loadingRules: action.loading,
      };
    case MANAGE_DAILY_CONCLUSION_STRATEGIES_RULES_SUCCESS:
      return {
        ...state,
        rules: action.page > 0 ? state.rules.concat(action.rules) : action.rules,
        pageRules: action.page,
        pageSizeRules: action.pageSize,
        totalItemsRules: action.totalItems,
        hasMoreRules: (action.page + 1) * action.pageSize < action.totalItems && action.totalItems > 0,
        errorsRules: null,
      };
    case MANAGE_DAILY_CONCLUSION_STRATEGIES_RULES_ERRORS:
      return {
        ...state,
        errorsRules: action.errors,
      };
    default:
      return state;
  }
};

export default manageDailyConclusionStrategiesReducer;
