import axios from 'utils/axios';

export const getPatientsAutocomplete = (query: string, page: number, pageSize: number) => {
  return axios.get('/api/v1/profile/autocomplete', {
    params: {
      query,
      page,
      pageSize,
    },
  });
};
