import React, { useState } from 'react';
import classNames from 'classnames';
import CloseIcon from '@material-ui/icons/Close';
import { Backdrop, Box, Dialog, Grid } from '@material-ui/core';
import { useFormValidation } from '../../utils/hooks/useFormValidation';
import Manage from '../../types/manage';
import styles from './AdminSpecGroupView.module.sass';
import InputField from '../Forms/InputField';
import Button from '../Forms/Button';
import ContentLoading from '../ContentLoading';
import FormValidator from '../Forms/FormValidator';
import AdminSpecSelectorView from '../AdminSpecSelectorView';
import manageSpecGroupsApi from '../../api/manageSpecGroups';
import { displayErrors, handleHttpError } from '../../utils/handleHttpError';
import ContentCard from '../ContentCard';

interface PropsInterface {
  onUpdated: (id: string) => void;
  editItem?: Manage.Spec.Group;
  [key: string]: any;
}

const AdminSpecGroupView = (props: PropsInterface): JSX.Element => {
  const { onClosed, onUpdated, editItem = {} } = props;

  const [isLoading, setLoading] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
  const [fields, validateOnChange, getFieldErrors, _, setFormErrors] = useFormValidation<any>({
    name: editItem.name,
    description: editItem.description,
    specializationIds: editItem.specializations?.map((value) => value.id) || [],
  });

  const [isActive, setActive] = useState<boolean>(['ACTIVE', 'ARCHIVE'].includes(editItem.status?.value));

  const isNew: boolean = !editItem.id;
  const isPrevActive = editItem.status?.value === 'ACTIVE';
  const isArchived = editItem.status?.value === 'ARCHIVE';

  const onSave = (e) => {
    e.preventDefault();
    const form = e.target;
    const inputs = [...form.elements].filter((i) => ['INPUT', 'SELECT', 'TEXTAREA'].includes(i.nodeName));

    const { hasError, errors } = FormValidator.bulkValidate(inputs);

    setFormErrors([...errors]);

    if (!hasError) {
      sendData(isNew);
    }
  };

  const sendData = (isCreate: boolean) => {
    setLoading(true);
    if (isArchived && !isActive) {
      manageSpecGroupsApi
        .updateActiveStatus(editItem.id, true)
        .then((res) => {
          setLoading(false);
          if (res.data.code === 'success') {
            onUpdated(editItem.id);
          } else {
            showError(res);
          }
        })
        .catch(showError);
    } else if (isCreate) {
      manageSpecGroupsApi
        .createSpecGroup(fields)
        .then((res) => {
          setLoading(false);
          if (res.data.code === 'success') {
            onUpdated(res.data.data.id);
          } else {
            showError(res);
          }
        })
        .catch(showError);
    } else {
      manageSpecGroupsApi
        .updateSpecGroup(editItem.id, fields)
        .then(async (res) => {
          setLoading(false);
          if (res.data.code === 'success') {
            if (isPrevActive !== isActive) {
              await manageSpecGroupsApi.updateActiveStatus(editItem.id, isActive);
            }
            onUpdated(editItem.id);
          } else {
            showError(res);
          }
        })
        .catch(showError);
    }
  };

  const showError = (error) => {
    const e = handleHttpError(error);
    displayErrors(e);
    setLoading(false);
  };

  return (
    <Dialog
      PaperProps={{ className: classNames(styles.Dialog) }}
      BackdropComponent={(props) => <Backdrop className={styles.Backdrop} {...props} />}
      onClose={onClosed}
      open
    >
      <ContentCard className={styles.AdminSpecGroupView}>
        <ContentCard.Header className={styles.AdminSpecGroupView_Header}>
          <h6 className="page-header-title">
            {isNew ? 'Создать группу' : `Редактировать группу${isArchived ? '(нельзя)' : ''}`}
          </h6>
          <CloseIcon onClick={onClosed} className={styles.AdminSpecGroupView_CloseIcon} />
        </ContentCard.Header>
        <ContentCard.Main className={styles.AdminSpecGroupView_Content}>
          <ContentLoading isLoading={isLoading} isError={false} fetchData={() => null}>
            <form onSubmit={onSave}>
              <Box className={classNames(styles.AdminSpecGroupView_Content_Wrapper)}>
                <Box className={classNames(styles.AdminSpecGroupView_Content_Item)}>
                  <Grid container justify="center" alignItems="center" spacing={2}>
                    <Grid item xs={12}>
                      <InputField
                        disabled={isArchived}
                        name="name"
                        maxLength={255}
                        minLength={1}
                        value={fields.name}
                        onChange={(e) => validateOnChange('name', e.target.value, e)}
                        data-validate='["required"]'
                        errors={getFieldErrors('name')}
                        block
                        label="Название"
                        placeholder="Название"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputField
                        disabled={isArchived}
                        type="textarea"
                        name="description"
                        maxLength={2048}
                        rows={12}
                        value={fields.description}
                        onChange={(e) => validateOnChange('description', e.target.value, e)}
                        block
                        label="Описание"
                        placeholder="Описание"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <AdminSpecSelectorView
                        onlyActive
                        disabled={isArchived || isPrevActive}
                        name="specializationIds"
                        value={fields.specializationIds}
                        isMulti
                        onChange={(option, e) => {
                          validateOnChange(
                            'specializationIds',
                            option?.map((v) => v.value),
                            e
                          );
                        }}
                        errors={getFieldErrors('specializationIds')}
                        data-validate='["required"]'
                        block
                        label="Доступные врачебные специализации"
                        placeholder="Доступные врачебные специализации"
                      />
                    </Grid>
                    {!isNew && (
                      <Grid item xs={12} container justify="space-between" alignItems="center" spacing={1}>
                        <Box>
                          {isArchived
                            ? isActive
                              ? 'В архиве'
                              : 'Активировать'
                            : isPrevActive
                            ? isActive
                              ? 'Активирован'
                              : 'Архивировать'
                            : isActive
                            ? 'Активировать'
                            : 'Новый'}
                        </Box>
                        <label className="toggle-checkbox-label">
                          <input
                            type="checkbox"
                            checked={isActive}
                            onChange={(event) => {
                              setActive(event.target.checked);
                            }}
                          />

                          <span className="checkmark">
                            <span className="check" />
                          </span>
                        </label>
                      </Grid>
                    )}
                    <Box mt={2} width="100%">
                      <Grid item xs={12} container justify="space-between" alignItems="center" spacing={1}>
                        <Grid item xs={6} lg="auto">
                          <Button className="mr-2" color="default" onClick={onClosed}>
                            Отмена
                          </Button>
                        </Grid>
                        <Grid item xs={6} lg="auto">
                          <Button type="submit" color="primary" isLoading={isLoading}>
                            {isNew ? 'Создать' : 'Обновить'}
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Box>
              </Box>
            </form>
          </ContentLoading>
        </ContentCard.Main>
      </ContentCard>
    </Dialog>
  );
};

export default AdminSpecGroupView;
