import React, { useCallback, useMemo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { UserRole } from 'types';
import { SIDEBAR_MENU } from 'constants/common';
import HasRole from 'components/HasRole';
import { AvailabilityStatus } from 'types/doctor';
import DoctorStatusLabel from '../DoctorStatus';
import styles from './SidebarMenu.module.sass';
import SidebarAgentRegistryMenu from '../SidebarAgentRegistryMenu';

const SidebarMenu = (): JSX.Element => {
  const role = useSelector<any, UserRole>((state) => state?.auth?.role as UserRole);
  const availability = useSelector<any, AvailabilityStatus>((state) => state?.doctor?.availability);
  const menu = useMemo(() => SIDEBAR_MENU?.[role] || [], [role]);
  const location = useLocation();
  const isActive = useCallback(
    (to: string): boolean =>
      ((location.pathname === to || location.pathname.startsWith(to)) && to !== '/') ||
      (to === '/' && location.pathname === to),
    [location]
  );
  return (
    <div className={styles.root}>
      <div className={styles.root_list}>
        {menu.map((_, index) => {
          const { icon: Icon, to, title, iconType } = _;
          return (
            <NavLink
              key={index}
              to={to}
              exact
              isActive={() => isActive(to)}
              className={styles.root_list_item}
              activeClassName={styles.root_list_item_active}
            >
              {Icon && (
                <Icon
                  className={
                    iconType === '3'
                      ? styles.root_list_item_icon3
                      : iconType === '2'
                      ? styles.root_list_item_icon2
                      : styles.root_list_item_icon
                  }
                />
              )}
              {to === '/profile' && (
                <HasRole target={UserRole.DOCTOR}>
                  <DoctorStatusLabel
                    style={{ position: 'absolute', top: 2, left: 30, width: 10, height: 10 }}
                    status={availability}
                  />
                </HasRole>
              )}
              {title}
            </NavLink>
          );
        })}
        <HasRole target={UserRole.AGENT}>
          <SidebarAgentRegistryMenu />
        </HasRole>
      </div>
    </div>
  );
};

export default SidebarMenu;
