import React, { PropsWithChildren, useEffect, useState } from 'react';
import ym, { YMInitializer } from 'react-yandex-metrika';
import { useLocation } from 'react-router-dom';

const isProduction = process.env.NODE_ENV === 'production';
interface PropsInterface extends PropsWithChildren<any> {
  accountId: number | null;
}
const AnalyticsProvider = (props: PropsInterface): JSX.Element => {
  const { children, accountId } = props;
  const location = useLocation();
  const [path, setPatch] = useState(location.pathname);
  useEffect(() => {
    setPatch((path) => (location.pathname !== path ? location.pathname : path));
  }, [location, setPatch]);
  useEffect(() => {
    accountId && isProduction && ym('hit', path);
  }, [path, accountId]);
  return (
    <>
      {accountId && isProduction && <YMInitializer accounts={[accountId]} options={{ webvisor: true }} version="2" />}
      {children}
    </>
  );
};
export default AnalyticsProvider;
