import React, { PropsWithChildren } from 'react';

import { Box } from '@material-ui/core';
import styles from './PageCard.module.sass';
import PageTitle from '../PageTitle';
import LinkBack from '../LinkBack';

interface PropsInterface extends PropsWithChildren<any> {
  title?: string;
  showBack?: boolean;
  backLink?: string;
  showHeader?: boolean;
}

const PageCard = (props: PropsInterface) => {
  const { title, showBack = false, backLink = null, children, showHeader = true } = props;
  return (
    <Box className={styles.root}>
      {showHeader && (
        <Box className={styles.root_header}>
          {(showBack || backLink) && (
            <Box className={styles.root_header_left}>
              <LinkBack link={backLink} />
            </Box>
          )}
          {title && (
            <Box className={styles.root_header_title}>
              <PageTitle>{title}</PageTitle>
            </Box>
          )}
          <Box className={styles.root_header_right} />
        </Box>
      )}
      <Box className={styles.root_content}>{children}</Box>
    </Box>
  );
};

export default PageCard;
