import React, { ChangeEvent, MouseEvent, useCallback, useState } from 'react';
import { Box } from '@material-ui/core';
import classNames from 'classnames';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import DeleteIcon from '@material-ui/icons/Delete';
import styles from './AdminStoriesListItem.module.sass';
import { convertUtcToZonedTime, format } from '../../../../../utils/date';
import { onDeleteStories, onGetStories, onToggleActiveItemStories } from '../../../../../store/stories/events';
import useRootSelector from '../../../../../hooks/useRootSelector';
import { selectStoriesLoading } from '../../../../../store/stories/selectors';
import AdminStoriesConfirmDelete from '../AdminStoriesConfirmDelete';
import AdminStoriesEditModal from '../AdminStoriesEditModal';

const DragHandle = SortableHandle(() => (
  <span>
    <DragHandleIcon />
  </span>
));
interface PropsInterface {
  item?: any;
}
const AdminStoriesListItem = SortableElement((props: PropsInterface): JSX.Element => {
  const { item } = props;
  const loading = useRootSelector(selectStoriesLoading);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [edit, setEdit] = useState(null);
  const handleToggleActive = useCallback(
    (event: MouseEvent<any> | ChangeEvent<any>) => {
      event.preventDefault();
      event.stopPropagation();
      onToggleActiveItemStories({ ...item, active: !item?.active });
    },
    [item]
  );
  const handleOpenConfirmDelete = useCallback((event: MouseEvent<any>) => {
    event.preventDefault();
    event.stopPropagation();
    setShowDeleteModal(true);
  }, []);
  const handleCloseConfirmDelete = useCallback(() => {
    setShowDeleteModal(false);
  }, []);
  const handleOpenEdit = useCallback(
    (event: MouseEvent<any>) => {
      event.preventDefault();
      event.stopPropagation();
      setEdit(item);
    },
    [item]
  );
  const handleCloseEdit = useCallback((isSaved?: boolean) => {
    setEdit(null);
    isSaved && onGetStories();
  }, []);
  const handleConfirmDelete = useCallback(() => {
    onDeleteStories(item?.id);
    setShowDeleteModal(false);
  }, [item?.id]);
  return (
    <>
      <Box className={styles.root} onClick={handleOpenEdit}>
        <Box className={classNames(styles.root_cell, styles.root_drag)} width="5%">
          <DragHandle />
        </Box>
        <Box className={styles.root_cell} width="10%">
          {item?.preview?.originalUrl && (
            <Box className={styles.root_content} style={{ backgroundImage: `url("${item?.preview?.originalUrl}")` }}>
              <img alt={item?.name} src={item?.preview?.originalUrl} width="50px" height="50px" />
            </Box>
          )}
        </Box>
        <Box className={classNames(styles.root_cell, styles.root_name)} width="35%">
          {item?.name}
        </Box>
        <Box className={styles.root_cell} width="25%">
          {item?.updatedAt ? format(convertUtcToZonedTime(item?.updatedAt), 'dd.MM.yyyy HH:mm') : null}
        </Box>
        <Box className={classNames(styles.root_cell, 'align-content-center')} width="15%">
          <label className="toggle-checkbox-label" onClick={handleToggleActive}>
            <input
              name="active"
              type="checkbox"
              disabled={loading}
              checked={item?.active}
              onChange={handleToggleActive}
            />
            <span className="checkmark">
              <span className="check" />
            </span>
          </label>
        </Box>
        <Box className={styles.root_cell} width="10%">
          <Box className={styles.root_delete} onClick={handleOpenConfirmDelete}>
            <DeleteIcon />
          </Box>
        </Box>
      </Box>
      {showDeleteModal && (
        <AdminStoriesConfirmDelete
          title={item?.name}
          onConfirm={handleConfirmDelete}
          onClose={handleCloseConfirmDelete}
        />
      )}
      {edit && <AdminStoriesEditModal item={edit} onClose={handleCloseEdit} />}
    </>
  );
});

export default AdminStoriesListItem;
