import React from 'react';
import DoctorsPageAdmin from '../DoctorsPageAdmin';

const HomePageAdmin = (props: any) => {
  return (
    <>
      <DoctorsPageAdmin {...props} />
    </>
  );
};

export default HomePageAdmin;
