import React from 'react';
import { connect } from 'react-redux';
import { validateFieldOnChange, getFieldErrors as getFieldErrorsUtil } from 'utils';
import { setEmailLoginForm, setEmailLoginErrors, authEmailPasswordLoginAdmin } from 'store/actions';

import InputField from 'components/Forms/InputField';
import Button from 'components/Forms/Button';
import FormValidator from 'components/Forms/FormValidator';

import { useHistory, useLocation } from 'react-router-dom';
import styles from './LoginScreenAdmin.module.sass';

const LoginScreenAdmin = (props: any): JSX.Element => {
  const { emailLoginForm, setEmailLoginForm, emailLoginFormErrors, setEmailLoginErrors, authEmailPasswordLoginAdmin } =
    props;
  const history = useHistory();
  const location = useLocation();
  const validateOnChange = (name: string, value: any, event, element?) => {
    validateFieldOnChange(
      name,
      value,
      event,
      emailLoginForm,
      setEmailLoginForm,
      emailLoginFormErrors,
      setEmailLoginErrors,
      element
    );
  };

  const getFieldErrors = (field: string) => getFieldErrorsUtil(field, emailLoginFormErrors);

  const emailLoginSubmit = (e) => {
    e.preventDefault();

    const form = e.target;
    const inputs = [...form.elements].filter((i) => ['INPUT', 'SELECT', 'TEXTAREA'].includes(i.nodeName));

    const { errors, hasError } = FormValidator.bulkValidate(inputs);

    setEmailLoginErrors([...errors]);

    if (!hasError) {
      authEmailPasswordLoginAdmin(props.emailLoginForm.email, props.emailLoginForm.password, history, location);
    }
  };

  return (
    <div className={styles.loginScreen}>
      <h3 className={styles.loginScreen_title}>Вход в личный кабинет</h3>

      <form onSubmit={(e) => emailLoginSubmit(e)}>
        <InputField
          name="email"
          data-validate='["required", "email"]'
          errors={getFieldErrors('email')}
          value={emailLoginForm.email}
          onChange={(e) => validateOnChange('email', e.target.value, e)}
          placeholder="Логин или эл. почта"
          block
        />

        <InputField
          name="password"
          className="mt-3"
          type="password"
          autocomplate="current-password"
          data-validate='["required"]'
          errors={getFieldErrors('password')}
          value={emailLoginForm.password}
          onChange={(e) => validateOnChange('password', e.target.value, e)}
          placeholder="Введите пароль"
          block
        />

        <Button className="mt-4" type="submit" block color="primary" isLoading={props.loginLoading}>
          Войти
        </Button>
      </form>
    </div>
  );
};

export default connect(
  (state: any) => ({
    phoneLoginForm: state.auth.phoneLoginForm,
    emailLoginForm: state.auth.emailLoginForm,
    phoneLoginFormErrors: state.auth.phoneLoginFormErrors,
    emailLoginFormErrors: state.auth.emailLoginFormErrors,
    loginLoading: state.auth.loginLoading,
  }),
  {
    authEmailPasswordLoginAdmin,
    setEmailLoginForm,
    setEmailLoginErrors,
  }
)(LoginScreenAdmin);
