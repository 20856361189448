import { AxiosResponse } from 'axios';
import { GetJournalAveragedResponseInterface, GetJournalResponseInterface, ResponseDataInterface } from 'types';
import axios from 'utils/axios';
import { JournalFilterRequest } from 'views/JournalPage/types';

export const getJournal = (
  params: JournalFilterRequest
): Promise<AxiosResponse<ResponseDataInterface<GetJournalResponseInterface>>> =>
  axios.get(`/api/v1/profile/observation`, { params });

export const getJournalAveraged = (
  params: JournalFilterRequest
): Promise<AxiosResponse<ResponseDataInterface<GetJournalAveragedResponseInterface>>> =>
  axios.get(`/api/v1/profile/observation/averaged`, { params });

export const postJournal = (data: any): Promise<AxiosResponse<ResponseDataInterface<any>>> =>
  axios.post(`/api/v1/profile/observation`, {
    params: {
      ...data,
    },
  });
export const putJournal = (data: {
  id: string;
  type: string;
  value: any;
}): Promise<AxiosResponse<ResponseDataInterface<any>>> => {
  const { id, ...other } = data;
  return axios.put(`/api/v1/profile/observation/${id}`, {
    ...other,
  });
};

export const deleteJournal = (id: string): Promise<AxiosResponse<ResponseDataInterface<any>>> => {
  return axios.delete(`/api/v1/profile/observation/${id}`);
};
