import React, { useCallback, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPackageView, fetchPackageViewAgent } from 'store/actions';

// Components
import ContentCard from 'components/ContentCard';
import ContentLoading from 'components/ContentLoading';
import Button from 'components/Forms/Button';

import { ReactComponent as AngleLeftIcon } from 'assets/img/icons/angle-left-icon.svg';
import { Box } from '@material-ui/core';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import useApiFx from 'hooks/useApiFx';
import useStoreApi from 'hooks/useStoreApi';
import { setAutoPaymentApi } from '$api';
import styles from './PackageView.module.sass';
import HasRole from '../../components/HasRole';
import useIsAgentRole from '../../hooks/useIsAgentRole';
import { UserRole } from '../../types';

const PackageView = () => {
  const history = useHistory();
  const routeMatch = useRouteMatch<{ servicePackageId: string }>();
  const servicePackageId = routeMatch?.params?.servicePackageId;
  const isAgent = useIsAgentRole();
  const { packageView, packageViewLoading, packageViewLoadingError } = useSelector((state: any) => ({
    packageView: state.servicePackage.packageView,
    packageViewLoading: state.servicePackage.packageViewLoading,
    packageViewLoadingError: state.servicePackage.packageViewLoadingError,
  }));

  const [isAutoPayment, setIsAutoPayment] = useState(packageView?.autoPayment === true);

  useEffect(() => {
    setIsAutoPayment(packageView?.autoPayment === true);
  }, [packageView]);

  const setAutoPaymentFx = useApiFx(setAutoPaymentApi);

  const {
    loading: autoPaymentLoading,
    data: autoPaymentDate,
    error: autoPaymentError,
  } = useStoreApi(setAutoPaymentFx, { loading: false });

  const dispatch = useDispatch();

  const handleFetchPackageView = useCallback(() => {
    const fn = isAgent ? fetchPackageViewAgent : fetchPackageView;
    dispatch(fn(servicePackageId));
  }, [dispatch, isAgent, servicePackageId]);

  useEffect(() => {
    servicePackageId && handleFetchPackageView();
  }, [handleFetchPackageView, servicePackageId]);

  useEffect(() => {
    autoPaymentError && toast.error('Ошибка при запросе');
  }, [autoPaymentError]);

  useEffect(() => {
    autoPaymentDate && setIsAutoPayment((isAutoPayment) => !isAutoPayment);
  }, [autoPaymentDate, setIsAutoPayment]);

  const handleSetBankrupt = useCallback(
    (isAutoPayment: boolean) => {
      setAutoPaymentFx({ servicePackageId, isAutoPayment }).then();
    },
    [servicePackageId, setAutoPaymentFx]
  );

  const goToDocuments = useCallback(() => {
    history.push(`/packages/${servicePackageId}/documents`);
  }, [history, servicePackageId]);

  return (
    <ContentCard>
      <ContentCard.Header>
        <div className="row">
          <div className="col-xs-4 text-center text-xs-left mb-2 mb-xs-0">
            <div className="page-header-back-link" onClick={() => history.push('/packages')}>
              <AngleLeftIcon className="page-header-back-link-icon" />
              Назад
            </div>
          </div>
          <div className="col-xs-8 col-md-4 text-center text-xs-right text-md-center">
            <h6 className="page-header-title">Информация о тарифе</h6>
          </div>
        </div>
      </ContentCard.Header>
      <ContentCard.Main>
        <ContentLoading
          isLoading={packageViewLoading}
          isError={packageViewLoadingError}
          fetchData={handleFetchPackageView}
        >
          {packageView && (
            <div className={styles.servicePackageView}>
              <div className={styles.servicePackageView_package}>
                <div className={styles.servicePackageView_package_title}>{packageView.name}</div>
                <div className={styles.servicePackageView_package_row}>
                  <h6 className={styles.servicePackageView_package_row_title}>Описание</h6>
                  <div>{packageView?.description || null}</div>
                </div>
                {!isAgent && (
                  <div className={styles.servicePackageView_package_row}>
                    <h6 className={styles.servicePackageView_package_row_key}>Дата покупки</h6>
                    <span className={styles.servicePackageView_package_row_value}>
                      {format(new Date(packageView.purchasedAt || null), 'dd.MM.yyyy')}
                    </span>
                  </div>
                )}

                {packageView.status && packageView.status.value === 'ACTIVATED' ? (
                  <div className={styles.servicePackageView_package_row}>
                    <h6 className={styles.servicePackageView_package_row_key}>Дата активации</h6>
                    <span className={styles.servicePackageView_package_row_value}>
                      {format(new Date(packageView.activatedAt || null), 'dd.MM.yyyy')}
                    </span>
                  </div>
                ) : null}

                <div className={styles.servicePackageView_package_row}>
                  <h6 className={styles.servicePackageView_package_row_key}>Дата окончания</h6>
                  <span className={styles.servicePackageView_package_row_value}>
                    {packageView.expiredAt ? format(new Date(packageView.expiredAt || null), 'dd.MM.yyyy') : '-'}
                  </span>
                </div>

                {packageView?.description && (
                  <div className={styles.servicePackageView_package_row}>
                    <h6 className={styles.servicePackageView_package_row_title}>Описание</h6>
                    <div>{packageView?.description}</div>
                  </div>
                )}

                <div className={styles.servicePackageView_package_row}>
                  <h6 className={styles.servicePackageView_package_row_key}>Стоимость</h6>
                  <span className={styles.servicePackageView_package_row_value}>{packageView?.price} &#8381;</span>
                </div>
              </div>
              <HasRole target={UserRole.PATIENT}>
                {packageView?.packageTariffication === true && (
                  <Box mt={5} mb={2} display="flex" alignItems="center" justifyContent="space-between">
                    <Box>Автоплатеж</Box>
                    <label className="toggle-checkbox-label">
                      <input
                        type="checkbox"
                        checked={isAutoPayment}
                        disabled={packageViewLoading || autoPaymentLoading}
                        onChange={(event) => handleSetBankrupt(event.target.checked)}
                      />

                      <span className="checkmark">
                        <span className="check" />
                      </span>
                    </label>
                  </Box>
                )}

                <Button color="primary" block className={styles.servicePackageView_btn} onClick={goToDocuments}>
                  Загруженные документы
                </Button>
              </HasRole>
            </div>
          )}
        </ContentLoading>
      </ContentCard.Main>
    </ContentCard>
  );
};

export default PackageView;
