import { UserRole, UserStatus } from './index';

export interface ResponseDataInterface<T = any> {
  data: T;
  code: ResponseCode;
}

export interface GetPatientsPersonalsResponseInterface extends PageItemsResponseInterface<any> {}
export interface GetJournalResponseInterface extends PageItemsResponseInterface<PageItemsResponseInterface> {}
export interface GetJournalAveragedResponseInterface extends ResponseDataInterface<Array<any>> {}

export interface GetDoctorsResponseInterface extends PageItemsResponseInterface<any> {}

export interface AuthData<T> {
  accessToken?: string;
  accessTokenExpire?: Date;
  refreshToken?: string;
  authInfo: T;
  requireRegister?: boolean;
}

export interface AuthInfo {
  displayName?: string;
  userId?: string;
  role?: UserRole;
  permissions?: Array<string>;
  status?: UserStatus;
  paymentLink?: string;
}
export interface getProfileMetaResponseInterface {
  lastObservations: Array<any>;
  [key: string]: any;
}
export interface getProfilePersonalsInteractionsMetaResponseInterface {
  personalPsp: any;
  personalAppointment: any;
  personalDoctorProfile: any;
}
export interface PageItemsResponseInterface<T = any> extends AnyResponseInterface {
  items: Array<T>;
  page: number;
  pageSize: number;
  totalItems: number;
}

export interface AnyResponseInterface {
  [key: string]: any;
}

export enum ResponseCode {
  SUCCESS = 'success',
}
