import React, { PropsWithChildren, useCallback, useMemo } from 'react';
import { RootStateOrAny, shallowEqual, useSelector } from 'react-redux';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { RouteComponentProps, RouteProps } from 'react-router';
import { UserRole } from 'types';

export interface PropsInterface extends PropsWithChildren<RouteProps> {
  component: (props: RouteComponentProps<any>) => JSX.Element;
  roles?: UserRole[];
}
const PrivateRoute = (props: PropsInterface) => {
  const location = useLocation();
  const { component, roles = [], ...rest } = props;
  const { isAuthenticated, currentRole } = useSelector(
    (state: RootStateOrAny) => ({
      isAuthenticated: state.auth.isAuthenticated,
      currentRole: state.auth.role,
    }),
    shallowEqual
  );
  const state = useMemo(
    () => ({
      nextPathname: location.pathname,
      nextSearch: location.search,
    }),
    [location]
  );
  const to = useCallback(
    (pathname: string) => ({
      pathname,
      state,
    }),
    [state]
  );
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated) {
          return roles.length && roles.indexOf(currentRole) === -1 ? <Redirect to={to('/')} /> : component(props);
        }
        return <Redirect to={to('/login')} />;
      }}
    />
  );
};

export default PrivateRoute;
