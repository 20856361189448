import React, { useEffect, useState } from 'react';
import { Lightbox } from 'react-modal-image';
import styles from './ChatFileResource.module.sass';
import Spinner from '../../../../../components/Spinner';
import { getResource } from '../../../../../api';

const ChatFileResource = (props: any) => {
  const { resourceId } = props;
  const [resource, setResource] = useState(null);
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isImageModalOpen, setImageModalOpen] = useState(null);
  useEffect(() => {
    setLoading(true);
    getResource(resourceId)
      .then((resourceUrlResponse) => {
        let resourceUrl = null;
        let resourceType = null;
        let resourceName = null;

        if (resourceUrlResponse.data.data) {
          if (resourceUrlResponse.data.data.contentType === 'application/pdf') {
            resourceType = 'pdf';
          } else {
            resourceType = 'image';
          }

          resourceUrl = resourceUrlResponse.data.data.originalUrl;
          resourceName = resourceUrlResponse.data.data.fileName;

          setResource({
            id: resourceId,
            url: resourceUrl,
            type: resourceType,
            name: resourceName,
            loading: false,
            error: false,
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        setErrors(error);
      });
  }, []);
  if (!resource || loading) {
    return <Spinner width={15} height={15} color="#1473ff" />;
  }
  if (errors) {
    return <div className={styles.image}>Ошибка при загрузке изображения</div>;
  }
  if (resource?.type === 'pdf') {
    return (
      <a href={resource.url} target="_blank" className={styles.doc} rel="noreferrer">
        {resource.name}
      </a>
    );
  }
  return (
    <>
      <div
        key={`msg-image-${resource.id}`}
        className={styles.image}
        style={{ backgroundImage: `url(${resource.url})` }}
        onClick={() => setImageModalOpen(true)}
      />
      {isImageModalOpen && (
        <Lightbox
          medium={resource.url}
          large={resource.url}
          onClose={(event) => {
            event.preventDefault();
            event.stopPropagation();
            setImageModalOpen(null);
          }}
        />
      )}
    </>
  );
};

export default ChatFileResource;
