import React, { useEffect, useRef, useState } from 'react';
import { Backdrop, Box, Dialog } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroller';
import styles from './AdminServicePackageTransactionsView.module.sass';
import ContentCard from '../ContentCard';
import Button from '../Forms/Button';
import {
  fetchManageTransactions,
  fetchManageTransactionStatuses,
  setManageTransactionsLoading,
  updatePatientServicePackageTransactionStatus,
} from '../../store/actions';
import { RootState } from '../../store/reducers';
import ContentLoading from '../ContentLoading';
import { PatientServicePackageTransactionStatus } from '../../types';
import { displayErrors } from '../../utils/handleHttpError';
import SelectInput2 from '../Forms/SelectInput2';
import useIsAdminRole from '../../hooks/useIsAdminRole';

interface PropsInterface {
  patient: any;
  onClose: () => void;
}

const AdminServicePackageTransactionsView = (props: PropsInterface) => {
  const dispatch = useDispatch();
  const { onClose = () => {}, patient = null } = props;
  const { page, pageSize, transactions, errors, hasMore, loading, loadingStatuses, errorsStatuses, statuses } =
    useSelector((state: RootState) => state.manageTransactions);
  const [scrollParentRef, setScrollParentRef] = useState<any>(null);
  const isAdminRole = useIsAdminRole();
  useEffect(() => {
    getManageTransactions(true);
    return () => {
      dispatch(setManageTransactionsLoading(true, true));
    };
  }, []);

  const isCanceled = patient?.status?.value === 'CANCELED';

  useEffect(() => {
    !isCanceled && dispatch(fetchManageTransactionStatuses());
  }, [isCanceled, dispatch]);

  useEffect(() => {
    if (errors) {
      displayErrors(errors);
    }
  }, [errors]);

  const getManageTransactions = (reset?: boolean) => {
    dispatch(fetchManageTransactions(patient?.id, patient?.patient?.id, reset ? 0 : page + 1, pageSize, reset));
  };

  const updateTransactionStatus = (id: string, status: PatientServicePackageTransactionStatus) => {
    dispatch(updatePatientServicePackageTransactionStatus(id, status));
  };

  return (
    <Dialog
      PaperProps={{ className: classNames(styles.Dialog) }}
      BackdropComponent={(props) => <Backdrop className={styles.Backdrop} {...props} />}
      onClose={onClose}
      open
    >
      <ContentCard className={styles.AdminServicePackageTransactionsView}>
        <ContentCard.Header className={styles.AdminServicePackageTransactionsView_Header}>
          <h6 className="page-header-title">История операция</h6>
          <CloseIcon onClick={onClose} className={styles.AdminServicePackageTransactionsView_CloseIcon} />
        </ContentCard.Header>
        <div ref={(ref) => setScrollParentRef(ref)} className={styles.AdminServicePackageTransactionsView_Content}>
          <InfiniteScroll
            pageStart={0}
            hasMore={loading ? false : hasMore}
            initialLoad={false}
            threshold={pageSize}
            loadMore={() => getManageTransactions()}
            loader={null}
            useWindow={false}
            getScrollParent={() => scrollParentRef}
          >
            <>
              {!(loading || errors) && (!transactions || !transactions.length) && (
                <Box width="100%" p={2} textAlign="center">
                  Нет транзакций
                </Box>
              )}
              {(transactions || []).map((item: any, index: number) => {
                return (
                  <Box
                    key={index}
                    p={2}
                    display="flex"
                    flexDirection="column"
                    borderBottom="1px solid #D8D8D8"
                    justifyContent="space-between"
                  >
                    <Box width="100%" display="flex" alignItems="center">
                      <Box display="flex" flexDirection="column">
                        <Box mb={1} color={item?.type?.value === 'PACKAGE_CHARGE' ? '#FF341A' : '#00C12F'}>
                          {item?.type?.name}
                        </Box>
                        <Box mb={1}>{item?.status?.name}</Box>
                        <Box>{item?.operationDay && moment(item?.operationDay).format('DD.MM.yyyy')}</Box>
                      </Box>
                      <Box display="flex" flexDirection="column">
                        <Box color={item?.type?.value === 'PACKAGE_CHARGE' ? '#FF341A' : '#00C12F'}>
                          {item?.type?.value === 'PACKAGE_CHARGE' ? '-' : '+'} {item?.amount} &#8381;
                        </Box>
                      </Box>
                    </Box>
                    {!isCanceled && (
                      <Box width="100%" mt={2} display="flex" alignItems="center" justifyContent="stretch">
                        {(loadingStatuses || errorsStatuses) && (
                          <Box width="100%" p={2}>
                            <ContentLoading
                              isLoading={loading}
                              isError={errors}
                              fetchData={() => getManageTransactions()}
                            />
                          </Box>
                        )}
                        {!(loadingStatuses || errorsStatuses) && (
                          <SelectInput2
                            classNames="w-100"
                            disabled={loadingStatuses || !isAdminRole}
                            name="cancellationReasonType"
                            value={statuses.find((opt) => opt?.value === item?.status?.value)?.value}
                            onChange={(event) => {
                              updateTransactionStatus(item.id, event.target.value);
                            }}
                            options={statuses.map((s) => ({ label: s?.name, value: s?.value }))}
                            block
                          />
                        )}
                      </Box>
                    )}
                  </Box>
                );
              })}
              {(loading || errors) && (
                <Box width="100%" p={2}>
                  <ContentLoading isLoading={loading} isError={errors} fetchData={() => getManageTransactions()} />
                </Box>
              )}
            </>
          </InfiniteScroll>
        </div>
        {isAdminRole && (
          <Box p={2} width="100%">
            <Button block color="default" onClick={() => onClose()}>
              Закрыть
            </Button>
          </Box>
        )}
      </ContentCard>
    </Dialog>
  );
};
export default AdminServicePackageTransactionsView;
