import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import App from './App';

import configureStore from './store/store';
import StoreProvider from './providers/StoreProvider';
import JivoSiteProvider from './providers/JivoSiteProvider';

const store = configureStore();
ReactDOM.render(
  <StoreProvider>
    <Provider store={store}>
      <JivoSiteProvider>
        <App />
      </JivoSiteProvider>
      <ToastContainer autoClose={1700} />
    </Provider>
  </StoreProvider>,
  document.getElementById('root')
);
