import React, { ChangeEvent, useCallback } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import classNames from 'classnames';
import { Box } from '@material-ui/core';
import { BoxProps } from '@material-ui/core/Box/Box';
import InputField from '../Forms/InputField';
import styles from './SearchString.module.sass';

interface PropsInterface extends BoxProps {
  value?: string;
  onChange?: (...args: any) => any;
  placeholder?: string;
  block?: boolean;
  disabled?: boolean;
}
const SearchString = (props: PropsInterface): JSX.Element => {
  const { value = '', onChange = () => {}, placeholder, block = true, disabled = false, className, ...rest } = props;
  const handleClear = useCallback(() => {
    onChange('');
  }, [onChange]);
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event?.target?.value);
    },
    [onChange]
  );
  return (
    <>
      <Box {...rest} className={classNames(styles.root, className)}>
        <SearchIcon className={styles.root_icon} />
        <InputField value={value} onChange={handleChange} block={block} disabled={disabled} placeholder={placeholder} />
        {value && <ClearIcon onClick={handleClear} className={classNames(styles.root_icon, styles.root_icon_clear)} />}
      </Box>
    </>
  );
};
export default SearchString;
