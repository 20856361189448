import React from 'react';
import cx from 'classnames';
import { toast } from 'react-toastify';

import DocumentFileInput from 'components/Forms/DocumentFileInput';
import Button from 'components/Forms/Button';

import { FileDataType } from 'types/file';

import styles from './FilesForm.module.sass';

import { ReactComponent as CogIcon } from 'assets/img/icons/cog-icon.svg';

type Props = {
  files: FileDataType[];
  fileIds: string[];
  onLoadFiles?: (ids: string[]) => void;
  disabled?: boolean;
  maxFiles?: number;
  rawStyle?: boolean;
  readOnly?: boolean;
  hideTitle?: boolean;
}

export default ({ files = [], hideTitle, rawStyle, maxFiles, onLoadFiles, fileIds, ...otherProps }: Props) => {
  const [initFiles, setInitFiles] = React.useState<FileDataType[]>(files);
  const [additionalFiles, setAdditionalFiles] = React.useState<FileDataType[]>([]);

  React.useEffect(
    () => {
      if (!initFiles.length) {
        setInitFiles([]);

        return;
      }

      setInitFiles(files);
    },
    []
  );

  React.useEffect(
    () => {
      if (!initFiles.length && files.length) {
        setInitFiles(files);
      }
    },
    [files]
  );

  return (
    <DocumentFileInput
      {...otherProps}
      dropElement={document.querySelector('#root')}
      fileIds={fileIds}
      onLoadFiles={onLoadFiles}
      rawStyle={rawStyle}
      initFiles={initFiles}
      maxFiles={maxFiles}
      additionalFiles={additionalFiles}
    >
      {({ open, isDisabled }) => {
        if (isDisabled) {
          return null;
        }

        return (
          <Button
            outline
            color="primary"
            className="mt-4"
            onClick={open}
            block
          >
            <CogIcon className={styles.PinIcon} />
            Прикрепить
          </Button>
        );
      }}
    </DocumentFileInput>
  );
};
