import { AxiosResponse } from 'axios';
import { getDailyConclusionStrategies } from '../../api/dailyconclusionstrategies/getDailyConclusionStrategies';
import { getDailyConclusionStrategiesRules } from '../../api/dailyconclusionstrategies/getDailyConclusionStrategiesRules';

export const MANAGE_DAILY_CONCLUSION_STRATEGIES_LOADING = 'MANAGE_DAILY_CONCLUSION_STRATEGIES_LOADING';
export const MANAGE_DAILY_CONCLUSION_STRATEGIES_SUCCESS = 'MANAGE_DAILY_CONCLUSION_STRATEGIES_SUCCESS';
export const MANAGE_DAILY_CONCLUSION_STRATEGIES_ERRORS = 'MANAGE_DAILY_CONCLUSION_STRATEGIES_ERRORS';

export const MANAGE_DAILY_CONCLUSION_STRATEGIES_RULES_LOADING = 'MANAGE_DAILY_CONCLUSION_STRATEGIES_RULES_LOADING';
export const MANAGE_DAILY_CONCLUSION_STRATEGIES_RULES_SUCCESS = 'MANAGE_DAILY_CONCLUSION_STRATEGIES_RULES_SUCCESS';
export const MANAGE_DAILY_CONCLUSION_STRATEGIES_RULES_ERRORS = 'MANAGE_DAILY_CONCLUSION_STRATEGIES_RULES_ERRORS';

export const setManageDailyConclusionStrategiesLoading = (loading: boolean, reset?: boolean) => ({
  type: MANAGE_DAILY_CONCLUSION_STRATEGIES_LOADING,
  loading,
  reset,
});

export const setManageDailyConclusionStrategiesErrors = (errors: any) => ({
  type: MANAGE_DAILY_CONCLUSION_STRATEGIES_ERRORS,
  errors,
});

export const setManageDailyConclusionStrategies = (
  strategies: any,
  page: number,
  pageSize: number,
  totalItems: number
) => ({
  type: MANAGE_DAILY_CONCLUSION_STRATEGIES_SUCCESS,
  strategies,
  page,
  pageSize,
  totalItems,
});

export const fetchManageDailyConclusionStrategies = (params?: {
  query?: string;
  active?: boolean;
  page?: number;
  pageSize?: number;
  reset?: boolean;
}): any => {
  return (dispatch) => {
    const { query, active, page, pageSize, reset } = params;
    dispatch(setManageDailyConclusionStrategiesLoading(true, reset));
    getDailyConclusionStrategies({ query, active, page, pageSize })
      .then((response: AxiosResponse<any>) => {
        dispatch(setManageDailyConclusionStrategiesLoading(false));
        if (response.data.code === 'success') {
          dispatch(
            setManageDailyConclusionStrategies(
              response.data.data.items,
              response.data.data.page,
              response.data.data.pageSize,
              response.data.data.totalItems
            )
          );
        } else {
          dispatch(setManageDailyConclusionStrategiesErrors(response.data));
        }
      })
      .catch((error: any) => {
        dispatch(setManageDailyConclusionStrategiesErrors(error));
        dispatch(setManageDailyConclusionStrategiesLoading(false));
      });
  };
};

export const setManageDailyConclusionStrategiesRulesLoading = (loading: boolean, reset?: boolean) => ({
  type: MANAGE_DAILY_CONCLUSION_STRATEGIES_RULES_LOADING,
  loading,
  reset,
});

export const setManageDailyConclusionStrategiesRulesErrors = (errors: any) => ({
  type: MANAGE_DAILY_CONCLUSION_STRATEGIES_RULES_ERRORS,
  errors,
});

export const setManageDailyConclusionStrategiesRules = (
  rules: any,
  page: number,
  pageSize: number,
  totalItems: number
) => ({
  type: MANAGE_DAILY_CONCLUSION_STRATEGIES_RULES_SUCCESS,
  rules,
  page,
  pageSize,
  totalItems,
});

export const fetchManageDailyConclusionStrategiesRules = (params?: {
  id?: number;
  page?: number;
  pageSize?: number;
  reset?: boolean;
}): any => {
  return (dispatch) => {
    const { id, page, pageSize, reset } = params;
    dispatch(setManageDailyConclusionStrategiesRulesLoading(true, reset));
    getDailyConclusionStrategiesRules({ id, page, pageSize })
      .then((response: AxiosResponse<any>) => {
        dispatch(setManageDailyConclusionStrategiesRulesLoading(false));
        if (response.data.code === 'success') {
          dispatch(
            setManageDailyConclusionStrategiesRules(
              response.data.data.items,
              response.data.data.page,
              response.data.data.pageSize,
              response.data.data.totalItems
            )
          );
        } else {
          dispatch(setManageDailyConclusionStrategiesRulesErrors(response.data));
        }
      })
      .catch((error: any) => {
        dispatch(setManageDailyConclusionStrategiesRulesErrors(error));
        dispatch(setManageDailyConclusionStrategiesRulesLoading(false));
      });
  };
};
