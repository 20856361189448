import axios from 'utils/axios';

export const getPatientServicePackageTransactionReport = (
  statuses?: string[],
  types?: string[],
  createdFrom?: Date,
  createdTo?: Date,
  page?: number,
  pageSize?: number
) => {
  page = page || 0;
  pageSize = pageSize || 10;
  statuses = statuses || [];
  types = types || []
  return axios.get(`/api/v1/admin/reports/patientservicepackagetransaction.xlsx`, {
    responseType: 'blob',
    params: {
      statuses: statuses.length ? statuses.join(',') : null,
      types: types.length ? types.join(',') : null,
      createdFrom,
      createdTo,
      page,
      pageSize,
    },
  });
};

export const getPatientServicePackageTransactionReportAsCsv = (
  statuses?: string[],
  types?: string[],
  createdFrom?: Date,
  createdTo?: Date,
  page?: number,
  pageSize?: number
) => {
  page = page || 0;
  pageSize = pageSize || 100;
  statuses = statuses || [];
  types = types || []
  return axios.get(`/api/v1/admin/reports/patientservicepackagetransaction.csv`, {
    responseType: 'blob',
    params: {
      statuses: statuses.length ? statuses.join(',') : null,
      types: types.length ? types.join(',') : null,
      createdFrom,
      createdTo,
      page,
      pageSize,
    },
  });
};
