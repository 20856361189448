import React, { useEffect, useState } from 'react';
import ContentCard from 'components/ContentCard';
import classNames from 'classnames';
import { connect, RootStateOrAny, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import {
  isAfter,
  isBefore,
  addDays,
  endOfWeek,
  startOfWeek,
  endOfMonth,
  startOfMonth,
  startOfDay,
  setMinutes,
  setSeconds,
  differenceInHours,
  endOfDay,
  addHours,
  isSameDay,
  isWithinInterval,
  isSameHour,
  parseISO,
} from 'date-fns';

import { toast } from 'react-toastify';
import { ru } from 'date-fns/locale';
import { AxiosResponse } from 'axios';
import Button from '../../components/Forms/Button';
import { ViewSchedulerDoctor } from '../../store/actions';
import Calendar from '../../components/Calendar';
import { ReactComponent as AngleLeftIcon } from '../../assets/img/icons/angle-left-icon.svg';
import { ReactComponent as AngleRightIcon } from '../../assets/img/icons/angle-right-icon.svg';
import Scheduler from '../../components/Scheduler';
import { format } from '../../utils/date';
import SchedulerEdit from '../../components/SchedulerEdit';
import { Appointment, Scheduler as SchedulerType } from '../../types';
import styles from './SchedulerDoctor.module.sass';
import SchedulerDelete from '../../components/SchedulerDelete';
import ContentLoading from '../../components/ContentLoading';
import { getDoctorFutureSchedule } from '../../api/getDoctorFutureSchedule';
import { getDoctorFutureWorkShifts } from '../../api/getDoctorFutureWorkShifts';
import { createWorkShift } from '../../api/createWorkShift';
import { deleteWorkShift } from '../../api/deleteWorkShift';

const SchedulerDoctor = () => {
  const doctor = useSelector((state: RootStateOrAny) => state.doctor);
  const [init, setInit] = useState(true);
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [schedules, setSchedules] = useState<SchedulerType[]>([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [loadingCreateSchedule, setLoadingCreateSchedule] = useState(false);
  const [loadingDeleteSchedule, setLoadingDeleteSchedule] = useState(false);
  const [view, changeView] = useState(ViewSchedulerDoctor.WEEK);
  const [date, changeCurrentDate] = useState(new Date());
  const [editScheduler, setEditScheduler] = useState(null);
  const [deleteScheduler, setDeleteScheduler] = useState(null);

  useEffect(() => {
    const timer = setTimeout(getSchedulesAppointments, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [view, date]);

  const getSchedulesAppointments = (): void => {
    let timeFrom = startOfDay(date);
    let timeTo = endOfDay(date);
    if (view === ViewSchedulerDoctor.WEEK) {
      timeFrom = startOfDay(startOfWeek(date, { locale: ru, weekStartsOn: 1 }));
      timeTo = endOfDay(endOfWeek(date, { locale: ru, weekStartsOn: 1 }));
    }
    setLoading(true);
    init && setInit(false);
    Promise.all([
      getDoctorFutureWorkShifts(doctor.id, timeFrom, timeTo),
      getDoctorFutureSchedule(doctor.id, timeFrom, timeTo),
    ])
      .then((data: AxiosResponse<any>[]) => {
        if (data[0].data.code === 'success') {
          setSchedules(
            (data[0].data.data.items || []).map((s: any) => ({
              startTime: parseISO(s.startTime),
              endTime: parseISO(s.endTime),
              id: s.id,
            }))
          );
        } else {
          throw new Error();
        }
        if (data[1].data.code === 'success') {
          setAppointments(
            (data[1].data.data.items || []).map((app: any) => {
              return {
                startTime: parseISO(app.startTime),
                busy: app.busy === true,
                appointment: app.appointment || null,
              };
            })
          );
        } else {
          throw new Error();
        }
      })
      .catch((err) => setErrors(err))
      .finally(() => setLoading(false));
  };

  const changeMonth = (amount: number): void => {
    amount = view === ViewSchedulerDoctor.TODAY ? amount : amount * 7;
    changeCurrentDate(addDays(date, amount));
  };

  const getLabel = (): string => {
    if (view === ViewSchedulerDoctor.WEEK) {
      const _startOdDay = startOfDay(date);
      const _endOfWeek: Date = endOfWeek(_startOdDay, { weekStartsOn: 1 });
      const _startOfWeek: Date = startOfWeek(_startOdDay, { weekStartsOn: 1 });
      if (isAfter(startOfMonth(_startOdDay), _startOfWeek)) {
        return `${format(_startOfWeek, 'MMMM')} - ${format(date, 'MMMM')} ${format(date, 'yyyy')}`;
      }
      if (isBefore(endOfMonth(_startOdDay), _endOfWeek)) {
        return `${format(date, 'MMMM')} - ${format(_endOfWeek, 'MMMM')} ${format(date, 'yyyy')}`;
      }
    }
    return format(date, 'MMMM yyyy');
  };

  const onSaveScheduler = (scheduler: SchedulerType): any => {
    if (
      schedules
        .filter((s: SchedulerType) => isSameDay(s.startTime, scheduler.startTime))
        .map((s: SchedulerType) => ({
          ...s,
          startTime: setSeconds(s.startTime, 0),
          endTime: setSeconds(s.startTime, 0),
        }))
        .some(
          (s: SchedulerType) =>
            (isWithinInterval(s.startTime, {
              start: scheduler.startTime,
              end: scheduler.endTime,
            }) &&
              !isSameHour(s.startTime, scheduler.startTime) &&
              !isSameHour(s.startTime, scheduler.endTime)) ||
            (isWithinInterval(s.endTime, {
              start: scheduler.startTime,
              end: scheduler.endTime,
            }) &&
              !isSameHour(s.endTime, scheduler.startTime) &&
              !isSameHour(s.endTime, scheduler.endTime))
        )
    ) {
      toast.error(
        'Выбранный интервал имеет пересечение. Попробуйте изменить дату начала и дату завершения или выбрать другой день'
      );
      return;
    }
    setLoadingCreateSchedule(true);
    createWorkShift(doctor.id, scheduler.startTime, scheduler.endTime)
      .then((response: any) => {
        setLoadingCreateSchedule(false);
        if (response.data.code === 'success') {
          getSchedulesAppointments();
          setEditScheduler(null);
        } else {
          toast.error(
            'Ошибка сохранения рабочего интервала. Попробуйте изменить дату начала и дату завершения или выбрать другой день и попробовать еще раз'
          );
        }
      })
      .catch((error: any) => {
        setLoadingCreateSchedule(false);
        toast.error(
          'Ошибка сохранения рабочего интервала. Попробуйте изменить дату начала и дату завершения или выбрать другой день и попробовать еще раз'
        );
      });
  };

  const onDeleteScheduler = (scheduler: SchedulerType): any => {
    setLoadingDeleteSchedule(true);
    deleteWorkShift(scheduler.id)
      .then((response: any) => {
        setLoadingDeleteSchedule(false);
        if (response.data.code === 'success') {
          getSchedulesAppointments();
          setDeleteScheduler(null);
        } else {
          toast.error('Ошибка удаления рабочего интервала. Попробуйте еще раз');
        }
      })
      .catch((error: any) => {
        setLoadingDeleteSchedule(false);
        toast.error('Ошибка удаления рабочего интервала. Попробуйте еще раз');
      });
  };

  const addScheduler = (): void => {
    const startTime: Date = addHours(setMinutes(setSeconds(new Date(), 0), 0), 1);
    setEditScheduler({
      id: null,
      startTime,
      endTime: differenceInHours(startTime, endOfDay(new Date())) >= -1 ? endOfDay(startTime) : addHours(startTime, 2),
    });
  };

  return (
    <>
      <ContentCard className={styles.SchedulerDoctor}>
        <ContentCard.Header>
          <div className="row">
            <div className="col-12 text-center text-xs-right text-md-center">
              <h6 className="page-header-title">Расписание</h6>
            </div>
          </div>
        </ContentCard.Header>
        <ContentCard.Main className={styles.SchedulerDoctor_Content}>
          <Box width="100%" className="d-flex flex-column flex-lg-row justify-content-between align-items-stretch">
            <div className="col-12 col-lg-4 col-md-3 p-0">
              <Box className={classNames(styles.SchedulerDoctor_Sidebar)}>
                <Calendar className={styles.SchedulerDoctor_Calendar} date={date} onChangeDate={changeCurrentDate} />
                <Box p={2} width="100%">
                  <Button type="button" block color="primary" onClick={addScheduler}>
                    Создать
                  </Button>
                </Box>
              </Box>
            </div>
            <div className="col-12 col-lg-8 col-md-9 d-flex align-items-start flex-column p-0">
              <Box className={classNames(styles.SchedulerDoctor_Toolbar)}>
                <Button
                  type="button"
                  size="sm"
                  onClick={() => !isSameDay(new Date(), date) && changeCurrentDate(new Date())}
                  color="primary"
                >
                  Сегодня
                </Button>
                <Box
                  className={styles.SchedulerDoctor_Toolbar_Date}
                  flexDirection="row"
                  justifyContent="space-between"
                  alignContent="center"
                  display="flex"
                >
                  <button
                    type="button"
                    className={styles.SchedulerDoctor_Toolbar_Date_Nav}
                    onClick={() => changeMonth(-1)}
                  >
                    <AngleLeftIcon className={styles.SchedulerDoctor_Toolbar_Date_Nav_Icon} />
                  </button>
                  <Box className={styles.SchedulerDoctor_Toolbar_Date_Nav_Label} alignSelf="center" ml={2} mr={2}>
                    {getLabel()}
                  </Box>
                  <button
                    type="button"
                    className={styles.SchedulerDoctor_Toolbar_Date_Nav}
                    onClick={() => changeMonth(1)}
                  >
                    <AngleRightIcon className={styles.SchedulerDoctor_Toolbar_Date_Nav_Icon} />
                  </button>
                </Box>
                <Button
                  type="button"
                  size="sm"
                  onClick={() =>
                    changeView(view === ViewSchedulerDoctor.WEEK ? ViewSchedulerDoctor.TODAY : ViewSchedulerDoctor.WEEK)
                  }
                  color={view === ViewSchedulerDoctor.WEEK ? 'default' : 'primary'}
                >
                  {view === ViewSchedulerDoctor.WEEK ? 'День' : 'Неделя'}
                </Button>
              </Box>
              <Box
                width="100%"
                display="flex"
                flex="1 1 auto"
                justifyContent="center"
                alignItems="center"
                flexDirection="row"
              >
                <ContentLoading isLoading={loading} isError={errors} fetchData={() => getSchedulesAppointments()}>
                  {!init && (
                    <Scheduler
                      appointments={appointments}
                      scheduler={schedules}
                      onClickCell={(data: SchedulerType) => setEditScheduler(data)}
                      onDelete={(data: SchedulerType) => setDeleteScheduler(data)}
                      date={date}
                      onChangeDate={(date: Date) => changeCurrentDate(date)}
                      view={view === ViewSchedulerDoctor.TODAY ? 'TODAY' : 'WEEK'}
                    />
                  )}
                </ContentLoading>
              </Box>
            </div>
          </Box>
        </ContentCard.Main>
      </ContentCard>
      {editScheduler && (
        <SchedulerEdit
          loading={loadingCreateSchedule}
          scheduler={editScheduler}
          onSave={onSaveScheduler}
          onClose={() => setEditScheduler(null)}
        />
      )}
      {deleteScheduler && (
        <SchedulerDelete
          loading={loadingDeleteSchedule}
          scheduler={deleteScheduler}
          onDelete={onDeleteScheduler}
          onClose={() => setDeleteScheduler(null)}
        />
      )}
    </>
  );
};
export default SchedulerDoctor;
