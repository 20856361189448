import React, { useEffect, useState } from 'react';
import { Avatar, Box, Grid } from '@material-ui/core';
import classNames from 'classnames';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import styles from './DailyConclusionStrategiesRule.module.sass';
import InputField from '../../../../components/Forms/InputField';
import ContentCard from '../../../../components/ContentCard';
import ContentLoading from '../../../../components/ContentLoading';
import { ReactComponent as AngleLeftIcon } from '../../../../assets/img/icons/angle-left-icon.svg';
import { decodeAvatarResource, getFieldErrors as getFieldErrorsUtil, validateFieldOnChange } from '../../../../utils';
import FormValidator from '../../../../components/Forms/FormValidator';
import { createDailyConclusionStrategyRules } from '../../../../api/dailyconclusionstrategies/createDailyConclusionStrategyRules';
import { getPatientAgeGroupTypeValues } from '../../../../api/dailyconclusionstrategies/getPatientAgeGroupTypeValues';
import SelectInput from '../../../../components/Forms/SelectInput';
import AdminDoctorSelect from '../../../../components/AdminDoctorSelect';
import Button from '../../../../components/Forms/Button';
import { updateDailyConclusionStrategyRule } from '../../../../api/dailyconclusionstrategies/updateDailyConclusionStrategyRules';
import FormLabel from '../../../../components/Forms/FormLabel';

interface PropsInterface {
  item: any;
  id: number;
  onSave: () => void;
  onClose: () => void;
}
const DailyConclusionStrategiesRule = (props: PropsInterface) => {
  const { onClose = () => {}, onSave = () => {}, item, id } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingStore, setLoadingStore] = useState<boolean>(false);
  const [viewDoctors, setViewDoctors] = useState<boolean>(false);
  const [doctor, setDoctor] = useState<any>(item?.doctor || null);
  const [errors, setErrors] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<any>([]);
  const [model, setModel] = useState<any>({
    id: item?.id,
    active: item?.active || true,
    doctorId: item?.doctor?.id,
    order: item?.order,
    sex: item?.sex,
    ageGroup: item?.ageGroup?.id,
    recommendation: item?.recommendations,
  });
  const [groups, setGroupd] = useState<any>([]);
  const validateOnChange = (name: string, value: any, event, element?: any) => {
    validateFieldOnChange(name, value, event, model, setModel, formErrors, setFormErrors, element);
  };
  const getFieldErrors = (field: string) => getFieldErrorsUtil(field, formErrors);
  useEffect(() => {
    init();
  }, []);
  const submit = (e) => {
    e.preventDefault();
    const form = e.target;
    const inputs = [...form.elements].filter((i) => ['INPUT', 'SELECT', 'TEXTAREA'].includes(i.nodeName));

    const { errors, hasError } = FormValidator.bulkValidate(inputs);

    setFormErrors([...errors]);

    if (!hasError) {
      store();
    }
  };
  const store = (): void => {
    setLoadingStore(true);
    // @ts-ignore
    (model.id ? updateDailyConclusionStrategyRule(model) : createDailyConclusionStrategyRules(id, model))
      .then((response: AxiosResponse<any>) => {
        if (response && response.data.code === 'success') {
          return model;
        }
        throw new Error();
      })
      .then(() => {
        onSave();
        setLoadingStore(false);
      })
      .catch((err) => {
        toast.error('Ошибка при запросе');
        setLoadingStore(false);
      });
  };
  const init = () => {
    setLoading(true);
    getPatientAgeGroupTypeValues()
      .then((response: AxiosResponse<any>) => {
        setLoading(false);
        if (response && response.data.code === 'success') {
          setGroupd((response.data.data.items || []).map((i) => ({ label: i?.name, value: i?.id })));
          return;
        }
        throw new Error();
      })
      .catch((err) => {
        setLoading(false);
        setErrors(err);
      });
  };
  return (
    <>
      <ContentCard>
        <ContentCard.Header>
          <div className="row">
            <div className="col-xs-4 text-center text-xs-left mb-2 mb-xs-0">
              <div className="page-header-back-link" onClick={() => onClose()}>
                <AngleLeftIcon className="page-header-back-link-icon" />
                Отменить
              </div>
            </div>
            <div className="col-xs-8 col-md-4 text-center text-xs-right text-md-center">
              <h6 className="page-header-title">{item.id ? 'Редактировать правило' : 'Создать правило'}</h6>
            </div>
          </div>
        </ContentCard.Header>
        <ContentCard.Main
          className={
            loading || errors ? styles.DailyConclusionStrategies_ContentCard : styles.DailyConclusionStrategies_Content
          }
        >
          <>
            {(loading || errors) && (
              <Box
                p={0}
                width="100%"
                height="100%"
                display="flex"
                flex="1 1 auto"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <ContentLoading isLoading={loading} isError={errors} fetchData={() => init()} />
              </Box>
            )}
            {!loading && !errors && (
              <form onSubmit={submit}>
                <Box className={classNames(styles.DailyConclusionStrategies_Content_Wrapper)}>
                  <Box className={classNames(styles.DailyConclusionStrategies_Content_Item)}>
                    <Grid
                      className={classNames(styles.DailyConclusionStrategies_Content_Item_Container)}
                      container
                      justify="center"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item xs={12}>
                        <InputField
                          name="order"
                          type="number"
                          max={356}
                          min={0}
                          disabled={loadingStore}
                          value={model.order}
                          onChange={(e) => validateOnChange('order', e.target.value, e)}
                          data-validate='["required"]'
                          errors={getFieldErrors('order')}
                          block
                          label="Порядковый номер дня"
                          placeholder="Порядковый номер дня"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <SelectInput
                          name="sex"
                          value={
                            model.sex ? { value: model.sex, label: model.sex === 'MALE' ? 'Мужской' : 'Женский' } : null
                          }
                          onChange={(option, e) => validateOnChange('sex', option.value, e)}
                          data-validate='["required"]'
                          options={[
                            { value: 'MALE', label: 'Мужской' },
                            { value: 'FEMALE', label: 'Женский' },
                          ]}
                          errors={getFieldErrors('sex')}
                          block
                          label="Пол"
                          placeholder="Пол"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <SelectInput
                          name="ageGroup"
                          disabled={loadingStore}
                          value={model.ageGroup ? groups.find((c) => c.value === model.ageGroup) : ''}
                          onChange={(option, e) => validateOnChange('ageGroup', option.value, e)}
                          errors={getFieldErrors('ageGroup')}
                          data-validate='["required"]'
                          options={groups}
                          block
                          label="Возрастная группа"
                          placeholder="Возрастная группа"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          setViewDoctors(true);
                        }}
                      >
                        <FormLabel>Врач</FormLabel>
                        <Box
                          borderColor="#D8D8D8"
                          fontSize="16px"
                          borderRadius="12px"
                          bgcolor="#EFEFF4"
                          border="1px solid"
                          padding="12.1px 20px"
                          lineHeight="16px"
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                        >
                          {doctor && (
                            <Avatar
                              style={{ marginRight: 20, width: 30, height: 30 }}
                              src={decodeAvatarResource(doctor.avatar, 360, 'MALE')}
                            />
                          )}
                          <Box display="flex" flexDirection="column">
                            <Box mb={doctor ? 1 : 0} lineHeight="14px" height="14px" color="#202020">
                              {doctor ? [doctor?.firstName, doctor?.lastName].filter((v) => v).join(' ') : 'Врач'}
                            </Box>
                            {doctor?.specialization?.name && <Box color="#B3BABD">{doctor?.specialization?.name}</Box>}
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <InputField
                          name="recommendation"
                          type="textarea"
                          minLength={1}
                          disabled={loadingStore}
                          value={model.recommendation}
                          onChange={(e) => validateOnChange('recommendation', e.target.value, e)}
                          data-validate='["required"]'
                          errors={getFieldErrors('recommendation')}
                          block
                          label="Рекомендации"
                          placeholder="Рекомендации"
                        />
                      </Grid>
                      <Grid item xs={12} container justify="center" alignItems="center">
                        <Button type="submit" color="primary" isLoading={loadingStore} block>
                          {model.id ? 'Обновить' : 'Создать'}
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </form>
            )}
          </>
        </ContentCard.Main>
      </ContentCard>
      {viewDoctors && (
        <AdminDoctorSelect
          onSelect={(data) => {
            setDoctor(data);
            setModel({ ...model, doctorId: data.id });
            setViewDoctors(false);
          }}
          onClose={() => {
            setViewDoctors(false);
          }}
        />
      )}
    </>
  );
};

export default DailyConclusionStrategiesRule;
