import { AxiosResponse } from 'axios';
import Manage from '../../types/manage';
import {findPatientServicePackageTransactionsReport} from "../../api/findPatientServicePackageTransactionsReport";

export const MANAGE_REPORT_SERVICE_PACKAGE_TRANSACTIONS_LOADING = 'MANAGE_REPORT_SERVICE_PACKAGE_TRANSACTIONS_LOADING';
export const MANAGE_REPORT_SERVICE_PACKAGE_TRANSACTIONS_SUCCESS = 'MANAGE_REPORT_SERVICE_PACKAGE_TRANSACTIONS_SUCCESS';
export const MANAGE_REPORT_SERVICE_PACKAGE_TRANSACTIONS_ERRORS = 'MANAGE_REPORT_SERVICE_PACKAGE_TRANSACTIONS_ERRORS';

export const setReportLoading = (loading: boolean, reset?: boolean) => ({
  type: MANAGE_REPORT_SERVICE_PACKAGE_TRANSACTIONS_LOADING,
  loading,
  reset,
});

export const setReportErrors = (errors: any) => ({
  type: MANAGE_REPORT_SERVICE_PACKAGE_TRANSACTIONS_ERRORS,
  errors,
});

export const setReportData = (
  items: Manage.ServicePackages.Transaction.Item[],
  page: number,
  pageSize: number,
  totalItems: number
) => ({
  type: MANAGE_REPORT_SERVICE_PACKAGE_TRANSACTIONS_SUCCESS,
  items,
  page,
  pageSize,
  totalItems,
});

export const fetchManageReportPatientServicePackageTransactions = (
  statuses?: string[],
  types?: string[],
  createdFrom?: Date,
  createdTo?: Date,
  page?: number,
  pageSize?: number,
  reset?: boolean
): any => {
  return (dispatch) => {
    dispatch(setReportLoading(true, reset));
    findPatientServicePackageTransactionsReport(
      statuses,
      types,
      createdFrom,
      createdTo,
      page,
      pageSize
    )
      .then((response: AxiosResponse<any>) => {
        dispatch(setReportLoading(false));
        if (response.data.code === 'success') {
          dispatch(
            setReportData(
              response.data.data.items,
              response.data.data.page,
              response.data.data.pageSize,
              response.data.data.totalItems
            )
          );
        } else {
          dispatch(setReportErrors(response.data));
        }
      })
      .catch((error: any) => {
        dispatch(setReportErrors(error));
        dispatch(setReportLoading(false));
      });
  };
};
