import { EnumDictionary } from './EnumDictionary';
import { FormError } from '../components/Forms/FormValidator/FormValidator';

export * from './response';

export * from './request';

export type InputError = FormError;
export enum DoctorsEventStatus {
  AWAITS = 'AWAITS',
  CANCELED = 'CANCELED',
  DONE = 'DONE',
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
  FAILED = 'FAILED',
  WAITING_FILLING = 'WAITING_FILLING',
}
export interface DoctorAppointments {
  id?: any;
  status?: DoctorsEventStatus;
  startTime?: any;
  subject?: string;
  patient: Patient;
  type?: EnumDictionary<string>;
}
export interface Patient {
  id?: any;
  avatar?: any;
  firstName?: string;
  lastName?: string;
  middleName?: string;
}
export type DoctorsFilterType = {
  categoryId: string;
  page: number;
  pageSize: number;
  query: string;
  sex: 'MALE' | 'FEMALE';
  specializationId: string;
};
export enum UserRole {
  PATIENT = 'PATIENT',
  DOCTOR = 'DOCTOR',
  ADMIN = 'ADMIN',
  AGENT = 'AGENT',
  SUPPORT = 'SUPPORT',
}

export enum UserStatus {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
}
export enum DoctorStatus {
  DUTY = 'DUTY',
  BUSY = 'BUSY',
  NOT_DUTY = 'NOT_DUTY',
  AWAY = 'AWAY',
}
export interface UserMetaDoctor extends UserMeta {
  firstName?: string;
  lastName?: string;
  middleName?: string;
  availability?: DoctorStatus;
  additionalInfo?: string;
  description?: string;
  education?: string;
  workingSinceYear?: number;
  specialization?: any;
  certsCount?: number;
  rating?: number;
  [key: string]: any;
}

export interface Scheduler {
  id?: any;
  doctorId?: any;
  endTime?: Date;
  startTime?: Date;
}

export interface Appointment {
  appointment?: any;
  startTime?: Date;
  busy?: boolean;
}

export interface UserMeta {
  id: any;
  phone: string;
  sex?: 'MALE' | 'FEMALE';
  balance?: number;
  displayName?: string;
  avatar?: any;
  permissions?: Array<string>;
}

export interface DoctorEntity {
  avatar?: any;
  firstName: string;
  id: string;
  lastName: string;
  middleName: string;
}

export type AppointmentsType =
  | 'AWAITS'
  | 'DONE'
  | 'CANCELED'
  | 'IN_PROGRESS'
  | 'FAILED'
  | 'FINISHED'
  | 'WAITING_FILLING'
  | 'WAITING_SENDING';

export enum AppointmentDisplayType {
  AWAITS = 'AWAITS',
  DONE = 'DONE',
  CANCELED = 'CANCELED',
}

export const getAppointmentStatuses = (status: AppointmentDisplayType): Array<AppointmentsType> => {
  switch (status) {
    case AppointmentDisplayType.AWAITS:
      return ['AWAITS', 'IN_PROGRESS'];
    case AppointmentDisplayType.DONE:
      return ['DONE', 'FINISHED', 'WAITING_FILLING', 'WAITING_SENDING'];
    case AppointmentDisplayType.CANCELED:
      return ['CANCELED', 'FAILED'];
    default:
      return [];
  }
};

export interface ResponseData<T = any> {
  data: T;
  code: ResponseDataCode;
}
export type ResponseDataCode = 'success' | any;

export type NameValue<T = string> = {
  name: string;
  value: T;
};

export type PatientServicePackageTransactionStatus = 'NORMAL' | 'IGNORED';

export type Maybe<T> = null | undefined | T;
