import axios from 'utils/axios';

export const updateSpecialization = (
  specializationId: string,
  name: string,
  durationMinutes: number,
  tariffId: string
) => {
  return axios.put(`/api/v1/specializations/${specializationId}`, {
    name,
    durationMinutes,
    tariffId,
  });
};
