import axios from 'utils/axios'
import Manage from "../../types/manage";
import {PatientServicePackageTransactionStatus} from "../../types";

export const updatePatientServicePackageTransactionStatus = (id: string, status: PatientServicePackageTransactionStatus) => {

  return axios.put(`/api/v1/servicepackages/patient/transactions/${id}`, {
    status: status
  })

}
