import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Popover } from '@material-ui/core';
import classNames from 'classnames';
import { ReactComponent as CalendarIcon } from 'assets/img/icons/calendar.svg';
import useRootSelector from 'hooks/useRootSelector';
import { selectJournalDate, selectJournalUserId } from 'views/JournalPage/store/selectors';
import { onSetJournalDate, onSetJournalDateCustom } from 'views/JournalPage/store/events';
import { JournalFilterType, JournalFilterTypeTitle } from 'views/JournalPage/types';
import Calendar from 'components/Calendar';
import styles from './JournalViewFilter.module.sass';

const JournalViewFilter = (): JSX.Element => {
  const date = useRootSelector(selectJournalDate);
  const handleClick = useCallback((date: string) => {
    onSetJournalDate(date);
  }, []);
  const [customDate, setCustomDate] = useState(new Date());
  const [anchorCalendar, setOpenCalendar] = useState<HTMLButtonElement | null>(null);
  const isOpenCalendar = useMemo(() => Boolean(anchorCalendar), [anchorCalendar]);
  const onChangeDate = useCallback((date: Date) => {
    setCustomDate(date);
    onSetJournalDateCustom(date);
    setOpenCalendar(null);
  }, []);
  useEffect(() => {
    date !== JournalFilterType.CUSTOM && setCustomDate(null);
  }, [customDate, date]);
  return (
    <>
      <Box className={styles.root}>
        <Box className={styles.root_filters}>
          {Object.keys(JournalFilterTypeTitle)
            .filter((k) => k !== JournalFilterType.CUSTOM)
            .map((k) => (
              <Box
                key={k}
                onClick={() => handleClick(k)}
                className={classNames(styles.root_filter, date === k && styles.root_filter_active)}
              >
                {JournalFilterTypeTitle[k]}
              </Box>
            ))}
        </Box>
        <Box
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            setOpenCalendar(event.currentTarget);
          }}
          className={classNames(
            styles.root_filter,
            styles.root_filter_date,
            date === JournalFilterType.CUSTOM && styles.root_filter_date_active
          )}
        >
          <Box />
          <CalendarIcon />
        </Box>
        <Popover
          PaperProps={{
            className: classNames(),
          }}
          open={isOpenCalendar}
          anchorEl={anchorCalendar}
          onClose={() => setOpenCalendar(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Box p={2}>
            <Calendar maxDate={new Date()} onChangeDate={onChangeDate} date={customDate} />
          </Box>
        </Popover>
      </Box>
    </>
  );
};

export default JournalViewFilter;
