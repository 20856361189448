import React from 'react';

import Cropper from 'components/Cropper';
import Modal from 'components/Modal';
import Button from 'components/Forms/Button';

type State = {
  isShow: boolean;
  file: File;
  croppedFile: File;
};

export default class AvatarCropper extends React.Component<{}, State> {
  state = {
    isShow: false,
    file: null,
    croppedFile: null
  };

  handleClose = () => {
    this.setState({
      isShow: false
    });

    this.promiseInfo.reject();
  };

  handleShowModal = (file: File) => new Promise((resolve, reject) => {
    this.setState({
      file,
      isShow: true
    });

    this.promiseInfo.reject = reject;
    this.promiseInfo.resolve = resolve;
  });

  handleCrop = (file: File) => {
    this.setState({
      croppedFile: file
    });
  };

  handleLoad = () => {
    this.promiseInfo.resolve(this.state.croppedFile);
    this.setState({
      isShow: false
    });
  }

  promiseInfo: any = {};

  render() {
    const { isShow, file } = this.state;

    return (
      <Modal
        isOpen={isShow}
        onClose={this.handleClose}
        title="Изображение"
      >
        <Cropper
          onCrop={this.handleCrop}
          file={file}
        />
        <Modal.Footer>
          <Button color="primary" size="sm" className="mr-2" onClick={this.handleLoad}>Загрузить</Button>
          <Button color="default" size="sm" onClick={this.handleClose}>Отменить</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
