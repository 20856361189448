import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { decodeAvatarResource, getWordNumEnding } from 'utils/helpers';
import { format } from 'date-fns';

import Button from 'components/Forms/Button';

import styles from '../AppointmentPage.module.sass';
import { datePlurals, getFullDateFormat, getTimeBetween, isBeforeOrEquals, PATTERN_TIME } from '../../../utils/date';

const InfoView = (props: any): JSX.Element => {
  const getAppointmentDescr = useCallback((status: string, serviceType: string, startTime: string) => {
    let appointmentDescr = [];
    switch (status) {
      case 'AWAITS':
        if (serviceType === 'PLANNED') {
          appointmentDescr = [
            `Вы успешно записаны. Консультация состоится ${getFullDateFormat(new Date(startTime))}.`,
            `В назначенное время откройте консультацию и дождитесь ответа врача.`,
          ];
        } else if (serviceType === 'IMMEDIATE') {
          const now = new Date();
          const estimatedStartTime = new Date(startTime);
          if (isBeforeOrEquals(now, estimatedStartTime)) {
            const dateTimeUnits = getTimeBetween(new Date(estimatedStartTime), new Date());
            appointmentDescr = [
              `Пожалуйста, подождите. Врач ответит в течение 5-ти минут.`,
              `Предварительное время ожидания ${dateTimeUnits.hours} ${getWordNumEnding(
                dateTimeUnits.hours,
                datePlurals.hours
              )} ${dateTimeUnits.minutes} ${getWordNumEnding(dateTimeUnits.minutes, datePlurals.minutes)}.`,
              'Не закрывайте страницу (экран).',
            ];
          } else {
            appointmentDescr = ['Время ожидания дежурного врача истекло.'];
          }
        }

        break;
      case 'CANCELED':
        appointmentDescr = ['Консультация была отменена без списания средств.'];
        break;
      case 'DONE':
        appointmentDescr = ['Откройте консультацию, чтобы просмотреть протокол.'];
        break;
      case 'IN_PROGRESS':
        appointmentDescr = ['Идет консультация'];
        break;
      case 'FAILED':
        appointmentDescr = ['Консультация отменена из-за возникших технических проблем без списания средств.'];
        break;
      case 'FINISHED':
        appointmentDescr = ['Консультация закрыта. Через некоторое время доктор отправит вам заключение.'];
        break;
      default:
        break;
    }

    return appointmentDescr.join('<br />');
  }, []);

  const { appointment } = props;

  const doctor = useMemo(() => appointment?.doctor || {}, [appointment?.doctor]);
  const isWait = useMemo(() => appointment?.status?.value === 'AWAITS', [appointment?.status?.value]);
  const isProgress = useMemo(() => appointment?.status?.value === 'IN_PROGRESS', [appointment?.status?.value]);
  const isChatAvailable = useMemo(() => !isWait, [isWait]);

  return appointment ? (
    <div className={styles.appointmentInfo}>
      <div
        className={styles.doctorDetailsInfo_avatar}
        style={{ backgroundImage: `url(${decodeAvatarResource(doctor.avatar, 360)})` }}
      />
      <div className={styles.doctorDetailsInfo_name}>
        {doctor.lastName} {doctor.firstName} {doctor.middleName}
      </div>
      <div className={styles.doctorDetailsInfo_position}>
        {appointment.specialization && appointment.specialization.name}
      </div>
      <div className={styles.doctorDetailsInfo_position}>{appointment.type?.name}</div>

      {appointment.startTime && (
        <div className={styles.appointmentInfo_date}>
          Создана {format(new Date(appointment.startTime), `dd.MM.yyyy в ${PATTERN_TIME}`)}
        </div>
      )}

      <div
        className={styles.appointmentInfo_msg}
        dangerouslySetInnerHTML={{
          __html: getAppointmentDescr(
            appointment?.status?.value,
            appointment?.serviceType?.value,
            appointment.startTime
          ),
        }}
      />

      {isChatAvailable ? (
        <Link to={`/appointments/${appointment.id}/chat`}>
          <Button
            disabled={appointment.status && appointment.status.value === 'AWAITS'}
            className={styles.appointmentInfo_btn}
            color="primary"
            block
          >
            {isProgress ? 'Начать консультацию' : 'Открыть консультацию'}
          </Button>
        </Link>
      ) : null}

      {isWait ? <div className={styles.appointmentInfo_btn_subtitle}>Ожидаем подключения врача...</div> : null}
    </div>
  ) : null;
};

export default InfoView;
