import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { format } from 'date-fns';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { decodeAvatarResource } from 'utils/helpers';
import Carousel, { Dots } from '@brainhubeu/react-carousel';
import { fetchDoctorDetails, fetchDoctorFeedback, fetchDoctorCerts, changeSetting } from 'store/actions';
import ModalImage from 'react-modal-image';

// Components
import ContentCard from 'components/ContentCard';
import ContentLoading from 'components/ContentLoading';
import Button from 'components/Forms/Button';

import { ReactComponent as AngleLeftIcon } from 'assets/img/icons/angle-left-icon.svg';
import { ReactComponent as AngleRightIcon } from 'assets/img/icons/angle-right-icon.svg';
import { ReactComponent as StarFillIcon } from 'assets/img/icons/star-fill-icon.svg';
import { useHistory } from 'react-router-dom';
import { chunk } from 'lodash';
import { useMediaQuery } from '@material-ui/core';
import styles from './DoctorDetailsPage.module.sass';

const DoctorDetailsPage = (props: any) => {
  const [commentsSlideActive, setCommentsSlideActive] = useState(0);
  const isMedia = useMediaQuery('only screen and (max-width : 480px)');
  const history = useHistory();
  useEffect(() => {
    props.fetchDoctorDetails(props.match.params.doctorId);
    props.fetchDoctorFeedback(props.match.params.doctorId);
    props.fetchDoctorCerts(props.match.params.doctorId);
  }, []);

  const {
    doctorDetails,
    doctorDetailsLoading,
    doctorDetailsLoadingError,

    doctorFeedbackList,
    doctorFeedbackLoading,
    doctorFeedbackLoadingError,

    doctorCertsList,
    doctorCertsLoading,
    doctorCertsLoadingError,
  } = props;

  const stars = [];

  for (let i = 1; i <= 5; i++) {
    if (doctorDetails) {
      let isFilled = false;

      if (doctorDetails.rating && doctorDetails.rating >= i) {
        isFilled = true;
      }

      stars.push(
        <StarFillIcon
          className={classNames('rate-star', {
            'rate-star-active': isFilled,
          })}
        />
      );
    }
  }

  const renderStars = useCallback((feedback) => {
    const stars = [];

    for (let i = 1; i <= 5; i++) {
      if (feedback) {
        let isFilled = false;

        if (feedback.rating && i <= feedback.rating) {
          isFilled = true;
        }

        stars.push(
          <StarFillIcon
            className={classNames('rate-star', {
              'rate-star-active': isFilled,
            })}
          />
        );
      }
    }
    return <>{stars}</>;
  }, []);

  const renderSlider = useCallback(
    (feedbacks) => {
      return feedbacks.map((feedback, feedbackIndex) => (
        <div key={feedbackIndex} className={classNames(styles.reviewCard, styles.reviewCardSlide)}>
          <div className={styles.reviewCard_main}>
            <div className={styles.reviewCard_header}>
              <div className={styles.reviewCard_date}>{format(new Date(feedback.createdAt), 'MM.dd.yyyy')}</div>

              <div className={styles.reviewCard_rate}>
                <div className="rate-stars-list">{renderStars(feedback)}</div>
              </div>
            </div>

            <div className={styles.reviewCard_comment}>{feedback.subject}</div>
          </div>

          <div className={styles.reviewCard_author}>
            <div
              className={styles.reviewCard_author_avatar}
              style={{ backgroundImage: `url(${decodeAvatarResource(feedback.avatar, 360)})` }}
            />
            <div className={styles.reviewCard_author_name}>
              {feedback.firstName} {feedback.lastName}
            </div>
          </div>
        </div>
      ));
    },
    [renderStars]
  );

  const dots = useMemo(
    () =>
      doctorFeedbackList.length > 0 ? (
        <div className={styles.reviewCardSlideDots}>
          <Dots
            value={commentsSlideActive}
            onChange={(value) => {
              setCommentsSlideActive(value);
            }}
            number={Math.floor(isMedia ? doctorFeedbackList.length : doctorFeedbackList.length / 2)}
            thumbnails={chunk(doctorFeedbackList, isMedia ? 1 : 2).map((feedback, feedbackIndex) => (
              <div key={feedbackIndex} className={styles.reviewCardSlideDot} />
            ))}
          />
        </div>
      ) : (
        <></>
      ),
    [isMedia, doctorFeedbackList, commentsSlideActive]
  );

  const arrowLeft = useMemo(
    () => (
      <div id={styles.reviewCardSlideBtnLeft} className={styles.reviewCardSlideBtn}>
        <AngleLeftIcon className={styles.reviewCardSlideBtn_icon} />
      </div>
    ),
    []
  );
  const arrowLRight = useMemo(
    () => (
      <div id={styles.reviewCardSlideBtnRight} className={styles.reviewCardSlideBtn}>
        <AngleRightIcon className={styles.reviewCardSlideBtn_icon} />
      </div>
    ),
    []
  );

  return (
    <ContentCard>
      <ContentCard.Header>
        <div className="row">
          <div className="col-xs-4 text-center text-xs-left mb-2 mb-xs-0">
            <div className="page-header-back-link" onClick={() => history.goBack()}>
              <AngleLeftIcon className="page-header-back-link-icon" />
              Назад
            </div>
          </div>
          <div className="col-xs-8 col-md-4 text-center text-xs-right text-md-center">
            <h6 className="page-header-title">Информация о враче</h6>
          </div>
        </div>
      </ContentCard.Header>

      <ContentCard.Main>
        <ContentLoading
          isLoading={doctorDetailsLoading}
          isError={doctorDetailsLoadingError}
          fetchData={() => props.fetchDoctorDetails(props.match.params.doctorId)}
        >
          {doctorDetails && (
            <div className={styles.doctorDetailsInfo}>
              <div
                className={styles.doctorDetailsInfo_avatar}
                style={{ backgroundImage: `url(${decodeAvatarResource(doctorDetails.avatar, 360)})` }}
              />
              <div className={styles.doctorDetailsInfo_name}>
                {doctorDetails.lastName} {doctorDetails.firstName} {doctorDetails.middleName}
              </div>
              <div className={styles.doctorDetailsInfo_position}>
                {doctorDetails.specialization && doctorDetails.specialization.name}
              </div>

              <div className={`${styles.doctorDetailsInfo_rate} rate-stars-list`}>
                {stars} {/* <span>{doctorDetails ? `(${doctorDetails.feedbackCount})` : ''}</span> */}
              </div>

              <Button
                className={styles.doctorDetailsInfo_btn}
                color="primary"
                onClick={() => {
                  props.changeSetting('newAppointment', {
                    ...props.newAppointment,
                    price: doctorDetails.price,
                    type: 'PLANNED',
                    doctor: doctorDetails,
                    date: null,
                    specialization: doctorDetails.specialization,
                    paymentViewType: 'PAYMENT',
                  });

                  history.push('/doctor/schedule');
                }}
              >
                Запись на консультацию {`${doctorDetails.price} ₽`}
              </Button>
            </div>
          )}
        </ContentLoading>

        <div className={styles.doctorDetailsInfo_MainWrap}>
          <div className={styles.doctorDetailsInfo_MainWrap_Item}>
            <h6>Отзывы</h6>

            <ContentLoading
              isLoading={doctorFeedbackLoading && !doctorDetailsLoading}
              isError={doctorFeedbackLoadingError}
              fetchData={() => props.fetchDoctorFeedback(props.match.params.doctorId)}
            >
              {doctorFeedbackList.length > 0 ? (
                <>
                  <div className={styles.reviewCardsCarouselWrap}>
                    <Carousel
                      value={commentsSlideActive}
                      slidesPerPage={1}
                      addArrowClickHandler
                      arrowLeft={arrowLeft}
                      arrowRight={arrowLRight}
                      infinite
                      onChange={(value) => {
                        setCommentsSlideActive(value);
                      }}
                      slides={chunk(doctorFeedbackList, isMedia ? 1 : 2).map(renderSlider)}
                    />

                    {dots}
                  </div>
                </>
              ) : (
                <div className="text-center">Не найдено</div>
              )}
            </ContentLoading>
          </div>
          <div className={styles.doctorDetailsInfo_MainWrap_Item}>
            <h6>О враче</h6>

            <p>{doctorDetails && doctorDetails.description}</p>
          </div>

          <div className={styles.doctorDetailsInfo_MainWrap_Item}>
            <h6>Дополнительная информация</h6>
            <p>{doctorDetails && doctorDetails.additionalInfo}</p>
          </div>
          <div className={styles.doctorDetailsInfo_MainWrap_Item}>
            <h6>Образование</h6>
            <p>{doctorDetails && doctorDetails.education}</p>
          </div>
          <div className={styles.doctorDetailsInfo_MainWrap_Item}>
            <h6>Специализация</h6>
            <ul className="raw-list">
              <li>- {doctorDetails && doctorDetails.specialization ? doctorDetails.specialization.name : ''}</li>
            </ul>
          </div>

          {doctorDetails?.additionalServiceTypes?.length > 0 && (
            <div className={styles.doctorDetailsInfo_MainWrap_Item}>
              <h6>Дополнительные услуги</h6>
              <ul className="raw-list">
                {doctorDetails.additionalServiceTypes?.map((value) => {
                  return <li>- {value.name}</li>;
                })}
              </ul>
            </div>
          )}
          <div className={styles.doctorDetailsInfo_MainWrap_Item}>
            <h6>Дипломы и сертификаты</h6>

            <ContentLoading
              isLoading={doctorCertsLoading && !doctorDetailsLoading && !doctorFeedbackLoading}
              isError={doctorCertsLoadingError}
              fetchData={() => props.fetchDoctorCerts(props.match.params.doctorId)}
            >
              {doctorCertsList.length > 0 ? (
                <div className="row">
                  {doctorCertsList.map((cert) => (
                    <div key={cert.id} className="col-md-4 mb-3 mb-md-0 text-center">
                      <div className={styles.doctorCertWrapper} style={{ backgroundImage: `url(${cert.originalUrl})` }}>
                        <ModalImage
                          className={styles.doctorCert}
                          small={cert.originalUrl}
                          large={cert.originalUrl}
                          hideZoom
                          hideDownload
                        />
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-center">Не найдено</div>
              )}
            </ContentLoading>
          </div>
        </div>
      </ContentCard.Main>
    </ContentCard>
  );
};

export default connect(
  (state: any) => ({
    newAppointment: state.storage.newAppointment,

    doctorDetails: state.doctorDetails.doctorDetails,
    doctorDetailsLoading: state.doctorDetails.doctorDetailsLoading,
    doctorDetailsLoadingError: state.doctorDetails.doctorDetailsLoadingError,

    doctorFeedbackList: state.doctorDetails.doctorFeedbackList,
    doctorFeedbackLoading: state.doctorDetails.doctorFeedbackLoading,
    doctorFeedbackLoadingError: state.doctorDetails.doctorFeedbackLoadingError,

    doctorCertsList: state.doctorDetails.doctorCertsList,
    doctorCertsLoading: state.doctorDetails.doctorCertsLoading,
    doctorCertsLoadingError: state.doctorDetails.doctorCertsLoadingError,
  }),
  {
    fetchDoctorDetails,
    fetchDoctorFeedback,
    fetchDoctorCerts,
    changeSetting,
  }
)(DoctorDetailsPage);
