import React, { useCallback, useEffect, useMemo } from 'react';
import PageCard from 'components/PageCard';
import { Box } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch } from 'react-redux';
import PersonalAppointmentInfo from '../../components/PersonalAppointmentInfo';

import styles from './PersonalAppointment.module.sass';
import useRootSelector from '../../hooks/useRootSelector';
import ContentLoading from '../../components/ContentLoading';
import {
  onGetMyAvailablePersonalDoctorServicePackages,
  onGetMyBuyServicePackages,
  onResetServicePackages,
} from '../../store/packages/events';
import PackageItem from '../../components/PackageItem';
import { onAddPersonalDoctor, onGetDoctors, onResetDoctors } from '../../store/doctors/events';
import DoctorItem from '../../components/DoctorItem';
import { hasMore } from '../../utils';
import { changeSetting, setDoctorsFilter } from '../../store/actions';
import Button from '../../components/Forms/Button';

const PersonalAppointment = () => {
  const {
    loading: loadingPackages,
    error: errorPackages,
    packages,
    url,
    loadingUrl,
  } = useRootSelector((state) => state.packages);
  const dispatch = useDispatch();
  const {
    loading: loadingDoctors,
    error: errorDoctors,
    doctors,
    params,
    loadingAddPersonal,
  } = useRootSelector((state) => state.doctors);
  const {
    packageId,
    doctor,
    loading: loadingPersonal,
    error: errorPersonal,
  } = useRootSelector((state) => state.personal);
  const title = useMemo(() => {
    if (!packageId) {
      return 'Выбор тарифа';
    }
    if (packageId && !doctor) {
      return null;
    }
  }, [packageId, doctor]);
  const getMyAvailablePersonalDoctorServicePackages = useCallback(() => {
    onGetMyAvailablePersonalDoctorServicePackages();
  }, []);
  const getDoctors = useCallback(() => {
    onGetDoctors({ ...params, isPersonal: true, isActive: true });
  }, [params]);

  const loadMore = useCallback(() => {
    const _params = { ...params, page: params?.page + 1 };
    setDoctorsFilter(_params);
    onGetDoctors(_params);
  }, [params]);

  useEffect(() => {
    !packageId && !loadingPersonal && getMyAvailablePersonalDoctorServicePackages();
    packageId && !doctor && getDoctors();
    return () => {
      onResetServicePackages();
      onResetDoctors();
    };
  }, [packageId, getMyAvailablePersonalDoctorServicePackages, loadingPersonal, doctor, getDoctors]);
  const onBuy = useCallback(
    (data: any) => {
      dispatch(changeSetting('newAppointment', {}));
      onGetMyBuyServicePackages(data);
    },
    [dispatch]
  );
  useEffect(() => {
    url && (window.location.href = url);
  }, [url]);
  const handleAddPersonalDoctor = useCallback(onAddPersonalDoctor, []);
  return (
    <PageCard showHeader={!packageId || !!title} showBack={!packageId} title={title}>
      <ContentLoading
        isLoading={loadingPersonal}
        isError={errorPersonal}
        fetchData={getMyAvailablePersonalDoctorServicePackages}
      >
        <PersonalAppointmentInfo
          doctor={doctor}
          mt={!packageId || !!title ? 2 : 0}
          type={!packageId && !doctor ? 'package' : doctor ? 'appointment' : 'doctor'}
        />
        {!packageId && (
          <>
            <Box className={styles.root_items}>
              <ContentLoading
                isLoading={loadingPackages}
                isError={errorPackages}
                fetchData={getMyAvailablePersonalDoctorServicePackages}
              >
                {packages.map((item, index) => (
                  <PackageItem key={index} loading={loadingUrl === item?.id} item={item} all onBuy={onBuy} />
                ))}
              </ContentLoading>
            </Box>
          </>
        )}
        {packageId && !doctor && (
          <>
            <Box className={styles.root_items}>
              <InfiniteScroll
                className={styles.root_items_doctors}
                pageStart={0}
                hasMore={loadingDoctors ? false : hasMore(params.page, params.pageSize, 0)}
                initialLoad={false}
                threshold={50}
                loadMore={loadMore}
                loader={null}
              >
                {doctors.map((item, index) => (
                  <DoctorItem key={index} item={item}>
                    <Button
                      type="button"
                      isLoading={loadingAddPersonal === item?.id}
                      onClick={() => handleAddPersonalDoctor(item?.id)}
                      block
                      size="lg"
                      color="primary"
                    >
                      Выбрать врача
                    </Button>
                  </DoctorItem>
                ))}
              </InfiniteScroll>
              <ContentLoading isLoading={loadingDoctors} isError={errorDoctors} fetchData={getDoctors} />
            </Box>
          </>
        )}
      </ContentLoading>
    </PageCard>
  );
};
export default PersonalAppointment;
