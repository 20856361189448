import { toast } from 'react-toastify';
import { parseISO } from 'date-fns';
import { Appointment, Scheduler } from '../../types';
import { createWorkShift } from '../../api/createWorkShift';
import { getDoctorFutureWorkShifts } from '../../api/getDoctorFutureWorkShifts';
import { deleteWorkShift } from '../../api/deleteWorkShift';
import { getDoctorFutureSchedule } from '../../api/getDoctorFutureSchedule';

export const DOCTOR_SCHEDULER_CHANGE_CURRENT_DATE = 'DOCTOR_SCHEDULER_CHANGE_CURRENT_DATE';
export const DOCTOR_SCHEDULER_CHANGE_VIEW = 'DOCTOR_SCHEDULER_CHANGE_VIEW';

export const DOCTOR_SCHEDULER_SCHEDULES_LOADING = 'DOCTOR_SCHEDULER_SCHEDULES_LOADING';
export const DOCTOR_SCHEDULER_SCHEDULES_ERROR = 'DOCTOR_SCHEDULER_SCHEDULES_ERROR';
export const DOCTOR_SCHEDULER_SCHEDULES_SUCCESS = 'DOCTOR_SCHEDULER_SCHEDULES_SUCCESS';

export const DOCTOR_SCHEDULER_APPOINTMENTS_LOADING = 'DOCTOR_SCHEDULER_APPOINTMENTS_LOADING';
export const DOCTOR_SCHEDULER_APPOINTMENTS_ERROR = 'DOCTOR_SCHEDULER_APPOINTMENTS_ERROR';
export const DOCTOR_SCHEDULER_APPOINTMENTS_SUCCESS = 'DOCTOR_SCHEDULER_APPOINTMENTS_SUCCESS';

export const DOCTOR_SCHEDULER_CREATE_SCHEDULE_LOADING = 'DOCTOR_SCHEDULER_CREATE_SCHEDULE_LOADING';
export const DOCTOR_SCHEDULER_CREATE_SCHEDULE_ERROR = 'DOCTOR_SCHEDULER_CREATE_SCHEDULE_ERROR';
export const DOCTOR_SCHEDULER_CREATE_SCHEDULE_SUCCESS = 'DOCTOR_SCHEDULER_CREATE_SCHEDULE_SUCCESS';

export const DOCTOR_SCHEDULER_DELETE_SCHEDULE_LOADING = 'DOCTOR_SCHEDULER_DELETE_SCHEDULE_LOADING';
export const DOCTOR_SCHEDULER_DELETE_SCHEDULE_ERROR = 'DOCTOR_SCHEDULER_DELETE_SCHEDULE_ERROR';
export const DOCTOR_SCHEDULER_DELETE_SCHEDULE_SUCCESS = 'DOCTOR_SCHEDULER_DELETE_SCHEDULE_SUCCESS';

export enum ViewSchedulerDoctor {
  TODAY,
  WEEK,
}

export const changeCurrentDate = (date: Date) => ({ type: DOCTOR_SCHEDULER_CHANGE_CURRENT_DATE, date });

export const changeView = (view: ViewSchedulerDoctor) => ({ type: DOCTOR_SCHEDULER_CHANGE_VIEW, view });

export const setLoading = (loading: boolean) => ({ type: DOCTOR_SCHEDULER_SCHEDULES_LOADING, loading });

export const setError = (errors: any) => ({ type: DOCTOR_SCHEDULER_SCHEDULES_ERROR, errors });

export const setSchedules = (schedules: Scheduler[]) => ({ type: DOCTOR_SCHEDULER_SCHEDULES_SUCCESS, schedules });

export const setLoadingAppointments = (loading: boolean) => ({ type: DOCTOR_SCHEDULER_APPOINTMENTS_LOADING, loading });

export const setErrorAppointments = (errors: any) => ({ type: DOCTOR_SCHEDULER_APPOINTMENTS_ERROR, errors });

export const setAppointments = (appointments: Appointment[]) => ({
  type: DOCTOR_SCHEDULER_APPOINTMENTS_SUCCESS,
  appointments,
});

export const setLoadingCreateSchedule = (loading: boolean) => ({
  type: DOCTOR_SCHEDULER_CREATE_SCHEDULE_LOADING,
  loading,
});

export const setErrorCreateSchedule = (errors: any) => ({
  type: DOCTOR_SCHEDULER_CREATE_SCHEDULE_ERROR,
  errors,
});

export const setSuccessCreateSchedule = (schedule: any) => ({
  type: DOCTOR_SCHEDULER_CREATE_SCHEDULE_SUCCESS,
  schedule,
});

export const setLoadingDeleteSchedule = (loading: boolean) => ({
  type: DOCTOR_SCHEDULER_DELETE_SCHEDULE_LOADING,
  loading,
});

export const setErrorDeleteSchedule = (errors: any) => ({
  type: DOCTOR_SCHEDULER_DELETE_SCHEDULE_ERROR,
  errors,
});

export const setSuccessDeleteSchedule = (schedule: any) => ({
  type: DOCTOR_SCHEDULER_DELETE_SCHEDULE_SUCCESS,
  schedule,
});

export const deleteSchedule = (schedule: Scheduler): any => {
  return (dispatch) => {
    dispatch(setLoadingDeleteSchedule(true));
    deleteWorkShift(schedule.id)
      .then((response: any) => {
        dispatch(setLoadingDeleteSchedule(false));
        if (response.data.code === 'success') {
          dispatch(setSuccessDeleteSchedule(true));
        } else {
          dispatch(setErrorDeleteSchedule(response.data));
          toast.error('Ошибка удаления рабочего интервала. Попробуйте еще раз');
        }
      })
      .catch((error: any) => {
        dispatch(setErrorDeleteSchedule(error));
        dispatch(setLoadingDeleteSchedule(false));
        toast.error('Ошибка удаления рабочего интервала. Попробуйте еще раз');
      });
  };
};

export const saveSchedule = (schedule: Scheduler, doctorId: string): any => {
  return (dispatch) => {
    dispatch(setLoadingCreateSchedule(true));
    createWorkShift(doctorId, schedule.startTime, schedule.endTime)
      .then((response: any) => {
        dispatch(setLoadingCreateSchedule(false));
        if (response.data.code === 'success') {
          dispatch(setSuccessCreateSchedule(true));
        } else {
          dispatch(setErrorCreateSchedule(response.data));
          toast.error(
            'Ошибка сохранения рабочего интервала. Попробуйте изменить дату начала и дату завершения или выбрать другой день и попробовать еще раз'
          );
        }
      })
      .catch((error: any) => {
        dispatch(setErrorCreateSchedule(error));
        dispatch(setLoadingCreateSchedule(false));
        toast.error(
          'Ошибка сохранения рабочего интервала. Попробуйте изменить дату начала и дату завершения или выбрать другой день и попробовать еще раз'
        );
      });
  };
};

export const fetchSchedules = (doctorId: string, timeFrom?: Date, timeTo?: Date): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    getDoctorFutureWorkShifts(doctorId, timeFrom, timeTo)
      .then((response: any) => {
        dispatch(setLoading(false));
        if (response.data.code === 'success') {
          dispatch(
            setSchedules(
              (response.data.data.items || []).map((s: any) => ({
                startTime: parseISO(s.startTime),
                endTime: parseISO(s.endTime),
                id: s.id,
              }))
            )
          );
        } else {
          dispatch(setError(response.data));
        }
      })
      .catch((error: any) => {
        dispatch(setError(error));
        dispatch(setLoading(false));
      });
  };
};

export const fetchDoctorAppointments = (doctorId: string, timeFrom?: Date, timeTo?: Date): any => {
  return (dispatch) => {
    dispatch(setLoadingAppointments(true));
    getDoctorFutureSchedule(doctorId, timeFrom, timeTo)
      .then((response: any) => {
        dispatch(setLoadingAppointments(false));
        if (response.data.code === 'success') {
          dispatch(
            setAppointments(
              (response.data.data.items || []).map((app: any) => {
                return {
                  startTime: parseISO(app.startTime),
                  busy: app.busy === true,
                  appointment: app.appointment || null,
                };
              })
            )
          );
        } else {
          dispatch(setError(response.data));
        }
      })
      .catch((error: any) => {
        dispatch(setError(error));
        dispatch(setLoading(false));
      });
  };
};
