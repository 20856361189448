import { parseISO } from 'date-fns';
import { Box } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { format, getSecondsSince, toHHMMSS } from 'utils/date';
import { getAppointmentLogStatus } from 'api/getAppointmentLogStatus';
import Doctor from 'types/doctor';
import styles from './AppointmentTimer.module.sass';
import { AppointmentStatus } from '../../views/AppointmentDoctor/types';

export interface PropsInterface {
  appointment?: any;
  status?: any;
}

export const AppointmentTimer = (props: PropsInterface): JSX.Element => {
  const [currentTime, setCurrentTime] = useState<number>(0);
  const { appointment, status } = props;
  const { planDurationSeconds } = appointment;

  const isProgress = useMemo(() => status === AppointmentStatus.IN_PROGRESS, [status]);

  useEffect(() => {
    if (isProgress && appointment?.type?.value !== 'PERSONAL') {
      getAppointmentLogStatus({
        appointmentId: appointment.id,
        status,
      }).then((value) => {
        const seconds = getSecondsSince(value.createdAt);
        setCurrentTime(seconds >= planDurationSeconds ? 0 : planDurationSeconds - seconds);
      });
    }
  }, [appointment.id, appointment?.type?.value, isProgress, planDurationSeconds, status]);

  const timer = useCallback(() => {
    setCurrentTime(currentTime - 1);
  }, [currentTime]);

  useEffect(() => {
    const interval = currentTime > 0 ? setInterval(timer, 1000) : null;
    return () => {
      interval && clearInterval(interval);
    };
  }, [currentTime, timer]);

  const render = useMemo(() => {
    if (isProgress) {
      return <Box>{toHHMMSS(currentTime)}</Box>;
    }
    return <Box>{appointment.startTime && format(parseISO(appointment.startTime.toString()), 'dd.MM.yyyy HH:mm')}</Box>;
  }, [appointment.startTime, currentTime, isProgress]);

  return <Box className={styles.root}>{render}</Box>;
};
