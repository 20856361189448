import React, { useCallback, useEffect, useState } from 'react';
import ContentCard from 'components/ContentCard';
import { Avatar, Box, Popover } from '@material-ui/core';
import EventNoteIcon from '@material-ui/icons/EventNote';
import classNames from 'classnames';
import { ReactComponent as CheckIcon } from 'assets/img/icons/check-icon.svg';
import { endOfDay, isSameDay, startOfDay } from 'date-fns';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styles from './EventsDoctor.module.sass';
import Button from '../../components/Forms/Button';
import CalendarRange from '../../components/CalendarRange';
import { DoctorAppointments, DoctorsEventStatus } from '../../types';
import { fetchDoctorEvents, resetEvents } from '../../store/actions';
import ContentLoading from '../../components/ContentLoading';
import { format } from '../../utils/date';
import { decodeAvatarResource } from '../../utils';
import AppointmentDoctorView from '../../components/AppointmentDoctorView';
import SearchPatients from '../../components/SearchPatients';
import Patient from '../../types/patient';

interface PropsInterface {
  isPersonal?: boolean;
}
const EventsDoctor = (props: PropsInterface): JSX.Element => {
  const { isPersonal } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const { loading, errors, events, hasMore } = useSelector((state: RootStateOrAny) => ({
    loading: state.doctorEvents.loading,
    errors: state.doctorEvents.errors,
    events: state.doctorEvents.events,
    hasMore: state.doctorEvents.hasMore,
  }));
  const [date, onChangeDate] = useState<{ start: Date; end: Date }>({ start: null, end: null });
  const [search, onChangeSearch] = useState<Patient.Data>(null);
  const [statuses, onChangeStatuses] = useState<DoctorsEventStatus[]>([]);
  const [chosenDateOpen, toggleChosenDateOpen] = useState<HTMLButtonElement>(null);
  const [chosenStatusOpen, toggleChosenStatusOpen] = useState<HTMLButtonElement>(null);
  const [appointmentDoctorView, setAppointmentDoctorView] = useState<DoctorAppointments>(null);
  const isOpenDateOpen = Boolean(chosenDateOpen);
  const isOpenStatusOpen = Boolean(chosenStatusOpen);
  const toggleStatus = (status: DoctorsEventStatus): void => {
    if (statuses.indexOf(status) > -1) {
      onChangeStatuses(statuses.filter((s) => s !== status));
    } else {
      onChangeStatuses(statuses.concat(status));
    }
  };
  const getEvents = useCallback(
    (reset?: boolean) => {
      dispatch(
        fetchDoctorEvents(
          isPersonal,
          search ? [search.id] : [],
          date.start ? startOfDay(date.start) : null,
          date.end || date.start ? endOfDay(date.end || date.start) : null,
          statuses,
          reset
        )
      );
    },
    [date.end, date.start, dispatch, isPersonal, search, statuses]
  );
  useEffect(() => {
    getEvents(true);
  }, [getEvents]);
  useEffect(() => {
    return () => {
      onChangeStatuses([]);
      onChangeSearch(null);
      onChangeDate({ start: null, end: null });
      dispatch(resetEvents());
    };
  }, [isPersonal, dispatch]);
  const getLabelStatus = (status: DoctorsEventStatus): string => {
    switch (status) {
      case DoctorsEventStatus.AWAITS:
        return 'Ожидает ответа';
      case DoctorsEventStatus.CANCELED:
        return 'Отменена';
      case DoctorsEventStatus.DONE:
        return 'Завершена';
      case DoctorsEventStatus.IN_PROGRESS:
        return 'Сейчас идет';
      case DoctorsEventStatus.FINISHED:
        return 'Ожидает заключение';
      case DoctorsEventStatus.FAILED:
        return 'Техническая проблема';
      default:
        return null;
    }
  };
  const getDateFilterLine = (): JSX.Element => {
    const { start, end } = date;
    const res: string[] = [];
    if (start) {
      res.push(format(start, 'dd.MM.yyyy'));
    }
    if (end && start && !isSameDay(start, end)) {
      res.push(format(end, 'dd.MM.yyyy'));
    }
    if (res.length) {
      return (
        <Box component="span" ml={2}>
          {res.join(' - ')}
        </Box>
      );
    }
    return <></>;
  };
  return (
    <>
      <ContentCard>
        <ContentCard.Header>
          <div className="row">
            <div className="col-12 text-center text-xs-right text-md-center">
              <h6 className="page-header-title">{isPersonal ? 'Личный пациент' : 'Консультации'}</h6>
            </div>
          </div>
        </ContentCard.Header>
        <Box className={styles.EventsDoctor_Toolbar}>
          <Box className={styles.EventsDoctor_Toolbar_Search}>
            <SearchPatients value={search} onChange={onChangeSearch} placeholder="Введите ФИО пациента" />
          </Box>
          <Box className={styles.EventsDoctor_Toolbar_Buttons}>
            <Box className={styles.EventsDoctor_Toolbar_Button}>
              <Button
                color="primary"
                size="md"
                block
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => toggleChosenDateOpen(event.currentTarget)}
              >
                <EventNoteIcon /> {getDateFilterLine()}
              </Button>
              {(date.start || date.end) && (
                <Box
                  className={classNames(
                    styles.EventsDoctor_Status,
                    styles.EventsDoctor_Status_FAILED,
                    styles.EventsDoctor_Toolbar_Button_Label
                  )}
                >
                  &nbsp;
                </Box>
              )}
              <Popover
                PaperProps={{ className: classNames(styles.EventsDoctor_Popover, styles.EventsDoctor_Popover_Date) }}
                open={isOpenDateOpen}
                anchorEl={chosenDateOpen}
                onClose={() => toggleChosenDateOpen(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Box width="100%" p={2}>
                  <CalendarRange date={date} onChangeDate={(date: { start: Date; end: Date }) => onChangeDate(date)} />
                </Box>
                <Box width="100%" className={styles.EventsDoctor_Popover_Date_Reset}>
                  <Button
                    color="default"
                    block
                    size="md"
                    onClick={() => {
                      onChangeDate({ start: null, end: null });
                    }}
                    disabled={!(date.start || date.end)}
                  >
                    Сбросить фильтр
                  </Button>
                </Box>
              </Popover>
            </Box>
            <Box className={styles.EventsDoctor_Toolbar_Button}>
              <Button
                color="primary"
                size="md"
                block
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => toggleChosenStatusOpen(event.currentTarget)}
              >
                Статус
              </Button>
              {statuses.length > 0 && (
                <Box
                  className={classNames(
                    styles.EventsDoctor_Status,
                    styles.EventsDoctor_Status_FAILED,
                    styles.EventsDoctor_Toolbar_Button_Label
                  )}
                >
                  &nbsp;
                </Box>
              )}
              <Popover
                PaperProps={{ className: classNames(styles.EventsDoctor_Popover, styles.EventsDoctor_Popover_Status) }}
                open={isOpenStatusOpen}
                anchorEl={chosenStatusOpen}
                onClose={() => toggleChosenStatusOpen(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <ul className={styles.EventsDoctor_Toolbar_StatusList}>
                  {Object.values(DoctorsEventStatus)
                    .filter((s) => [DoctorsEventStatus.WAITING_FILLING].indexOf(s) === -1)
                    .map((status: DoctorsEventStatus, index: number) => (
                      <li key={index}>
                        <Box
                          className={styles.EventsDoctor_Toolbar_StatusList_Item}
                          onClick={() => toggleStatus(status)}
                        >
                          <Box
                            className={classNames(styles.EventsDoctor_Status, styles[`EventsDoctor_Status_${status}`])}
                          >
                            &nbsp;
                          </Box>
                          <Box display="flex" flex="1 1 auto" ml={2}>
                            {getLabelStatus(status)}
                          </Box>
                          <Box className={styles.EventsDoctor_Toolbar_StatusList_Item_Icon}>
                            {statuses.indexOf(status) > -1 && <CheckIcon />}
                          </Box>
                        </Box>
                      </li>
                    ))}
                  <li>
                    <Button
                      color="default"
                      block
                      size="md"
                      onClick={() => {
                        onChangeStatuses([]);
                      }}
                      disabled={!statuses.length}
                    >
                      Сбросить фильтр
                    </Button>
                  </li>
                </ul>
              </Popover>
            </Box>
          </Box>
        </Box>
        <ContentCard.Main>
          <>
            <Box
              width="100%"
              display="flex"
              flexDirection="column"
              justifyContent="flex-between"
              alignItems="stretch"
              className={styles.EventsDoctor_Wrapper}
            >
              <Box
                pt={2}
                pb={2}
                width="100%"
                display="flex"
                flexDirection="row"
                justifyContent="flex-between"
                alignItems="center"
                className={classNames(styles.EventsDoctor_Row, styles.EventsDoctor_Row_Header)}
              >
                <Box className={classNames(styles.EventsDoctor_Row_Cell, styles.EventsDoctor_Row_Cell_Header)}>
                  Пациент
                </Box>
                <Box className={classNames(styles.EventsDoctor_Row_Cell, styles.EventsDoctor_Row_Cell_Header)}>Тип</Box>
                <Box className={classNames(styles.EventsDoctor_Row_Cell, styles.EventsDoctor_Row_Cell_Header)}>
                  Вопрос
                </Box>
                <Box className={classNames(styles.EventsDoctor_Row_Cell, styles.EventsDoctor_Row_Cell_Header)}>
                  Дата
                </Box>
                <Box className={classNames(styles.EventsDoctor_Row_Cell, styles.EventsDoctor_Row_Cell_Header)}>
                  Статус
                </Box>
              </Box>
              <Box
                p={0}
                width="100%"
                height="100%"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Box className={styles.EventsDoctor_InfiniteScroll}>
                  <>
                    {events.map((event: DoctorAppointments) => (
                      <Box
                        onClick={() => setAppointmentDoctorView(event)}
                        key={event.id}
                        pt={2}
                        pb={2}
                        width="100%"
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-between"
                        alignItems="center"
                        className={styles.EventsDoctor_Row}
                      >
                        <Box className={styles.EventsDoctor_Row_Cell}>
                          {event.patient && (
                            <Avatar
                              className={styles.EventsDoctor_ProfileImg}
                              src={decodeAvatarResource(event.patient.avatar, 360, 'MALE')}
                            />
                          )}
                          <Box>
                            {event.patient.firstName} {event.patient.lastName}
                          </Box>
                        </Box>
                        <Box className={styles.EventsDoctor_Row_Cell}>
                          <Box>{event.type?.name || '-'}</Box>
                        </Box>
                        <Box className={styles.EventsDoctor_Row_Cell}>
                          <Box>{event.subject}</Box>
                        </Box>
                        <Box className={styles.EventsDoctor_Row_Cell}>
                          <Box>{format(event.startTime as Date, 'dd.MM.yyyy | HH:mm')}</Box>
                        </Box>
                        <Box
                          className={classNames(
                            styles.EventsDoctor_Row_Cell,
                            styles[`EventsDoctor_Row_Cell_Status_${event.status}`]
                          )}
                        >
                          <Box>{getLabelStatus(event.status)}</Box>
                        </Box>
                      </Box>
                    ))}
                    {loading && !errors && (
                      <Box width="100%" p={2}>
                        <ContentLoading isLoading={loading} isError={errors} fetchData={() => {}} />
                      </Box>
                    )}
                  </>
                </Box>
                {hasMore && !loading && (
                  <Box p={1}>
                    <Button
                      size="md"
                      color="default"
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        getEvents();
                      }}
                    >
                      Показать еще
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
            {appointmentDoctorView && (
              <AppointmentDoctorView
                withAction
                history={history}
                appointmentId={appointmentDoctorView.id}
                onClose={() => setAppointmentDoctorView(null)}
              />
            )}
          </>
        </ContentCard.Main>
      </ContentCard>
    </>
  );
};
export default EventsDoctor;
