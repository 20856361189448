import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Popover } from '@material-ui/core';
import { onDeleteJournal, onSetJournalDelete, onSetJournalEdit } from 'views/JournalPage/store/events';
import classNames from 'classnames';
import styles from './JournalViewList.module.sass';
import useRootSelector from '../../../hooks/useRootSelector';
import {
  selectJournalDeleteItem,
  selectJournalItems,
  selectJournalMaxMin,
  selectJournalType,
  selectJournalTypeUnit,
} from '../store/selectors';
import { convertUtcToZonedTime, format } from '../../../utils/date';
import { JournalType, JournalUnitTypeTitle, JournalValueColor, JournalValueColorStatus } from '../types';
import Button from '../../../components/Forms/Button';
import { UserRole } from '../../../types';
import HasRole from '../../../components/HasRole';

const JournalViewList = (): JSX.Element => {
  const items = useRootSelector(selectJournalItems);
  const unit = useRootSelector(selectJournalTypeUnit);
  const type = useRootSelector(selectJournalType);
  const deleteItem = useRootSelector(selectJournalDeleteItem);
  const isFeeling = useMemo(() => type === JournalType.Feeling, [type]);
  const [anchorDialog, setAnchorDialog] = useState<HTMLButtonElement | null>(null);
  const isOpenDialog = useMemo(() => Boolean(anchorDialog), [anchorDialog]);
  const handleClick = useCallback((data: any) => {
    const { id, type, values, description } = data;
    onSetJournalEdit({ id, [type]: { ...values, description } });
  }, []);
  const handleDelete = useCallback(() => {
    setAnchorDialog(null);
    onDeleteJournal(deleteItem);
  }, [deleteItem]);
  useEffect(() => {
    !anchorDialog && onSetJournalDelete(null);
  }, [anchorDialog]);
  const handleConfirmDelete = useCallback((event: React.MouseEvent<HTMLButtonElement>, data: any) => {
    onSetJournalDelete(data);
    setAnchorDialog(event.currentTarget);
  }, []);
  const minMax = useRootSelector(selectJournalMaxMin);
  const renderValues = useCallback(
    (values: any): Array<Array<any>> => {
      return unit.map((v, i) => {
        const value = values?.[v];
        const color = minMax
          ? (minMax || []).find(({ max, min }) => min <= value && max >= value)?.color || JournalValueColor.RED
          : null;
        return (
          <Fragment key={i}>
            <Box className={styles.root_item_value}>
              <Box className={styles.root_item_value_wrapper}>
                <Box className={styles.root_item_value_data}>{value}</Box>
                <Box className={styles.root_item_value_unit}>{JournalUnitTypeTitle?.[v]}</Box>
              </Box>
              {color && (
                <Box
                  className={styles.root_item_value_status}
                  style={{ backgroundColor: `${JournalValueColorStatus?.[color]}` }}
                >
                  &nbsp;
                </Box>
              )}
            </Box>
            {unit.length !== i + 1 && <Box className={styles.root_item_value_dot}>/</Box>}
          </Fragment>
        );
      });
    },
    [unit]
  );
  return (
    <>
      <Box className={styles.root}>
        {items?.map((data) => (
          <Box key={data?.id} className={styles.root_item}>
            <Box className={styles.root_item_date}>
              <Box>{format(convertUtcToZonedTime(data?.values?.observationTime), 'dd MMMM yyyy г. в HH:mm')}</Box>
              {!isFeeling && <Box className={styles.root_item_values}>{renderValues(data?.values)}</Box>}
            </Box>
            <Box className={styles.root_item_data}>
              {isFeeling && (
                <Box className={classNames(styles.root_item_desc, styles.root_item_desc_text)}>
                  {data?.values?.[unit?.[0]]}
                </Box>
              )}
              {!isFeeling && data?.description && <Box className={styles.root_item_desc}>{data?.description}</Box>}
              {!isFeeling && <Box className={styles.root_item_values}>{renderValues(data?.values)}</Box>}
            </Box>
            <Box className={styles.root_item_actions}>
              <HasRole target={UserRole.PATIENT}>
                <Box className={styles.root_item_action} onClick={() => handleClick(data)}>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0 15.3666C0.4448 13.8402 0.888 12.3138 1.3328 10.7874C1.368 10.6658 1.4048 10.5442 1.4368 10.4226C1.488 10.2386 1.584 10.0898 1.7312 9.96664C1.8688 9.85304 1.9904 9.71704 2.1168 9.59064C4.2016 7.52505 6.2864 5.45945 8.3696 3.39226C8.4176 3.34426 8.4592 3.28986 8.4928 3.24826C8.776 3.53306 9.0416 3.80026 9.3376 4.09786C9.3056 4.12026 9.2432 4.15226 9.1952 4.20026C7.0352 6.35545 4.8768 8.51065 2.7216 10.6706C2.6448 10.749 2.5808 10.8562 2.5504 10.9618C2.2912 11.8178 2.0448 12.6786 1.7888 13.5378C1.7568 13.645 1.7728 13.7122 1.8544 13.7858C1.9872 13.9058 2.104 14.0434 2.2384 14.1618C2.2848 14.2018 2.3728 14.2322 2.4288 14.2178C3.2896 13.9714 4.1488 13.7202 5.0064 13.461C5.08 13.4386 5.1488 13.3762 5.2064 13.317C7.3968 11.133 9.584 8.94584 11.7712 6.76025C11.8128 6.71865 11.848 6.67225 11.872 6.64505C12.1824 6.95545 12.4832 7.25625 12.8128 7.58585C12.7984 7.59545 12.7408 7.61945 12.7008 7.65945C10.544 9.79544 8.384 11.9266 6.2384 14.0738C5.9392 14.3746 5.6144 14.5602 5.2128 14.6738C3.7888 15.077 2.3712 15.5026 0.9504 15.9202C0.856 15.9474 0.76 15.973 0.6656 16.0002H0.5376C0.4688 15.9698 0.3984 15.9426 0.3296 15.9106C0.1376 15.821 0.072 15.6386 0 15.461V15.3666ZM4.3024 12.7154C4.2608 12.4498 4.216 12.2066 4.1872 11.9634C4.1728 11.8482 4.1264 11.8018 4.0144 11.781C3.7408 11.7282 3.4704 11.6594 3.2176 11.6034C5.4864 9.32984 7.7552 7.05465 10.0096 4.79386C10.3696 5.15385 10.7456 5.52825 11.1088 5.88985C8.8624 8.14265 6.5952 10.4162 4.3024 12.7154ZM10.8368 1.02586C11.0416 0.825865 11.2464 0.613065 11.4656 0.414666C12.0944 -0.151733 12.9136 -0.138933 13.5264 0.459466C14.2032 1.11866 14.8704 1.78586 15.5296 2.46266C16.1536 3.10106 16.144 3.95546 15.5216 4.59226C15.3344 4.78426 15.1408 4.97145 14.9616 5.14745C13.592 3.77946 12.224 2.41306 10.8368 1.02586ZM9.168 2.53626C9.4784 2.24506 9.7968 1.94586 10.0992 1.66106C11.5328 3.09466 12.9584 4.52186 14.3968 5.96025C14.0976 6.24025 13.776 6.54265 13.4656 6.83385L9.168 2.53626Z"
                      fill="#8F9DC9"
                    />
                  </svg>
                </Box>
                <Box
                  className={styles.root_item_action}
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleConfirmDelete(event, data)}
                >
                  <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M2.00964 13.3248C2.02564 13.7024 2.33604 14 2.71364 14H9.29124C9.66884 14 9.98084 13.7024 9.99524 13.3248L10.4656 3.408H1.53924L2.00964 13.3248ZM7.48964 5.872C7.48964 5.7136 7.61764 5.5856 7.77604 5.5856H8.23364C8.39204 5.5856 8.52004 5.7152 8.52004 5.872V11.5376C8.52004 11.696 8.39044 11.824 8.23364 11.824H7.77604C7.61764 11.824 7.48964 11.696 7.48964 11.5376V5.872ZM5.48804 5.872C5.48804 5.7136 5.61604 5.5856 5.77444 5.5856H6.23204C6.39044 5.5856 6.51845 5.7152 6.51845 5.872V11.5376C6.51845 11.696 6.39044 11.824 6.23204 11.824H5.77444C5.61604 11.824 5.48804 11.696 5.48804 11.5376V5.872ZM3.48484 5.872C3.48484 5.7136 3.61284 5.5856 3.76964 5.5856H4.22724C4.38564 5.5856 4.51364 5.7152 4.51364 5.872V11.5376C4.51364 11.696 4.38404 11.824 4.22724 11.824H3.76964C3.61124 11.824 3.48484 11.696 3.48484 11.5376V5.872ZM10.7776 0.7216H7.74404V0.1472C7.74404 0.0656 7.67844 0 7.59684 0H4.40964C4.32804 0 4.26244 0.0656 4.26244 0.1472V0.7216H1.22724C0.984044 0.7216 0.785645 0.9184 0.785645 1.1632V2.552H11.2192V1.1632C11.2192 0.92 11.0224 0.7216 10.7776 0.7216Z"
                      fill="#FF6760"
                    />
                  </svg>
                </Box>
                <Popover
                  PaperProps={{
                    className: styles.root_mui_paper_root,
                  }}
                  open={isOpenDialog}
                  anchorEl={anchorDialog}
                  onClose={() => setAnchorDialog(null)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <Box className={styles.root_popover}>
                    <Box className={styles.root_popover_text}>Вы уверены, что хотите удалить запись?</Box>
                    <Box className={styles.root_popover_actions}>
                      <Button size="md" type="button" color="primary" onClick={handleDelete}>
                        Удалить
                      </Button>
                      <Button size="md" type="button" color="default" onClick={() => setAnchorDialog(null)}>
                        Отменить
                      </Button>
                    </Box>
                  </Box>
                </Popover>
              </HasRole>
            </Box>
          </Box>
        ))}
      </Box>
    </>
  );
};

export default JournalViewList;
