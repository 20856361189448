import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { fetchNotifications } from 'store/actions';
import { setNotificationsSettings } from 'api';
import ContentLoading from 'components/ContentLoading';
import styles from '../ProfilePage.module.sass';

const NotificationsView = (props: any) => {
  const [notificationsViewType, setNotificationsViewType] = useState('EMAIL');

  const [notificationsSmsList, setNotificationsSmsList] = useState({});
  const [notificationsEmailList, setNotificationsEmailList] = useState({});

  useEffect(() => {
    props.fetchNotifications();
  }, []);

  useEffect(() => {
    setNotificationsSmsList({ ...props.notificationsSmsList });
  }, [props.notificationsSmsList]);

  useEffect(() => {
    setNotificationsEmailList({ ...props.notificationsEmailList });
  }, [props.notificationsEmailList]);

  const getNotificationName = (notificationType: string) => {
    let notificationName = '';

    switch (notificationType) {
      case 'APPOINTMENT_CANCEL':
        notificationName = 'Отмена консультации';
        break;

      case 'APPOINTMENT_CONCLUSION':
        notificationName = 'Заключение консультации';
        break;

      case 'APPOINTMENT_NEW':
        notificationName = 'Новая консультация';
        break;

      case 'APPOINTMENT_STARTED':
        notificationName = 'Начало консультации';
        break;

      case 'MONEY_INCOME':
        notificationName = 'Зачисление средств';
        break;

      case 'MONEY_OUTCOME':
        notificationName = 'Списание средств';
        break;

      case 'NEWS':
        notificationName = 'Новости';
        break;

      default:
        notificationName = '';
    }

    return notificationName;
  };

  const getMobileViewNotificationsList = (notifiViewType: string) => {
    return Object.keys(notificationsEmailList).map((notification) => {
      let isChecked = false;

      if (notifiViewType === 'EMAIL') {
        isChecked = notificationsEmailList[notification];
      } else if (notifiViewType === 'SMS') {
        isChecked = notificationsSmsList[notification];
      }

      return (
        <div className={styles.profileNotificationsView_list_item}>
          <div className="toggle-checkbox">
            <h5 className="label">{getNotificationName(notification)}</h5>

            <label className="toggle-checkbox-label">
              <input
                type="checkbox"
                checked={isChecked}
                onChange={(e) => {
                  setNotificationsSettings(notifiViewType, notification, e.target.checked);

                  if (notifiViewType === 'EMAIL') {
                    setNotificationsEmailList({
                      ...notificationsEmailList,
                      [notification]: e.target.checked,
                    });
                  } else if (notifiViewType === 'SMS') {
                    setNotificationsSmsList({
                      ...notificationsSmsList,
                      [notification]: e.target.checked,
                    });
                  }
                }}
              />

              <span className="checkmark">
                <span className="check" />
              </span>
            </label>
          </div>
        </div>
      );
    });
  };

  const { notificationsLoading, notificationsLoadingError } = props;

  return (
    <div className={styles.profileNotificationsView}>
      <div className={`${styles.profileNotifications_tabs} d-md-none`}>
        <div
          className={classNames(styles.profileNotifications_tabs_item, {
            [styles.profileNotifications_tabs_item_active]: notificationsViewType === 'EMAIL',
          })}
          onClick={() => setNotificationsViewType('EMAIL')}
        >
          На эл. почту
        </div>
      </div>

      <div className={`${styles.profileNotificationsView_list} mt-3 d-md-none`}>
        {getMobileViewNotificationsList(notificationsViewType)}
      </div>

      <div className="row mb-3 d-none d-md-flex">
        <div className="col-12 text-center">
          <h6>Оповещение на электронную почту</h6>
        </div>
        <div className={styles.profileNotificationsView_notificationsEmailList}>
          <ContentLoading
            isLoading={notificationsLoading}
            isError={notificationsLoadingError}
            fetchData={() => props.fetchNotifications()}
          >
            {Object.keys(notificationsEmailList).map((notification) => (
              <div className={styles.profileNotificationsView_notificationsEmailList_item}>
                <div>
                  <h5
                    className={classNames([
                      styles.profileNotificationsView_notificationsEmailList_item_label,
                      notificationsEmailList[notification] &&
                        styles.profileNotificationsView_notificationsEmailList_item_label_active,
                    ])}
                    onClick={() => {
                      const value = !notificationsEmailList[notification];
                      setNotificationsSettings('EMAIL', notification, value);
                      setNotificationsEmailList({
                        ...notificationsEmailList,
                        [notification]: value,
                      });
                    }}
                  >
                    {getNotificationName(notification)}
                  </h5>
                </div>
                <div>
                  <label className="toggle-checkbox-label">
                    <input
                      type="checkbox"
                      checked={notificationsEmailList[notification]}
                      onChange={(e) => {
                        setNotificationsSettings('EMAIL', notification, e.target.checked);
                        setNotificationsEmailList({
                          ...notificationsEmailList,
                          [notification]: e.target.checked,
                        });
                      }}
                    />

                    <span className="checkmark">
                      <span className="check" />
                    </span>
                  </label>
                </div>
              </div>
            ))}
          </ContentLoading>
        </div>
      </div>
    </div>
  );
};

export default connect(
  (state: any) => ({
    notificationsSmsList: state.profile.notificationsSmsList,
    notificationsEmailList: state.profile.notificationsEmailList,
    notificationsLoading: state.profile.notificationsLoading,
    notificationsLoadingError: state.profile.notificationsLoadingError,
  }),
  { fetchNotifications }
)(NotificationsView);
