import React, { useEffect, useState } from 'react';
import ContentCard from 'components/ContentCard';
import { Avatar, Box } from '@material-ui/core';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import classNames from 'classnames';
import EventIcon from '@material-ui/icons/Event';
import TuneIcon from '@material-ui/icons/Tune';
import { parseISO } from 'date-fns';
import styles from './ReviewsPageAdmin.module.sass';
import { fetchManageReviews } from '../../../store/actions';
import ContentLoading from '../../../components/ContentLoading';
import { decodeAvatarResource } from '../../../utils';
import InputField from '../../../components/Forms/InputField';
import Button from '../../../components/Forms/Button';
import Manage from '../../../types/manage';
import DropdownToggle from '../../../components/DropdownMenu/DropdownToggle';
import DropdownMenu from '../../../components/DropdownMenu';
import DropdownContent from '../../../components/DropdownMenu/DropdownContent';
import CalendarRange from '../../../components/CalendarRange';
import { DoctorsEventStatus } from '../../../types';
import { ManageReviewsInitialSettings } from '../../../store/reducers/manageReviewsReducer.reducer';
import { ReactComponent as CheckIcon } from '../../../assets/img/icons/check-icon.svg';
import { format } from '../../../utils/date';
import StarRating from '../../../components/StarRating';
import AdminReviewAppointmentView from '../../../components/AdminReviewAppointmentView';

interface ReviewsPageAdminInterface {
  page: number;
  pageSize: number;
  hasMore: boolean;
  loading: boolean;
  errors: any;
  reviews: Manage.Review.Item[];
  fetchManageReviews: (
    query?: string,
    timeFrom?: Date,
    timeTo?: Date,
    statuses?: string[],
    page?: number,
    pageSize?: number,
    reset?: boolean
  ) => void;
}
const ReviewsPageAdmin = (props: ReviewsPageAdminInterface) => {
  const { reviews = [], loading, errors, page, pageSize, hasMore, fetchManageReviews } = props;
  const [search, onChangeSearch] = useState<string>('');
  const [date, onChangeDate] = useState<{ start: Date; end: Date }>({ start: null, end: null });
  const [statuses, onChangeStatuses] = useState<string[]>([]);
  const [review, setReview] = useState<Manage.Review.Item>(null);
  const [toolbarDateOpen, toggleToolbarDateOpen] = useState<boolean>(false);
  const [toolbarStatusOpen, toggleToolbarStatusOpen] = useState<boolean>(false);
  useEffect(() => {
    if (!search) {
      getReviews(true);
    }
    const timeoutSearch: NodeJS.Timeout = search ? setTimeout(() => getReviews(true), 500) : null;
    return () => {
      timeoutSearch && clearTimeout(timeoutSearch);
    };
  }, [search, date, statuses]);
  const getReviews = (reset?: boolean) => {
    fetchManageReviews(search, date.start, date.end, statuses, reset ? 0 : page + 1, pageSize, reset);
  };
  const getLabelStatus = (status: string): string => {
    switch (status) {
      case 'APPROVED':
        return 'Проверен';
      case 'AWAITS':
        return 'Новый';
      case 'REJECTED':
        return 'Нарушение';
      default:
        return null;
    }
  };
  return (
    <>
      <ContentCard>
        <ContentCard.Header>
          <div className="row">
            <div className="col-12 text-center text-xs-right text-md-center">
              <h6 className="page-header-title">Обратная связь</h6>
            </div>
          </div>
        </ContentCard.Header>
        <Box className={styles.ReviewsPageAdmin_Toolbar}>
          <Box className={styles.ReviewsPageAdmin_Toolbar_Search}>
            <SearchIcon className={styles.ReviewsPageAdmin_Toolbar_Search_Icon} />
            <InputField
              value={search}
              onChange={(e) => {
                onChangeSearch(e.target.value);
              }}
              block
              placeholder="Введите ФИО пациента"
            />
            {search && (
              <ClearIcon
                onClick={() => {
                  onChangeSearch('');
                }}
                className={classNames(
                  styles.ReviewsPageAdmin_Toolbar_Search_Icon,
                  styles.ReviewsPageAdmin_Toolbar_Search_Icon_Clear
                )}
              />
            )}
          </Box>
          <Box className={styles.ReviewsPageAdmin_Toolbar_Buttons}>
            <DropdownMenu isOpen={toolbarDateOpen} toggle={() => toggleToolbarDateOpen(!toolbarDateOpen)}>
              <DropdownToggle>
                <Button color="primary" size="md">
                  <EventIcon />
                  {(date.start || date.end) &&
                    [date.start, date.end]
                      .filter((v) => v)
                      .map((v) => format(v, 'dd.MM.yyyy'))
                      .join(' - ')}
                  {(date.start || date.end) && <Box className={styles.ReviewsPageAdmin_Toolbar_Buttons_Status} />}
                </Button>
              </DropdownToggle>
              <DropdownContent
                className={classNames(
                  styles.ReviewsPageAdmin_Toolbar_DropdownContent,
                  styles.ReviewsPageAdmin_Toolbar_DropdownContent_Calendar
                )}
              >
                <CalendarRange date={date} onChangeDate={(date: { start: Date; end: Date }) => onChangeDate(date)} />
                {(date.start || date.end) && (
                  <Box className={styles.ReviewsPageAdmin_Toolbar_DropdownContent_Reset}>
                    <Button
                      color="default"
                      block
                      size="md"
                      onClick={(event) => {
                        event.preventDefault();
                        onChangeDate({ start: null, end: null });
                      }}
                    >
                      Сбросить фильтр
                    </Button>
                  </Box>
                )}
              </DropdownContent>
            </DropdownMenu>
            <DropdownMenu isOpen={toolbarStatusOpen} toggle={() => toggleToolbarStatusOpen(!toolbarStatusOpen)}>
              <DropdownToggle>
                <Button color="primary" size="md">
                  <TuneIcon />
                  {statuses.length > 0 && <Box className={styles.ReviewsPageAdmin_Toolbar_Buttons_Status} />}
                </Button>
              </DropdownToggle>
              <DropdownContent className={styles.ReviewsPageAdmin_Toolbar_DropdownContent}>
                <ul>
                  {['APPROVED', 'AWAITS', 'REJECTED'].map((_status: string, index: number) => (
                    <li
                      key={index}
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        if (statuses.indexOf(_status) > -1) {
                          onChangeStatuses(statuses.filter((s) => s !== _status));
                        } else {
                          onChangeStatuses([...statuses, _status]);
                        }
                      }}
                    >
                      <Box>
                        <Box
                          className={classNames(
                            styles.ReviewsPageAdmin_Toolbar_DropdownContent_Status,
                            styles[`ReviewsPageAdmin_Toolbar_DropdownContent_Status_${_status}`]
                          )}
                        />
                        <Box display="flex" flex="1 1 auto" width="100%" pl={2}>
                          {getLabelStatus(_status)}
                        </Box>
                      </Box>
                      <CheckIcon
                        className={classNames(
                          styles.ReviewsPageAdmin_Toolbar_DropdownContent_Icon,
                          statuses.indexOf(_status) === -1 &&
                            styles.ReviewsPageAdmin_Toolbar_DropdownContent_Icon_Hidden
                        )}
                      />
                    </li>
                  ))}
                </ul>
                {statuses.length > 0 && (
                  <Box className={styles.ReviewsPageAdmin_Toolbar_DropdownContent_Reset}>
                    <Button
                      color="default"
                      block
                      size="md"
                      onClick={(event) => {
                        event.preventDefault();
                        onChangeStatuses([]);
                      }}
                    >
                      Сбросить фильтр
                    </Button>
                  </Box>
                )}
              </DropdownContent>
            </DropdownMenu>
          </Box>
        </Box>
        <ContentCard.Main>
          <>
            <Box
              p={0}
              width="100%"
              height="100%"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <InfiniteScroll
                className={styles.ReviewsPageAdmin_InfiniteScroll}
                pageStart={0}
                hasMore={loading ? false : hasMore}
                initialLoad={false}
                threshold={pageSize}
                loadMore={() => getReviews()}
                loader={null}
              >
                <>
                  {reviews.map((item: Manage.Review.Item, index: number) => (
                    <Box key={index} className={styles.ReviewsPageAdmin_Row} onClick={() => setReview(item)}>
                      <Box className={styles.ReviewsPageAdmin_Row_Main}>
                        <Box className={styles.ReviewsPageAdmin_Row_Cell}>
                          <Box className={styles.ReviewsPageAdmin_Profile}>
                            <Box className={classNames(styles.ReviewsPageAdmin_Profile_Data)}>
                              <Box
                                className={classNames(
                                  styles.ReviewsPageAdmin_Profile_Item,
                                  styles.ReviewsPageAdmin_Profile_Name
                                )}
                              >
                                {[item.patient.firstName, item.patient.lastName, item.patient.middleName]
                                  .filter((s) => s)
                                  .join(' ')}{' '}
                              </Box>
                              <Box className={classNames(styles.ReviewsPageAdmin_Profile_Date)}>
                                {format(parseISO(item.createdAt.toString()), 'dd.MM.yyyy HH:mm')}
                              </Box>
                            </Box>
                            <StarRating rating={item.rating} />
                          </Box>
                          <Box
                            className={classNames(
                              styles.ReviewsPageAdmin_Profile_Item,
                              styles.ReviewsPageAdmin_Profile_Status,
                              styles[`ReviewsPageAdmin_Profile_Status_${item.status.value}`]
                            )}
                          >
                            {getLabelStatus(item.status.value as string)}
                          </Box>
                          <Box
                            className={classNames(
                              styles.ReviewsPageAdmin_Profile_Item,
                              styles.ReviewsPageAdmin_Profile_Review
                            )}
                          >
                            {item.subject}
                          </Box>
                        </Box>
                      </Box>
                      <Box pb={0} className={styles.ReviewsPageAdmin_Row_Main}>
                        <Box className={classNames(styles.ReviewsPageAdmin_Row_Cell)}>
                          <Box
                            mb={1}
                            className={classNames(
                              styles.ReviewsPageAdmin_Profile_Item,
                              styles.ReviewsPageAdmin_Profile_Label
                            )}
                          >
                            Врач
                          </Box>
                          <Box
                            className={classNames(
                              styles.ReviewsPageAdmin_Profile_Item,
                              styles.ReviewsPageAdmin_Profile_Name
                            )}
                          >
                            {item.doctor.firstName} {item.doctor.lastName} {item.doctor.middleName}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  ))}
                  {(loading || errors) && (
                    <Box width="100%" p={2}>
                      <ContentLoading isLoading={loading} isError={errors} fetchData={() => getReviews()} />
                    </Box>
                  )}
                </>
              </InfiniteScroll>
            </Box>
          </>
        </ContentCard.Main>
      </ContentCard>
      {review && (
        <AdminReviewAppointmentView
          review={review}
          onClose={(reset) => {
            reset && fetchManageReviews(search, date.start, date.end, statuses, page, pageSize, false);
            setReview(null);
          }}
        />
      )}
    </>
  );
};
export default connect(
  (state: any) => ({
    ...(state.manageReviews as ManageReviewsInitialSettings),
  }),
  {
    fetchManageReviews,
  }
)(ReviewsPageAdmin);
