import React, { memo, ReactElement, useMemo } from 'react';
import { Box } from '@material-ui/core';
import { useStore } from 'effector-react';
import styles from './AgentMetricsCount.module.sass';
import store from '../../store';

const AgentMetricsCount = (): ReactElement => {
  const { metrics } = useStore(store);
  const count = useMemo(() => (metrics?.agentDashboardData || []).length, [metrics?.agentDashboardData]);
  return (
    <>
      <Box component="h6" className={styles.root}>
        Найдено {count || 0} записей
      </Box>
    </>
  );
};

export default AgentMetricsCount;
