import React, { memo, ReactNode, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { Box } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { ReactComponent as TariffIcon } from '../../assets/img/icons/tariff.svg';
import styles from './PackageItem.module.sass';
import Button from '../Forms/Button';

interface PropsInterface {
  item: any;
  all?: boolean;
  loading?: boolean;
  onBuy?: (...args: any) => any;
  actions?: ReactNode;
  showLink?: boolean;
}
const PackageItem = memo((props: PropsInterface) => {
  const history = useHistory();
  const { item, all = false, onBuy = () => {}, loading = false, showLink = true, actions } = props;
  const to = useMemo(() => `/packages/${all ? 'all/' : ''}${item?.id}`, [all, item]);
  const handleClick = useCallback(() => {
    showLink && history.push(to);
  }, [history, showLink, to]);
  return (
    <Box onClick={handleClick} className={classNames(styles.root, all && styles.root_all)}>
      <Box className={styles.root_content}>
        <Box className={styles.root_icon}>
          <TariffIcon />
        </Box>
        <Box className={styles.root_info}>
          <div className={styles.root_name}>{item?.name}</div>
          {showLink && (
            <Box className={styles.root_about} onClick={handleClick}>
              <Box mr={1}>Описание тарифа</Box>
              <Box pt={0.5}>
                <KeyboardArrowDownIcon />
              </Box>
            </Box>
          )}
        </Box>
        {(all || actions) && (
          <>
            <div className={styles.root_price}>
              <h6>{item?.price} ₽</h6>
            </div>
            <div className={styles.root_action}>
              {actions || (
                <Button
                  size="lg"
                  block
                  isLoading={loading}
                  color="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onBuy(item?.id);
                  }}
                >
                  Купить
                </Button>
              )}
            </div>
          </>
        )}
        {!all && item?.status?.value === 'PURCHASED' && (
          <Button
            size="lg"
            className="mb-2"
            color="alert"
            isLoading={loading}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onBuy(item?.id, item?.debt?.debtSum || 0);
            }}
          >
            Оплатить
          </Button>
        )}
      </Box>
    </Box>
  );
});

export default PackageItem;
