import axios from 'utils/axios';

export const getTransactions = (patientServicePackageId: number, page?: number, pageSize?: number) => {
  page = page || 0;
  pageSize = pageSize || 50;
  return axios.get(`/api/v1/servicepackages/my/${patientServicePackageId}/transactions`, {
    params: {
      page,
      pageSize,
    },
  });
};
