import React, { useCallback, useEffect, useState } from 'react';
import ContentCard from 'components/ContentCard';
import { Avatar, Box } from '@material-ui/core';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import classNames from 'classnames';
import EventIcon from '@material-ui/icons/Event';
import { parseISO } from 'date-fns';
import {
  fetchManagePatientAppointments,
  fetchManagePatients,
  setManagePatientAppointmentsLoading,
} from 'store/actions';
import ContentLoading from 'components/ContentLoading';
import { decodeAvatarResource, getDisplayName } from 'utils';
import InputField from 'components/Forms/InputField';
import Button from 'components/Forms/Button';
import Manage from 'types/manage';
import DropdownToggle from 'components/DropdownMenu/DropdownToggle';
import DropdownMenu from 'components/DropdownMenu';
import DropdownContent from 'components/DropdownMenu/DropdownContent';
import CalendarRange from 'components/CalendarRange';
import AdminPatientView from 'components/AdminPatientView';
import { format } from 'utils/date';
import { DoctorsEventStatus } from 'types';
import AdminPatientAppointmentView from 'components/AdminPatientAppointmentView';
import { getOutpatientCardCover } from 'api/getOutpatientCardCover';
import { displayErrors, handleHttpError } from 'utils/handleHttpError';
import DeleteDialog from 'components/DeleteDialog';
import { depersonalizationPatient } from 'api/depersonalizationPatient';
import { toast } from 'react-toastify';
import { setBankruptApi } from '$api';
import styles from './PatientsPageAdmin.module.sass';

interface PatientsPageAdminInterface {
  page: number;
  pageSize: number;
  hasMore: boolean;
  loading: boolean;
  errors: any;
  patients: Manage.Patient.Item[];
  pageAppointments: number;
  pageSizeAppointments: number;
  hasMoreAppointments: boolean;
  loadingAppointments: boolean;
  errorsAppointments: any;
  patientAppointments: Manage.Patient.Appointments.Item[];
  setManagePatientAppointmentsLoading: (loading?: boolean, reset?: boolean) => void;
  fetchManagePatientAppointments: (patientId?: string, page?: number, pageSize?: number, reset?: boolean) => void;
  fetchManagePatients: (
    query?: string,
    activeFrom?: Date,
    activeTo?: Date,
    external?: boolean,
    page?: number,
    pageSize?: number,
    reset?: boolean
  ) => void;
}
const PatientsPageAdmin = (props: any) => {
  const {
    patients = [],
    loading,
    errors,
    page,
    pageSize,
    hasMore,
    pageAppointments,
    pageSizeAppointments,
    hasMoreAppointments,
    loadingAppointments,
    errorsAppointments,
    patientAppointments,
    setManagePatientAppointmentsLoading,
    fetchManagePatients,
    fetchManagePatientAppointments,
  } = props;
  const [loadingBankrup, setLoadingBankrup] = useState<string | null>(null);
  const [timeoutPatient, setTimeoutPatient] = useState<NodeJS.Timeout>(null);
  const [timeoutSearch, setTimeoutSearch] = useState<NodeJS.Timeout>(null);
  const [search, onChangeSearch] = useState<string>('');
  const [date, onChangeDate] = useState<{ start: Date; end: Date }>({ start: null, end: null });
  const [isMFO, onChangeMFO] = useState<boolean | null>(null);
  const [toolbarOpen, toggleToolbarOpen] = useState<boolean>(false);
  const [patient, setPatient] = useState<Manage.Patient.Item>(null);
  const [appointment, setAppointment] = useState<Manage.Patient.Appointments.Item>(null);
  const [patientAppointment, setPatientAppointment] = useState<Manage.Patient.Item>(null);
  const [depersona, setDepersona] = useState<any>(null);
  const handleSetBankrupt = useCallback(
    (patientId: string) => {
      setLoadingBankrup(patientId);
      setBankruptApi(patientId)
        .then((data) => data.data.code === 'success' && toast.success('Клиент обанкрочен'))
        .finally(() => setLoadingBankrup(null));
    },
    [setLoadingBankrup]
  );
  useEffect(() => {
    if (timeoutSearch) {
      clearTimeout(timeoutSearch);
    }
    setPatient(null);
    setPatientAppointment(null);
    setTimeoutSearch(
      setTimeout(() => {
        getPatients(true);
      }, 500)
    );
    return () => {
      if (timeoutSearch) {
        clearTimeout(timeoutSearch);
      }
    };
  }, [search, date, isMFO]);
  const getPatients = (reset?: boolean) => {
    fetchManagePatients(search, date.start, date.end, isMFO, reset ? 0 : reset ? 0 : page + 1, pageSize, reset);
  };
  useEffect(() => {
    if (timeoutPatient) {
      clearTimeout(timeoutPatient);
    }
    patientAppointment &&
      setTimeoutPatient(
        setTimeout(() => {
          getPatientAppointments(true);
        }, 1)
      );
    return () => {
      if (timeoutPatient) {
        clearTimeout(timeoutPatient);
      }
    };
  }, [patientAppointment]);
  const getPatientAppointments = (reset?: boolean) => {
    fetchManagePatientAppointments(
      patientAppointment.id,
      // eslint-disable-next-line no-nested-ternary
      reset === true ? 0 : reset ? 0 : pageAppointments + 1,
      pageSizeAppointments,
      reset
    );
  };

  const showOutpatientCardCover = (id: string) => {
    getOutpatientCardCover(id).then(
      (value) => {
        const newWindow = window?.open(value.data, '_blank', 'noopener,noreferrer');
        if (newWindow) {
          newWindow.opener = null;
        }
      },
      (error) => displayErrors(handleHttpError(error))
    );
  };

  const onDepersonalizationPatient = (id: string) => {
    return depersonalizationPatient(id);
  };

  const getLabelStatus = (status: DoctorsEventStatus): string => {
    switch (status) {
      case DoctorsEventStatus.AWAITS:
        return 'Ожидает ответа';
      case DoctorsEventStatus.CANCELED:
        return 'Отменена';
      case DoctorsEventStatus.DONE:
        return 'Завершена';
      case DoctorsEventStatus.IN_PROGRESS:
        return 'Сейчас идет';
      case DoctorsEventStatus.FINISHED:
        return 'Ожидает заключение';
      case DoctorsEventStatus.FAILED:
        return 'Техническая проблема';
      default:
        return null;
    }
  };
  return (
    <>
      <ContentCard>
        <ContentCard.Header>
          <div className="row">
            <div className="col-12 text-center text-xs-right text-md-center">
              <h6 className="page-header-title">Пациенты</h6>
            </div>
          </div>
        </ContentCard.Header>
        <Box className={styles.PatientsPageAdmin_Toolbar}>
          <Box className={styles.PatientsPageAdmin_Toolbar_Search}>
            <SearchIcon className={styles.PatientsPageAdmin_Toolbar_Search_Icon} />
            <InputField
              value={search}
              onChange={(e) => {
                onChangeSearch(e.target.value);
              }}
              block
              placeholder="Введите ФИО пациента"
            />
            {search && (
              <ClearIcon
                onClick={() => {
                  onChangeSearch('');
                }}
                className={classNames(
                  styles.PatientsPageAdmin_Toolbar_Search_Icon,
                  styles.PatientsPageAdmin_Toolbar_Search_Icon_Clear
                )}
              />
            )}
          </Box>
          <Box className={styles.PatientsPageAdmin_Toolbar_Buttons}>
            <Button
              color={isMFO ? 'primary' : 'default'}
              size="md"
              onClick={() => {
                const mfoFilter = isMFO ? null : true;
                onChangeMFO(mfoFilter);
              }}
            >
              МФО
            </Button>
            <DropdownMenu isOpen={toolbarOpen} toggle={() => toggleToolbarOpen(!toolbarOpen)}>
              <DropdownToggle>
                <Button color="primary" size="md">
                  <EventIcon />
                  {(date.start || date.end) && <Box className={styles.PatientsPageAdmin_Toolbar_Buttons_Status} />}
                </Button>
              </DropdownToggle>
              <DropdownContent className={styles.PatientsPageAdmin_Toolbar_DropdownContent}>
                <CalendarRange date={date} onChangeDate={(date: { start: Date; end: Date }) => onChangeDate(date)} />
                {(date.start || date.end) && (
                  <Box className={styles.PatientsPageAdmin_Toolbar_DropdownContent_Reset}>
                    <Button
                      color="default"
                      block
                      size="md"
                      onClick={(event) => {
                        event.preventDefault();
                        onChangeDate({ start: null, end: null });
                      }}
                    >
                      Сбросить фильтр
                    </Button>
                  </Box>
                )}
              </DropdownContent>
            </DropdownMenu>
          </Box>
        </Box>
        <ContentCard.Main>
          <>
            <Box
              p={0}
              width="100%"
              height="100%"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <InfiniteScroll
                className={styles.PatientsPageAdmin_InfiniteScroll}
                pageStart={0}
                hasMore={loading ? false : hasMore}
                initialLoad={false}
                threshold={pageSize}
                loadMore={() => getPatients()}
                loader={null}
              >
                <>
                  {patients.map((item: any, index: number) => (
                    <Box key={index} className={styles.PatientsPageAdmin_Row}>
                      <Box className={styles.PatientsPageAdmin_Row_Main}>
                        <Box className={styles.PatientsPageAdmin_Row_Cell}>
                          <Avatar
                            className={styles.PatientsPageAdmin_ProfileImg}
                            src={decodeAvatarResource(item.avatar, 360, 'MALE')}
                          />
                          <Box className={styles.PatientsPageAdmin_Profile}>
                            <Box
                              className={classNames(
                                styles.PatientsPageAdmin_Profile_Item,
                                styles.PatientsPageAdmin_Profile_Name
                              )}
                            >
                              {[item.lastName, item.firstName, item.middleName].filter((s) => s).join(' ')}
                            </Box>
                            {item.phone && (
                              <Box className={classNames(styles.PatientsPageAdmin_Profile_Item)}>{item.phone}</Box>
                            )}
                            {item.email && (
                              <Box className={classNames(styles.PatientsPageAdmin_Profile_Item)}>{item.email}</Box>
                            )}

                            <Box mt={2} className={classNames(styles.PatientsPageAdmin_Profile_Item)}>
                              Клиент МФО: {item?.external === true ? 'да' : 'нет'}
                            </Box>
                            <Box mt={1} mb={2} className={classNames(styles.PatientsPageAdmin_Profile_Item)}>
                              Банкрот: {item?.status === 'BANKRUPT' ? 'да' : 'нет'}
                            </Box>
                          </Box>
                        </Box>
                        <Box className={styles.PatientsPageAdmin_Row_Cell}>
                          <Box className={styles.PatientsPageAdmin_Buttons}>
                            <Button
                              className={styles.PatientsPageAdmin_Button}
                              onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                if (patient && patient.id === item.id) {
                                  setPatient(null);
                                } else {
                                  setPatient(item);
                                }
                              }}
                              size="sm"
                              color="default"
                            >
                              Подробная информация
                            </Button>
                            <Button
                              className={styles.PatientsPageAdmin_Button}
                              onClick={() => {
                                setManagePatientAppointmentsLoading(true, true);
                                setPatientAppointment(
                                  patientAppointment && patientAppointment.id === item.id ? null : item
                                );
                              }}
                              size="sm"
                              color="default"
                            >
                              Список консультаций
                            </Button>
                            <Button
                              className={styles.PatientsPageAdmin_Button}
                              onClick={() => showOutpatientCardCover(item.id)}
                              size="sm"
                              color="default"
                            >
                              Амбулаторная карта
                            </Button>
                            <Button
                              className={styles.PatientsPageAdmin_Button_Alert}
                              onClick={() => handleSetBankrupt(item?.id)}
                              size="sm"
                              isLoading={loadingBankrup === item?.id}
                              color="alert"
                            >
                              Банкрот
                            </Button>
                            <Button
                              className={styles.PatientsPageAdmin_Button_Alert}
                              onClick={() => setDepersona(item)}
                              size="sm"
                              color="alert"
                            >
                              Деперсонализировать
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                      {patientAppointment != null && patientAppointment.id === item.id && (
                        <Box className={styles.PatientsPageAdmin_Row_Appointments}>
                          <Box
                            pt={2}
                            pb={2}
                            width="100%"
                            display="flex"
                            flexDirection="row"
                            justifyContent="flex-between"
                            alignItems="center"
                            className={classNames(
                              styles.PatientsPageAdmin_Row_Appointments_Row,
                              styles.PatientsPageAdmin_Row_Appointments_Row_Header
                            )}
                          >
                            <Box
                              className={classNames(
                                styles.PatientsPageAdmin_Row_Appointments_Row_Cell,
                                styles.PatientsPageAdmin_Row_Appointments_Row_Cell_Header
                              )}
                            >
                              Специалист
                            </Box>
                            <Box
                              className={classNames(
                                styles.PatientsPageAdmin_Row_Appointments_Row_Cell,
                                styles.PatientsPageAdmin_Row_Appointments_Row_Cell_Header
                              )}
                            >
                              Тип
                            </Box>
                            <Box
                              className={classNames(
                                styles.PatientsPageAdmin_Row_Appointments_Row_Cell,
                                styles.PatientsPageAdmin_Row_Appointments_Row_Cell_Header
                              )}
                            >
                              Дата
                            </Box>
                            <Box
                              className={classNames(
                                styles.PatientsPageAdmin_Row_Appointments_Row_Cell,
                                styles.PatientsPageAdmin_Row_Appointments_Row_Cell_Header
                              )}
                            >
                              Стоимость
                            </Box>
                            <Box
                              className={classNames(
                                styles.PatientsPageAdmin_Row_Appointments_Row_Cell,
                                styles.PatientsPageAdmin_Row_Appointments_Row_Cell_Header
                              )}
                            >
                              Тип оплаты
                            </Box>
                            <Box
                              className={classNames(
                                styles.PatientsPageAdmin_Row_Appointments_Row_Cell,
                                styles.PatientsPageAdmin_Row_Appointments_Row_Cell_Header
                              )}
                            >
                              Статус
                            </Box>
                          </Box>
                          <Box className={styles.PatientsPageAdmin_Row_Appointments_Wrapper}>
                            {patientAppointments.map((appointment: Manage.Patient.Appointments.Item) => (
                              <Box
                                key={appointment.id}
                                onClick={() => setAppointment(appointment)}
                                className={styles.PatientsPageAdmin_Row_Appointments_Row}
                              >
                                <Box className={styles.PatientsPageAdmin_Row_Appointments_Row_Cell}>
                                  <Box className={styles.PatientsPageAdmin_Row_Appointments_Profile}>
                                    <Avatar
                                      className={styles.PatientsPageAdmin_Row_Appointments_ProfileImg}
                                      src={decodeAvatarResource(
                                        appointment.doctor && appointment.doctor.avatar,
                                        360,
                                        'MALE'
                                      )}
                                    />
                                    <Box>
                                      {appointment.doctor &&
                                        getDisplayName(appointment.doctor.firstName, appointment.doctor.lastName)}
                                      {!appointment.doctor && appointment.serviceType.name}
                                    </Box>
                                  </Box>
                                </Box>
                                <Box className={styles.PatientsPageAdmin_Row_Appointments_Row_Cell}>
                                  {`${appointment?.type?.name || ''}${
                                    appointment.numberOfDay ? ` День ${appointment.numberOfDay}` : ''
                                  }`}
                                </Box>
                                <Box className={styles.PatientsPageAdmin_Row_Appointments_Row_Cell}>
                                  <Box>{format(parseISO(appointment.startTime.toString()), 'dd.MM.yyyy | HH:mm')}</Box>
                                </Box>
                                <Box className={styles.PatientsPageAdmin_Row_Appointments_Row_Cell}>
                                  {appointment.price} <Box>&#8381;</Box>
                                </Box>
                                <Box className={styles.PatientsPageAdmin_Row_Appointments_Row_Cell}>
                                  {appointment.payedWithPromo ? 'Промокод' : 'Внесенные средства'}
                                </Box>
                                <Box
                                  className={classNames(
                                    styles.PatientsPageAdmin_Row_Appointments_Row_Cell,
                                    styles[
                                      `PatientsPageAdmin_Row_Appointments_Row_Cell_Status_${
                                        appointment.status.value as DoctorsEventStatus
                                      }`
                                    ]
                                  )}
                                >
                                  <Box>{getLabelStatus(appointment.status.value as DoctorsEventStatus)}</Box>
                                </Box>
                              </Box>
                            ))}
                            <Box width="100%" display="flex" justifyContent="center" alignItems="center" p={2}>
                              <ContentLoading
                                isLoading={loadingAppointments}
                                isError={errorsAppointments}
                                fetchData={() => getPatientAppointments()}
                              >
                                {hasMoreAppointments && (
                                  <Button
                                    size="md"
                                    color="default"
                                    onClick={(event) => {
                                      event.preventDefault();
                                      event.stopPropagation();
                                      getPatientAppointments();
                                    }}
                                  >
                                    Показать еще
                                  </Button>
                                )}
                              </ContentLoading>
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  ))}
                  {(loading || errors) && (
                    <Box width="100%" p={2}>
                      <ContentLoading isLoading={loading} isError={errors} fetchData={() => getPatients()} />
                    </Box>
                  )}
                </>
              </InfiniteScroll>
            </Box>
          </>
        </ContentCard.Main>
        {patient && <AdminPatientView patientId={patient.id} onClose={() => setPatient(null)} />}
        {appointment && <AdminPatientAppointmentView appointment={appointment} onClose={() => setAppointment(null)} />}
        {depersona && (
          <DeleteDialog
            item={depersona.id}
            action={onDepersonalizationPatient}
            onDeleted={() => setDepersona(null)}
            onClose={() => setDepersona(null)}
            positiveButtonLabel="Деперсонализировать"
            title="Деперсонализация пользователя"
            message={`Вы уверены, что хотите деперсонализировать пользователя ${[
              depersona.lastName,
              depersona.firstName,
              depersona.middleName,
            ]
              .filter((s) => s)
              .join(' ')}`}
          />
        )}
      </ContentCard>
    </>
  );
};
export default connect(
  (state: any) => ({
    ...state.managePatients,
  }),
  {
    fetchManagePatients,
    fetchManagePatientAppointments,
    setManagePatientAppointmentsLoading,
  }
)(PatientsPageAdmin);
