import getResourcesUrl from 'api/getResourceUrl';

export const getUrlForUploading = async (file: File, name?: string) => {
  let data;

  try {
    data = await getResourcesUrl(file, name);
  } catch (error) {
    throw {
      ...error,
      file,
    };
  }

  return data;
};

export const loadFile = (file: File, url: string) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.onload = () => {
      if (xhr.status !== 200) {
        return reject();
      }

      return resolve(null);
    };
    xhr.onerror = () => {
      return reject({
        file,
      });
    };
    xhr.open('PUT', url);
    xhr.send(file);
  });
};

export const validateFile = (file: File, accept: string | string[]) => {
  const acceptRegExp = [];

  if (Array.isArray(accept)) {
    acceptRegExp.push(...accept);
  } else {
    acceptRegExp.push(...accept.split(/,\s?/).map((item) => new RegExp(`${item}$`, 'g')));
  }

  return acceptRegExp.some((item) => item.test(file.name));
};
