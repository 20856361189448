import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import CheckerWrapper from './components/CheckerWrapper';
import styles from './CheckerPage.module.sass';
import CheckerSidebar from './components/CheckerSidebar';
import { onResetChecker } from './store/events';

const CheckerPage = () => {
  useEffect(() => () => onResetChecker(), []);
  return (
    <>
      <Box className={styles.root}>
        <CheckerSidebar />
        <CheckerWrapper />
      </Box>
    </>
  );
};

export default CheckerPage;
