import { AxiosResponse } from 'axios';
import { findPatientServicePackageTransactions } from '../../api/transactions/findPatientServicePackageTransactions';
import { updatePatientServicePackageTransactionStatus as apiUpdateTransactionStatus } from '../../api/transactions/updatePatientServicePackageTransactionStatus';
import { handleHttpError } from '../../utils/handleHttpError';
import { PatientServicePackageTransactionStatus } from '../../types';
import { getPatientServicePackageTransactionStatus } from '../../api/servicePackage/getPatientServicePackageTransactionStatus';

export const MANAGE_TRANSACTIONS_LOADING = 'MANAGE_TRANSACTIONS_LOADING';
export const MANAGE_TRANSACTIONS_SUCCESS = 'MANAGE_TRANSACTIONS_SUCCESS';
export const MANAGE_TRANSACTIONS_ERRORS = 'MANAGE_TRANSACTIONS_ERRORS';

export const UPDATE_PSP_TRANSACTION_STATUS_LOADING = 'UPDATE_PSP_TRANSACTION_STATUS_LOADING';
export const UPDATE_PSP_TRANSACTION_STATUS_SUCCESS = 'UPDATE_PSP_TRANSACTION_STATUS_SUCCESS';
export const UPDATE_PSP_TRANSACTION_STATUS_ERRORS = 'UPDATE_PSP_TRANSACTION_STATUS_ERRORS';

export const MANAGE_TRANSACTION_STATUSES_LOADING = 'MANAGE_TRANSACTION_STATUSES_LOADING';
export const MANAGE_TRANSACTION_STATUSES_SUCCESS = 'MANAGE_TRANSACTION_STATUSES_SUCCESS';
export const MANAGE_TRANSACTION_STATUSES_ERRORS = 'MANAGE_TRANSACTION_STATUSES_ERRORS';

export const setManageTransactionStatusesLoading = (loading: boolean) => ({
  type: MANAGE_TRANSACTION_STATUSES_LOADING,
  loading,
});

export const setManageTransactionStatusesErrors = (errors: any) => ({
  type: MANAGE_TRANSACTION_STATUSES_ERRORS,
  errors,
});

export const setManageTransactionStatuses = (statuses: any) => ({
  type: MANAGE_TRANSACTION_STATUSES_SUCCESS,
  statuses,
});

export const setManageTransactionsLoading = (loading: boolean, reset?: boolean) => ({
  type: MANAGE_TRANSACTIONS_LOADING,
  loading,
  reset,
});

export const setManageTransactionsErrors = (errors: any) => ({ type: MANAGE_TRANSACTIONS_ERRORS, errors });

export const setManageTransactions = (transactions: any, page: number, pageSize: number, totalItems: number) => ({
  type: MANAGE_TRANSACTIONS_SUCCESS,
  transactions,
  page,
  pageSize,
  totalItems,
});

export const fetchManageTransactionStatuses = (): any => {
  return (dispatch) => {
    dispatch(setManageTransactionStatusesLoading(true));
    getPatientServicePackageTransactionStatus()
      .then((response: AxiosResponse<any>) => {
        dispatch(setManageTransactionStatusesLoading(false));
        if (response.data.code === 'success') {
          dispatch(setManageTransactionStatuses(response.data.data.items));
        } else {
          dispatch(setManageTransactionStatusesErrors(response.data));
        }
      })
      .catch((error: any) => {
        dispatch(setManageTransactionStatusesErrors(error));
        dispatch(setManageTransactionStatusesLoading(false));
      });
  };
};

export const fetchManageTransactions = (
  patientServicePackageId?: number,
  patientId?: number,
  page?: number,
  pageSize?: number,
  reset?: boolean
): any => {
  return (dispatch) => {
    dispatch(setManageTransactionsLoading(true, reset));
    findPatientServicePackageTransactions(patientServicePackageId, patientId, page, pageSize)
      .then((response: AxiosResponse<any>) => {
        dispatch(setManageTransactionsLoading(false));
        if (response.data.code === 'success') {
          dispatch(
            setManageTransactions(
              response.data.data.items,
              response.data.data.page,
              response.data.data.pageSize,
              response.data.data.totalItems
            )
          );
        } else {
          dispatch(setManageTransactionsErrors(response.data));
        }
      })
      .catch((error: any) => {
        dispatch(setManageTransactionsErrors(error));
        dispatch(setManageTransactionsLoading(false));
      });
  };
};

export const setUpdatePSPTransactionStatusLoading = (id: string, loading: boolean) => ({
  type: UPDATE_PSP_TRANSACTION_STATUS_LOADING,
  id,
  loading,
});

export const setUpdatePSPTransactionStatusErrors = (errors: any) => ({
  type: UPDATE_PSP_TRANSACTION_STATUS_ERRORS,
  errors,
});

export const setUpdatePSPTransactionStatusSuccess = (id: string, status: PatientServicePackageTransactionStatus) => ({
  type: UPDATE_PSP_TRANSACTION_STATUS_SUCCESS,
  id,
  status,
});

export const updatePatientServicePackageTransactionStatus = (
  id: string,
  status: PatientServicePackageTransactionStatus
) => {
  return (dispatch) => {
    dispatch(setUpdatePSPTransactionStatusLoading(id, true));
    apiUpdateTransactionStatus(id, status).then(
      (response) => {
        dispatch(setUpdatePSPTransactionStatusLoading(id, false));
        dispatch(setUpdatePSPTransactionStatusSuccess(id, status));
      },
      (e) => {
        const error = handleHttpError(e);
        console.log('error', error);
        dispatch(setUpdatePSPTransactionStatusLoading(id, false));
        dispatch(setUpdatePSPTransactionStatusErrors(error));
      }
    );
  };
};
