import React from 'react';
import { connect } from 'react-redux';
import { UserRole } from '../../types';

interface HasRoleInterface {
  children?: any;
  target?: UserRole;
  role?: UserRole;
}
const HasRole = ({ children, target = null, role = null }: HasRoleInterface) => {
  if ((target && role === target) || !target) {
    return <>{children}</>;
  }
  return <></>;
};

export default connect((state: any) => ({
  role: state.auth.role,
}))(HasRole);
