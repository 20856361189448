import React, { useEffect, useMemo, useState } from 'react';
import { Backdrop, Box, Dialog, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DescriptionIcon from '@material-ui/icons/Description';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import styles from './AdminMfoTariffDocEdit.module.sass';
import ContentCard from '../ContentCard';
import InputField from '../Forms/InputField';
import { getFieldErrors as getFieldErrorsUtil, validateFieldOnChange } from '../../utils';
import FormValidator from '../Forms/FormValidator';
import ContentLoading from '../ContentLoading';
import Button from '../Forms/Button';
import { getDocAttachmentsTypeValues } from '../../api/servicePackage/getDocAttachmentsTypeValues';
import { createServicePackageAttachment } from '../../api/servicePackage/createServicePackageAttachment';
import { updateServicePackageAttachment } from '../../api/servicePackage/updateServicePackageAttachment';
import { createResource } from '../../api/createResource';
import SelectInput2 from '../Forms/SelectInput2';

interface AdminMfoTariffDocEditInterface {
  onSave: () => void;
  onClose: () => void;
  data?: any;
  tariffDocId: any;
  disabled?: boolean;
  isGroup?: boolean;
}

const AdminMfoTariffDocEdit = (props: AdminMfoTariffDocEditInterface) => {
  const { onClose = () => {}, onSave = () => {}, data, tariffDocId, disabled = false, isGroup = false } = props;
  const [loading, setLoading] = useState<boolean>(true);
  const [errors, setErrors] = useState<any>(null);
  const [formErrors, setFormErrors] = useState<any>([]);
  const [loadingStore, setLoadingStore] = useState<boolean>(false);
  const [docAttachmentsType, setDocAttachmentsType] = useState([]);
  const [fileDocs, addFileDocs] = useState<File>(null);
  const [inputFileDocs, setInputFileDocs] = useState<HTMLInputElement>(null);
  const [model, setModel] = useState<any>({
    type: isGroup ? 'HTML' : data?.type || 'FILE',
    docTypeId: data?.docType?.id || null,
    name: data?.name || null,
    resourceId: isGroup ? null : data?.resource?.id || null,
    html: data?.html || null,
  });
  const isImage = useMemo(
    () => data?.resource?.id && data?.type === 'FILE' && (data?.resource?.contentType || '').search('image') > -1,
    [data]
  );
  useEffect(() => init(), []);
  const validateOnChange = (name: string, value: any, event, element?: any) => {
    validateFieldOnChange(name, value, event, model, setModel, formErrors, setFormErrors, element);
  };
  const getFieldErrors = (field: string) => getFieldErrorsUtil(field, formErrors);
  const submit = (e) => {
    if (disabled) {
      return;
    }
    e.preventDefault();
    const form = e.target;
    const inputs = [...form.elements].filter((i) => ['INPUT', 'SELECT', 'TEXTAREA'].includes(i.nodeName));

    const { errors, hasError } = FormValidator.bulkValidate(inputs);

    setFormErrors([...errors]);

    if (!hasError) {
      store();
    }
  };
  const onChangeDocs = (event: any): void => {
    const fs: FileList = event.target.files as FileList;
    const f = fs.item(0);
    addFileDocs(f);
    inputFileDocs.value = '';
  };
  const init = (): void => {
    setLoading(true);
    getDocAttachmentsTypeValues()
      .then((response: AxiosResponse<any>) => {
        setLoading(false);
        if (response.data.code === 'success') {
          setDocAttachmentsType((response.data.data.items || []).map((i) => ({ label: i?.name, value: i?.id })));
        } else {
          setErrors(response.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        setErrors(err);
      });
  };
  const isNew = !data.id;
  const store = (): void => {
    setLoadingStore(true);
    new Promise((resolve) => {
      if (fileDocs) {
        createResource(fileDocs).then((res) => resolve(res));
      } else {
        resolve(null);
      }
    })
      .then((resourceId) =>
        isNew
          ? createServicePackageAttachment(tariffDocId, { ...model, resourceId }, isGroup)
          : updateServicePackageAttachment({ ...model, id: data.id }, isGroup)
      )
      .then((response: AxiosResponse<any>) => {
        if (response.data.code === 'success') {
          onSave();
        } else {
          throw new Error();
        }
        setLoadingStore(false);
      })
      .catch(() => {
        toast.error('Ошибка при запросе');
        setLoadingStore(false);
      });
  };

  return (
    <Dialog
      PaperProps={{ className: classNames(styles.Dialog) }}
      BackdropComponent={(props) => <Backdrop className={styles.Backdrop} {...props} />}
      onClose={onClose}
      onClick={(e) => e.stopPropagation()}
      open
    >
      <ContentCard className={styles.AdminMfoTariffDocEdit}>
        <ContentCard.Header className={styles.AdminMfoTariffDocEdit_Header}>
          <h6 className="page-header-title">{isNew ? 'Добавить' : 'Редактировать'} документ</h6>
          <CloseIcon onClick={onClose} className={styles.AdminMfoTariffDocEdit_CloseIcon} />
        </ContentCard.Header>
        <ContentCard.Main className={styles.AdminMfoTariffDocEdit_Content}>
          <ContentLoading isLoading={loading} isError={errors} fetchData={() => init()}>
            <form onSubmit={submit}>
              <Box className={classNames(styles.AdminMfoTariffDocEdit_Content_Wrapper)}>
                <Box className={classNames(styles.AdminMfoTariffDocEdit_Content_Item)}>
                  <Grid
                    className={classNames(styles.AdminMfoTariffDocEdit_Content_Item_Container)}
                    container
                    justify="center"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item xs={12}>
                      <InputField
                        name="name"
                        maxLength={255}
                        minLength={1}
                        value={model.name}
                        disabled={disabled}
                        onChange={(e) => validateOnChange('name', e.target.value, e)}
                        data-validate='["required"]'
                        errors={getFieldErrors('name')}
                        block
                        label="Название"
                        placeholder="Название"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <SelectInput2
                        name="docTypeId"
                        disabled={loadingStore || disabled}
                        value={model.docTypeId || ''}
                        onChange={(event, e) => {
                          validateOnChange('docTypeId', event.target.value, null, e);
                        }}
                        errors={getFieldErrors('docTypeId')}
                        dataValidate={'["required"]'}
                        options={docAttachmentsType}
                        label="Тип документа"
                        placeholder="Тип документа"
                      />
                    </Grid>
                    {!isGroup && (
                      <Grid item xs={12} container justify="space-between" alignItems="center">
                        <Box>Прикрепить файл?</Box>
                        <label className="toggle-checkbox-label">
                          <input
                            disabled={disabled}
                            type="checkbox"
                            checked={model.type === 'FILE'}
                            onChange={(event) => {
                              setModel({
                                ...model,
                                type: event.target.checked ? 'FILE' : 'HTML',
                                html: model.type === 'HTML' ? model.html : null,
                                resourceId: model.type === 'FILE' ? model.resourceId : null,
                                docTypeId: model.docTypeId,
                              });
                            }}
                          />

                          <span className="checkmark">
                            <span className="check" />
                          </span>
                        </label>
                      </Grid>
                    )}
                    {model.type === 'FILE' && !model?.resourceId && (
                      <Grid item xs={12} container justify="center" alignItems="center">
                        <Button type="button" color="default" block style={{ position: 'relative' }}>
                          <AttachFileIcon className={classNames(styles.AttachFileIcon)} />{' '}
                          <Box textOverflow="ellipsis" width="100%" padding="0 30px" overflow="hidden">
                            {fileDocs ? fileDocs.name : 'Прикрепить файл'}
                          </Box>
                          <input
                            disabled={disabled}
                            ref={setInputFileDocs}
                            style={{ position: 'absolute', width: '100%', height: '100%', opacity: 0 }}
                            type="file"
                            onChange={onChangeDocs}
                          />
                        </Button>
                      </Grid>
                    )}
                    {model.type === 'FILE' && model?.resourceId && (
                      <>
                        <Grid
                          item
                          xs={12}
                          container
                          justify="center"
                          alignItems="center"
                          style={{ position: 'relative' }}
                        >
                          <Box
                            width="90%"
                            height="300px"
                            style={
                              isImage
                                ? {
                                    backgroundSize: 'cover',
                                    backgroundImage: `url(${data?.resource?.originalUrl})`,
                                  }
                                : {}
                            }
                          >
                            {!isImage && <DescriptionIcon className={classNames(styles.DescriptionIcon)} />}
                          </Box>
                          {!disabled && (
                            <Box
                              onClick={() => {
                                setModel({ ...model, resourceId: null });
                              }}
                              className={classNames(styles.Remove)}
                            >
                              <DeleteOutlineIcon />
                            </Box>
                          )}
                        </Grid>
                      </>
                    )}
                    {model.type === 'HTML' && (
                      <Grid item xs={12}>
                        <InputField
                          name="html"
                          disabled={disabled}
                          type="textarea"
                          value={model.html}
                          onChange={(e) => validateOnChange('html', e.target.value, e)}
                          data-validate='["required"]'
                          errors={getFieldErrors('html')}
                          block
                          label="Содержимое"
                          placeholder="Содержимое"
                        />
                      </Grid>
                    )}
                  </Grid>
                  {!disabled && (
                    <Grid item xs={12} container justify="center" alignItems="center">
                      <Button type="submit" color="primary" isLoading={loadingStore} block>
                        Сохранить
                      </Button>
                    </Grid>
                  )}
                </Box>
              </Box>
            </form>
          </ContentLoading>
        </ContentCard.Main>
      </ContentCard>
    </Dialog>
  );
};
export default AdminMfoTariffDocEdit;
