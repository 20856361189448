import { AxiosResponse } from 'axios';
import Manage from '../../types/manage';
import { findPatientServicePackages } from '../../api/findPatientServicePackages';

export const MANAGE_SERVICE_PACKAGES_PATIENTS_LOADING = 'MANAGE_SERVICE_PACKAGES_PATIENTS_LOADING';
export const MANAGE_SERVICE_PACKAGES_PATIENTS_SUCCESS = 'MANAGE_SERVICE_PACKAGES_PATIENTS_SUCCESS';
export const MANAGE_SERVICE_PACKAGES_PATIENTS_ERRORS = 'MANAGE_SERVICE_PACKAGES_PATIENTS_ERRORS';
export const MANAGE_SERVICE_PACKAGES_PATIENTS_UPDATE = 'MANAGE_SERVICE_PACKAGES_PATIENTS_UPDATE';
export const MANAGE_SERVICE_PACKAGES_PATIENTS_UPDATE_DEBT = 'MANAGE_SERVICE_PACKAGES_PATIENTS_UPDATE_DEBT';
export const MANAGE_SERVICE_PACKAGES_PATIENTS_PROMOTION_UPDATE = 'MANAGE_SERVICE_PACKAGES_PATIENTS_PROMOTION_UPDATE';
export const MANAGE_SERVICE_PACKAGES_PATIENTS_ACCRUAL_STATUS_UPDATE =
  'MANAGE_SERVICE_PACKAGES_PATIENTS_ACCRUAL_STATUS_UPDATE';

export const updatePromotionStatusPackagesMfo = (id: string, promotionId: string, status: any) => ({
  type: MANAGE_SERVICE_PACKAGES_PATIENTS_PROMOTION_UPDATE,
  payload: { id, promotionId, status },
});

export const updateAccrualStatusPackagesMfo = (id: string, status: any) => ({
  type: MANAGE_SERVICE_PACKAGES_PATIENTS_ACCRUAL_STATUS_UPDATE,
  payload: { id, status },
});

export const updateManageServicePackagesPatientsDept = (servicePackageId: string, debt?: any) => ({
  type: MANAGE_SERVICE_PACKAGES_PATIENTS_UPDATE_DEBT,
  servicePackageId,
  debt,
});

export const setManageServicePackagesPatientsLoading = (loading: boolean, reset?: boolean) => ({
  type: MANAGE_SERVICE_PACKAGES_PATIENTS_LOADING,
  loading,
  reset,
});

export const updateManageServicePackagesPatients = (servicePackageId: any, status?: any) => ({
  type: MANAGE_SERVICE_PACKAGES_PATIENTS_UPDATE,
  servicePackageId,
  status,
});

export const setManageServicePackagesPatientsErrors = (errors: any) => ({
  type: MANAGE_SERVICE_PACKAGES_PATIENTS_ERRORS,
  errors,
});

export const setManageServicePackagesPatients = (
  items: Manage.ServicePackages.Patient.Item[],
  page: number,
  pageSize: number,
  totalItems: number
) => ({
  type: MANAGE_SERVICE_PACKAGES_PATIENTS_SUCCESS,
  items,
  page,
  pageSize,
  totalItems,
});

export const fetchManageServicePackagesPatients = (
  query?: string,
  status?: string,
  categoryBenefitsId?: string,
  createdFrom?: Date,
  createdTo?: Date,
  signOnline?: any,
  groupId?: string,
  page?: number,
  pageSize?: number,
  reset?: boolean
): any => {
  return (dispatch) => {
    dispatch(setManageServicePackagesPatientsLoading(true, reset));
    findPatientServicePackages(
      query,
      status,
      categoryBenefitsId,
      createdFrom,
      createdTo,
      signOnline,
      groupId,
      page,
      pageSize
    )
      .then((response: AxiosResponse<Manage.ServicePackages.Patient.Response>) => {
        dispatch(setManageServicePackagesPatientsLoading(false));
        if (response.data.code === 'success') {
          dispatch(
            setManageServicePackagesPatients(
              response.data.data.items,
              response.data.data.page,
              response.data.data.pageSize,
              response.data.data.totalItems
            )
          );
        } else {
          dispatch(setManageServicePackagesPatientsErrors(response.data));
        }
      })
      .catch((error: any) => {
        dispatch(setManageServicePackagesPatientsErrors(error));
        dispatch(setManageServicePackagesPatientsLoading(false));
      });
  };
};
