import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import classNames from 'classnames';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import { parseISO } from 'date-fns';
import TuneIcon from '@material-ui/icons/Tune';
import EventIcon from '@material-ui/icons/Event';
import { toast } from 'react-toastify';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import styles from './ReportsPageAdminServicePackagesItems.module.sass';
import { fetchManageReportServicePackagesPatients, fetchManageServicePackagesMfo } from '../../../../store/actions';
import Manage from '../../../../types/manage';
import ContentLoading from '../../../../components/ContentLoading';
import Button from '../../../../components/Forms/Button';
import { format } from '../../../../utils/date';
import DropdownMenu from '../../../../components/DropdownMenu';
import DropdownToggle from '../../../../components/DropdownMenu/DropdownToggle';
import DropdownContent from '../../../../components/DropdownMenu/DropdownContent';
import { ReactComponent as CheckIcon } from '../../../../assets/img/icons/check-icon.svg';
import CalendarRange from '../../../../components/CalendarRange';
import SelectInput from '../../../../components/Forms/SelectInput';
import { ManageReportServicePackagesReducerInitialSettings } from '../../../../store/reducers/manageReportServicePackagesReducer.reducer';
import { getPatientServicePackageReport } from '../../../../api/getPatientServicePackageReport';
import InputField from '../../../../components/Forms/InputField';
import ServicePackageGroupSelector from '../../ServicePackagesAdmin/ServicePackagesAdminGroups/GroupSelector/ServicePackageGroupSelector';
import CategoryBenefitsSelect from '../../../../components/CategoryBenefitsSelect';
import ReportItemUtils from '../utils/ReportItemUtils';

interface PropsInterface {
  loading: boolean;
  errors: any;
  page: number;
  pageSize: number;
  hasMore: boolean;
  items: Manage.ServicePackages.Patient.Item[];
  fetchManageServicePackagesMfo: () => void;
  fetchManageReportServicePackagesPatients: (
    query?: string,
    groupId?: string,
    statuses?: string[],
    purchasedFrom?: Date,
    purchasedTo?: Date,
    categoryBenefits?: string,
    signOnline?: any,
    page?: number,
    pageSize?: number,
    reset?: boolean
  ) => void;
  type: any;
  types: any;
  onChangeType: (type: any) => void;
}
const ReportsPageAdminServicePackagesItems = (props: PropsInterface) => {
  const {
    loading,
    items,
    fetchManageReportServicePackagesPatients,
    fetchManageServicePackagesMfo,
    errors,
    page,
    pageSize,
    hasMore,
    type,
    types,
    onChangeType,
  } = props;
  const [timeoutSearch, setTimeoutSearch] = useState<NodeJS.Timeout>(null);
  const [group, setGroup] = useState<string>(null);
  const [query, setQuery] = useState<string>(null);
  const _statuses = ['ACTIVATED', 'CLOSED', 'NEW', 'PURCHASED', 'SENT', 'CANCELED'];
  const [loadingReport, setLoadingReport] = useState(false);
  const [date, setDate] = useState<{ start: Date; end: Date }>({ start: null, end: null });
  const [statuses, setStatuses] = useState<string[]>([]);
  const [toolbarOpenStatus, toggleToolbarOpenStatus] = useState<boolean>(false);
  const [toolbarDateOpen, toggleToolbarDateOpen] = useState<boolean>(false);
  const [categoryBenefits, setCategoryBenefits] = useState<string>(null);
  const [signOnline, setSignOnline] = useState<boolean>(null);
  const [signOnlineOptions] = useState([
    { label: 'Все', value: null },
    { label: 'Онлайн', value: true },
    { label: 'Офлайн', value: false },
  ]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => fetchManageServicePackagesMfo(), []);
  useEffect(() => {
    if (timeoutSearch) {
      clearTimeout(timeoutSearch);
    }
    if (query) {
      setTimeoutSearch(
        setTimeout(() => {
          getItems(true);
        }, 500)
      );
    } else {
      getItems(true);
    }
    return () => {
      if (timeoutSearch) {
        clearTimeout(timeoutSearch);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, date, statuses, group, categoryBenefits, signOnline]);
  const onGetAdminAppointmentReport = () => {
    setLoadingReport(true);
    getPatientServicePackageReport(
      query,
      group,
      statuses,
      date.start,
      date.end,
      categoryBenefits,
      signOnline,
      page,
      pageSize
    )
      .then((data) => {
        setLoadingReport(false);
        const fileURL = URL.createObjectURL(data.data);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = `ServicePackagesReport.xlsx`;
        link.click();
        setTimeout(() => link.remove(), 300);
      })
      .catch(() => {
        setLoadingReport(false);
        toast.error('Ошибка при запросе');
      });
  };
  const getLabelStatus = (status: string): string => {
    switch (status) {
      case 'NEW':
        return 'Новый';
      case 'SENT':
        return 'Отправлен';
      case 'PURCHASED':
        return 'Куплен';
      case 'ACTIVATED':
        return 'Активирован';
      case 'CLOSED':
        return 'Закрыт';
      case 'CANCELED':
        return 'Отменен';
      default:
        return null;
    }
  };

  const getItems = (reset?: boolean): void => {
    fetchManageReportServicePackagesPatients(
      query,
      group,
      statuses,
      date.start,
      date.end,
      categoryBenefits,
      signOnline,
      reset ? 0 : page + 1,
      pageSize,
      reset
    );
  };
  return (
    <>
      <Box className={classNames(styles.ReportsPageAdminServicePackagesItems)}>
        <Box
          className={classNames(
            styles.ReportsPageAdminServicePackagesItems_Section,
            styles.ReportsPageAdminServicePackagesItems_Toolbar
          )}
        >
          <Box className={classNames(styles.ReportsPageAdminServicePackagesItems_Toolbar_Types)}>
            <Box width="100%">
              <SelectInput
                block
                value={types.find((t) => t.value === type)}
                options={types}
                placeholder="Тип документа"
                onChange={(event) => onChangeType(event.value)}
              />
            </Box>
            <Button
              size="md"
              isLoading={loadingReport}
              disabled={loadingReport}
              className="ml-5"
              color="primary"
              onClick={onGetAdminAppointmentReport}
            >
              Скачать в Excel
            </Button>
          </Box>
          <Box className={classNames(styles.ReportsPageAdminServicePackagesItems_Toolbar_Filters)}>
            <Box className={classNames(styles.ReportsPageAdminServicePackagesItems_Toolbar_Search)}>
              <SearchIcon className={styles.ReportsPageAdminServicePackagesItems_Toolbar_Search_Icon} />
              <InputField
                value={query}
                onChange={(e) => {
                  setQuery(e.target.value);
                }}
                block
                placeholder="Введите ФИО пациента или название тарифа"
              />
              {query && (
                <ClearIcon
                  onClick={() => {
                    setQuery('');
                  }}
                  className={classNames(
                    styles.ReportsPageAdminServicePackagesItems_Toolbar_Search_Icon,
                    styles.ReportsPageAdminServicePackagesItems_Toolbar_Search_Icon_Clear
                  )}
                />
              )}
            </Box>
            <Box className={styles.ReportsPageAdminServicePackagesItems_Toolbar_Buttons}>
              <DropdownMenu isOpen={toolbarOpenStatus} toggle={() => toggleToolbarOpenStatus(!toolbarOpenStatus)}>
                <DropdownToggle>
                  <Button color="primary" size="md">
                    <Box display="flex" alignItems="center">
                      <TuneIcon />
                    </Box>
                    {statuses.length > 0 && (
                      <Box className={styles.ReportsPageAdminServicePackagesItems_Toolbar_Buttons_Status} />
                    )}
                  </Button>
                </DropdownToggle>
                <DropdownContent className={styles.ReportsPageAdminServicePackagesItems_Toolbar_DropdownContent}>
                  <ul>
                    {_statuses.map((_status: Manage.Doctor.AvailabilityType, index: number) => (
                      <li
                        key={index}
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          if (statuses.indexOf(_status) > -1) {
                            setStatuses(statuses.filter((s) => s !== _status));
                          } else {
                            setStatuses([...statuses, _status]);
                          }
                        }}
                      >
                        <Box>
                          <Box
                            className={classNames(
                              styles.ReportsPageAdminServicePackagesItems_Toolbar_DropdownContent_Status,
                              styles[`ReportsPageAdminServicePackagesItems_Toolbar_DropdownContent_Status_${_status}`]
                            )}
                          />
                          <Box display="flex" flex="1 1 auto" style={{ width: 'calc(100% - 12px)' }} pl={2}>
                            {getLabelStatus(_status)}
                          </Box>
                        </Box>
                        <CheckIcon
                          className={classNames(
                            styles.ReportsPageAdminServicePackagesItems_Toolbar_DropdownContent_Icon,
                            statuses.indexOf(_status) === -1 &&
                              styles.ReportsPageAdminServicePackagesItems_Toolbar_DropdownContent_Icon_Hidden
                          )}
                        />
                      </li>
                    ))}
                  </ul>
                  {statuses.length > 0 && (
                    <Box className={styles.ReportsPageAdminServicePackagesItems_Toolbar_DropdownContent_Reset}>
                      <Button
                        color="default"
                        block
                        size="md"
                        onClick={(event) => {
                          event.preventDefault();
                          setStatuses([]);
                        }}
                      >
                        Сбросить фильтр
                      </Button>
                    </Box>
                  )}
                </DropdownContent>
              </DropdownMenu>
              <DropdownMenu isOpen={toolbarDateOpen} toggle={() => toggleToolbarDateOpen(!toolbarDateOpen)}>
                <DropdownToggle>
                  <Button color="primary" size="md">
                    <EventIcon />
                    {(date.start || date.end) &&
                      [date.start, date.end]
                        .filter((v) => v)
                        .map((v) => format(v, 'dd.MM.yyyy'))
                        .join(' - ')}
                    {(date.start || date.end) && (
                      <Box className={styles.ReportsPageAdminServicePackagesItems_Toolbar_Buttons_Status} />
                    )}
                  </Button>
                </DropdownToggle>
                <DropdownContent
                  className={classNames(
                    styles.ReportsPageAdminServicePackagesItems_Toolbar_DropdownContent,
                    styles.ReportsPageAdminServicePackagesItems_Toolbar_DropdownContent_Calendar
                  )}
                >
                  <CalendarRange date={date} onChangeDate={(date: { start: Date; end: Date }) => setDate(date)} />
                  {(date.start || date.end) && (
                    <Box className={styles.ReportsPageAdminServicePackagesItems_Toolbar_DropdownContent_Reset}>
                      <Button
                        color="default"
                        block
                        size="md"
                        onClick={(event) => {
                          event.preventDefault();
                          setDate({ start: null, end: null });
                        }}
                      >
                        Сбросить фильтр
                      </Button>
                    </Box>
                  )}
                </DropdownContent>
              </DropdownMenu>
            </Box>
          </Box>
        </Box>
        <Box className={classNames(styles.ReportsPageAdminServicePackagesItems_Toolbar_Filters_Groups)}>
          <Box mr={2}>
            <ServicePackageGroupSelector
              name="groupId"
              isEmpty
              value={group}
              onChange={(option) => setGroup(option.value ? String(option.value) : null)}
              placeholder="Группа"
            />
          </Box>
          <Box mr={2}>
            <SelectInput
              value={signOnline}
              onChange={(option) => {
                setSignOnline(option.value);
              }}
              options={signOnlineOptions}
              placeholder={signOnlineOptions.find((s) => (s.value as any) === signOnline)?.label}
            />
          </Box>
          <Box>
            <CategoryBenefitsSelect
              value={categoryBenefits}
              onChange={(option) => {
                setCategoryBenefits(option.value);
              }}
              placeholder="Льгота"
            />
          </Box>
        </Box>
        <Box
          className={classNames(
            styles.ReportsPageAdminServicePackagesItems_Section,
            styles.ReportsPageAdminServicePackagesItems_Content
          )}
        >
          <InfiniteScroll
            className={styles.ReportsPageAdminServicePackagesItems_InfiniteScroll}
            pageStart={0}
            hasMore={loading ? false : hasMore}
            initialLoad={false}
            threshold={pageSize}
            loadMore={() => getItems()}
            loader={null}
          >
            <>
              {items.length === 0 && !(loading || errors) && <Box>Тарифов не найдено</Box>}
              {items.length > 0 && (
                <Box className={classNames(styles.ReportsPageAdminServicePackagesItems_Items)}>
                  {items.map((item: Manage.ServicePackages.Patient.Item, index: number) => (
                    <Box className={classNames(styles.ReportsPageAdminServicePackagesItems_Items_Item)} key={index}>
                      <Box className={classNames(styles.ReportsPageAdminServicePackagesItems_Items_Item_Name)}>
                        {item?.name}
                      </Box>
                      <Box className={classNames(styles.ReportsPageAdminServicePackagesItems_Items_Item_Info)}>
                        Информация о тарифе
                      </Box>
                      {item?.patient && (
                        <Box className={classNames(styles.ReportsPageAdminServicePackagesItems_Items_Item_Label)}>
                          Пациент:&nbsp;{item?.patient?.lastName} {item?.patient?.firstName} {item?.patient?.middleName}
                        </Box>
                      )}
                      <Box className={classNames(styles.ReportsPageAdminServicePackagesItems_Items_Item_Label)}>
                        Стоимость за шт (в день):&nbsp;<Box color="#1373ff">{item?.price || 0} &#8381;</Box>
                      </Box>
                      {item?.createdAt && (
                        <Box className={classNames(styles.ReportsPageAdminServicePackagesItems_Items_Item_Label)}>
                          Дата создания:&nbsp;{format(parseISO(item.createdAt.toString()), 'dd.MM.yyyy')}
                        </Box>
                      )}
                      {item?.purchasedAt && (
                        <Box className={classNames(styles.ReportsPageAdminServicePackagesItems_Items_Item_Label)}>
                          Дата продажи:&nbsp;{format(parseISO(item.purchasedAt.toString()), 'dd.MM.yyyy')}
                        </Box>
                      )}
                      {item?.activatedAt && (
                        <Box className={classNames(styles.ReportsPageAdminServicePackagesItems_Items_Item_Label)}>
                          Дата активации:&nbsp;{format(parseISO(item.activatedAt.toString()), 'dd.MM.yyyy')}
                        </Box>
                      )}
                      {item?.closedAt && (
                        <Box className={classNames(styles.ReportsPageAdminServicePackagesItems_Items_Item_Label)}>
                          Дата закрытия:&nbsp;{format(parseISO(item.closedAt.toString()), 'dd.MM.yyyy')}
                        </Box>
                      )}
                      {item.status && (
                        <Box className={classNames(styles.ReportsPageAdminServicePackagesItems_Items_Item_Label)}>
                          Статус:&nbsp;
                          <Box
                            className={classNames(
                              styles.ReportsPageAdminServicePackagesItems_Items_Item_Status,
                              styles[`ReportsPageAdminServicePackagesItems_Items_Item_Status_${item.status.value}`]
                            )}
                          >
                            {getLabelStatus(item.status.value as string)}
                          </Box>
                        </Box>
                      )}
                      <Box className={classNames(styles.ReportsPageAdminServicePackagesItems_Items_Item_Label)}>
                        Онлайн подписание: {item.verificationCode?.length > 0 ? 'Да' : 'Нет'}
                      </Box>
                      <Box className={classNames(styles.ReportsPageAdminServicePackagesItems_Items_Item_Label)}>
                        Внесено средств:&nbsp;<Box color="#1373ff">{item.debt?.totalPaidSum || 0} &#8381;</Box>
                      </Box>
                      <Box className={classNames(styles.ReportsPageAdminServicePackagesItems_Items_Item_Label)}>
                        Начислено средств:&nbsp;<Box color="#1373ff">{item.debt?.totalAmountSum || 0} &#8381;</Box>
                      </Box>
                      <Box className={classNames(styles.ReportsPageAdminServicePackagesItems_Items_Item_Label)}>
                        Долг:&nbsp;<Box color="#1373ff">{item.debt?.debtSum || 0} &#8381;</Box>
                      </Box>
                      <Box className={classNames(styles.ReportsPageAdminServicePackagesItems_Items_Item_Label)}>
                        Кол-во оказанных услуг:&nbsp;<Box color="#1373ff">{item.usedCount || 0}</Box>
                      </Box>
                      {item?.group?.name && (
                        <Box className={classNames(styles.ReportsPageAdminServicePackagesItems_Items_Item_Label)}>
                          Контрагент (группа):&nbsp;<Box color="#1373ff">{item?.group?.name}</Box>
                        </Box>
                      )}
                      {item?.activatedBy && (
                        <Box className={classNames(styles.ReportsPageAdminServicePackagesItems_Items_Item_Label)}>
                          Активировал:&nbsp;
                          <Box color="#1373ff">
                            {item?.activatedBy?.lastName} {item?.activatedBy?.firstName} {item?.activatedBy?.middleName}
                          </Box>
                          {item?.activatedBy?.role && (
                            <>
                              &nbsp;<Box color="#00C12F">{ReportItemUtils.getLabelRole(item?.activatedBy?.role)}</Box>
                            </>
                          )}
                        </Box>
                      )}
                      {item?.closedBy && (
                        <Box className={classNames(styles.ReportsPageAdminServicePackagesItems_Items_Item_Label)}>
                          Закрыл:&nbsp;{' '}
                          <Box color="#1373ff">
                            {item?.closedBy?.lastName} {item?.closedBy?.firstName} {item?.closedBy?.middleName}
                          </Box>
                          {item?.closedBy?.role && (
                            <>
                              &nbsp;<Box color="#00C12F">{ReportItemUtils.getLabelRole(item?.closedBy?.role)}</Box>
                            </>
                          )}
                        </Box>
                      )}
                      {item?.promo && (
                        <Box className={classNames(styles.ReportsPageAdminServicePackagesItems_Items_Item_Label)}>
                          Промокод для активации:&nbsp;<Box color="#1373ff">{item?.promo}</Box>
                        </Box>
                      )}
                    </Box>
                  ))}
                </Box>
              )}
              {(loading || errors) && (
                <Box width="100%" p={2}>
                  <ContentLoading isLoading={loading} isError={errors} fetchData={() => getItems()} />
                </Box>
              )}
            </>
          </InfiniteScroll>
        </Box>
      </Box>
    </>
  );
};

export default connect(
  (state: any) => ({
    ...(state.manageReportServicePackages as ManageReportServicePackagesReducerInitialSettings),
  }),
  {
    fetchManageReportServicePackagesPatients,
    fetchManageServicePackagesMfo,
  }
)(ReportsPageAdminServicePackagesItems);
