import React, { useEffect } from 'react';
import classNames from 'classnames';
import { ReactComponent as AngleLeftIcon } from 'assets/img/icons/angle-left-icon.svg';
import { ReactComponent as AngleRightIcon } from 'assets/img/icons/angle-right-icon.svg';
import { Calendar, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { endOfMonth, isAfter, isBefore, isSameDay, isSameMonth, isWithinInterval, startOfMonth } from 'date-fns';
import { Box } from '@material-ui/core';
import { ru } from 'date-fns/locale';
import styles from './CalendarRange.module.sass';
import { format } from '../../utils/date';

export interface CalendarRangeDateInterface {
  start?: Date;
  end?: Date;
}
export interface CalendarRangeInterface {
  date?: CalendarRangeDateInterface;
  onChangeDate: (date: CalendarRangeDateInterface) => void;
  [key: string]: any;
}
const CalendarRange = (props: CalendarRangeInterface) => {
  const { date, onChangeDate } = {
    date: { start: null, end: null },
    onChangeDate: (date: CalendarRangeDateInterface) => {},
    ...props,
  };
  useEffect(() => {
    const { start, end } = date;
    if (!start && end) {
      onChangeDate({
        ...date,
        start: date.end,
        end: null,
      });
    }
  }, []);
  const onChange = (selectDate: Date) => {
    const { start, end } = date;
    let _start: Date = null;
    let _end: Date = null;
    if ((start && end) || (!start && !end)) {
      if (start && isSameDay(start, selectDate)) {
        return;
      }
      _start = selectDate;
      _end = null;
    } else if (start && !end) {
      if (isBefore(selectDate, start)) {
        _end = start;
        _start = selectDate;
      } else {
        _end = selectDate;
        _start = start;
      }
    }
    onChangeDate({
      ...date,
      start: _start,
      end: _end,
    });
  };
  const getDate = (): Date => {
    const { start, end } = date;
    if (end) {
      return end;
    }
    if (start) {
      return start;
    }
    return new Date();
  };
  const renderOfDay = (day: MaterialUiPickersDate): JSX.Element => {
    const { start, end } = date;
    return (
      <Box
        component="span"
        className={classNames(
          styles.CalendarRange_Day,
          start && end && isWithinInterval(day, { start, end }) && styles.CalendarRange_Day_Current,
          start && isSameDay(start, day) && styles.CalendarRange_Day_Current,
          end && isSameDay(end, day) && styles.CalendarRange_Day_Current,
          isSameDay(new Date(), day) && styles.CalendarRange_Day_Today,
          isAfter(day, endOfMonth(end || start || new Date())) && styles.CalendarRange_Day_Future,
          isBefore(day, startOfMonth(start || new Date())) && styles.CalendarRange_Day_Past
        )}
        onClick={(event) => {
          onChange(day);
        }}
        width="27px"
        height="27px"
        fontSize="13px"
        lineHeight="27px"
        display="inline-block"
        m="2px"
        textAlign="center"
      >
        {format(day, 'd')}
      </Box>
    );
  };
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
      <Box
        className={classNames(styles.CalendarRange, 'Calendar')}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
      >
        <Calendar
          leftArrowIcon={<AngleLeftIcon className={styles.CalendarRange_Arrow_Icon} />}
          rightArrowIcon={<AngleRightIcon className={styles.CalendarRange_Arrow_Icon} />}
          date={getDate()}
          onChange={() => {}}
          disablePast={false}
          disableFuture={false}
          shouldDisableDate={() => true}
          renderDay={(...args) => renderOfDay.call(null, ...args)}
        />
      </Box>
    </MuiPickersUtilsProvider>
  );
};
export default CalendarRange;
