import React, { ReactElement, useCallback, useMemo } from 'react';
import { useStore } from 'effector-react';
import $store from '../../store';
import { AgentSaleType } from '../../types';
import Button from '../../../../../components/Forms/Button';
import { validateByFieldValue } from '../../../../../components/Forms/FormValidator/FormValidator';
import { onSetAgentErrorsForm } from '../../store/events';
import { getValidator } from '../../constants/validators';
import { pspFx, userPspFx } from '../../store/effects';

const AgentSaleActions = (): ReactElement => {
  const { form, errors, type, loading } = useStore($store);
  const handleSubmit = useCallback(() => {
    const _ = validateByFieldValue(form, getValidator(type), errors);
    onSetAgentErrorsForm(_);
    if (!_.length) {
      const fn = type === AgentSaleType.CURRENT ? pspFx : userPspFx;
      fn.call(fn, [form]);
    }
  }, [errors, form, type]);
  const title = useMemo(() => {
    switch (type) {
      case AgentSaleType.NEW:
        return 'Зарегистрировать и продать';
      default:
        return 'Продать тариф';
    }
  }, [type]);
  return (
    <>
      <Button
        isLoading={loading}
        size="md"
        onClick={handleSubmit}
        disabled={loading}
        className="mb-5"
        type="button"
        block
        color="primary"
      >
        {title}
      </Button>
    </>
  );
};

export default AgentSaleActions;
