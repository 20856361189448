import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

// Components
import ProfileView from './ProfileView';
import NotificationsView from './NotificationsView';
import PromoView from './PromoView';
import ContentCard from 'components/ContentCard';

import styles from './ProfilePage.module.sass';

const ProfilePage = (props: any) => {

  const [profileViewType, setProfileViewType] = useState('PROFILE');

  const getProfileView = (profileViewType: string) => {
    let profileView = null;

    switch (profileViewType) {
      case 'PROFILE':
        profileView = <ProfileView />
        break;

      case 'NOTIF':
        profileView = <NotificationsView />
        break;

      case 'PROMO':
        profileView = <PromoView />
        break;
    }

    return profileView;
  };

  return (
    <ContentCard>
      <ContentCard.Header className={styles.profileCardHeader}>
        <div className={`page-header-menu ${styles.contentCard_menu}`}>
          <div
            className={classNames('page-header-menu-item', styles.contentCard_menu_item, {
              'page-header-menu-item-active': profileViewType === 'PROFILE'
            })}
            onClick={e => setProfileViewType('PROFILE')}
          >
            Профиль
          </div>

          <div
            className={classNames('page-header-menu-item', styles.contentCard_menu_item, {
              'page-header-menu-item-active': profileViewType === 'NOTIF'
            })}
            onClick={e => setProfileViewType('NOTIF')}
          >
            Уведомления
          </div>

          <div
            className={classNames('page-header-menu-item', styles.contentCard_menu_item, {
              'page-header-menu-item-active': profileViewType === 'PROMO'
            })}
            onClick={e => setProfileViewType('PROMO')}
          >
            Промокод
          </div>
        </div>
      </ContentCard.Header>

      <ContentCard.Main>
        {getProfileView(profileViewType)}
      </ContentCard.Main>
    </ContentCard>
  );
};

export default ProfilePage;
