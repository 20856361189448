import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import classNames from 'classnames';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import EventIcon from '@material-ui/icons/Event';
import { parseISO } from 'date-fns';
import { toast } from 'react-toastify';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import styles from './ReportsPageAdminPatientItems.module.sass';
import { fetchManageReportPatient } from '../../../../store/actions';
import ContentLoading from '../../../../components/ContentLoading';
import Button from '../../../../components/Forms/Button';
import { format } from '../../../../utils/date';
import DropdownMenu from '../../../../components/DropdownMenu';
import DropdownToggle from '../../../../components/DropdownMenu/DropdownToggle';
import DropdownContent from '../../../../components/DropdownMenu/DropdownContent';
import CalendarRange from '../../../../components/CalendarRange';
import SelectInput from '../../../../components/Forms/SelectInput';
import { ManageReportPatientReducerInitialSettings } from '../../../../store/reducers/manageReportPatientReducer.reducer';
import { getAdminAppointmentReport } from '../../../../api/getAdminAppointmentReport';
import { getReportPatient } from '../../../../api/getReportPatient';
import InputField from '../../../../components/Forms/InputField';

type ReportsPageAdminAppointmentItemsFilter = 'all' | 'payedWithPromo';
interface PropsInterface {
  loading: boolean;
  errors: any;
  page: number;
  pageSize: number;
  hasMore: boolean;
  items: any;
  fetchManageReportPatient: (
    query?: string,
    external?: boolean,
    regFrom?: Date,
    regTo?: Date,
    page?: number,
    pageSize?: number,
    reset?: boolean
  ) => void;
  type: any;
  types: any;
  onChangeType: (type: any) => void;
}
const ReportsPageAdminPatientItems = (props: PropsInterface) => {
  const { loading, items, fetchManageReportPatient, errors, page, pageSize, hasMore, type, types, onChangeType } =
    props;
  const [timeoutSearch, setTimeoutSearch] = useState<NodeJS.Timeout>(null);
  const [loadingReport, setLoadingReport] = useState(false);
  const [query, setQuery] = useState<string>(null);
  const [date, setDate] = useState<{ start: Date; end: Date }>({ start: null, end: null });
  const [filter, setFilter] = useState<any>('all');
  const [toolbarDateOpen, toggleToolbarDateOpen] = useState<boolean>(false);
  useEffect(() => {
    if (timeoutSearch) {
      clearTimeout(timeoutSearch);
    }
    if (query) {
      setTimeoutSearch(
        setTimeout(() => {
          getItems(true);
        }, 500)
      );
    } else {
      getItems(true);
    }
    return () => {
      if (timeoutSearch) {
        clearTimeout(timeoutSearch);
      }
    };
  }, [query, date, filter]);
  const onGetAdminPatientReport = () => {
    setLoadingReport(true);
    getReportPatient(query, filter === 'payedWithPromo' ? true : null, date.start, date.end, page, pageSize)
      .then((data) => {
        setLoadingReport(false);
        const fileURL = URL.createObjectURL(data.data);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = `PatientReport.xlsx`;
        link.click();
        setTimeout(() => link.remove(), 300);
      })
      .catch(() => {
        setLoadingReport(false);
        toast.error('Ошибка при запросе');
      });
  };
  const getItems = (reset?: boolean): void => {
    fetchManageReportPatient(
      query,
      filter === 'payedWithPromo' ? true : null,
      date.start,
      date.end,
      reset ? 0 : page + 1,
      pageSize,
      reset
    );
  };
  return (
    <>
      <Box className={classNames(styles.ReportsPageAdminPatientItems)}>
        <Box
          className={classNames(
            styles.ReportsPageAdminPatientItems_Section,
            styles.ReportsPageAdminPatientItems_Toolbar
          )}
        >
          <Box className={classNames(styles.ReportsPageAdminPatientItems_Toolbar_Types)}>
            <Box width="100%">
              <SelectInput
                block
                value={types.find((t) => t.value === type)}
                options={types}
                placeholder="Тип документа"
                onChange={(event) => onChangeType(event.value)}
              />
            </Box>
            <Button
              size="md"
              isLoading={loadingReport}
              disabled={loadingReport}
              className="ml-5"
              color="primary"
              onClick={onGetAdminPatientReport}
            >
              Скачать в Excel
            </Button>
          </Box>
          <Box className={classNames(styles.ReportsPageAdminPatientItems_Toolbar_Filters)}>
            <Box className={classNames(styles.ReportsPageAdminPatientItems_Toolbar_Search)}>
              <SearchIcon className={styles.ReportsPageAdminPatientItems_Toolbar_Search_Icon} />
              <InputField
                value={query}
                onChange={(e) => {
                  setQuery(e.target.value);
                }}
                block
                placeholder="Введите ФИО пациента"
              />
              {query && (
                <ClearIcon
                  onClick={() => {
                    setQuery('');
                  }}
                  className={classNames(
                    styles.ReportsPageAdminPatientItems_Toolbar_Search_Icon,
                    styles.ReportsPageAdminPatientItems_Toolbar_Search_Icon_Clear
                  )}
                />
              )}
            </Box>
            <Box className={styles.ReportsPageAdminPatientItems_Toolbar_Buttons}>
              <DropdownMenu isOpen={toolbarDateOpen} toggle={() => toggleToolbarDateOpen(!toolbarDateOpen)}>
                <DropdownToggle>
                  <Button color="primary" size="md">
                    <EventIcon />
                    {(date.start || date.end) &&
                      [date.start, date.end]
                        .filter((v) => v)
                        .map((v) => format(v, 'dd.MM.yyyy'))
                        .join(' - ')}
                    {(date.start || date.end) && (
                      <Box className={styles.ReportsPageAdminPatientItems_Toolbar_Buttons_Status} />
                    )}
                  </Button>
                </DropdownToggle>
                <DropdownContent
                  className={classNames(
                    styles.ReportsPageAdminPatientItems_Toolbar_DropdownContent,
                    styles.ReportsPageAdminPatientItems_Toolbar_DropdownContent_Calendar
                  )}
                >
                  <CalendarRange date={date} onChangeDate={(date: { start: Date; end: Date }) => setDate(date)} />
                  {(date.start || date.end) && (
                    <Box className={styles.ReportsPageAdminPatientItems_Toolbar_DropdownContent_Reset}>
                      <Button
                        color="default"
                        block
                        size="md"
                        onClick={(event) => {
                          event.preventDefault();
                          setDate({ start: null, end: null });
                        }}
                      >
                        Сбросить фильтр
                      </Button>
                    </Box>
                  )}
                </DropdownContent>
              </DropdownMenu>
            </Box>
          </Box>
        </Box>

        <Box className={classNames(styles.ReportsPageAdminPatientItems_Toolbar_Filters_Buttons)}>
          <Button
            className="mr-2"
            size="sm"
            color={filter === 'all' ? 'primary' : 'default'}
            onClick={() => {
              filter !== 'all' && setFilter('all');
            }}
          >
            Все
          </Button>
          <Button
            size="sm"
            color={filter === 'payedWithPromo' ? 'primary' : 'default'}
            onClick={() => {
              filter !== 'payedWithPromo' && setFilter('payedWithPromo');
            }}
          >
            Тариф МФО
          </Button>
        </Box>
        <Box
          className={classNames(
            styles.ReportsPageAdminPatientItems_Section,
            styles.ReportsPageAdminPatientItems_Content
          )}
        >
          <InfiniteScroll
            className={styles.ReportsPageAdminPatientItems_InfiniteScroll}
            pageStart={0}
            hasMore={loading ? false : hasMore}
            initialLoad={false}
            threshold={pageSize}
            loadMore={() => getItems()}
            loader={null}
          >
            <>
              {items.length === 0 && !(loading || errors) && <Box>Пацеинтов не найдено</Box>}
              {items.length > 0 && (
                <Box className={classNames(styles.ReportsPageAdminPatientItems_Items)}>
                  {items.map((item: any, index: number) => (
                    <Box className={classNames(styles.ReportsPageAdminPatientItems_Items_Item)} key={index}>
                      <Box className={classNames(styles.ReportsPageAdminPatientItems_Items_Item_Name)}>
                        {item?.lastName} {item?.firstName} {item?.middleName}
                      </Box>
                      <Box className={classNames(styles.ReportsPageAdminPatientItems_Items_Item_Info)}>
                        Информация о пациенте
                      </Box>
                      <Box className={classNames(styles.ReportsPageAdminPatientItems_Items_Item_Label)}>
                        Клиент МФО:&nbsp;{item?.external === true ? <Box color="#1373ff">да</Box> : 'нет'}
                      </Box>
                      {item?.regDate && (
                        <Box className={classNames(styles.ReportsPageAdminPatientItems_Items_Item_Label)}>
                          Дата: {format(parseISO(item?.regDate.toString()), 'dd.MM.yyyy')}
                        </Box>
                      )}
                      {item?.appointmentCount >= 0 && (
                        <Box className={classNames(styles.ReportsPageAdminPatientItems_Items_Item_Label)}>
                          Число консультаций: {item?.appointmentCount}
                        </Box>
                      )}
                      {item?.purchasedPackages && (
                        <Box className={classNames(styles.ReportsPageAdminPatientItems_Items_Item_Label)}>
                          Проданные тарифы:{' '}
                          {(item?.purchasedPackages || [])
                            .map(({ name }) => name)
                            .filter((v) => v)
                            .join(',') || 'нет'}
                        </Box>
                      )}
                      {item?.activatedPackages && (
                        <Box className={classNames(styles.ReportsPageAdminPatientItems_Items_Item_Label)}>
                          Активные тарифы:{' '}
                          {(item?.activatedPackages || [])
                            .map(({ name }) => name)
                            .filter((v) => v)
                            .join(',') || 'нет'}
                        </Box>
                      )}
                      {item?.closedPackages >= 0 && (
                        <Box className={classNames(styles.ReportsPageAdminPatientItems_Items_Item_Label)}>
                          Закрытые тарифы:{' '}
                          {(item?.closedPackages || [])
                            .map(({ name }) => name)
                            .filter((v) => v)
                            .join(',') || 'нет'}
                        </Box>
                      )}
                      <Box className={classNames(styles.ReportsPageAdminPatientItems_Items_Item_Label)}>
                        Внесенные средства:&nbsp;<Box color="#1373ff">{item?.paymentTotal || 0} &#8381;</Box>
                      </Box>
                      {item?.externalId && (
                        <Box className={classNames(styles.ReportsPageAdminPatientItems_Items_Item_Label)}>
                          Внешний id:&nbsp;<Box color="#1373ff">{item?.externalId}</Box>
                        </Box>
                      )}
                      <Box className={classNames(styles.ReportsPageAdminPatientItems_Items_Item_Label)}>
                        Долг:&nbsp;<Box color="#1373ff">{item?.debt?.debtSum} &#8381;</Box>
                      </Box>
                    </Box>
                  ))}
                </Box>
              )}
              {(loading || errors) && (
                <Box width="100%" p={2}>
                  <ContentLoading isLoading={loading} isError={errors} fetchData={() => getItems()} />
                </Box>
              )}
            </>
          </InfiniteScroll>
        </Box>
      </Box>
    </>
  );
};

export default connect(
  (state: any) => ({
    ...(state.manageReportPatient as ManageReportPatientReducerInitialSettings),
  }),
  {
    fetchManageReportPatient,
  }
)(ReportsPageAdminPatientItems);
