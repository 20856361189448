const beforeMaskedValueChangePhone: any = (newState: any) => {
  let { value, selection } = newState;
  if (value?.length > 1 && value[1] === '8') {
    value = value.replace('+8', '+7');
  }
  if (value?.length > 1 && value[1] !== '7' && value[1] !== '_') {
    const { index = 1 } = value.match(/[^+0-9]/) || {};
    selection = { start: index + 3, end: index + 3 };
    value = value.replace('+', '+7').substr(0, 18);
  }
  return {
    ...newState,
    value,
    selection,
  };
};

export default beforeMaskedValueChangePhone;
