import React from 'react';

import styles from './RawPage.module.sass';

const RawPage = ({ children }) => (
  <section className={styles.rawPageLayoutWrapper}>
    {children}
  </section>
);

export default RawPage;
