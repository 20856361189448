import React, { PropsWithChildren } from 'react';

import { Box } from '@material-ui/core';
import { ReactComponent as Icon } from 'assets/img/googleplay.svg';
import { GOOGLE_STORE_LINK } from '../../constants/common';

interface PropsInterface {
  className?: string;
}
const GoogleStoreLink = (props: PropsInterface): JSX.Element => {
  const { className } = props;
  return (
    <Box
      className={className}
      component={(props) => (
        <a {...props} rel="noreferrer" href={GOOGLE_STORE_LINK} target="_blank">
          <Icon />
        </a>
      )}
    />
  );
};

export default GoogleStoreLink;
