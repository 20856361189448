import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { decodeAvatarResource, getWordNumEnding } from 'utils/helpers';
import { setUserData, changeSetting } from 'store/actions';
import { convertToUserData } from 'utils';
import {
  payAppointmentsPlanned,
  payAppointmentsImmediate,
  addBalance,
  authProfileMeta,
  getServicePackagesMySuitable,
  activatePromo,
} from 'api';
import { toast } from 'react-toastify';

// Components
import ContentLoading from 'components/ContentLoading';
import Button from 'components/Forms/Button';
import InputField from 'components/Forms/InputField';
import CustomCheckbox from 'components/Forms/CustomCheckbox';

import { Box } from '@material-ui/core';
import { updateRedirect } from 'store/actions/redirect.actions';
import { datePlurals, getTimeBetween, isBeforeOrEquals, PATTERN_TIME } from 'utils/date';
import { getDuty } from 'api/getDuty';
import styles from '../PaymentPage.module.sass';

interface PropsInterface {
  setPaymentViewType?: (...args: any) => any;
}
const PaymentView = (props: PropsInterface) => {
  const { setPaymentViewType = () => {} } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector((state: RootStateOrAny) => state?.auth?.userData);
  const userToken = useSelector((state: RootStateOrAny) => state?.auth?.userToken);
  const newAppointment = useSelector((state: RootStateOrAny) => state?.storage?.newAppointment);

  const handleSetUserData = useCallback((data: any) => dispatch(setUserData(data)), [dispatch]);
  const handleChangeSetting = useCallback((key: string, value: any) => dispatch(changeSetting(key, value)), [dispatch]);
  const handleUpdateRedirect = useCallback((value: any) => dispatch(updateRedirect(value)), [dispatch]);

  const [paymentLoading, setPaymentLoading] = useState(false);

  const [isAgreementChecked, setAgreementChecked] = useState(true);
  const [agreementRequiredError, setAgreementRequiredError] = useState(false);

  const [paymentPackages, setPaymentPackages] = useState([]);
  const [paymentPackage, setPaymentPackage] = useState(null);

  const [paymentPackagesLoading, setPaymentPackagesLoading] = useState(false);
  const [paymentPackagesErrorLoading, setPaymentPackagesErrorLoading] = useState(false);

  const [estimatedStartTime, setEstimatedStartTime] = useState<string>(null);

  const [promoActivateLoading, setPromoActivateLoading] = useState(false);

  const [promoActivate, setPromoActivate] = useState(null);

  useEffect(() => {
    if ((paymentPackages || []).length > 0) {
      setPaymentPackage(paymentPackages[0]);
    }
  }, [paymentPackages]);

  useEffect(() => {
    if (newAppointment?.type === 'IMMEDIATE') {
      getDuty(newAppointment?.specialization?.id).then((value) => {
        setEstimatedStartTime(value?.estimatedStartTime);
      });
    }
  }, [newAppointment?.specialization?.id, newAppointment?.type]);

  useEffect(() => {
    setPaymentViewType(newAppointment?.paymentViewType);
  }, [newAppointment?.paymentViewType, setPaymentViewType]);

  const getPaymentPackages = useCallback(() => {
    setPaymentPackagesLoading(true);
    setPaymentPackagesErrorLoading(false);

    newAppointment.type &&
      getServicePackagesMySuitable(
        newAppointment.type,
        newAppointment.specialization?.id,
        newAppointment.date,
        newAppointment.additionalServiceType?.value
      )
        .then((response) => {
          setPaymentPackagesLoading(false);

          if (response.data.data && response.data.data.items) {
            setPaymentPackages(response.data.data.items);
          }
        })
        .catch(() => {
          setPaymentPackagesLoading(false);
          setPaymentPackagesErrorLoading(true);
        });
  }, [newAppointment]);

  useEffect(() => {
    getPaymentPackages();
  }, [getPaymentPackages, newAppointment?.type]);

  const handleActivatePromo = useCallback(() => {
    setPromoActivateLoading(true);

    activatePromo(promoActivate)
      .then(() => {
        setPromoActivateLoading(false);
        setPromoActivate(null);
        getPaymentPackages();
        toast.success('Промо код успешно применен!');
      })
      .catch(() => {
        setPromoActivate(null);
        setPromoActivateLoading(false);
        toast.error('Ошибка при активации промо кода');
      });
  }, [getPaymentPackages, promoActivate]);

  const payAppointmentsPlannedSubmit = useCallback(() => {
    payAppointmentsPlanned({
      doctorId: newAppointment?.doctor?.id,
      startTime: newAppointment?.date,
      patientServicePackageId: paymentPackage?.id,
      additionalServiceType: newAppointment?.additionalServiceType?.value || null,
    })
      .then((response) => {
        setPaymentLoading(false);

        toast.success(
          paymentPackage ? `Консультация оплачена тарифом ${paymentPackage?.name}` : 'Оплата прошла успешно!'
        );

        authProfileMeta(userToken)
          .then((response) => {
            if (response.data.data) {
              handleSetUserData(convertToUserData(response.data.data));
            }
          })
          .catch(() => {});

        handleChangeSetting('newAppointment', {
          doctor: null,
          date: null,
          price: null,
          type: 'PLANNED',
          specialization: null,
          appointmentId: response.data.data.id,
          paymentViewType: 'INFO',
          estimatedStartTime: null,
          withFiles: response.data.data.withFiles,
          additionalServiceType: null,
        });

        setPaymentViewType('INFO');
      })
      .catch(() => {
        setPaymentLoading(false);
        toast.error('Ошибка при процессинге оплаты');
      });
  }, [handleChangeSetting, handleSetUserData, newAppointment, paymentPackage, setPaymentViewType, userToken]);

  const payAppointmentsImmediateSubmit = useCallback(() => {
    payAppointmentsImmediate({
      specializationId: newAppointment?.specialization?.id,
      patientServicePackageId: paymentPackage?.id,
    })
      .then((response) => {
        setPaymentLoading(false);

        toast.success(
          paymentPackage ? `Консультация оплачена тарифом ${paymentPackage?.name}` : 'Оплата прошла успешно!'
        );

        authProfileMeta(userToken)
          .then((response) => {
            if (response.data.data) {
              handleSetUserData(convertToUserData(response.data.data));
            }
          })
          .catch(() => {});

        handleChangeSetting('newAppointment', {
          doctor: null,
          date: null,
          price: null,
          type: 'IMMEDIATE',
          specialization: null,
          appointmentId: response.data.data?.id,
          withFiles: response.data.data?.withFiles,
          paymentViewType: 'INFO',
          additionalServiceType: null,
        });
        setPaymentViewType('INFO');
      })
      .catch(() => {
        setPaymentLoading(false);
        toast.error('Ошибка при процессинге оплаты');
      });
  }, [
    handleChangeSetting,
    handleSetUserData,
    newAppointment?.specialization?.id,
    paymentPackage,
    setPaymentViewType,
    userToken,
  ]);
  const balanceUp = useCallback(() => {
    addBalance((newAppointment?.price || 0) - (user?.balance || 0))
      .then((response) => {
        setPaymentLoading(false);

        if (response.data.data) {
          const win = window;
          handleUpdateRedirect(location.pathname);
          win.open(response.data.data, '_self');
          win.focus();
        }
      })
      .catch(() => {
        setPaymentLoading(false);
        toast.error('Ошибка при пополнении баланса');
      });
  }, [handleUpdateRedirect, location.pathname, newAppointment?.price, user?.balance]);
  const appointmentsPaymentSubmit = useCallback(() => {
    if (!isAgreementChecked) {
      toast.error('Вы не согласились с условиями сервиса');
      setAgreementRequiredError(true);
      return false;
    }

    if (newAppointment?.type === 'PLANNED') {
      setPaymentLoading(true);

      if (paymentPackage || (user.balance || 0) >= (newAppointment.price || 0)) {
        payAppointmentsPlannedSubmit();
      } else {
        balanceUp();
      }
    } else if (newAppointment?.type === 'IMMEDIATE') {
      setPaymentLoading(true);

      if (paymentPackage || (user.balance || 0) >= (newAppointment.price || 0)) {
        payAppointmentsImmediateSubmit();
      } else {
        balanceUp();
      }
    }
  }, [
    balanceUp,
    isAgreementChecked,
    newAppointment,
    payAppointmentsImmediateSubmit,
    payAppointmentsPlannedSubmit,
    paymentPackage,
    user.balance,
  ]);

  const getDutyEstimatedTime = useCallback((estimatedStartTime: string) => {
    const now = new Date();
    const startTime = new Date(estimatedStartTime);
    if (isBeforeOrEquals(now, startTime)) {
      const dateTimeUnits = getTimeBetween(new Date(estimatedStartTime), new Date());
      return (
        `В течение нескольких минут после оплаты с Вами свяжется наш специалист. Предварительное время ожидания ` +
        `${dateTimeUnits.hours} ${getWordNumEnding(dateTimeUnits.hours, datePlurals.hours)}` +
        ` ${dateTimeUnits.minutes} ${getWordNumEnding(dateTimeUnits.minutes, datePlurals.minutes)}`
      );
    }
    return 'Время ожидания дежурного врача истекло.';
  }, []);

  const doctor = useMemo(() => newAppointment.doctor, [newAppointment]);

  const doctorDisplayName = useMemo(
    () => (doctor ? `${doctor.lastName || ''} ${doctor.firstName || ''} ${doctor.middleName || ''}` : 'Дежурный врач'),
    [doctor]
  );

  const renderLabel = useCallback(
    (payPackage): JSX.Element => (
      <>
        {payPackage.name}
        {payPackage.status && payPackage.status.value === 'PURCHASED' && (
          <Box ml={2} component="span">
            (требует активации)
          </Box>
        )}
      </>
    ),
    []
  );

  const label = useMemo(
    () => (
      <>
        Я принимаю{' '}
        <Link className="link" to="/agreements" target="_blank">
          условия Соглашений
        </Link>
      </>
    ),
    []
  );

  return (
    <div className={styles.paymentDetails}>
      <div className={styles.paymentDetails_doctor}>
        <div
          className={styles.paymentDetails_doctor_avatar}
          style={{ backgroundImage: `url(${decodeAvatarResource((doctor && doctor.avatar) || null, 360)})` }}
        />
        <div className={styles.paymentDetails_doctor_name}>{doctorDisplayName}</div>
        <div className={styles.paymentDetails_doctor_position}>{newAppointment?.specialization?.name}</div>

        <div className={styles.paymentDetails_doctor_descr}>
          {newAppointment?.type === 'PLANNED' ? (
            <>
              Консультация
              {newAppointment?.additionalServiceType && (
                <span className="text-sky-blue">&nbsp;{newAppointment?.additionalServiceType?.name}</span>
              )}{' '}
              начнется &nbsp;
              <span className="text-sky-blue">
                {newAppointment?.date ? format(new Date(newAppointment?.date), 'dd.MM.yyyy') : ''}
              </span>{' '}
              в{' '}
              <span className="text-sky-blue">
                {newAppointment?.date ? format(new Date(newAppointment?.date), PATTERN_TIME) : ''}
              </span>
              &nbsp; В назначенное время с Вами свяжется врач
            </>
          ) : (
            getDutyEstimatedTime(estimatedStartTime)
          )}
        </div>
      </div>

      <div className={styles.paymentDetails_details}>
        <div className={styles.paymentDetails_details_agree_check}>
          <CustomCheckbox
            label={label}
            checked={isAgreementChecked}
            onChange={(e) => {
              setAgreementChecked(e.target.checked);

              if (e.target.checked === false) {
                setAgreementRequiredError(true);
              } else {
                setAgreementRequiredError(false);
              }
            }}
            invalid={agreementRequiredError}
          />
        </div>

        <div className={styles.paymentDetails_details_promo}>
          <InputField
            block
            value={promoActivate}
            onChange={(event) => setPromoActivate(event.target.value)}
            className={styles.paymentDetails_details_promo_input}
            placeholder="Промокод"
          />
          <Button
            color="primary"
            onClick={handleActivatePromo}
            isLoading={promoActivateLoading}
            disabled={promoActivateLoading || !promoActivate}
            size="sm"
            className={styles.paymentDetails_details_promo_btn}
          >
            Добавить
          </Button>
        </div>

        {!paymentPackagesLoading && !paymentPackagesErrorLoading && paymentPackages.length === 0 && (
          <div className={styles.paymentDetails_details_pay}>
            <div className={styles.paymentDetails_details_pay_item}>
              <div className={styles.paymentDetails_details_pay_title}>Стоимость</div>
              <div className={styles.paymentDetails_details_pay_value}>{newAppointment?.price} ₽</div>
            </div>
          </div>
        )}
      </div>

      <ContentLoading
        isLoading={paymentPackagesLoading}
        isError={paymentPackagesErrorLoading}
        fetchData={() => getPaymentPackages()}
      >
        <div className={styles.paymentDetails_paypackage_radio_list}>
          {paymentPackages.map((payPackage) => (
            <div key={`package-option-${payPackage?.id}`} className={styles.paymentDetails_paypackage_radio}>
              <CustomCheckbox
                label={renderLabel(payPackage)}
                disabled={payPackage.status && payPackage.status.value === 'PURCHASED'}
                checked={paymentPackage?.id === payPackage.id}
                onChange={(e) => {
                  if (e.target.checked) {
                    setPaymentPackage(payPackage);
                  }
                }}
              />
            </div>
          ))}
        </div>
      </ContentLoading>

      <Button
        color="primary"
        block
        className="mt-4"
        disabled={paymentPackagesLoading}
        isLoading={paymentLoading}
        onClick={() => appointmentsPaymentSubmit()}
      >
        {paymentPackagesLoading
          ? 'Загрузка...'
          : paymentPackage
          ? 'Оплатить тарифом'
          : (user?.balance || 0) > (newAppointment?.price || 0)
          ? `К оплате ${newAppointment?.price || 0} ₽`
          : `Оплатить`}
      </Button>
    </div>
  );
};

export default PaymentView;
