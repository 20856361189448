import { createStore, forward } from 'effector';
import { getProfilePersonalsInteractionsMetaFx } from './effects';
import { onGetProfilePersonalsInteractionsMeta } from './events';
import { createPersonalAppointmentFx } from '../appointments/effects';

export interface PersonalStateInterface {
  count: number;
  loading: boolean;
  patients: Array<any>;
  error: any;
  packageId: string | null;
  appointmentId: string | null;
  doctor: any | null;
}
const defaultState: PersonalStateInterface = {
  count: 0,
  loading: false,
  patients: [],
  error: null,
  packageId: null,
  appointmentId: null,
  doctor: null,
};

const $store = createStore(defaultState);
forward({ from: onGetProfilePersonalsInteractionsMeta, to: getProfilePersonalsInteractionsMetaFx });
$store.on(createPersonalAppointmentFx.doneData, (_, appointmentId) => ({ ..._, appointmentId }));
$store.on(getProfilePersonalsInteractionsMetaFx.pending, (_, loading) => ({ ..._, loading, error: null }));
$store.on(getProfilePersonalsInteractionsMetaFx.doneData, (_, data) => ({ ..._, ...data }));
$store.on(getProfilePersonalsInteractionsMetaFx.fail, (_, error) => ({ ..._, loading: false, error }));

export default $store;
