import { Fields } from '../../../../constants/fields';

export const PspTranslation: Record<string, Array<string>> = {
  [Fields.Phone]: ['Телефон'],
  [Fields.Email]: [' Электронная почта'],
  [Fields.Sex]: ['Пол'],
  [Fields.Birthday]: ['Дата рождения'],
  [Fields.ConfirmCode]: ['Введите код'],
  [Fields.FirstName]: ['Имя'],
  [Fields.LastName]: ['Фамилия'],
  [Fields.MiddleName]: ['Отчество'],
  [Fields.InternalTariffId]: ['Выберите тариф'],
};
