import { StateInterface } from 'store';
import {
  eachDayOfInterval,
  eachHourOfInterval,
  eachMonthOfInterval,
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfDay,
  startOfHour,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from 'date-fns';
import {
  JournalFilterType,
  JournalType,
  JournalUnitTypeUnits,
  JournalUnitTypeUnitsDomain,
  JournalValueColor,
} from 'views/JournalPage/types';
import { convertUtcToZonedTime, format } from 'utils/date';

export const selectJournal = (state: StateInterface) => state?.journal;
export const selectJournalType = (state: StateInterface) => selectJournal(state)?.type;
export const selectJournalPatient = (state: StateInterface) => selectJournal(state)?.patient;
export const selectJournalPatientName = (state: StateInterface) => {
  const patient = selectJournalPatient(state);
  const name = [patient?.firstName, patient?.lastName].filter((s) => s).join(' ');
  return name || null;
};
export const selectJournalUserId = (state: StateInterface) => selectJournal(state)?.patient?.id;
export const selectJournalView = (state: StateInterface) => selectJournal(state)?.view;
export const selectJournalDate = (state: StateInterface) => selectJournal(state)?.date;
export const selectJournalTimeFrom = (state: StateInterface) => selectJournal(state)?.timeFrom;
export const selectJournalTimeTo = (state: StateInterface) => selectJournal(state)?.timeTo;
export const selectJournalPage = (state: StateInterface) => selectJournal(state)?.page;
export const selectJournalModel = (state: StateInterface) => selectJournal(state)?.model;
export const selectJournalPageSize = (state: StateInterface) => selectJournal(state)?.pageSize;
export const selectJournalLoading = (state: StateInterface) => selectJournal(state)?.loading;
export const selectJournalError = (state: StateInterface) => selectJournal(state)?.error;
export const selectJournalItems = (state: StateInterface) => selectJournal(state)?.items;
export const selectJournalCurrent = (state: StateInterface) => selectJournal(state)?.current;
export const selectJournalDeleteItem = (state: StateInterface) => selectJournal(state)?.deleteItem;
export const selectJournalTotalItems = (state: StateInterface) => selectJournal(state)?.totalItems;
export const selectJournalTypeUnit = (state: StateInterface) => {
  const type = selectJournalType(state);
  return JournalUnitTypeUnits?.[type] || [];
};
export const selectJournalDomainXAxis = (state: StateInterface): Array<any> => {
  let start = selectJournalTimeFrom(state);
  let end = selectJournalTimeTo(state);
  const date = selectJournalDate(state);
  let dates = [];
  let formatTime = 'dd';
  let formatter = startOfHour;
  switch (date) {
    case JournalFilterType.CUSTOM:
    case JournalFilterType.DAY:
      formatTime = 'HH:mm';
      start = startOfDay(start);
      end = endOfDay(end);
      dates = eachHourOfInterval({ start, end }, { step: 1 }).map((time) =>
        format(convertUtcToZonedTime(formatter(new Date(time))), formatTime)
      );
      return dates.concat(format(convertUtcToZonedTime(endOfDay(new Date())), formatTime));
    case JournalFilterType.WEEK:
      formatter = startOfDay;
      formatTime = 'EEEEEE';
      start = startOfWeek(start, { weekStartsOn: 1 });
      end = endOfWeek(end, { weekStartsOn: 1 });
      dates = eachDayOfInterval({ start, end });
      return dates.map((time) => format(convertUtcToZonedTime(formatter(new Date(time))), formatTime));
    case JournalFilterType.MONTH:
      formatter = startOfDay;
      formatTime = 'dd';
      start = startOfMonth(start);
      end = endOfMonth(end);
      dates = eachDayOfInterval({ start, end });
      return dates.map((time) => format(convertUtcToZonedTime(formatter(new Date(time))), formatTime));
    case JournalFilterType.YEAR:
      start = startOfYear(start);
      end = endOfYear(end);
      formatTime = 'LLL';
      dates = eachMonthOfInterval({ start, end });
      return dates.map((time) => format(convertUtcToZonedTime(formatter(new Date(time))), formatTime));
    default:
      break;
  }
  return [];
};
export const selectJournalDomainYAxis = (state: StateInterface): Array<any> => {
  const type = selectJournalType(state);
  return JournalUnitTypeUnitsDomain?.[type] || [];
};
export const selectJournalMaxMin = (state: StateInterface): Array<{ max: number; min: number; color: string }> => {
  const type = selectJournalType(state);
  switch (type) {
    case JournalType.Sugar:
      return [
        { max: 10, min: 6.1, color: JournalValueColor.RED },
        { max: 6.1, min: 5.5, color: JournalValueColor.YELLOW },
        { max: 5.5, min: 3.3, color: JournalValueColor.GREEN },
        { max: 3.3, min: 1.5, color: JournalValueColor.RED },
      ];
    case JournalType.Cholesterol:
      return [
        { max: 10, min: 7.6, color: JournalValueColor.RED },
        { max: 7.6, min: 6.4, color: JournalValueColor.YELLOW },
        { max: 6.4, min: 3.5, color: JournalValueColor.GREEN },
        { max: 3.5, min: 1.1, color: JournalValueColor.YELLOW },
        { max: 1.1, min: 0, color: JournalValueColor.RED },
      ];
    case JournalType.Temperature:
      return [
        { max: 42, min: 37.5, color: JournalValueColor.RED },
        { max: 37.5, min: 37, color: JournalValueColor.YELLOW },
        { max: 37, min: 36, color: JournalValueColor.GREEN },
        { max: 36, min: 35, color: JournalValueColor.YELLOW },
        { max: 35, min: 34, color: JournalValueColor.RED },
      ];
    default:
      return null;
  }
};
export const selectJournalAveraged = (state: StateInterface) => {
  const averaged = selectJournal(state)?.averaged;
  const date = selectJournalDate(state);
  const unit = selectJournalTypeUnit(state);
  let formatTime = 'dd';
  let formatter = null;
  switch (date) {
    case JournalFilterType.CUSTOM:
    case JournalFilterType.DAY:
      formatter = startOfHour;
      formatTime = 'HH:mm';
      break;
    case JournalFilterType.WEEK:
      formatter = null;
      formatTime = 'EEEEEE';
      break;
    case JournalFilterType.MONTH:
      formatter = null;
      formatTime = 'dd';
      break;
    case JournalFilterType.YEAR:
      formatter = null;
      formatTime = 'LLL';
      break;
    default:
      break;
  }
  return averaged.map(({ time, values = {} }) => {
    const date = formatter ? formatter(convertUtcToZonedTime(time)) : convertUtcToZonedTime(time);
    let res = { time: format(date, formatTime) };
    unit.forEach((f) => {
      res = { ...res, [f]: Number(values?.[f]) };
    });
    return res;
  });
};
