import { 
  getDoctors,
  getDoctorsDuties,
  getDoctorSpecializations 
} from 'api';
import { DoctorsFilterType } from 'types';

export const DOCTORS_DUTIES_LOADING = 'DOCTORS_DUTIES_LOADING';
export const DOCTORS_DUTIES_ERROR = 'DOCTORS_DUTIES_ERROR';
export const DOCTORS_DUTIES_SUCCESS = 'DOCTORS_DUTIES_SUCCESS';

export const DOCTOR_SPECIALIZATIONS_LOADING = 'DOCTOR_SPECIALIZATIONS_LOADING';
export const DOCTOR_SPECIALIZATIONS_ERROR = 'DOCTOR_SPECIALIZATIONS_ERROR';
export const DOCTOR_SPECIALIZATIONS_SUCCESS = 'DOCTOR_SPECIALIZATIONS_SUCCESS';

export const DOCTORS_LOADING = 'DOCTORS_LOADING';
export const DOCTORS_ERROR = 'DOCTORS_ERROR';
export const DOCTORS_SUCCESS = 'DOCTORS_SUCCESS';

export const SET_DOCTORS_FILTER = 'SET_DOCTORS_FILTER';


export const doctorsDutiesLoading = (isLoading: boolean) => ({ type: DOCTORS_DUTIES_LOADING, isLoading });

export const doctorsDutiesSuccess = (result: any) => {
  let duties = [];

  if (result && result.data && result.data.items) {
    duties = [...result.data.items];
  }

  return { type: DOCTORS_DUTIES_SUCCESS, duties };
};

export const doctorsDutiesError = (isError: boolean) => ({ type: DOCTORS_DUTIES_ERROR, isError });


export const doctorSpecializationsLoading = (isLoading: boolean) => ({ type: DOCTOR_SPECIALIZATIONS_LOADING, isLoading });

export const doctorSpecializationsSuccess = (result: any) => {
  let specializations = [];

  if (result && result.data && result.data.items) {
    specializations = [...result.data.items];
  }

  return { type: DOCTOR_SPECIALIZATIONS_SUCCESS, specializations };
};

export const doctorSpecializationsError = (isError: boolean) => ({ type: DOCTOR_SPECIALIZATIONS_ERROR, isError });


export const doctorsLoading = (isLoading: boolean) => ({ type: DOCTORS_LOADING, isLoading });

export const doctorsSuccess = (result: any, filter: any) => {
  let doctors = [];

  if (result && result.data && result.data.items) {
    doctors = [...result.data.items];
  }

  return { type: DOCTORS_SUCCESS, doctors, filter };
};

export const doctorsError = (isError: boolean) => ({ type: DOCTORS_ERROR, isError });

export const setDoctorsFilter = (filter: any) => ({ type: SET_DOCTORS_FILTER, filter });


export const fetchDoctorsDuties = () => {
  return dispatch => {
    dispatch(doctorsDutiesLoading(true));
    dispatch(doctorsDutiesError(false));

    getDoctorsDuties().then(response => {
      dispatch(doctorsDutiesLoading(false));
      dispatch(doctorsDutiesSuccess(response.data));
    }).catch(error => {
      dispatch(doctorsDutiesLoading(false));
      dispatch(doctorsDutiesError(true));
    });
  };
};

export const fetchDoctorSpecializations = () => {
  return dispatch => {
    dispatch(doctorSpecializationsLoading(true));
    dispatch(doctorSpecializationsError(false));

    getDoctorSpecializations().then(response => {
      dispatch(doctorSpecializationsLoading(false));
      dispatch(doctorSpecializationsSuccess(response.data));
    }).catch(error => {
      dispatch(doctorSpecializationsLoading(false));
      dispatch(doctorSpecializationsError(true));
    });
  };
};

export const fetchDoctors = (filter: any) => {
  return dispatch => {
    dispatch(doctorsLoading(true));
    dispatch(doctorsError(false));

    getDoctors({
      categoryId: filter.categoryId,
      page: filter.page,
      pageSize: filter.pageSize,
      query: filter.query,
      sex: filter.sex,
      specializationId: filter.specializationId
    }).then(response => {
      dispatch(doctorsLoading(false));
      dispatch(doctorsSuccess(response.data, filter));
    }).catch(error => {
      dispatch(doctorsLoading(false));
      dispatch(doctorsError(true));
    });
  };
};

