import React, { useState } from 'react';
import { Backdrop, Box, Dialog, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import styles from './DeleteDialog.module.sass';
import ContentCard from '../ContentCard';
import Button from '../Forms/Button';

interface PropsInterface {
  item: any;
  action: (any) => Promise<any>;
  onDeleted: () => void;
  onClose: () => void;
  title: string;
  positiveButtonLabel?: string;
  message: string;
}

const DeleteDialog = (props: PropsInterface) => {
  const { onClose = () => {}, onDeleted = () => {}, action, item, positiveButtonLabel = 'Удалить', message } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const submit = (): void => {
    setLoading(true);
    action(item)
      .then((response: AxiosResponse<any>) => {
        if (response && response?.data?.code !== 'success') {
          throw new Error();
        }
        return item;
      })
      .then(() => {
        onDeleted();
        setLoading(false);
      })
      .catch((err) => {
        toast.error('Ошибка при запросе');
        setLoading(false);
      });
  };

  return (
    <Dialog
      PaperProps={{ className: classNames(styles.Dialog) }}
      BackdropComponent={(props) => <Backdrop className={styles.Backdrop} {...props} />}
      onClose={onClose}
      open
    >
      <ContentCard className={styles.DeleteDialog}>
        <ContentCard.Header className={styles.DeleteDialog_Header}>
          <h6 className="page-header-title">{props.title}</h6>
          <CloseIcon onClick={onClose} className={styles.DeleteDialog_CloseIcon} />
        </ContentCard.Header>
        <ContentCard.Main className={styles.DeleteDialog_Content}>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              submit();
            }}
          >
            <Grid container direction="column" justify="space-between" alignItems="stretch" spacing={2}>
              <Grid item xs={12}>
                <Box textAlign="center" mt={2} mb={2}>
                  {message}
                </Box>
              </Grid>
              <Grid item xs={12} container justify="space-between" alignItems="center" spacing={1}>
                <Grid item xs={12} lg={4}>
                  <Button className="mr-2" color="default" block onClick={onClose}>
                    Отмена
                  </Button>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Button type="submit" color="primary" isLoading={loading} block>
                    {positiveButtonLabel}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </ContentCard.Main>
      </ContentCard>
    </Dialog>
  );
};
export default DeleteDialog;
