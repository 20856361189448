import axios from 'utils/axios';
import { AxiosResponse } from 'axios';
import { GetPatientsPersonalsResponseInterface, PageSizeFilterRequest, ResponseDataInterface } from '../types';

export const setBankruptApi = (patientId: string): Promise<AxiosResponse<ResponseDataInterface<null>>> =>
  axios.post(`/api/v1/patients/${patientId}/bankrupt`);
export const getPatientsPersonals = (
  params: PageSizeFilterRequest
): Promise<AxiosResponse<ResponseDataInterface<GetPatientsPersonalsResponseInterface>>> =>
  axios.get(`/api/v1/patients/personals`, { params });
