import { createEffect } from 'effector';
import { toast } from 'react-toastify';
import { authPhoneCodesAgent } from '../../../../api';
import { getAgentByPhoneSp, getAgentReport, getCertificate, getCertificateData, postPsp, postUserPsp } from '../api';
import { onAgentReplace, onAgentReset } from './events';
import saveFileInBrowser from '../../../../utils/saveFile';
import printToPdfCertificate from '../../../../utils/printToPdfCertificate';

export const getAgentReportFx = createEffect(async () => {
  return await getAgentReport.call(getAgentReport).then((data) => data?.data);
});
export const getAgentByPhoneFx = createEffect(async (args: any) => {
  const [arg = null, ...rest] = Object.values(args || {});
  return await getAgentByPhoneSp.call(getAgentByPhoneSp, arg, ...rest).catch(() => onAgentReplace(arg));
});
export const getCodeFx = createEffect(async (args: any) => {
  const [arg = null, ...rest] = Object.values(args || {});
  return await authPhoneCodesAgent
    .call(authPhoneCodesAgent, arg, ...rest)
    .then(() => toast.success('Код отправлен на указанный номер'))
    .catch(() => toast.error('Ошибка при запросе'));
});

export const userPspFx = createEffect(async (args: any) => {
  const [arg = null, ...rest] = Object.values(args || {});
  return await postUserPsp
    .call(postUserPsp, arg, ...rest)
    .then((data) => data?.data?.data?.id)
    .then((id) => id && getCertificateData(id).then((data) => printToPdfCertificate(data?.data?.data)))
    .then(() => onAgentReset())
    .then(() => toast.success('Тариф успешно продан'))
    .catch(() => {
      toast.error('Ошибка при запросе');
      return new Error('Ошибка при запросе');
    });
});

export const pspFx = createEffect(async (args: any) => {
  const [arg = null, ...rest] = Object.values(args || {});
  return await postPsp
    .call(postPsp, arg, ...rest)
    .then(() => onAgentReset())
    .then(() => toast.success('Тариф успешно продан'))
    .catch(() => {
      toast.error('Ошибка при запросе');
      return new Error('Ошибка при запросе');
    });
});
