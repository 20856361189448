import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import ContentCard from 'components/ContentCard';
import { Avatar, Box } from '@material-ui/core';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import { formatDistanceStrict, parseISO } from 'date-fns';
import { ru } from 'date-fns/locale';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import classNames from 'classnames';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { RouteComponentProps } from 'react-router';
import styles from './PatientsDoctor.module.sass';
import { fetchMyPatients, fetchPatientAppointments, setPatientAppointmentsLoading } from '../../store/actions';
import ContentLoading from '../../components/ContentLoading';
import { decodeAvatarResource, getDisplayName } from '../../utils';
import Patient from '../../types/patient';
import InputField from '../../components/Forms/InputField';
import Button from '../../components/Forms/Button';
import { format } from '../../utils/date';
import { DoctorsEventStatus } from '../../types';
import Doctor from '../../types/doctor';
import { AppointmentConclusionLinkView } from '../../components/AppointmentCoclusion/AppointmentConclusionLinkView';
import { JournalType } from '../JournalPage/types';

const defaultKey = Object.values(JournalType)?.[0];
const PatientsDoctor = (props: any) => {
  const {
    history,
    patients,
    loading,
    errors,
    page,
    pageSize,
    hasMore,
    fetchPatientAppointments,
    fetchMyPatients,
    setPatientAppointmentsLoading,
    patientAppointments,
    loadingAppointments,
    errorsAppointments,
    pageAppointments,
    pageSizeAppointments,
    hasMoreAppointments,
  } = props;
  const [timeoutSearch, setTimeoutSearch] = useState<any>(null);
  const [timeoutPatient, setTimeoutPatient] = useState<any>(null);
  const [search, onChangeSearch] = useState<string>('');
  const [patient, setPatient] = useState<Patient.Data>(null);
  useEffect(() => {
    if (timeoutSearch) {
      clearTimeout(timeoutSearch);
    }
    setPatient(null);
    if (search && search.length) {
      setTimeoutSearch(
        setTimeout(() => {
          getPatients(true);
        }, 500)
      );
    } else {
      getPatients(true);
    }
    setPatient(null);
    return () => {
      if (timeoutSearch) {
        clearTimeout(timeoutSearch);
      }
    };
  }, [search]);
  useEffect(() => {
    if (timeoutPatient) {
      clearTimeout(timeoutPatient);
    }
    patient &&
      setTimeoutPatient(
        setTimeout(() => {
          getPatientAppointments(true);
        }, 1)
      );
    return () => {
      if (timeoutPatient) {
        clearTimeout(timeoutPatient);
      }
    };
  }, [patient]);
  const getPatients = (reset?: boolean) => {
    // eslint-disable-next-line no-nested-ternary
    fetchMyPatients(search, reset === true ? 0 : reset ? 0 : page + 1, pageSize, reset);
  };
  const getPatientAppointments = (reset?: boolean) => {
    fetchPatientAppointments(
      patient.id,
      // eslint-disable-next-line no-nested-ternary
      reset === true ? 0 : reset ? 0 : pageAppointments + 1,
      pageSizeAppointments,
      reset
    );
  };
  const getLabelStatus = (status: DoctorsEventStatus): string => {
    switch (status) {
      case DoctorsEventStatus.AWAITS:
        return 'Ожидает ответа';
      case DoctorsEventStatus.CANCELED:
        return 'Отменена';
      case DoctorsEventStatus.DONE:
        return 'Завершена';
      case DoctorsEventStatus.IN_PROGRESS:
        return 'Сейчас идет';
      case DoctorsEventStatus.FINISHED:
        return 'Ожидает заключение';
      case DoctorsEventStatus.FAILED:
        return 'Техническая проблема';
      default:
        return null;
    }
  };

  const handleViewPatientJournal = useCallback(
    (event: Event, patientId) => {
      event.preventDefault();
      event.stopPropagation();
      history.push(`/patients/${patientId}/journal/${defaultKey}`);
    },
    [history]
  );

  return (
    <>
      <ContentCard>
        <ContentCard.Header>
          <div className="row">
            <div className="col-12 text-center text-xs-right text-md-center">
              <h6 className="page-header-title">Мои пациенты</h6>
            </div>
          </div>
        </ContentCard.Header>
        <Box className={styles.PatientsDoctor_Toolbar}>
          <Box className={styles.PatientsDoctor_Toolbar_Search}>
            <SearchIcon className={styles.PatientsDoctor_Toolbar_Search_Icon} />
            <InputField
              value={search}
              onChange={(e) => {
                onChangeSearch(e.target.value);
              }}
              block
              placeholder="Введите ФИО пациента"
            />
            {search && (
              <ClearIcon
                onClick={() => {
                  onChangeSearch('');
                }}
                className={classNames(
                  styles.PatientsDoctor_Toolbar_Search_Icon,
                  styles.PatientsDoctor_Toolbar_Search_Icon_Clear
                )}
              />
            )}
          </Box>
        </Box>
        <ContentCard.Main>
          <>
            <Box className={styles.PatientsDoctor_Items}>
              <Box
                pt={2}
                pb={2}
                width="100%"
                display="flex"
                flexDirection="row"
                justifyContent="flex-between"
                alignItems="center"
                className={classNames(styles.PatientsDoctor_Row, styles.PatientsDoctor_Row_Header)}
              >
                <Box className={classNames(styles.PatientsDoctor_Row_Cell, styles.PatientsDoctor_Row_Cell_Header)}>
                  Пациент
                </Box>
                <Box
                  className={classNames(
                    styles.PatientsDoctor_Row_Cell,
                    styles.PatientsDoctor_Row_Cell_Age,
                    styles.PatientsDoctor_Row_Cell_Header
                  )}
                >
                  Возраст
                </Box>
                <Box
                  className={classNames(
                    styles.PatientsDoctor_Row_Cell,
                    styles.PatientsDoctor_Row_Cell_Journal,
                    styles.PatientsDoctor_Row_Cell_Header
                  )}
                >
                  Журнал пациента
                </Box>
                <Box className={classNames(styles.PatientsDoctor_Row_Cell, styles.PatientsDoctor_Row_Cell_Header)}>
                  &nbsp;
                </Box>
              </Box>
              <InfiniteScroll
                className={styles.PatientsDoctor_InfiniteScroll}
                pageStart={0}
                hasMore={loading ? false : hasMore}
                initialLoad={false}
                threshold={pageSize}
                loadMore={() => getPatients()}
                loader={null}
              >
                <>
                  {patients.map((item: any) => (
                    <Box
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        setPatientAppointmentsLoading(true, true);
                        if (patient?.id === item.id) {
                          setPatient(null);
                        } else {
                          setPatient(item);
                        }
                      }}
                      key={item.id}
                      className={classNames(
                        styles.PatientsDoctor_Row,
                        patient?.id === item?.id && styles.PatientsDoctor_Row_Collapsed
                      )}
                    >
                      <Box className={styles.PatientsDoctor_Row_Main}>
                        <Box className={styles.PatientsDoctor_Row_Cell}>
                          <Box className={styles.PatientsDoctor_Profile}>
                            <Avatar
                              className={styles.PatientsDoctor_ProfileImg}
                              src={decodeAvatarResource(item.avatar, 360, 'MALE')}
                            />
                            <Box className={styles.PatientsDoctor_Profile_Name}>
                              {[item.firstName, item.lastName].filter((s) => s).join(' ')}
                            </Box>
                          </Box>
                        </Box>
                        <Box className={classNames(styles.PatientsDoctor_Row_Cell, styles.PatientsDoctor_Row_Cell_Age)}>
                          <Box className={styles.PatientsDoctor_Profile_Age}>
                            {item.birthday &&
                              formatDistanceStrict(parseISO(item.birthday.toString()), new Date(), {
                                locale: ru,
                                addSuffix: false,
                              })}
                          </Box>
                        </Box>
                        <Box
                          className={classNames(styles.PatientsDoctor_Row_Cell, styles.PatientsDoctor_Row_Cell_Journal)}
                        >
                          {item?.shared === true && (
                            <Button
                              className={styles.PatientsDoctor_Button}
                              onClick={(event) => handleViewPatientJournal(event, item?.id)}
                              size="sm"
                              color="default"
                            >
                              Открыть журнал
                            </Button>
                          )}
                        </Box>
                        <Box className={styles.PatientsDoctor_Row_Cell}>
                          <Button
                            className={styles.PatientsDoctor_Button}
                            onClick={(event) => {
                              event.preventDefault();
                              event.stopPropagation();
                              setPatientAppointmentsLoading(true, true);
                              if (patient && patient.id === item.id) {
                                setPatient(null);
                              } else {
                                setPatient(item);
                              }
                            }}
                            size="sm"
                            color="default"
                          >
                            Подробная информация
                            {patient && patient.id === item.id && (
                              <ExpandLessIcon className={styles.PatientsDoctor_Button_Icon} />
                            )}
                            {(!patient || patient.id !== item.id) && (
                              <ExpandMoreIcon className={styles.PatientsDoctor_Button_Icon} />
                            )}
                          </Button>
                        </Box>
                      </Box>
                      {patient && patient.id === item.id && (
                        <Box className={styles.PatientsDoctor_Row_Info}>
                          <Box className={styles.PatientsDoctor_Patient}>
                            <Box component="h6" className={styles.PatientsDoctor_Patient_Header}>
                              История консультаций
                            </Box>
                            <Box
                              pt={2}
                              pb={2}
                              width="100%"
                              display="flex"
                              flexDirection="row"
                              justifyContent="flex-between"
                              alignItems="center"
                              className={classNames(
                                styles.PatientsDoctor_Patient_Appointments_Row,
                                styles.PatientsDoctor_Patient_Appointments_Row_Header
                              )}
                            >
                              <Box
                                className={classNames(
                                  styles.PatientsDoctor_Patient_Appointments_Row_Cell,
                                  styles.PatientsDoctor_Patient_Appointments_Row_Cell_Header
                                )}
                              >
                                Специалист
                              </Box>
                              <Box
                                className={classNames(
                                  styles.PatientsDoctor_Patient_Appointments_Row_Cell,
                                  styles.PatientsDoctor_Patient_Appointments_Row_Cell_Header
                                )}
                              >
                                Заключение
                              </Box>
                              <Box
                                className={classNames(
                                  styles.PatientsDoctor_Patient_Appointments_Row_Cell,
                                  styles.PatientsDoctor_Patient_Appointments_Row_Cell_Header
                                )}
                              >
                                Дата
                              </Box>
                              <Box
                                className={classNames(
                                  styles.PatientsDoctor_Patient_Appointments_Row_Cell,
                                  styles.PatientsDoctor_Patient_Appointments_Row_Cell_Header
                                )}
                              >
                                Статус
                              </Box>
                            </Box>
                            <Box className={styles.PatientsDoctor_Patient_Appointments}>
                              {patientAppointments.map((appointment: Patient.PatientAppointments.Item) => (
                                <Box
                                  onClick={(event) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    history.push(`/appointments/${appointment.id}`);
                                  }}
                                  key={appointment.id}
                                  className={styles.PatientsDoctor_Patient_Appointments_Row}
                                >
                                  <Box className={styles.PatientsDoctor_Patient_Appointments_Row_Cell}>
                                    <Box className={styles.PatientsDoctor_Profile}>
                                      <Avatar
                                        className={styles.PatientsDoctor_ProfileImg}
                                        src={decodeAvatarResource(
                                          appointment.doctor && appointment.doctor.avatar,
                                          360,
                                          'MALE'
                                        )}
                                      />
                                      <Box>
                                        {appointment.doctor &&
                                          getDisplayName(appointment.doctor.firstName, appointment.doctor.lastName)}
                                        {!appointment.doctor && appointment.serviceType.name}
                                      </Box>
                                    </Box>
                                  </Box>
                                  <Box className={styles.PatientsDoctor_Patient_Appointments_Row_Cell}>
                                    <AppointmentConclusionLinkView
                                      url={appointment.conclusionUrl}
                                      numberOfDay={appointment.numberOfDay}
                                      isDaily={appointment.serviceType?.value === 'DAILY'}
                                    />
                                  </Box>
                                  <Box className={styles.PatientsDoctor_Patient_Appointments_Row_Cell}>
                                    <Box>
                                      {format(parseISO(appointment.startTime.toString()), 'dd.MM.yyyy | HH:mm')}
                                    </Box>
                                  </Box>
                                  <Box
                                    className={classNames(
                                      styles.PatientsDoctor_Patient_Appointments_Row_Cell,
                                      styles.PatientsDoctor_Patient_Appointments_Row_Cell_Status,
                                      styles[
                                        `PatientsDoctor_Patient_Appointments_Row_Cell_Status_${
                                          appointment.status.value as DoctorsEventStatus
                                        }`
                                      ]
                                    )}
                                  >
                                    <Box>{getLabelStatus(appointment.status.value as DoctorsEventStatus)}</Box>
                                  </Box>
                                </Box>
                              ))}
                              <Box width="100%" display="flex" justifyContent="center" alignItems="center" p={2}>
                                <ContentLoading
                                  isLoading={loadingAppointments}
                                  isError={errorsAppointments}
                                  fetchData={() => getPatientAppointments()}
                                >
                                  {hasMoreAppointments && (
                                    <Button
                                      size="md"
                                      color="default"
                                      onClick={(event) => {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        getPatientAppointments();
                                      }}
                                    >
                                      Показать еще
                                    </Button>
                                  )}
                                </ContentLoading>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  ))}
                  {(loading || errors) && (
                    <Box width="100%" p={2}>
                      <ContentLoading isLoading={loading} isError={errors} fetchData={() => getPatients()} />
                    </Box>
                  )}
                </>
              </InfiniteScroll>
            </Box>
          </>
        </ContentCard.Main>
      </ContentCard>
    </>
  );
};
export default connect(
  (state: any) => ({
    ...state.doctorPatients,
    doctor: state.doctor,
  }),
  {
    fetchMyPatients,
    fetchPatientAppointments,
    setPatientAppointmentsLoading,
  }
)(PatientsDoctor);
