import React from 'react';
import classNames from 'classnames';
import { ReactComponent as AngleLeftIcon } from 'assets/img/icons/angle-left-icon.svg';
import { ReactComponent as AngleRightIcon } from 'assets/img/icons/angle-right-icon.svg';
import { Calendar, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { isBefore, isSameDay, isSameMonth } from 'date-fns';
import { Box } from '@material-ui/core';
import { ru } from 'date-fns/locale';
import styles from './Calendar.module.sass';
import { format } from '../../utils/date';

export default (props: any) => {
  const { date: pDate, onChangeDate, maxDate = null, className: classes } = props;
  const date: Date = pDate ? new Date(pDate) : new Date();
  const onChange = (value: Date) => {
    onChangeDate(value);
  };
  const isDisabledDay = (day: Date): boolean => {
    return maxDate && isBefore(maxDate, day);
  };
  const renderOfDay = (day: MaterialUiPickersDate): JSX.Element => {
    return (
      <Box
        component="span"
        className={classNames(
          styles.Calendar_Day,
          isSameDay(date, day) && styles.Calendar_Day_Current,
          isSameDay(new Date(), day) && styles.Calendar_Day_Today,
          !isSameMonth(date, day) && styles.Calendar_Day_Future,
          !isSameMonth(date, day) && styles.Calendar_Day_Past,
          isDisabledDay(day) && styles.Calendar_Day_Disabled
        )}
        onClick={() => !isDisabledDay(day) && (pDate && isSameDay(date, day) ? null : onChange(day))}
        width="27px"
        height="27px"
        fontSize="13px"
        lineHeight="27px"
        display="inline-block"
        m="2px"
        textAlign="center"
      >
        {format(day, 'd')}
      </Box>
    );
  };
  let other = {};
  if (maxDate) {
    other = { ...other, maxDate };
  }
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
      <Box className={classNames(styles.Calendar, 'Calendar', classes)}>
        <Calendar
          leftArrowIcon={<AngleLeftIcon className={styles.Calendar_Arrow_Icon} />}
          rightArrowIcon={<AngleRightIcon className={styles.Calendar_Arrow_Icon} />}
          date={date}
          onChange={() => {}}
          disablePast={false}
          disableFuture={false}
          shouldDisableDate={() => true}
          renderDay={(...args) => renderOfDay.call(null, ...args)}
          {...other}
        />
      </Box>
    </MuiPickersUtilsProvider>
  );
};
