import React, { ReactElement, useMemo } from 'react';
import { Box } from '@material-ui/core';
import { useStore } from 'effector-react';
import styles from './styles.module.sass';
import store from '../../store';

const AgentMetricsToolbar = (): ReactElement => {
  const { metrics } = useStore(store);
  const allCount = useMemo(
    () => (metrics?.agentDashboardData || []).map(({ count }) => count || 0).reduce((acc, count) => acc + count, 0),
    [metrics?.agentDashboardData]
  );
  const allPrice = useMemo(
    () => (metrics?.agentDashboardData || []).map(({ price }) => price || 0).reduce((acc, price) => acc + price, 0),
    [metrics?.agentDashboardData]
  );
  return (
    <>
      <Box className={styles.root}>
        <Box className={styles.root_item}>Итого:</Box>
        <Box className={styles.root_item}>{metrics ? 'Все тарифы' : '-'}</Box>
        <Box className={styles.root_item}>{allCount || 0} шт.</Box>
        <Box className={styles.root_item}>{allPrice} ₽</Box>
        <Box className={styles.root_item}>{metrics?.allSum || 0} ₽</Box>
      </Box>
    </>
  );
};

export default AgentMetricsToolbar;
