import { TOGGLE_SETTING, CHANGE_SETTING } from '../actions';

const initialSettings = {
  isFullscreen: false,
  newAppointment: {
    appointmentId: null,
    doctor: null,
    date: null,
    price: null,
    type: null,
    specialization: null,
    paymentViewType: 'PAYMENT',
    estimatedStartTime: null,
    withFiles: false,
  }
};

const storageReducer = (state = initialSettings, action) => {
  switch (action.type) {
    case TOGGLE_SETTING:
      return {
        ...state,
        [action.name]: !state[action.name]
      };

    case CHANGE_SETTING:
      return {
        ...state,
        [action.name]: action.value
      };

    default:
      return state;
  }
};

export default storageReducer;
