import React, { useCallback, useEffect } from 'react';
import { Router } from 'react-router-dom';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { createBrowserHistory } from 'history';

import { authCheck } from 'store/actions';
import { hot } from 'react-hot-loader';
import Routes from './Routes';
import FullPageLoader from './components/FullPageLoader';

import './assets/sass/styles.sass';
import AnalyticsProvider from './providers/AnalyticsProvider';

const history = createBrowserHistory();
const account_id: number = Number(process.env?.REACT_APP_YA_ACCOUNT_ID || 92660232);
const App = () => {
  const isAuthChecking = useSelector((state: RootStateOrAny) => state.auth.isAuthChecking);
  const dispatch = useDispatch();
  const handleAuthCheck = useCallback(() => dispatch(authCheck()), [dispatch]);
  useEffect(() => {
    handleAuthCheck();
  }, [handleAuthCheck]);

  return (
    <Router history={history}>
      <AnalyticsProvider accountId={account_id}>{isAuthChecking ? <FullPageLoader /> : <Routes />}</AnalyticsProvider>
    </Router>
  );
};
export default process.env.NODE_ENV === 'development' ? hot(module)(App) : App;
