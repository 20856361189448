import React, {useEffect, useState} from 'react';

import styles from './FileLink.module.sass';
import getFileIcon from 'utils/getFileIcon';

const FileLink = ({resource}) => {


  return (
    <a
      className={styles.link}
      target="_blank" href={resource?.originalUrl}>
      {
        resource?.contentType?.startsWith("image") < 0 ?
          <img
            src={getFileIcon(resource?.fileName || '.doc')} // eslint-disable-line
            alt="файл"
          /> : <></>
      }
      <span className={styles.link_name}>
        {resource.fileName}
      </span>

    </a>
  );
};

export default FileLink;
