import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { authInvitePasswordLogin, checkRegistrationCode } from 'store/actions';
import styles from './DoctorInvitePage.module.sass';
import InputField from '../../components/Forms/InputField';
import Button from '../../components/Forms/Button';
import { getFieldErrors as getFieldErrorsUtil, hasFieldError, validateFieldOnChange } from '../../utils';
import FormValidator from '../../components/Forms/FormValidator';
import ContentLoading from '../../components/ContentLoading';

const DoctorInvitePage = (props: any) => {
  useEffect(() => {
    props.checkRegistrationCode(props.match.params.code, props.history);
  }, []);
  const [data, setData] = useState({ password: '', passwordConfirm: '' });
  const [formErrors, setFormErrors] = useState([]);

  const hasError = (field: string, code?: string) => hasFieldError(formErrors, field, code);

  const getFieldErrors = (field: string) => getFieldErrorsUtil(field, formErrors);

  const confirmRegistrationSubmit = (e: any) => {
    e.preventDefault();

    const form = e.target;
    const inputs = [...form.elements].filter((i) => ['INPUT', 'SELECT', 'TEXTAREA'].includes(i.nodeName));

    const { errors, hasError } = FormValidator.bulkValidate(inputs);

    setFormErrors([...errors]);

    if (!hasError) {
      props.authInvitePasswordLogin(props.match.params.code, data.password, props.history, props.location);
    }
  };

  const validateOnChange = (name: string, value: any, event, element?) => {
    validateFieldOnChange(name, value, event, data, setData, formErrors, setFormErrors, element);
  };

  return (
    <>
      <ContentLoading isLoading={props.inviteLoading} isError={false} fetchData={() => {}}>
        <div className={styles.DoctorInvitePage}>
          <h3 className={styles.DoctorInvitePage_title}>Создайте Ваш пароль</h3>
          <p className={styles.DoctorInvitePage_title_sub}>
            Пароль должен содержать цифры, заглавные и строчные буквы, и быть не менее 8 символов
          </p>
          <form onSubmit={(e) => confirmRegistrationSubmit(e)} className={styles.DoctorInvitePage_form}>
            <InputField
              id="password"
              name="password"
              type="password"
              autocomplate="current-password"
              data-validate='["required", "password"]'
              errors={getFieldErrors('password')}
              value={data.password}
              onChange={(e) => validateOnChange('password', e.target.value, e)}
              placeholder="Введите пароль"
              block
            />

            <InputField
              id="passwordConfirm"
              name="passwordConfirm"
              className="mt-3"
              type="password"
              data-param="password"
              data-validate='["required", "equalto"]'
              autocomplate="current-password"
              errors={getFieldErrors('passwordConfirm')}
              value={data.passwordConfirm}
              onChange={(e) => validateOnChange('passwordConfirm', e.target.value, e)}
              placeholder="Введите пароль повторно"
              block
            />

            <Button className="mt-4" type="submit" block color="primary" isLoading={props.loginLoading}>
              Сохранить
            </Button>
          </form>
        </div>
      </ContentLoading>
    </>
  );
};

export default connect(
  (state: any) => ({
    inviteLoading: state.auth.inviteLoading,
    loginLoading: state.auth.loginLoading,
  }),
  { authInvitePasswordLogin, checkRegistrationCode }
)(DoctorInvitePage);
