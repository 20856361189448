import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { fetchDoctorSpecializations, setDoctorsFilter, setTabState, getTabState } from 'store/actions';

import ContentCard from 'components/ContentCard';
import Button from 'components/Forms/Button';
import InputField from 'components/Forms/InputField';
import ContentLoading from 'components/ContentLoading';

import { ReactComponent as FiltersIcon } from 'assets/img/icons/filters-icon.svg';
import styles from './HomePage.module.sass';
import DoctorsList from './DoctorsList';
import DoctorsDuties from './DoctorsDuties';

const HomePage = (props: any) => {
  const { home } = getTabState();
  const [doctorsViewType, setDoctorsViewType] = useState(home || 'DOCTORS');
  const [doctorsSearchQuery, setDoctorsSearchQuery] = useState('');

  const {
    doctorsFilter,
    setDoctorsFilter,

    doctorSpecializations,
    doctorSpecializationsLoadingError,

    fetchDoctorSpecializations,
  } = props;

  useEffect(() => {
    props.fetchDoctorSpecializations();
  }, []);

  useEffect(() => {
    !home && setTabState({ home: doctorsViewType });
  }, [doctorsViewType]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      props.setDoctorsFilter({
        ...doctorsFilter,
        query: doctorsSearchQuery,
        page: 0,
        hasMore: true,
        updateLoad: true,
        initialLoad: false,
      });
    }, 500);
    return () => {
      clearTimeout(timeout);
    };
  }, [doctorsSearchQuery]);

  const getDoctorsView = (doctorsViewType: string) => {
    let doctorsView = null;

    switch (doctorsViewType) {
      case 'DOCTORS':
        doctorsView = <DoctorsList />;
        break;
      case 'DUTIES':
        doctorsView = <DoctorsDuties history={props.history} />;
        break;
      default:
        doctorsView = <></>;
        break;
    }

    return doctorsView;
  };

  return (
    <>
      <ContentCard className="mb-3 mb-md-4">
        <ContentCard.Header className={styles.doctorsFilterCardHeader}>
          <div className={`page-header-menu ${styles.contentCard_menu}`}>
            <div
              className={classNames('page-header-menu-item', styles.contentCard_menu_item, {
                'page-header-menu-item-active': doctorsViewType === 'DOCTORS',
              })}
              onClick={(e) => {
                setTabState({ home: 'DOCTORS' });
                setDoctorsViewType('DOCTORS');
              }}
            >
              Консультация узкого специалиста
            </div>

            <div
              className={classNames('page-header-menu-item', styles.contentCard_menu_item, {
                'page-header-menu-item-active': doctorsViewType === 'DUTIES',
              })}
              onClick={(e) => {
                setTabState({ home: 'DUTIES' });
                setDoctorsViewType('DUTIES');
              }}
            >
              Консультация дежурного врача
            </div>
          </div>
        </ContentCard.Header>

        {doctorsViewType === 'DOCTORS' && (
          <ContentCard.Main className={styles.doctorsFilterCardMain}>
            <div className={styles.doctorsFilterSearchForm}>
              <InputField
                className={styles.doctorsFilterSearchForm_input}
                value={doctorsSearchQuery}
                onChange={(e) => {
                  setDoctorsSearchQuery(e.target.value);
                }}
                searchBar
                block
                placeholder="Введите ФИО или специализацию врача"
              />
            </div>

            <ContentLoading
              isLoading={false}
              isError={doctorSpecializationsLoadingError}
              fetchData={() => fetchDoctorSpecializations()}
            >
              <div className={styles.doctorsFilterTypesList}>
                <div
                  id={styles.filterTypesIconItem}
                  className={styles.doctorsFilterTypesList_item}
                  onClick={(e) => {
                    setDoctorsFilter({
                      ...doctorsFilter,
                      specializationId: null,
                      page: 0,
                      hasMore: true,
                      updateLoad: true,
                    });
                  }}
                >
                  <Button size="md" color="primary">
                    <FiltersIcon />
                  </Button>
                </div>

                {doctorSpecializations.map((spec) => (
                  <div
                    key={spec.id}
                    className={classNames(styles.doctorsFilterTypesList_item, {
                      [styles.doctorsFilterTypesList_item_active]: doctorsFilter.specializationId === spec.id,
                    })}
                    onClick={(e) => {
                      setDoctorsFilter({
                        ...doctorsFilter,
                        specializationId: spec.id,
                        page: 0,
                        hasMore: true,
                        updateLoad: true,
                      });
                    }}
                  >
                    <Button size="md" color={doctorsFilter.specializationId === spec.id ? 'primary' : 'default'}>
                      {spec.name}
                    </Button>
                  </div>
                ))}
              </div>
            </ContentLoading>
          </ContentCard.Main>
        )}
      </ContentCard>
      {getDoctorsView(doctorsViewType)}
    </>
  );
};

export default connect(
  (state: any) => ({
    doctorsFilter: state.doctors.doctorsFilter,

    doctorSpecializations: state.doctors.doctorSpecializations,
    doctorSpecializationsLoading: state.doctors.doctorSpecializationsLoading,
    doctorSpecializationsLoadingError: state.doctors.doctorSpecializationsLoadingError,
  }),
  { fetchDoctorSpecializations, setDoctorsFilter }
)(HomePage);
