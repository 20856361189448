import React, { MouseEvent, PropsWithChildren, useCallback } from 'react';

import classNames from 'classnames';
import { Box } from '@material-ui/core';
import { BoxProps } from '@material-ui/core/Box/Box';
import { useHistory } from 'react-router-dom';
import styles from './LinkBack.module.sass';
import { ReactComponent as AngleLeftIcon } from '../../assets/img/icons/angle-left-icon.svg';

interface PropsInterface extends PropsWithChildren<BoxProps> {
  className?: string;
  link?: string;
}

const LinkBack = (props: PropsInterface) => {
  const { className, children = 'Назад', link = null, ...rest } = props;
  const history = useHistory();
  const goBack = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.preventDefault();
      event.stopPropagation();
      link ? history.push(link) : history.goBack();
    },
    [history]
  );
  return (
    <Box className={classNames(styles.root, className)} onClick={goBack} {...rest}>
      <AngleLeftIcon className={styles.root_icon} />
      {children}
    </Box>
  );
};

export default LinkBack;
