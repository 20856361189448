import React, { useEffect, useState } from 'react';
import ContentCard from 'components/ContentCard';
import { Avatar, Box } from '@material-ui/core';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import classNames from 'classnames';
import EventIcon from '@material-ui/icons/Event';
import { fetchManagePatients } from 'store/actions';
import ContentLoading from 'components/ContentLoading';
import { decodeAvatarResource } from 'utils';
import InputField from 'components/Forms/InputField';
import Button from 'components/Forms/Button';
import Manage from 'types/manage';
import DropdownToggle from 'components/DropdownMenu/DropdownToggle';
import DropdownMenu from 'components/DropdownMenu';
import DropdownContent from 'components/DropdownMenu/DropdownContent';
import CalendarRange from 'components/CalendarRange';
import AdminPatientView from 'components/AdminPatientView';
import AdminPatientAppointmentView from 'components/AdminPatientAppointmentView';
import styles from './PatientsSupport.module.sass';

const PatientsSupport = (props: any) => {
  const { patients = [], loading, errors, page, pageSize, hasMore, fetchManagePatients } = props;
  const [timeoutSearch, setTimeoutSearch] = useState<NodeJS.Timeout>(null);
  const [search, onChangeSearch] = useState<string>('');
  const [date, onChangeDate] = useState<{ start: Date; end: Date }>({ start: null, end: null });
  const [isMFO, onChangeMFO] = useState<boolean | null>(null);
  const [toolbarOpen, toggleToolbarOpen] = useState<boolean>(false);
  const [patient, setPatient] = useState<Manage.Patient.Item>(null);
  const [appointment, setAppointment] = useState<Manage.Patient.Appointments.Item>(null);
  useEffect(() => {
    if (timeoutSearch) {
      clearTimeout(timeoutSearch);
    }
    setPatient(null);
    setTimeoutSearch(
      setTimeout(() => {
        getPatients(true);
      }, 500)
    );
    return () => {
      if (timeoutSearch) {
        clearTimeout(timeoutSearch);
      }
    };
  }, [search, date, isMFO]);
  const getPatients = (reset?: boolean) => {
    fetchManagePatients(search, date.start, date.end, isMFO, reset ? 0 : reset ? 0 : page + 1, pageSize, reset);
  };
  return (
    <>
      <ContentCard>
        <ContentCard.Header>
          <div className="row">
            <div className="col-12 text-center text-xs-right text-md-center">
              <h6 className="page-header-title">Пациенты</h6>
            </div>
          </div>
        </ContentCard.Header>
        <Box className={styles.PatientsSupport_Toolbar}>
          <Box className={styles.PatientsSupport_Toolbar_Search}>
            <SearchIcon className={styles.PatientsSupport_Toolbar_Search_Icon} />
            <InputField
              value={search}
              onChange={(e) => {
                onChangeSearch(e.target.value);
              }}
              block
              placeholder="Введите ФИО пациента"
            />
            {search && (
              <ClearIcon
                onClick={() => {
                  onChangeSearch('');
                }}
                className={classNames(
                  styles.PatientsSupport_Toolbar_Search_Icon,
                  styles.PatientsSupport_Toolbar_Search_Icon_Clear
                )}
              />
            )}
          </Box>
          <Box className={styles.PatientsSupport_Toolbar_Buttons}>
            <Button
              color={isMFO ? 'primary' : 'default'}
              size="md"
              onClick={() => {
                const mfoFilter = isMFO ? null : true;
                onChangeMFO(mfoFilter);
              }}
            >
              МФО
            </Button>
            <DropdownMenu isOpen={toolbarOpen} toggle={() => toggleToolbarOpen(!toolbarOpen)}>
              <DropdownToggle>
                <Button color="primary" size="md">
                  <EventIcon />
                  {(date.start || date.end) && <Box className={styles.PatientsSupport_Toolbar_Buttons_Status} />}
                </Button>
              </DropdownToggle>
              <DropdownContent className={styles.PatientsSupport_Toolbar_DropdownContent}>
                <CalendarRange date={date} onChangeDate={(date: { start: Date; end: Date }) => onChangeDate(date)} />
                {(date.start || date.end) && (
                  <Box className={styles.PatientsSupport_Toolbar_DropdownContent_Reset}>
                    <Button
                      color="default"
                      block
                      size="md"
                      onClick={(event) => {
                        event.preventDefault();
                        onChangeDate({ start: null, end: null });
                      }}
                    >
                      Сбросить фильтр
                    </Button>
                  </Box>
                )}
              </DropdownContent>
            </DropdownMenu>
          </Box>
        </Box>
        <ContentCard.Main>
          <>
            <Box
              p={0}
              width="100%"
              height="100%"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <InfiniteScroll
                className={styles.PatientsSupport_InfiniteScroll}
                pageStart={0}
                hasMore={loading ? false : hasMore}
                initialLoad={false}
                threshold={pageSize}
                loadMore={() => getPatients()}
                loader={null}
              >
                <>
                  {patients.map((item: any, index: number) => (
                    <Box key={index} className={styles.PatientsSupport_Row}>
                      <Box className={styles.PatientsSupport_Row_Main}>
                        <Box className={styles.PatientsSupport_Row_Cell}>
                          <Avatar
                            className={styles.PatientsSupport_ProfileImg}
                            src={decodeAvatarResource(item.avatar, 360, 'MALE')}
                          />
                          <Box className={styles.PatientsSupport_Profile}>
                            <Box
                              className={classNames(
                                styles.PatientsSupport_Profile_Item,
                                styles.PatientsSupport_Profile_Name
                              )}
                            >
                              {[item.lastName, item.firstName, item.middleName].filter((s) => s).join(' ')}
                            </Box>
                            {item.phone && (
                              <Box className={classNames(styles.PatientsSupport_Profile_Item)}>{item.phone}</Box>
                            )}
                            {item.email && (
                              <Box className={classNames(styles.PatientsSupport_Profile_Item)}>{item.email}</Box>
                            )}

                            <Box mt={2} className={classNames(styles.PatientsSupport_Profile_Item)}>
                              Клиент МФО: {item?.external === true ? 'да' : 'нет'}
                            </Box>
                            <Box mt={1} mb={2} className={classNames(styles.PatientsSupport_Profile_Item)}>
                              Банкрот: {item?.status === 'BANKRUPT' ? 'да' : 'нет'}
                            </Box>
                          </Box>
                        </Box>
                        <Box className={styles.PatientsSupport_Row_Cell}>
                          <Box display="flex" flexDirection="column">
                            <Button
                              className={styles.PatientsSupport_Button}
                              onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                if (patient && patient.id === item.id) {
                                  setPatient(null);
                                } else {
                                  setPatient(item);
                                }
                              }}
                              size="sm"
                              color="default"
                            >
                              Подробная информация
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  ))}
                  {(loading || errors) && (
                    <Box width="100%" p={2}>
                      <ContentLoading isLoading={loading} isError={errors} fetchData={() => getPatients()} />
                    </Box>
                  )}
                </>
              </InfiniteScroll>
            </Box>
          </>
        </ContentCard.Main>
        {patient && <AdminPatientView patientId={patient.id} onClose={() => setPatient(null)} />}
        {appointment && <AdminPatientAppointmentView appointment={appointment} onClose={() => setAppointment(null)} />}
      </ContentCard>
    </>
  );
};
export default connect(
  (state: any) => ({
    ...state.managePatients,
  }),
  {
    fetchManagePatients,
  }
)(PatientsSupport);
