import React, { useEffect } from 'react';
import ContentCard from 'components/ContentCard';
import classNames from 'classnames';
import { connect } from 'react-redux';
import ModalImage from 'react-modal-image';
import ContentLoading from '../../components/ContentLoading';
import { decodeAvatarResource } from '../../utils';
import { ReactComponent as StarFillIcon } from '../../assets/img/icons/star-fill-icon.svg';
import styles from './ProfileDoctor.module.sass';
import { fetchDoctorCerts, fetchDoctorProfile } from '../../store/actions';
import Doctor from '../../types/doctor';
import StarRating from '../../components/StarRating';

const ProfileDoctor = (props: any) => {
  const { fetchDoctorCerts, fetchDoctorProfile, doctor, doctorCertsList, doctorCertsLoading, doctorCertsLoadingError } =
    props;

  useEffect(() => {
    const { id } = doctor as Doctor.ProfileMetaInfo.Data;
    fetchDoctorProfile();
    fetchDoctorCerts(id);
  }, []);

  const stars = [];

  const getProfile = (): Doctor.Profile => {
    return doctor.profile || {};
  };

  return (
    <>
      <ContentCard>
        <ContentCard.Header>
          <div className="row">
            <div className="col-12 text-center text-xs-right text-md-center">
              <h6 className="page-header-title">Профиль</h6>
            </div>
          </div>
        </ContentCard.Header>
        <ContentCard.Main>
          <ContentLoading
            isLoading={doctor.loadingProfile}
            isError={doctor.loadingErrors}
            fetchData={() => fetchDoctorProfile()}
          >
            <div className={styles.doctorDetailsInfo}>
              <div
                className={styles.doctorDetailsInfo_avatar}
                style={{ backgroundImage: `url(${decodeAvatarResource(getProfile().avatar, 360)})` }}
              />
              <div className={styles.doctorDetailsInfo_name}>
                {getProfile().lastName} {getProfile().firstName} {getProfile().middleName}
              </div>
              <div className={styles.doctorDetailsInfo_position}>
                {getProfile().specialization && getProfile().specialization.name}
              </div>

              <div className={`${styles.doctorDetailsInfo_rate} rate-stars-list`}>
                <StarRating rating={doctor?.profile?.rating} />
              </div>
            </div>
            <div className={styles.doctorDetailsInfo_About}>
              <div className={classNames(styles.doctorDetailsInfo_Block)}>
                <h6>О враче</h6>

                <p>{getProfile().description}</p>
              </div>
              <div className={classNames(styles.doctorDetailsInfo_Block)}>
                <h6>Дополнительная информация</h6>

                <p>{getProfile().additionalInfo}</p>
              </div>
              <div className={classNames(styles.doctorDetailsInfo_Block)}>
                <h6>Образование</h6>

                <p>{getProfile().education}</p>
              </div>

              <div className={classNames(styles.doctorDetailsInfo_Block)}>
                <h6>Специализация</h6>

                <ul className="raw-list">
                  <li>- {getProfile().specialization ? getProfile().specialization.name : ''}</li>
                </ul>
              </div>
              <div className={classNames(styles.doctorDetailsInfo_Block)}>
                <h6>Дополнительные услуги</h6>

                <ul className="raw-list">
                  {getProfile().additionalServiceTypes?.map((value) => {
                    return <li>- {value.name}</li>;
                  })}
                </ul>
              </div>
              <div className={styles.doctorDetailsInfo_Block}>
                <h6>Дипломы и сертификаты</h6>

                <ContentLoading
                  isLoading={doctorCertsLoading}
                  isError={doctorCertsLoadingError}
                  fetchData={() => fetchDoctorCerts(doctor.id)}
                >
                  {doctorCertsList.length > 0 ? (
                    <div className={styles.doctorDetailsInfo_Block_Certs}>
                      {doctorCertsList.map((cert) => (
                        <div key={cert.id} className={styles.doctorDetailsInfo_Block_Certs_Item}>
                          <div
                            className={styles.doctorCertWrapper}
                            style={{ backgroundImage: `url(${cert.originalUrl})` }}
                          >
                            <ModalImage
                              className={styles.doctorCert}
                              small={cert.originalUrl}
                              large={cert.originalUrl}
                              hideZoom
                              hideDownload
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="text-center">Не найдено</div>
                  )}
                </ContentLoading>
              </div>
              <div className={styles.doctorDetailsInfo_Block}>
                <h6>Подпись</h6>
                {doctor?.profile?.signature ? (
                  <div key={doctor.profile.signature.id} className="col-md-4 mb-3 mb-md-0 text-center">
                    <div
                      className={styles.doctorCertWrapper}
                      style={{ backgroundImage: `url(${doctor.profile.signature.originalUrl})` }}
                    >
                      <ModalImage
                        className={styles.doctorCert}
                        small={doctor.profile.signature.originalUrl}
                        large={doctor.profile.signature.originalUrl}
                        hideZoom
                        hideDownload
                      />
                    </div>
                  </div>
                ) : (
                  <div className="text-center">Не предоставлена</div>
                )}
              </div>
            </div>
          </ContentLoading>
        </ContentCard.Main>
      </ContentCard>
    </>
  );
};

export default connect(
  (state: any) => ({
    doctor: state.doctor,
    doctorCertsList: state.doctorDetails.doctorCertsList,
    doctorCertsLoading: state.doctorDetails.doctorCertsLoading,
    doctorCertsLoadingError: state.doctorDetails.doctorCertsLoadingError,
  }),
  {
    fetchDoctorProfile,
    fetchDoctorCerts,
  }
)(ProfileDoctor);
