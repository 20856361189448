import {AxiosResponse} from 'axios';
import {getFirebaseToken} from '../../api';
import {myFirebase, myFirestore} from '../../utils/firebase';
import Chat from '../../types/chat';
import {createResource} from '../../api/createResource';
import {convertToChatMessage} from "../../utils";

export const CHAT_SET = 'CHAT_SET';
export const CHAT_LOADING = 'CHAT_LOADING';
export const CHAT_ERRORS = 'CHAT_ERRORS';
export const CHAT_DESTROY = 'DESTROY_CHAT';
export const CHAT_MESSAGES_SUCCESS = 'CHAT_MESSAGES_SUCCESS';
export const CHAT_MESSAGES_ERRORS = 'CHAT_MESSAGES_ERRORS';
export const CHAT_MESSAGES_LOADING = 'CHAT_MESSAGES_LOADING';
export const CHAT_MESSAGE_MODIFIED = 'CHAT_ADD_MESSAGE_MODIFIED';
export const CHAT_ADD_MESSAGE_SUCCESS = 'CHAT_ADD_MESSAGE_SUCCESS';
export const CHAT_ADD_MESSAGE_ERRORS = 'CHAT_ADD_MESSAGE_ERRORS';
export const CHAT_ADD_MESSAGE_LOADING = 'CHAT_ADD_MESSAGE_LOADING';
export const setChat = (
  token: string,
  from: string,
  to: string,
  appointmentId: string,
  collection: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>
) => ({type: CHAT_SET, token, from, to, appointmentId, collection});
export const removeChat = () => ({type: CHAT_DESTROY});
export const setChatLoading = (loading: boolean) => ({type: CHAT_LOADING, loading});
export const setChatErrors = (errors: any) => ({type: CHAT_ERRORS, errors});

export const setChatMessages = (messages: Chat.Message[], offset: any) => ({
  type: CHAT_MESSAGES_SUCCESS,
  messages,
  offset,
});
export const setChatMessagesLoading = (loading: boolean) => ({type: CHAT_MESSAGES_LOADING, loading});
export const setChatMessagesErrors = (errors: any) => ({type: CHAT_MESSAGES_ERRORS, errors});

export const addChatMessage = (message: Chat.Message) => ({
  type: CHAT_ADD_MESSAGE_SUCCESS,
  message,
});
export const modifiedChatMessage = (message: Chat.Message) => (dispatch) => dispatch({
  type: CHAT_MESSAGE_MODIFIED,
  message
})
export const setChatAddMessageLoading = (loading: boolean) => ({type: CHAT_ADD_MESSAGE_ERRORS, loading});
export const setChatAddMessageErrors = (errors: any) => ({type: CHAT_ADD_MESSAGE_LOADING, errors});

export const initChat = (from: string, to: string, appointmentId: string) => {
  return (dispatch) => {
    dispatch(setChatLoading(true));
    getFirebaseToken().then(
      (response: AxiosResponse) => {
        if (response.data.code === 'success' && response.data.data && response.data.data.token) {
          const token: string = response.data.data.token;
          myFirebase
            .auth()
            .signInWithCustomToken(token)
            .then(
              () => {
                const collection: firebase.firestore.CollectionReference = myFirestore
                  .collection('rooms')
                  .doc(appointmentId)
                  .collection('messages');
                dispatch(setChat(token, from, to, appointmentId, collection));
                dispatch(setChatLoading(false));
              },
              (errors) => {
                dispatch(setChatLoading(false));
                dispatch(setChatErrors(errors));
              }
            );
        } else {
          dispatch(setChatLoading(false));
          dispatch(setChatErrors(response.data));
        }
      },
      (errors) => {
        dispatch(setChatLoading(false));
        dispatch(setChatErrors(errors));
      }
    );
  };
};

export const destroyChat = () => {
  return (dispatch) => {
    myFirebase
      .auth()
      .signOut()
      .finally(() => {
        dispatch(removeChat());
      });
  };
};

export const getMessages = (collection: firebase.firestore.CollectionReference, offset?: any, pageSize?: number) => {
  return (dispatch) => {
    dispatch(setChatMessagesLoading(true));
    let request = collection.orderBy('timestamp', 'desc');
    if (offset) {
      request = request.startAfter(offset);
    }
    request
      .limit(pageSize || 50)
      .get()
      .then(
        (response) => {
          dispatch(setChatMessagesLoading(false));
          const messages = response.docs.map((doc) => doc.data());
          dispatch(
            setChatMessages(
              messages
                .map((message: any) => {
                  return convertToChatMessage(message)
                })
                .reverse(),
              messages.length ? response.docs[messages.length - 1] : offset
            )
          );
        },
        (errors) => {
          dispatch(setChatMessagesLoading(false));
          dispatch(setChatMessagesErrors(errors));
        }
      );
  };
};

export const addIncomingMessage = (message: Chat.Message) => {
  return (dispatch, getState) => {
    const state = getState()
    const {messages} = state.chat
    if (!messages.find((item) => item.id === message.id)) {
      dispatch(addChatMessage(message))
    } else {
      dispatch(modifiedChatMessage(message))
    }
  }
};

export const addMessage = (
  collection: firebase.firestore.CollectionReference,
  authorId: string,
  roomId: string,
  message?: string,
  file?: File
) => {
  return (dispatch) => {
    dispatch(setChatAddMessageLoading(true));
    const doc: any = collection.doc();
    console.log(myFirebase.firestore.FieldValue.serverTimestamp());
    let mgs: any = {
      authorId,
      id: doc.id,
      readed: false,
      roomId,
      text: message,
      timestamp: myFirebase.firestore.FieldValue.serverTimestamp(),
      type: 'text',
    };
    (file ? createResource(file) : new Promise((resolve) => resolve(null)))
      .then((resourceId: string) => {
        if (resourceId) {
          mgs = {
            ...mgs,
            resourceIds: [resourceId],
            type: file.type === 'application/pdf' ? 'file' : 'image',
          };
        }
        dispatch(setChatAddMessageLoading(false));
        return doc.set(mgs)
      })
      .catch((errors) => {
        dispatch(setChatAddMessageLoading(false));
        dispatch(setChatAddMessageErrors(errors));
      });
  };
};
