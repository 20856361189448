import React from 'react';
import { Box } from '@material-ui/core';
import useRootSelector from 'hooks/useRootSelector';
import classNames from 'classnames';
import styles from './CheckerSidebar.module.sass';
import { selectCheckerStepTotalValue } from '../../store/selectors';

const CheckerSidebar = (): JSX.Element => {
  const stepValue = useRootSelector(selectCheckerStepTotalValue);
  return (
    <>
      <Box className={styles.root}>
        <Box className={classNames(styles.root_inner)} width={`${stepValue}%`} />
      </Box>
    </>
  );
};
export default CheckerSidebar;
