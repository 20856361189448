import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import classNames from 'classnames';
import { connect } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import styles from './ServicePackagesAdminMfo.module.sass';
import Button from '../../../../components/Forms/Button';
import { fetchManageServicePackagesMfo, updateManageServicePackagesMfo } from '../../../../store/actions';
import Manage from '../../../../types/manage';
import ContentLoading from '../../../../components/ContentLoading';
import InputField from '../../../../components/Forms/InputField';
import { ManageServicePackagesMfoInitialSettings } from '../../../../store/reducers/manageServicePackagesMfoReducer.reducer';
import AdminServicePackageView from '../../../../components/AdminServicePackageView';
import AdminMfoEdit from '../../../../components/AdminMfoEdit';
import AdminMfoSend from '../../../../components/AdminMfoSend';
import { updateServicePackageStatus } from '../../../../api/updateServicePackageStatus';
import ServicePackageGroupSelector from '../ServicePackagesAdminGroups/GroupSelector/ServicePackageGroupSelector';
import { getServicePackageAttachments } from '../../../../api/servicePackage/getServicePackageAttachments';
import AdminMfoTariffDocEdit from '../../../../components/AdminMfoTariffDocEdit';
import { deleteServicePackageAttachment } from '../../../../api/servicePackage/deleteServicePackageAttachment';
import CategoryBenefitsSelect from '../../../../components/CategoryBenefitsSelect';
import AdminServicePackageLimitEdit from '../../../../components/AdminServicePackageLimits';
import AdminMarketingInfoDialog from '../../../../components/AdminMarketingInfo';
import useIsAdminRole from '../../../../hooks/useIsAdminRole';
import HasRole from '../../../../components/HasRole';
import { UserRole } from '../../../../types';
import ServicePackagesDocuments from '../ServicePackagesDocuments';

interface PropsInterface {
  loading: boolean;
  items: Manage.ServicePackages.Mfo.Item[];
  fetchManageServicePackagesMfo: (query?: string, group?: string, status?: string, categoryBenefits?: string) => void;
  updateManageServicePackagesMfo: (item: Manage.ServicePackages.Mfo.Item) => void;
  errors: any;
}

const ServicePackagesAdminMfo = (props: PropsInterface) => {
  const { loading, items, fetchManageServicePackagesMfo, updateManageServicePackagesMfo, errors } = props;
  const [search, onChangeSearch] = useState<string>(null);
  const [tariff, setTariff] = useState<Manage.ServicePackages.Mfo.Item>(null);
  const [tariffAdd, setTariffAdd] = useState(false);
  const [tariffSend, setTariffSend] = useState<string>(null);
  const [editLimit, setEditLimit] = useState<string>(null);
  const [editMarketingInfoId, setEditMarketingInfoId] = useState<string>(null);
  const [tariffDocId, setTariffDocId] = useState<any>(null);
  const [tariffDocLoading, setTariffDocLoading] = useState<boolean>(false);
  const [tariffDocError, setTariffDocError] = useState<any>(null);
  const [tariffDocs, setTariffDocs] = useState<any>([]);
  const [filterGroup, setFilterGroup] = useState<string>(null);
  const [categoryBenefits, setCategoryBenefits] = useState<string>(null);
  const isAdminRole = useIsAdminRole();
  useEffect(() => {
    const timeout = search ? setTimeout(getTariffs, 500) : null;
    !search && getTariffs();
    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [search, filterGroup, categoryBenefits]);
  useEffect(() => {
    if (tariffDocId) {
      getTariffDocs();
    } else {
      setTariffDocs([]);
      setTariffDocLoading(false);
      setTariffDocError(null);
    }
  }, [tariffDocId]);
  const getTariffDocs = () => {
    setTariffDocLoading(true);
    getServicePackageAttachments(tariffDocId)
      .then((data) => {
        if (data.data.code === 'success') {
          setTariffDocs(data.data?.data?.items || []);
        }
      })
      .catch((error) => setTariffDocError(error))
      .finally(() => setTariffDocLoading(false));
  };
  const deleteTariffDocs = (item: any) => {
    setTariffDocLoading(true);
    deleteServicePackageAttachment(item)
      .then((data) => {
        if (data.data.code === 'success') {
          getTariffDocs();
        }
      })
      .catch((error) => {
        setTariffDocLoading(false);
        setTariffDocError(error);
      });
  };
  const getTariffs = (): void => {
    fetchManageServicePackagesMfo(search, filterGroup, null, categoryBenefits);
  };
  const getTariffOptions = (): { key: string; value: string | any; column?: boolean }[] => {
    if (!tariff) {
      return [];
    }
    return [
      {
        key: 'Cтоимость',
        value: (tariff.price || 0).toString(),
      },
      {
        key: 'Льгота',
        value: tariff.categoryBenefits?.name || 'Без льгот',
      },
      {
        key: 'Доступные типы специализации',
        column: true,
        value: (tariff.specializations || [])
          .map((spec) => spec.name)
          .filter((v) => v)
          .join(', '),
      },
      {
        key: 'Доступные типы связи',
        column: true,
        value: (tariff.channel ? [tariff.channel] : [])
          .map((type) => {
            switch (type.value) {
              case 'CHAT':
                return 'Чат';
              case 'PHONE':
                return 'Чат/Аудио';
              case 'VIDEO':
                return 'Чат/Аудио/Видео';
              default:
                return null;
            }
          })
          .filter((v) => v)
          .join(', '),
      },
      {
        key: 'Доступные типы консультаций',
        column: true,
        value: (tariff?.serviceTypes || [])
          .map((type) => type.name)
          .filter((v) => v)
          .join(', '),
      },
      {
        key: 'Консультаций в день',
        value: (tariff.appointmentsPerDay || 0).toString(),
      },
      {
        key: 'Дополнительные услуги',
        value:
          (tariff.additionalServices || [])
            .map((value) => `${value.type.name} (${value.limitPerPeriod || '-'})`)
            .join(', ') || '-',
      },
      {
        key: 'Прикрепление файлов',
        value: tariff.withFiles ? 'Да' : 'Нет',
      },
      {
        key: 'Кол-во дней оказания услуг с момента активации',
        value: tariff?.expirationDays === null ? 'Бессрочный' : `${tariff?.expirationDays}`,
      },
      {
        key: 'Активация по промокоду',
        value: tariff?.promoActivation ? 'Да' : 'Нет',
      },
      {
        key: 'Активировать',
        value: (
          <label className="toggle-checkbox-label">
            <input
              type="checkbox"
              disabled={!isAdminRole}
              checked={tariff.status.value === 'ACTIVE'}
              onChange={(event) => {
                const status = event.target.checked ? 'ACTIVE' : 'ARCHIVE';
                updateServicePackageStatus(tariff.id, status)
                  .then((response: AxiosResponse<any>) => {
                    if (response.data.code === 'success') {
                      toast[status === 'ACTIVE' ? 'success' : 'warning'](
                        `Тариф ${status === 'ACTIVE' ? 'активирован' : 'архивирован'}`
                      );
                      const newTariff = {
                        ...tariff,
                        status: {
                          ...tariff.status,
                          value: status,
                        },
                      };
                      updateManageServicePackagesMfo(newTariff);
                      setTariff(newTariff);
                    } else {
                      throw new Error();
                    }
                  })
                  .catch(() => toast.error('Ошибка при запросе'));
              }}
            />
            <span className="checkmark">
              <span className="check" />
            </span>
          </label>
        ),
      },
    ];
  };
  const getLabelStatus = (status: string): string => {
    switch (status) {
      case 'ACTIVE':
        return 'Активный';
      case 'ARCHIVE':
        return 'В архиве';
      default:
        return 'Новый';
    }
  };

  const onClosePreview = useCallback(() => {
    setTariff(null);
  }, []);

  return (
    <>
      <Box className={classNames(styles.ServicePackagesAdminMfo)}>
        <Box className={classNames(styles.ServicePackagesAdminMfo_Section, styles.ServicePackagesAdminMfo_Toolbar)}>
          <Box className={styles.ServicePackagesAdminMfo_Toolbar_Search}>
            <SearchIcon className={styles.ServicePackagesAdminMfo_Toolbar_Search_Icon} />
            <InputField
              value={search}
              onChange={(e) => {
                onChangeSearch(e.target.value);
              }}
              block
              placeholder="Название тарифа"
            />
            {search && (
              <ClearIcon
                onClick={() => {
                  onChangeSearch('');
                }}
                className={classNames(
                  styles.ServicePackagesAdminMfo_Toolbar_Search_Icon,
                  styles.ServicePackagesAdminMfo_Toolbar_Search_Icon_Clear
                )}
              />
            )}
          </Box>
        </Box>
        <Box className={classNames(styles.ServicePackagesAdminMfo_Section, styles.ServicePackagesAdminMfo_Toolbar)}>
          <Box display="flex">
            <Box mr={2}>
              <ServicePackageGroupSelector
                width={250}
                name="groupId"
                isEmpty
                value={filterGroup}
                onChange={(option) => setFilterGroup(option.value ? String(option.value) : null)}
                placeholder="Группа"
              />
            </Box>
            <Box>
              <CategoryBenefitsSelect
                width={250}
                value={categoryBenefits}
                onChange={(option) => {
                  setCategoryBenefits(option.value);
                }}
                placeholder="Льгота"
              />
            </Box>
            <HasRole target={UserRole.ADMIN}>
              <Box ml={8}>
                <Button
                  className={styles.ServicePackagesAdminMfo_Toolbar_Button}
                  size="md"
                  color="primary"
                  onClick={() => {
                    setTariff({ promoActivation: true } as any);
                    setTariffAdd(true);
                  }}
                >
                  Создать новый тариф
                </Button>
              </Box>
            </HasRole>
          </Box>
        </Box>
        <Box className={classNames(styles.ServicePackagesAdminMfo_Section, styles.ServicePackagesAdminMfo_Content)}>
          <ContentLoading isLoading={loading} isError={errors} fetchData={() => getTariffs()}>
            {items.length === 0 && !search && <Box>У вас пока нет созданных тарифов</Box>}
            {items.length === 0 && search && search.length > 0 && <Box>По вашему запросу ничего не найдено</Box>}
            {items.length > 0 && (
              <Box className={classNames(styles.ServicePackagesAdminMfo_Items)}>
                {items.map((item: Manage.ServicePackages.Mfo.Item, index: number) => (
                  <Box
                    className={classNames(styles.ServicePackagesAdminMfo_Items_Wrapper)}
                    onClick={() => setTariff(item)}
                    key={index}
                  >
                    <Box className={classNames(styles.ServicePackagesAdminMfo_Items_Item)} key={index}>
                      <Box className={classNames(styles.ServicePackagesAdminMfo_Items_Item_Data)}>
                        <Box className={classNames(styles.ServicePackagesAdminMfo_Items_Item_Data_Name)}>
                          {item.name}
                        </Box>
                        <Box className={classNames(styles.ServicePackagesAdminMfo_Items_Item_Data_Price)}>
                          {item.price} &#8381;
                        </Box>
                        <Box className={classNames(styles.ServicePackagesAdminMfo_Items_Item_Data_Name)}>
                          {`Группа: ${item?.group?.name}`}
                        </Box>
                        <Box className={classNames(styles.ServicePackagesAdminMfo_Items_Item_Data_Name)}>
                          {`Льгота: ${item?.categoryBenefits?.name || 'Без льгот'}`}
                        </Box>
                        {(item?.expirationDays === null || item?.expirationDays) && (
                          <Box className={classNames(styles.ServicePackagesAdminMfo_Items_Item_Data_Name)}>
                            {`Кол-во дней оказания услуг с момента активации: ${
                              item?.expirationDays === null ? 'Бессрочный' : `${item?.expirationDays}`
                            }`}
                          </Box>
                        )}
                        <Box className={classNames(styles.ServicePackagesAdminMfo_Items_Item_Data_Name)}>
                          {`Активация по промокоду: ${item?.promoActivation ? 'Да' : `Нет`}`}
                        </Box>
                        <Box className={classNames(styles.ServicePackagesAdminMfo_Items_Item_Data_Name)}>
                          {`Услуги: ${
                            (item?.additionalServices || []).map((value) => value.type.name).join(',') || '-'
                          }`}
                        </Box>
                        <Box
                          className={classNames(
                            styles.ServicePackagesAdminMfo_Items_Item_Data_Status,
                            styles[`ServicePackagesAdminMfo_Items_Item_Data_Status_${item.status.value}`]
                          )}
                        >
                          {getLabelStatus(item.status.value as string)}
                        </Box>
                      </Box>
                      <Box className={classNames(styles.ServicePackagesAdminMfo_Items_Item_Actions)}>
                        <Box display="flex" flexDirection="column">
                          <HasRole target={UserRole.ADMIN}>
                            <Button
                              className={styles.ServicePackagesAdminMfo_Button}
                              size="sm"
                              color="default"
                              disabled={item.status.value !== 'ACTIVE'}
                              onClick={(event) => {
                                event.stopPropagation();
                                item.status.value === 'ACTIVE' && setTariffSend(item.id);
                              }}
                            >
                              Отправить промокод
                            </Button>
                          </HasRole>
                          <HasRole target={UserRole.ADMIN}>
                            <Button
                              className={styles.ServicePackagesAdminMfo_Button}
                              size="sm"
                              color="default"
                              onClick={(event) => {
                                event.stopPropagation();
                                setTariffAdd(true);
                                setTariff(item);
                              }}
                            >
                              Редактировать тариф
                            </Button>
                          </HasRole>
                          <HasRole target={UserRole.ADMIN}>
                            <Button
                              className={styles.ServicePackagesAdminMfo_Button}
                              size="sm"
                              color="default"
                              onClick={(event) => {
                                event.stopPropagation();
                                setEditLimit(item.id);
                              }}
                            >
                              Редактировать лимиты
                            </Button>
                          </HasRole>
                          <HasRole target={UserRole.ADMIN}>
                            <Button
                              className={styles.ServicePackagesAdminMfo_Button}
                              size="sm"
                              color="default"
                              onClick={(event) => {
                                event.stopPropagation();
                                setEditMarketingInfoId(item.id);
                              }}
                            >
                              Маркетинговое описание
                            </Button>
                          </HasRole>
                          <Button
                            className={styles.ServicePackagesAdminMfo_Button}
                            size="sm"
                            color={item.id === tariffDocId ? 'primary' : 'default'}
                            onClick={(event) => {
                              event.stopPropagation();
                              if (item.id === tariffDocId) {
                                setTariffDocId(null);
                              } else {
                                setTariffDocId(item.id);
                              }
                            }}
                          >
                            Список документов
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                    {item.id === tariffDocId && (
                      <>
                        <ServicePackagesDocuments
                          id={tariffDocId}
                          loading={tariffDocLoading}
                          error={tariffDocError}
                          tariffDocs={tariffDocs}
                          onDelete={deleteTariffDocs}
                          getData={getTariffDocs}
                        />
                      </>
                    )}
                  </Box>
                ))}
              </Box>
            )}
          </ContentLoading>
        </Box>
      </Box>
      {tariff && !tariffAdd && (
        <AdminServicePackageView
          disabled={!isAdminRole}
          name={tariff.name}
          options={getTariffOptions()}
          onClose={onClosePreview}
        />
      )}
      {tariffAdd && (
        <AdminMfoEdit
          data={tariff}
          disabled={!isAdminRole}
          onClose={() => {
            setTariffAdd(false);
            setTariff(null);
          }}
          onSave={() => {
            setTariff(null);
            setTariffAdd(false);
            getTariffs();
          }}
        />
      )}
      {tariffSend && (
        <AdminMfoSend
          servicePackageId={tariffSend}
          onClose={() => {
            setTariffSend(null);
          }}
          onSend={() => {
            setTariffSend(null);
          }}
        />
      )}
      {editLimit && (
        <AdminServicePackageLimitEdit
          servicePackageId={editLimit}
          onClosed={() => {
            setEditLimit(null);
          }}
          onUpdated={() => {
            setEditLimit(null);
          }}
        />
      )}
      {editMarketingInfoId && (
        <AdminMarketingInfoDialog
          disabled={!isAdminRole}
          servicePackageId={editMarketingInfoId}
          onClosed={() => setEditMarketingInfoId(null)}
          title="Маркетинговое описание"
        />
      )}
    </>
  );
};

export default connect(
  (state: any) => ({
    ...(state.manageServicePackagesMfo as ManageServicePackagesMfoInitialSettings),
  }),
  {
    fetchManageServicePackagesMfo,
    updateManageServicePackagesMfo,
  }
)(ServicePackagesAdminMfo);
