import React, { useRef } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import classNames from 'classnames';
import { InputLabel } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import FormLabel from '../FormLabel';
import styles from './SelectInput2.module.sass';
import FormInvalidMessage from '../FormInvalidMessage';

const SelectInput2 = (props: any) => {
  const ref = useRef();
  const {
    name,
    value,
    onChange = (event: any) => {},
    label,
    labelId,
    options = [],
    dataValidate,
    errors,
    placeholder,
    multiple = false,
    ...other
  } = props;
  return (
    <>
      <FormControl
        variant="outlined"
        className={classNames(errors && errors.length ? styles.formControlErrors : styles.formControl)}
      >
        {label && <FormLabel id={labelId}>{label}</FormLabel>}
        {!value && !label && placeholder && (
          <InputLabel disableAnimation filled={false} variant="filled" id={labelId}>
            {placeholder}
          </InputLabel>
        )}
        <Select
          multiple={multiple}
          className={classNames(styles.select)}
          labelId={labelId}
          name={name}
          errors={errors}
          value={value}
          displayEmpty
          onChange={(event) => {
            const input: HTMLInputElement = (ref.current as any)?.node as HTMLInputElement;
            input.value = event.target.value as any;
            input.setAttribute('name', name);
            onChange(event, input);
          }}
          inputProps={{
            inputRef: ref,
            placeholder,
            'data-validate': dataValidate,
          }}
          {...other}
        >
          {options.map((value, index) => (
            <MenuItem key={index} value={value.value}>
              <em>{value.label}</em>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormInvalidMessage>{errors?.[0]?.message}</FormInvalidMessage>
    </>
  );
};

export default SelectInput2;
