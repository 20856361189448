import React, { PropsWithChildren } from 'react';

// Components
import Header from 'components/Header';

import { Box } from '@material-ui/core';
import { BoxProps } from '@material-ui/core/Box/Box';
import styles from './BasePage.module.sass';

interface PropsInterface extends PropsWithChildren<BoxProps> {}
const BasePage = (props: PropsInterface): JSX.Element => {
  const { children, ...rest } = props;
  return (
    <Box className={styles.root} {...rest}>
      <Header />
      <div className={styles.root_wrapper}>{children}</div>
    </Box>
  );
};

export default BasePage;
