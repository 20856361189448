import axios from 'utils/axios';
import { v4 } from 'uuid';
import { toast } from 'react-toastify';
import saveFile from '../../../utils/saveFile';

export const getAgentExampleApi = () =>
  axios
    .get('/api/v1/agent/example', {
      responseType: 'blob',
    })
    .then((data) => {
      const filename =
        (data.headers?.['Content-Disposition'.toLowerCase()] || '')
          .split(';')
          .map((str: string) => str.replaceAll('"', '').trim().split('='))
          .find((_) => _?.[0] === 'filename')?.[1] || `${v4()}.xlsx`;
      saveFile(data.data, filename);
    })
    .catch(() => toast.error('Ошибка при запросе'));

export const uploadAgentRegistryApi = (file: File) => {
  const data = new FormData();
  data.append('excelFile', file);
  return axios
    .post('/api/v1/agent/reestr', data)
    .then((data) => {
      if (data.data?.data?.length > 0) {
        return Promise.reject();
      }
      toast.success('Реестр загружен');
    })
    .catch(() => toast.error('Ошибка при запросе'));
};
