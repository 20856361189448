import { UserRole } from 'types';
import { ReactComponent as MonitoringIcon } from 'assets/img/icons/monitoring.svg';

import { ReactComponent as PatientGlavnaya } from 'assets/img/sidebar/patient/01-glavnaya.svg';
import { ReactComponent as PatientKonsultaciya } from 'assets/img/sidebar/patient/03-konsultaciya.svg';
import { ReactComponent as PatientTarif } from 'assets/img/sidebar/patient/04-tarif.svg';
import { ReactComponent as PatientKabinet } from 'assets/img/sidebar/patient/05-moi-kabinet.svg';
import { ReactComponent as UserCheck } from 'assets/img/sidebar/patient/user-check.svg';
import { ReactComponent as DoctorNowPriem } from 'assets/img/sidebar/doctor/01-now-priem.svg';
import { ReactComponent as DoctorRaspisanie } from 'assets/img/sidebar/doctor/02-raspisanie.svg';
import { ReactComponent as DoctorEvents } from 'assets/img/sidebar/doctor/events.svg';
import { ReactComponent as DoctorEventsP } from 'assets/img/sidebar/doctor/events-p.svg';
import { ReactComponent as DoctorMoiPacienti } from 'assets/img/sidebar/doctor/04-moi-pacienti.svg';
import { ReactComponent as DoctorMoiKabinet } from 'assets/img/sidebar/doctor/05-moi-kabinet.svg';
import { ReactComponent as AgentCoins } from 'assets/img/sidebar/agent/coins.svg';
import { ReactComponent as AgentPieChart } from 'assets/img/sidebar/agent/pie-chart.svg';
import * as React from 'react';

type IconType = '1' | '2' | '3';
interface SideBarMenuInterface {
  [key: string]: Array<{ to: string; title: string; icon?: React.FunctionComponent<any>; iconType?: IconType }>;
}
export const AUDIO_SIGNAL = 'https://doconline-media-prod.storage.yandexcloud.net/public/sound/signal.wav';
export const ESIA_AUTH_URL = `${process.env.REACT_APP_ESIA_URL}/auth/authorize?client_id=${process.env.REACT_APP_ESIA_CLIENT_ID}&response_type=code&scope=openid%20fullname%20mobile%20birthdate%20email%20gender&redirect_uri=${process.env.REACT_APP_ESIA_REDIRECT_URL}&provider=esia_oauth`;

export const SIDEBAR_MENU: SideBarMenuInterface = {
  [UserRole.DOCTOR]: [
    { to: '/current', title: 'Текущий приём', icon: DoctorNowPriem, iconType: '2' },
    { to: '/scheduler', title: 'Расписание', icon: DoctorRaspisanie, iconType: '2' },
    { to: '/events', title: 'Консультации', icon: DoctorEvents, iconType: '2' },
    { to: '/personal', title: 'Личный пациент', icon: DoctorEventsP, iconType: '2' },
    { to: '/patients', title: 'Мои пациенты', icon: DoctorMoiPacienti, iconType: '2' },
    { to: '/profile', title: 'Профиль', icon: DoctorMoiKabinet, iconType: '1' },
  ],
  [UserRole.PATIENT]: [
    { to: '/', title: 'Главная', icon: PatientGlavnaya, iconType: '1' },
    { to: '/appointments', title: 'Консультации', icon: PatientKonsultaciya, iconType: '3' },
    { to: '/packages', title: 'Тарифы', icon: PatientTarif, iconType: '1' },
    { to: '/profile', title: 'Мой кабинет', icon: PatientKabinet, iconType: '1' },
    { to: '/checker', title: 'Проверить себя', icon: UserCheck, iconType: '2' },
    { to: '/journal', title: 'Мониторинг', icon: MonitoringIcon, iconType: '2' },
  ],
  [UserRole.ADMIN]: [
    { to: '/doctors', title: 'Врачи' },
    { to: '/patients', title: 'Пациенты' },
    { to: '/services', title: 'Тарифы' },
    { to: '/reports', title: 'Отчеты' },
    { to: '/settings', title: 'Настройки' },
    { to: '/reviews', title: 'Обратная связь' },
    { to: '/dailyconclusionstrategies', title: 'Ежедневные заключения' },
    { to: '/partners', title: 'Партнеры и агенты' },
    { to: '/stories', title: 'Сторисы' },
  ],
  [UserRole.SUPPORT]: [
    { to: '/doctors', title: 'Врачи' },
    { to: '/patients', title: 'Пациенты' },
    { to: '/services', title: 'Тарифы' },
  ],
  [UserRole.AGENT]: [
    { to: '/', title: 'Агентские продажи', icon: AgentCoins, iconType: '3' },
    { to: '/metrics', title: 'Аналитика', icon: AgentPieChart, iconType: '2' },
  ],
};

export const APP_STORE_LINK =
  'https://apps.apple.com/ru/app/%D0%B4%D0%BE%D0%BA-%D0%BE%D0%BD%D0%BB%D0%B0%D0%B9%D0%BD-%D0%BA%D0%BE%D0%BD%D1%81%D1%83%D0%BB%D1%8C%D1%82%D0%B0%D1%86%D0%B8%D0%B8-%D0%B2%D1%80%D0%B0%D1%87%D0%B0/id1553258252';

export const GOOGLE_STORE_LINK = 'https://play.google.com/store/apps/details?id=ru.doconline.lk';

export const INFO_MAIL = 'info@doconline.ru';
