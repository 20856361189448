import Manage from "../../types/manage";
import axios from "../../utils/axios";
import {AxiosResponse} from "axios";
import {ResponseData} from "../../types";

class manageAgentApi {

  static updateAgent = (id: string, request: Manage.Agent.EditRequest): Promise<AxiosResponse<ResponseData<void>>> => {
    return axios.put(`/api/v1/agent/${id}`, request)
  }

  static getToken = (id: string): Promise<AxiosResponse<ResponseData<string>>> => {
    return axios.get(`/api/v1/agent/${id}/token`)
  }

  static generateCertificates = (agentId: string, request: Manage.Agent.CertsGenerateRequest):
    Promise<AxiosResponse<ResponseData<boolean>>> => {
    return axios.post(`/api/v1/agent/${agentId}/servicepackages/generate`, request)
  }

  static getAvailableServicePackages = (agentId: string):
    Promise<AxiosResponse<ResponseData<Manage.Agent.AvailableServicePackagesResponse>>> => {
    return axios.get(`/api/v1/agent/${agentId}/servicepackages/available`)
  }

  static updatePassword = (agentId: string, request: Manage.Agent.PasswordRequest):
    Promise<AxiosResponse<ResponseData<void>>> => {
    return axios.put(`/api/v1/agent/${agentId}/password`, request)
  }

}

export default manageAgentApi
