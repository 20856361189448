import axios from 'utils/axios';

export const findPatientServicePackageTransactions = (
  patientServicePackageId?: number,
  patientId?: number,
  page?: number,
  pageSize?: number
) => {
  page = page || 0;
  pageSize = pageSize || 50;
  return axios.get(`/api/v1/servicepackages/patient/transactions`, {
    params: {
      patientServicePackageId,
      patientId,
      page,
      pageSize,
      type: [],
    },
  });
};
