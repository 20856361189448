import { EnumDictionary } from '../EnumDictionary';

module Doctor {
  export interface Availability {
    name?: string;
    value?: AvailabilityStatus;
  }
  export interface Gender {
    name?: string;
    value?: string;
  }
  export type AppointmentsType = 'AWAITS' | 'DONE' | 'CANCELED' | 'IN_PROGRESS' | 'FAILED' | 'FINISHED';
  export interface AppointmentStatus {
    name?: string;
    value?: AppointmentsType;
  }
  export interface Avatar {
    contentType?: string;
    createdAt?: Date;
    fileName?: string;
    formatUrls?: { [key: string]: string };
    id?: string;
    originalUrl?: string;
  }
  export interface Profile {
    id?: string;
    firstName?: string;
    middleName?: null;
    lastName?: string;
    birthday?: null;
    email?: string;
    phone?: null;
    sex?: Gender;
    avatar?: Avatar;
    deleted?: boolean;
    availability?: Availability;
    specialization?: Profile.Category;
    category?: Profile.Category;
    workingSinceYear?: number;
    durationMinutes?: number;
    tariff?: Profile.Tariff;
    description?: string;
    additionalInfo?: string;
    education?: string;
    active?: boolean;
    certsCount?: number;
    additionalServiceTypes?: Array<EnumDictionary<string>>;
  }
  export namespace Profile {
    export interface Category {
      id?: string;
      name?: string;
    }
    export interface Tariff {
      id?: string;
      name?: string;
      price?: number;
      createdAt?: Date;
      active?: boolean;
      archivedAt?: null;
    }
  }

  export namespace ProfileMetaInfo {
    export interface Response {
      code?: string;
      data?: Data;
    }

    export interface Data {
      activeAppointment?: ActiveAppointment;
      availability?: Availability;
      avatar?: Avatar;
      firstName?: string;
      id?: string;
      lastName?: string;
      middleName?: string;
    }
    export interface ActiveAppointment {
      createdAt?: Date;
      id?: string;
      patient?: ShortPatient;
      startTime?: Date;
      status?: AppointmentStatus;
      subject?: null;
    }
  }
  export interface ShortPatient {
    avatar?: Avatar;
    firstName?: string;
    id?: string;
    lastName?: string;
  }
}

export enum AvailabilityStatus {
  DUTY = 'DUTY',
  BUSY = 'BUSY',
  NOT_DUTY = 'NOT_DUTY',
  AWAY = 'AWAY',
}

export default Doctor;
