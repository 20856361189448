import React, { useState } from 'react';
import { Backdrop, Box, Dialog } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import Button from '../Forms/Button';
import styles from './AppointmentDoctorClose.module.sass';
import ContentCard from '../ContentCard';
import Doctor from '../../types/doctor';
import SelectInput from '../Forms/SelectInput';

export interface AppointmentDoctorCloseInterface {
  loading?: boolean;
  onClose: () => void;
  onApply: (status: Doctor.AppointmentsType) => void;
}

const AppointmentDoctorClose = (props: AppointmentDoctorCloseInterface) => {
  const { onApply = () => {}, onClose = () => {}, loading = false } = props;
  const [status, setStatus] = useState<Doctor.AppointmentsType>('FINISHED');
  return (
    <Dialog
      PaperProps={{ className: classNames(styles.Dialog) }}
      BackdropComponent={(props) => <Backdrop className={styles.Backdrop} {...props} />}
      onClose={onClose}
      open
    >
      <ContentCard className={styles.AppointmentDoctorClose}>
        <ContentCard.Header className={styles.AppointmentDoctorClose_Header}>
          <h6 className="page-header-title">Завершение консультации</h6>
          <CloseIcon onClick={onClose} className={styles.AppointmentDoctorClose_CloseIcon} />
        </ContentCard.Header>
        <ContentCard.Main className={styles.AppointmentDoctorClose_Content}>
          <Box className="p-3">
            <SelectInput
              name="status"
              value={{
                label:
                  status === 'FINISHED'
                    ? 'Завершение консультации с заполнением протокола'
                    : 'Завершить консультацию без заполнения протокола',
                value: status,
              }}
              onChange={(option) => {
                setStatus(option.value as Doctor.AppointmentsType);
              }}
              options={[
                {
                  label: 'Завершение консультации с заполнением протокола',
                  value: 'FINISHED',
                },
                {
                  label: 'Завершить консультацию без заполнения протокола',
                  value: 'FAILED',
                },
              ]}
              block
            />
          </Box>
          <Box width="100%" className="d-flex justify-content-between align-items-center p-3">
            <Button type="button" block size="md" className="mr-1" onClick={onClose} color="default">
              Отмена
            </Button>
            <Button
              type="button"
              block
              size="md"
              className="ml-1"
              disabled={loading}
              isLoading={loading}
              onClick={() => onApply(status)}
              color="primary"
            >
              Подтвердить
            </Button>
          </Box>
        </ContentCard.Main>
      </ContentCard>
    </Dialog>
  );
};
export default AppointmentDoctorClose;
