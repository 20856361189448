import React, { useState } from 'react';
import { Backdrop, Box, Dialog, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import styles from './AdminDailyConclusion.module.sass';
import ContentCard from '../ContentCard';
import InputField from '../Forms/InputField';
import Button from '../Forms/Button';
import { getFieldErrors as getFieldErrorsUtil, validateFieldOnChange } from '../../utils';
import FormValidator from '../Forms/FormValidator';
import { updateDailyConclusionStrategy } from '../../api/dailyconclusionstrategies/updateDailyConclusionStrategy';
import { createDailyConclusionStrategy } from '../../api/dailyconclusionstrategies/createDailyConclusionStrategy';

interface PropsInterface {
  item: any;
  onSave: () => void;
  onClose: () => void;
}

const AdminDailyConclusionView = (props: PropsInterface) => {
  const { onClose = () => {}, onSave = () => {}, item } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<any>([]);
  const [model, setModel] = useState<any>({ ...item, active: item.id ? item.active : true });
  const validateOnChange = (name: string, value: any, event, element?: any) => {
    validateFieldOnChange(name, value, event, model, setModel, formErrors, setFormErrors, element);
  };
  const getFieldErrors = (field: string) => getFieldErrorsUtil(field, formErrors);
  const submit = (e) => {
    e.preventDefault();
    const form = e.target;
    const inputs = [...form.elements].filter((i) => ['INPUT', 'SELECT', 'TEXTAREA'].includes(i.nodeName));

    const { errors, hasError } = FormValidator.bulkValidate(inputs);

    setFormErrors([...errors]);

    if (!hasError) {
      store();
    }
  };
  const store = (): void => {
    setLoading(true);
    // @ts-ignore
    (model.id ? updateDailyConclusionStrategy(model) : createDailyConclusionStrategy(model))
      .then((response: AxiosResponse<any>) => {
        if (response && response.data.code === 'success') {
          return model;
        }
        throw new Error();
      })
      .then(() => {
        onSave();
        setLoading(false);
      })
      .catch(() => {
        toast.error('Ошибка при запросе');
        setLoading(false);
      });
  };
  return (
    <Dialog
      PaperProps={{ className: classNames(styles.Dialog) }}
      BackdropComponent={(props) => <Backdrop className={styles.Backdrop} {...props} />}
      onClose={onClose}
      open
    >
      <ContentCard className={styles.AdminDailyConclusionView}>
        <ContentCard.Header className={styles.AdminDailyConclusionView_Header}>
          <h6 className="page-header-title">{item.id ? 'Редактировать генератор' : 'Создать генератор'}</h6>
          <CloseIcon onClick={onClose} className={styles.AdminDailyConclusionView_CloseIcon} />
        </ContentCard.Header>
        <ContentCard.Main className={styles.AdminDailyConclusionView_Content}>
          <form onSubmit={submit}>
            <Grid container direction="column" justify="space-between" alignItems="stretch" spacing={2}>
              <Grid item xs={12}>
                <InputField
                  name="name"
                  value={model.name || ''}
                  onChange={(e) => validateOnChange('name', e.target.value, e)}
                  data-validate='["required"]'
                  errors={getFieldErrors('name')}
                  block
                  label="Название"
                  placeholder="Введите название генератора"
                />
              </Grid>
              <Grid item xs={12} container justify="space-between" alignItems="center">
                <Box>Активный генератор</Box>
                <label className="toggle-checkbox-label">
                  <input
                    type="checkbox"
                    checked={model.active === true}
                    onChange={(event) => {
                      setModel({ ...model, active: event.target.checked });
                    }}
                  />

                  <span className="checkmark">
                    <span className="check" />
                  </span>
                </label>
              </Grid>
              <Grid item xs={12} container justify="space-between" alignItems="center" spacing={1}>
                <Grid item xs={12} lg={6}>
                  <Button className="mr-2" color="default" block onClick={onClose}>
                    Отмена
                  </Button>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Button type="submit" color="primary" isLoading={loading} block>
                    {item.id ? 'Сохранить' : 'Создать'}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </ContentCard.Main>
      </ContentCard>
    </Dialog>
  );
};
export default AdminDailyConclusionView;
