import { createEffect } from 'effector';
import { DoctorFilterRequest, PageItemsResponseInterface, ResponseCode } from 'types';
import { toast } from 'react-toastify';
import { addPersonalDoctor, getDoctors } from '$api';

export const onGetDoctorsFx = createEffect(
  async (params: DoctorFilterRequest): Promise<PageItemsResponseInterface<any>> => {
    const res = await getDoctors(params)
      .then()
      .catch((res) => res);
    if (res?.data?.code === ResponseCode.SUCCESS) {
      return {
        ...res?.data?.data,
        doctors: res?.data?.data?.items || [],
      };
    }
    toast.error('Ошибка при запросе');
    throw new Error(res?.data?.code);
  }
);

export const addPersonalDoctorFx = createEffect(
  async (id: string): Promise<any> => {
    const res = await addPersonalDoctor(id)
      .then()
      .catch((res) => res);
    if (res?.data?.code === ResponseCode.SUCCESS) {
      return res?.data?.data;
    }
    toast.error('Ошибка при запросе');
    throw new Error(res?.data?.code);
  }
);
