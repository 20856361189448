import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { Backdrop, Box, Dialog } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import ContentCard from 'components/ContentCard';
import Button from 'components/Forms/Button';
import styles from './AdminStoriesEditModal.module.sass';
import MediaContent from './components/MediaContent';
import { MediaContentType } from './components/MediaContent/MediaContent';
import InputField from '../../../../../components/Forms/InputField';
import useStoreApi from '../../../../../hooks/useStoreApi';
import { originEditStoriesFx } from '../../../../../store/stories/effects';

interface PropsInterface extends PropsWithChildren<any> {
  item: any;
  onClose?: (...args: any) => any;
}

const AdminStoriesEditModal = (props: PropsInterface) => {
  const { onClose = () => {}, item } = props;
  const [viewLinkMobile, setViewLinkMobile] = useState<any>(false);
  const [viewLinkWeb, setViewLinkWeb] = useState<any>(false);
  const [model, setModel] = useState<any>({});
  const isNew = useMemo(() => !item?.id, [item]);
  const { loading } = useStoreApi(originEditStoriesFx, { loading: false });
  useEffect(() => {
    setModel((model) => ({ ...(model || {}), ...(item || {}) }));
  }, [item]);
  const handleClose = useCallback(() => {
    !loading && onClose();
  }, [loading, onClose]);
  const handleChangeMedia = useCallback((data: any, type: MediaContentType) => {
    if (type === MediaContentType.PHOTO) {
      setModel((model) => ({ ...model, preview: data }));
    }
    if (type === MediaContentType.VIDEO) {
      setModel((model) => ({ ...model, content: data }));
    }
  }, []);
  const handleToggleActive = useCallback(() => {
    !loading && setModel((model) => ({ ...model, active: !model?.active }));
  }, [loading]);
  const handleToggleViewLinkMobile = useCallback(() => {
    if (!loading) {
      viewLinkMobile && setModel((model) => ({ ...model, deeplinkMobile: null }));
      setViewLinkMobile(!viewLinkMobile);
    }
  }, [loading, viewLinkMobile]);
  const handleToggleViewLinkWeb = useCallback(() => {
    if (!loading) {
      viewLinkWeb && setModel((model) => ({ ...model, deeplinkWeb: null }));
      setViewLinkWeb(!viewLinkWeb);
    }
  }, [loading, viewLinkWeb]);
  useEffect(() => {
    !viewLinkWeb && setModel((model) => ({ ...model, deeplinkWeb: null }));
  }, [viewLinkWeb]);
  const handleSave = useCallback(() => {
    originEditStoriesFx(model).then(() => onClose(true));
  }, [onClose, model]);
  const isValid = useMemo(
    () =>
      !!model?.name &&
      !!model?.preview?.originalUrl &&
      !!model?.content?.originalUrl &&
      (!viewLinkWeb || (viewLinkWeb && model?.deeplinkWeb)) &&
      (!viewLinkMobile || (viewLinkMobile && model?.deeplinkMobile)),
    [
      model?.name,
      model?.content?.originalUrl,
      model?.deeplinkMobile,
      model?.deeplinkWeb,
      model?.preview?.originalUrl,
      viewLinkMobile,
      viewLinkWeb,
    ]
  );
  useEffect(() => {
    item?.deeplinkMobile && setViewLinkMobile(true);
  }, [item?.deeplinkMobile]);
  useEffect(() => {
    item?.deeplinkWeb && setViewLinkWeb(true);
  }, [item?.deeplinkWeb]);
  return (
    <Dialog
      PaperProps={{ className: classNames(styles.dialog) }}
      BackdropComponent={(props) => <Backdrop className={styles.backdrop} {...props} />}
      onClose={handleClose}
      open
    >
      <ContentCard className={styles.root}>
        <ContentCard.Header className={styles.root_header}>
          <h6 className="page-header-title">{!isNew ? 'Изменить ' : 'Добавить '}сторис</h6>
          <CloseIcon onClick={handleClose} className={styles.root_close} />
        </ContentCard.Header>
        <ContentCard.Main className={styles.root_content}>
          <Box mb={2}>
            <InputField
              name="name"
              value={model.name}
              disabled={loading}
              onChange={(event) => {
                setModel({ ...model, name: event.target.value });
              }}
              block
              placeholder="Название"
            />
          </Box>
          <Box className={styles.root_media}>
            <MediaContent
              disabled={loading}
              value={model?.preview?.originalUrl}
              onChange={(data) => handleChangeMedia(data, MediaContentType.PHOTO)}
              type={MediaContentType.PHOTO}
            />
            <MediaContent
              disabled={loading}
              value={model?.content?.originalUrl}
              onChange={(data) => handleChangeMedia(data, MediaContentType.VIDEO)}
              type={MediaContentType.VIDEO}
            />
          </Box>
          <Box className={styles.root_controls}>
            <Box className={styles.root_controls_checkbox}>
              <Box onClick={handleToggleActive}>Видимость публикации</Box>
              <label className="toggle-checkbox-label">
                <input
                  type="checkbox"
                  disabled={loading}
                  checked={model.active === true}
                  onChange={handleToggleActive}
                />
                <span className="checkmark">
                  <span className="check" />
                </span>
              </label>
            </Box>
            <Box className={styles.root_controls_checkbox}>
              <Box onClick={handleToggleViewLinkMobile}>Добавить кнопку в сторис в приложении</Box>
              <label className="toggle-checkbox-label">
                <input
                  type="checkbox"
                  disabled={loading}
                  checked={viewLinkMobile}
                  onChange={handleToggleViewLinkMobile}
                />
                <span className="checkmark">
                  <span className="check" />
                </span>
              </label>
            </Box>
            <Box>
              <InputField
                name="deeplinkMobile"
                value={model.deeplinkMobile}
                disabled={!viewLinkMobile || loading}
                onChange={(event) => {
                  setModel({ ...model, deeplinkMobile: event.target.value });
                }}
                block
                placeholder="Добавьте ссылку (Deep linking) в сторис для приложения"
              />
            </Box>
            <Box className={styles.root_controls_checkbox}>
              <Box onClick={handleToggleViewLinkWeb}>Добавить кнопку в сторис для веб-версии</Box>
              <label className="toggle-checkbox-label">
                <input type="checkbox" disabled={loading} checked={viewLinkWeb} onChange={handleToggleViewLinkWeb} />
                <span className="checkmark">
                  <span className="check" />
                </span>
              </label>
            </Box>
            <Box>
              <InputField
                name="deeplinkWeb"
                value={model.deeplinkWeb}
                disabled={!viewLinkWeb || loading}
                onChange={(event) => {
                  setModel({ ...model, deeplinkWeb: event.target.value });
                }}
                block
                placeholder="Добавьте ссылку (Deep linking) в сторис для веб-версии"
              />
            </Box>
          </Box>
          <Box className={styles.root_actions}>
            <Button className="mr-2" color="default" block disabled={loading} onClick={handleClose}>
              Отмена
            </Button>
            <Button type="submit" color="primary" block disabled={!isValid || loading} onClick={handleSave}>
              {!isNew ? 'Сохранить' : 'Добавить'}
            </Button>
          </Box>
        </ContentCard.Main>
      </ContentCard>
    </Dialog>
  );
};
export default AdminStoriesEditModal;
