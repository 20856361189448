import {  
  PACKAGE_VIEW_LOADING,
  PACKAGE_VIEW_ERROR,
  PACKAGE_VIEW_SUCCESS
} from '../actions';


const initialState = {
  packageView: null,
  packageViewLoading: false,
  packageViewLoadingError: false
};

const packageViewReducer = (state = initialState, action) => {
  switch (action.type) {
    case PACKAGE_VIEW_LOADING:
      return {
        ...state,
        packageViewLoading: action.isLoading
      };

    case PACKAGE_VIEW_ERROR:
      return {
        ...state,
        packageViewLoadingError: action.isError
      };

    case PACKAGE_VIEW_SUCCESS:
      return {
        ...state,
        packageView: action.servicePackage ? {...action.servicePackage} : action.servicePackage
      };
      
    default:
      return state;
  }
};

export default packageViewReducer;
