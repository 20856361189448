import { DOCTOR_EVENTS_LOADING, DOCTOR_EVENTS_ERROR, DOCTOR_EVENTS_SUCCESS, DOCTOR_EVENTS_RESET } from '../actions';

export interface DoctorEventsInitialSettings {
  events?: any;
  page?: number;
  pageSize?: number;
  totalItems?: number;
  hasMore?: boolean;
  errors?: any;
  loading?: boolean;
}
const initialSettings: DoctorEventsInitialSettings = {
  events: [],
  page: 0,
  pageSize: 9,
  totalItems: 0,
  hasMore: false,
  loading: false,
  errors: null,
};

const doctorEventsReducer = (state = initialSettings, action): DoctorEventsInitialSettings => {
  switch (action.type) {
    case DOCTOR_EVENTS_RESET:
      return {
        ...state,
        ...initialSettings,
      };
    case DOCTOR_EVENTS_LOADING:
      return {
        ...state,
        events: action.loading && action.reset ? [] : state.events,
        loading: action.loading,
      };
    case DOCTOR_EVENTS_SUCCESS:
      return {
        ...state,
        events: action.page > 0 ? state.events.concat(action.events) : action.events,
        page: action.page,
        pageSize: action.pageSize,
        totalItems: action.totalItems,
        hasMore: (action.page + 1) * action.pageSize < action.totalItems && action.totalItems > 0,
        errors: null,
      };
    case DOCTOR_EVENTS_ERROR:
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
};

export default doctorEventsReducer;
