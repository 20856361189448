import React from 'react';
import ContentCard from 'components/ContentCard';

const NotificationsDoctor = (props: any) => {
  return (
    <>
      <ContentCard>
        <ContentCard.Header>
          <div className="row">
            <div className="col-12 text-center text-xs-right text-md-center">
              <h6 className="page-header-title">Информация о враче</h6>
            </div>
          </div>
        </ContentCard.Header>
        <ContentCard.Main />
      </ContentCard>
    </>
  );
};

export default NotificationsDoctor;
