import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, ClickAwayListener, useMediaQuery } from '@material-ui/core';
import InputField from 'components/Forms/InputField';
import useRootSelector from 'hooks/useRootSelector';
import {
  selectJournalLoading,
  selectJournalModel,
  selectJournalType,
  selectJournalTypeUnit,
} from 'views/JournalPage/store/selectors';
import {
  JournalType,
  JournalUnitTypeErrorMessage,
  JournalUnitTypeTitle,
  JournalViewType,
} from 'views/JournalPage/types';
import { onEditJournal, onSetJournalModel, onSetJournalView } from 'views/JournalPage/store/events';
import Button from 'components/Forms/Button';
import { getJournalUnitTypeMask, isJournalUnitTypeValid } from 'views/JournalPage/helpers';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import { getHours, getMinutes, getSeconds, isAfter, setHours, setMinutes, setSeconds } from 'date-fns';
import { TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import styles from './JournalEdit.module.sass';
import { convertUtcToZonedTime, format } from '../../../utils/date';
import { ReactComponent as DateIcon } from '../../../assets/img/journal/calendar.svg';
import { ReactComponent as ClockIcon } from '../../../assets/img/journal/clock.svg';
import Calendar from '../../../components/Calendar';

const JournalEdit = (): JSX.Element => {
  const unit = useRootSelector(selectJournalTypeUnit);
  const model = useRootSelector(selectJournalModel);
  const type = useRootSelector(selectJournalType);
  const media = useMediaQuery('only screen and (max-width : 480px)');
  const loading = useRootSelector(selectJournalLoading);
  const [isOpenCalendar, setOpenCalendar] = useState<boolean>(false);
  const [isOpenTime, setOpenTime] = useState<boolean>(false);
  const value = useMemo(() => model?.[type] || {}, [model, type]);
  const date = useMemo(() => (value?.observationTime ? new Date(value?.observationTime) : null), [value]);
  const id = useMemo(() => model?.id, [model]);
  const [errors, setError] = useState({});
  const isFeeling = useMemo(() => type === JournalType.Feeling, [type]);
  const handleClose = useCallback(() => {
    onSetJournalView(JournalViewType.VIEW);
  }, []);
  const handleEdit = useCallback(() => {
    let errors = {};
    unit
      .filter((k) => !isJournalUnitTypeValid(k, value?.[k]))
      .forEach((k) => {
        errors = { ...errors, [k]: [{ code: k, filed: k, message: null }] };
      });
    setError(errors);
    if (!isEmpty(errors)) {
      return;
    }
    onEditJournal(
      id
        ? { id, type, value: { ...value, observationTime: convertUtcToZonedTime(value?.observationTime, 'UTC') } }
        : { ...model, [type]: { ...value, observationTime: convertUtcToZonedTime(value?.observationTime, 'UTC') } }
    );
  }, [id, model, type, unit, value]);
  const getError = useCallback((k) => errors?.[k] || null, [errors]);
  const handleChange = useCallback(
    (key: string, val: any, force?: boolean) => {
      setError({});
      onSetJournalModel({
        ...(model || {}),
        [type]: { ...value, [key]: force ? val : getJournalUnitTypeMask(key, val, value?.[key] || '') },
      });
    },
    [model, type, value]
  );
  const onChangeDate = useCallback(
    (value: Date) => {
      const newDate = setHours(setMinutes(setSeconds(value, getSeconds(date)), getMinutes(date)), getHours(date));
      handleChange('observationTime', newDate, true);
      setOpenCalendar(false);
    },
    [date, handleChange]
  );
  const onChangeTime = useCallback(
    (value: Date) => {
      const newDate = setHours(setMinutes(setSeconds(value, getSeconds(date)), getMinutes(value)), getHours(value));
      handleChange('observationTime', isAfter(newDate, new Date()) ? new Date() : newDate, true);
    },
    [date, handleChange]
  );
  useEffect(
    () => () => {
      onSetJournalModel(null);
    },
    []
  );
  const canAdd = useMemo(() => !loading && unit.every((k) => !!value?.[k]), [loading, unit, value]);

  const hasError = useMemo(() => unit.some((v) => getError(v) !== null), [getError, unit]);

  const errorMessage: string[] = useMemo(() => {
    const code = unit.filter((v) => getError(v) !== null);
    return code.length
      ? code.map((code) => JournalUnitTypeErrorMessage[code].replace(':field', JournalUnitTypeTitle[code]))
      : [];
  }, [getError, unit]);

  const dateSting = useMemo(() => format(new Date(value?.observationTime), 'dd.MM.yyyy'), [value]);

  const timeSting = useMemo(() => format(new Date(value?.observationTime), 'HH:mm'), [value]);

  return (
    <>
      <Box className={styles.root}>
        <Box className={styles.root_title}>Добавление замера</Box>
        <Box className={styles.root_date}>
          <Box
            className={styles.root_date_item}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              setOpenCalendar(true);
            }}
          >
            <InputField label="Дата" className={styles.root_date_input} name="date" value={dateSting} block />
            <DateIcon className={styles.root_date_icon} />
          </Box>
          {isOpenCalendar && (
            <ClickAwayListener
              onClickAway={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setOpenCalendar(false);
              }}
            >
              <Box className={styles.root_date_popover}>
                <Calendar maxDate={new Date()} onChangeDate={onChangeDate} date={date} />
              </Box>
            </ClickAwayListener>
          )}
          <Box
            className={styles.root_date_item}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              setOpenTime(true);
            }}
          >
            <InputField label="Время" className={styles.root_date_input} name="time" value={timeSting} block />
            <ClockIcon className={styles.root_date_icon} />
          </Box>
          {isOpenTime && (
            <ClickAwayListener
              onClickAway={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setOpenTime(false);
              }}
            >
              <Box
                className={classNames(
                  styles.root_date_popover,
                  styles.root_date_popover_time,
                  media && styles.root_date_popover_time_fixed
                )}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <TimePicker
                    autoOk
                    ampm={false}
                    variant="static"
                    orientation="portrait"
                    openTo="minutes"
                    value={date}
                    onChange={onChangeTime}
                  />
                </MuiPickersUtilsProvider>
              </Box>
            </ClickAwayListener>
          )}
        </Box>
        <Box className={styles.root_values}>
          {unit.map((v, i) => (
            <Box key={i} className={styles.root_value}>
              {isFeeling ? (
                <InputField
                  maxLength={200}
                  type="textarea"
                  name={v}
                  className={styles.root_value_input_desc}
                  value={value?.[v] || ''}
                  onChange={(e: any) => handleChange(v, e.target.value)}
                  block
                />
              ) : (
                <InputField
                  className={styles.root_value_input}
                  name={v}
                  errors={getError(v)}
                  value={value?.[v] || ''}
                  onChange={(e: any) => handleChange(v, e.target.value)}
                  block
                />
              )}
              <Box className={styles.root_value_label}>{JournalUnitTypeTitle?.[v]}</Box>
            </Box>
          ))}
        </Box>
        <Box className={styles.root_values_error} style={{ visibility: !hasError ? 'hidden' : 'visible' }}>
          {errorMessage.join('\n')}
        </Box>
        {!isFeeling && (
          <Box className={styles.root_value}>
            <InputField
              maxLength={200}
              type="textarea"
              name="description"
              className={styles.root_value_input_desc}
              value={value?.description || ''}
              onChange={(e: any) => handleChange('description', e.target.value, true)}
              block
            />
            <Box className={styles.root_value_label}>Примечание</Box>
          </Box>
        )}
        <Box className={styles.root_actions}>
          <Button isLoading={loading} disabled={!canAdd} onClick={handleEdit} size="lg" type="button" color="primary">
            Добавить данные
          </Button>
          <Button disabled={loading} onClick={handleClose} size="lg" type="button" color="default">
            Отменить
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default JournalEdit;
