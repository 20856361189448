import React from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';

import Spinner from 'components/Spinner';
import useFileUploader, { FileType } from 'components/hooks/useFileUploader';

import { FileDataType } from 'types/file';

import styles from './DocumentFileInput.module.sass';

import DocumentView from './DocumentView';

type ChildrenProps = {
  open: () => void;
  addFile: (file: Partial<FileType>) => void;
  isDisabled: boolean;
};

type Props = {
  onLoadFiles: (ids: string[]) => void;
  initFiles?: FileDataType[];
  additionalFiles?: FileDataType[];
  disabled?: boolean;
  readOnly?: boolean;
  usePasteEvent?: boolean;
  maxFiles?: number;
  children?: (props: ChildrenProps) => void;
  rawStyle?: boolean;
  fileIds?: string[];
  accept?: string | string[];
  dropElement?: HTMLDivElement | null;
};

const DocumentFileInput = ({
  onLoadFiles,
  initFiles = [],
  additionalFiles = [],
  disabled,
  readOnly,
  rawStyle,
  maxFiles,
  children,
  fileIds = [],
  usePasteEvent = false,
  dropElement,
  accept,
}: Props) => {
  const isDisabled = disabled || readOnly;

  const onFailedLoadFile = React.useCallback(
    (fileName: string) => {
      toast.error(`Не удалось загрузить файл: ${fileName}`);
    },
    [null]
  );

  const { files, isLoading, getRootProps, getInputProps, isDragActive, removeFile, open, addFile } = useFileUploader({
    dropElement,
    usePasteEvent,
    onFailedLoadFile,
    initFiles,
    additionalFiles,
    disabled: isDisabled,
    multiple: true,
    accept: accept === '*' ? '' : '.pdf, .xls, .xlsx, .doc, .docx, .jpg, .jpeg, .png',
  });

  const loadedFiles = React.useMemo(
    () => files.filter((file) => file.isLoaded).slice(0, (maxFiles = files.filter((file) => file.isLoaded).length)),
    [files]
  );

  React.useEffect(() => {
    onLoadFiles(loadedFiles.map((item) => item.id));
  }, [loadedFiles]);

  const handleOpenFile = React.useCallback(
    (url: string) => {
      if (!url) {
        return;
      }

      window.open(url);
    },
    [files]
  );

  const fieldfiles = loadedFiles.filter((file) => fileIds.includes(file.id));
  const rootProps = loadedFiles.length || isLoading || isDisabled ? {} : getRootProps();

  return (
    <div>
      <div
        {...rootProps}
        className={classNames({
          [styles.inputFileContainerDragndrop]: !rawStyle,
          [styles.inputFileContainerDragndrop_drag_over]: isDragActive,
          [styles.inputFileContainerDragndrop_has_files]: !!fieldfiles.length && !rawStyle,
          [styles.inputFileContainerDragndrop_is_disabled]: isDisabled,
        })}
      >
        {isLoading && (
          <div className={styles.spinner}>
            <Spinner />
          </div>
        )}

        {!isLoading && !fieldfiles.length && <span>Выберите документ</span>}

        {!!fieldfiles.length && (
          <div className={styles.returnFilesList}>
            {fieldfiles.map((fileData, index) => (
              <DocumentView
                key={`document-view-${index}`}
                doc={index}
                data={fileData}
                onFileClick={handleOpenFile}
                onFileRemove={removeFile}
              />
            ))}
          </div>
        )}

        <input {...getInputProps()} />
      </div>

      {children({ isDisabled, open, addFile })}
    </div>
  );
};

export default DocumentFileInput;
