import Manage from "../../types/manage";
import {PARTNERS_ERROR, PARTNERS_LOADING, PARTNERS_SUCCESS} from "../actions/partner/managePartners.actions";


export interface ManagePartnersSettings {
  items?: Array<Manage.Partners.Item>,
  page?: number;
  pageSize?: number;
  totalItems?: number;
  hasMore?: boolean;
  errors?: any;
  loading?: boolean;
}

const initState: ManagePartnersSettings = {
  errors: null, hasMore: false, items: [], loading: false, page: 0, pageSize: 50, totalItems: 0
}

export const managePartnersReducer = (state = initState, action): ManagePartnersSettings => {
  switch (action.type) {
    case PARTNERS_LOADING:
      return {
        ...state,
        items: action.loading && action.reset ? [] : state.items,
        loading: action.loading,
      }
    case PARTNERS_ERROR:
      return {
        ...state,
        errors: action.errors,
      }
    case PARTNERS_SUCCESS:
      return {
        ...state,
        items: action.page > 0 ? state.items.concat(action.items) : action.items,
        page: action.page,
        pageSize: action.pageSize,
        totalItems: action.totalItems,
        hasMore: (action.page + 1) * action.pageSize + action.pageSize < action.totalItems && action.totalItems > 0,
        errors: null,
      }
    default:
      return state
  }
}
