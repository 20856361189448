import {ReactComponent as CrossIcon} from 'assets/img/icons/cross-icon.svg';
import React, {useEffect, useState} from "react";
import styles from './DocumentFileInput.module.sass';
import FileLink from 'components/FileLink';
import {getResource} from "../../../api";
import {FileDataType} from "../../../types/file";

export type DocumentViewProps = {
  doc: string | number
  data: {
    id: string
  }
  onFileClick: (url: string)  => void
  onFileRemove: (file: any) =>  void
}

const DocumentView = (props: DocumentViewProps) => {

  const {doc, data} = props

  const [file, setFile] = useState<FileDataType>(null)


  const downloadResource = async (id: string) => {
    const res = await getResource(id)
    return res.data.data
  }

  const handleOpenFile = () => {
    props.onFileClick(file?.originalUrl)
  }

  const handleRemoveFile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    props.onFileRemove(data)
  }

  useEffect(() => {
    downloadResource(data.id).then(res => setFile(res))
  }, [data.id])

  return (
    <div
      key={doc}
      className={styles.fileToUpload}
      style={{backgroundImage: `url(${file?.originalUrl})`}}
      onClick={handleOpenFile}
    >
      {file && <div className={styles.fileIcon}>
        <FileLink resource={file}/>
      </div>}

      <div className={styles.fileRemoveIcon} onClick={handleRemoveFile}>
        <CrossIcon className={styles.fileRemoveIcon_icon}/>
      </div>
    </div>
  )

}

export default DocumentView
