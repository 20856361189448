import React, { ReactElement, useCallback } from 'react';
import { Box } from '@material-ui/core';

import classNames from 'classnames';
import { useStore } from 'effector-react';
import { tabs } from '../../constants';
import $store from '../../store';
import { AgentSaleType } from '../../types';
import { onSetAgentSaleType } from '../../store/events';

const AgentSaleHeader = (): ReactElement => {
  const { type, loading } = useStore($store);
  const handleChange = useCallback(
    (k: AgentSaleType) => {
      !loading && onSetAgentSaleType(k);
    },
    [loading]
  );
  return (
    <>
      <Box className="page-header-menu d-flex flex-column flex-sm-row align-items-center justify-content-between flex-nowrap">
        {Object.keys(tabs).map((k) => (
          <Box
            key={k}
            width="100%"
            onClick={() => handleChange(AgentSaleType?.[k])}
            className={classNames('page-header-menu-item', {
              'page-header-menu-item-active': type === k,
            })}
          >
            {tabs[k]}
          </Box>
        ))}
      </Box>
    </>
  );
};

export default AgentSaleHeader;
