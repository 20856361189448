import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import ContentCard from 'components/ContentCard';
import { ReactComponent as AngleLeftIcon } from 'assets/img/icons/angle-left-icon.svg';
import PaymentView from './PaymentView';
import InfoView from './InfoView';

const PaymentPage = (): JSX.Element => {
  const history = useHistory();
  const [paymentViewType, setPaymentViewType] = useState('PAYMENT');

  const getPaymentViewHeader = (paymentViewType: string) => {
    let paymentView = null;

    switch (paymentViewType) {
      case 'PAYMENT':
        paymentView = (
          <div className="row">
            <div className="col-xs-4 text-center text-xs-left mb-2 mb-xs-0">
              <div className="page-header-back-link" onClick={() => history.push('/')}>
                <AngleLeftIcon className="page-header-back-link-icon" />
                Назад
              </div>
            </div>
            <div className="col-xs-8 col-md-4 text-center text-xs-right text-md-center">
              <h6 className="page-header-title">Завершение и оплата</h6>
            </div>
          </div>
        );
        break;

      case 'INFO':
        paymentView = <h6 className="page-header-title text-center">Формирование заявки</h6>;
        break;
      default:
        paymentView = null;
        break;
    }

    return paymentView;
  };

  const getPaymentView = (paymentViewType: string) => {
    let paymentView = null;

    switch (paymentViewType) {
      case 'PAYMENT':
        paymentView = <PaymentView setPaymentViewType={setPaymentViewType} />;
        break;

      case 'INFO':
        paymentView = <InfoView />;
        break;
      default:
        paymentView = null;
        break;
    }

    return paymentView;
  };

  return (
    <ContentCard>
      <ContentCard.Header>{getPaymentViewHeader(paymentViewType)}</ContentCard.Header>

      <ContentCard.Main>{getPaymentView(paymentViewType)}</ContentCard.Main>
    </ContentCard>
  );
};

export default PaymentPage;
