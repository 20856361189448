import axios from 'utils/axios';

export const getDoctorFutureWorkShifts = (doctorId: string, timeFrom?: Date, timeTo?: Date) => {
  return axios.get(`/api/v1/doctors/${doctorId}/workshifts`, {
    params: {
      timeFrom,
      timeTo,
    },
  });
};
