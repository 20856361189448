import { AxiosResponse } from 'axios';
import Manage from '../../types/manage';
import { findDoctors } from '../../api/findDoctors';

export const MANAGE_DOCTORS_LOADING = 'MANAGE_DOCTORS_LOADING';
export const MANAGE_DOCTORS_SUCCESS = 'MANAGE_DOCTORS_SUCCESS';
export const MANAGE_DOCTORS_ERRORS = 'MANAGE_DOCTORS_ERRORS';
export const MANAGE_DOCTOR_UPDATE = 'MANAGE_DOCTOR_UPDATE';
export const MANAGE_DOCTOR_UPDATE_ACTIVE = 'MANAGE_DOCTOR_UPDATE_ACTIVE';

export const setManageDoctorsLoading = (loading: boolean, reset?: boolean) => ({
  type: MANAGE_DOCTORS_LOADING,
  loading,
  reset,
});

export const setManageDoctorsErrors = (errors: any) => ({ type: MANAGE_DOCTORS_ERRORS, errors });

export const updateManageDoctorStatus = (doctorId: string, status: string) => ({
  type: MANAGE_DOCTOR_UPDATE,
  doctorId,
  status,
});

export const updateManageDoctorStatusActive = (doctorId: string, active: boolean) => ({
  type: MANAGE_DOCTOR_UPDATE_ACTIVE,
  doctorId,
  active,
});

export const setManageDoctors = (
  doctors: Manage.Doctor.Item[],
  page: number,
  pageSize: number,
  totalItems: number
) => ({
  type: MANAGE_DOCTORS_SUCCESS,
  doctors,
  page,
  pageSize,
  totalItems,
});

export const fetchManageDoctors = (
  query: string,
  availability?: string,
  page?: number,
  pageSize?: number,
  reset?: boolean
): any => {
  return (dispatch) => {
    dispatch(setManageDoctorsLoading(true, reset));
    findDoctors(query, availability, page, pageSize)
      .then((response: AxiosResponse<Manage.Doctor.Response>) => {
        dispatch(setManageDoctorsLoading(false));
        if (response.data.code === 'success') {
          dispatch(
            setManageDoctors(
              response.data.data.items,
              response.data.data.page,
              response.data.data.pageSize,
              response.data.data.totalItems
            )
          );
        } else {
          dispatch(setManageDoctorsErrors(response.data));
        }
      })
      .catch((error: any) => {
        dispatch(setManageDoctorsErrors(error));
        dispatch(setManageDoctorsLoading(false));
      });
  };
};
