import React, { useCallback, useState } from 'react';
import {
  addHours,
  differenceInHours,
  endOfDay,
  isBefore,
  isSameDay,
  isSameHour,
  isSameMinute,
  setHours,
  setMinutes,
  setSeconds,
  startOfDay,
} from 'date-fns';
import { Backdrop, Box, Dialog, Popover } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import classNames from 'classnames';
import Button from '../Forms/Button';
import { format } from '../../utils/date';
import Calendar from '../Calendar';
import styles from './SchedulerEdit.module.sass';
import { Scheduler } from '../../types';
import ContentCard from '../ContentCard';
import CustomCheckbox from '../Forms/CustomCheckbox';

export interface SchedulerEditInterface {
  loading?: boolean;
  isAdmin?: boolean;
  scheduler?: Scheduler;
  onClose: () => void;
  onSave: (scheduler: Scheduler, allMonth?: boolean) => void;
}

const SchedulerEdit = (props: SchedulerEditInterface) => {
  const {
    scheduler,
    loading = false,
    isAdmin = false,
    onSave = (scheduler: Scheduler, allMonth?: boolean) => {},
    onClose = () => {},
  } = props;
  const [allMonth, setAllMonth] = useState<boolean>(false);
  const [anchorCalendar, setOpenCalendar] = useState<HTMLButtonElement | null>(null);
  const isOpenCalendar = Boolean(anchorCalendar);
  const [anchorTime, setOpenTime] = useState<{ anchor: HTMLButtonElement; endTime?: boolean } | null>(null);
  const isOpenTime = Boolean(anchorTime);
  const [model, setScheduler] = useState(scheduler);
  useCallback(() => {
    setScheduler(scheduler);
  }, [scheduler]);
  const onChangeDate = (data: Date): void => {
    const { startTime, endTime } = model;
    setScheduler({
      ...model,
      startTime: setHours(setMinutes(data, startTime.getMinutes()), startTime.getHours()),
      endTime: setHours(setMinutes(data, endTime.getMinutes()), endTime.getHours()),
    });
    setOpenCalendar(null);
  };
  const onChangeTime = (data: Date, isEnd?: boolean): void => {
    const { startTime, endTime } = model;
    if (isEnd) {
      endTime.setTime(data.getTime());
    } else {
      startTime.setTime(data.getTime());
      if (differenceInHours(startTime, endOfDay(startTime)) >= -1) {
        endTime.setTime(endOfDay(startTime).getTime());
      } else if (differenceInHours(startTime, endTime) > -1) {
        endTime.setTime(addHours(startTime, 2).getTime());
      }
    }
    setScheduler({
      ...model,
      startTime,
      endTime,
    });
  };
  const isDisabledTime = (date: Date, isEnd?: boolean): boolean => {
    const { startTime } = model;
    if (isEnd) {
      return differenceInHours(startTime, date) > -1;
    }
    return isBefore(date, new Date());
  };
  return (
    <Dialog
      PaperProps={{ className: classNames(styles.Dialog) }}
      BackdropComponent={(props) => <Backdrop className={styles.Backdrop} {...props} />}
      onClose={onClose}
      open
    >
      <ContentCard className={classNames(['Modal', styles.SchedulerEdit])}>
        <ContentCard.Header className={classNames(['Modal-header', styles.SchedulerEdit_Header])}>
          <h6 className="page-header-title">Создайте расписание</h6>
          <CloseIcon onClick={onClose} className={classNames(['Modal-close', styles.SchedulerEdit_CloseIcon])} />
        </ContentCard.Header>
        <ContentCard.Main className={classNames(['Modal-content', styles.SchedulerEdit_Content])}>
          <Box width="100%" className="align-items-center justify-content-between d-flex p-3">
            <Box width="50%" className="align-items-center justify-content-start d-flex">
              <AccessTimeIcon className={styles.SchedulerEdit_AccessTimeIcon} />
              <Box
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  setOpenCalendar(event.currentTarget);
                }}
                className={classNames(
                  styles.SchedulerEdit_Time,
                  styles.SchedulerEdit_Time_Toggle,
                  isSameDay(new Date(), model.startTime) && styles.SchedulerEdit_Time_Current
                )}
              >
                {format(model.startTime, 'd MMMM yyyy')}
              </Box>
              <Popover
                PaperProps={{ className: classNames(styles.SchedulerEdit_Popover, styles.SchedulerEdit_Popover_Date) }}
                open={isOpenCalendar}
                anchorEl={anchorCalendar}
                onClose={() => setOpenCalendar(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Box p={2}>
                  <Calendar onChangeDate={onChangeDate} date={model.startTime} />
                </Box>
              </Popover>
            </Box>
            <Box width="50%" className="align-items-center justify-content-start d-flex">
              <Box
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  setOpenTime({ anchor: event.currentTarget, endTime: false });
                }}
                className={classNames(
                  styles.SchedulerEdit_Time,
                  styles.SchedulerEdit_Time_Toggle,
                  anchorTime && !anchorTime.endTime && styles.SchedulerEdit_Time_Current
                )}
              >
                {format(model.startTime, 'HH:mm')}
              </Box>
              <Box className={styles.SchedulerEdit_Time}>-</Box>
              <Box
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  setOpenTime({ anchor: event.currentTarget, endTime: true });
                }}
                className={classNames(
                  styles.SchedulerEdit_Time,
                  styles.SchedulerEdit_Time_Toggle,
                  anchorTime && anchorTime.endTime && styles.SchedulerEdit_Time_Current
                )}
              >
                {format(model.endTime, 'HH:mm')}
              </Box>
              <Popover
                PaperProps={{ className: classNames(styles.SchedulerEdit_Popover, styles.SchedulerEdit_Popover_Time) }}
                open={isOpenTime}
                anchorEl={anchorTime && anchorTime.anchor}
                onClose={() => setOpenTime(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Box className="d-flex flex-column align-items-center justify-content-start p-2">
                  {Array.from(Array(anchorTime && anchorTime.endTime ? 25 : 24).keys())
                    .map((h: number, index: number) =>
                      setHours(
                        setMinutes(
                          setSeconds(
                            startOfDay(anchorTime && anchorTime.endTime ? model.endTime : model.startTime),
                            index === 24 ? 59 : 0
                          ),
                          index === 24 ? 59 : 0
                        ),
                        index === 24 ? index - 1 : h
                      )
                    )
                    .map((h: Date) => (
                      <Box
                        key={h.valueOf()}
                        onClick={() => {
                          if (!isDisabledTime(h, anchorTime && anchorTime.endTime)) {
                            onChangeTime(h, anchorTime && anchorTime.endTime);
                            setOpenTime(null);
                          }
                        }}
                        className={classNames(
                          styles.SchedulerEdit_Popover_Time_SetTime,
                          isDisabledTime(h, anchorTime && anchorTime.endTime) &&
                            styles.SchedulerEdit_Popover_Time_SetTime_Disabled,
                          anchorTime &&
                            ((!anchorTime.endTime &&
                              model.startTime &&
                              isSameHour(model.startTime, h) &&
                              isSameMinute(model.startTime, h)) ||
                              (anchorTime.endTime &&
                                model.endTime &&
                                isSameHour(model.endTime, h) &&
                                isSameMinute(model.endTime, h))) &&
                            styles.SchedulerEdit_Popover_Time_SetTime_Active
                        )}
                      >
                        {format(h, 'HH:mm')}
                      </Box>
                    ))}
                </Box>
              </Popover>
            </Box>
          </Box>
          {isAdmin && (
            <Box width="100%" className="align-items-center justify-content-between d-flex p-3">
              <CustomCheckbox
                className="flex-row-reverse d-flex"
                checked={allMonth}
                onChange={(event) => setAllMonth(event.target.checked)}
                label="Применить расписание на месяц"
              />
            </Box>
          )}
          <Box width="100%" className="d-flex justify-content-between align-items-center p-3">
            <Button type="button" block size="md" className="mr-1" onClick={onClose} color="default">
              Отмена
            </Button>
            <Button
              type="button"
              block
              size="md"
              className="ml-1"
              disabled={loading}
              isLoading={loading}
              onClick={() => onSave(model, allMonth)}
              color="primary"
            >
              Сохранить
            </Button>
          </Box>
        </ContentCard.Main>
      </ContentCard>
    </Dialog>
  );
};
export default SchedulerEdit;
