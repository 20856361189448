import React, { PropsWithChildren, useCallback, useMemo } from 'react';
import { Box } from '@material-ui/core';
import { BoxProps } from '@material-ui/core/Box/Box';
import { decodeAvatarResource, getWordNumEnding } from 'utils';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import styles from './DoctorItem.module.sass';
import Button from '../Forms/Button';

interface PropsInterface extends PropsWithChildren<BoxProps> {
  item: any;
  reviews?: boolean;
  favorite?: boolean;
}
const DoctorItem = (props: PropsInterface): JSX.Element => {
  const { item, children, reviews = false, favorite = false, ...rest } = props;
  const history = useHistory();
  const goToProfile = useCallback(() => {
    history.push(`/doctor/${item?.id}/profile`);
  }, [history, item]);
  const seniority = useMemo(
    () => `${item?.seniority || 0} ${getWordNumEnding(item?.seniority || 0, ['год', 'года', 'лет'])} опыта`,
    [item]
  );
  const feedback = useMemo(
    () =>
      `${item?.publicFeedbackCount || 0} ${getWordNumEnding(item?.publicFeedbackCount || 0, [
        'отзыв',
        'отзыва',
        'отзывов',
      ])}`,
    [item]
  );
  return (
    <Box className={styles.root} {...rest}>
      {favorite && (
        <Box className={classNames(styles.root_favorite, item?.favorite === true && styles.root_favorite_active)}>
          <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M18.4412 9.56642L10.8982 17.3672C10.4045 17.8777 9.5986 17.8781 9.10485 17.3676L1.55798 9.56683C-0.633431 7.30079 -0.511946 3.5586 1.9154 1.45548C4.03493 -0.379678 7.28102 -0.0460846 9.23024 1.96798L9.99977 2.75978L10.7693 1.96798C12.7205 -0.0460846 15.9295 -0.379678 18.0857 1.45548C20.5115 3.5547 20.6326 7.30079 18.4412 9.56642Z"
              fill="#DEEFFF"
            />
          </svg>
        </Box>
      )}
      <div className={styles.root_wrapper}>
        <div className={styles.root_avatar}>
          <div style={{ backgroundImage: `url(${decodeAvatarResource(item?.avatar, 360)})` }} />
        </div>
        <div className={styles.root_about}>
          <div className={styles.root_specialization}>{item?.specialization?.name}</div>
          <div className={styles.root_name}>
            {item?.firstName} {item?.middleName} {item?.lastName}
          </div>
          {reviews && (
            <div className={styles.root_reviews}>
              <Box className={styles.root_reviews_seniority}>{seniority}</Box>
              <Box className={styles.root_reviews_feedback}>{feedback}</Box>
            </div>
          )}
        </div>
      </div>
      <div className={styles.root_actions}>
        <>
          {children}
          <Button type="submit" onClick={goToProfile} block size="lg" color="default">
            Подробнее
          </Button>
        </>
      </div>
    </Box>
  );
};

export default DoctorItem;
