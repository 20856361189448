import { MANAGE_CATEGORIES_LOADING, MANAGE_CATEGORIES_SUCCESS, MANAGE_CATEGORIES_ERRORS } from '../actions';
import Manage from '../../types/manage';

export interface ManageCategoriesReducerInitialSettings {
  categories?: Manage.Category.Item[];
  errors?: any;
  loading?: boolean;
}
const initialSettings: ManageCategoriesReducerInitialSettings = {
  categories: [],
  loading: false,
  errors: null,
};

const manageCategoriesReducer = (state = initialSettings, action): ManageCategoriesReducerInitialSettings => {
  switch (action.type) {
    case MANAGE_CATEGORIES_LOADING:
      return {
        ...state,
        categories: [],
        loading: action.loading,
      };
    case MANAGE_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.categories,
        errors: null,
      };
    case MANAGE_CATEGORIES_ERRORS:
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
};

export default manageCategoriesReducer;
