import { Fields } from '../../../../constants/fields';
import { ErrorCode } from '../../../../components/Forms/FormValidator/FormValidator';
import { AgentSaleType } from '../types';

export const validatorPsp: Record<string, Array<ErrorCode | [ErrorCode, any]>> = {
  [Fields.Phone]: [ErrorCode.Required, ErrorCode.Phone],
  [Fields.ConfirmCode]: [ErrorCode.Required, ErrorCode.Code],
  [Fields.InternalTariffId]: [ErrorCode.Required],
};

export const validatorUserPsp: Record<string, Array<ErrorCode | [ErrorCode, any]>> = {
  [Fields.Email]: [ErrorCode.Email, [ErrorCode.Maxlen, 36]],
  [Fields.Sex]: [ErrorCode.Required],
  [Fields.Birthday]: [ErrorCode.Required, ErrorCode.Birthday],
  [Fields.FirstName]: [ErrorCode.Required, ErrorCode.Name, [ErrorCode.Maxlen, 36]],
  [Fields.LastName]: [ErrorCode.Required, ErrorCode.Name, [ErrorCode.Maxlen, 36]],
  [Fields.MiddleName]: [ErrorCode.Required, ErrorCode.Name, [ErrorCode.Maxlen, 36]],
  ...validatorPsp,
};

export const getValidator = (type: AgentSaleType) => (type === AgentSaleType.NEW ? validatorUserPsp : validatorPsp);
