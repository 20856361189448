import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import uuid from 'react-uuid';
import MaskedInput from 'react-input-mask';
import numeral from 'numeral';
import { InputError } from 'types';
import { localStringToNumber } from 'utils/helpers';
import { ReactComponent as EyeOffIcon } from 'assets/img/icons/eye-off.svg';
import SearchIcon from '@material-ui/icons/Search';
import TextareaAutosize from 'react-autosize-textarea';
import FormLabel from '../FormLabel';
import FormGroup from '../FormGroup';
import FormInvalidMessage from '../FormInvalidMessage';
import './InputField.sass';

interface PropsInterface {
  type?: 'text' | 'number' | 'password' | 'money' | 'percent' | 'textarea';
  name?: string;
  label?: string;
  value?: string;
  mask?: string;
  beforeMaskedValueChange?: any;
  className?: string;
  invalid?: boolean;
  onChange?: (...args: any) => any;
  onBlur?: (...args: any) => any;
  onFocus?: (...args: any) => any;
  readOnly?: boolean;
  disabled?: boolean;
  required?: boolean;
  filled?: boolean;
  searchBar?: boolean;
  searchBarIcon?: boolean;
  minLength?: number;
  rows?: number;
  cols?: number;
  maxLength?: number;
  min?: number;
  max?: number;
  block?: boolean;
  placeholder?: string;
  errors?: InputError[];
  autoresize?: boolean;
  [propName: string]: any;
}

const InputField = (props: PropsInterface) => {
  const {
    label,
    className,
    invalid,
    block = false,
    autoresize = false,
    id,
    mask = null,
    errors,
    value,
    disabled = false,
    readOnly = false,
    searchBar = false,
    searchBarIcon = false,
    filled = false,
    innerRef,
    beforeMaskedValueChange,
    type = 'text',
    onChange = () => {},
    onBlur = () => {},
    onFocus = () => {},
    ...attributes
  } = props;
  const [inputFieldId] = useState(`inputField-${uuid()}`);

  const [showPassword, setShowPassword] = useState(false);

  const isPassword = useMemo(() => type === 'password', [type]);
  const isTextarea = useMemo(() => type === 'textarea', [type]);
  const isNumber = useMemo(() => type === 'number', [type]);
  const isMoney = useMemo(() => type === 'money', [type]);
  const isPercent = useMemo(() => type === 'percent', [type]);
  const isMoneyOrPercent = useMemo(() => isMoney || isPercent, [isMoney, isPercent]);

  const toggleShowPassword = useCallback(() => {
    setShowPassword((showPassword) => !showPassword);
  }, []);

  const handleBlur = useCallback(
    (e: any) => {
      if (type === 'money' && !mask) {
        const value = e.target.value;
        const currency = 'RUB';
        const options = {
          maximumFractionDigits: 2,
          currency,
          style: 'currency',
          currencyDisplay: 'symbol',
        };
        e.target.value = value ? localStringToNumber(value).toLocaleString(undefined, options) : '';
        onChange(e);
      } else if (type === 'percent' && !mask) {
        const value = e.target.value;
        e.target.value = value ? numeral(numeral(value.replace(',', '.')).value() / 100).format('0.00%') : '';
        onChange(e);
      }
      onBlur(e);
    },
    [mask, onBlur, onChange, type]
  );

  const handleFocus = useCallback(
    (e: any) => {
      if (type === 'money' && !mask) {
        const value = e.target.value;

        e.target.value = e.target.value
          ? localStringToNumber(value.split(',')[0]) + localStringToNumber(value.split(',')[1]) / 100
          : '';
      } else if (type === 'percent' && !mask) {
        const value = e.target.value;

        e.target.value = e.target.value ? numeral(numeral(value).value() * 100).format('0.00') : '';
      }
      onFocus(e);
    },
    [mask, onFocus, type]
  );

  const handleChange = useCallback(
    (e: ChangeEvent<any>) => {
      if (disabled || readOnly) {
        e.preventDefault();
        return;
      }
      onChange(e);
    },
    [disabled, onChange, readOnly]
  );

  const finalAttributes = useMemo<any>(() => {
    const _attributes = {
      ...attributes,
      type: isPassword && showPassword ? 'text' : type,
      ...(!mask ? {} : { mask, beforeMaskedValueChange }),
    };
    if (!isNumber) {
      delete _attributes.min;
      delete _attributes.max;
    }

    if (!isTextarea) {
      delete _attributes.rows;
      delete _attributes.cols;
    }

    if (isMoneyOrPercent) {
      _attributes.type = 'text';
    }

    return {
      ..._attributes,
      disabled: disabled || readOnly,
      readOnly,
      id: id || inputFieldId,
      className: classNames(className, 'fg-input', {
        'input-block': block,
        is_searchBar: searchBar,
        'is-invalid': invalid || (errors && errors.length > 0),
        'input-filled': filled,
      }),
      onChange: handleChange,
      onBlur: handleBlur,
      onFocus: handleFocus,
      ref: innerRef,
      value: value ? value : '', // eslint-disable-line
    };
  }, [
    attributes,
    isPassword,
    showPassword,
    type,
    mask,
    beforeMaskedValueChange,
    isNumber,
    isTextarea,
    isMoneyOrPercent,
    disabled,
    readOnly,
    id,
    inputFieldId,
    className,
    block,
    searchBar,
    invalid,
    errors,
    filled,
    handleChange,
    handleBlur,
    handleFocus,
    innerRef,
    value,
  ]);

  const onKeyPress = useCallback(
    (event: any) => {
      !event.ctrlKey && event.charCode === 13 && event.preventDefault();
      if (event.ctrlKey && event.charCode === 13) {
        handleChange({
          ...event,
          target: {
            ...event.target,
            value: `${value}\n`,
          },
        });
      }
    },
    [handleChange, value]
  );

  const renderInput = useMemo(() => {
    if (mask) {
      return <MaskedInput {...finalAttributes} />;
    }
    if (isTextarea) {
      return autoresize ? (
        <TextareaAutosize {...finalAttributes} onKeyPress={onKeyPress} />
      ) : (
        <textarea {...finalAttributes} />
      );
    }
    return <input {...finalAttributes} />;
  }, [autoresize, finalAttributes, isTextarea, mask]);

  return (
    <>
      {!!label && <FormLabel invalid={invalid || (errors && errors.length > 0)}>{label}</FormLabel>}
      {isPassword && (
        <FormGroup className="FormGroup-password">
          {renderInput}
          <EyeOffIcon
            className={classNames('FormGroup-password-icon', showPassword && 'FormGroup-password-icon-active')}
            onClick={toggleShowPassword}
          />
        </FormGroup>
      )}
      {searchBarIcon && searchBar && (
        <FormGroup className="FormGroup-SearchBar">
          <SearchIcon className="FormGroup-SearchBar-Icon" />
          {renderInput}
        </FormGroup>
      )}
      {!isPassword && !(searchBarIcon && searchBar) && renderInput}
      <FormInvalidMessage>{errors?.[0]?.message}</FormInvalidMessage>
    </>
  );
};

export default InputField;
