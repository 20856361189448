import { AxiosResponse } from 'axios';
import { ResponseData } from '../../types';
import Manage from '../../types/manage';
import axios from '../../utils/axios';

const managePartnersApi = {
  loadFile: (file: File): Promise<AxiosResponse<ResponseData<Manage.Partners.PartnersResponse>>> => {
    const data = new FormData();
    data.append('excelFile', file);
    return axios.post('/api/v1/admin/psp/file', data).then((data) => data?.data?.data);
  },

  getPartners: (
    request: Manage.Partners.PartnersRequest
  ): Promise<AxiosResponse<ResponseData<Manage.Partners.PartnersResponse>>> => {
    return axios.get('/api/v1/partner/', {
      params: {
        ...request,
      },
    });
  },

  getAgents: (
    partnerId: string,
    filter: Manage.Agent.AgentFilter
  ): Promise<AxiosResponse<ResponseData<Manage.Agent.AgentsResponse>>> => {
    return axios.get(`/api/v1/partner/${partnerId}/agent`, {
      params: {
        ...filter,
      },
    });
  },

  createAgent: (
    partnerId: string,
    request: Manage.Agent.EditRequest
  ): Promise<AxiosResponse<ResponseData<{ id: string }>>> => {
    return axios.post(`/api/v1/partner/${partnerId}/agent`, request);
  },

  createPartner: (request: Manage.Partners.EditRequest): Promise<AxiosResponse<ResponseData<{ id: string }>>> => {
    return axios.post('/api/v1/partner/', request);
  },

  updatePartner: (id: string, request: Manage.Partners.EditRequest): Promise<AxiosResponse<ResponseData<boolean>>> => {
    return axios.put(`/api/v1/partner/${id}`, request);
  },
};

export default managePartnersApi;
