import { getServicePackageMyId } from 'api';
import { getAgentSp } from '../../views/Agent/AgentSale/api';
import { Fields } from '../../constants/fields';

export const PACKAGE_VIEW_LOADING = 'PACKAGE_VIEW_LOADING';
export const PACKAGE_VIEW_ERROR = 'PACKAGE_VIEW_ERROR';
export const PACKAGE_VIEW_SUCCESS = 'PACKAGE_VIEW_SUCCESS';

export const packageViewLoading = (isLoading: boolean) => ({ type: PACKAGE_VIEW_LOADING, isLoading });

export const packageViewSuccess = (result: any) => {
  let servicePackage = null;

  if (result && result.data) {
    servicePackage = { ...result.data };
  }

  return { type: PACKAGE_VIEW_SUCCESS, servicePackage };
};

export const packageViewError = (isError: boolean) => ({ type: PACKAGE_VIEW_ERROR, isError });

export const fetchPackageView = (patientServicePackageId: string) => {
  return (dispatch) => {
    dispatch(packageViewLoading(true));
    dispatch(packageViewError(false));

    getServicePackageMyId(patientServicePackageId)
      .then((response) => {
        dispatch(packageViewLoading(false));
        dispatch(packageViewSuccess(response.data));
      })
      .catch((error) => {
        dispatch(packageViewLoading(false));
        dispatch(packageViewError(true));
      });
  };
};

export const fetchPackageViewAgent = (packageId: string) => {
  return (dispatch) => {
    dispatch(packageViewLoading(true));
    dispatch(packageViewError(false));

    getAgentSp()
      .then((response) => {
        const servicePackage = response.data?.data?.find(({ [Fields.Id]: id }) => id === packageId) || null;
        if (!servicePackage) {
          return new Error();
        }
        dispatch(packageViewLoading(false));
        dispatch({ type: PACKAGE_VIEW_SUCCESS, servicePackage });
      })
      .catch(() => {
        dispatch(packageViewLoading(false));
        dispatch(packageViewError(true));
      });
  };
};
