import axios from 'utils/axios';

export const createServicePackageAttachment = (servicePackageId: number, data: any, isGroup?: boolean) => {
  return axios.post(
    isGroup
      ? `/api/v1/servicepackagegroups/attachments/${servicePackageId}`
      : `/api/v1/servicepackages/mfo/${servicePackageId}/attachments`,
    data
  );
};
