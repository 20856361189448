import {EnumDictionary} from "../../../../../types/EnumDictionary";
import Manage from "../../../../../types/manage";
import React from "react";
import ReportDropdownMenu from "../../../../../components/ReportDropdownMenu/ReportDropdownMenu";

interface SpecGroupStatusFilterViewProps {
  isOpen: boolean
  onToggle: (open: boolean) => void
  onSelectChanged: (values: Array<Manage.Spec.GroupStatus>) => void
}

const statusValues: Array<EnumDictionary<Manage.Spec.GroupStatus>> = [
  {name: 'Новая', value: "NEW"},
  {name: 'Активна', value: "ACTIVE"},
  {name: 'В архиве', value: "ARCHIVE"}
]

const SpecGroupStatusFilterView: React.FC<SpecGroupStatusFilterViewProps> = (props) => {

  const {isOpen, onToggle, onSelectChanged} = props

  return (
    <ReportDropdownMenu items={statusValues} isOpen={isOpen} title="Статусы" onToggle={onToggle}
                        onSelectChanged={onSelectChanged}/>
  )

}

export default SpecGroupStatusFilterView
