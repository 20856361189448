import axios from 'utils/axios';

export const findAdminAppointmentItems = (
  patientIds: string[],
  doctorIds: string[],
  statuses?: string[],
  types?: string[],
  payedWithPromo?: boolean,
  timeFrom?: Date,
  timeTo?: Date,
  page?: number,
  pageSize?: number
) => {
  page = page || 0;
  pageSize = pageSize || 10;
  statuses = statuses || [];
  types = types || [];
  patientIds = patientIds || [];
  payedWithPromo = payedWithPromo === true ? true : payedWithPromo === false ? false : null;
  return axios.get(`/api/v1/admin/appointments`, {
    params: {
      timeFrom,
      timeTo,
      payedWithPromo,
      patientIds: patientIds.length ? patientIds.join(',') : null,
      doctorIds: doctorIds.length ? doctorIds.join(',') : null,
      statuses: statuses.length ? statuses.join(',') : null,
      types: types.length ? types.join(',') : null,
      page,
      pageSize,
    },
  });
};
