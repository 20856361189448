import axios from 'utils/axios';

export const findReportCDSummary = (
  page?: number,
  pageSize?: number
) => {
  page = page || 0;
  pageSize = pageSize || 365;
  return axios.get(`/api/v1/admin/reports/closedaysummary`, {
    params: {
      page,
      pageSize,
    },
  });
};

export const getReportCDSummaryAsCsv = (
  page?: number,
  pageSize?: number
) => {
  page = page || 0;
  pageSize = pageSize || 365;
  return axios.get(`/api/v1/admin/reports/closedaysummary.csv`, {
    responseType: 'blob',
    params: {
      page,
      pageSize,
    },
  });
};

export const getReportCDSummaryAsExcel = (
  page?: number,
  pageSize?: number,
  force?: boolean
) => {
  page = page || 0;
  pageSize = pageSize || 365;
  return axios.get(`/api/v1/admin/reports/closedaysummary.xlsx`, {
    responseType: 'blob',
    params: {
      page,
      pageSize,
      force
    },
  });
};
