const STORAGE_KEY = 'redirect';

export const redirectState = (() => {
  try {
    const pathname = localStorage.getItem(STORAGE_KEY) || '';

    if (!pathname) return { redirect: { pathname } };
    return { redirect: { pathname } };
  } catch (err) {
    return {
      redirect: {
        pathname: '',
      },
    };
  }
})();

export const saveRedirect = (state) => {
  try {
    const { pathname = '' } = state;
    localStorage.setItem(STORAGE_KEY, pathname);
  } catch (err) {
    // Ignore write errors.
  }
};

export const removeRedirect = (state) => {
  try {
    localStorage.removeItem(STORAGE_KEY);
  } catch (error) {
    // Ignore write errors.
  }
};
