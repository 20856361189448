import React, { MouseEvent, TouchEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Rnd } from 'react-rnd';
import { Avatar, Box, Button as ButtonMaterial, useMediaQuery, Dialog } from '@material-ui/core';
import * as VoxImplant from 'voximplant-websdk';
import { useSelector } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import PhoneIcon from '@material-ui/icons/Phone';
import TransitEnterexitIcon from '@material-ui/icons/TransitEnterexit';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import { addSeconds, setMinutes, setSeconds } from 'date-fns';
import { ReactComponent as VideoLocalOn } from 'assets/img/icons/video_local.svg';
import { ReactComponent as VideoLocalOff } from 'assets/img/icons/video_local_off.svg';
import { ReactComponent as VideoRemoteOn } from 'assets/img/icons/video_outline.svg';
import { ReactComponent as VideoRemoteOff } from 'assets/img/icons/video_off_outline.svg';
import { ReactComponent as MicOn } from 'assets/img/icons/microphone.svg';
import { ReactComponent as MicOff } from 'assets/img/icons/microphone_off.svg';
import voximplantManager from 'utils/VoximplantManager';
import styles from './CallDialog.module.sass';
import { decodeAvatarResource } from '../../utils';
import { Opponent } from './CallToolbar';
import { updateCallFactDuration } from '../../api/updateCallFactDuration';
import { UserRole } from '../../types';
import { format } from '../../utils/date';

enum CallState {
  PROGRESSING = 'PROGRESSING',
  CONNECTED = 'CONNECTED',
  ENDED = 'ENDED',
  FAILED = 'FAILED',
}
interface PropsInterface {
  callTo: Opponent;
  customData: string;
  onClose: (message?: string, error?: any) => void;
  isVideo: boolean;
  incomingCall: any;
}
const CallDialog = (props: PropsInterface) => {
  const { callTo, customData, onClose, isVideo, incomingCall } = props;
  const [voximplant] = useState<any>(voximplantManager.getClient());
  const [isFull, setIsFull] = useState<boolean>(false);
  const [isAllowRemoteVideo, setIsAllowRemoteVideo] = useState<boolean>(true);
  const [isAllowLocalVideo, setIsAllowLocalVideo] = useState<boolean>(true);
  const [isAllowMicVideo, setIsAllowMicVideo] = useState<boolean>(true);
  const [call, setCall] = useState<any>(incomingCall || null);
  const [isIncomingCall] = useState<boolean>(!!incomingCall);
  const [callState, setCallState] = useState<CallState>(null);
  const [statusText, setStatusText] = useState('');
  const [isActiveCall, setIsActiveCall] = useState(false);
  const role = useSelector<any, UserRole>((state) => state.auth.role);
  const isMedia = useMediaQuery('only screen and (max-width : 480px)');

  const sendVideo = useCallback(
    async (flag) => {
      await call.sendVideo(flag);
    },
    [call]
  );

  const showLocalVideo = useCallback(
    (flag) => {
      voximplant.showLocalVideo(flag).then((value) => {
        if (flag && value) {
          value?.render(localVideoRef.current);
        }
      });
    },
    [voximplant]
  );

  useEffect(() => {
    if (callState === CallState.CONNECTED) {
      showLocalVideo(isAllowLocalVideo);
    }
  }, [callState, isAllowLocalVideo, showLocalVideo]);

  const answerCall = useCallback(() => {
    call.answer(customData, {}, { receiveVideo: isVideo, sendVideo: isVideo });
    setCall(call);
    setCallState(CallState.PROGRESSING);
  }, [call, isVideo, customData]);

  const createCall = useCallback(
    (isVideo?: boolean) => {
      setCall(
        voximplant.call({
          number: callTo.id,
          video: { receiveVideo: isVideo, sendVideo: isVideo },
          customData,
        })
      );
      setCallState(CallState.PROGRESSING);
    },
    [callTo, customData, voximplant]
  );

  const handleAnswerCall = useCallback(() => {
    answerCall();
  }, [answerCall]);

  const _updateCallDuration = useCallback(() => {
    if (role === UserRole.DOCTOR) {
      updateCallFactDuration(customData).then();
    }
  }, [customData, role]);

  const onRemoteMediaAdded = useCallback(
    (e: any) => {
      if (isVideo) {
        const { mediaRenderer } = e;
        mediaRenderer.element.style.width = `${240}px`;
        mediaRenderer.element.style.height = `${120}px`;
        mediaRenderer.render(remoteVideoRef.current);
      }
    },
    [isVideo]
  );

  const onEndpointAdded = useCallback(
    (params: any) => {
      const { endpoint } = params || {};
      endpoint.on(VoxImplant.EndpointEvents.RemoteMediaAdded, onRemoteMediaAdded);
    },
    [onRemoteMediaAdded]
  );

  const closeCall = useCallback(() => {
    if (call) {
      !callState && isIncomingCall ? call.reject() : call.hangup();
    }
  }, [call, callState, isIncomingCall]);

  const closeCallByEvent = useCallback(() => {
    if (call) {
      showLocalVideo(false);
      setCall(null);
    }
  }, [call, showLocalVideo]);

  const onCallDisconnected = useCallback((): void => {
    _updateCallDuration();
    setCallState(CallState.ENDED);
    closeCallByEvent();
  }, [_updateCallDuration, closeCallByEvent]);

  const onCallConnected = useCallback((): void => {
    setCallState(CallState.CONNECTED);
  }, []);

  const onCallFailed = useCallback((): void => {
    _updateCallDuration();
    setCallState(CallState.FAILED);
    closeCallByEvent();
  }, [_updateCallDuration, closeCallByEvent]);

  const handleNormalCloseDialog = useCallback(
    (event: MouseEvent<HTMLButtonElement> | TouchEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();
      closeCall();
      onClose(!callState && isIncomingCall ? 'Звонок отклонен' : 'Звонок завершён');
    },
    [callState, closeCall, isIncomingCall, onClose]
  );

  const subscribe = useCallback(
    (call: any) => {
      call.on(VoxImplant.CallEvents.Connected, onCallConnected);
      call.on(VoxImplant.CallEvents.Disconnected, onCallDisconnected);
      call.on(VoxImplant.CallEvents.Failed, onCallFailed);
      call.on(VoxImplant.CallEvents.EndpointAdded, onEndpointAdded);
    },
    [onCallConnected, onCallDisconnected, onCallFailed, onEndpointAdded]
  );

  const unsubscribe = useCallback(
    (call: any) => {
      call.off(VoxImplant.CallEvents.Connected, onCallConnected);
      call.off(VoxImplant.CallEvents.Disconnected, onCallDisconnected);
      call.off(VoxImplant.CallEvents.Failed, onCallFailed);
      call.off(VoxImplant.CallEvents.EndpointAdded, onEndpointAdded);
      const endpoints = call.getEndpoints();
      if (endpoints) {
        endpoints.forEach((value) => {
          value.off(VoxImplant.EndpointEvents.RemoteMediaAdded, onRemoteMediaAdded);
        });
      }
    },
    [onCallConnected, onCallDisconnected, onCallFailed, onEndpointAdded, onRemoteMediaAdded]
  );

  useEffect(() => {
    if (call) {
      sendVideo(call.settings.videoDirections.sendVideo).then();
    }
  }, [call, sendVideo]);

  useEffect(() => {
    let timer = null;
    if (isActiveCall) {
      let seconds = 0;
      const date = addSeconds(setMinutes(setSeconds(new Date(), 0), 0), seconds);
      setStatusText(`${format(date, 'mm:ss')}`);
      timer = setInterval(() => {
        seconds += 1;
        const date = addSeconds(setMinutes(setSeconds(new Date(), 0), 0), seconds);
        setStatusText(`${format(date, 'mm:ss')}`);
      }, 1000);
    } else if (isIncomingCall) {
      setStatusText(`Входящий ${isVideo ? 'видео' : 'аудио'} звонок`);
    } else {
      setStatusText(`Исходящий ${isVideo ? 'видео' : 'аудио'} звонок`);
    }
    return () => {
      timer && clearInterval(timer);
    };
  }, [isIncomingCall, isActiveCall, isVideo]);

  useEffect(() => {
    setIsActiveCall(callState === CallState.CONNECTED);
    if (callState === CallState.CONNECTED) {
      VoxImplant.Hardware.StreamManager.get()
        .showLocalVideo(true)
        .then((value) => {
          value.element.style.width = `${240}px`;
          value.element.style.height = `${120}px`;
          value.render(localVideoRef.current);
        });
    }
  }, [callState]);

  useEffect(() => {
    callState && call && [CallState.ENDED, CallState.FAILED].indexOf(callState) > -1 && unsubscribe(call);
  }, [call, callState, incomingCall, subscribe, unsubscribe]);

  useEffect(() => {
    !callState && incomingCall && subscribe(incomingCall);
    call && callState === CallState.PROGRESSING && subscribe(call);
  }, [call, callState, incomingCall, subscribe]);

  useEffect(() => {
    !call && !callState && !incomingCall && createCall(isVideo);
  }, [call, callState, createCall, incomingCall, isVideo]);

  useEffect(() => {
    callState && [CallState.ENDED, CallState.FAILED].indexOf(callState) > -1 && onClose('Звонок завершен');
  }, [callState, onClose]);

  const remoteVideoRef = useRef<HTMLDivElement>(null);
  const localVideoRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (call && callState === CallState.CONNECTED) {
      if (isAllowMicVideo) {
        call.unmuteMicrophone();
      } else {
        call.muteMicrophone();
      }
    }
  }, [call, callState, isAllowMicVideo]);

  useEffect(() => {
    if (call && callState === CallState.CONNECTED) {
      if (isAllowLocalVideo) {
        call.sendVideo(true).then();
      } else {
        call.sendVideo(false).then();
      }
    }
  }, [call, callState, isAllowLocalVideo]);

  return (
    <Dialog PaperProps={{ className: classNames(styles.Dialog) }} BackdropComponent={() => <></>} open>
      <Rnd
        allowAnyClick
        className={classNames(styles.CallDialog, isFull && styles.CallDialog_Full)}
        default={{
          x: isMedia ? 0 : (window.innerWidth - 400) / 2,
          y: isMedia ? 0 : (window.innerHeight - 600) / 2,
          width: isMedia ? '100%' : '400px',
          height: isMedia ? '100%' : '600px',
        }}
      >
        <Box className={styles.CallDialog_wrapper}>
          <Box className={styles.CallDialog_container}>
            {(isActiveCall || isIncomingCall) && (
              <div className={styles.CallDialog_videoContainer}>
                <div
                  className={classNames(
                    styles.CallDialog_videoContainer_remote,
                    !isAllowRemoteVideo && styles.CallDialog_videoContainer_hidden
                  )}
                  ref={remoteVideoRef}
                />
                <div
                  className={classNames(
                    styles.CallDialog_videoContainer_local,
                    !isAllowLocalVideo && styles.CallDialog_videoContainer_hidden
                  )}
                  ref={localVideoRef}
                />
              </div>
            )}

            <Box
              className={classNames(
                styles.CallDialog_remoteContainer,
                isActiveCall &&
                  isVideo &&
                  isAllowRemoteVideo &&
                  remoteVideoRef &&
                  remoteVideoRef.current &&
                  styles.CallDialog_remoteContainer_hidden
              )}
            >
              <Box className={styles.CallDialog_remoteContainer_avatarContainer}>
                <Avatar
                  className={styles.CallDialog_remoteContainer_avatarContainer_avatar}
                  src={decodeAvatarResource(callTo?.avatar, 360)}
                />
              </Box>
              <div className={styles.CallDialog_remoteContainer_name}>
                {callTo?.firstName} {callTo?.lastName}
              </div>
              <Box className={styles.CallDialog_remoteContainer_status}>{statusText}</Box>
            </Box>
            <Box className={styles.CallDialog_tools}>
              {isActiveCall && (
                <Box className={styles.CallDialog_tools_line}>
                  <ButtonMaterial
                    size="medium"
                    className={classNames(
                      styles.CallDialog_tools_button,
                      isAllowLocalVideo && styles.CallDialog_tools_button_active,
                      'mr-2'
                    )}
                    onClick={() => {
                      setIsAllowLocalVideo(!isAllowLocalVideo);
                    }}
                    onTouchEnd={() => {
                      setIsAllowLocalVideo(!isAllowLocalVideo);
                    }}
                    color="default"
                  >
                    {isAllowLocalVideo ? (
                      <VideoLocalOn className={classNames(styles.CallDialog_tools_icon)} />
                    ) : (
                      <VideoLocalOff className={classNames(styles.CallDialog_tools_icon)} />
                    )}
                  </ButtonMaterial>
                  {isVideo && (
                    <ButtonMaterial
                      size="medium"
                      className={classNames(
                        styles.CallDialog_tools_button,
                        isAllowRemoteVideo && styles.CallDialog_tools_button_active,
                        'mr-2'
                      )}
                      onClick={() => {
                        setIsAllowRemoteVideo(!isAllowRemoteVideo);
                      }}
                      onTouchEnd={() => {
                        setIsAllowRemoteVideo(!isAllowRemoteVideo);
                      }}
                      color="default"
                    >
                      {isAllowRemoteVideo ? (
                        <VideoRemoteOn className={classNames(styles.CallDialog_tools_icon)} />
                      ) : (
                        <VideoRemoteOff className={classNames(styles.CallDialog_tools_icon)} />
                      )}
                    </ButtonMaterial>
                  )}
                  <ButtonMaterial
                    size="medium"
                    className={classNames(
                      styles.CallDialog_tools_button,
                      isAllowMicVideo && styles.CallDialog_tools_button_active,
                      'mr-2'
                    )}
                    onClick={() => {
                      setIsAllowMicVideo(!isAllowMicVideo);
                    }}
                    onTouchEnd={() => {
                      setIsAllowMicVideo(!isAllowMicVideo);
                    }}
                    color="default"
                  >
                    {isAllowMicVideo ? (
                      <MicOn className={classNames(styles.CallDialog_tools_icon)} />
                    ) : (
                      <MicOff className={classNames(styles.CallDialog_tools_icon)} />
                    )}
                  </ButtonMaterial>
                  <ButtonMaterial
                    size="medium"
                    className={classNames(
                      styles.CallDialog_tools_button,
                      isFull && styles.CallDialog_tools_button_active,
                      'mr-2'
                    )}
                    onClick={() => setIsFull(!isFull)}
                    onTouchEnd={() => setIsFull(!isFull)}
                    color="default"
                  >
                    {isFull ? (
                      <TransitEnterexitIcon className={classNames(styles.CallDialog_tools_icon)} />
                    ) : (
                      <ZoomOutMapIcon className={classNames(styles.CallDialog_tools_icon)} />
                    )}
                  </ButtonMaterial>
                  {isFull && (
                    <ButtonMaterial
                      size="medium"
                      className={classNames(styles.CallDialog_tools_button, styles.CallDialog_tools_button_close)}
                      onClick={handleNormalCloseDialog}
                      onTouchEnd={handleNormalCloseDialog}
                      color="default"
                    >
                      {isActiveCall ? (
                        <PhoneIcon className={classNames(styles.CallDialog_tools_icon)} />
                      ) : (
                        <CloseIcon className={classNames(styles.CallDialog_tools_icon)} />
                      )}
                    </ButtonMaterial>
                  )}
                </Box>
              )}
              <Box className={styles.CallDialog_tools_line}>
                {isIncomingCall && !isActiveCall && (
                  <ButtonMaterial
                    size="medium"
                    className={classNames(
                      styles.CallDialog_tools_button,
                      styles.CallDialog_tools_button_success,
                      'mr-5'
                    )}
                    onClick={handleAnswerCall}
                    onTouchEnd={handleAnswerCall}
                    color="default"
                  >
                    <PhoneIcon className={classNames(styles.CallDialog_tools_icon)} />
                  </ButtonMaterial>
                )}
                {!isFull && (
                  <ButtonMaterial
                    size="medium"
                    className={classNames(styles.CallDialog_tools_button, styles.CallDialog_tools_button_close)}
                    onClick={handleNormalCloseDialog}
                    onTouchEnd={handleNormalCloseDialog}
                    color="default"
                  >
                    {isActiveCall ? (
                      <PhoneIcon className={classNames(styles.CallDialog_tools_icon)} />
                    ) : (
                      <CloseIcon className={classNames(styles.CallDialog_tools_icon)} />
                    )}
                  </ButtonMaterial>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Rnd>
    </Dialog>
  );
};

export default CallDialog;
