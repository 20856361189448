import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Backdrop, Box, Dialog } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { getServicePackagesAccess, getSpecializations, createServicePackage, updateServicePackage } from 'api';
import ContentCard from 'components/ContentCard';
import InputField from 'components/Forms/InputField';
import { getFieldErrors as getFieldErrorsUtil, validateFieldOnChange } from 'utils';
import FormValidator from 'components/Forms/FormValidator';
import ContentLoading from 'components/ContentLoading';
import Button from 'components/Forms/Button';
import SelectInput from 'components/Forms/SelectInput';
import ServicePackageGroupSelector from 'views/Admin/ServicePackagesAdmin/ServicePackagesAdminGroups/GroupSelector/ServicePackageGroupSelector';
import DailyStrategySelector from 'views/Admin/ServicePackagesAdmin/DailyStrategySelector/DailyStrategySelector';
import { range } from 'lodash';
import isNumber from 'lodash/isNumber';
import styles from './AdminMfoEdit.module.sass';
import CategoryBenefitsSelect from '../CategoryBenefitsSelect';
import { getServicePackages } from '../../api/getServicePackages';

interface AppointmentDoctorViewInterface {
  onSave: () => void;
  onClose: () => void;
  data?: any;
  disabled?: boolean;
}

const AdminMfoEdit = (props: AppointmentDoctorViewInterface) => {
  const { onClose = () => {}, onSave = () => {}, data, disabled = false } = props;
  const [loading, setLoading] = useState<boolean>(true);
  const [errors, setErrors] = useState<any>(null);
  const [formErrors, setFormErrors] = useState<any>([]);
  const [loadingStore, setLoadingStore] = useState<boolean>(false);
  const [specializations, setSpecializations] = useState([]);
  const [access, setAccess] = useState([]);
  const [nonDiscount, setNonDiscount] = useState([]);
  const isActive = useMemo(() => data?.status?.value === 'ACTIVE', [data?.status?.value]);
  const [model, setModel] = useState<any>({
    category: data?.category || 0,
    appointmentsPerDay: data?.appointmentsPerDay || null,
    channel: data?.channel ? String(data.channel.value) : null,
    access: data?.access || null,
    name: data?.name || null,
    description: data?.description || null,
    descriptionHTML: data?.descriptionHTML || null,
    priceRub: data?.price || null,
    serviceTypes: data?.serviceTypes?.map((value) => String(value.value)) || [],
    specializationIds: data?.specializations?.map((value) => value.id) || [],
    withFiles: data?.withFiles,
    groupId: data?.group?.id || null,
    categoryBenefits: data?.categoryBenefits?.id || null,
    expirationDays: data?.expirationDays || null,
    isRecurrent: data?.isRecurrent === true,
    promoActivation: data?.promoActivation === true,
    isPersonalized: data?.isPersonalized === true,
    dailyConclusionStrategy: data?.dailyConclusionStrategy || null,
    packageTariffication: data?.packageTariffication === true,
    secondOpinionLimit: (
      data?.additionalServices?.find((value) => value.type.value === 'SECOND_OPINION') || { limitPerPeriod: null }
    ).limitPerPeriod,
    isDiscount: data?.isDiscount === true,
    referenceId: data?.referenceId || null,
    hybridDuration: isNumber(data?.hybridDuration) ? Number(data?.hybridDuration) : null,
  });
  const validateOnChange = (name: string, value: any, event, element?: any) => {
    validateFieldOnChange(name, value, event, model, setModel, formErrors, setFormErrors, element);
  };
  const getFieldErrors = (field: string) => getFieldErrorsUtil(field, formErrors);
  const channels = [
    { label: 'Чат', value: 'CHAT' },
    { label: 'Чат/Аудио', value: 'PHONE' },
    { label: 'Чат/Аудио/Видео', value: 'VIDEO' },
  ];
  const categories = range(1, 6).map((value) => ({ label: `${value}`, value }));
  const serviceTypes = [
    { label: 'Срочная консультация', value: 'IMMEDIATE' },
    { label: 'По времени ', value: 'PLANNED' },
  ];
  const optionsAccess = useMemo(() => {
    const options = [];
    Object.keys(access).forEach((value) => {
      options.push({ value, label: access[value] });
    });
    return options;
  }, [access]);

  const submit = (e) => {
    if (disabled) {
      return;
    }
    e.preventDefault();
    const form = e.target;
    const inputs = [...form.elements].filter((i) => ['INPUT', 'SELECT', 'TEXTAREA'].includes(i.nodeName));

    const { errors, hasError } = FormValidator.bulkValidate(inputs);

    setFormErrors([...errors]);

    if (!hasError) {
      store();
    }
  };
  const init = useCallback((): void => {
    setLoading(true);
    Promise.all([
      getSpecializations().catch(() => []),
      getServicePackagesAccess().catch(() => []),
      getServicePackages('').catch(() => []),
    ])
      .then((responseAll: AxiosResponse<any>[] | Array<any>) => {
        setLoading(false);
        responseAll.some((response, i) => {
          if (response?.data?.code === 'success') {
            switch (i) {
              case 1:
                setAccess(response?.data?.data || []);
                break;
              case 2:
                setNonDiscount((response?.data?.data?.items || []).filter((t: any) => data?.id !== t));
                break;
              default:
                setSpecializations(response?.data?.data?.items || []);
                break;
            }
          } else {
            setErrors(response?.data);
            return true;
          }
          return false;
        });
      })
      .catch((err) => {
        setLoading(false);
        setErrors(err);
      });
  }, [data?.id]);
  useEffect(() => init(), [init]);
  const isNew = !data || !data.id;
  const isDisabled = disabled || (!isNew && data.status.value !== 'NEW');

  const getAction = (): Promise<AxiosResponse<any>> => {
    const additionalServices = [];
    if (model?.secondOpinionLimit || model.secondOpinionLimit === 0) {
      additionalServices.push({
        type: 'SECOND_OPINION',
        limitPerPeriod: model?.secondOpinionLimit,
      });
    }
    return isNew
      ? createServicePackage({
          ...model,
          additionalServices,
          dailyConclusionStrategy: model?.dailyConclusionStrategy?.id,
        })
      : updateServicePackage(data.id, {
          ...model,
          additionalServices,
          dailyConclusionStrategy: model?.dailyConclusionStrategy?.id,
        });
  };

  const store = (): void => {
    setLoadingStore(true);
    getAction()
      .then((response: AxiosResponse<any>) => {
        if (response.data.code === 'success') {
          onSave();
        } else {
          throw new Error();
        }
        setLoadingStore(false);
      })
      .catch(() => {
        toast.error('Ошибка при запросе');
        setLoadingStore(false);
      });
  };

  return (
    <Dialog
      PaperProps={{ className: classNames(styles.Dialog) }}
      BackdropComponent={(props) => <Backdrop className={styles.Backdrop} {...props} />}
      onClose={onClose}
      open
    >
      <ContentCard className={styles.AdminMfoEdit}>
        <ContentCard.Header className={styles.AdminMfoEdit_Header}>
          <h6 className="page-header-title">{isNew ? 'Создание тарифа' : 'Редактирование тарифа'}</h6>
          <CloseIcon onClick={onClose} className={styles.AdminMfoEdit_CloseIcon} />
        </ContentCard.Header>
        <ContentCard.Main
          className={classNames(styles.AdminMfoEdit_Content, loading && styles.AdminMfoEdit_Content_Loading)}
        >
          <ContentLoading isLoading={loading} isError={errors} fetchData={() => init()}>
            <form onSubmit={submit}>
              <Box className={classNames(styles.AdminMfoEdit_Content_Wrapper)}>
                <Box className={classNames(styles.AdminMfoEdit_Container)}>
                  <Box className={classNames(styles.AdminMfoEdit_Item)}>
                    <InputField
                      name="name"
                      maxLength={255}
                      minLength={1}
                      disabled={disabled}
                      value={model.name}
                      onChange={(e) => validateOnChange('name', e.target.value, e)}
                      data-validate='["required"]'
                      errors={getFieldErrors('name')}
                      block
                      label="Название"
                      placeholder="Название"
                    />
                  </Box>
                  <Box className={classNames(styles.AdminMfoEdit_Item)}>
                    <InputField
                      name="description"
                      type="textarea"
                      disabled={disabled}
                      value={model.description}
                      onChange={(event) => {
                        setModel({ ...model, description: event.target.value });
                      }}
                      block
                      label="Описание"
                      placeholder="Описание"
                    />
                  </Box>
                  <Box className={classNames(styles.AdminMfoEdit_Item)}>
                    <InputField
                      name="descriptionHTML"
                      type="textarea"
                      disabled={disabled}
                      value={model.descriptionHTML}
                      onChange={(event) => {
                        setModel({ ...model, descriptionHTML: event.target.value });
                      }}
                      block
                      label="Описание (HTML)"
                      placeholder="Описание (HTML)"
                    />
                  </Box>
                  <Box className={classNames(styles.AdminMfoEdit_Item)}>
                    <InputField
                      name="appointmentsPerDay"
                      type="number"
                      min={1}
                      disabled={isDisabled}
                      value={model.appointmentsPerDay ? model.appointmentsPerDay.toString() : ''}
                      onChange={(e) => validateOnChange('appointmentsPerDay', e.target.value, e)}
                      data-validate='["required", "number"]'
                      errors={getFieldErrors('appointmentsPerDay')}
                      block
                      label="Лимит консультаций в день"
                      placeholder="Лимит консультаций в день"
                    />
                  </Box>
                  <Box className={classNames(styles.AdminMfoEdit_Item)}>
                    <InputField
                      name="secondOpinionLimit"
                      type="number"
                      min={1}
                      disabled={isDisabled || model.secondOpinionLimit === null}
                      value={model.secondOpinionLimit ? model.secondOpinionLimit.toString() : ''}
                      onChange={(e) => validateOnChange('secondOpinionLimit', e.target.value, e)}
                      data-validate='["number"]'
                      errors={getFieldErrors('secondOpinionLimit')}
                      block
                      label="Второе мнение лимит"
                      placeholder="Второе мнение лимит"
                    />
                  </Box>
                  <Box className={classNames(styles.AdminMfoEdit_Item, styles.AdminMfoEdit_Item_Row)}>
                    <Box>Второе мнение</Box>
                    <label className="toggle-checkbox-label">
                      <input
                        type="checkbox"
                        disabled={isDisabled}
                        checked={model.secondOpinionLimit !== null}
                        onChange={(event) => {
                          setModel({ ...model, secondOpinionLimit: event.target.checked ? 0 : null });
                        }}
                      />
                      <span className="checkmark">
                        <span className="check" />
                      </span>
                    </label>
                  </Box>
                  <Box className={classNames(styles.AdminMfoEdit_Item, styles.AdminMfoEdit_Item_Row)}>
                    <Box>Акционный тариф</Box>
                    <label className="toggle-checkbox-label">
                      <input
                        type="checkbox"
                        disabled={isDisabled || model.packageTariffication !== true}
                        checked={model.isDiscount === true}
                        onChange={(event) => {
                          setModel({
                            ...model,
                            isDiscount: event.target.checked,
                            referenceId: !event.target.checked ? null : model.referenceId,
                          });
                        }}
                      />
                      <span className="checkmark">
                        <span className="check" />
                      </span>
                    </label>
                  </Box>
                  <Box className={classNames(styles.AdminMfoEdit_Item)}>
                    <SelectInput
                      name="referenceId"
                      value={
                        model.referenceId
                          ? {
                              label: nonDiscount.find(({ id }) => id === model.referenceId)?.name,
                              value: model.referenceId,
                            }
                          : ''
                      }
                      disabled={model.isDiscount !== true || disabled || isActive}
                      onChange={(option, e) => validateOnChange('referenceId', option.value, e)}
                      errors={getFieldErrors('referenceId')}
                      data-validate={model.isDiscount === true ? '["required"]' : null}
                      options={nonDiscount.map(({ name: label, id: value }) => ({ label, value }))}
                      block
                      label="Полный тариф"
                      placeholder="Полный тариф"
                    />
                  </Box>
                  <Box className={classNames(styles.AdminMfoEdit_Item)}>
                    <SelectInput
                      name="channel"
                      disabled={isDisabled}
                      value={model.channel ? channels.find((c) => c.value === model.channel) : ''}
                      onChange={(option, e) => validateOnChange('channel', option.value, e)}
                      errors={getFieldErrors('channel')}
                      data-validate='["required"]'
                      options={channels}
                      block
                      label="Доступные типы связи"
                      placeholder="Доступные типы связи"
                    />
                  </Box>
                  <Box className={classNames(styles.AdminMfoEdit_Item)}>
                    <SelectInput
                      name="access"
                      value={model.access ? optionsAccess.find((c) => c.value === model.access) : ''}
                      onChange={(option, e) => validateOnChange('access', option.value, e)}
                      errors={getFieldErrors('access')}
                      data-validate='["required"]'
                      options={optionsAccess}
                      disabled={disabled}
                      block
                      label="Тип доступности"
                      placeholder="Тип доступности"
                    />
                  </Box>
                  <Box className={classNames(styles.AdminMfoEdit_Item)}>
                    <SelectInput
                      disabled={isDisabled}
                      name="serviceTypes"
                      value={model.serviceTypes?.map((s) => serviceTypes.find((_s) => _s.value === s))}
                      isMulti
                      onChange={(option, e) => {
                        validateOnChange(
                          'serviceTypes',
                          option?.map((v) => v.value),
                          e
                        );
                      }}
                      errors={getFieldErrors('serviceTypes')}
                      data-validate='["required"]'
                      options={serviceTypes}
                      block
                      label="Доступные типы консультации"
                      placeholder="Доступные типы консультации"
                    />
                  </Box>
                  <Box className={classNames(styles.AdminMfoEdit_Item)}>
                    <SelectInput
                      disabled={isDisabled}
                      name="specializationIds"
                      value={model.specializationIds?.map((s) => ({
                        label: (specializations.find((_s) => _s.id === s) || {}).name,
                        value: s,
                      }))}
                      isMulti
                      onChange={(option, e) => {
                        validateOnChange(
                          'specializationIds',
                          option?.map((v) => v.value),
                          e
                        );
                      }}
                      errors={getFieldErrors('specializationIds')}
                      data-validate='["required"]'
                      options={specializations.map((s) => ({ label: s.name, value: s.id }))}
                      block
                      label="Доступные врачебные специализации"
                      placeholder="Доступные врачебные специализации"
                    />
                  </Box>
                  <Box className={classNames(styles.AdminMfoEdit_Item)}>
                    <CategoryBenefitsSelect
                      name="categoryBenefits"
                      value={model.categoryBenefits}
                      onChange={(option, e) => {
                        validateOnChange('categoryBenefits', option.value, e);
                      }}
                      errors={getFieldErrors('categoryBenefits')}
                      block
                      isDisabled={disabled}
                      label="Льгота"
                      placeholder="Льгота"
                    />
                  </Box>
                  <Box className={classNames(styles.AdminMfoEdit_Item)}>
                    <ServicePackageGroupSelector
                      name="groupId"
                      value={model.groupId}
                      data-validate='["required"]'
                      block
                      disabled={disabled}
                      onChange={(option, e) => validateOnChange('groupId', option.value, e)}
                      errors={getFieldErrors('groupId')}
                      label="Группа"
                      placeholder="Группа"
                    />
                  </Box>
                  <Box className={classNames(styles.AdminMfoEdit_Item)}>
                    <DailyStrategySelector
                      value={model.dailyConclusionStrategy || ''}
                      name="dailyConclusionStrategy"
                      label="Генератор заключений"
                      disabled={disabled}
                      placeholder="Генератор заключений"
                      errors={getFieldErrors('dailyConclusionStrategy')}
                      onChange={(e) => validateOnChange('dailyConclusionStrategy', e, null)}
                    />
                  </Box>
                  <Box className={classNames(styles.AdminMfoEdit_Item)}>
                    <InputField
                      name="priceRub"
                      type="number"
                      onBlur={(event) =>
                        setModel({
                          ...model,
                          priceRub:
                            event.target.value !== '' && Number(event.target.value) >= 0
                              ? Number(Number(event.target.value).toFixed(2))
                              : model.priceRub,
                        })
                      }
                      value={model.priceRub ? model.priceRub.toString() : ''}
                      onChange={(e) => validateOnChange('priceRub', e.target.value, e)}
                      data-validate='["required", "number"]'
                      errors={getFieldErrors('priceRub')}
                      block
                      disabled={isDisabled}
                      label="Стоимость"
                      placeholder="Стоимость"
                    />
                  </Box>
                  <Box className={classNames(styles.AdminMfoEdit_Item)}>
                    <InputField
                      name="expirationDays"
                      type="number"
                      min={1}
                      disabled={isDisabled || model.expirationDays === null}
                      value={model.expirationDays ? model.expirationDays.toString() : ''}
                      onChange={(e) => validateOnChange('expirationDays', e.target.value, e)}
                      data-validate='["number"]'
                      errors={getFieldErrors('expirationDays')}
                      block
                      label="Кол-во дней оказания услуг с момента активации"
                      placeholder="Кол-во дней оказания услуг с момента активации"
                    />
                    {!(isDisabled || model.expirationDays === null) && model.packageTariffication === true && (
                      <input
                        type="hidden"
                        name="expirationDays"
                        data-validate='["required"]'
                        value={model.expirationDays ? model.expirationDays.toString() : ''}
                      />
                    )}
                  </Box>
                </Box>
                <Box className={classNames(styles.AdminMfoEdit_Container)}>
                  <Box className={classNames(styles.AdminMfoEdit_Item, styles.AdminMfoEdit_Item_Row)}>
                    <Box>Бессрочный</Box>
                    <label className="toggle-checkbox-label">
                      <input
                        type="checkbox"
                        disabled={isDisabled || model.packageTariffication === true}
                        checked={model.expirationDays === null}
                        onChange={(event) => {
                          setModel({
                            ...model,
                            hybridDuration: !event.target.checked ? null : model.hybridDuration,
                            expirationDays: event.target.checked ? null : 0,
                            isRecurrent: event.target.checked ? null : model.isRecurrent,
                            referenceId: event.target.checked ? null : model.referenceId,
                          });
                        }}
                      />

                      <span className="checkmark">
                        <span className="check" />
                      </span>
                    </label>
                  </Box>
                  <Box className={classNames(styles.AdminMfoEdit_Item, styles.AdminMfoEdit_Item_Row)}>
                    <Box>Пакетная тарификация</Box>
                    <label className="toggle-checkbox-label">
                      <input
                        type="checkbox"
                        disabled={isDisabled || model.expirationDays === null}
                        checked={model.packageTariffication === true}
                        onChange={(event) => {
                          setModel({
                            ...model,
                            packageTariffication: event.target.checked,
                            isRecurrent: event.target.checked ? model?.isRecurrent === true : false,
                            isDiscount: !event.target.checked ? false : model?.isDiscount,
                            referenceId: !event.target.checked ? null : model?.referenceId,
                          });
                        }}
                      />

                      <span className="checkmark">
                        <span className="check" />
                      </span>
                    </label>
                  </Box>
                  <Box className={classNames(styles.AdminMfoEdit_Item, styles.AdminMfoEdit_Item_Row)}>
                    <Box>Рекуррентный</Box>
                    <label className="toggle-checkbox-label">
                      <input
                        type="checkbox"
                        disabled={isDisabled || model.expirationDays === null || model.packageTariffication !== true}
                        checked={model.isRecurrent === true}
                        onChange={(event) => {
                          setModel({ ...model, isRecurrent: event.target.checked });
                        }}
                      />

                      <span className="checkmark">
                        <span className="check" />
                      </span>
                    </label>
                  </Box>
                  <Box className={classNames(styles.AdminMfoEdit_Item, styles.AdminMfoEdit_Item_Row)}>
                    <Box>Персональный</Box>
                    <label className="toggle-checkbox-label">
                      <input
                        type="checkbox"
                        disabled={isDisabled}
                        checked={model.isPersonalized === true}
                        onChange={(event) => {
                          setModel({ ...model, isPersonalized: event.target.checked });
                        }}
                      />

                      <span className="checkmark">
                        <span className="check" />
                      </span>
                    </label>
                  </Box>
                  <Box className={classNames(styles.AdminMfoEdit_Item, styles.AdminMfoEdit_Item_Row)}>
                    <Box>Активация по промокоду</Box>
                    <label className="toggle-checkbox-label">
                      <input
                        disabled={disabled}
                        type="checkbox"
                        checked={model.promoActivation === true}
                        onChange={(event) => {
                          setModel({ ...model, promoActivation: event.target.checked });
                        }}
                      />

                      <span className="checkmark">
                        <span className="check" />
                      </span>
                    </label>
                  </Box>
                  <Box className={classNames(styles.AdminMfoEdit_Item, styles.AdminMfoEdit_Item_Row)}>
                    <Box>Возможность прикреплять файлы</Box>
                    <label className="toggle-checkbox-label">
                      <input
                        type="checkbox"
                        disabled={isDisabled}
                        checked={model.withFiles === true}
                        onChange={(event) => {
                          setModel({ ...model, withFiles: event.target.checked });
                        }}
                      />

                      <span className="checkmark">
                        <span className="check" />
                      </span>
                    </label>
                  </Box>
                  <Box className={classNames(styles.AdminMfoEdit_Item)}>
                    <InputField
                      name="hybridDuration"
                      max={365}
                      type="number"
                      min={1}
                      disabled={disabled || model.packageTariffication === true}
                      value={model.hybridDuration}
                      onChange={(e: any) => validateOnChange('hybridDuration', e.target.value, e)}
                      data-validate='["number"]'
                      errors={getFieldErrors('hybridDuration')}
                      block
                      label="Количество предоплатных дней"
                      placeholder="Количество предоплатных дней"
                    />
                  </Box>
                  <Box className={classNames(styles.AdminMfoEdit_Item)}>
                    <SelectInput
                      name="category"
                      value={model.category ? categories.find((c) => c.value === model.category) : ''}
                      onChange={(option, e) => validateOnChange('category', option.value, e)}
                      errors={getFieldErrors('category')}
                      options={categories}
                      block
                      label="Категория"
                      placeholder="Категория"
                    />
                  </Box>
                </Box>
              </Box>
              <Box pl={1} pr={1} display="flex" justifyItems="center" alignItems="stretch">
                <Box mr={1} width="100%">
                  <Button block color="default" onClick={onClose}>
                    {disabled ? 'Закрыть' : 'Отмена'}
                  </Button>
                </Box>
                {!disabled && (
                  <Box ml={1} width="100%">
                    <Button type="submit" color="primary" isLoading={loadingStore} block>
                      {isNew ? 'Сохранить' : 'Обновить'}
                    </Button>
                  </Box>
                )}
              </Box>
            </form>
          </ContentLoading>
        </ContentCard.Main>
      </ContentCard>
    </Dialog>
  );
};
export default AdminMfoEdit;
