import React, { useEffect, useMemo } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { JournalType } from 'views/JournalPage/types';
import JournalPageWrapper from './JournalPageWrapper/JournalPageWrapper';
import useIsDoctorRole from '../../hooks/useIsDoctorRole';
import { onSetJournalUserId } from './store/events';

const defaultKey = Object.values(JournalType)?.[0];
const Routes = (): JSX.Element => {
  const isDoctor = useIsDoctorRole();
  const routeMatch = useRouteMatch<{ patientId: string }>();
  const patientId = useMemo(() => routeMatch?.params?.patientId || null, [routeMatch]);
  useEffect(() => {
    onSetJournalUserId(patientId);
    return () => {
      onSetJournalUserId(null);
    };
  }, [patientId]);
  return (
    <Switch>
      {Object.values(JournalType).map((k) => (
        <Route
          key={k}
          path={isDoctor ? `/patients/:patientId/journal/${k}` : `/journal/${k}`}
          component={() => <JournalPageWrapper type={k} />}
        />
      ))}
      <Route
        component={() => (
          <Redirect to={patientId ? `/patients/${patientId}/journal/${defaultKey}` : `/journal/${defaultKey}`} />
        )}
      />
    </Switch>
  );
};

export default Routes;
