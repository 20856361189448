import { createEvent } from 'effector';
import { AppointmentDoctorViewType } from '../types';

export const onGetAppointment = createEvent<string>('onGetAppointment');
export const onSetAppointmentStatus = createEvent<any>('onSetAppointmentStatus');
export const onRestAppointment = createEvent<any>('onRestAppointment');
export const onSetCloseModal = createEvent<boolean>('onSetCloseModal');
export const onCloseAppointment = createEvent<{
  appointmentId: string;
  anamnesis: string;
  recommendations: string;
  dictionaries?: Array<any>;
  files?: File[];
}>('onCloseAppointment');
export const onGetIemk = createEvent<any>('onGetIemk');
export const onSetViewTab = createEvent<AppointmentDoctorViewType>('onSetViewTab');
