import { AxiosResponse } from 'axios';
import Manage from '../../types/manage';
import { findPatientsAdmin } from '../../api/findPatientsAdmin';
import { getPatientAppointmentsAdmin } from '../../api/getPatientAppointmentsAdmin';

export const MANAGE_PATIENTS_LOADING = 'MANAGE_PATIENTS_LOADING';
export const MANAGE_PATIENTS_SUCCESS = 'MANAGE_PATIENTS_SUCCESS';
export const MANAGE_PATIENTS_ERRORS = 'MANAGE_PATIENTS_ERRORS';
export const MANAGE_PATIENT_APPOINTMENTS_LOADING = 'MANAGE_PATIENT_APPOINTMENTS_LOADING';
export const MANAGE_PATIENT_APPOINTMENTS_SUCCESS = 'MANAGE_PATIENT_APPOINTMENTS_SUCCESS';
export const MANAGE_PATIENT_APPOINTMENTS_ERRORS = 'MANAGE_PATIENT_APPOINTMENTS_ERRORS';

export const setManagePatientsLoading = (loading: boolean, reset?: boolean) => ({
  type: MANAGE_PATIENTS_LOADING,
  loading,
  reset,
});

export const setManagePatientsErrors = (errors: any) => ({ type: MANAGE_PATIENTS_ERRORS, errors });

export const setManagePatients = (
  patients: Manage.Patient.Item[],
  page: number,
  pageSize: number,
  totalItems: number
) => ({
  type: MANAGE_PATIENTS_SUCCESS,
  patients,
  page,
  pageSize,
  totalItems,
});

export const setManagePatientAppointmentsLoading = (loading: boolean, reset?: boolean) => ({
  type: MANAGE_PATIENT_APPOINTMENTS_LOADING,
  loading,
  reset,
});

export const setManagePatientAppointmentsErrors = (errors: any) => ({
  type: MANAGE_PATIENT_APPOINTMENTS_ERRORS,
  errors,
});

export const setManagePatientAppointments = (
  patientAppointments: Manage.Patient.Appointments.Item[],
  page: number,
  pageSize: number,
  totalItems: number
) => ({
  type: MANAGE_PATIENT_APPOINTMENTS_SUCCESS,
  patientAppointments,
  page,
  pageSize,
  totalItems,
});

export const fetchManagePatients = (
  query: string,
  activeFrom?: Date,
  activeTo?: Date,
  external?: boolean,
  page?: number,
  pageSize?: number,
  reset?: boolean
): any => {
  return (dispatch) => {
    dispatch(setManagePatientsLoading(true, reset));
    findPatientsAdmin(query, activeFrom, activeTo, external, page, pageSize)
      .then((response: AxiosResponse<Manage.Patient.Response>) => {
        dispatch(setManagePatientsLoading(false));
        if (response.data.code === 'success') {
          dispatch(
            setManagePatients(
              response.data.data.items,
              response.data.data.page,
              response.data.data.pageSize,
              response.data.data.totalItems
            )
          );
        } else {
          dispatch(setManagePatientsErrors(response.data));
        }
      })
      .catch((error: any) => {
        dispatch(setManagePatientsErrors(error));
        dispatch(setManagePatientsLoading(false));
      });
  };
};

export const fetchManagePatientAppointments = (
  patientId: string,
  page?: number,
  pageSize?: number,
  reset?: boolean
): any => {
  return (dispatch) => {
    dispatch(setManagePatientAppointmentsLoading(true, reset));
    getPatientAppointmentsAdmin(patientId, page, pageSize)
      .then((response: AxiosResponse<Manage.Patient.Appointments.Response>) => {
        dispatch(setManagePatientAppointmentsLoading(false));
        if (response.data.code === 'success') {
          dispatch(
            setManagePatientAppointments(
              response.data.data.items,
              response.data.data.page,
              response.data.data.pageSize,
              response.data.data.totalItems
            )
          );
        } else {
          dispatch(setManagePatientAppointmentsErrors(response.data));
        }
      })
      .catch((error: any) => {
        dispatch(setManagePatientAppointmentsErrors(error));
        dispatch(setManagePatientAppointmentsLoading(false));
      });
  };
};
