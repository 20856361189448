import React, { ReactElement, useCallback, useMemo } from 'react';
import { useStore } from 'effector-react';
import { Box } from '@material-ui/core';
import { subYears } from 'date-fns';
import InputField from 'components/Forms/InputField';
import { Fields } from 'constants/fields';
import { getFormLabel } from 'utils/form';
import SelectInput from 'components/Forms/SelectInput';
import DateField from 'components/Forms/DateField';
import beforeMaskedValueChangePhone from 'utils/beforeMaskedValueChangePhone';
import classNames from 'classnames';
import { PspTranslation } from '../../constants/translations';
import $store from '../../store';
import { onAgentChangeForm, onAgentResetCheck } from '../../store/events';
import { getErrorsByFields } from '../../../../../components/Forms/FormValidator/FormValidator';
import { AgentSaleType } from '../../types';
import AgentSaleUserPspAction from '../AgentSaleUserPspAction';
import FormLabel from '../../../../../components/Forms/FormLabel';
import FormInvalidMessage from '../../../../../components/Forms/FormInvalidMessage';
import AgentSaleSp from '../AgentSaleSp';

const options = [
  {
    value: 'MALE',
    label: 'Мужской',
  },
  {
    value: 'FEMALE',
    label: 'Женский',
  },
];
const AgentSaleUserPsp = (): ReactElement => {
  const { form, errors, type, isCheckCode, loading } = useStore($store);
  const getErrorsByField = useCallback((field) => getErrorsByFields([field], errors), [errors]);
  const handleChange = useCallback((field, value) => {
    field === Fields.Phone && onAgentResetCheck();
    onAgentChangeForm({ [field]: field === Fields.Phone ? value?.replaceAll(/[^0-9]/g, '') : value });
  }, []);
  const isUserPsp = useMemo(() => type === AgentSaleType.NEW, [type]);
  const handelSelect = useCallback((internalTariffId: any) => {
    onAgentChangeForm({ [Fields.InternalTariffId]: internalTariffId });
  }, []);
  const internalTariffId = useMemo(() => form?.[Fields.InternalTariffId], [form]);
  return (
    <>
      {isUserPsp && (
        <>
          <Box className="row mt-2">
            <Box className="col-12">
              <InputField
                name={Fields.LastName}
                value={form?.[Fields.LastName]}
                block
                disabled={loading}
                errors={getErrorsByField(Fields.LastName)}
                onChange={(event) => handleChange(Fields.LastName, event.target?.value)}
                label={getFormLabel(Fields.LastName, PspTranslation)}
                placeholder={getFormLabel(Fields.LastName, PspTranslation)}
              />
            </Box>
          </Box>
          <Box className="row">
            <Box className="col-12">
              <InputField
                name={Fields.FirstName}
                value={form?.[Fields.FirstName]}
                block
                disabled={loading}
                errors={getErrorsByField(Fields.FirstName)}
                onChange={(event) => handleChange(Fields.FirstName, event.target?.value)}
                label={getFormLabel(Fields.FirstName, PspTranslation)}
                placeholder={getFormLabel(Fields.FirstName, PspTranslation)}
              />
            </Box>
          </Box>
          <Box className="row">
            <Box className="col-12">
              <InputField
                name={Fields.MiddleName}
                value={form?.[Fields.MiddleName]}
                errors={getErrorsByField(Fields.MiddleName)}
                block
                disabled={loading}
                onChange={(event) => handleChange(Fields.MiddleName, event.target?.value)}
                label={getFormLabel(Fields.MiddleName, PspTranslation)}
                placeholder={getFormLabel(Fields.MiddleName, PspTranslation)}
              />
            </Box>
          </Box>
          <Box className="row">
            <Box className="col-9">
              <SelectInput
                name="sex"
                errors={getErrorsByField(Fields.Sex)}
                value={options?.find(({ value }) => value === form?.[Fields.Sex]) || null}
                label={getFormLabel(Fields.Sex, PspTranslation)}
                onChange={(option) => handleChange(Fields.Sex, option?.value)}
                options={options}
                disabled={loading}
                block
                placeholder="Пол"
              />
            </Box>
            <Box className="col-3 d-flex flex-column">
              <DateField
                errors={getErrorsByField(Fields.Birthday)}
                onChange={(event) => handleChange(Fields.Birthday, event)}
                maxDate={subYears(new Date(), 19)}
                disabled={loading}
                label={getFormLabel(Fields.Birthday, PspTranslation)}
                value={form?.[Fields.Birthday]}
              />
            </Box>
          </Box>
        </>
      )}
      <Box className={classNames('row', !isUserPsp && 'mt-2')}>
        <Box className="col-9">
          <InputField
            name={Fields.Phone}
            errors={getErrorsByField(Fields.Phone)}
            value={form?.[Fields.Phone]}
            beforeMaskedValueChange={beforeMaskedValueChangePhone}
            onChange={(event) => handleChange(Fields.Phone, event.target?.value)}
            block
            disabled={!!isCheckCode || loading}
            mask="+9 (999) 999-99-99"
            label={getFormLabel(Fields.Phone, PspTranslation)}
            placeholder={getFormLabel(Fields.Phone, PspTranslation)}
          />
        </Box>
        <Box className="col-3 d-flex align-items-center">
          <AgentSaleUserPspAction />
        </Box>
      </Box>
      <Box className="row">
        <Box className="col-12">
          <InputField
            errors={getErrorsByField(Fields.ConfirmCode)}
            name={Fields.ConfirmCode}
            value={form?.[Fields.ConfirmCode]}
            block
            disabled={!isCheckCode || loading}
            onChange={(event) => handleChange(Fields.ConfirmCode, event.target?.value)}
            mask="9999"
            label={getFormLabel(Fields.ConfirmCode, PspTranslation)}
            placeholder={getFormLabel(Fields.ConfirmCode, PspTranslation)}
          />
        </Box>
      </Box>
      {isUserPsp && (
        <>
          <Box className="row">
            <Box className="col-12">
              <InputField
                errors={getErrorsByField(Fields.Email)}
                name={Fields.Email}
                value={form?.[Fields.Email]}
                onChange={(event) => handleChange(Fields.Email, event.target?.value)}
                block
                disabled={loading}
                label={getFormLabel(Fields.Email, PspTranslation)}
                placeholder={getFormLabel(Fields.Email, PspTranslation)}
              />
            </Box>
          </Box>
        </>
      )}
      <Box className="mb-5">
        <FormLabel invalid={getErrorsByFields([Fields.InternalTariffId], errors).length > 0}>
          {getFormLabel(Fields.InternalTariffId, PspTranslation)}
        </FormLabel>
        <FormInvalidMessage>{getErrorsByFields([Fields.InternalTariffId], errors)?.[0]?.message}</FormInvalidMessage>
        <AgentSaleSp onSelect={handelSelect} internalTariffId={internalTariffId} />
      </Box>
    </>
  );
};

export default AgentSaleUserPsp;
