import React from 'react';
import Cropper from 'cropperjs';

import debounce from 'utils/debounce';

import 'cropperjs/dist/cropper.css';

import styles from './Cropper.module.sass';

type Props = {
  onCrop: (data: File) => void;
  file: File;
};

export default ({ onCrop, file }: Props) => {
  const wrapperRef = React.useRef<HTMLDivElement>();
  const [isImageSet, setImageState] = React.useState<boolean>(false);

  const handleCrop = debounce((data: any) => {
    data.getCroppedCanvas().toBlob(blob => {
      if (!blob) {
        return;
      }

      const tmpBlob = blob.slice(0, blob.size, file.type);
      tmpBlob.lastModifiedDate = new Date();
      tmpBlob.name = file.name;

      onCrop(tmpBlob);
    });
  }, 500);

  React.useEffect(
    () => {
      if (!file || isImageSet) {
        return;
      }

      setImageState(true);

      const image = new Image();
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = () => {
        // @ts-ignore
        image.src = reader.result;
        wrapperRef.current.appendChild(image);

        new Cropper(image, { // eslint-disable-line
          aspectRatio: 1,
          crop() {
            handleCrop(this.cropper);  // eslint-disable-line
          },
          viewMode: 2
        });
      };
    },
    [file]
  );

  return (
    <div ref={wrapperRef} className={styles.container} />
  );
};
