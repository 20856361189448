import { JournalTitle, JournalType } from '../views/JournalPage/types';

export const journalItems = [
  {
    title: JournalTitle[JournalType.Pulse],
    icon: require('assets/img/journal/pulse.png'),
    url: `${JournalType.Pulse}`,
  },
  {
    title: JournalTitle[JournalType.Pressure],
    icon: require('assets/img/journal/stress.png'),
    url: `${JournalType.Pressure}`,
  },
  {
    title: JournalTitle[JournalType.Weight],
    icon: require('assets/img/journal/weight.png'),
    url: `${JournalType.Weight}`,
  },
  {
    title: JournalTitle[JournalType.Sugar],
    icon: require('assets/img/journal/sugar.png'),
    url: `${JournalType.Sugar}`,
  },
  {
    title: JournalTitle[JournalType.Cholesterol],
    icon: require('assets/img/journal/cholesterol.png'),
    url: `${JournalType.Cholesterol}`,
  },
  {
    title: JournalTitle[JournalType.Temperature],
    icon: require('assets/img/journal/temp.png'),
    url: `${JournalType.Temperature}`,
  },
  {
    title: JournalTitle[JournalType.Feeling],
    icon: require('assets/img/journal/feeling.png'),
    url: `${JournalType.Feeling}`,
  },
];
