import React, { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { useStore } from 'effector-react';
import Button from 'components/Forms/Button';
import { Fields } from 'constants/fields';
import $store from '../../store';
import { onAgentChangeForm, onAgentResetCheck } from '../../store/events';
import { getErrorsByFields } from '../../../../../components/Forms/FormValidator/FormValidator';
import { AgentSaleType } from '../../types';
import { getAgentByPhoneFx, getCodeFx } from '../../store/effects';

const AgentSaleUserPspAction = (): ReactElement => {
  const { loading, form, errors, type, loadingPhone, loadingCode, isCheckPhone, isCheckCode } = useStore($store);
  const getErrorsByField = useCallback((field) => getErrorsByFields([field], errors), [errors]);
  const canConfirm = useMemo(
    () => form?.[Fields.Phone] && getErrorsByField(Fields.Phone).length === 0,
    [form, getErrorsByField]
  );
  const handleGetCodePhone = useCallback(() => getCodeFx({ phone: form?.[Fields.Phone] }), [form]);
  const handleResetCodePhone = useCallback(() => {
    onAgentResetCheck();
    onAgentChangeForm({ [Fields.ConfirmCode]: '' });
  }, []);
  const handleGetAgentByPhone = useCallback(() => {
    getAgentByPhoneFx.call(getAgentByPhoneFx, [{ [Fields.Phone]: form?.[Fields.Phone] }]);
  }, [form]);
  useEffect(() => {
    !form?.[Fields.Phone] && onAgentResetCheck();
  }, [form]);

  if (type === AgentSaleType.NEW || isCheckPhone) {
    return (
      <Button
        size="md"
        isLoading={loadingCode}
        disabled={!canConfirm || loadingCode || loading}
        onClick={isCheckCode ? handleResetCodePhone : handleGetCodePhone}
        type="button"
        block
        color="primary"
      >
        {!isCheckCode ? 'Подтвердить' : 'Сбросить'}
      </Button>
    );
  }
  return (
    <Button
      size="md"
      disabled={!canConfirm || loadingPhone}
      onClick={handleGetAgentByPhone}
      type="button"
      block
      color="primary"
    >
      Проверить
    </Button>
  );
};

export default AgentSaleUserPspAction;
