import React, { PropsWithChildren, useCallback, useEffect } from 'react';
import { createEvent, createStore } from 'effector';
import { useStore } from 'effector-react';
import JivoSiteContext from '../context/JivoSiteContext';

interface JivoSiteStateInterface {
  init: boolean;
  hide: boolean;
}
const defaultState: JivoSiteStateInterface = {
  init: false,
  hide: false,
};
const $store = createStore(defaultState);
export const onHideJivoChat = createEvent<any>('onHideJivoChat');
export const onShowJivoChat = createEvent<any>('onShowJivoChat');
export const onInitJivoChat = createEvent<any>('onInitJivoChat');
$store.on(onHideJivoChat, (_) => ({ ..._, hide: true }));
$store.on(onShowJivoChat, (_) => ({ ..._, hide: false }));
$store.on(onInitJivoChat, (_) => ({ ..._, init: true }));
const self: any = window;
export interface PropsInterface extends PropsWithChildren<any> {}
const JivoSiteProvider = (props: PropsInterface): JSX.Element => {
  const { children } = props;
  const store = useStore($store);
  self.jivo_onLoadCallback = useCallback(() => {
    onInitJivoChat();
  }, []);
  useEffect(() => {
    if (store.init && !store.hide) {
      self?.jivo_init();
    } else if (store.init && store.hide) {
      self?.jivo_destroy();
    }
  }, [store.hide, store.init]);
  return <JivoSiteContext.Provider value={store}>{children}</JivoSiteContext.Provider>;
};

export default JivoSiteProvider;
