import axios from 'utils/axios';

export const authRegistration = (
  phone: string, 
  firstName: string,
  middleName: string,
  lastName: string,
  birthday: string,
  code: string,
  sex?: string
) => {
  return axios.post('/api/v1/registration/phones', {
    phone,
    firstName,
    middleName,
    lastName,
    birthday,
    code,
    sex
  });
};
