import React, { useState, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { validateFieldOnChange, getFieldErrors as getFieldErrorsUtil } from 'utils';
import { addBalance } from 'api';
import { getWordNumEnding } from 'utils/helpers';
import { toast } from 'react-toastify';

import ContentCard from 'components/ContentCard';
import InputField from 'components/Forms/InputField';
import FormGroup from 'components/Forms/FormGroup';
import FormValidator from 'components/Forms/FormValidator';
import Button from 'components/Forms/Button';

import { ReactComponent as AngleLeftIcon } from 'assets/img/icons/angle-left-icon.svg';
import { updateRedirect } from 'store/actions/redirect.actions';
import styles from './AddBalancePage.module.sass';

const AddBalancePage = (props: any) => {
  const [balanceForm, setBalanceForm] = useState({
    balance: null,
  });

  const [balanceFormErrors, setBalanceFormErrors] = useState([]);

  const [addBalanceLoading, setAddBalanceLoading] = useState(false);

  const validateOnChange = (name: string, value: any, event, element?) => {
    validateFieldOnChange(
      name,
      value,
      event,
      balanceForm,
      setBalanceForm,
      balanceFormErrors,
      setBalanceFormErrors,
      element
    );
  };

  const getFieldErrors = (field: string) => getFieldErrorsUtil(field, balanceFormErrors);

  const addBalanceSubmit = (e) => {
    e.preventDefault();

    const form = e.target;
    const inputs = [...form.elements].filter((i) => ['INPUT', 'SELECT', 'TEXTAREA'].includes(i.nodeName));

    const { errors, hasError } = FormValidator.bulkValidate(inputs);

    setBalanceFormErrors([...errors]);

    if (!hasError) {
      setAddBalanceLoading(true);

      addBalance(balanceForm.balance)
        .then((response) => {
          setAddBalanceLoading(false);

          setBalanceForm({
            balance: null,
          });

          if (response.data.data) {
            const win = window;
            props.updateRedirect('/');
            win.open(response.data.data, '_self');
            win.focus();
          }
        })
        .catch(() => {
          setAddBalanceLoading(false);

          toast.error('Ошибка при пополнении баланса');
        });
    }
  };

  const handleChange = useCallback((event: any) => {
    if (Number(event.target.value) > 99999) {
      event.target.value = 99999;
    }
    validateOnChange('balance', event.target.value, event);
  }, []);

  const balanceLabel = useMemo(
    () =>
      [`Пополнить`]
        .concat(
          balanceForm.balance
            ? [`${balanceForm.balance}`, getWordNumEnding(balanceForm.balance, ['рубль', 'рубля', 'рублей'])]
            : []
        )
        .join(' '),
    [balanceForm]
  );

  return (
    <ContentCard>
      <ContentCard.Header>
        <div className="row">
          <div className="col-xs-4 text-center text-xs-left mb-2 mb-xs-0">
            <div className="page-header-back-link" onClick={() => props.history.push('/')}>
              <AngleLeftIcon className="page-header-back-link-icon" />
              Назад
            </div>
          </div>
          <div className="col-xs-8 col-md-4 text-center text-xs-right text-md-center">
            <h6 className="page-header-title">Пополнение баланса</h6>
          </div>
        </div>
      </ContentCard.Header>

      <ContentCard.Main>
        <div className={styles.addBalanceView}>
          <p>Укажите сумму на которую хотите пополнить баланс</p>

          <h6 className="mb-4">Баланс: {props.user.balance || 0} ₽</h6>

          <form onSubmit={(e) => addBalanceSubmit(e)}>
            <FormGroup>
              <InputField
                name="balance"
                type="number"
                value={balanceForm.balance}
                onChange={handleChange}
                data-param={1}
                max={999999}
                data-validate='["required", "min"]'
                errors={getFieldErrors('balance')}
                filled
                block
                placeholder="Введите сумму"
              />
            </FormGroup>

            <Button type="submit" className="mt-5" color="primary" block isLoading={addBalanceLoading}>
              {balanceLabel}
            </Button>
          </form>
        </div>
      </ContentCard.Main>
    </ContentCard>
  );
};

export default connect(
  (state: any) => ({
    user: state.auth.userData,
  }),
  { updateRedirect }
)(AddBalancePage);
