import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { decodeAvatarResource } from 'utils/helpers';
import { format } from 'date-fns';
import { myFirebase, myFirestore } from 'utils/firebase';
import { toast } from 'react-toastify';
import Scroll, { Element as ScrollElement } from 'react-scroll';
import { getFirebaseToken } from 'api';

import SendIcon from '@material-ui/icons/Send';
import ContentLoading from 'components/ContentLoading';
import InputField from 'components/Forms/InputField';
import Spinner from 'components/Spinner';

import { ReactComponent as MsgUnreadIcon } from 'assets/img/icons/msg-unread-icon.svg';
import { ReactComponent as MsgReadIcon } from 'assets/img/icons/msg-read-icon.svg';
import classNames from 'classnames';
import { Box } from '@material-ui/core';
import { RootStateOrAny, useSelector } from 'react-redux';
import styles from '../../AppointmentChatPage.module.sass';
import ChatFileAttach from './ChatFileAttach';
import { AttachedFileInfo } from './ChatFileAttach/ChatFileAttach';
import { CallToolbar } from '../../../../components/VoximPlantToolbar';
import ChatFileResource from './ChatFileResource';
import FilesView from '../FilesView';
import ChatPatientData from '../ChatPatientData';
import { AppointmentStatus } from '../../../AppointmentDoctor/types';

const CHAT_PAGE_SIZE = 20;

export interface PropsInterface {
  profile: any;
  appointment: any;
  toggleDrawer?: (event: React.KeyboardEvent | React.MouseEvent) => any;
  showFiles: boolean;
  showToolbar?: boolean;
  showSideBar?: boolean;
  isDoctor?: boolean;
  allowFiles?: boolean;
}

const ChatView = (props: PropsInterface): JSX.Element => {
  let messagesReloading = false;
  const doctor = useSelector((state: RootStateOrAny) => state?.doctor);
  const formRef = useRef<HTMLFormElement>();

  const {
    appointment = null,
    toggleDrawer = () => {},
    showFiles = false,
    allowFiles = true,
    showToolbar = true,
    showSideBar = true,
    isDoctor = false,
    profile,
  } = props;
  const isCurrentDoctor = useMemo(
    () => !isDoctor || (isDoctor && doctor?.id === appointment?.doctor?.id),
    [isDoctor, doctor?.id, appointment?.doctor?.id]
  );
  const [fireStoreMessagesCollection] = useState(
    myFirestore.collection('rooms').doc(appointment?.id).collection('messages')
  );
  const status = useMemo(() => appointment?.status?.value, [appointment?.status?.value]);

  const [chatBodyRef] = useState(React.createRef<HTMLDivElement>());
  const [messagesList, setMessagesList] = useState([]);
  const [messagesLoading, setMessagesLoading] = useState(false);
  const [messagesLoadingError, setMessagesLoadingError] = useState(false);

  const [messageValue, setMessageValue] = useState('');

  const [messageSendLoading, setMessageSendLoading] = useState(false);

  const [newMessage, setNewMessage] = useState(null);

  const [modifyMessage, setModifyMessage] = useState(null);

  const [attachFiles, setAttachFiles] = useState([]);

  const [chatResources, setChatResources] = useState([]);
  const [chatResource] = useState(null);

  const [messagesOffset, setMessagesOffset] = useState(null);

  useEffect(() => {
    setMessagesLoading(true);
    setMessagesLoadingError(false);
    let unsubscribe;

    getFirebaseToken()
      .then((response) => {
        if (response.data.data) {
          const token = response.data.data.token;

          myFirebase
            .auth()
            .signInWithCustomToken(token)
            .then(() => {
              getMessagesHistory(messagesOffset);
              unsubscribe = subscribeMessagesChanges();
            })
            .catch(() => {
              setMessagesLoadingError(true);
            });
        }
      })
      .catch(() => {
        setMessagesLoadingError(true);
      });

    return () => {
      myFirebase.auth().signOut().then().catch();
      unsubscribe && unsubscribe();
    };
  }, []);

  useEffect(() => {
    chatBodyRef.current.removeEventListener('scroll', chatBodyOnScroll);

    if (messagesOffset) {
      chatBodyRef.current.addEventListener('scroll', chatBodyOnScroll);
    }

    messagesList.forEach((message) => {
      message.resourceIds.forEach((resource) => {
        getMsgResource(resource.id, message.id);
      });
    });

    if (messagesList.length <= CHAT_PAGE_SIZE) {
      if (chatBodyRef.current) {
        chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
      }
    } else if (messagesList.length % CHAT_PAGE_SIZE === 0) {
      if (messagesList[CHAT_PAGE_SIZE]) {
        Scroll.scroller.scrollTo(`chatmsg-${messagesList[CHAT_PAGE_SIZE].id}`, {
          smooth: false,
          duration: 0,
          containerId: 'chatBodyContainerId',
          offset: 500,
        });

        setTimeout(() => {
          messagesReloading = false;
        }, 100);
      }
    }

    checkMessageInView();
  }, [messagesList.length]);

  useEffect(() => {
    if (!messagesOffset) {
      chatBodyRef.current.removeEventListener('scroll', chatBodyOnScroll);
    }
  }, [messagesOffset]);

  useEffect(() => {
    if (chatResource) {
      setChatResources([...chatResources, { ...chatResource }]);
    }
  }, [chatResource]);

  useEffect(() => {
    if (newMessage) {
      if (!messagesList.find((msg) => msg.id === newMessage.id)) {
        setMessagesList([...messagesList, { ...newMessage }]);
        chatBodyRef.current &&
          setTimeout(() => {
            if (chatBodyRef.current) {
              chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
            }
          });
      }
    }
  }, [newMessage]);

  useEffect(() => {
    if (modifyMessage) {
      const msgList: Array<any> = messagesList.filter((value) => value.id !== modifyMessage.id);
      setMessagesList([...msgList, modifyMessage]);
    }
  }, [modifyMessage]);

  function subscribeMessagesChanges() {
    return fireStoreMessagesCollection
      .orderBy('timestamp', 'desc')
      .limit(1)
      .onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            const newMessage = snapshot.docs.map((doc) => doc.data())[0];
            setNewMessage(parseMessageDoc(newMessage));
            return;
          }
          if (change.type === 'modified') {
            const messages = snapshot.docs.map((it) => it.data());
            messages.forEach((value) => {
              setModifyMessage(parseMessageDoc(value));
            });
          }
        });
      });
  }

  function getMessagesHistory(msgOffset: number) {
    setMessagesLoading(true);
    messagesReloading = true;

    let messagesCollection = fireStoreMessagesCollection.orderBy('timestamp', 'desc');

    if (msgOffset) {
      messagesCollection = messagesCollection.startAfter(msgOffset);
    }

    messagesCollection = messagesCollection.limit(CHAT_PAGE_SIZE);

    messagesCollection
      .get()
      .then((response) => {
        setMessagesLoading(false);

        let messages = response.docs.map((doc) => doc.data());

        messages = messages.map((doc) => parseMessageDoc(doc));

        if (messages.length >= CHAT_PAGE_SIZE) {
          setMessagesOffset(response.docs[response.docs.length - 1]);
        } else {
          messagesReloading = true;
          setMessagesOffset(null);
        }

        if (messages.length > 0) {
          setMessagesList([...messages.reverse().concat(messagesList)]);
        }
      })
      .catch(() => {
        setMessagesLoading(false);
      });
  }

  function chatBodyOnScroll() {
    checkMessageInView();

    if (chatBodyRef.current && !messagesLoading && !messagesReloading) {
      if (chatBodyRef.current.scrollTop < 200) {
        getMessagesHistory(messagesOffset);
      }
    }
  }

  function parseMessageDoc(doc: any) {
    let timestamp = doc.timestamp;
    let resourceIds = doc.resourceIds || [];
    const id = doc.id;

    resourceIds = resourceIds.map((resource) => ({
      id: resource,
      url: null,
      type: null,
      name: null,
      loading: false,
      error: false,
    }));

    if (!timestamp) {
      timestamp = {
        seconds: new Date().getTime() / 1000,
        nanoseconds: null,
      };
    }

    return {
      ...doc,
      id,
      timestamp,
      resourceIds,
    };
  }

  function checkMessageInView() {
    messagesList.forEach((message) => {
      const msgEl = document.getElementById(`chatmsg-${message.id}`);

      if (msgEl && !isOutgoing(message.authorId) && isInViewport(msgEl)) {
        const msgCur = messagesList.find((msg) => msg.id === message.id);

        if (msgCur && !msgCur.readed) {
          fireStoreMessagesCollection
            .doc(msgCur.id)
            .update({
              readed: true,
            })
            .then(() => {
              const messagesListTemp = [...messagesList];

              const msgIndex = messagesListTemp.findIndex((msg) => msg.id === msgCur.id);

              if (msgIndex >= 0) {
                messagesListTemp[msgIndex].readed = true;

                setMessagesList([...messagesListTemp]);
              }
            });
        }
      }
    });
  }

  function setMsgResource(msgId: string, resourceNew: any) {
    const messagesListTemp = [...messagesList];

    const msgIndex = messagesListTemp.findIndex((msg) => msg.id === msgId);

    if (msgIndex >= 0) {
      messagesListTemp[msgIndex].resourceIds = messagesListTemp[msgIndex].resourceIds.map((resource) => {
        if (resourceNew.resourceId === resource.resourceId) {
          return { ...resourceNew };
        }
        return { ...resource };
      });

      setMessagesList([...messagesListTemp]);
    }
  }

  function getMsgResource(resourceId: string, msgId: string) {
    setMsgResource(msgId, {
      id: resourceId,
      url: null,
      name: null,
      type: null,
      loading: true,
      error: false,
    });
  }

  const getAuthorName = useCallback(
    (authorId: string) => {
      const patient = isDoctor ? props.appointment?.patient : profile;
      const doctor = isDoctor ? profile : props.appointment?.doctor;
      if (authorId === patient.id) {
        return [patient.lastName, patient.firstName, patient.middleName].filter((v) => v).join(' ');
      }
      if (authorId === doctor?.id) {
        return [doctor.lastName, doctor.firstName, doctor.middleName].filter((v) => v).join(' ');
      }
      if (authorId === 'system') {
        return 'Сервисное сообщение';
      }
      return null;
    },
    [isDoctor, profile, props.appointment?.doctor, props.appointment?.patient]
  );

  const getAuthorAvatar = useCallback(
    (authorId: string) => {
      const patient = isDoctor ? props.appointment?.patient : profile;
      const doctor = isDoctor ? profile : props.appointment?.doctor;
      if (authorId === patient.id) {
        return decodeAvatarResource(patient.avatar, 360);
      }
      if (authorId === doctor?.id) {
        return decodeAvatarResource(props.appointment.doctor.avatar, 360);
      }

      return decodeAvatarResource(null, 360);
    },
    [isDoctor, profile, props.appointment.doctor, props.appointment?.patient]
  );

  const isOutgoing = (authorId: string) => {
    return props.profile?.id === authorId;
  };

  const getMessageTime = (timestamp: any) => {
    let timestr = '';

    if (timestamp) {
      if (format(new Date(null).setTime(timestamp.seconds * 1000), 'dd.MM.yyyy') === format(new Date(), 'dd.MM.yyyy')) {
        timestr = format(new Date(null).setTime(timestamp.seconds * 1000), 'HH:mm');
      } else if (format(new Date(null).setTime(timestamp.seconds * 1000), 'yyyy') === format(new Date(), 'yyyy')) {
        timestr = format(new Date(null).setTime(timestamp.seconds * 1000), 'dd.MM HH:mm');
      } else {
        timestr = format(new Date(null).setTime(timestamp.seconds * 1000), 'dd.MM.yyyy HH:mm');
      }
    }

    return timestr;
  };

  const sendTextMessage = () => {
    setMessageSendLoading(true);
    const doc = fireStoreMessagesCollection.doc();
    doc
      .set({
        authorId: props.profile.id,
        id: doc.id,
        readed: false,
        roomId: props.appointment.id,
        text: messageValue,
        timestamp: myFirebase.firestore.FieldValue.serverTimestamp(),
        type: 'text',
      })
      .then(() => {
        setMessageValue('');
        setMessageSendLoading(false);
      })
      .catch(() => {
        setMessageSendLoading(false);
        toast.error('Ошибка при отправке сообщения');
      });
  };

  const sendFileMessage = () => {
    setMessageSendLoading(true);
    const doc = fireStoreMessagesCollection.doc();
    let payload = {};
    if (attachFiles.length === 1) {
      payload = {
        authorId: props.profile.id,
        id: doc.id,
        readed: false,
        resourceIds: [attachFiles[0].id],
        roomId: props.appointment.id,
        text: messageValue,
        timestamp: myFirebase.firestore.FieldValue.serverTimestamp(),
        type: attachFiles[0].type === 'application/pdf' ? 'file' : 'image',
      };
    } else {
      payload = {
        authorId: props.profile.id,
        id: doc.id,
        readed: false,
        resourceIds: attachFiles.map((value) => value.id),
        roomId: props.appointment.id,
        text: messageValue,
        timestamp: myFirebase.firestore.FieldValue.serverTimestamp(),
        type: 'file',
      };
    }

    doc
      .set(payload)
      .then(() => {
        setMessageValue('');
        setAttachFiles([]);
        setMessageSendLoading(false);
      })
      .catch(() => {
        setMessageSendLoading(false);
        toast.error('Ошибка при отправке сообщения');
      });
  };

  const sendMessage = (e) => {
    e.preventDefault();

    if (attachFiles.length > 0) {
      sendFileMessage();

      if (chatBodyRef.current) {
        setTimeout(() => {
          chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
        }, 300);
      }
    } else if (messageValue.length > 0) {
      sendTextMessage();

      if (chatBodyRef.current) {
        setTimeout(() => {
          chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
        }, 300);
      }
    }
  };

  const handleKeyPressEnter = useCallback(
    (event: any) => {
      !event.ctrlKey && event.key === 'Enter' && sendMessage(event);
    },
    [sendMessage]
  );

  function isInViewport(el) {
    const rect = el.getBoundingClientRect();

    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  const handleChangeFileIds = React.useCallback((files: AttachedFileInfo[]) => {
    setAttachFiles(files);
  }, []);

  const allowChat = useMemo(() => {
    return [AppointmentStatus.AWAITS, AppointmentStatus.IN_PROGRESS].indexOf(status) > -1;
  }, [status]);

  const isChatActive = useMemo(() => ['AWAITS', 'IN_PROGRESS'].includes(status), [status]);

  return (
    <div className={styles.appointmentChat}>
      {showToolbar && (
        <div className={styles.appointmentChat_toolsContainer}>
          <div className={styles.appointmentChat_toolsContainer_Profile}>
            <div className={styles.appointmentChat_toolsContainer_Profile_About}>
              <div
                className={styles.appointmentChat_toolsContainer_Profile_Avatar}
                style={{
                  backgroundImage: `url(${decodeAvatarResource(
                    (isDoctor ? appointment?.patient?.avatar : appointment?.doctor?.avatar) || null,
                    360
                  )})`,
                }}
              />
              <div className={styles.appointmentChat_toolsContainer_Profile_Name}>
                {[
                  appointment?.[isDoctor ? 'patient' : 'doctor']?.lastName,
                  appointment?.[isDoctor ? 'patient' : 'doctor']?.firstName,
                  appointment?.[isDoctor ? 'patient' : 'doctor']?.middleName,
                ]
                  .filter((v) => v)
                  .join(' ')}
              </div>
            </div>
            {allowChat && appointment && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <CallToolbar
                  appointment={appointment}
                  toggleDrawer={toggleDrawer}
                  showVideo={appointment?.type?.value !== 'PERSONAL' && isDoctor}
                  showPhone={appointment?.type?.value !== 'PERSONAL' && isDoctor}
                  showFiles={allowFiles}
                  callTo={{
                    id: appointment.doctor.id,
                    avatar: appointment.doctor.avatar,
                    firstName: appointment.doctor.firstName,
                    lastName: appointment.doctor.lastName,
                  }}
                />
              </div>
            )}
          </div>
        </div>
      )}
      <div className={styles.appointmentChat_wrapper}>
        {!showFiles && (
          <>
            {isDoctor && showSideBar && <ChatPatientData patient={appointment?.patient} />}
            <div id="chatBodyContainerId" ref={chatBodyRef} className={styles.appointmentChat_body}>
              <ContentLoading
                key="chat-content-loader"
                isLoading={false}
                isError={messagesLoadingError}
                fetchData={() => {}}
              >
                {messagesLoading && (
                  <div className="text-center mb-2">
                    <Spinner />
                  </div>
                )}
                {messagesList.map((message) => (
                  <ScrollElement
                    name={`chatmsg-${message.id}`}
                    id={`chatmsg-${message.id}`}
                    key={message.id}
                    className={styles.appointmentChat_message}
                  >
                    <div
                      className={styles.appointmentChat_message_avatar}
                      style={{ backgroundImage: `url(${getAuthorAvatar(message.authorId)})` }}
                    />
                    <div className={styles.appointmentChat_message_body}>
                      <h6 className={styles.appointmentChat_message_author}>
                        <div className={styles.appointmentChat_message_name}>{getAuthorName(message.authorId)}</div>
                        {isOutgoing(message.authorId) && (
                          <div className={styles.appointmentChat_message_time}>
                            {message.readed ? <MsgReadIcon className="mr-2" /> : <MsgUnreadIcon className="mr-2" />}
                          </div>
                        )}
                      </h6>
                      <div className={styles.appointmentChat_message_text}>
                        {message.text}

                        {message.resourceIds.length > 0 ? (
                          <div className={styles.appointmentChat_message_fileList}>
                            {message.resourceIds
                              .sort((rsrc) => {
                                if (rsrc.type === 'pdf') {
                                  return -1;
                                }
                                return 1;
                              })
                              .map((resource, index) => (
                                <ChatFileResource key={index} resourceId={resource?.id} />
                              ))}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div
                      className={classNames(
                        styles.appointmentChat_message_name,
                        styles.appointmentChat_message_timestamp
                      )}
                    >
                      {getMessageTime(message.timestamp)}
                    </div>
                  </ScrollElement>
                ))}
              </ContentLoading>
            </div>
          </>
        )}
        {showFiles && (
          <div className={styles.menuContainer}>
            <FilesView key={`chat-files-view-${appointment?.id}`} appointment={appointment} />
          </div>
        )}
      </div>
      {!isChatActive && (
        <div className={styles.appointmentChat_disableContainer}>
          <div>Консультация завершена, отправка сообщений невозможна</div>
        </div>
      )}
      {isChatActive && (
        <form
          ref={formRef}
          className={styles.appointmentChat_input}
          onKeyPress={handleKeyPressEnter}
          onSubmit={sendMessage}
        >
          {props.appointment.withFiles && (
            <div className={styles.appointmentChat_input_attach}>
              <ChatFileAttach
                disabled={!isCurrentDoctor}
                onLoadFiles={handleChangeFileIds}
                dropElement={document.querySelector('#root')}
                fileIds={attachFiles ? attachFiles.map((value) => value.id) : []}
                initFiles={[]}
                additionalFiles={[]}
              />
            </div>
          )}

          <InputField
            type="textarea"
            className={styles.appointmentChat_input_message}
            value={messageValue}
            autoresize
            disabled={!isCurrentDoctor}
            onChange={(e) => setMessageValue(e.target.value)}
            filled
            block
            placeholder="Введите сообщение.."
          />

          <button
            type="submit"
            className={styles.appointmentChat_input_sendBtn}
            disabled={messageSendLoading || messagesLoading || !isCurrentDoctor}
            title="Отправить сообщение"
          >
            {messageSendLoading ? (
              <Spinner width={25} height={25} color="#1473ff" />
            ) : (
              <SendIcon className={styles.appointmentChat_sendIcon} />
            )}
            <Box className={styles.appointmentChat_input_sendBtn_label}>Отправить сообщение</Box>
          </button>
        </form>
      )}
    </div>
  );
};

export default ChatView;
