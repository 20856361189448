import { PageSizeFilterRequest } from 'types/request';

export enum JournalType {
  Pulse = 'pulse',
  Pressure = 'pressure',
  Weight = 'weight',
  Sugar = 'sugar',
  Cholesterol = 'cholesterol',
  Temperature = 'temperature',
  Feeling = 'feeling',
}

export enum JournalValueColor {
  RED = '#FFD5D5',
  YELLOW = '#F8E4C2',
  GREEN = '#CCF5CC',
}

export const JournalValueColorStatus = {
  [JournalValueColor.RED]: '#ED5B88',
  [JournalValueColor.YELLOW]: '#FEEED5',
  [JournalValueColor.GREEN]: '#87D285',
};

export enum JournalTypeColor {
  RED = 'RED',
  YELLOW = 'YELLOW',
  GREEN = 'GREEN',
}

export enum JournalUnitType {
  Frequency = 'frequency',
  Dystolic = 'dystolic',
  Systolic = 'systolic',
  Kg = 'kg',
  Degrees = 'degrees',
  MmolPerLiter = 'mmolPerLiter',
  Ldl = 'ldl',
  Hdl = 'hdl',
  Tg = 'tg',
  Total = 'total',
  Text = 'text',
}

export const JournalUnitTypeTitle = {
  [JournalUnitType.Frequency]: 'уд/мин',
  [JournalUnitType.Dystolic]: 'Диастолическое',
  [JournalUnitType.Systolic]: 'Систолическое',
  [JournalUnitType.Kg]: 'кг.',
  [JournalUnitType.Degrees]: '°C',
  [JournalUnitType.MmolPerLiter]: 'ммоль/л',
  [JournalUnitType.Ldl]: 'ЛПНП',
  [JournalUnitType.Hdl]: 'ЛПВП',
  [JournalUnitType.Tg]: 'ТГ',
  [JournalUnitType.Total]: 'Общий',
  [JournalUnitType.Text]: 'Введите свои наблюдения',
};

export const JournalUnitTypeErrorMessage = {
  [JournalUnitType.Frequency]: 'Значение ":field" должно быть между 1 и 400',
  [JournalUnitType.Dystolic]: 'Значение ":field" должно быть между 1 и 300',
  [JournalUnitType.Systolic]: 'Значение ":field" должно быть между 1 и 400',
  [JournalUnitType.Kg]: 'Значение ":field" должно быть между 0.1 и 400',
  [JournalUnitType.Degrees]: 'Значение ":field" должно быть между 34 и 42',
  [JournalUnitType.MmolPerLiter]: 'Значение ":field" должно быть между 1.5 и 10',
  [JournalUnitType.Ldl]: 'Значение ":field" должно быть между 1 и 1000',
  [JournalUnitType.Hdl]: 'Значение ":field" должно быть между 1 и 1000',
  [JournalUnitType.Tg]: 'Значение ":field" должно быть между 1 и 1000',
  [JournalUnitType.Total]: 'Значение ":field" должно быть между 0 и 10',
};

export const JournalUnitTypeErrorColors = {
  [JournalUnitType.Systolic]: '#7587C5',
  [JournalUnitType.Dystolic]: '#BD64AA',
  [JournalUnitType.Total]: '#4F63D8',
  [JournalUnitType.Hdl]: '#B5D47A',
  [JournalUnitType.Tg]: '#8DC6EB',
  [JournalUnitType.Tg]: '#B66E71',
};
export const JournalUnitTypeUnitsDomain = {
  [JournalType.Pulse]: [0, 400],
  [JournalType.Pressure]: [],
  [JournalType.Weight]: [0, 400],
  [JournalType.Sugar]: [1.5, 10],
  [JournalType.Cholesterol]: [0, 10],
  [JournalType.Temperature]: [34, 42],
  [JournalType.Feeling]: [],
};
export const JournalUnitTypeUnits = {
  [JournalType.Pulse]: [JournalUnitType.Frequency],
  [JournalType.Pressure]: [JournalUnitType.Systolic, JournalUnitType.Dystolic],
  [JournalType.Weight]: [JournalUnitType.Kg],
  [JournalType.Sugar]: [JournalUnitType.MmolPerLiter],
  [JournalType.Cholesterol]: [JournalUnitType.Total],
  [JournalType.Temperature]: [JournalUnitType.Degrees],
  [JournalType.Feeling]: [JournalUnitType.Text],
};

export enum JournalFilterType {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
  CUSTOM = 'CUSTOM',
}

export const JournalFilterTypeTitle = {
  [JournalFilterType.DAY]: 'День',
  [JournalFilterType.WEEK]: 'Неделя',
  [JournalFilterType.MONTH]: 'Месяц',
  [JournalFilterType.YEAR]: 'Год',
};

export enum JournalViewType {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
}

export const JournalTitle = {
  [JournalType.Pulse]: 'Пульс',
  [JournalType.Pressure]: 'Давление',
  [JournalType.Weight]: 'Вес',
  [JournalType.Sugar]: 'Сахар',
  [JournalType.Cholesterol]: 'Холестерин',
  [JournalType.Temperature]: 'Температура',
  [JournalType.Feeling]: 'Заметки',
};

export interface JournalFilterRequest extends PageSizeFilterRequest {
  types?: string;
  type?: string;
  period?: string | JournalFilterType;
  timeFrom?: Date | string;
  timeTo?: Date | string;
  userId?: string;
}
