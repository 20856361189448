import { renderToString } from 'react-dom/server';
import html2pdf from 'html2pdf.js';
import { v4 as uuidv4 } from 'uuid';
import CertificatePdf from '../components/CertificatePdf';

const printToPdfCertificate = (data: any, filename?: string) => {
  const page = renderToString(<CertificatePdf {...data} />);
  filename = filename || `${uuidv4()}.pdf`;
  const opt = {
    margin: 0,
    filename,
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 2, backgroundColor: '#f4f9fd', height: 1055 },
    jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
  };
  html2pdf().set(opt).from(page).save();
};

export default printToPdfCertificate;
