import { createEffect } from 'effector';
import { ResponseCode } from 'types';
import { toast } from 'react-toastify';
import { createPersonalAppointment } from '$api';

export const createPersonalAppointmentFx = createEffect(
  async (id: string): Promise<any> => {
    const res = await createPersonalAppointment(id)
      .then()
      .catch((res) => res);
    if (res?.data?.code === ResponseCode.SUCCESS) {
      return res?.data?.data?.id || null;
    }
    toast.error('Ошибка при запросе');
    throw new Error(res?.data?.code);
  }
);
