import {validateFieldOnChange} from "../validateFieldOnChange";
import {getFieldErrors as getFieldErrorsUtil} from "../getFieldErrors";
import {useState} from "react";

export function  useFormValidation<T>(initFields: T){

  const [formErrors, setFormErrors] = useState<any>([])
  const [fields, setFields] = useState<T>(initFields)

  const validateOnChange = (name: string, value: any, event, element?: any) => {
    validateFieldOnChange(name, value, event, fields, setFields, formErrors, setFormErrors, element)
  };
  const getFieldErrors = (field: string) => getFieldErrorsUtil(field, formErrors)

  return [fields, validateOnChange, getFieldErrors, formErrors, setFormErrors, setFields] as
    [typeof fields, typeof validateOnChange, typeof getFieldErrors, typeof formErrors, typeof setFormErrors, typeof setFields]
}
