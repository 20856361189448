import axios from 'utils/axios';

export const getCategories = (query?: string, active?: boolean) => {
  return axios.get(`/api/v1/admin/doctorcategories`, {
    params: {
      query,
      active: active === true ? true : active === false ? false : null,
    },
  });
};
