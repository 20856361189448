import {
  SET_DOCTOR,
  SET_DOCTOR_AVAILABILITY,
  DOCTOR_PROFILE_SUCCESS,
  DOCTOR_PROFILE_LOADING,
  DOCTOR_PROFILE_ERRORS,
  UPDATE_DOCTOR,
  UPDATE_CURRENT_APPOINTMENT_STATUS
} from '../actions';
import Doctor, { AvailabilityStatus } from '../../types/doctor';
import { getDisplayName } from '../../utils';

export interface DoctorInitialSettings {
  availability?: AvailabilityStatus;
  id?: string;
  avatar?: Doctor.Avatar;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  displayName?: string;
  activeAppointment?: Doctor.ProfileMetaInfo.ActiveAppointment;
  profile: Doctor.Profile;
  loadingProfile?: boolean;
  errorsProfile?: boolean;
}
const initialSettings: DoctorInitialSettings = {
  id: null,
  firstName: null,
  lastName: null,
  middleName: null,
  displayName: null,
  availability: null,
  activeAppointment: null,
  profile: {},
  loadingProfile: null,
  errorsProfile: null,
};

const doctorReducer = (state = initialSettings, action): DoctorInitialSettings => {
  switch (action.type) {
    case SET_DOCTOR:
      const {
        id,
        firstName,
        lastName,
        middleName,
        avatar,
        activeAppointment = null,
        availability: { value = null },
      } = action.meta as Doctor.ProfileMetaInfo.Data;
      return {
        ...state,
        id,
        avatar,
        firstName,
        lastName,
        middleName,
        activeAppointment,
        displayName: getDisplayName(firstName, lastName, middleName),
        availability: value,
      };
    case UPDATE_DOCTOR:
      return {
        ...state,
        firstName: action.meta.firstName,
        lastName: action.meta.firstName,
        middleName: action.meta.firstName,
        activeAppointment: action.meta.activeAppointment,
        displayName: getDisplayName(action.meta.firstName, action.meta.lastName, action.meta.middleName),
        availability:
          action.meta.availability && action.meta.availability.value ? action.meta.availability.value : null,
      };
    case SET_DOCTOR_AVAILABILITY:
      return {
        ...state,
        availability: action.availability,
      };
    case DOCTOR_PROFILE_LOADING:
      return {
        ...state,
        loadingProfile: action.loading,
      };
    case DOCTOR_PROFILE_ERRORS:
      return {
        ...state,
        errorsProfile: action.errors,
      };
    case DOCTOR_PROFILE_SUCCESS:
      return {
        ...state,
        errorsProfile: null,
        profile: action.profile,
      };
    case UPDATE_CURRENT_APPOINTMENT_STATUS:
      return {
        ...state,
        activeAppointment: {
          ...state.activeAppointment,
          status: action.status
        }
      }
    default:
      return state;
  }
};

export default doctorReducer;
