import React, { useMemo } from 'react';
import ChatView from 'views/AppointmentChatPage/components/ChatView';
import useRootSelector from '../../../../hooks/useRootSelector';
import { selectAppointmentDoctorAppointment } from '../../store/selectors';

const AppointmentDoctorChat = () => {
  const appointment = useRootSelector(selectAppointmentDoctorAppointment);
  const doctor = useMemo(() => appointment?.doctor, [appointment]);
  return (
    <>
      <ChatView
        showToolbar={false}
        showSideBar
        isDoctor
        profile={doctor}
        showFiles={false}
        allowFiles={false}
        appointment={appointment}
      />
    </>
  );
};

export default AppointmentDoctorChat;
