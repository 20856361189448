import React, {useEffect, useState} from "react";
import {SelectInputProps} from "../Forms/SelectInput/SelectInput";
import SelectInput from "../Forms/SelectInput";
import {AxiosResponse} from "axios";
import {dictionaryApi} from "../../api/dictionaryApi";

interface AdminAdditionalServicesSelectorViewProps extends SelectInputProps {

}

const AdminAdditionalServicesSelectorView: React.FC<AdminAdditionalServicesSelectorViewProps> = (props) => {

  const {value, options,...rest} = props

  const [services, setServices] = useState([]);

  useEffect(() => init(), [])

  const init = (): void => {
    dictionaryApi.getAdditionalServiceTypes()
      .then((response: AxiosResponse<any>) => {
        if (response.data.code === 'success') {
          setServices(response.data.data.items);
        } else {
          setServices([])
        }
      })
      .catch((err) => {
        console.error(err)
        setServices([])
      });
  };

  return (
    <SelectInput
      value={value?.map((s) => ({
        label: (services?.find((_s) => _s.value === s) || {}).name,
        value: s,
      }))}
      options={services?.map((s) => ({ label: s.name, value: s.value }))}
      {...rest}
    />
  )
}

export default AdminAdditionalServicesSelectorView
