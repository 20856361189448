import axios from 'utils/axios';
import Manage from "../../types/manage";

export const getDailyConclusionStrategies = (params?: Manage.DailyStrategies.Request) => {
  let { query, active, page, pageSize, deleted = false } = params;
  query = query || null;
  page = page || 0;
  pageSize = pageSize || 50;
  active = active || null;
  return axios.get(`/api/v1/dailyconclusionstrategies`, {
    params: {
      query,
      active,
      page,
      pageSize,
      deleted,
    },
  });
};
