import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAppointmentView, fetchProfile, updateAppointmentViewStatus } from 'store/actions';
import ContentCard from 'components/ContentCard';
import ContentLoading from 'components/ContentLoading';
import { format } from 'date-fns';
import styles from './AppointmentChatPage.module.sass';
import ChatView from './components/ChatView';
import { AppointmentsType } from '../../types';
import { AppointmentTimer } from '../../components/AppointmentTimer/AppointmentTimer';
import useIsDoctorRole from '../../hooks/useIsDoctorRole';
import LinkBack from '../../components/LinkBack';
import { AppointmentStatus } from '../AppointmentDoctor/types';
import { onHideJivoChat, onShowJivoChat } from '../../providers/JivoSiteProvider';

interface PropsInterface {
  match: any;
}
const AppointmentChatPage = (props: PropsInterface): JSX.Element => {
  const { match } = props;
  const {
    appointmentView,
    appointmentViewLoading,
    appointmentViewLoadingError,
    profile,
    profileLoading,
    profileLoadingError,
  } = useSelector((state: any) => ({
    appointmentView: state.appointment.appointmentView,
    appointmentViewLoading: state.appointment.appointmentViewLoading,
    appointmentViewLoadingError: state.appointment.appointmentViewLoadingError,

    profile: state.profile.profile,
    profileLoading: state.profile.profileLoading,
    profileLoadingError: state.profile.profileLoadingError,
  }));
  const dispatch = useDispatch();
  const isDoctor = useIsDoctorRole();
  const appointmentId = useMemo(() => match?.params?.appointmentId, [match]);
  const handleFetchAppointmentView = useCallback(
    () => dispatch(fetchAppointmentView(appointmentId)),
    [dispatch, appointmentId]
  );
  const handleFetchProfile = useCallback(() => dispatch(fetchProfile()), [dispatch]);
  const handleUpdateAppointmentViewStatus = useCallback(
    (appointmentId) => dispatch(updateAppointmentViewStatus(appointmentId)),
    [dispatch]
  );
  const [showFiles, setShowFiles] = useState<boolean>(false);

  const status = useMemo(() => appointmentView?.status?.value, [appointmentView?.status?.value]);

  const isActive = useMemo(
    () => [AppointmentStatus.IN_PROGRESS, AppointmentStatus.FINISHED].includes(status),
    [status]
  );

  const init = useCallback(() => {
    handleFetchAppointmentView();
    !isDoctor && handleFetchProfile();
  }, [handleFetchAppointmentView, handleFetchProfile, isDoctor]);

  useEffect(() => {
    init();
  }, [init]);

  const checkStatus = useCallback(() => {
    handleUpdateAppointmentViewStatus(appointmentView?.id);
  }, [handleUpdateAppointmentViewStatus, appointmentView]);

  useEffect(() => {
    const timer: any = isActive ? setInterval(checkStatus, 10000) : null;
    return () => {
      timer !== null && clearInterval(timer);
    };
  }, [checkStatus, isActive]);

  const toggleDrawer = useCallback(
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setShowFiles((showFiles) => !showFiles);
    },
    [setShowFiles]
  );

  const title = useMemo(() => {
    const status = appointmentView?.status?.value as AppointmentsType;
    const modifiedAt = appointmentView?.lastModifiedAt;
    const formatter: string = 'dd.MM.yyyy в HH:mm';
    switch (status) {
      case 'DONE':
        return `Завершена ${format(new Date(modifiedAt), formatter)}`;
      case 'FINISHED':
        return `Закрыта ${format(new Date(modifiedAt), formatter)}`;
      case 'FAILED':
        return `Техническая проблема ${format(new Date(modifiedAt), formatter)}`;
      case 'CANCELED':
        return `Отменена ${format(new Date(modifiedAt), formatter)}`;
      default:
        return null;
    }
  }, [appointmentView]);

  useEffect(() => {
    onHideJivoChat();
    return () => {
      onShowJivoChat();
    };
  }, []);

  return (
    <ContentLoading
      key="contentLoading"
      isLoading={appointmentViewLoading || (!isDoctor && profileLoading)}
      isError={appointmentViewLoadingError || (!isDoctor && profileLoadingError)}
      fetchData={() => init()}
    >
      <ContentCard>
        <ContentCard.Header className={styles.appointmentChatView_Header}>
          <LinkBack />
          <h6 className={styles.appointmentChatView_Header_Title}>
            {showFiles ? 'Вложенные файлы' : appointmentView?.type?.name || 'Консультация'}
          </h6>
          <div>
            {!title && appointmentView && appointmentView?.type?.value !== 'PERSONAL' && (
              <AppointmentTimer appointment={appointmentView} status={appointmentView?.status?.value} />
            )}
            {title && <div className={`${styles.appointmentChat_title}`}>{title}</div>}
          </div>
        </ContentCard.Header>

        {((!isDoctor && profile) || isDoctor) && appointmentView && (
          <ContentCard.Main className={styles.appointmentChatView_CardMain}>
            <ChatView
              key={`chat-view-${appointmentView?.id}`}
              toggleDrawer={toggleDrawer}
              isDoctor={isDoctor}
              profile={isDoctor ? appointmentView?.doctor : profile}
              showFiles={showFiles}
              appointment={appointmentView}
            />
          </ContentCard.Main>
        )}
      </ContentCard>
    </ContentLoading>
  );
};

export default AppointmentChatPage;
