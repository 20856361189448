import { attach, createStore, forward } from 'effector';
import {
  onDeleteJournal,
  onEditJournal,
  onGetJournal,
  onSetJournalCurrent,
  onSetJournalDate,
  onSetJournalDateCustom,
  onSetJournalDelete,
  onSetJournalEdit,
  onSetJournalModel,
  onSetJournalUserId,
  onSetJournalReset,
  onSetJournalType,
  onSetJournalView,
  onShowMore,
  onSetJournalPatient,
} from './events';
import { JournalFilterType, JournalType, JournalUnitType, JournalUnitTypeUnits, JournalViewType } from '../types';
import { originDeleteJournalFx, originGetJournalFx, originPostJournalFx } from './effects';
import { convertUtcToZonedTime } from '../../../utils/date';
import { prepareJournalDates } from '../helpers';

const currentDate = () => convertUtcToZonedTime(new Date());

export interface JournalStateInterface {
  type: string | JournalType | null;
  view: string | JournalViewType | null;
  date: string | JournalFilterType | null;
  model: any;
  current: Array<any>;
  timeFrom: Date;
  timeTo: Date;
  page: number;
  pageSize: number;
  items: Array<any>;
  averaged: Array<any>;
  loading: boolean;
  error: any;
  totalItems: number;
  deleteItem: any;
  patient?: any;
}

const resetPage = { page: 0, pageSize: 20 };

const defaultState: JournalStateInterface = {
  type: null,
  view: JournalViewType.VIEW,
  date: JournalFilterType.DAY,
  model: {},
  current: [],
  loading: true,
  totalItems: 0,
  items: [],
  averaged: [],
  deleteItem: null,
  patient: null,
  error: true,
  ...prepareJournalDates(JournalFilterType.DAY),
  ...resetPage,
};

const $store = createStore(defaultState);
const prependParams = (states: JournalStateInterface) => {
  const { timeFrom, timeTo, type, page, pageSize, date, patient } = {
    ...states,
    ...prepareJournalDates(states.date, states?.date === JournalFilterType.CUSTOM && (states.timeFrom || null)),
  };
  let period = JournalFilterType.DAY;
  switch (date) {
    case JournalFilterType.MONTH:
    case JournalFilterType.YEAR:
      period = date;
      break;
    case JournalFilterType.WEEK:
      period = JournalFilterType.MONTH;
      break;
    default:
      break;
  }
  return {
    page,
    pageSize,
    types: type.toUpperCase(),
    timeFrom,
    timeTo,
    type: type.toUpperCase(),
    period,
    ...(patient?.id ? { userId: patient?.id } : {}),
  };
};
const mapParams = (_: any, states: JournalStateInterface) => {
  return { _, params: { ...prependParams(states), page: 0 } };
};
export const postJournalFx = attach({
  effect: originPostJournalFx,
  source: $store,
  mapParams,
});
export const deleteJournalFx = attach({
  effect: originDeleteJournalFx,
  source: $store,
  mapParams,
});
export const getJournalFx = attach({
  effect: originGetJournalFx,
  source: $store,
  mapParams: (_: any, states: JournalStateInterface) => {
    return prependParams(states);
  },
});
forward({ from: onGetJournal, to: getJournalFx });
forward({ from: onEditJournal, to: postJournalFx });
forward({ from: onDeleteJournal, to: deleteJournalFx });
$store.on(onSetJournalDelete, (_, deleteItem) => ({ ..._, deleteItem }));
$store.on(onSetJournalCurrent, (_, current) => ({ ..._, current }));
$store.on(onSetJournalType, (_, type) => {
  return {
    ..._,
    type,
    loading: true,
    view: JournalViewType.VIEW,
    date: JournalFilterType.DAY,
    ...(type ? { ...prepareJournalDates(JournalFilterType.DAY), ...resetPage } : {}),
  };
});
$store.on(onSetJournalUserId, (_, id) => {
  return {
    ..._,
    patient: { id },
  };
});
$store.on(onSetJournalPatient, (_, patient) => {
  return {
    ..._,
    patient: patient ? { ...(_.patient || {}), ...(patient || {}) } : _.patient || null,
  };
});
$store.on(onSetJournalView, (_, view) => ({
  ..._,
  view: view || _.view,
  date: JournalFilterType.DAY,
  ...(view === JournalViewType.VIEW ? { ...prepareJournalDates(JournalFilterType.DAY), ...resetPage } : {}),
}));
$store.on(onShowMore, (_) => ({ ..._, page: _.page + 1 }));
$store.on(onSetJournalEdit, (_, model) => {
  const defaultValues = {};
  JournalUnitTypeUnits[_.type].forEach((value) => {
    defaultValues[value] = '';
  });
  if (_?.type === JournalType.Cholesterol) {
    [JournalUnitType.Hdl, JournalUnitType.Ldl, JournalUnitType.Tg].forEach((value) => {
      defaultValues[value] = 1;
    });
  }
  return {
    ..._,
    view: JournalViewType.EDIT,
    model: model
      ? { ...model, [_.type]: { ...defaultValues, observationTime: currentDate(), ...(model?.[_.type] || {}) } }
      : { [_.type]: { ...defaultValues, observationTime: currentDate() } },
  };
});
$store.on(onSetJournalModel, (_, model) => ({ ..._, model }));
$store.on(onSetJournalDate, (_, date) => ({ ..._, date, ...prepareJournalDates(date), ...resetPage, averaged: [] }));
$store.on(onSetJournalDateCustom, (_, date) => ({
  ..._,
  date: JournalFilterType.CUSTOM,
  ...prepareJournalDates(JournalFilterType.CUSTOM, date),
  ...resetPage,
  averaged: [],
}));
$store.on(onSetJournalReset, (_, date) => ({ ...defaultState, ...(date || {}) }));
$store.on(getJournalFx.pending, (_, loading) => ({ ..._, loading, error: null }));
$store.on(getJournalFx.doneData, (_, data) => ({
  ..._,
  totalItems: data?.totalItems || 0,
  items: _.page > 0 ? _.items.concat(data?.items || []) : data?.items,
  current: data?.current || [],
  averaged: data?.averaged || [],
  patient: data?.patient ? { ...(_.patient || {}), ...(data?.patient || {}) } : _.patient || null,
}));
$store.on(deleteJournalFx.doneData, (_, data) => ({
  ..._,
  ...data,
}));
$store.on(getJournalFx.fail, (_, error) => ({ ..._, error }));
$store.on(postJournalFx.pending, (_, loading) => ({ ..._, loading, error: null }));
$store.on(postJournalFx.doneData, (_, data) => ({
  ..._,
  ...data,
  view: JournalViewType.VIEW,
}));
$store.on(postJournalFx.fail, (_, error) => ({ ..._, error }));
export default $store;
