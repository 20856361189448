import React, {useEffect, useState} from "react";
import SelectInput from "../../../../../components/Forms/SelectInput";
import {useFetchServicePackageGroups} from "../hooks/useFetchServicePackageGroups";
import {SelectInputProps, SelectOptions} from "../../../../../components/Forms/SelectInput/SelectInput";


interface ServicePackageGroupSelectorProps extends SelectInputProps{
  isEmpty?: boolean
}

const ServicePackageGroupSelector: React.FC<ServicePackageGroupSelectorProps> = (props) => {

  const {value , options, isEmpty = false, ...rest} = props

  const [data] = useFetchServicePackageGroups()
  const [selected, setSelected] = useState<{label: string, value: string}>()
  const [selectOptions, setSelectOptions] = useState<SelectOptions>([])

  useEffect(() => {
    if(value || isEmpty) {
      const found = data.find(it => it?.id === value)
      if(found){
        setSelected({value: value, label: found.name})
      }else{
        setSelected(null)
      }
    }
  }, [value, data, isEmpty])

  useEffect(() => {
    let options = []
    if(isEmpty){
      options.push({value: null, label: 'Не указано'})
    }
    setSelectOptions([...options, ...data.map((s) => ({ label: s.name, value: s.id }))])
  }, [data, isEmpty])


  return (
    <SelectInput
      value={selected}
      options={selectOptions}
      {...rest}
    />
  )
}

export default ServicePackageGroupSelector
