import React, { ReactElement, useCallback } from 'react';
import { Box } from '@material-ui/core';
import useRootSelector from 'hooks/useRootSelector';
import Button from 'components/Forms/Button';
import PageTitle from 'components/PageTitle';
import styles from './CheckerWrapper.module.sass';
import {
  selectCheckerBackStep,
  selectCheckerChecker,
  selectCheckerLoading,
  selectCheckerLoadingOrder,
  selectCheckerNextStep,
  selectCheckerStep,
  selectCheckerStepIsLast,
  selectCheckerStepIsValid,
  selectCheckerSuccess,
} from '../../store/selectors';
import { onSetOrderChecker, onSetStepChecker } from '../../store/events';
import { CheckerTypeTranslate } from '../../types';
import CheckerSuccess from '../CheckerSuccess';
import CheckerFrom from '../CheckerForm';

const CheckerWrapper = (): ReactElement => {
  const step = useRootSelector(selectCheckerStep);
  const success = useRootSelector(selectCheckerSuccess);
  const backStep = useRootSelector(selectCheckerBackStep);
  const nextStep = useRootSelector(selectCheckerNextStep);
  const isValid = useRootSelector(selectCheckerStepIsValid);
  const isLast = useRootSelector(selectCheckerStepIsLast);
  const loading = useRootSelector(selectCheckerLoading);
  const loadingOrder = useRootSelector(selectCheckerLoadingOrder);
  const checker = useRootSelector(selectCheckerChecker);
  const handleNext = useCallback(() => {
    isLast ? onSetOrderChecker(checker) : onSetStepChecker(nextStep);
  }, [checker, isLast, nextStep]);
  const handleBack = useCallback(() => {
    onSetStepChecker(backStep);
  }, [backStep]);
  return (
    <>
      <Box className={styles.root}>
        {success && <CheckerSuccess />}
        {!success && <PageTitle center>{CheckerTypeTranslate?.[step]}</PageTitle>}
        {!success && <CheckerFrom />}
        {!success && (
          <Box className={styles.root_actions}>
            <Button
              className={styles.root_actions_next}
              disabled={!isValid || loading || loadingOrder}
              onClick={handleNext}
              size="lg"
              isLoading={loadingOrder}
              type="button"
              color="primary"
            >
              {isLast ? 'Результат' : 'Далее'}
            </Button>
            {backStep && (
              <Button className={styles.root_actions_back} size="lg" type="button" color="default" onClick={handleBack}>
                Назад
              </Button>
            )}
          </Box>
        )}
      </Box>
    </>
  );
};
export default CheckerWrapper;
