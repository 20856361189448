import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { authESIALogin } from '../../store/actions';
import FullPageLoader from '../../components/FullPageLoader';

const RedirectEsia = (): JSX.Element => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { loading } = useSelector((state: any) => state?.auth?.loginLoading);
  useEffect(() => {
    const code = queryString.parse(location?.search)?.code as string;
    if (code) {
      dispatch(authESIALogin(code, history));
    } else {
      history.push('/login');
    }
  }, [location]);
  return <>{loading && <FullPageLoader />}</>;
};

export default RedirectEsia;
