import axios from 'utils/axios';

export const findPatientServicePackages = (
  query?: string,
  status?: string,
  categoryBenefitsId?: string,
  createdFrom?: Date,
  createdTo?: Date,
  signOnline?: any,
  groupId?: string,
  page?: number,
  pageSize?: number
) => {
  query = query || null;
  status = status || null;
  categoryBenefitsId = categoryBenefitsId || null;
  createdFrom = createdFrom || null;
  createdTo = createdTo || null;
  groupId = groupId || null;
  page = page || 0;
  pageSize = pageSize || 50;
  return axios.get(`/api/v1/servicepackages/patient`, {
    params: {
      query,
      status,
      categoryBenefitsId,
      createdFrom,
      createdTo,
      signOnline,
      groupId,
      page,
      pageSize,
    },
  });
};
