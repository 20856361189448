import React from 'react';
import BaseDialog, { BaseDialogProps } from '../BaseDialog/BaseDialog';
import AdminMarketingInfoView from './AdminMarketingInfoView';

interface AdminMarketingInfoDialogProps extends BaseDialogProps {
  servicePackageId: string;
  disabled?: boolean;
}

const AdminMarketingInfoDialog: React.FC<AdminMarketingInfoDialogProps> = (props) => {
  const { servicePackageId, onClosed, title, disabled = false } = props;

  return (
    <BaseDialog onClosed={onClosed} title={title}>
      <AdminMarketingInfoView
        disabled={disabled}
        servicePackageId={servicePackageId}
        onCancel={onClosed}
        positiveBtnText="Применить"
      />
    </BaseDialog>
  );
};

export default AdminMarketingInfoDialog;
