import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import Doctor, { AvailabilityStatus } from '../../types/doctor';
import { updateSelfAvailability } from '../../api/updateSelfAvailability';
import {AppointmentsType, ResponseData} from '../../types';
import { getDoctorProfile } from '../../api/getDoctorProfile';
import { getDoctorsProfileMetaInfo } from '../../api/getProfileMetaInfo';
import {displayErrors, handleHttpError} from "../../utils/handleHttpError";

export const SET_DOCTOR = 'SET_DOCTOR';

export const UPDATE_DOCTOR = 'UPDATE_DOCTOR';

export const SET_DOCTOR_AVAILABILITY = 'SET_DOCTOR_AVAILABILITY';

export const DOCTOR_PROFILE_SUCCESS = 'DOCTOR_PROFILE_SUCCESS';
export const DOCTOR_PROFILE_LOADING = 'DOCTOR_PROFILE_LOADING';
export const DOCTOR_PROFILE_ERRORS = 'DOCTOR_PROFILE_ERRORS';
export const UPDATE_CURRENT_APPOINTMENT_STATUS = 'UPDATE_CURRENT_APPOINTMENT_STATUS';

export const setDoctorProfile = (profile: Doctor.Profile) => ({ type: DOCTOR_PROFILE_SUCCESS, profile });
export const setDoctorProfileLoading = (loading: boolean) => ({ type: DOCTOR_PROFILE_LOADING, loading });
export const setDoctorProfileErrors = (errors: any) => ({ type: DOCTOR_PROFILE_ERRORS, errors });
export const setDoctor = (meta: Doctor.ProfileMetaInfo.Data) => ({ type: SET_DOCTOR, meta });
export const updateDoctor = (meta: Doctor.ProfileMetaInfo.Data) => ({ type: UPDATE_DOCTOR, meta });
export const setDoctorAvailability = (availability: AvailabilityStatus) => ({
  type: SET_DOCTOR_AVAILABILITY,
  availability,
});
export const updateCurrentAppointmentStatus = (status: Doctor.AppointmentStatus) => {
  return (dispatch) => {
    dispatch({type: UPDATE_CURRENT_APPOINTMENT_STATUS, status})
  }
}

export const updateDoctorAvailability = (availability: AvailabilityStatus) => {
  return (dispatch) => {
    updateSelfAvailability(availability)
      .then((response: AxiosResponse<ResponseData<Doctor.Availability>>) => {
        if (response.data.code === 'success') {
          dispatch(setDoctorAvailability(availability));
        } else {
          toast.error('Ошибка при запросе! ' + response.data.code);
        }
      })
      .catch((e) => {
        const problem  = handleHttpError(e);
        console.error(problem);
        displayErrors(problem)
      });
  };
};

export const fetchDoctorProfile = () => {
  return (dispatch) => {
    dispatch(setDoctorProfileLoading(true));
    getDoctorProfile()
      .then((response: AxiosResponse<ResponseData<Doctor.Profile>>) => {
        dispatch(setDoctorProfileLoading(false));
        if (response.data.code === 'success') {
          dispatch(setDoctorProfile(response.data.data));
        } else {
          dispatch(setDoctorProfileErrors(response.data));
          toast.error('Ошибка при запросе');
        }
      })
      .catch((errors: any) => {
        dispatch(setDoctorProfileLoading(false));
        dispatch(setDoctorProfileErrors(errors));
        toast.error('Ошибка при запросе');
      });
  };
};

export const updateDoctorMeta = () => {
  return (dispatch) => {
    getDoctorsProfileMetaInfo()
      .then((response: AxiosResponse<ResponseData<Doctor.ProfileMetaInfo.Data>>) => {
        if (response.data.code === 'success') {
          dispatch(updateDoctor(response.data.data));
        }
      })
      .catch(console.log);
  };
};
