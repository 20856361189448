import React, { useEffect, useState } from 'react';
import ContentCard from 'components/ContentCard';
import { Avatar, Box } from '@material-ui/core';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import classNames from 'classnames';
import TuneIcon from '@material-ui/icons/Tune';
import { AxiosResponse } from 'axios';
import { RouteComponentProps } from 'react-router';
import { useHistory } from 'react-router-dom';
import styles from './DoctorsPageAdmin.module.sass';
import { fetchManageDoctors, updateManageDoctorStatus, updateManageDoctorStatusActive } from '../../../store/actions';
import ContentLoading from '../../../components/ContentLoading';
import { decodeAvatarResource } from '../../../utils';
import InputField from '../../../components/Forms/InputField';
import Button from '../../../components/Forms/Button';
import Manage from '../../../types/manage';
import DropdownToggle from '../../../components/DropdownMenu/DropdownToggle';
import DropdownMenu from '../../../components/DropdownMenu';
import DropdownContent from '../../../components/DropdownMenu/DropdownContent';
import { ReactComponent as CheckIcon } from '../../../assets/img/icons/check-icon.svg';
import AdminDoctorView from '../../../components/AdminDoctorView';
import StarRating from '../../../components/StarRating/StarRating';
import { updateAnyDoctorAvailability } from '../../../api/updateAnyDoctorAvailability';
import { displayErrors, handleHttpError } from '../../../utils/handleHttpError';
import UpdateDoctorStatus from '../../../components/UpdateDoctorStatus';
import DeleteDialog from '../../../components/DeleteDialog';
import { dropDoctorsPassword } from '../../../api';
import useIsAdminRole from '../../../hooks/useIsAdminRole';
import HasRole from '../../../components/HasRole';
import { UserRole } from '../../../types';

interface PropsInterface extends RouteComponentProps<any> {
  page?: number;
  pageSize?: number;
  hasMore?: boolean;
  loading?: boolean;
  errors?: any;
  doctors?: Array<any>;
  fetchManageDoctors?: (
    query: string,
    availability?: string,
    page?: number,
    pageSize?: number,
    reset?: boolean
  ) => void;
  updateManageDoctorStatus?: (doctorId: string, status: string) => void;
  updateManageDoctorStatusActive?: (doctorId: string, active: boolean) => void;
}
const DoctorsPageAdmin = (props: PropsInterface) => {
  const history = useHistory();
  const {
    doctors = [],
    loading,
    errors,
    page,
    pageSize,
    hasMore,
    fetchManageDoctors,
    updateManageDoctorStatus,
    updateManageDoctorStatusActive,
  } = props;
  const [timeoutSearch, setTimeoutSearch] = useState<any>(null);
  const [search, onChangeSearch] = useState<string>('');
  const [toolbarOpen, toggleToolbarOpen] = useState<boolean>(false);
  const [openDoctorStatus, toggleOpenDoctorStatus] = useState<string>(null);
  const [doctor, setDoctor] = useState<Manage.Doctor.Item>(null);
  const [dropDoctor, setDropDoctor] = useState<Manage.Doctor.Item>(null);
  const [status, setStatus] = useState<Manage.Doctor.AvailabilityType>(null);
  const isAdmin = useIsAdminRole();
  useEffect(() => {
    if (timeoutSearch) {
      clearTimeout(timeoutSearch);
    }
    setDoctor(null);
    if (search && search.length) {
      setTimeoutSearch(
        setTimeout(() => {
          getDoctors(true);
        }, 500)
      );
    } else {
      getDoctors(true);
    }
    return () => {
      if (timeoutSearch) {
        clearTimeout(timeoutSearch);
      }
    };
  }, [search, status]);
  const onSetStatus = (value: string) => {
    updateAnyDoctorAvailability(openDoctorStatus, value)
      .then((data: AxiosResponse<any>) => {
        if (data.data.code === 'success') {
          updateManageDoctorStatus(openDoctorStatus, value);
          toggleOpenDoctorStatus(null);
        } else {
          throw new Error();
        }
      })
      .catch((e) => {
        toggleOpenDoctorStatus(null);
        displayErrors(handleHttpError(e));
      });
  };
  const getDoctors = (reset?: boolean) => {
    fetchManageDoctors(search, status, reset ? 0 : reset ? 0 : page + 1, pageSize, reset);
  };

  const dropPassword = (doctor: Manage.Doctor.Item): Promise<any> => {
    return dropDoctorsPassword(doctor.id);
  };

  const getLabelStatus = (status: Manage.Doctor.AvailabilityType): string => {
    switch (status) {
      case 'AWAY':
        return 'Нет на месте';
      case 'BUSY':
        return 'Занят';
      case 'DUTY':
        return 'На смене';
      case 'INVITED':
        return 'Приглашение отправлено';
      case 'NOT_DUTY':
        return 'Не на смене';
      default:
        return null;
    }
  };
  return (
    <>
      <ContentCard>
        <ContentCard.Header>
          <div className="row">
            <div className="col-12 text-center text-xs-right text-md-center">
              <h6 className="page-header-title">Наши доктора</h6>
            </div>
          </div>
        </ContentCard.Header>
        <Box className={styles.DoctorsPageAdmin_Toolbar}>
          <Box className={styles.DoctorsPageAdmin_Toolbar_Search}>
            <SearchIcon className={styles.DoctorsPageAdmin_Toolbar_Search_Icon} />
            <InputField
              value={search}
              onChange={(e) => {
                onChangeSearch(e.target.value);
              }}
              block
              placeholder="Введите ФИО доктора"
            />
            {search && (
              <ClearIcon
                onClick={() => {
                  onChangeSearch('');
                }}
                className={classNames(
                  styles.DoctorsPageAdmin_Toolbar_Search_Icon,
                  styles.DoctorsPageAdmin_Toolbar_Search_Icon_Clear
                )}
              />
            )}
          </Box>
          <Box className={styles.DoctorsPageAdmin_Toolbar_Buttons}>
            <DropdownMenu isOpen={toolbarOpen} toggle={() => toggleToolbarOpen(!toolbarOpen)}>
              <DropdownToggle>
                <Button color="primary" size="md">
                  <TuneIcon />
                  {status != null && <Box className={styles.DoctorsPageAdmin_Toolbar_Buttons_Status} />}
                </Button>
              </DropdownToggle>
              <DropdownContent className={styles.DoctorsPageAdmin_Toolbar_DropdownContent}>
                <ul>
                  {['AWAY', 'BUSY', 'DUTY', 'INVITED', 'NOT_DUTY'].map(
                    (_status: Manage.Doctor.AvailabilityType, index: number) => (
                      <li
                        key={index}
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          if (status === _status) {
                            setStatus(null);
                          } else {
                            setStatus(_status);
                          }
                        }}
                      >
                        <Box>
                          <Box
                            className={classNames(
                              styles.DoctorsPageAdmin_Toolbar_DropdownContent_Status,
                              styles[`DoctorsPageAdmin_Toolbar_DropdownContent_Status_${_status}`]
                            )}
                          />
                          <Box display="flex" flex="1 1 auto" width="100%" pl={2}>
                            {getLabelStatus(_status)}
                          </Box>
                        </Box>
                        <CheckIcon
                          className={classNames(
                            styles.DoctorsPageAdmin_Toolbar_DropdownContent_Icon,
                            _status !== status && styles.DoctorsPageAdmin_Toolbar_DropdownContent_Icon_Hidden
                          )}
                        />
                      </li>
                    )
                  )}
                </ul>
                {status !== null && (
                  <Box className={styles.DoctorsPageAdmin_Toolbar_DropdownContent_Reset}>
                    <Button
                      color="default"
                      block
                      size="md"
                      onClick={(event) => {
                        event.preventDefault();
                        setStatus(null);
                      }}
                    >
                      Сбросить фильтр
                    </Button>
                  </Box>
                )}
              </DropdownContent>
            </DropdownMenu>
            <HasRole target={UserRole.ADMIN}>
              <Button color="primary" size="md" onClick={() => setDoctor({})}>
                Добавить доктора
              </Button>
            </HasRole>
          </Box>
        </Box>
        <ContentCard.Main>
          <>
            <Box
              p={0}
              width="100%"
              height="100%"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <InfiniteScroll
                className={styles.DoctorsPageAdmin_InfiniteScroll}
                pageStart={0}
                hasMore={loading ? false : hasMore}
                initialLoad={false}
                threshold={pageSize}
                loadMore={() => getDoctors()}
                loader={null}
              >
                <>
                  {doctors.map((item: Manage.Doctor.Item, index: number) => (
                    <Box key={index} className={styles.DoctorsPageAdmin_Row}>
                      <Box className={styles.DoctorsPageAdmin_Row_Main}>
                        <Box className={styles.DoctorsPageAdmin_Row_Cell}>
                          <Avatar
                            className={styles.DoctorsPageAdmin_ProfileImg}
                            src={decodeAvatarResource(item.avatar, 360, 'MALE')}
                          />
                          <Box className={styles.DoctorsPageAdmin_Profile}>
                            <Box
                              className={classNames(
                                styles.DoctorsPageAdmin_Profile_Item,
                                styles.DoctorsPageAdmin_Profile_Name
                              )}
                            >
                              {[item.firstName, item.middleName, item.lastName].filter((s) => s).join(' ')}
                            </Box>
                            {item.rating && (
                              <Box className={classNames(styles.DoctorsPageAdmin_Profile_Item)}>
                                Рейтинг: <StarRating rating={item.rating} />
                              </Box>
                            )}
                            {item.additionalInfo && (
                              <Box className={classNames(styles.DoctorsPageAdmin_Profile_Item)}>
                                Дополнительно: {item.additionalInfo}
                              </Box>
                            )}
                            {item.category && (
                              <Box className={classNames(styles.DoctorsPageAdmin_Profile_Item)}>
                                Категория: {item.category.name}
                              </Box>
                            )}
                            {item.specialization && (
                              <Box className={classNames(styles.DoctorsPageAdmin_Profile_Item)}>
                                Специализация: {item.specialization.name}
                              </Box>
                            )}
                            {item.tariff && (
                              <Box className={classNames(styles.DoctorsPageAdmin_Profile_Item)}>
                                Стоимость консультации: {item.tariff.price} &#8381;
                              </Box>
                            )}
                            {item.additionalServiceTypes && (
                              <Box className={classNames(styles.DoctorsPageAdmin_Profile_Item)}>
                                Услуги:{' '}
                                {item.additionalServiceTypes.length > 0
                                  ? item.additionalServiceTypes.map((value) => value.name).join(',')
                                  : '-'}
                              </Box>
                            )}
                            {item.availability && (
                              <Box
                                className={classNames(
                                  styles.DoctorsPageAdmin_Profile_Item,
                                  styles.DoctorsPageAdmin_Profile_Status
                                )}
                              >
                                <DropdownMenu
                                  isOpen={isAdmin && openDoctorStatus === item.id}
                                  toggle={() =>
                                    isAdmin && toggleOpenDoctorStatus(openDoctorStatus === item.id ? null : item.id)
                                  }
                                >
                                  <DropdownToggle>
                                    <Box
                                      className={classNames(
                                        styles.DoctorsPageAdmin_Profile_Status,
                                        styles[`DoctorsPageAdmin_Profile_Status_${item.availability.value}`]
                                      )}
                                    >
                                      {getLabelStatus(item.availability.value)}
                                    </Box>
                                  </DropdownToggle>
                                  <DropdownContent className={styles.DoctorsPageAdmin_Profile_Status_DropdownContent}>
                                    <ul>
                                      {['AWAY', 'BUSY', 'DUTY', 'INVITED', 'NOT_DUTY'].map(
                                        (_status: Manage.Doctor.AvailabilityType, index: number) => (
                                          <li
                                            key={index}
                                            onClick={(event) => {
                                              event.preventDefault();
                                              event.stopPropagation();
                                              onSetStatus(_status);
                                            }}
                                          >
                                            <Box>
                                              <Box
                                                className={classNames(
                                                  styles.DoctorsPageAdmin_Profile_Status_DropdownContent_Status,
                                                  styles[
                                                    `DoctorsPageAdmin_Profile_Status_DropdownContent_Status_${_status}`
                                                  ]
                                                )}
                                              />
                                              <Box display="flex" flex="1 1 auto" width="100%" pl={2}>
                                                {getLabelStatus(_status)}
                                              </Box>
                                            </Box>
                                            <CheckIcon
                                              className={classNames(
                                                styles.DoctorsPageAdmin_Profile_Status_DropdownContent_Icon,
                                                _status !== item.availability.value &&
                                                  styles.DoctorsPageAdmin_Profile_Status_DropdownContent_Icon_Hidden
                                              )}
                                            />
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </DropdownContent>
                                </DropdownMenu>
                              </Box>
                            )}
                          </Box>
                        </Box>
                        <Box className={styles.DoctorsPageAdmin_Row_Cell}>
                          <Box display="flex" flexDirection="column">
                            <HasRole target={UserRole.ADMIN}>
                              <Button
                                className={styles.DoctorsPageAdmin_Button}
                                onClick={(event) => {
                                  event.preventDefault();
                                  event.stopPropagation();
                                  history.push(`/doctors/${item.id}/scheduler`);
                                }}
                                size="sm"
                                color="default"
                              >
                                Расписание врача
                              </Button>
                            </HasRole>
                            <Button
                              className={styles.DoctorsPageAdmin_Button}
                              onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                if (doctor && doctor.id === item.id) {
                                  setDoctor(null);
                                } else {
                                  setDoctor(item);
                                }
                              }}
                              size="sm"
                              color="default"
                            >
                              Подробная информация
                            </Button>
                            <HasRole target={UserRole.ADMIN}>
                              <Button
                                className={styles.DoctorsPageAdmin_Button}
                                disabled={!((item as any)?.active === true)}
                                onClick={(event) => {
                                  event.preventDefault();
                                  event.stopPropagation();
                                  setDropDoctor(item);
                                }}
                                size="sm"
                                color="default"
                              >
                                Сброс пароля
                              </Button>
                              <UpdateDoctorStatus
                                doctorId={item.id}
                                onUpdateDoctorStatus={(enabled) =>
                                  updateManageDoctorStatusActive(item.id, enabled === true)
                                }
                                status={(item as any)?.active === true}
                              />
                            </HasRole>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  ))}
                  {(loading || errors) && (
                    <Box width="100%" p={2}>
                      <ContentLoading isLoading={loading} isError={errors} fetchData={() => getDoctors()} />
                    </Box>
                  )}
                </>
              </InfiniteScroll>
            </Box>
          </>
        </ContentCard.Main>
      </ContentCard>
      {doctor !== null && (
        <AdminDoctorView
          doctor={doctor}
          disabled={!isAdmin}
          onClose={() => setDoctor(null)}
          onSaved={() => {
            setDoctor(null);
            getDoctors(true);
          }}
        />
      )}
      {dropDoctor && (
        <DeleteDialog
          item={dropDoctor}
          action={dropPassword}
          onDeleted={() => setDropDoctor(null)}
          onClose={() => setDropDoctor(null)}
          title="Сброс пароля"
          positiveButtonLabel="Сбросить"
          message={`Вы действительно хотите сбросить пароль врачу ${[
            dropDoctor.firstName,
            dropDoctor.middleName,
            dropDoctor.lastName,
          ]
            .filter((s) => s)
            .join(' ')}?`}
        />
      )}
    </>
  );
};
export default connect(
  (state: any) => ({
    ...state.manageDoctors,
  }),
  {
    fetchManageDoctors,
    updateManageDoctorStatus,
    updateManageDoctorStatusActive,
  }
)(DoctorsPageAdmin);
