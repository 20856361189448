import { AxiosResponse } from 'axios';
import { findReportPatient } from '../../api/findReportPatient';

export const MANAGE_REPORT_PATIENT_LOADING = 'MANAGE_REPORT_PATIENT_LOADING';
export const MANAGE_REPORT_PATIENT_SUCCESS = 'MANAGE_REPORT_PATIENT_SUCCESS';
export const MANAGE_REPORT_PATIENT_ERRORS = 'MANAGE_REPORT_PATIENT_ERRORS';

export const setManageReportPatientLoading = (loading: boolean, reset?: boolean) => ({
  type: MANAGE_REPORT_PATIENT_LOADING,
  loading,
  reset,
});

export const setManageReportPatientErrors = (errors: any) => ({ type: MANAGE_REPORT_PATIENT_ERRORS, errors });

export const setManageReportPatient = (items: any, page: number, pageSize: number, totalItems: number) => ({
  type: MANAGE_REPORT_PATIENT_SUCCESS,
  items,
  page,
  pageSize,
  totalItems,
});

export const fetchManageReportPatient = (
  query?: string,
  external?: boolean,
  regFrom?: Date,
  regTo?: Date,
  page?: number,
  pageSize?: number,
  reset?: boolean
): any => {
  return (dispatch) => {
    dispatch(setManageReportPatientLoading(true, reset));
    findReportPatient(query, external, regFrom, regTo, page, pageSize)
      .then((response: AxiosResponse<any>) => {
        dispatch(setManageReportPatientLoading(false));
        if (response.data.code === 'success') {
          dispatch(
            setManageReportPatient(
              response.data.data.items,
              response.data.data.page,
              response.data.data.pageSize,
              response.data.data.totalItems
            )
          );
        } else {
          dispatch(setManageReportPatientErrors(response.data));
        }
      })
      .catch((error: any) => {
        dispatch(setManageReportPatientErrors(error));
        dispatch(setManageReportPatientLoading(false));
      });
  };
};
