import React from "react";
import PatientsSupport from "../PatientSupportPage";

const HomePageSupport: React.FC<any> = () => {
  return (
    <PatientsSupport/>
  )
}

export default HomePageSupport
