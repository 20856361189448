import React from 'react';
import { Link } from 'react-router-dom';

import styles from './Logo.module.sass';

const Logo = (props: any) => (
  <div {...props} className={`${styles.headerLogoWrapper} ${props.className}`}>
    <Link to="/" className={styles.logo} />
  </div>
);

export default Logo;
