import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import ContentCard from '../../../components/ContentCard';
import styles from './ServicePackagesAdmin.module.sass';
import ServicePackagesAdminMfo from './ServicePackagesAdminMfo/ServicePackagesAdminMfo';
import ServicePackagesAdminPatients from './ServicePackagesAdminPatients/ServicePackagesAdminPatients';
import { getTabState, setTabState } from '../../../store/actions';
import useIsAdminRole from '../../../hooks/useIsAdminRole';
import ServicePackagesAdminGroups from './ServicePackagesAdminGroups';

type ServicePackagesAdminTab = 'mfo' | 'patients' | 'package_group';
const ServicePackagesAdmin = () => {
  const isAdminRole = useIsAdminRole();
  const { services } = getTabState(true) || {};
  const [view, setView] = useState<ServicePackagesAdminTab>(
    services ? (isAdminRole ? services : services === 'package_group' ? null : services) || 'patients' : 'patients'
  );
  useEffect(() => {
    if (view && !services) {
      setTabState({ services: view }, true);
    }
  }, [services, view]);
  const renderView = useMemo(() => {
    switch (view) {
      case 'mfo':
        return (
          <>
            <ServicePackagesAdminMfo />
          </>
        );
      case 'patients':
        return (
          <>
            <ServicePackagesAdminPatients />
          </>
        );
      case 'package_group':
        return !isAdminRole ? (
          <></>
        ) : (
          <>
            <ServicePackagesAdminGroups />
          </>
        );
      default:
        return <></>;
    }
  }, [view, isAdminRole]);
  return (
    <>
      <ContentCard>
        <ContentCard.Header className={styles.ServicePackagesAdmin_Header}>
          <div className={`page-header-menu ${styles.ServicePackagesAdmin_Header_Menu}`}>
            <div
              className={classNames('page-header-menu-item', styles.ServicePackagesAdmin_Header_Menu_Item, {
                'page-header-menu-item-active': view === 'mfo',
              })}
              onClick={() => {
                view !== 'mfo' && setView('mfo');
                view !== 'mfo' && setTabState({ services: 'mfo' }, true);
              }}
            >
              Тарифы
            </div>

            <div
              className={classNames('page-header-menu-item', styles.ServicePackagesAdmin_Header_Menu_Item, {
                'page-header-menu-item-active': view === 'patients',
              })}
              onClick={() => {
                view !== 'patients' && setView('patients');
                view !== 'patients' && setTabState({ services: 'patients' }, true);
              }}
            >
              Отправленные тарифы
            </div>

            {isAdminRole && (
              <div
                className={classNames('page-header-menu-item', styles.ServicePackagesAdmin_Header_Menu_Item, {
                  'page-header-menu-item-active': view === 'package_group',
                })}
                onClick={() => {
                  view !== 'package_group' && setView('package_group');
                  view !== 'package_group' && setTabState({ services: 'package_group' }, true);
                }}
              >
                Группы тарифов
              </div>
            )}
          </div>
        </ContentCard.Header>
        <ContentCard.Main className={styles.ServicePackagesAdmin_Content}>{renderView}</ContentCard.Main>
      </ContentCard>
    </>
  );
};

export default ServicePackagesAdmin;
