import React, { useCallback } from 'react';
import { Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { journalItems } from 'constants/jurnal';
import classNames from 'classnames';
import styles from './JournalItemList.module.sass';
import useRootSelector from '../../../hooks/useRootSelector';
import { selectJournalType, selectJournalUserId } from '../store/selectors';

const JournalItemList = (): JSX.Element => {
  const history = useHistory();
  const userId = useRootSelector(selectJournalUserId);
  const type = useRootSelector(selectJournalType);
  const handleClick = useCallback(
    (to: string) => {
      const url = userId ? `/patients/${userId}/journal/${to}` : `/journal/${to}`;
      type !== to && history.push(url);
    },
    [history, type, userId]
  );
  return (
    <>
      <Box className={classNames(styles.root)}>
        {journalItems.map(({ icon, title, url }, index) => (
          <Box
            style={{ backgroundImage: `url(${icon})` }}
            onClick={() => handleClick(url)}
            className={classNames(styles.root_item, type === url && styles.root_item_active)}
            key={index}
          >
            <Box className={styles.root_item_title}>{title}</Box>
          </Box>
        ))}
      </Box>
    </>
  );
};

export default JournalItemList;
