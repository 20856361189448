import React, { useCallback, useEffect, useMemo } from 'react';
import { RootStateOrAny, shallowEqual, useSelector } from 'react-redux';

import { Box } from '@material-ui/core';
import { useHistory, useRouteMatch } from 'react-router-dom';
import classNames from 'classnames';
import styles from './AppointmentDoctor.module.sass';
import Button from '../../components/Forms/Button';
import ContentLoading from '../../components/ContentLoading';
import { AppointmentTimer } from '../../components/AppointmentTimer/AppointmentTimer';
import PageTitle from '../../components/PageTitle';
import { AppointmentDoctorViewType, AppointmentDoctorViewTypeTranslate, AppointmentStatus } from './types';
import {
  onGetAppointment,
  onRestAppointment,
  onSetAppointmentStatus,
  onSetCloseModal,
  onSetViewTab,
} from './store/events';
import AppointmentDoctorChat from './components/AppointmentDoctorChat';
import AppointmentDoctorClose from '../../components/AppointmentDoctorClose';
import { decodeAvatarResource } from '../../utils';
import { CallToolbar } from '../../components/VoximPlantToolbar';
import AppointmentDoctorReport from './components/AppointmentDoctorReport';
import useRootSelector from '../../hooks/useRootSelector';
import { selectAppointmentDoctor } from './store/selectors';
import LinkBack from '../../components/LinkBack';
import { onHideJivoChat, onShowJivoChat } from '../../providers/JivoSiteProvider';

const AppointmentDoctor = () => {
  const { loading, loadingStatus, appointment, view, error, showCloseModal } = useRootSelector(selectAppointmentDoctor);
  const history = useHistory();
  const doctor = useSelector(
    (state: RootStateOrAny) => state?.doctor,
    (d1, d2) => d1?.id === d2?.id
  );
  const activeAppointment = useSelector(
    (state: RootStateOrAny) => state?.doctor?.activeAppointment,
    (p1, p2) => p1?.id === p2?.id && p1?.status?.value === p2?.status?.value
  );
  const routeMatch = useRouteMatch<{ appointmentId: string }>();
  const appointmentId = useMemo(() => routeMatch?.params?.appointmentId, [routeMatch?.params?.appointmentId]);
  const status = useMemo(() => appointment?.status?.value, [appointment?.status?.value]);
  const patient = useMemo(() => appointment?.patient, [appointment?.patient]);
  const handleToggleStatus = useCallback(
    (status: any) => {
      onSetAppointmentStatus({ appointmentId, status });
    },
    [appointmentId]
  );
  const getData = useCallback((): void => {
    appointmentId && onGetAppointment(appointmentId);
  }, [appointmentId]);
  useEffect(() => {
    getData();
  }, [appointmentId, getData]);
  const inAwaits = useMemo(() => {
    return [AppointmentStatus.AWAITS].indexOf(status) > -1;
  }, [status]);
  const isInteractive = useMemo(() => {
    return [AppointmentStatus.AWAITS, AppointmentStatus.IN_PROGRESS].indexOf(status) > -1;
  }, [status]);
  const isCurrentDoctor = useMemo(() => doctor?.id === appointment?.doctor?.id, [doctor, appointment]);
  const handleConfirmClose = useCallback((): void => {
    onSetCloseModal(false);
  }, []);
  const handleConfirmOpen = useCallback((): void => {
    onSetCloseModal(true);
  }, []);
  const title = useMemo(() => {
    if (activeAppointment && appointment && appointment.id === activeAppointment.id) {
      return 'Текущий прием';
    }
    if (appointment) {
      return appointment.type?.name || appointment.serviceType?.name;
    }
    return null;
  }, [activeAppointment, appointment]);
  const isActiveAppointment = useMemo((): boolean => {
    return activeAppointment && appointment && appointment.id === activeAppointment.id;
  }, [activeAppointment, appointment]);
  useEffect(() => {
    isActiveAppointment &&
      appointment?.type?.value === 'PERSONAL' &&
      history.push(`/appointments/${appointment.id}/chat`);
  }, [isActiveAppointment, appointment, history]);
  useEffect(() => {
    view !== AppointmentDoctorViewType.CHAT && handleConfirmClose();
  }, [handleConfirmClose, view]);
  const callTo = useMemo(
    () => ({
      id: patient?.id,
      avatar: patient?.avatar,
      firstName: patient?.firstName,
      lastName: patient?.lastName,
    }),
    [patient]
  );
  const handleChangeView = useCallback((tab: AppointmentDoctorViewType) => {
    onSetViewTab(tab);
  }, []);
  useEffect(
    () => () => {
      onRestAppointment();
    },
    []
  );
  useEffect(() => {
    onHideJivoChat();
    return () => {
      onShowJivoChat();
    };
  }, []);
  return (
    <>
      <ContentLoading isLoading={loading} isError={error} fetchData={getData}>
        <Box className={styles.root_title}>
          <Box>
            <LinkBack />
          </Box>
          <PageTitle className="p-0 m-0" center>
            {title}
          </PageTitle>
          <Box>{isInteractive && <AppointmentTimer appointment={appointment} status={status} />}</Box>
        </Box>
        {patient && (
          <div className={styles.root_profile}>
            <div className={styles.root_profile_about}>
              <div
                className={styles.root_profile_avatar}
                style={{
                  backgroundImage: `url(${decodeAvatarResource(patient?.avatar || null, 360)})`,
                }}
              />
              <div className={styles.root_profile_name}>
                <div className={styles.root_profile_label}>Пациент</div>
                <div className={styles.root_profile_value}>
                  {[patient?.lastName, patient?.firstName, patient?.middleName].filter((v) => v).join(' ')}
                </div>
              </div>
            </div>
            <Box className="d-flex align-items-center">
              {isInteractive && (
                <>
                  <Button
                    className="mr-2"
                    type="button"
                    size="sm"
                    disabled={!isCurrentDoctor}
                    color="primary"
                    onClick={() => (inAwaits ? handleToggleStatus(AppointmentStatus.IN_PROGRESS) : handleConfirmOpen())}
                  >
                    {inAwaits ? 'В работу' : 'Завершить'}
                  </Button>
                  <CallToolbar appointment={appointment} showVideo showPhone callTo={callTo} />
                </>
              )}
            </Box>
          </div>
        )}
        <Box className={styles.root_tabs}>
          {Object.values(AppointmentDoctorViewType).map((tab) => (
            <Box
              key={tab}
              onClick={() => handleChangeView(tab)}
              className={classNames(styles.root_tabs_tab, view === tab && styles.root_tabs_tab_active)}
            >
              {AppointmentDoctorViewTypeTranslate?.[tab]}
            </Box>
          ))}
        </Box>
        <Box display={view !== AppointmentDoctorViewType.CHAT ? 'none' : 'block'}>
          <AppointmentDoctorChat />
        </Box>
        <Box display={view !== AppointmentDoctorViewType.REPORT ? 'none' : 'block'}>
          <AppointmentDoctorReport />
        </Box>
        {showCloseModal && (
          <AppointmentDoctorClose onClose={handleConfirmClose} loading={loadingStatus} onApply={handleToggleStatus} />
        )}
      </ContentLoading>
    </>
  );
};

export default AppointmentDoctor;
