import React, { ReactElement, useCallback, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { useStore } from 'effector-react';
import ContentLoading from 'components/ContentLoading';
import { format } from 'date-fns';
import styles from './styles.module.sass';
import AgentMetricsListHeader from '../AgentMetricsListHeader';
import store from '../../store';
import { getAgentDashboardDataFx } from '../../store/effects';
import AgentMetricsItem from '../AgentMetricsItem';

const AgentMetricsList = (): ReactElement => {
  const { loading, error, fromDate, toDate, metrics } = useStore(store);
  const getData = useCallback(() => {
    getAgentDashboardDataFx.call(getAgentDashboardDataFx, {
      fromDate: format(fromDate, 'yyyy-MM-dd'),
      toDate: format(toDate, 'yyyy-MM-dd'),
    });
  }, [fromDate, toDate]);
  useEffect(() => {
    getData();
  }, [getData]);
  return (
    <>
      <Box className={styles.root}>
        <AgentMetricsListHeader />
        <Box p={loading || error ? 2 : 0} width="100%">
          <ContentLoading isLoading={loading} isError={!!error} fetchData={getData}>
            {(metrics?.agentDashboardData || []).map((item, index) => (
              <AgentMetricsItem key={index} item={item} />
            ))}
          </ContentLoading>
        </Box>
      </Box>
    </>
  );
};

export default AgentMetricsList;
