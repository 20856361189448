import { getAppointment } from 'api';
import { getAppointmentStatus } from '../../api/getAppointmentStatus';

export const APPOINTMENT_VIEW_LOADING = 'APPOINTMENT_VIEW_LOADING';
export const APPOINTMENT_VIEW_ERROR = 'APPOINTMENT_VIEW_ERROR';
export const APPOINTMENT_VIEW_SUCCESS = 'APPOINTMENT_VIEW_SUCCESS';

export const APPOINTMENT_VIEW_STATUS_UPDATE_SUCCESS = 'APPOINTMENT_VIEW_STATUS_UPDATE_SUCCESS';

export const appointmentViewLoading = (isLoading: boolean) => ({ type: APPOINTMENT_VIEW_LOADING, isLoading });

export const appointmentViewSuccess = (result: any) => {
  let appointment = null;

  if (result && result.data) {
    appointment = { ...result.data };
  }

  return { type: APPOINTMENT_VIEW_SUCCESS, appointment };
};

export const appointmentViewStatusUpdateSuccess = (status: any) => {
  return { type: APPOINTMENT_VIEW_STATUS_UPDATE_SUCCESS, status };
};

export const appointmentViewError = (isError: boolean) => ({ type: APPOINTMENT_VIEW_ERROR, isError });

export const fetchAppointmentView = (appointmentId: string) => {
  return (dispatch) => {
    dispatch(appointmentViewLoading(true));
    dispatch(appointmentViewError(false));

    getAppointment(appointmentId)
      .then((response) => {
        dispatch(appointmentViewLoading(false));
        dispatch(appointmentViewSuccess(response.data));
      })
      .catch((error) => {
        dispatch(appointmentViewLoading(false));
        dispatch(appointmentViewError(true));
      });
  };
};

export const updateAppointmentViewStatus = (appointmentId) => {
  return (dispatch) => {
    getAppointmentStatus(appointmentId)
      .then((response) => {
        if (response.data.code === 'success') {
          dispatch(appointmentViewStatusUpdateSuccess(response.data.data));
        }
      })
      .catch(() => {});
  };
};
