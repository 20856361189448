import axios from 'utils/axios';
import Manage from "../types/manage";
import {AxiosResponse} from "axios";
import {ResponseData} from "../types";

class manageServicePackageMarketingInfoApi {

  static getMarketingInfo = (servicePackageId: string): Promise<AxiosResponse<ResponseData<Manage.ServicePackages.MarketingInfo>>> => {
    return axios.get(`/api/v1/servicepackages/mfo/${servicePackageId}/marketing`)
  }

  static updateMarketingInfo = (servicePackageId: string, request: Manage.ServicePackages.MarketingInfo):
    Promise<AxiosResponse<ResponseData<void>>> => {
    return axios.post(`/api/v1/servicepackages/mfo/${servicePackageId}/marketing`, request)
  }

}

export default manageServicePackageMarketingInfoApi
