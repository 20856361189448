import React, { memo, ReactElement } from 'react';
import { Box } from '@material-ui/core';
import { format } from 'utils/date';
import styles from './styles.module.sass';
import { AgentDashboardData } from '../../types';

interface PropsInterface {
  item: AgentDashboardData;
}
const AgentMetricsItem = memo((props: PropsInterface): ReactElement => {
  const { item } = props;
  return (
    <>
      <Box className={styles.root}>
        <Box className={styles.root_item}>{format(new Date(item?.date), 'dd.MM.yyyy')}</Box>
        <Box className={styles.root_item}>{item?.name}</Box>
        <Box className={styles.root_item}>{item?.count} шт.</Box>
        <Box className={styles.root_item}>{item?.price} ₽</Box>
        <Box className={styles.root_item}>{item?.sum} ₽</Box>
      </Box>
    </>
  );
});

export default AgentMetricsItem;
