import React, { useEffect } from 'react';
import classNames from 'classnames';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import './DropdownMenu.sass';

interface DropdownMenuProps {
  isOpen: boolean;
  toggle: (any?) => void;
  children?: React.ReactNode;
  [propName: string]: any;
}

const DropdownMenu = (props: DropdownMenuProps) => {
  const { isOpen, toggle, className, ...attributes } = props;

  return (
    <ClickAwayListener onClickAway={(e) => isOpen && toggle(e)}>
      <div
        {...attributes}
        className={classNames(`dropdownMenu ${className || ''}`, {
          dropdownMenuOpen: isOpen,
        })}
        onClick={toggle}
      />
    </ClickAwayListener>
  );
};

export default DropdownMenu;
