import React from 'react';
import { Box } from '@material-ui/core';
import classNames from 'classnames';
import useRootSelector from 'hooks/useRootSelector';
import { selectJournalPatientName } from 'views/JournalPage/store/selectors';
import PageTitle from 'components/PageTitle';
import useIsDoctorRole from 'hooks/useIsDoctorRole';
import LinkBack from 'components/LinkBack';
import styles from './JournalTitle.module.sass';

const JournalTitle = (): JSX.Element => {
  const patientName = useRootSelector(selectJournalPatientName);
  const isDoctor = useIsDoctorRole();
  return (
    <>
      <PageTitle className={classNames(styles.root, isDoctor && styles.root_patient_wrapper)}>
        {isDoctor && (
          <Box className={styles.root_header_left}>
            <LinkBack link="/patients" />
          </Box>
        )}
        <Box className={classNames(isDoctor && styles.root_patient)}>
          <Box>Мониторинг здоровья</Box>
          {isDoctor && (
            <Box className={styles.root_patientName}>
              <Box mr={2}>:</Box>
              <Box>{patientName || 'Загрузка...'}</Box>
            </Box>
          )}
        </Box>
      </PageTitle>
    </>
  );
};

export default JournalTitle;
