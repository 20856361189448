import axios from 'utils/axios';

export const getDailyConclusionStrategiesRules = (params?: {
  id: number;
  active?: boolean;
  page?: number;
  pageSize?: number;
}) => {
  const { id } = params;
  let { active, page, pageSize } = params;
  page = page || 0;
  pageSize = pageSize || 50;
  active = active || null;
  return axios.get(`/api/v1/dailyconclusionstrategies/${id}/rules`, {
    params: {
      active,
      page,
      pageSize,
      deleted: false,
    },
  });
};
