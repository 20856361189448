import React, { PropsWithChildren } from 'react';

import { Box } from '@material-ui/core';
import styles from './style.module.sass';

const FormInvalidMessage = (props: PropsWithChildren<any>) => {
  const { children } = props;
  return (
    <Box className={styles.root} style={{ visibility: !children ? 'hidden' : 'visible' }}>
      {children}
      {!children && <Box>&nbsp;</Box>}
    </Box>
  );
};

export default FormInvalidMessage;
