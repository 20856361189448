import { StateInterface } from 'store';
import { AppointmentDoctorStateInterface } from './index';

export const selectAppointmentDoctor = (state: StateInterface): AppointmentDoctorStateInterface =>
  state?.appointmentDoctor;

export const selectAppointmentDoctorAppointment = (state: StateInterface) =>
  selectAppointmentDoctor(state)?.appointment;
export const selectAppointmentDoctorLoading = (state: StateInterface) => selectAppointmentDoctor(state)?.loading;
export const selectAppointmentDoctorError = (state: StateInterface) => selectAppointmentDoctor(state)?.error;
export const selectAppointmentDoctorLoadingView = (state: StateInterface) => selectAppointmentDoctor(state)?.view;
export const selectAppointmentDoctorLoadingStatus = (state: StateInterface) =>
  selectAppointmentDoctor(state)?.loadingStatus;
export const selectAppointmentDoctorErrorStatus = (state: StateInterface) =>
  selectAppointmentDoctor(state)?.errorStatus;

export const selectAppointmentDoctorLoadingReport = (state: StateInterface) =>
  selectAppointmentDoctor(state)?.loadingReport;
export const selectAppointmentDoctorErrorReport = (state: StateInterface) =>
  selectAppointmentDoctor(state)?.errorReport;

export const selectAppointmentDoctorShowCloseModal = (state: StateInterface) =>
  selectAppointmentDoctor(state)?.showCloseModal;

export const selectAppointmentDoctorDictionaries = (state: StateInterface) =>
  selectAppointmentDoctor(state)?.dictionaries;
