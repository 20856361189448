import {
  authEmailLogin,
  authEsiaLogin,
  authPhoneCodes,
  authPhoneToken,
  authProfileMeta,
  authRegistration,
  logout,
} from 'api';
import { toast } from 'react-toastify';
import axios from 'utils/axios';
import { convertToUserData } from 'utils';
import { AxiosResponse } from 'axios';
import {
  AuthData,
  AuthInfo,
  ResponseCode,
  ResponseData,
  ResponseDataInterface,
  UserMeta,
  UserRole,
  UserStatus,
} from '../../types';
import { authCodePasswordToken } from '../../api/auth/authCodePasswordToken';
import { checkEmailRegistrationCode } from '../../api/auth/checkEmailRegistrationCode';
import Doctor from '../../types/doctor';
import { setDoctor } from './doctor.actions';
import { getDoctorsProfileMetaInfo } from '../../api/getProfileMetaInfo';
import { getMetaAdmin } from '../../api/auth/getMetaAdmin';
import { getMetaAgent } from '../../api/auth/getMetaAgent';
import { onSetJournalCurrent } from '../../views/JournalPage/store/events';

export const USER_LOGIN = 'USER_LOGIN';
export const LOGIN_LOADING = 'LOGIN_LOADING';
export const INVITE_LOADING = 'INVITE_LOADING';
export const USER_LOGOUT = 'USER_LOGOUT';
export const SET_AUTH_CHECKING = 'SET_AUTH_CHECKING';
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_LOGIN_SCREEN = 'SET_LOGIN_SCREEN';
export const SET_PHONE_LOGIN_FORM = 'SET_PHONE_LOGIN_FORM';
export const SET_PHONE_LOGIN_ERRORS = 'SET_PHONE_LOGIN_ERRORS';
export const SET_EMAIL_LOGIN_FORM = 'SET_EMAIL_LOGIN_FORM';
export const SET_EMAIL_LOGIN_ERRORS = 'SET_EMAIL_LOGIN_ERRORS';
export const SET_CODE_CONFIRM_FORM = 'SET_CODE_CONFIRM_FORM';
export const SET_CODE_CONFIRM_ERRORS = 'SET_CODE_CONFIRM_ERRORS';

export const setPhoneLoginForm = (form: any) => ({ type: SET_PHONE_LOGIN_FORM, form });
export const setPhoneLoginErrors = (errors: []) => ({ type: SET_PHONE_LOGIN_ERRORS, errors });

export const setEmailLoginForm = (form: any) => ({ type: SET_EMAIL_LOGIN_FORM, form });
export const setEmailLoginErrors = (errors: []) => ({ type: SET_EMAIL_LOGIN_ERRORS, errors });

export const setCodeConfirmForm = (form: any) => ({ type: SET_CODE_CONFIRM_FORM, form });
export const setCodeConfirmFormErrors = (errors: []) => ({ type: SET_CODE_CONFIRM_ERRORS, errors });

export const setAuthChecking = (isAuthChecking: boolean) => ({ type: SET_AUTH_CHECKING, isAuthChecking });
export const userLogin = (token: string, role: string, permissions?: string[]) => ({
  type: USER_LOGIN,
  token,
  role,
  permissions,
});
export const loginLoading = (isLoading: boolean) => ({ type: LOGIN_LOADING, isLoading });
export const inviteLoading = (isLoading: boolean) => ({ type: INVITE_LOADING, isLoading });
export const setUserData = (userData: UserMeta) => ({ type: SET_USER_DATA, userData });
export const setLoginScreen = (screen: 'LOGIN' | 'PHONE_CONFIRM' | 'REGISTER' | 'ADMIN' | 'PHONE_CONFIRM_ESIA') => ({
  type: SET_LOGIN_SCREEN,
  screen,
});

export const userLogout = () => {
  return (dispatch) => {
    return logout()
      .catch()
      .finally(() => {
        axios.defaults.headers.common.Authorization = null;
        localStorage.removeItem('authToken');
        localStorage.removeItem('role');
        localStorage.removeItem('permissions');
        dispatch({ type: USER_LOGOUT });
      });
  };
};

export const authCheck = () => {
  return (dispatch) => {
    const token = localStorage.getItem('authToken');
    const role: UserRole = localStorage.getItem('role') as UserRole;
    const permissions: string[] = (JSON.parse(localStorage.getItem('permissions') || null) as string[]) || [];
    const isDoctor = role === UserRole.DOCTOR;
    const isAdmin = role === UserRole.ADMIN;
    const isAgent = role === UserRole.AGENT;
    const isSupport = role === UserRole.SUPPORT;

    if (token) {
      dispatch(setAuthChecking(true));
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      getMetaInfo(role, token)
        .then((response: AxiosResponse<ResponseData>) => {
          dispatch(setAuthChecking(false));

          axios.defaults.headers.common.Authorization = `Bearer ${token}`;

          if (response.data.data) {
            dispatch(
              isDoctor
                ? setDoctor(response.data.data)
                : setUserData(
                    isAdmin || isAgent || isSupport ? response.data.data : convertToUserData(response.data.data)
                  )
            );
            !(isAdmin || isAgent || isSupport) && onSetJournalCurrent(response?.data?.data?.lastObservations || []);
          }

          dispatch(userLogin(token, role, permissions));
        })
        .catch(() => {
          dispatch(setAuthChecking(false));
        });
    } else {
      dispatch(setAuthChecking(false));
    }
  };
};

export const authPhoneLogin = (phone: string, gRecaptcha: string) => {
  return (dispatch) => {
    dispatch(loginLoading(true));

    authPhoneCodes(phone, gRecaptcha)
      .then(() => {
        dispatch(loginLoading(false));
        dispatch(setLoginScreen('PHONE_CONFIRM'));
      })
      .catch(() => {
        toast.error('Ошибка при запросе');
        dispatch(loginLoading(false));
      });
  };
};
export const checkRegistrationCode = (code: string, history: any) => {
  return (dispatch) => {
    dispatch(inviteLoading(true));
    checkEmailRegistrationCode(code)
      .then(() => {
        dispatch(inviteLoading(false));
      })
      .catch(() => {
        dispatch(inviteLoading(false));
        history.push('/');
        toast.error('Вы уже приняли приглашение');
      });
  };
};
export const authInvitePasswordLogin = (code: string, password: string, history: any, location?: any) => {
  return (dispatch) => {
    dispatch(loginLoading(true));

    authCodePasswordToken(code, password)
      .then((codeResponse) => {
        if (!codeResponse.data.data.requireRegister) {
          const token = codeResponse.data.data.accessToken;
          const role: UserRole = codeResponse.data.data.authInfo.role;
          const permissions: string[] = codeResponse.data.data.authInfo.permissions || [];

          localStorage.setItem('authToken', token);
          localStorage.setItem('role', role);
          localStorage.setItem('permissions', JSON.stringify(permissions));
          axios.defaults.headers.common.Authorization = `Bearer ${token}`;
          const isDoctor = role === UserRole.DOCTOR;
          (isDoctor ? getDoctorsProfileMetaInfo() : authProfileMeta(token))
            .then((profileResponse: AxiosResponse<ResponseData<any | Doctor.ProfileMetaInfo.Response>>) => {
              dispatch(loginLoading(false));
              if (profileResponse.data.data) {
                dispatch(
                  isDoctor
                    ? setDoctor(profileResponse.data.data)
                    : setUserData(convertToUserData(profileResponse.data.data))
                );
              }

              dispatch(userLogin(token, role, permissions));

              // const nextPathname = location && location.state ? location.state.nextPathname : null;

              history.push('/');
            })
            .catch(() => {
              dispatch(loginLoading(false));
              toast.error('Ошбика завершения регистрации. Попробуйте позже или обратитесь к администратору сервиса');
            });
        } else {
          dispatch(loginLoading(false));
          toast.error('Ошбика завершения регистрации. Попробуйте позже или обратитесь к администратору сервиса');
        }
      })
      .catch((error) => {
        dispatch(loginLoading(false));

        let errorMsg = '';

        if (error?.response?.data?.statusCode === 401) {
          errorMsg = 'Ошбика завершения регистрации. Попробуйте позже или обратитесь к администратору сервиса';
        } else {
          errorMsg = 'Ошбика завершения регистрации. Попробуйте позже или обратитесь к администратору сервиса';
        }

        toast.error(errorMsg);
      });
  };
};

export const authESIALogin = (code: string, history?: any) => {
  return (dispatch) => {
    dispatch(loginLoading(true));

    authEsiaLogin(code)
      .then((data: AxiosResponse<ResponseDataInterface<AuthData<AuthInfo>>>) => {
        if (data?.data?.code === ResponseCode.SUCCESS) {
          if (data?.data?.data?.authInfo?.status === UserStatus.ACTIVE) {
            const token = data?.data?.data.accessToken;
            const role: UserRole = data?.data?.data?.authInfo?.role;
            const permissions: string[] = data?.data?.data?.authInfo?.permissions || [];

            axios.defaults.headers.common.Authorization = `Bearer ${token}`;

            localStorage.setItem('authToken', token);
            localStorage.setItem('role', role);
            localStorage.setItem('permissions', JSON.stringify(permissions));

            return getMetaInfo(role, token)
              .then((profileResponse: AxiosResponse) => {
                dispatch(loginLoading(false));
                if (profileResponse.data.data) {
                  dispatch(setUserData(profileResponse.data.data));
                }
                dispatch(userLogin(token, role, permissions));
                history.push('/');
              })
              .catch(() => {
                dispatch(loginLoading(false));
                toast.error('Ошибка входа');
              });
          }
          dispatch(setPhoneLoginForm({ phone: data?.data?.data?.authInfo?.displayName }));
          dispatch(loginLoading(false));
          dispatch(setLoginScreen('PHONE_CONFIRM_ESIA'));
          history.push('/login');
        } else {
          dispatch(loginLoading(false));
          toast.error('Пользователя с таким эл. адресом или паролем не найден');
        }
      })
      .catch((error) => {
        dispatch(loginLoading(false));

        const errorMsg = error?.response?.data?.statusCode === 40 ? 'Неверные данные' : 'Ошибка при запросе';
        history.push('/');
        toast.error(errorMsg);
      });
  };
};

export const authEmailPasswordLoginAdmin = (email: string, password: string, history?: any, location?: any) => {
  return (dispatch) => {
    dispatch(loginLoading(true));

    authEmailLogin(email, password)
      .then((codeResponse: AxiosResponse<ResponseData>) => {
        if (!codeResponse.data.data.requireRegister) {
          const token = codeResponse.data.data.accessToken;
          const role: UserRole = codeResponse.data.data.authInfo.role;
          const permissions: string[] = codeResponse.data.data.authInfo.permissions || [];

          axios.defaults.headers.common.Authorization = `Bearer ${token}`;

          localStorage.setItem('authToken', token);
          localStorage.setItem('role', role);
          localStorage.setItem('permissions', JSON.stringify(permissions));

          return getMetaInfo(role, token)
            .then((profileResponse: AxiosResponse<any>) => {
              dispatch(loginLoading(false));
              if (profileResponse.data.data) {
                dispatch(setUserData(profileResponse.data.data));
              }

              dispatch(userLogin(token, role, permissions));

              // const nextPathname = location && location.state ? location.state.nextPathname : null;
              history.push('/admin');
            })
            .catch(() => {
              dispatch(loginLoading(false));
              toast.error('Ошибка входа');
            });
        }
        dispatch(loginLoading(false));
        toast.error('Пользователя с таким эл. адресом или паролем не найден');
      })
      .catch((error) => {
        dispatch(loginLoading(false));

        let errorMsg = '';

        if (error?.response?.data?.statusCode === 401) {
          errorMsg = 'Неверные данные';
        } else {
          errorMsg = 'Ошибка при запросе';
        }

        toast.error(errorMsg);
      });
  };
};

export const authEmailPasswordLogin = (email: string, password: string, history?: any) => {
  return (dispatch) => {
    dispatch(loginLoading(true));

    authEmailLogin(email, password)
      .then((codeResponse: AxiosResponse<ResponseData>) => {
        if (!codeResponse.data.data.requireRegister) {
          const token = codeResponse.data.data.accessToken;
          const role: UserRole = codeResponse.data.data.authInfo.role;
          const permissions: string[] = codeResponse.data.data.authInfo.permissions || [];

          axios.defaults.headers.common.Authorization = `Bearer ${token}`;

          localStorage.setItem('authToken', token);
          localStorage.setItem('role', role);
          localStorage.setItem('permissions', JSON.stringify(permissions));
          const isAgent = role === UserRole.AGENT;
          (isAgent ? getMetaAgent(token) : getDoctorsProfileMetaInfo())
            .then((profileResponse: AxiosResponse<ResponseData<Doctor.ProfileMetaInfo.Data>>) => {
              dispatch(loginLoading(false));
              if (profileResponse.data.data) {
                dispatch((isAgent ? setUserData : setDoctor).call(null, profileResponse.data.data));
              }

              dispatch(userLogin(token, role, permissions));

              history.push('/');
            })
            .catch(() => {
              dispatch(loginLoading(false));
              toast.error('Ошибка входа');
            });
        } else {
          dispatch(loginLoading(false));
          toast.error('Пользователя с таким эл. адресом или паролем не найдено');
        }
      })
      .catch((error) => {
        dispatch(loginLoading(false));

        let errorMsg = '';

        if (error?.response?.data?.statusCode === 401) {
          errorMsg = 'Неверные данные';
        } else {
          errorMsg = 'Ошибка при запросе';
        }

        toast.error(errorMsg);
      });
  };
};

export const authPhoneCodeConfirm = (phone: string, code: string, history: any, location: any) => {
  return (dispatch, getState) => {
    const loginScreen = getState()?.auth?.loginScreen;
    dispatch(loginLoading(true));

    authPhoneToken(phone, code, loginScreen === 'PHONE_CONFIRM_ESIA')
      .then((codeResponse) => {
        if (!codeResponse.data.data.requireRegister) {
          const token = codeResponse.data.data.accessToken;
          const role: UserRole = codeResponse.data.data.authInfo.role;
          const permissions: string[] = codeResponse.data.data.authInfo.permissions || [];

          axios.defaults.headers.common.Authorization = `Bearer ${token}`;

          localStorage.setItem('authToken', token);
          localStorage.setItem('role', role);
          localStorage.setItem('permissions', JSON.stringify(permissions));

          authProfileMeta(token)
            .then((profileResponse) => {
              dispatch(loginLoading(false));

              if (profileResponse.data.data) {
                dispatch(setUserData(convertToUserData(profileResponse.data.data)));
              }

              dispatch(userLogin(token, role, permissions));

              // const nextPathname = location.state ? location.state.nextPathname : null;

              history.push('/');
            })
            .catch(() => {
              dispatch(loginLoading(false));
              toast.error('Ошибка входа');
            });
        } else {
          dispatch(loginLoading(false));
          dispatch(setLoginScreen('REGISTER'));
        }
      })
      .catch((error) => {
        dispatch(loginLoading(false));

        let errorMsg = '';

        if (error.response.data.statusCode === 401) {
          errorMsg = 'Неверные данные';
        } else {
          errorMsg = 'Ошибка при запросе';
        }

        toast.error(errorMsg);
      });
  };
};

export const authRegister = (
  phone: string,
  firstName: string,
  middleName: string,
  lastName: string,
  birthday: string,
  code: string,
  location: any,
  history: any,
  sex?: string
) => {
  return (dispatch) => {
    dispatch(loginLoading(true));

    authRegistration(phone, firstName, middleName, lastName, birthday, code, sex)
      .then((response) => {
        const token = response.data.data.accessToken;
        const role: UserRole = response.data.data.authInfo.role;
        const permissions: string[] = response.data.data.authInfo.permissions || [];
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;

        localStorage.setItem('authToken', token);
        localStorage.setItem('role', role);
        localStorage.setItem('permissions', JSON.stringify(permissions));

        authProfileMeta(token)
          .then((profileResponse) => {
            dispatch(loginLoading(false));

            if (profileResponse.data.data) {
              dispatch(setUserData(convertToUserData(profileResponse.data.data)));
            }

            dispatch(userLogin(token, role, permissions));

            history.push('/');
          })
          .catch(() => {
            dispatch(loginLoading(false));
            toast.error('Ошибка входа');
          });
      })
      .catch(() => {
        dispatch(loginLoading(false));

        toast.error('Ошибка при регистрации');
      });
  };
};

const getMetaInfo = (role: UserRole, token?: string): Promise<AxiosResponse> => {
  switch (role) {
    case UserRole.ADMIN:
      return getMetaAdmin(token);
    case UserRole.AGENT:
      return getMetaAgent(token);
    case UserRole.DOCTOR:
    case UserRole.SUPPORT:
      return getDoctorsProfileMetaInfo();
    default:
      return authProfileMeta(token);
  }
};
