import axios from 'utils/axios';

export const findPatientServicePackageReport = (
  query?: string,
  groupId?: string,
  statuses?: string[],
  purchasedFrom?: Date,
  purchasedTo?: Date,
  categoryBenefitsId?: string,
  signOnline?: any,
  page?: number,
  pageSize?: number
) => {
  page = page || 0;
  pageSize = pageSize || 10;
  query = query || null;
  groupId = groupId || null;
  statuses = statuses || [];
  categoryBenefitsId = categoryBenefitsId || null;
  return axios.get(`/api/v1/admin/reports/patientservicepackage`, {
    params: {
      groupId,
      statuses: statuses.length ? statuses.join(',') : null,
      purchasedFrom,
      purchasedTo,
      query,
      categoryBenefitsId,
      signOnline,
      page,
      pageSize,
    },
  });
};
