import React from 'react';
import { Box } from '@material-ui/core';
import styles from './AdminStoriesListHeader.module.sass';

const AdminStoriesListHeader = (): JSX.Element => {
  return (
    <>
      <Box className={styles.root}>
        <Box className={styles.root_cell} width="5%" />
        <Box className={styles.root_cell} width="10%">
          Фото
        </Box>
        <Box className={styles.root_cell} width="35%">
          Заголовок
        </Box>
        <Box className={styles.root_cell} width="25%">
          Дата
        </Box>
        <Box className={styles.root_cell} width="15%">
          Видимость
        </Box>
        <Box className={styles.root_cell} width="10%" />
      </Box>
    </>
  );
};

export default AdminStoriesListHeader;
