import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { deepObjectMerge } from 'utils/helpers';
import reducers from './reducers';
import middlewares from './middlewares';
import { persistedState, saveState } from './persisted.store';
import { redirectState, saveRedirect } from './redirect.store';

export default function configureStore() {
  const appliedMiddlewares = applyMiddleware(thunk, ...middlewares);
  const createStoreWithMiddleware = compose(
    appliedMiddlewares,
    typeof window !== 'undefined' && (window as any).__REDUX_DEVTOOLS_EXTENSION__
      ? (window as any).__REDUX_DEVTOOLS_EXTENSION__()
      : (f) => f
    // @ts-ignore
  )(createStore);

  const store = createStoreWithMiddleware(
    reducers,
    deepObjectMerge(deepObjectMerge(createStore(reducers).getState(), persistedState), redirectState) // second argument overrides the initial state
  );

  // add a listener that will be invoked on any state change
  store.subscribe(() => {
    saveState({
      storage: store.getState().storage,
    });
    saveRedirect(store.getState().redirect || '');
  });

  return store;
}
