import { createStore, forward } from 'effector';
import { subYears } from 'date-fns';
import { omit } from 'lodash';
import {
  onAgentChangeForm,
  onAgentReplace,
  onAgentReset,
  onAgentResetCheck,
  onAgentResetForm,
  onSetAgentErrorsForm,
  onSetAgentSaleType,
} from './events';
import { AgentSaleType, AgentSaleUserPsp } from '../types';
import { Fields } from '../../../../constants/fields';
import { FormError, validateByFieldValue } from '../../../../components/Forms/FormValidator/FormValidator';
import { getAgentByPhoneFx, getCodeFx, pspFx, userPspFx } from './effects';
import { getValidator } from '../constants/validators';

export interface AgentSaleStateInterface {
  type: AgentSaleType;
  form: AgentSaleUserPsp;
  errors: Array<FormError>;
  loadingPhone: boolean;
  loadingCode: boolean;
  isCheckPhone: boolean;
  isCheckCode: boolean;
  loading: boolean;
}
const defaultPspForm = {
  [Fields.Phone]: '',
  [Fields.ConfirmCode]: '',
  [Fields.InternalTariffId]: '',
};
const defaultUserPspForm = {
  [Fields.FirstName]: '',
  [Fields.LastName]: '',
  [Fields.MiddleName]: '',
  [Fields.Email]: '',
  [Fields.Birthday]: subYears(new Date(), 19),
  [Fields.Sex]: 'MALE',
  ...defaultPspForm,
};
const defaultCheck = {
  loadingPhone: false,
  loadingCode: false,
  isCheckPhone: false,
  isCheckCode: false,
};
const defaultState: AgentSaleStateInterface = {
  type: AgentSaleType.NEW,
  loading: false,
  errors: [],
  ...defaultCheck,
  form: { ...defaultUserPspForm },
};

const $store = createStore(defaultState);
$store.on(onAgentReplace, (_, __) => ({
  ..._,
  type: AgentSaleType.NEW,
  form: { ...defaultUserPspForm, ...(__ || {}) },
  errors: [],
  loading: false,
  ...defaultCheck,
}));
$store.on(userPspFx.pending, (_, loading) => ({ ..._, loading }));
$store.on(pspFx.pending, (_, loading) => ({ ..._, loading }));
$store.on(getAgentByPhoneFx.pending, (_, loadingPhone) => ({ ..._, loadingPhone }));
$store.on(getCodeFx.pending, (_, loadingCode) => ({ ..._, loadingCode }));
$store.on(getAgentByPhoneFx.doneData, (_, __) => ({ ..._, isCheckPhone: !!__ }));
$store.on(getCodeFx.doneData, (_, __) => ({ ..._, isCheckCode: !!__ }));
$store.on(onAgentResetCheck, (_) => ({ ..._, ...defaultCheck }));
$store.on(onAgentResetForm, (_, __) => ({ ..._, form: { ...defaultUserPspForm, ...(__ || {}) }, errors: [] }));
$store.on(onAgentChangeForm, (_, __) => {
  const errors = validateByFieldValue(__, getValidator(_?.type), _?.errors);
  return { ..._, form: { ..._.form, ...__ }, errors };
});
$store.on(onSetAgentErrorsForm, (_, errors) => ({ ..._, errors }));
$store.on(onAgentReset, () => ({ ...defaultState }));
$store.on(onSetAgentSaleType, (_, type) => ({
  ..._,
  type,
  form: { ...(type === AgentSaleType.NEW ? defaultUserPspForm : defaultPspForm) },
  errors: [],
  ...defaultCheck,
}));
export default $store;
