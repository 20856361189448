import axios from 'utils/axios';
import {CallCredentials} from "../types/call/CallCredentials";

export const getMyCredentials = async (forceUpdate?: boolean): Promise<CallCredentials> => {
  const result = await axios.get(`/api/v1/videochat/mycredentials`,  {
    params: {
      forceCreate: forceUpdate
    }
  })
  return result.data.data as CallCredentials
}
