import axios from 'utils/axios';

type SuccessResponse = {
  data: {
    uploadUrl: string,
    id: string
  },
  code: string
}

export default async (file: File, name?: string) => {
  let data;

  try {
    ({ data } = await axios.post<SuccessResponse>('/api/v1/resources', {
      contentType: file.type,
      name: name || file.name,
      secured: true
    }));
  } catch (error) {
    throw error.response.data;
  }

  const response = data.data

  return {
    contentType: file.type,
    id: response.id as string,
    uploadUrl: response.uploadUrl as string,
  }
};
