import React, { useState } from 'react';
import { validateFieldOnChange, getFieldErrors as getFieldErrorsUtil, hasFieldError } from 'utils';
import { activatePromo } from 'api';
import { toast } from 'react-toastify';

// Components
import InputField from 'components/Forms/InputField';
import FormValidator from 'components/Forms/FormValidator';
import Button from 'components/Forms/Button';

import styles from '../ProfilePage.module.sass';

const PromoView = () => {
  const [profilePromoForm, setProfilePromoForm] = useState({
    promo: '',
  });

  const [profilePromoFormErrors, setProfilePromoFormErrors] = useState([]);

  const [promoActivateLoading, setPromoActivateLoading] = useState(false);

  const validateOnChange = (name: string, value: any, event, element?) => {
    validateFieldOnChange(
      name,
      value,
      event,
      profilePromoForm,
      setProfilePromoForm,
      profilePromoFormErrors,
      setProfilePromoFormErrors,
      element
    );
  };

  const hasError = (field: string, code?: string) => hasFieldError(profilePromoFormErrors, field, code);

  const getFieldErrors = (field: string) => getFieldErrorsUtil(field, profilePromoFormErrors);

  const profileUpdateSubmit = (e) => {
    e.preventDefault();

    const form = e.target;
    const inputs = [...form.elements].filter((i) => ['INPUT', 'SELECT', 'TEXTAREA'].includes(i.nodeName));

    const { errors, hasError } = FormValidator.bulkValidate(inputs);

    setProfilePromoFormErrors([...errors]);

    if (!hasError) {
      setPromoActivateLoading(true);

      activatePromo(profilePromoForm.promo)
        .then((response) => {
          setPromoActivateLoading(false);

          toast.success('Промо код успешно применен!');
        })
        .catch((error) => {
          setPromoActivateLoading(false);

          toast.error('Ошибка при активации промо кода');
        });
    }
  };

  return (
    <div className={styles.profilePromoView}>
      <form onSubmit={(e) => profileUpdateSubmit(e)}>
        <InputField
          name="promo"
          value={profilePromoForm.promo}
          onChange={(e) => validateOnChange('promo', e.target.value, e)}
          data-validate='["required"]'
          errors={getFieldErrors('promo')}
          filled
          block
          placeholder="Промокод"
        />

        <p className="mt-3 mb-0">Введите промокод для активации тарифа</p>

        <Button type="submit" className="mt-5" color="primary" block isLoading={promoActivateLoading}>
          Активировать
        </Button>
      </form>
    </div>
  );
};

export default PromoView;
