import axios from 'utils/axios';
import Manage from "../types/manage";
import {AxiosResponse} from "axios";
import {ResponseData} from "../types";
import {StringIdReference} from "../types/IdReference";

class manageSpecGroupsApi {
  static createSpecGroup = (request: Manage.Spec.EditGroupRequest): Promise<AxiosResponse<ResponseData<{ id: string }>>> => {
    return axios.post(`/api/v1/specializations/groups`, request)
  }

  static findSpecGroups = (request: Manage.Spec.GroupFilterRequest): Promise<AxiosResponse<ResponseData<Manage.Spec.GroupsResponse>>> => {
    const statuses = request.statuses || []
    return axios.get(`/api/v1/specializations/groups`, {
      params: {
        ...request,
        statuses: statuses.length > 0 ? statuses.join(',') : null
      }
    })
  }

  static getSpecGroup = (id: string): Promise<AxiosResponse<ResponseData<Manage.Spec.Group>>> => {
    return axios.get(`/api/v1/specializations/groups/${id}`)
  }

  static updateSpecGroup = (id: string, request: Manage.Spec.EditGroupRequest): Promise<AxiosResponse<ResponseData<boolean>>> => {
    return axios.put(`/api/v1/specializations/groups/${id}`, request)
  }

  static updateActiveStatus= (id: string, enabled: boolean): Promise<AxiosResponse<ResponseData<boolean>>> => {
    return axios.put(`/api/v1/specializations/groups/${id}/status`, {
      enabled: enabled
    })
  }

  static getAvailableSpecGroups = (): Promise<AxiosResponse<ResponseData<{items: Array<StringIdReference>}>>> => {
    return axios.get(`/api/v1/specializations/groups/available`)
  }

}

export default manageSpecGroupsApi
