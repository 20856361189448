import { MANAGE_TARIFFS_LOADING, MANAGE_TARIFFS_SUCCESS, MANAGE_TARIFFS_ERRORS } from '../actions';
import Manage from '../../types/manage';

export interface ManageTariffsInitialSettings {
  tariffs?: Manage.Tariff.Item[];
  errors?: any;
  loading?: boolean;
}
const initialSettings: ManageTariffsInitialSettings = {
  tariffs: [],
  loading: false,
  errors: null,
};

const manageTariffsReducer = (state = initialSettings, action): ManageTariffsInitialSettings => {
  switch (action.type) {
    case MANAGE_TARIFFS_LOADING:
      return {
        ...state,
        tariffs: [],
        loading: action.loading,
      };
    case MANAGE_TARIFFS_SUCCESS:
      return {
        ...state,
        tariffs: action.tariffs,
        errors: null,
      };
    case MANAGE_TARIFFS_ERRORS:
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
};

export default manageTariffsReducer;
