import React, { KeyboardEvent, useCallback, useEffect } from 'react';
import { Box } from '@material-ui/core';
import useRootSelector from 'hooks/useRootSelector';
import classNames from 'classnames';
import ContentLoading from 'components/ContentLoading';
import styles from './CheckerFrom.module.sass';
import {
  selectCheckerDictionaryByParentIdAndStep,
  selectCheckerError,
  selectCheckerLoading,
  selectCheckerLoadingOrder,
  selectCheckerParentId,
  selectCheckerStep,
  selectCheckerStepValue,
} from '../../store/selectors';
import { onGetChecker, onSetStepDataChecker } from '../../store/events';
import { CheckerDataInterface, CheckerTypeDescriptionTranslate } from '../../types';

const CheckerFrom = (): JSX.Element => {
  const step = useRootSelector(selectCheckerStep);
  const value = useRootSelector(selectCheckerStepValue);
  const parentId = useRootSelector(selectCheckerParentId);
  const loading = useRootSelector(selectCheckerLoading);
  const loadingOrder = useRootSelector(selectCheckerLoadingOrder);
  const error = useRootSelector(selectCheckerError);
  const dictionary = useRootSelector<Array<CheckerDataInterface>>(selectCheckerDictionaryByParentIdAndStep);
  const onChange = useCallback((data: CheckerDataInterface) => {
    onSetStepDataChecker(data);
  }, []);
  const onKeyPress = useCallback(
    (event: KeyboardEvent<any>, _: CheckerDataInterface) => {
      event.keyCode === 13 && onChange(_);
    },
    [onChange]
  );
  const isChecked = useCallback((_: CheckerDataInterface) => !!value.find(({ id }) => id === _.id), [value]);
  const getData = useCallback(() => {
    onGetChecker({ parentId, type: step });
  }, [parentId, step]);
  useEffect(() => {
    !dictionary && getData();
  }, [dictionary, getData]);
  return (
    <>
      <Box className={styles.root}>
        <Box className={styles.root_wrapper}>
          <ContentLoading isLoading={loading || loadingOrder} isError={error} fetchData={getData}>
            {(dictionary || []).length > 0 && (
              <Box className={styles.root_values}>
                {(dictionary || []).map((_, index) => (
                  <Box
                    key={index}
                    tabIndex={index + 1}
                    onKeyUp={(event) => onKeyPress(event, _)}
                    className={styles.root_value}
                    onClick={() => onChange(_)}
                  >
                    <Box
                      className={classNames(styles.root_value_checker, isChecked(_) && styles.root_value_checker_check)}
                    >
                      <Box className={styles.root_value_checker_inner} />
                    </Box>
                    <Box className={styles.root_value_label}>{_.value}</Box>
                  </Box>
                ))}
              </Box>
            )}
            {(dictionary || []).length === 0 && <Box className={styles.root_nodata}>Нет данных</Box>}
          </ContentLoading>
        </Box>
        <Box className={styles.root_desc}>{CheckerTypeDescriptionTranslate?.[step]}</Box>
      </Box>
    </>
  );
};
export default CheckerFrom;
