import Manage from "../../../types/manage";
import {useEffect, useState} from "react";
import {editServicePackageGroup} from "../../../api/editServicePackageGroup";
import {createServicePackageGroup} from "../../../api/createServicePackageGroup";
import {deleteServicePackageGroup} from "../../../api/deleteServicePackageGroup";
import {handleHttpError, HttpError} from "../../../utils/handleHttpError";

export const useEditServicePackageGroup = (onSuccess: () => void, onError: (error: HttpError) => void, initGroup?: Manage.ServicePackages.Groups.Item) => {

    const [groupId, setGroupId] = useState<string>()
    const [request, setRequest] = useState<Manage.ServicePackages.Groups.EditRequest>({})
    const [isLoading, setLoading] = useState(false)
    const [error, setError] = useState<HttpError>()


    useEffect(() => {
      setGroupId(initGroup?.id)
      setRequest( {
        name: initGroup?.name,
        description: initGroup?.description,
        externalKey: initGroup?.externalKey
      })
    }, [initGroup])

    const onSaved = () => {
      setLoading(false)
      setError(null)
      onSuccess()
    }

    const onFailed = (error: any) => {
      setLoading(false)
      const httpError = handleHttpError(error)
      setError(httpError)
      onError(httpError)
    }

    const onSave = () => {
      setLoading(true)
      if(groupId){
        editServicePackageGroup(groupId, request).then(res => onSaved(), onFailed)
      }else{
        createServicePackageGroup(request).then(res => onSaved(), onFailed)
      }
    }

    const onDelete = () => {
      if(groupId){
        setLoading(true)
        deleteServicePackageGroup(groupId).then(_ => onSaved(), onFailed)
      }
    }

    return [request, setRequest, isLoading, onSave, onDelete,  error] as [typeof request, typeof setRequest, typeof isLoading, typeof onSaved, typeof onDelete,typeof error]

}
