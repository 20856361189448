import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import classNames from 'classnames';
import Manage from '../../types/manage';
import BaseDialog, { BaseDialogProps } from '../BaseDialog/BaseDialog';
import styles from './AgentCertsGenerateDialog.module.sass';
import InputField from '../Forms/InputField';
import Button from '../Forms/Button';
import ContentLoading from '../ContentLoading';
import FormValidator from '../Forms/FormValidator';
import { useFormValidation } from '../../utils/hooks/useFormValidation';
import { displayErrors, handleHttpError } from '../../utils/handleHttpError';
import manageAgent from '../../api/agent/manageAgent';
import SelectInput2 from '../Forms/SelectInput2';

interface AgentCertsGenerateDialogProps {
  agentId: string;
  onClosed: () => void;
}

const AgentCertsGenerateDialog: React.FC<AgentCertsGenerateDialogProps> = (props) => {
  const { onClosed, agentId } = props;

  const [isLoading, setLoading] = useState<boolean>(false);
  const [tariffs, setTariffs] = useState<Array<{ label: string; value: string }>>([]);

  const [
    fields,
    validateOnChange,
    getFieldErrors,
    _,
    setFormErrors,
  ] = useFormValidation<Manage.Agent.CertsGenerateRequest>({
    count: 1,
    servicePackageId: null,
  });

  useEffect(() => _reloadTariffs(), []);

  const _reloadTariffs = () => {
    setLoading(true);
    manageAgent
      .getAvailableServicePackages(agentId)
      .then((res) => {
        setTariffs(
          res.data?.data?.items.map((value) => {
            return { label: value.name, value: value.id };
          }) || []
        );
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.error(e);
        setTariffs([]);
      });
  };

  const _onSendData = () => {
    setLoading(true);
    const action = manageAgent.generateCertificates(agentId, fields);
    action
      .then((res) => {
        onClosed();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        const e = handleHttpError(error);
        displayErrors(e);
      });
  };

  const onSave = (e) => {
    e.preventDefault();
    const form = e.target;
    const inputs = [...form.elements].filter((i) => ['INPUT', 'SELECT', 'TEXTAREA'].includes(i.nodeName));

    const { hasError, errors } = FormValidator.bulkValidate(inputs);

    setFormErrors([...errors]);

    if (!hasError) {
      console.log(fields);
      _onSendData();
    } else {
      console.error(errors);
    }
  };

  return (
    <BaseDialog onClosed={onClosed} title="Генерация сертификатов">
      <ContentLoading isLoading={isLoading} isError={false} fetchData={() => null}>
        <form onSubmit={onSave}>
          <Box className={classNames(styles.AgentCertsGenerateDialog_Content_Wrapper)}>
            <Box className={classNames(styles.AgentCertsGenerateDialog_Content_Item)}>
              <Grid container justify="space-between" alignItems="flex-end" spacing={1}>
                <Grid item xs={6}>
                  <SelectInput2
                    name="servicePackageId"
                    disabled={isLoading}
                    value={fields.servicePackageId || null}
                    onChange={(option, e) => {
                      validateOnChange('servicePackageId', option.target.value, null, e);
                    }}
                    options={tariffs}
                    errors={getFieldErrors('servicePackageId')}
                    dataValidate={'["required"]'}
                    label="Тариф"
                    placeholder="Тариф"
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputField
                    name="count"
                    type="number"
                    min={1}
                    value={fields.count ? fields.count.toString() : ''}
                    onChange={(e) => validateOnChange('count', e.target.value, e)}
                    data-validate='["required", "number"]'
                    errors={getFieldErrors('count')}
                    block
                    label="Кол-во сертификатов"
                    placeholder="Кол-во сертификатов"
                  />
                </Grid>
                <Grid item xs={12} container justify="space-around" alignItems="center" spacing={1}>
                  <Grid item xs={6} lg={6}>
                    <Button className="mr-2" color="default" onClick={onClosed}>
                      Отмена
                    </Button>
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <Button type="submit" color="primary" isLoading={isLoading}>
                      Генерировать
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </form>
      </ContentLoading>
    </BaseDialog>
  );
};

export default AgentCertsGenerateDialog;
