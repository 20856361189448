import React, { PropsWithChildren } from 'react';

import classNames from 'classnames';
import styles from './PageTitle.module.sass';

interface PropsInterface {
  className?: string;
  center?: boolean;
}

const PageTitle = (props: PropsWithChildren<PropsInterface>) => {
  const { className, children, center = true } = props;
  return <h6 className={classNames([styles.root, center && styles.root_center, className])}>{children}</h6>;
};

export default PageTitle;
