import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchPackagesMy } from 'store/actions';
import { toast } from 'react-toastify';

// Components
import ContentLoading from 'components/ContentLoading';

import { Box } from '@material-ui/core';
import classNames from 'classnames';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ImageIcon from '@material-ui/icons/Image';
import ContentCard from '../../../components/ContentCard';
import { ReactComponent as AngleLeftIcon } from '../../../assets/img/icons/angle-left-icon.svg';
import { getUploadDocuments } from '../../../api/servicePackage/getUploadDocuments';
import Button from '../../../components/Forms/Button';
import styles from './PackagesDocuments.module.sass';
import axios from '../../../utils/axios';
import { displayErrors, handleHttpError } from '../../../utils/handleHttpError';
import { onlineSignPatientServicePackageDocs } from '../../../api/servicePackage/onlineSignPatientServicePackageDocs';

const PackagesDocuments = (props: any) => {
  const { history } = props;

  const {
    fetchPackagesMy,
    packgesMyLoading,
    packgesMyLoadingError,
    match: { params: servicePackageId },
  } = props;

  const { servicePackageId: packageId } = servicePackageId;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [items, setItems] = useState([]);
  const [docContentLoading, setDocContentLoading] = useState<any>(null);

  useEffect(() => {
    fetchPackagesMy(servicePackageId);
    if (items.length === 0) {
      getMyDocuments();
    }
  }, []);

  const onlineSign = (packageId: string) => {
    onlineSignPatientServicePackageDocs(packageId)
      .then(() => getMyDocuments())
      .catch((error) => {
        displayErrors(handleHttpError(error));
      });
  };

  const getMyDocuments = () => {
    setLoading(true);
    getUploadDocuments(packageId)
      .then((res) => {
        if (res.data.code === 'success') {
          const items = res.data?.data?.items || [];
          if (items.length === 0) {
            onlineSign(packageId);
          }
          setItems(items);
        } else {
          setError(true);
          toast.error('Ошибка удаления рабочего интервала. Попробуйте еще раз');
        }
        setLoading(false);
      })
      .catch((e) => {
        setError(true);
        setLoading(false);
        displayErrors(handleHttpError(e));
      });
  };

  const downloadDoc = (item: any): void => {
    setDocContentLoading(item?.id);
    axios
      .get(item?.resourceUrl, { responseType: 'blob' })
      .then((data) => {
        const fileURL = URL.createObjectURL(data?.data);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = `${item?.name}`;
        link.click();
        setTimeout(() => {
          link.remove();
          setDocContentLoading(null);
        }, 300);
      })
      .catch((e) => {
        setDocContentLoading(null);
        displayErrors(handleHttpError(e));
      });
  };

  return (
    <>
      <ContentCard>
        <ContentCard.Header>
          <div className="row">
            <div className="col-xs-4 text-center text-xs-left mb-2 mb-xs-0">
              <div
                className="page-header-back-link"
                onClick={() => {
                  history.push(`/packages/${packageId}`);
                }}
              >
                <AngleLeftIcon className="page-header-back-link-icon" />
                Назад
              </div>
            </div>
            <div className="col-xs-8 col-md-4 text-center text-xs-right text-md-center">
              <h6 className="page-header-title">Загруженные документы</h6>
            </div>
          </div>
        </ContentCard.Header>
        <ContentCard.Main>
          <ContentLoading
            isLoading={packgesMyLoading || loading}
            isError={packgesMyLoadingError || error}
            fetchData={() => getMyDocuments()}
          >
            <Box className={classNames(styles.Docs)}>
              {items.length === 0 && (
                <Box width="100%" display="flex" alignItems="center" justifyContent="center">
                  <Box>Нет загруженных документов</Box>
                </Box>
              )}
              {items.map((item: any, index: number) => (
                <Box className={classNames(styles.Docs_Item)} key={index}>
                  <Box className={classNames(styles.Docs_Item_Data)}>
                    <Box className={classNames(styles.Docs_Item_Data_Value)}>
                      {item?.contentType === 'application/pdf' ? (
                        <PictureAsPdfIcon className={classNames(styles.Docs_Item_Data_Icon)} />
                      ) : (
                        <ImageIcon className={classNames(styles.Docs_Item_Data_Icon)} />
                      )}
                    </Box>
                    <Box className={classNames(styles.Docs_Item_Data_Value)}>{item.name}</Box>
                  </Box>
                  <Box className={classNames(styles.Docs_Item_Actions)}>
                    <Button
                      style={{
                        paddingLeft: 10,
                        paddingRight: 10,
                      }}
                      disabled={!!docContentLoading}
                      isLoading={docContentLoading === item?.id}
                      size="sm"
                      color="default"
                      onClick={(event) => {
                        event.stopPropagation();
                        downloadDoc(item);
                      }}
                    >
                      Просмотреть
                    </Button>
                  </Box>
                </Box>
              ))}
            </Box>
          </ContentLoading>
        </ContentCard.Main>
      </ContentCard>
    </>
  );
};

export default connect(
  (state: any) => ({
    packgesMyList: state.profile.packgesMyList,
    packgesMyLoading: state.profile.packgesMyLoading,
    packgesMyLoadingError: state.profile.packgesMyLoadingError,
  }),
  { fetchPackagesMy }
)(withRouter(PackagesDocuments));
