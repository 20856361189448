import axios from 'utils/axios';

export const getMyPatientsInfo = (query: string, page: number, pageSize: number) =>
  axios.get(`/api/v1/profile/mypatients`, {
    params: {
      query,
      page,
      pageSize,
    },
  });

export const getMyPatientsById = (userId: string) =>
  axios.get(`/api/v1/profile/mypatients`, {
    params: {
      userId,
    },
  });
