import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import ContentCard from '../../../components/ContentCard';
import styles from './PartnerPageAdmin.module.sass';
import Button from '../../../components/Forms/Button';
import Manage from '../../../types/manage';
import { fetchPartners } from '../../../store/actions';
import { ManagePartnersSettings } from '../../../store/reducers/managePartners.reducer';
import ContentLoading from '../../../components/ContentLoading';
import PartnerModifyDialog from '../../../components/PartnerModifyDialog';
import managePartnersApi from '../../../api/partner/managePartners';
import useApiFx from '../../../hooks/useApiFx';
import useStoreApi from '../../../hooks/useStoreApi';
import useHasMore from '../../../hooks/useHasMore';

interface PartnersPageAdminProps {
  history: any;
  page: number;
  pageSize: number;
  totalItems: number;
  loading: boolean;
  errors: any;
  items: Array<Manage.Partners.Item>;
  fetchPartners: (page?: number, pageSize?: number, reset?: boolean) => void;
}

const PartnersPageAdmin: React.FC<PartnersPageAdminProps> = (props) => {
  const { history, totalItems, page, pageSize, loading, errors, items = [], fetchPartners } = props;
  const [file, setFile] = useState<File | null>(null);
  const [partner, setPartner] = useState<any>(null);

  const loadFileFx = useApiFx<any, any>(managePartnersApi.loadFile);

  const { loading: loadingFile, error, data } = useStoreApi(loadFileFx, { loading: true });

  const hasMore = useHasMore(totalItems, page + 1, pageSize);

  const onLoadMore = useCallback((): void => {
    fetchPartners(page + 1, pageSize);
  }, [fetchPartners, pageSize, page]);

  const getData = useCallback(() => fetchPartners(0, pageSize, true), [fetchPartners, pageSize]);

  useEffect(() => getData(), [getData]);

  const onCreatePartnerClick = useCallback(() => {
    setPartner({});
  }, []);

  const handleSelectFile = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setFile(event.target.files.item(0));
  }, []);

  useEffect(() => {
    file &&
      loadFileFx({ file })
        .then()
        .finally(() => setFile(null));
  }, [file, loadFileFx]);

  useEffect(() => {
    if (!loadingFile) {
      if (error) {
        toast.error(error?.message);
      } else if ((data || []).length) {
        toast.error(
          <Box>
            {(data || []).map((v) => (
              <Box>{v}</Box>
            ))}
          </Box>
        );
      } else if (data !== null) {
        toast.success('Файл загружен');
      }
    }
  }, [error, data, loadingFile]);

  return (
    <>
      <ContentCard>
        <ContentCard.Header>
          <div className="row">
            <div className="col-12 text-center text-xs-right text-md-center">
              <h6 className="page-header-title">Партнеры</h6>
            </div>
          </div>
        </ContentCard.Header>
        <Box className={styles.PartnersPageAdmin_Toolbar}>
          <Box className={styles.PartnersPageAdmin_Toolbar_Buttons}>
            <Button isLoading={loadingFile} disabled={loadingFile} color="primary" size="md">
              Загрузить реестр
              <input
                type="file"
                accept=".xls, .xlsx, .rtf, .txt, .csv"
                onChange={handleSelectFile}
                className={styles.PartnersPageAdmin_Toolbar_File}
              />
            </Button>
            <Button color="primary" size="md" onClick={onCreatePartnerClick}>
              Создать партнера
            </Button>
          </Box>
        </Box>
        <ContentCard.Main>
          <>
            <Box p={0} width="100%" height="100%" display="flex" flexDirection="column" justifyContent="center">
              <>
                {items.length === 0 && !(loading || errors) && (
                  <Box className={styles.PartnersPageAdmin_EmptyLabel}>Партнеров по вашему запросу не найдено</Box>
                )}
                {items.map((value) => {
                  return (
                    <Box key={`partner-${value.id}`} className={styles.PartnersPageAdmin_Row}>
                      <Box className={styles.PartnersPageAdmin_Row_Main}>
                        <Box className={styles.PartnersPageAdmin_Row_Cell}>
                          <Box className={styles.PartnersPageAdmin_Profile}>
                            <Box
                              className={classNames(
                                styles.PartnersPageAdmin_Profile_Item,
                                styles.PartnersPageAdmin_Profile_Name
                              )}
                            >
                              {`${value.company} (${value.key})`}
                            </Box>
                            <Box className={classNames(styles.PartnersPageAdmin_Profile_Item)}>
                              {[value.firstName, value.middleName, value.lastName].filter((s) => s).join(' ')}
                            </Box>
                            <Box className={classNames(styles.PartnersPageAdmin_Profile_Item)}>{value.email}</Box>
                          </Box>
                        </Box>
                        <Box className={styles.PartnersPageAdmin_Row_Cell} style={{ justifyContent: 'flex-end' }}>
                          <Box display="flex" flexDirection="column">
                            <Button
                              className={styles.PartnersPageAdmin_Button}
                              onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                setPartner(value);
                              }}
                              size="sm"
                              color="default"
                            >
                              Редактировать
                            </Button>
                            <Button
                              className={styles.PartnersPageAdmin_Button}
                              onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                history.push(`/partners/${value.id}/agents`);
                              }}
                              size="sm"
                              color="default"
                            >
                              Список агентов
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
                {hasMore && !loading && !errors && (
                  <Box className="d-flex justify-content-center align-items-center mt-5">
                    <Button size="md" color="default" onClick={onLoadMore}>
                      Показать еще
                    </Button>
                  </Box>
                )}
                {(loading || errors) && (
                  <Box width="100%" p={2}>
                    <ContentLoading isLoading={loading} isError={errors} fetchData={getData} />
                  </Box>
                )}
              </>
            </Box>
          </>
        </ContentCard.Main>
      </ContentCard>
      {partner && (
        <PartnerModifyDialog
          editItem={partner}
          onUpdated={() => {
            setPartner(null);
            getData();
          }}
          onClosed={() => setPartner(null)}
          title={null}
        />
      )}
    </>
  );
};

export default connect(
  (state: any) => ({
    ...(state.managePartnersReducer as ManagePartnersSettings),
  }),
  {
    fetchPartners,
  }
)(PartnersPageAdmin);
