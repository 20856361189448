import {
  DOCTOR_PATIENTS_LOADING,
  DOCTOR_PATIENTS_SUCCESS,
  DOCTOR_PATIENTS_ERRORS,
  DOCTOR_PATIENT_APPOINTMENTS_LOADING,
  DOCTOR_PATIENT_APPOINTMENTS_SUCCESS,
  DOCTOR_PATIENT_APPOINTMENTS_ERRORS,
} from '../actions';
import Patient from '../../types/patient';

export interface DoctorPatientsInitialSettings {
  patients?: Patient.Data[];
  page?: number;
  pageSize?: number;
  totalItems?: number;
  hasMore?: boolean;
  errors?: any;
  loading?: boolean;
  patientAppointments?: Patient.PatientAppointments.Item[];
  pageAppointments?: number;
  pageSizeAppointments?: number;
  totalItemsAppointments?: number;
  hasMoreAppointments?: boolean;
  errorsAppointments?: any;
  loadingAppointments?: boolean;
}
const initialSettings: DoctorPatientsInitialSettings = {
  patients: [],
  page: 0,
  pageSize: 50,
  totalItems: 0,
  hasMore: false,
  loading: false,
  errors: null,
  patientAppointments: [],
  pageAppointments: 0,
  pageSizeAppointments: 10,
  totalItemsAppointments: 0,
  hasMoreAppointments: false,
  loadingAppointments: false,
  errorsAppointments: null,
};

const doctorPatientsReducer = (state = initialSettings, action): DoctorPatientsInitialSettings => {
  switch (action.type) {
    case DOCTOR_PATIENTS_LOADING:
      return {
        ...state,
        patients: action.loading && action.reset ? [] : state.patients,
        loading: action.loading,
      };
    case DOCTOR_PATIENTS_SUCCESS:
      return {
        ...state,
        patients: action.page > 0 ? state.patients.concat(action.patients) : action.patients,
        page: action.page,
        pageSize: action.pageSize,
        totalItems: action.totalItems,
        hasMore: (action.page + 1) * action.pageSize < action.totalItems && action.totalItems > 0,
        errors: null,
      };
    case DOCTOR_PATIENTS_ERRORS:
      return {
        ...state,
        errors: action.errors,
      };
    case DOCTOR_PATIENT_APPOINTMENTS_LOADING:
      return {
        ...state,
        patientAppointments: action.loading && action.reset ? [] : state.patientAppointments,
        loadingAppointments: action.loading,
      };
    case DOCTOR_PATIENT_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        patientAppointments:
          action.page > 0 ? state.patientAppointments.concat(action.appointments) : action.appointments,
        pageAppointments: action.page,
        pageSizeAppointments: action.pageSize,
        totalItemsAppointments: action.totalItems,
        hasMoreAppointments: (action.page + 1) * action.pageSize < action.totalItems && action.totalItems > 0,
        errorsAppointments: null,
      };
    case DOCTOR_PATIENT_APPOINTMENTS_ERRORS:
      return {
        ...state,
        errorsAppointments: action.errors,
      };
    default:
      return state;
  }
};

export default doctorPatientsReducer;
