import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import classNames from 'classnames';
import { connect } from 'react-redux';
import styles from './SettingsPageAdminTariffs.module.sass';
import Button from '../../../../components/Forms/Button';
import { fetchManageTariffs } from '../../../../store/actions';
import Manage from '../../../../types/manage';
import { ManageTariffsInitialSettings } from '../../../../store/reducers/manageTariffsReducer.reducer';
import ContentLoading from '../../../../components/ContentLoading';
import AdminTariffView from '../../../../components/AdminTariffView';

type SettingsPageAdminTariffsFilter = 'all' | 'active' | 'archive';
interface PropsInterface {
  loading: boolean;
  tariffs: Manage.Tariff.Item[];
  fetchManageTariffs: (active: boolean) => void;
  errors: any;
}
const SettingsPageAdminTariffs = (props: PropsInterface) => {
  const { loading, tariffs, fetchManageTariffs, errors } = props;
  const [filter, setFilter] = useState<SettingsPageAdminTariffsFilter>('all');
  const [tariff, setTariff] = useState<Manage.Tariff.Item>(null);
  useEffect(() => {
    getTariffs();
  }, [filter]);
  const getTariffs = (): void => {
    fetchManageTariffs(filter === 'active' ? true : filter === 'archive' ? false : null);
  };
  return (
    <>
      <Box className={classNames(styles.SettingsPageAdminTariffs)}>
        <Box className={classNames(styles.SettingsPageAdminTariffs_Section, styles.SettingsPageAdminTariffs_Toolbar)}>
          <Button
            className="mr-4"
            size="sm"
            color={filter === 'all' ? 'primary' : 'default'}
            onClick={() => {
              filter !== 'all' && setFilter('all');
            }}
          >
            Все
          </Button>
          <Button
            className="mr-4"
            size="sm"
            color={filter === 'active' ? 'primary' : 'default'}
            onClick={() => {
              filter !== 'active' && setFilter('active');
            }}
          >
            Активные
          </Button>
          <Button
            size="sm"
            color={filter === 'archive' ? 'primary' : 'default'}
            onClick={() => {
              filter !== 'archive' && setFilter('archive');
            }}
          >
            В архиве
          </Button>
        </Box>
        <Box className={classNames(styles.SettingsPageAdminTariffs_Section, styles.SettingsPageAdminTariffs_Content)}>
          <ContentLoading isLoading={loading} isError={errors} fetchData={() => getTariffs()}>
            {tariffs.length === 0 && <Box>У вас пока нет созданных тарифов</Box>}
            {tariffs.length > 0 && (
              <Box className={classNames(styles.SettingsPageAdminTariffs_Tariffs)}>
                {tariffs.map((tariff: Manage.Tariff.Item, index: number) => (
                  <Box className={classNames(styles.SettingsPageAdminTariffs_Tariffs_Tariff)} key={index}>
                    <Box className={classNames(styles.SettingsPageAdminTariffs_Tariffs_Tariff_Data)}>
                      <Box className={classNames(styles.SettingsPageAdminTariffs_Tariffs_Tariff_Data_Name)}>
                        {tariff.name}
                      </Box>
                      <Box className={classNames(styles.SettingsPageAdminTariffs_Tariffs_Tariff_Data_Price)}>
                        {tariff.price} &#8381;
                      </Box>
                      <Box
                        className={classNames(
                          styles.SettingsPageAdminTariffs_Tariffs_Tariff_Data_Status,
                          tariff.active === true && styles.SettingsPageAdminTariffs_Tariffs_Tariff_Data_Status_Active
                        )}
                      >
                        {tariff.active === true ? 'Активный тариф' : 'Тариф в архиве'}
                      </Box>
                    </Box>
                    <Box className={classNames(styles.SettingsPageAdminTariffs_Tariffs_Tariff_Actions)}>
                      <Button size="sm" color="default" onClick={() => setTariff(tariff)}>
                        Редактировать
                      </Button>
                    </Box>
                  </Box>
                ))}
              </Box>
            )}
          </ContentLoading>
        </Box>
        <Box className={classNames(styles.SettingsPageAdminTariffs_Section, styles.SettingsPageAdminTariffs_Action)}>
          <Button size="md" color="primary" onClick={() => setTariff({})}>
            Создать новый тариф
          </Button>
        </Box>
      </Box>
      {tariff && (
        <AdminTariffView
          tariff={tariff}
          onSave={() => {
            getTariffs();
            setTariff(null);
          }}
          onClose={() => setTariff(null)}
        />
      )}
    </>
  );
};
export default connect(
  (state: any) => ({
    ...(state.manageTariffs as ManageTariffsInitialSettings),
  }),
  {
    fetchManageTariffs,
  }
)(SettingsPageAdminTariffs);
