import {
  MANAGE_REPORT_CD_SUMMARY_ERRORS,
  MANAGE_REPORT_CD_SUMMARY_LOADING,
  MANAGE_REPORT_CD_SUMMARY_SUCCESS
} from "../actions/manageReportCloseDaySummary.actions";

export interface ManageReportCloseDaySummaryReducerInitialSettings {
  items?: any;
  page?: number;
  pageSize?: number;
  totalItems?: number;
  hasMore?: boolean;
  errors?: any;
  loading?: boolean;
}
const initialSettings: ManageReportCloseDaySummaryReducerInitialSettings = {
  items: [],
  page: 0,
  pageSize: 7,
  totalItems: 0,
  hasMore: false,
  loading: false,
  errors: null,
};

const manageReportCDSummaryReducer = (state = initialSettings, action): ManageReportCloseDaySummaryReducerInitialSettings => {
  switch (action.type) {
    case MANAGE_REPORT_CD_SUMMARY_LOADING:
      return {
        ...state,
        items: action.loading && action.reset ? [] : state.items,
        loading: action.loading,
      };
    case MANAGE_REPORT_CD_SUMMARY_SUCCESS:
      return {
        ...state,
        items: action.page > 0 ? state.items.concat(action.items) : action.items,
        page: action.page,
        pageSize: action.pageSize,
        totalItems: action.totalItems,
        hasMore: (action.page + 1) * action.pageSize + action.pageSize < action.totalItems && action.totalItems > 0,
        errors: null,
      };
    case MANAGE_REPORT_CD_SUMMARY_ERRORS:
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
};

export default manageReportCDSummaryReducer;
