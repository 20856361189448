import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { onGetStories, onResetStories } from 'store/stories/events';
import PageCard from 'components/PageCard';
import PageTitle from 'components/PageTitle';
import Button from 'components/Forms/Button';
import styles from './AdminStoriesSearch.module.sass';
import AdminStoriesListHeader from './components/AdminStoriesListHeader';
import AdminStoriesList from './components/AdminStoriesList';
import AdminStoriesEditModal from './components/AdminStoriesEditModal';
import useRootSelector from '../../../hooks/useRootSelector';
import { selectStoriesItemsCount } from '../../../store/stories/selectors';

const AdminStoriesSearch = (): JSX.Element => {
  const reset = useCallback(() => {
    onResetStories();
  }, []);
  const getData = useCallback(() => {
    onGetStories();
  }, []);
  useEffect(() => {
    getData();
  }, [getData]);
  useEffect(
    () => () => {
      reset();
    },
    [reset]
  );
  const weight = useRootSelector(selectStoriesItemsCount) + 1;
  const [edit, setEdit] = useState(null);
  const handleOpenEdit = useCallback(() => {
    setEdit({ weight });
  }, [weight]);
  const handleCloseEdit = useCallback((isSaved?: boolean) => {
    setEdit(null);
    isSaved && onGetStories();
  }, []);
  return (
    <>
      <Box className={styles.root}>
        <PageTitle className={styles.root_title}>
          <Box>Список постов:</Box>
          <Button onClick={handleOpenEdit} size="sm" type="submit" color="primary">
            <Box className="d-flex align-items-center">
              <Box>Добавить</Box>
              <Box className="ml-2">+</Box>
            </Box>
          </Button>
        </PageTitle>
        <AdminStoriesList header={<AdminStoriesListHeader />} />
        {edit && <AdminStoriesEditModal item={edit} onClose={handleCloseEdit} />}
      </Box>
    </>
  );
};

export default AdminStoriesSearch;
