import { AxiosResponse } from 'axios';
import {
  getProfileMetaResponseInterface,
  getProfilePersonalsInteractionsMetaResponseInterface,
  ResponseDataInterface,
} from 'types';
import axios from 'utils/axios';

export const getProfilePersonalsInteractionsMeta = (): Promise<
  AxiosResponse<ResponseDataInterface<getProfilePersonalsInteractionsMetaResponseInterface>>
> => axios.get(`/api/v1/profile/personals/interactions/meta`);

export const getProfilePersonalsMeta = (): Promise<
  AxiosResponse<ResponseDataInterface<getProfileMetaResponseInterface>>
> => axios.get(`/api/v1/profile/meta`);
