import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import BaseDialog from '../BaseDialog';
import ContentLoading from '../ContentLoading';
import manageAgentApi from '../../api/agent/manageAgent';
import { displayErrors, handleHttpError } from '../../utils/handleHttpError';
import styles from './AgentTokenDialog.module.sass';
import InputField from '../Forms/InputField';

interface AgentTokenDialogProps {
  agentId: string;
  onClosed: () => void;
}

const AgentTokenDialog: React.FC<AgentTokenDialogProps> = (props) => {
  const { onClosed, agentId } = props;

  const [isLoading, setLoading] = useState<boolean>(false);
  const [token, setToken] = useState<string>(null);

  useEffect(() => {
    setLoading(true);
    manageAgentApi
      .getToken(agentId)
      .then((response) => {
        setLoading(false);
        setToken(response.data.data);
      })
      .catch((error) => {
        setLoading(false);
        const e = handleHttpError(error);
        displayErrors(e);
      });
  }, [agentId]);

  return (
    <BaseDialog onClosed={onClosed} title="Токен доступа">
      <ContentLoading isLoading={isLoading} isError={false} fetchData={() => null}>
        <Box className={styles.AgentTokenDialog_Wrapper}>
          <Box className={styles.AgentTokenDialog_Item}>
            <InputField disabled type="textarea" name="token" rows={6} value={token} block placeholder="Токен" />
          </Box>
        </Box>
      </ContentLoading>
    </BaseDialog>
  );
};

export default AgentTokenDialog;
