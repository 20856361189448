import { AxiosResponse } from 'axios';
import axios from 'utils/axios';
import { CheckerDataInterface, CheckerFilterRequest, CheckerInterface } from '../types';
import { ResponseData } from '../../../types';

export const getCheckerApi = (params: CheckerFilterRequest): Promise<AxiosResponse<ResponseData<Array<any>>>> =>
  axios.get(`/api/v1/checker`, { params });

export const setCheckerApi = (items: Array<CheckerDataInterface>): Promise<AxiosResponse<CheckerInterface>> =>
  axios.post(`/api/v1/checker/order`, { items });
