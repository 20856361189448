import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

// Components
import ContentCard from 'components/ContentCard';
import PackagesAll from './PackagesAll';
import PackagesMy from './PackagesMy';

import styles from './ServicePackagesPage.module.sass';
import { getTabState, setTabState } from '../../store/actions';

const ServicePackagesPage = (props: any) => {
  const { packages } = getTabState();

  const [packagesViewType, setPackagesViewType] = useState(packages || 'PACKAGES_MY');
  const getPackagesView = (packagesViewType: string) => {
    let packagesView = null;

    switch (packagesViewType) {
      case 'PACKAGES_ALL':
        packagesView = <PackagesAll />;
        break;

      case 'PACKAGES_MY':
        packagesView = <PackagesMy />;
        break;

      default:
        return <></>;
    }

    return packagesView;
  };

  useEffect(() => {
    !packages && setTabState({ packages: packagesViewType });
  }, [packagesViewType]);

  return (
    <>
      <ContentCard>
        <ContentCard.Header className={styles.servicePackagesCardHeader}>
          <div className={`page-header-menu ${styles.contentCard_menu}`}>
            <div
              className={classNames('page-header-menu-item', styles.contentCard_menu_item, {
                'page-header-menu-item-active': packagesViewType === 'PACKAGES_MY',
              })}
              onClick={(e) => {
                setPackagesViewType('PACKAGES_MY');
                setTabState({ packages: 'PACKAGES_MY' });
              }}
            >
              Мои тарифы
            </div>

            <div
              className={classNames('page-header-menu-item', styles.contentCard_menu_item, {
                'page-header-menu-item-active': packagesViewType === 'PACKAGES_ALL',
              })}
              onClick={(e) => {
                setPackagesViewType('PACKAGES_ALL');
                setTabState({ packages: 'PACKAGES_ALL' });
              }}
            >
              Все тарифы
            </div>
          </div>
        </ContentCard.Header>
        <ContentCard.Main>{getPackagesView(packagesViewType)}</ContentCard.Main>
      </ContentCard>
    </>
  );
};

export default ServicePackagesPage;

//Request URL: http://localhost:3000/api/v1/servicepackages/mfo
//Request URL: http://localhost:3000/api/v1/promotionstatuses
//Request URL: http://localhost:3000/api/v1/servicepackages/patient?page=0&pageSize=50
