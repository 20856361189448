import { createEffect } from 'effector';
import { ResponseCode } from 'types';
import { getProfilePersonalsInteractionsMeta } from '../../$api';

export const getProfilePersonalsInteractionsMetaFx = createEffect(
  async (): Promise<{ packageId: string | null; doctor: any; appointmentId: string | null }> => {
    const {
      data: {
        data: { personalAppointment, personalPsp, personalDoctorProfile },
        code,
      },
    } = await getProfilePersonalsInteractionsMeta().then();
    if (code === ResponseCode.SUCCESS) {
      return {
        doctor: personalDoctorProfile || null,
        appointmentId: personalAppointment?.id || null,
        packageId: personalPsp?.id || null,
      };
    }
    throw new Error(code);
  }
);
