import {
  MANAGE_APPOINTMENT_ITEMS_LOADING,
  MANAGE_APPOINTMENT_ITEMS_SUCCESS,
  MANAGE_APPOINTMENT_ITEMS_ERRORS,
} from '../actions';
import Manage from '../../types/manage';

export interface ManageAppointmentsItemsInitialSettings {
  items?: Manage.AppointmentItem.Item[];
  page?: number;
  pageSize?: number;
  totalItems?: number;
  hasMore?: boolean;
  errors?: any;
  loading?: boolean;
}
const initialSettings: ManageAppointmentsItemsInitialSettings = {
  items: [],
  page: 0,
  pageSize: 50,
  totalItems: 0,
  hasMore: false,
  loading: false,
  errors: null,
};

const manageAppointmentsItemsReducer = (state = initialSettings, action): ManageAppointmentsItemsInitialSettings => {
  switch (action.type) {
    case MANAGE_APPOINTMENT_ITEMS_LOADING:
      return {
        ...state,
        items: action.loading && action.reset ? [] : state.items,
        loading: action.loading,
      };
    case MANAGE_APPOINTMENT_ITEMS_SUCCESS:
      return {
        ...state,
        items: action.page > 0 ? state.items.concat(action.items) : action.items,
        page: action.page,
        pageSize: action.pageSize,
        totalItems: action.totalItems,
        hasMore: (action.page + 1) * action.pageSize + action.pageSize < action.totalItems && action.totalItems > 0,
        errors: null,
      };
    case MANAGE_APPOINTMENT_ITEMS_ERRORS:
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
};

export default manageAppointmentsItemsReducer;
