import React, { useEffect, useState } from 'react';
import { Avatar, Backdrop, Box, Dialog, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import styles from './AdminMfoSend.module.sass';
import ContentCard from '../ContentCard';
import InputField from '../Forms/InputField';
import { decodeAvatarResource } from '../../utils';
import ContentLoading from '../ContentLoading';
import Button from '../Forms/Button';
import { createPatientServicePackage } from '../../api/createPatientServicePackage';
import Patient from '../../types/patient';
import { getPatientsAutocomplete } from '../../api/getPatientsAutocomplete';
import CustomCheckbox from '../Forms/CustomCheckbox';
import { displayErrors, handleHttpError } from '../../utils/handleHttpError';

interface AppointmentDoctorViewInterface {
  servicePackageId: string;
  onSend: () => void;
  onClose: () => void;
}

const AdminMfoSend = (props: AppointmentDoctorViewInterface) => {
  const { onClose = () => {}, onSend = () => {}, servicePackageId } = props;
  const [loading, setLoading] = useState<boolean>(true);
  const [errors, setErrors] = useState<any>(null);
  const [loadingSend, setLoadingSend] = useState<boolean>(false);
  const [query, setQuery] = useState<string>(null);
  const [patientId, setPatientId] = useState<string>(null);
  const [patients, setPatients] = useState<Patient.PatientAutocomplete.Item[]>([]);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(50);
  const [hasMore, setHasMore] = useState(false);
  useEffect(() => {
    if (!query) {
      getPatients(true);
    }
    const timer: any = !query ? null : setTimeout(() => getPatients(true), 500);
    return () => {
      timer && clearTimeout(timer);
    };
  }, [query]);
  const submit = (e) => {
    e.preventDefault();
    patientId && servicePackageId && send();
  };
  useEffect(() => {
    setPatientId((patientId) => (patients.map(({ id }) => id).indexOf(patientId) > -1 ? patientId : null));
  }, [patients]);
  const getPatients = (reset?: boolean, lastPage?: number): void => {
    setLoading(true);
    getPatientsAutocomplete(query, reset ? 0 : Number(lastPage) >= 0 ? lastPage : page + 1, pageSize)
      .then((response: AxiosResponse<Patient.PatientAutocomplete.Response>) => {
        setLoading(false);
        if (response.data.code === 'success') {
          setPatients(response.data.data.items);
          setPage(response.data.data.page);
          setPageSize(response.data.data.pageSize);
          setHasMore(
            (response.data.data.page + 1) * response.data.data.pageSize < response.data.data.totalItems &&
              response.data.data.totalItems > 0
          );
        } else {
          setErrors(response.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        setErrors(err);
      });
  };
  const send = (): void => {
    setLoadingSend(true);
    createPatientServicePackage(patientId, servicePackageId)
      .then((response: AxiosResponse<any>) => {
        if (response.data.code === 'success') {
          toast.success('Промокод успешно отправлен');
          onSend();
        } else {
          throw new Error();
        }
        setLoadingSend(false);
      })
      .catch((err) => {
        displayErrors(handleHttpError(err));
        setLoadingSend(false);
      });
  };
  return (
    <Dialog
      PaperProps={{ className: classNames(styles.Dialog) }}
      BackdropComponent={(props) => <Backdrop className={styles.Backdrop} {...props} />}
      onClose={onClose}
      open
    >
      <ContentCard className={styles.AdminMfoSend}>
        <ContentCard.Header className={styles.AdminMfoSend_Header}>
          <h6 className="page-header-title">Отправить промокод</h6>
          <CloseIcon onClick={onClose} className={styles.AdminMfoSend_CloseIcon} />
        </ContentCard.Header>
        <ContentCard.Main className={styles.AdminMfoSend_Content}>
          <form onSubmit={() => getPatients(true)} className={styles.AdminMfoSend_Content_Search}>
            <SearchIcon className={styles.AdminMfoSend_Content_Search_Icon} />
            <InputField
              value={query}
              onChange={(e) => {
                setQuery(e.target.value);
              }}
              block
              placeholder="Введите ФИО пациента"
            />
            {query && (
              <ClearIcon
                onClick={() => {
                  setQuery('');
                }}
                className={classNames(
                  styles.AdminMfoSend_Content_Search_Icon,
                  styles.AdminMfoSend_Content_Search_Icon_Clear
                )}
              />
            )}
          </form>
          <Box className={classNames(styles.AdminMfoSend_Content_Patients)}>
            <>
              {!(loading && errors) && query && query.length && patients.length === 0 && (
                <Box className={styles.AdminMfoSend_Label}>По вашему запросу ничего не найдено</Box>
              )}
              {patients.map((patient: Patient.PatientAutocomplete.Item, index: number) => (
                <Box key={index} className={classNames(styles.AdminMfoSend_Content_Patients_Patient)}>
                  <Box className={classNames(styles.AdminMfoSend_Content_Patients_Patient_Profile)}>
                    <Avatar
                      className={styles.AdminMfoSend_Content_Patients_Patient_Profile_Img}
                      src={decodeAvatarResource(patient.avatar, 360, 'MALE')}
                    />
                    <Box className={classNames(styles.AdminMfoSend_Content_Patients_Patient_Profile_Name)}>
                      {patient.firstName} {patient.lastName}
                    </Box>
                  </Box>
                  <CustomCheckbox
                    className={styles.AdminMfoSend_Content_Patients_Patient_CustomCheckbox}
                    checked={patientId === patient.id}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setPatientId(patient.id);
                      } else {
                        setPatientId(null);
                      }
                    }}
                  />
                </Box>
              ))}
              {!(loading || errors) && hasMore && (
                <Box p={2}>
                  <Button color="primary" size="sm" block onClick={() => getPatients()}>
                    Показать еще
                  </Button>
                </Box>
              )}
              {(loading || errors) && (
                <Box p={2} width="100%">
                  <ContentLoading isLoading={loading} isError={errors} fetchData={() => getPatients(false, page)} />
                </Box>
              )}
            </>
          </Box>
          <Box width="100%" display="flex" justifyContent="space-between" alignItems="center" p={2}>
            <Grid container item spacing={1} xs={12} lg={6}>
              <Button color="default" block onClick={onClose}>
                Отмена
              </Button>
            </Grid>
            <Grid container item spacing={1} xs={12} lg={6}>
              <Button
                type="submit"
                disabled={!(patientId && servicePackageId)}
                color="primary"
                onClick={submit}
                isLoading={loadingSend}
                block
              >
                Отправить
              </Button>
            </Grid>
          </Box>
        </ContentCard.Main>
      </ContentCard>
    </Dialog>
  );
};
export default AdminMfoSend;
