import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import InfiniteScroll from 'react-infinite-scroller';
import { Box } from '@material-ui/core';
import { connect } from 'react-redux';
import { parseISO } from 'date-fns';
import { toast } from 'react-toastify';
import Manage from '../../../../types/manage';
import styles from './ReportsPageAdminCloseDaySummaryItems.module.sass';
import SelectInput from '../../../../components/Forms/SelectInput';
import Button from '../../../../components/Forms/Button';
import { ManageReportCloseDaySummaryReducerInitialSettings } from '../../../../store/reducers/manageReportCloseDaySummaryReducer.reducer';
import { fetchManageReportCDSummary } from '../../../../store/actions/manageReportCloseDaySummary.actions';
import ContentLoading from '../../../../components/ContentLoading';
import { format } from '../../../../utils/date';
import { getReportCDSummaryAsExcel } from '../../../../api/findReportCDSummary';

interface PropsInterface {
  loading: boolean;
  errors: any;
  page: number;
  pageSize: number;
  hasMore: boolean;
  items: Manage.CloseDaySummary.Item[];
  fetchManageReportCDSummary: (page?: number, pageSize?: number, reset?: boolean) => void;
  type: any;
  types: any;
  onChangeType: (type: any) => void;
}

function round(num, decimalPlaces = 0) {
  if (num < 0) return -round(-num, decimalPlaces);
  const p = Math.pow(10, decimalPlaces);
  const n = num * p;
  const f = n - Math.floor(n);
  const e = Number.EPSILON * n;

  // Determine whether this fraction is a midpoint value.
  return f >= 0.5 - e ? Math.ceil(n) / p : Math.floor(n) / p;
}

const ReportsPageAdminCloseDaySummaryItems: React.FC<PropsInterface> = (props) => {
  const {
    loading,
    errors,
    page,
    pageSize,
    hasMore,
    items,
    fetchManageReportCDSummary,
    type,
    types,
    onChangeType,
  } = props;

  const [loadingReport, setLoadingReport] = useState(false);
  const [force, setForce] = useState(false);

  useEffect(() => onLoadMore(true), []);

  const onReportAsExcel = () => {
    setLoadingReport(true);
    getReportCDSummaryAsExcel(page, null, force)
      .then((data) => {
        setLoadingReport(false);
        const fileURL = URL.createObjectURL(data.data);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = `CloseDaySummaryReport_${format(new Date(), 'yyyyMMddHHmm')}.xlsx`;
        link.click();
        setTimeout(() => link.remove(), 300);
      })
      .catch(() => {
        setLoadingReport(false);
        toast.error('Ошибка при запросе');
      });
  };

  const onLoadMore = useCallback(
    (reset?: boolean): void => {
      console.log('load more elements');
      fetchManageReportCDSummary(reset ? 0 : page + 1, pageSize, reset);
    },
    [pageSize, page]
  );

  return (
    <>
      <Box className={classNames(styles.ReportsPageAdminCloseDaySummaryItems)}>
        <Box
          className={classNames(
            styles.ReportsPageAdminCloseDaySummaryItems_Section,
            styles.ReportsPageAdminCloseDaySummaryItems_Toolbar
          )}
        >
          <Box className={classNames(styles.ReportsPageAdminCloseDaySummaryItems_Toolbar_Types)}>
            <Box width="100%">
              <SelectInput
                block
                value={types.find((t) => t.value === type)}
                options={types}
                placeholder="Тип документа"
                onChange={(event) => onChangeType(event.value)}
              />
            </Box>
            <Button
              size="md"
              isLoading={loadingReport}
              disabled={loadingReport}
              className="ml-5"
              color="primary"
              onClick={onReportAsExcel}
            >
              Скачать в Excel
            </Button>
          </Box>
          <Box className={classNames(styles.ReportsPageAdminCloseDaySummaryItems_Toolbar_Types)}>
            <Button
              className="mr-2"
              size="sm"
              color={force ? 'primary' : 'default'}
              onClick={() => {
                setForce(!force);
              }}
            >
              {force ? 'Обновить' : 'Не обновлять'}
            </Button>
          </Box>
        </Box>
        <Box
          className={classNames(
            styles.ReportsPageAdminCloseDaySummaryItems_Section,
            styles.ReportsPageAdminCloseDaySummaryItems_Content
          )}
        >
          <InfiniteScroll
            className={styles.ReportsPageAdminCloseDaySummaryItems_InfiniteScroll}
            pageStart={0}
            hasMore={loading ? false : hasMore}
            initialLoad={false}
            threshold={pageSize}
            loadMore={() => onLoadMore()}
            loader={null}
          >
            <>
              {items.length === 0 && !(loading || errors) && <Box>Транзакций по вашему запросу не найдено</Box>}
              {/* Display list content */}
              {items.length > 0 && (
                <Box className={classNames(styles.ReportsPageAdminCloseDaySummaryItems_Items)}>
                  {items.map((item: Manage.CloseDaySummary.Item, index: number) => (
                    <Box className={classNames(styles.ReportsPageAdminCloseDaySummaryItems_Items_Item)} key={index}>
                      <Box className={classNames(styles.ReportsPageAdminCloseDaySummaryItems_Items_Item_Name)}>
                        ID:&nbsp;<Box color="#1373ff">{item?.id || '-'}</Box>
                      </Box>
                      <Box className={classNames(styles.ReportsPageAdminCloseDaySummaryItems_Items_Item_Label)}>
                        Операционный день:&nbsp;
                        {item?.operationDay ? format(parseISO(item.operationDay.toString()), 'dd.MM.yyyy') : '-'}
                      </Box>
                      <Box className={classNames(styles.ReportsPageAdminCloseDaySummaryItems_Items_Item_Label)}>
                        Время последнего обновления:&nbsp;
                        {item?.updatedAt ? format(parseISO(item.updatedAt.toString()), 'dd.MM.yyyy HH:mm') : '-'}
                      </Box>
                      <Box className={classNames(styles.ReportsPageAdminCloseDaySummaryItems_Items_Item_Label)}>
                        Продано (кол-во):&nbsp;{item?.saleCount}
                      </Box>
                      <Box className={classNames(styles.ReportsPageAdminCloseDaySummaryItems_Items_Item_Label)}>
                        Займ погашен (кол-во):&nbsp;{item?.loanPaidCount}
                      </Box>
                      <Box className={classNames(styles.ReportsPageAdminCloseDaySummaryItems_Items_Item_Label)}>
                        Займ погашен от продаж (%):&nbsp;
                        {item?.loanPaidPercent ? round(item.loanPaidPercent * 100, 4) : 0}
                      </Box>
                      <Box className={classNames(styles.ReportsPageAdminCloseDaySummaryItems_Items_Item_Label)}>
                        Активировано (кол-во):&nbsp;{item?.activatedCount}
                      </Box>
                      <Box className={classNames(styles.ReportsPageAdminCloseDaySummaryItems_Items_Item_Label)}>
                        Активировано от продаж (%):&nbsp;
                        {item?.activatedPercent ? round(item.activatedPercent * 100, 4) : 0}
                      </Box>
                      <Box className={classNames(styles.ReportsPageAdminCloseDaySummaryItems_Items_Item_Label)}>
                        Платежи (кол-во):&nbsp;{item?.totalAgentPaymentsCount}
                      </Box>
                      <Box className={classNames(styles.ReportsPageAdminCloseDaySummaryItems_Items_Item_Label)}>
                        Платежи от продаж (%):&nbsp;{item?.paymentsPercent ? round(item.paymentsPercent * 100, 4) : 0}
                      </Box>
                      <Box className={classNames(styles.ReportsPageAdminCloseDaySummaryItems_Items_Item_Label)}>
                        Возвраты (кол-во):&nbsp;{item?.totalRefundCount}
                      </Box>
                      <Box className={classNames(styles.ReportsPageAdminCloseDaySummaryItems_Items_Item_Label)}>
                        Возвраты от платежей (%):&nbsp;
                        {item?.refundCountPercent ? round(item.refundCountPercent * 100, 4) : 0}
                      </Box>
                      <Box className={classNames(styles.ReportsPageAdminCloseDaySummaryItems_Items_Item_Label)}>
                        Закрыто (кол-во):&nbsp;{item?.closedCount}
                      </Box>
                      <Box className={classNames(styles.ReportsPageAdminCloseDaySummaryItems_Items_Item_Label)}>
                        Закрыто от продаж (%):&nbsp;{item?.closedPercent ? round(item.closedPercent * 100, 4) : 0}
                      </Box>
                      <Box className={classNames(styles.ReportsPageAdminCloseDaySummaryItems_Items_Item_Label)}>
                        Отменено (кол-во):&nbsp;{item?.canceledCount}
                      </Box>
                      <Box className={classNames(styles.ReportsPageAdminCloseDaySummaryItems_Items_Item_Label)}>
                        Отменено от продаж (%):&nbsp;{item?.canceledPercent ? round(item.canceledPercent * 100, 4) : 0}
                      </Box>
                      <Box className={classNames(styles.ReportsPageAdminCloseDaySummaryItems_Items_Item_Name)}>
                        Начислений (руб.):&nbsp;<Box color="#1373ff">{item?.totalAccrualsAmount || 0} &#8381;</Box>
                      </Box>
                      <Box className={classNames(styles.ReportsPageAdminCloseDaySummaryItems_Items_Item_Label)}>
                        Платежи от начислений (%):&nbsp;
                        {item?.paymentAmountPercent ? round(item.paymentAmountPercent * 100, 4) : 0}
                      </Box>
                      <Box className={classNames(styles.ReportsPageAdminCloseDaySummaryItems_Items_Item_Name)}>
                        Платежи (руб.):&nbsp;<Box color="#1373ff">{item?.totalAgentPaymentsAmount || 0} &#8381;</Box>
                      </Box>
                      <Box className={classNames(styles.ReportsPageAdminCloseDaySummaryItems_Items_Item_Label)}>
                        Возвраты от платежей (%)):&nbsp;
                        {item?.refundAmountPercent ? round(item.refundAmountPercent * 100, 4) : 0}
                      </Box>
                      <Box className={classNames(styles.ReportsPageAdminCloseDaySummaryItems_Items_Item_Name)}>
                        Возвраты (руб.)):&nbsp;<Box color="#1373ff">{item?.totalRefundsAmount || 0} &#8381;</Box>
                      </Box>
                      <Box className={classNames(styles.ReportsPageAdminCloseDaySummaryItems_Items_Item_Name)}>
                        Продано акций (кол-вл)):&nbsp;<Box>{item?.promotionsCount || 0}</Box>
                      </Box>
                    </Box>
                  ))}
                </Box>
              )}
              {(loading || errors) && (
                <Box width="100%" p={2}>
                  <ContentLoading isLoading={loading} isError={errors} fetchData={() => onLoadMore()} />
                </Box>
              )}
            </>
          </InfiniteScroll>
        </Box>
      </Box>
    </>
  );
};

export default connect(
  (state: any) => ({
    ...(state.manageReportCDSummaryReducer as ManageReportCloseDaySummaryReducerInitialSettings),
  }),
  {
    fetchManageReportCDSummary,
  }
)(ReportsPageAdminCloseDaySummaryItems);
