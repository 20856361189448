import React, { useEffect, useCallback, useMemo } from 'react';
import { RootStateOrAny, shallowEqual, useDispatch, useSelector } from 'react-redux';
import { fetchAppointmentView, updateAppointmentViewStatus } from 'store/actions';

import ContentLoading from 'components/ContentLoading';
import ContentCard from 'components/ContentCard';
import { ReactComponent as AngleLeftIcon } from 'assets/img/icons/angle-left-icon.svg';
import { useHistory, useRouteMatch } from 'react-router-dom';
import InfoView from './InfoView';
import ConclusionView from './ConclusionView/ConclusionView';
import { AppointmentStatus } from '../AppointmentDoctor/types';

const AppointmentPage = () => {
  const { appointmentView, appointmentViewLoading, appointmentViewLoadingError } = useSelector(
    (state: RootStateOrAny) => ({
      appointmentView: state?.appointment?.appointmentView,
      appointmentViewLoading: state?.appointment?.appointmentViewLoading,
      appointmentViewLoadingError: state?.appointment?.appointmentViewLoadingError,
    }),
    shallowEqual
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const routeMatch = useRouteMatch<{ appointmentId: string }>();
  const appointmentId = useMemo(() => routeMatch.params?.appointmentId, [routeMatch.params?.appointmentId]);
  const status = useMemo(() => appointmentView?.status?.value, [appointmentView?.status?.value]);
  const id = useMemo(() => appointmentView?.id, [appointmentView?.id]);
  const name = useMemo(
    () =>
      [AppointmentStatus.DONE, AppointmentStatus.WAITING_SENDING, AppointmentStatus.WAITING_FILLING].indexOf(status) >
      -1
        ? appointmentView?.type?.name
        : appointmentView?.status?.name || 'Новая консультация',
    [appointmentView?.status?.name, appointmentView?.type?.name, status]
  );
  const handleGetAppointmentView = useCallback(() => {
    dispatch(fetchAppointmentView(appointmentId));
  }, [appointmentId, dispatch]);
  useEffect(() => {
    handleGetAppointmentView();
  }, [handleGetAppointmentView]);
  const checkAppointmentStatusUpdate = useCallback(() => {
    updateAppointmentViewStatus(appointmentId);
  }, [appointmentId]);
  useEffect(() => {
    const appointmentCheckInterval =
      id === appointmentId && status === AppointmentStatus.AWAITS
        ? setInterval(checkAppointmentStatusUpdate, 3000)
        : null;

    return () => {
      appointmentCheckInterval && clearInterval(appointmentCheckInterval);
    };
  }, [appointmentId, appointmentView, checkAppointmentStatusUpdate, id, status]);

  const getAppointmentView = useMemo(() => {
    switch (status) {
      case AppointmentStatus.AWAITS:
        return <InfoView appointment={appointmentView} />;

      case AppointmentStatus.IN_PROGRESS:
        return <InfoView appointment={appointmentView} />;

      case AppointmentStatus.CANCELED:
        return <InfoView appointment={appointmentView} />;

      case AppointmentStatus.FAILED:
        return <InfoView appointment={appointmentView} />;

      case AppointmentStatus.DONE:
      case AppointmentStatus.WAITING_FILLING:
      case AppointmentStatus.WAITING_SENDING:
        return <ConclusionView appointment={appointmentView} />;

      case AppointmentStatus.FINISHED:
        return <InfoView appointment={appointmentView} />;
      default:
        return <></>;
    }
  }, [appointmentView, status]);

  return (
    <ContentCard>
      <ContentCard.Header>
        <div className="row">
          <div className="col-xs-4 text-center text-xs-left mb-2 mb-xs-0">
            <div className="page-header-back-link" onClick={() => history.push('/appointments')}>
              <AngleLeftIcon className="page-header-back-link-icon" />
              Назад
            </div>
          </div>
          <div className="col-xs-8 col-md-4 text-center text-xs-right text-md-center">
            <h6 className="page-header-title">{name}</h6>
          </div>
        </div>
      </ContentCard.Header>

      <ContentCard.Main>
        <ContentLoading
          isLoading={appointmentViewLoading}
          isError={appointmentViewLoadingError}
          fetchData={handleGetAppointmentView}
        >
          {getAppointmentView}
        </ContentLoading>
      </ContentCard.Main>
    </ContentCard>
  );
};

export default AppointmentPage;
