import React, { FormEvent, useCallback, useMemo, useState } from 'react';
import { validateFieldOnChange } from 'utils';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { changeSetting } from 'store/actions';
import { toast } from 'react-toastify';
import { putAppointmentSubject, appointmentAttachResource } from 'api';
import FilesForm from 'components/FilesForm';
import Button from 'components/Forms/Button';
import FormValidator from 'components/Forms/FormValidator';
import InputField from 'components/Forms/InputField';

import { useHistory } from 'react-router-dom';
import styles from '../PaymentPage.module.sass';

const InfoView = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const newAppointment = useSelector((state: RootStateOrAny) => state?.storage?.newAppointment);
  const [patientSubjectForm, setPatientSubjectForm] = useState({
    subject: '',
  });

  const [patientSubjectFormErrors, setPatientSubjectFormErrors] = useState([]);

  const [patientSubjectSubmitLoading, setPatientSubjectSubmitLoading] = useState(false);

  const [fileIds, setFileIds] = useState([]);

  const handleChangeSetting = useCallback(
    (key: string, value: any) => {
      dispatch(changeSetting(key, value));
    },
    [dispatch]
  );

  const handleChangeFileIds = React.useCallback((ids: string[]) => {
    setFileIds(ids.slice(0, 5));
  }, []);

  const validateOnChange = (name: string, value: any, event, element?) => {
    validateFieldOnChange(
      name,
      value,
      event,
      patientSubjectForm,
      setPatientSubjectForm,
      patientSubjectFormErrors,
      setPatientSubjectFormErrors,
      element
    );
  };

  const patientSubjectFormSubmit = useCallback(
    async (e: FormEvent<any>) => {
      e.preventDefault();

      const form: any = e.target;
      const inputs = [...form?.elements].filter((i) => ['INPUT', 'SELECT', 'TEXTAREA'].includes(i.nodeName));

      const { errors, hasError } = FormValidator.bulkValidate(inputs);

      setPatientSubjectFormErrors([...errors]);

      if (!hasError) {
        let errorResponse = false;

        setPatientSubjectSubmitLoading(true);

        await appointmentAttachResource(newAppointment.appointmentId, fileIds).catch(() => {
          errorResponse = true;
          setPatientSubjectSubmitLoading(false);
          toast.error('Ошибка при загрузке файла');
        });

        if (errorResponse) return false;

        putAppointmentSubject(newAppointment.appointmentId, patientSubjectForm.subject)
          .then(() => {
            setPatientSubjectSubmitLoading(false);

            toast.success('Обращение успешно зарегистрировано!');

            handleChangeSetting('newAppointment', {
              doctorId: null,
              date: null,
              price: null,
              name: null,
              type: null,
              spec: null,
              appointmentId: null,
              paymentViewType: 'PAYMENT',
              withFiles: false,
            });

            history.push(
              newAppointment.type === 'IMMEDIATE' ? `/appointments/${newAppointment.appointmentId}` : '/appointments'
            );
          })
          .catch(() => {
            setPatientSubjectSubmitLoading(false);

            toast.error('Ошибка при регистрации обращения');
          });
      }
    },
    [
      fileIds,
      handleChangeSetting,
      history,
      newAppointment.appointmentId,
      newAppointment.type,
      patientSubjectForm.subject,
    ]
  );

  const isWithFiles = useMemo(() => !!newAppointment?.withFiles, [newAppointment]);

  return (
    <div className={styles.paymentRequestInfo}>
      <h4 className={styles.paymentRequestInfo_title}>Что Вас беспокоит?</h4>
      <p className={styles.paymentRequestInfo_subtitle}>
        Для проведения качественной консультации укажите Ваши жалобы и приложите необходимые файлы
      </p>

      <form onSubmit={patientSubjectFormSubmit}>
        <InputField
          type="textarea"
          name="subject"
          value={patientSubjectForm.subject}
          onChange={(e) => validateOnChange('subject', e.target.value, e)}
          block
          filled
          placeholder="Жалоба"
        />

        {isWithFiles && (
          <div className="mt-4">
            <FilesForm
              onLoadFiles={handleChangeFileIds}
              files={[]}
              fileIds={fileIds}
              disabled={fileIds.length >= 5}
              maxFiles={5}
            />
          </div>
        )}

        <Button className="mt-5" type="submit" color="primary" block isLoading={patientSubjectSubmitLoading}>
          Продолжить
        </Button>
      </form>
    </div>
  );
};

export default InfoView;
