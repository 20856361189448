import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Box } from '@material-ui/core';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import EventIcon from '@material-ui/icons/Event';
import InfiniteScroll from 'react-infinite-scroller';
import { parseISO } from 'date-fns';
import Manage from '../../../../types/manage';
import styles from './ReportPageAdminServicePackageTransactionItems.module.sass';
import { ManageReportServicePackagesReducerInitialSettings } from '../../../../store/reducers/manageReportServicePackagesReducer.reducer';
import SelectInput from '../../../../components/Forms/SelectInput';
import Button from '../../../../components/Forms/Button';
import {
  getPatientServicePackageTransactionReport,
  getPatientServicePackageTransactionReportAsCsv,
} from '../../../../api/getPatientServicePackageTransactionReport';
import { format } from '../../../../utils/date';
import DropdownToggle from '../../../../components/DropdownMenu/DropdownToggle';
import DropdownContent from '../../../../components/DropdownMenu/DropdownContent';
import CalendarRange from '../../../../components/CalendarRange';
import DropdownMenu from '../../../../components/DropdownMenu';
import { fetchManageReportPatientServicePackageTransactions } from '../../../../store/actions';
import ContentLoading from '../../../../components/ContentLoading';
import { ManageReportPatientReducerInitialSettings } from '../../../../store/reducers/manageReportPatientReducer.reducer';
import ReportItemUtils from '../utils/ReportItemUtils';
import ReportDropdownMenuComponent from '../../../../components/ReportDropdownMenu';
import ReportDropdownMenu from '../../../../components/ReportDropdownMenu/ReportDropdownMenu';

interface PropsInterface {
  loading: boolean;
  errors: any;
  page: number;
  pageSize: number;
  hasMore: boolean;
  items: Manage.ServicePackages.Transaction.Item[];
  fetchManageReportPatientServicePackageTransactions: (
    statuses?: string[],
    types?: string[],
    createdFrom?: Date,
    createdTo?: Date,
    page?: number,
    pageSize?: number,
    reset?: boolean
  ) => void;
  type: any;
  types: any;
  onChangeType: (type: any) => void;
}

const ReportPageAdminServicePackageTransactionItems = (props: PropsInterface) => {
  const {
    loading,
    items,
    errors,
    page,
    pageSize,
    hasMore,
    fetchManageReportPatientServicePackageTransactions,
    type,
    types,
    onChangeType,
  } = props;

  const [loadingReport, setLoadingReport] = useState(false);
  const [date, setDate] = useState<{ start: Date; end: Date }>({ start: null, end: null });
  const [selectedStatuses, setSelectedStatuses] = useState<Array<string>>(['NORMAL']);
  const [selectedTypes, setSelectedTypes] = useState<Array<string>>(['CLIENT_AGENT_PAYMENT']);
  const [toolbarDateOpen, toggleToolbarDateOpen] = useState<boolean>(false);
  const [isStatusMenuOpened, setStatusMenuOpened] = useState<boolean>(false);
  const [isTypeMenuOpened, setTypeMenuOpened] = useState<boolean>(false);

  useEffect(() => onLoadMore(true), [date, selectedStatuses, selectedTypes]);

  const onTransactionsReportAsExcel = () => {
    setLoadingReport(true);
    getPatientServicePackageTransactionReportAsCsv(selectedStatuses, selectedTypes, date.start, date.end, page)
      .then((data) => {
        setLoadingReport(false);
        const fileURL = URL.createObjectURL(data.data);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = `PatientServicePackagesTransactionReport_${format(new Date(), 'yyyyMMddHHmm')}.csv`;
        link.click();
        setTimeout(() => link.remove(), 300);
      })
      .catch(() => {
        setLoadingReport(false);
        toast.error('Ошибка при запросе');
      });
  };

  const onLoadMore = useCallback(
    (reset?: boolean): void => {
      fetchManageReportPatientServicePackageTransactions(
        selectedStatuses,
        selectedTypes,
        date.start,
        date.end,
        reset ? 0 : page + 1,
        pageSize,
        reset
      );
    },
    [pageSize, date, selectedStatuses, selectedTypes, page]
  );

  return (
    <>
      <Box className={classNames(styles.ReportsPageAdminServicePackageTransactionItems)}>
        <Box
          className={classNames(
            styles.ReportsPageAdminServicePackageTransactionItems_Section,
            styles.ReportsPageAdminServicePackageTransactionItems_Toolbar
          )}
        >
          <Box className={classNames(styles.ReportsPageAdminServicePackageTransactionItems_Toolbar_Types)}>
            <Box width="100%">
              <SelectInput
                block
                value={types.find((t) => t.value === type)}
                options={types}
                placeholder="Тип документа"
                onChange={(event) => onChangeType(event.value)}
              />
            </Box>
            <Button
              size="md"
              isLoading={loadingReport}
              disabled={loadingReport}
              className="ml-5"
              color="primary"
              onClick={onTransactionsReportAsExcel}
            >
              Скачать в Csv
            </Button>
          </Box>
          <Box className={classNames(styles.ReportsPageAdminServicePackageTransactionItems_Toolbar_Filters)}>
            <Box className={styles.ReportsPageAdminServicePackageTransactionItems_Toolbar_Buttons}>
              <ReportDropdownMenu
                items={ReportItemUtils.getTransactionStatusOptions}
                selectIndexes={[0]}
                isOpen={isStatusMenuOpened}
                title="Статусы"
                onToggle={setStatusMenuOpened}
                onSelectChanged={setSelectedStatuses}
              />
              <ReportDropdownMenu
                items={ReportItemUtils.transactionTypeOptions}
                selectIndexes={[1]}
                isOpen={isTypeMenuOpened}
                title="Типы"
                onToggle={setTypeMenuOpened}
                onSelectChanged={setSelectedTypes}
              />
              <DropdownMenu isOpen={toolbarDateOpen} toggle={() => toggleToolbarDateOpen(!toolbarDateOpen)}>
                <DropdownToggle>
                  <Button color="primary" size="md">
                    <EventIcon />
                    {(date.start || date.end) &&
                      [date.start, date.end]
                        .filter((v) => v)
                        .map((v) => format(v, 'dd.MM.yyyy'))
                        .join(' - ')}
                    {(date.start || date.end) && (
                      <Box className={styles.ReportsPageAdminServicePackageTransactionItems_Toolbar_Buttons_Status} />
                    )}
                  </Button>
                </DropdownToggle>
                <DropdownContent
                  className={classNames(
                    styles.ReportsPageAdminServicePackageTransactionItems_Toolbar_DropdownContent,
                    styles.ReportsPageAdminServicePackageTransactionItems_Toolbar_DropdownContent_Calendar
                  )}
                >
                  <CalendarRange date={date} onChangeDate={(date: { start: Date; end: Date }) => setDate(date)} />
                  {(date.start || date.end) && (
                    <Box
                      className={styles.ReportsPageAdminServicePackageTransactionItems_Toolbar_DropdownContent_Reset}
                    >
                      <Button
                        color="default"
                        block
                        size="md"
                        onClick={(event) => {
                          event.preventDefault();
                          setDate({ start: null, end: null });
                        }}
                      >
                        Сбросить фильтр
                      </Button>
                    </Box>
                  )}
                </DropdownContent>
              </DropdownMenu>
            </Box>
          </Box>
        </Box>
        <Box
          className={classNames(
            styles.ReportsPageAdminServicePackageTransactionItems_Section,
            styles.ReportsPageAdminServicePackageTransactionItems_Content
          )}
        >
          <InfiniteScroll
            className={styles.ReportsPageAdminServicePackageTransactionItems_InfiniteScroll}
            pageStart={0}
            hasMore={loading ? false : hasMore}
            initialLoad={false}
            threshold={pageSize}
            loadMore={() => onLoadMore()}
            loader={null}
          >
            <>
              {items.length === 0 && !(loading || errors) && <Box>Транзакций по вашему запросу не найдено</Box>}
              {/* Display list content */}
              {items.length > 0 && (
                <Box className={classNames(styles.ReportsPageAdminServicePackageTransactionItems_Items)}>
                  {items.map((item: Manage.ServicePackages.Transaction.Item, index: number) => (
                    <Box
                      className={classNames(styles.ReportsPageAdminServicePackageTransactionItems_Items_Item)}
                      key={index}
                    >
                      <Box
                        className={classNames(styles.ReportsPageAdminServicePackageTransactionItems_Items_Item_Name)}
                      >
                        ID:&nbsp;<Box color="#1373ff">{item?.id || '-'}</Box>
                      </Box>
                      <Box
                        className={classNames(styles.ReportsPageAdminServicePackageTransactionItems_Items_Item_Info)}
                      >
                        Описание транзакции
                      </Box>
                      <Box
                        className={classNames(styles.ReportsPageAdminServicePackageTransactionItems_Items_Item_Label)}
                      >
                        Идентификатор:&nbsp;{item?.idempotentKey ? item.idempotentKey : '-'}
                      </Box>
                      <Box
                        className={classNames(styles.ReportsPageAdminServicePackageTransactionItems_Items_Item_Label)}
                      >
                        Сумма:&nbsp;<Box color="#1373ff">{item?.amount || 0}&#8381;</Box>
                      </Box>
                      <Box
                        className={classNames(styles.ReportsPageAdminServicePackageTransactionItems_Items_Item_Label)}
                      >
                        Время создания:&nbsp;
                        {item?.createdAt ? format(parseISO(item.createdAt.toString()), 'dd.MM.yyyy HH:mm') : '-'}
                      </Box>
                      <Box
                        className={classNames(styles.ReportsPageAdminServicePackageTransactionItems_Items_Item_Label)}
                      >
                        Операционный день:&nbsp;
                        {item?.operationDay ? format(parseISO(item.operationDay.toString()), 'dd.MM.yyyy') : '-'}
                      </Box>
                      <Box
                        className={classNames(styles.ReportsPageAdminServicePackageTransactionItems_Items_Item_Label)}
                      >
                        Время последнего обновления:&nbsp;
                        {item?.updatedAt ? format(parseISO(item.updatedAt.toString()), 'dd.MM.yyyy HH:mm') : '-'}
                      </Box>
                      <Box
                        className={classNames(styles.ReportsPageAdminServicePackageTransactionItems_Items_Item_Label)}
                      >
                        Пользовательский тариф:&nbsp;<Box color="#1373ff">{item?.patientServicePackageId}</Box>
                      </Box>
                      <Box
                        className={classNames(styles.ReportsPageAdminServicePackageTransactionItems_Items_Item_Label)}
                      >
                        Базовый тариф:&nbsp;{item?.name}&nbsp;<Box color="#1373ff">{item?.servicePackageId}</Box>
                      </Box>
                      <Box
                        className={classNames(styles.ReportsPageAdminServicePackageTransactionItems_Items_Item_Label)}
                      >
                        Статус:&nbsp;<Box color="#00C12F">{item?.status?.name || '-'}</Box>
                      </Box>
                      <Box
                        className={classNames(styles.ReportsPageAdminServicePackageTransactionItems_Items_Item_Label)}
                      >
                        Тип:&nbsp;<Box color="#00C12F">{item?.type?.name || '-'}</Box>
                      </Box>
                      {item?.patient && (
                        <>
                          <Box
                            className={classNames(
                              styles.ReportsPageAdminServicePackageTransactionItems_Items_Item_Label
                            )}
                          >
                            Пациент:&nbsp;{item.patient?.lastName} {item.patient?.firstName} {item.patient?.middleName}{' '}
                            ({item.patient?.id || '-'})
                          </Box>
                          <Box
                            className={classNames(
                              styles.ReportsPageAdminServicePackageTransactionItems_Items_Item_Label
                            )}
                          >
                            Внешний ИД пользователя:&nbsp;{item?.externalUserId || '-'}
                          </Box>
                        </>
                      )}
                      {item?.createdBy && (
                        <Box
                          className={classNames(styles.ReportsPageAdminServicePackageTransactionItems_Items_Item_Label)}
                        >
                          Создал:&nbsp;
                          <Box color="#1373ff">
                            {item.createdBy?.lastName} {item.createdBy?.firstName} {item.createdBy?.middleName}
                          </Box>
                          {item.createdBy?.role && (
                            <>
                              &nbsp;<Box color="#00C12F">{ReportItemUtils.getLabelRole(item?.createdBy?.role)}</Box>
                            </>
                          )}
                        </Box>
                      )}
                    </Box>
                  ))}
                </Box>
              )}
              {(loading || errors) && (
                <Box width="100%" p={2}>
                  <ContentLoading isLoading={loading} isError={errors} fetchData={() => onLoadMore()} />
                </Box>
              )}
            </>
          </InfiniteScroll>
        </Box>
      </Box>
    </>
  );
};

export default connect(
  (state: any) => ({
    ...(state.manageReportPatientServicePackageTransactions as ManageReportPatientReducerInitialSettings),
  }),
  {
    fetchManageReportPatientServicePackageTransactions,
  }
)(ReportPageAdminServicePackageTransactionItems);
