import { DoctorStatus, UserMeta } from 'types';
import Doctor, { AvailabilityStatus } from '../types/doctor';
import Chat from "../types/chat";

export * from './axios';
export * from './helpers';
export * from './validateFieldOnChange';
export * from './hasFieldError';
export * from './getFieldErrors';

export const convertToUserData = (data?: any): UserMeta => ({
  id: data.id,
  phone: data.phone,
  avatar: data.avatar,
  sex: data.sex,
  displayName: data.displayName,
  balance: data.balance,
});

export const getNameStatusFromDoctorByStatus = (data: AvailabilityStatus): string => {
  switch (data) {
    case AvailabilityStatus.DUTY:
      return 'На смене';
    case AvailabilityStatus.BUSY:
      return 'Занят';
    case AvailabilityStatus.NOT_DUTY:
      return 'Нет на смене';
    case AvailabilityStatus.AWAY:
      return 'Не на месте';
    default:
      return '';
  }
};

export const getDisplayName = (firstName?: string, lastName?: string, middleName?: string): string => {
  const res: string[] = [];
  if (lastName) {
    res.push(`${lastName}`);
  }
  if (firstName) {
    res.push(` ${firstName[0].toUpperCase()}.`);
  }
  if (middleName) {
    res.push(` ${middleName[0].toUpperCase()}.`);
  }
  return res.join('');
};

export const convertToChatMessage = (message: any): Chat.Message => {
  const {
    authorId,
    id,
    readed,
    resourceIds = [],
    roomId,
    text,
    type,
  } = message;

  let timestamp = null;
  if(message.timestamp) {
    timestamp = new Date()
    timestamp.setTime(message.timestamp?.seconds * 1000);
  }
  return {
    id,
    readed,
    authorId,
    resourceIds,
    roomId,
    text,
    timestamp,
    type,
  } as Chat.Message;
}
