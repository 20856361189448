import React from 'react';
import { Backdrop, Box, Dialog } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import Button from '../Forms/Button';
import styles from './SchedulerDelete.module.sass';
import { Scheduler } from '../../types';
import ContentCard from '../ContentCard';

export interface SchedulerDeleteInterface {
  loading?: boolean;
  isAdmin?: boolean;
  scheduler?: Scheduler;
  onClose: () => void;
  onDelete: (scheduler: Scheduler) => void;
}

const SchedulerDelete = (props: SchedulerDeleteInterface) => {
  const { scheduler, loading = false, isAdmin = false, onDelete = () => {}, onClose = () => {} } = props;
  return (
    <Dialog
      PaperProps={{ className: classNames(styles.Dialog) }}
      BackdropComponent={(props) => <Backdrop className={styles.Backdrop} {...props} />}
      onClose={onClose}
      open
    >
      <ContentCard className={classNames(['Modal', styles.SchedulerDelete])}>
        <ContentCard.Header className={classNames(['Modal-header', styles.SchedulerDelete_Header])}>
          <h6 className="page-header-title">Удалить смену</h6>
          <CloseIcon onClick={onClose} className={classNames(['Modal-close', styles.SchedulerDelete_CloseIcon])} />
        </ContentCard.Header>
        <ContentCard.Main className={classNames(['Modal-content', styles.SchedulerDelete_Content])}>
          <Box component="p" width="100%" className="align-items-center justify-content-start d-flex p-3">
            Вы уверены что хотите удалить {isAdmin ? '' : 'вашу'} смену?
          </Box>
          <Box width="100%" className="d-flex justify-content-between align-items-center p-3">
            <Button type="button" block size="md" className="mr-1" onClick={onClose} color="default">
              Отмена
            </Button>
            <Button
              type="button"
              block
              size="md"
              className="ml-1"
              disabled={loading}
              isLoading={loading}
              onClick={() => onDelete(scheduler)}
              color="primary"
            >
              Удалить
            </Button>
          </Box>
        </ContentCard.Main>
      </ContentCard>
    </Dialog>
  );
};
export default SchedulerDelete;
