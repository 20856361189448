import React from 'react';
import { format, parseISO } from 'date-fns';
import { Box } from '@material-ui/core';
import styles from './ChatPatientData.module.sass';

export interface PropsInterface {
  patient: any;
}

const ChatPatientData = (props: PropsInterface) => {
  const { patient } = props;

  return (
    <Box className={styles.root}>
      <Box className={styles.root_label}>Персональные данные</Box>
      <Box className={styles.root_item}>
        <div className={styles.root_item_name}>Фамилия</div>
        <div className={styles.root_item_value}>{patient?.lastName}</div>
      </Box>
      <div className={styles.root_item}>
        <div className={styles.root_item_name}>Имя</div>
        <div className={styles.root_item_value}>{patient?.firstName}</div>
      </div>
      <div className={styles.root_item}>
        <div className={styles.root_item_name}>Отчество</div>
        <div className={styles.root_item_value}>{patient?.middleName}</div>
      </div>
      <div className={styles.root_item}>
        <div className={styles.root_item_name}>Дата рождения</div>
        <div className={styles.root_item_value}>
          {patient?.birthday ? format(parseISO(patient?.birthday), 'dd.MM.yyyy') : '-'}
        </div>
      </div>
      <div className={styles.root_item}>
        <div className={styles.root_item_name}>Пол</div>
        <div className={styles.root_item_value}>
          {patient?.sex?.value === 'MALE' ? 'Мужской' : patient?.sex?.value === 'FEMALE' ? 'Женский' : '-'}
        </div>
      </div>
      <div className={styles.root_item}>
        <div className={styles.root_item_name}>Телефон</div>
        <div className={styles.root_item_value}>{patient?.phone ? `+${patient?.phone}` : '-'}</div>
      </div>
      <div className={styles.root_label}>Медицинские данные</div>
      <div className={styles.root_item}>
        <div className={styles.root_item_name}>Рост</div>
        <div className={styles.root_item_value}>{patient?.heightCm ? `${patient?.heightCm} см` : '-'}</div>
      </div>
      <div className={styles.root_item}>
        <div className={styles.root_item_name}>Вес</div>
        <div className={styles.root_item_value}>{patient?.weightKilo ? `${patient?.weightKilo} кг` : '-'}</div>
      </div>
      <div className={styles.root_item}>
        <div className={styles.root_item_name}>Инвалидность</div>
        <div className={styles.root_item_value}>{patient?.disability ? patient?.disability : '-'}</div>
      </div>
      <div className={styles.root_item}>
        <div className={styles.root_item_name}>Резус фактор</div>
        <div className={styles.root_item_value}>{patient?.blood ? patient?.blood : '-'}</div>
      </div>
      <div className={styles.root_item}>
        <div className={styles.root_item_name}>Аллергические реакции</div>
        <div className={styles.root_item_value}>{patient?.allergy ? patient?.allergy : '-'}</div>
      </div>
      <div className={styles.root_item}>
        <div className={styles.root_item_name}>Хронические заболевания</div>
        <div className={styles.root_item_value}>{patient?.chronicIllness ? patient?.chronicIllness : '-'}</div>
      </div>
    </Box>
  );
};

export default ChatPatientData;
