import React, { ReactElement, useMemo } from 'react';
import { Box } from '@material-ui/core';
import { useStore } from 'effector-react';
import styles from './styles.module.sass';
import store from '../../store';
import { AgentMetricsDateTypeTypeTranslation } from '../../constants/translations';
import { AgentMetricsDateTypeType } from '../../types';
import { format } from '../../../../../utils/date';

const AgentMetricsTitle = (): ReactElement => {
  const { type, fromDate, toDate } = useStore(store);
  const title = useMemo(() => {
    const res: Array<string> = [];
    switch (type) {
      case AgentMetricsDateTypeType.NOW:
      case AgentMetricsDateTypeType.TOMORROW:
        res.push(`${AgentMetricsDateTypeTypeTranslation[type]}:`);
        res.push(`${format(fromDate, 'd MMMM yyyy')}`);
        break;
      case AgentMetricsDateTypeType.WEEK:
      case AgentMetricsDateTypeType.MONTH:
        res.push(`${AgentMetricsDateTypeTypeTranslation[type]}:`);
        res.push(`${format(fromDate, 'dd.MM.yyyy')}-${format(toDate, 'dd.MM.yyyy')}`);
        break;
      default:
        res.push('Дата:');
        res.push(`${format(fromDate, 'd MMMM yyyy')}`);
        break;
    }
    return res.join(' ');
  }, [fromDate, toDate, type]);
  return (
    <>
      <Box className={styles.root}>{title}</Box>
    </>
  );
};

export default AgentMetricsTitle;
