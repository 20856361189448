import {
  MANAGE_SERVICE_PACKAGES_MFO_LOADING,
  MANAGE_SERVICE_PACKAGES_MFO_SUCCESS,
  MANAGE_SERVICE_PACKAGES_MFO_ERRORS,
  MANAGE_SERVICE_PACKAGES_MFO_UPDATE,
} from '../actions';
import Manage from '../../types/manage';

export interface ManageServicePackagesMfoInitialSettings {
  items?: Manage.ServicePackages.Mfo.Item[];
  errors?: any;
  loading?: boolean;
}
const initialSettings: ManageServicePackagesMfoInitialSettings = {
  items: [],
  loading: false,
  errors: null,
};

const manageServicePackagesMfoReducer = (state = initialSettings, action): ManageServicePackagesMfoInitialSettings => {
  switch (action.type) {
    case MANAGE_SERVICE_PACKAGES_MFO_LOADING:
      return {
        ...state,
        items: [],
        loading: action.loading,
      };
    case MANAGE_SERVICE_PACKAGES_MFO_SUCCESS:
      return {
        ...state,
        items: action.items,
        errors: null,
      };
    case MANAGE_SERVICE_PACKAGES_MFO_ERRORS:
      return {
        ...state,
        errors: action.errors,
      };
    case MANAGE_SERVICE_PACKAGES_MFO_UPDATE:
      return {
        ...state,
        items: state.items.map((i) => {
          return i.id === action.item.id
            ? {
                ...i,
                status: {
                  ...i.status,
                  ...action.item.status,
                },
              }
            : i;
        }),
      };
    default:
      return state;
  }
};

export default manageServicePackagesMfoReducer;
