import React, { useEffect } from 'react';
import ContentCard from 'components/ContentCard';
import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import moment from 'moment';
import styles from './TransactionsPage.module.sass';
import ContentLoading from '../../components/ContentLoading';
import { RootState } from '../../store/reducers';
import { fetchTransactions } from '../../store/actions';
import { ReactComponent as AngleLeftIcon } from '../../assets/img/icons/angle-left-icon.svg';
import MyDebt from '../../components/MyDebt';

interface PropsInterface {
  [key: string]: any;
}
const TransactionsPage = (props: PropsInterface) => {
  const {
    history,
    match: {
      params: { servicePackageId },
    },
  } = props;
  const dispatch = useDispatch();
  const {
    pageSizeTransactions,
    pageTransactions,
    transactions,
    errorsTransactions,
    hasMoreTransactions,
    loadingTransactions,
  } = useSelector((state: RootState) => state.profile);
  useEffect(() => {
    getTransactions(true);
  }, []);
  const getTransactions = (reset?: boolean) => {
    dispatch(
      fetchTransactions(servicePackageId, reset ? 0 : reset ? 0 : pageTransactions + 1, pageSizeTransactions, reset)
    );
  };
  return (
    <>
      <ContentCard>
        <ContentCard.Header>
          <div className="row">
            <div className="col-xs-2 text-center text-xs-left mb-2 mb-xs-0">
              <div className="page-header-back-link" onClick={() => history.push(`/packages`)}>
                <AngleLeftIcon className="page-header-back-link-icon" />
                Назад
              </div>
            </div>
            <div className="col-xs-10 col-md-8 text-center text-xs-right text-md-center">
              <h6 className="page-header-title">История начислений и списаний</h6>
            </div>
          </div>
        </ContentCard.Header>
        <ContentCard.Main>
          <>
            <Box
              p={0}
              width="100%"
              height="100%"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <InfiniteScroll
                className={styles.InfiniteScroll}
                pageStart={0}
                hasMore={loadingTransactions ? false : hasMoreTransactions}
                initialLoad={false}
                threshold={pageSizeTransactions}
                loadMore={() => getTransactions()}
                loader={null}
              >
                <>
                  {(transactions || []).map((item: any, index: number) => (
                    <Box
                      key={index}
                      p={2}
                      display="flex"
                      alignItems="center"
                      borderBottom="1px solid #D8D8D8"
                      justifyContent="space-between"
                    >
                      <Box display="flex" flexDirection="column">
                        <Box mb={1} color={item?.type?.value === 'PACKAGE_CHARGE' ? '#FF341A' : '#00C12F'}>
                          {item?.type?.name}
                        </Box>
                        <Box>{item?.operationDay && moment(item?.operationDay).format('DD.MM.yyyy')}</Box>
                      </Box>
                      <Box>
                        <Box color={item?.type?.value === 'PACKAGE_CHARGE' ? '#FF341A' : '#00C12F'}>
                          {item?.type?.value === 'PACKAGE_CHARGE' ? '-' : '+'} {item?.amount} &#8381;
                        </Box>
                      </Box>
                    </Box>
                  ))}
                  {(loadingTransactions || errorsTransactions) && (
                    <Box width="100%" p={2}>
                      <ContentLoading
                        isLoading={loadingTransactions}
                        isError={errorsTransactions}
                        fetchData={() => getTransactions()}
                      />
                    </Box>
                  )}
                </>
              </InfiniteScroll>
            </Box>
          </>
        </ContentCard.Main>
      </ContentCard>
    </>
  );
};
export default TransactionsPage;
