import {
  CHAT_ADD_MESSAGE_ERRORS,
  CHAT_ADD_MESSAGE_LOADING,
  CHAT_ADD_MESSAGE_SUCCESS,
  CHAT_DESTROY,
  CHAT_ERRORS,
  CHAT_LOADING,
  CHAT_MESSAGE_MODIFIED,
  CHAT_MESSAGES_ERRORS,
  CHAT_MESSAGES_LOADING,
  CHAT_MESSAGES_SUCCESS,
  CHAT_SET,
} from '../actions/chat.actions';
import Chat from '../../types/chat';

export interface ChatStateInterface {
  from?: string;
  to?: string;
  token?: string;
  collection: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;
  appointmentId?: string;
  loading?: boolean;
  errors?: any;
  messages?: Chat.Message[];
  loadingMessages?: boolean;
  errorsMessages?: any;
  pageSize?: number;
  offset?: any;
  hasMore?: boolean;
  loadingAddMessage?: boolean;
  errorsAddMessage?: any;
}

const initialSettings: ChatStateInterface = {
  from: null,
  to: null,
  token: null,
  collection: null,
  appointmentId: null,
  loading: false,
  errors: null,
  messages: [],
  loadingMessages: false,
  errorsMessages: null,
  loadingAddMessage: false,
  errorsAddMessage: null,
  offset: null,
  hasMore: true,
  pageSize: 50,
};

const chatReducer = (state = initialSettings, action) => {
  switch (action.type) {
    case CHAT_ADD_MESSAGE_SUCCESS:
      return {
        ...state,
        messages: state.messages.concat(action.message),
        errors: null,
        errorsAddMessage: null,
      };
    case CHAT_ADD_MESSAGE_LOADING:
      return {
        ...state,
        loadingAddMessage: action.loading,
        errorsAddMessage: null,
      };
    case CHAT_ADD_MESSAGE_ERRORS:
      return {
        ...state,
        loadingAddMessage: false,
        errorsAddMessage: action.errors,
      };
    case CHAT_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: action.messages.concat(
          state.messages.filter((m) => {
            return action.messages.map((_m) => _m.id).indexOf(m?.id) === -1;
          })
        ),
        offset: action.offset,
        hasMore: action.messages.length > 0,
        errors: null,
      };
    case CHAT_MESSAGE_MODIFIED:
      return {
        ...state,
        messages: modifyMessage(state.messages, action.message),
      };
    case CHAT_MESSAGES_LOADING:
      return {
        ...state,
        loadingMessages: action.loading,
        errors: null,
      };
    case CHAT_MESSAGES_ERRORS:
      return {
        ...state,
        loadingMessages: false,
        errorsMessages: action.errors,
      };
    case CHAT_SET:
      return {
        ...state,
        collection: action.collection,
        appointmentId: action.appointmentId,
        errors: null,
        from: action.from,
        to: action.to,
        token: action.token,
      };
    case CHAT_LOADING:
      return {
        ...state,
        loading: action.loading,
        errors: null,
      };
    case CHAT_ERRORS:
      return {
        ...state,
        loading: false,
        errors: action.errors,
      };
    case CHAT_DESTROY:
      return {
        ...state,
        errors: null,
        offset: null,
        from: null,
        to: null,
        token: null,
        collection: null,
        messages: [],
        appointmentId: null,
      };
    default:
      return state;
  }
};

export default chatReducer;

function modifyMessage(messages: Chat.Message[], message: Chat.Message) {
  const newCollection = messages.filter((value) => value.id !== message.id);
  newCollection.push(message);
  return newCollection;
}
