import {
  DOCTORS_DUTIES_LOADING,
  DOCTORS_DUTIES_ERROR,
  DOCTORS_DUTIES_SUCCESS,

  DOCTOR_SPECIALIZATIONS_LOADING,
  DOCTOR_SPECIALIZATIONS_ERROR,
  DOCTOR_SPECIALIZATIONS_SUCCESS,
  
  DOCTORS_LOADING,
  DOCTORS_ERROR,
  DOCTORS_SUCCESS,

  SET_DOCTORS_FILTER
} from '../actions';


const initialSettings = {
  doctorsDuties: [],
  doctorsDutiesLoading: false,
  doctorsDutiesLoadingError: false,

  doctorsFilter: {
    categoryId: null,
    page: 0,
    pageSize: 10,
    query: '',
    hasMore: true,
    sex: null,
    initialLoad: true,
    updateLoad: false,
    specializationId: null
  },

  doctorsList: [],
  doctorsLoading: false,
  doctorsLoadingError: false,

  doctorSpecializations: [],
  doctorSpecializationsLoading: false,
  doctorSpecializationsLoadingError: false
};

const doctorsReducer = (state = initialSettings, action) => {
  switch (action.type) {
    case DOCTORS_DUTIES_LOADING:
      return {
        ...state,
        doctorsDutiesLoading: action.isLoading
      };

    case DOCTORS_DUTIES_ERROR:
      return {
        ...state,
        doctorsDutiesLoadingError: action.isError
      };

    case DOCTORS_DUTIES_SUCCESS:
      return {
        ...state,
        doctorsDuties: [...action.duties]
      };

    case DOCTORS_LOADING:
      return {
        ...state,
        doctorsLoading: action.isLoading,
        doctorsFilter: {
          ...state.doctorsFilter,
          hasMore: false
        }
      };

    case DOCTORS_ERROR:
      return {
        ...state,
        doctorsLoadingError: action.isError,
        doctorsFilter: {
          categoryId: null,
          page: 0,
          pageSize: 10,
          hasMore: true,
          initialLoad: true,
          updateLoad: false,
          query: '',
          sex: null,
          specializationId: null
        }
      };

    case DOCTORS_SUCCESS:
      return {
        ...state,
        doctorsList: action.filter.page === 0 ? [
          ...action.doctors
        ] : [
          ...state.doctorsList,
          ...action.doctors
        ],
        doctorsFilter: {
          ...state.doctorsFilter,
          ...action.filter,
          page: action.filter.page + 1,
          hasMore: action.doctors.length < state.doctorsFilter.pageSize ? false : true,
          initialLoad: false,
          updateLoad: false
        }
      };

    case SET_DOCTORS_FILTER:
      return {
        ...state,
        doctorsFilter: {...action.filter}
      };

    case DOCTOR_SPECIALIZATIONS_LOADING:
      return {
        ...state,
        doctorSpecializationsLoading: action.isLoading
      };

    case DOCTOR_SPECIALIZATIONS_ERROR:
      return {
        ...state,
        doctorSpecializationsLoadingError: action.isError
      };

    case DOCTOR_SPECIALIZATIONS_SUCCESS:
      return {
        ...state,
        doctorSpecializations: [...action.specializations]
      };
      
    default:
      return state;
  }
};

export default doctorsReducer;
