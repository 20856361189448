import React, { useEffect, useState } from 'react';
import { Avatar, Box } from '@material-ui/core';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import InfiniteScroll from 'react-infinite-scroller';
import styles from './DailyConclusionStrategies.module.sass';
import Button from '../../../../components/Forms/Button';
import InputField from '../../../../components/Forms/InputField';
import {
  fetchManageDailyConclusionStrategies,
  fetchManageDailyConclusionStrategiesRules,
  setManageDailyConclusionStrategiesRulesLoading,
} from '../../../../store/actions';
import ContentCard from '../../../../components/ContentCard';
import ContentLoading from '../../../../components/ContentLoading';
import { RootState } from '../../../../store/reducers';
import AdminDailyConclusionView from '../../../../components/AdminDailyConclusionView';
import AdminDailyConclusionDelete from '../../../../components/AdminDailyConclusionDelete';
import { ReactComponent as AngleLeftIcon } from '../../../../assets/img/icons/angle-left-icon.svg';
import DailyConclusionStrategiesRule from '../DailyConclusionStrategiesRule';
import { decodeAvatarResource } from '../../../../utils';

const DailyConclusionStrategies = (props: any) => {
  const dispatch = useDispatch();
  const [search, onChangeSearch] = useState<string>(null);
  const [item, setItem] = useState<any>(null);
  const [rule, setRule] = useState<any>(null);
  const [ruleRemove, setRuleRemove] = useState<any>(null);
  const [itemRemove, setItemRemove] = useState<any>(null);
  const [itemView, setItemView] = useState<any>(null);
  const [timeoutSearch, setTimeoutSearch] = useState<NodeJS.Timeout>(null);
  const {
    loading,
    errors,
    hasMore,
    pageSize,
    strategies,
    page,
    loadingRules,
    hasMoreRules,
    pageSizeRules,
    rules,
    pageRules,
  } = useSelector((rootReducer: RootState) => rootReducer.manageDailyConclusionStrategies);
  useEffect(() => {
    setItem(null);
    if (timeoutSearch) {
      clearTimeout(timeoutSearch);
    }
    if (search && search.length) {
      setTimeoutSearch(
        setTimeout(() => {
          getItems(true);
        }, 500)
      );
    } else {
      getItems(true);
    }
    return () => {
      if (timeoutSearch) {
        clearTimeout(timeoutSearch);
      }
    };
  }, [search]);
  useEffect(() => {
    if (itemView) {
      getItemsRules(true);
    } else {
      dispatch(setManageDailyConclusionStrategiesRulesLoading(true, true));
    }
  }, [itemView]);
  const getItems = (reset?: boolean): void => {
    dispatch(
      fetchManageDailyConclusionStrategies({
        query: search,
        pageSize,
        page: reset ? 0 : page + 1,
        reset: reset === true,
      })
    );
  };

  const getItemsRules = (reset?: boolean): void => {
    dispatch(
      fetchManageDailyConclusionStrategiesRules({
        id: itemView.id,
        pageSize: pageSizeRules,
        page: reset ? 0 : pageRules + 1,
        reset: reset === true,
      })
    );
  };

  return (
    <>
      {!rule && (
        <ContentCard>
          <ContentCard.Header>
            {itemView && (
              <div className="row">
                <div className="col-xs-4 text-center text-xs-left mb-2 mb-xs-0">
                  <div className="page-header-back-link" onClick={() => setItemView(null)}>
                    <AngleLeftIcon className="page-header-back-link-icon" />
                    Назад
                  </div>
                </div>
                <div className="col-xs-8 col-md-4 text-center text-xs-right text-md-center">
                  <h6 className="page-header-title">{itemView.name}</h6>
                </div>
              </div>
            )}
            {!itemView && (
              <div className="row">
                <div className="col-12 text-center text-xs-right text-md-center">
                  <h6 className="page-header-title">Ежедневные заключения</h6>
                </div>
              </div>
            )}
          </ContentCard.Header>
          {!itemView && (
            <Box className={styles.DailyConclusionStrategies_Toolbar}>
              <Box className={styles.DailyConclusionStrategies_Toolbar_Search}>
                <SearchIcon className={styles.DailyConclusionStrategies_Toolbar_Search_Icon} />
                <InputField
                  value={search}
                  onChange={(e) => {
                    onChangeSearch(e.target.value);
                  }}
                  block
                  placeholder="Введите название заключения"
                />
                {search && (
                  <ClearIcon
                    onClick={() => {
                      onChangeSearch('');
                    }}
                    className={classNames(
                      styles.DailyConclusionStrategies_Toolbar_Search_Icon,
                      styles.DailyConclusionStrategies_Toolbar_Search_Icon_Clear
                    )}
                  />
                )}
              </Box>
              <Box className={styles.DailyConclusionStrategies_Toolbar_Buttons}>
                <Button color="primary" size="md" onClick={() => setItem({})}>
                  Добавить
                </Button>
              </Box>
            </Box>
          )}
          <ContentCard.Main className={styles.DailyConclusionStrategies_ContentCard}>
            <>
              <Box
                p={0}
                width="100%"
                height="100%"
                display="flex"
                flex="1 1 auto"
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="center"
              >
                {!itemView && (
                  <>
                    {strategies !== null && strategies.length === 0 && !search && !loading && (
                      <Box>У Вас пока нет стратегий ежедневных заключений. Нажмите кнопку "Добавить"</Box>
                    )}
                    {strategies !== null && strategies.length === 0 && search && !loading && (
                      <Box>По вашему запросу ничего не найдено</Box>
                    )}
                  </>
                )}
                {itemView && (
                  <>
                    {rules !== null && rules.length === 0 && !loading && (
                      <Box flex="1 1 auto" alignItems="center" justifyContent="center" display="flex">
                        <Box>У Вас пока нет созданных стратегий. Нажмите кнопку "Добавить"</Box>
                      </Box>
                    )}
                  </>
                )}
                {!itemView && (
                  <InfiniteScroll
                    className={styles.DailyConclusionStrategies_InfiniteScroll}
                    pageStart={0}
                    hasMore={loading ? false : hasMore}
                    initialLoad={false}
                    threshold={pageSize}
                    loadMore={() => getItems()}
                    loader={null}
                  >
                    <>
                      {strategies !== null &&
                        strategies.map((item: any, index: number) => (
                          <Box
                            key={index}
                            className={styles.DailyConclusionStrategies_Row}
                            onClick={() => {
                              setItemView(item);
                            }}
                          >
                            <Box className={styles.DailyConclusionStrategies_Row_Main}>
                              <Box className={styles.DailyConclusionStrategies_Row_Cell}>
                                <Box className={styles.DailyConclusionStrategies_Item}>
                                  <Box
                                    className={classNames(
                                      styles.DailyConclusionStrategies_Item_Label,
                                      styles.DailyConclusionStrategies_Item_Name
                                    )}
                                  >
                                    {item?.name}
                                  </Box>
                                  <Box
                                    className={classNames(
                                      styles.DailyConclusionStrategies_Item_Status,
                                      item.active === true
                                        ? styles.DailyConclusionStrategies_Item_Status_Active
                                        : styles.DailyConclusionStrategies_Item_Status_Archive
                                    )}
                                  >
                                    {item?.active === true ? 'Активный генератор' : 'Генератор в архиве'}
                                  </Box>
                                </Box>
                              </Box>
                              <Box className={styles.DailyConclusionStrategies_Row_Cell}>
                                <Box display="flex" flexDirection="column">
                                  <Button
                                    className={styles.DailyConclusionStrategies_Button}
                                    onClick={(event) => {
                                      event.preventDefault();
                                      event.stopPropagation();
                                      setItem(item);
                                    }}
                                    size="sm"
                                    color="default"
                                  >
                                    Редактировать
                                  </Button>
                                  <Button
                                    className={classNames(
                                      styles.DailyConclusionStrategies_Button,
                                      styles.DailyConclusionStrategies_Button_Remove
                                    )}
                                    onClick={(event) => {
                                      event.preventDefault();
                                      event.stopPropagation();
                                      setItemRemove(item);
                                    }}
                                    size="sm"
                                    color="default"
                                  >
                                    Удалить
                                  </Button>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        ))}
                      {(loading || errors) && (
                        <Box width="100%" p={2}>
                          <ContentLoading isLoading={loading} isError={errors} fetchData={() => getItems()} />
                        </Box>
                      )}
                    </>
                  </InfiniteScroll>
                )}
                {itemView && (
                  <InfiniteScroll
                    className={styles.DailyConclusionStrategies_InfiniteScroll}
                    pageStart={0}
                    hasMore={loadingRules ? false : hasMoreRules}
                    initialLoad={false}
                    threshold={pageSizeRules}
                    loadMore={() => getItemsRules()}
                    loader={null}
                  >
                    <>
                      {rules !== null &&
                        rules.map((item: any, index: number) => (
                          <Box
                            key={index}
                            className={styles.DailyConclusionStrategies_Row}
                            onClick={() => {
                              setRule(item);
                            }}
                          >
                            <Box className={styles.DailyConclusionStrategies_Row_Main}>
                              <Box className={styles.DailyConclusionStrategies_Row_Cell}>
                                <Box className={styles.DailyConclusionStrategies_Item}>
                                  <Box
                                    className={classNames(
                                      styles.DailyConclusionStrategies_Item_Label,
                                      styles.DailyConclusionStrategies_Item_Name
                                    )}
                                  >
                                    <Box component="span" fontWeight={500}>
                                      Порядковый номер дня:{' '}
                                    </Box>
                                    <Box
                                      component="span"
                                      className={classNames(
                                        styles.DailyConclusionStrategies_Item_Status,
                                        styles.DailyConclusionStrategies_Item_Status_Active
                                      )}
                                    >
                                      {item?.order}
                                    </Box>
                                  </Box>
                                  <Box
                                    className={classNames(
                                      styles.DailyConclusionStrategies_Item_Label,
                                      styles.DailyConclusionStrategies_Item_Name
                                    )}
                                  >
                                    <Box component="span" fontWeight={500}>
                                      Пол:{' '}
                                    </Box>
                                    <Box
                                      component="span"
                                      className={classNames(
                                        styles.DailyConclusionStrategies_Item_Status,
                                        styles.DailyConclusionStrategies_Item_Status_Active
                                      )}
                                    >
                                      {item?.sex === 'MALE' ? 'Муж.' : 'Жен.'}
                                    </Box>
                                  </Box>
                                  <Box
                                    className={classNames(
                                      styles.DailyConclusionStrategies_Item_Label,
                                      styles.DailyConclusionStrategies_Item_Name
                                    )}
                                  >
                                    <Box component="span" fontWeight={500}>
                                      Возрастная группа:{' '}
                                    </Box>
                                    <Box
                                      component="span"
                                      className={classNames(
                                        styles.DailyConclusionStrategies_Item_Status,
                                        styles.DailyConclusionStrategies_Item_Status_Active
                                      )}
                                    >
                                      {item?.ageGroup?.name}
                                    </Box>
                                  </Box>
                                  <Box
                                    className={classNames(
                                      styles.DailyConclusionStrategies_Item_Label,
                                      styles.DailyConclusionStrategies_Item_Name
                                    )}
                                  >
                                    <Box component="span" fontWeight={500} color="#B3BABD">
                                      Врач:{' '}
                                    </Box>
                                    {!item?.doctor?.id && (
                                      <Box
                                        component="span"
                                        className={classNames(
                                          styles.DailyConclusionStrategies_Item_Status,
                                          styles.DailyConclusionStrategies_Item_Status_Archive
                                        )}
                                      >
                                        Не выбрано
                                      </Box>
                                    )}
                                    {item?.doctor?.id && (
                                      <Box
                                        component="div"
                                        display="flex"
                                        alignItems="center"
                                        p={2}
                                        width="100%"
                                        borderRadius={5}
                                        bgcolor="#efeff4"
                                      >
                                        <Avatar
                                          className={styles.Avatar}
                                          src={decodeAvatarResource(item?.doctor?.avatar, 360, 'MALE')}
                                        />
                                        <Box display="flex" flexDirection="column">
                                          <Box>
                                            {[item?.doctor?.firstName, item?.doctor?.lastName]
                                              .filter((v) => v)
                                              .join(' ')}
                                          </Box>
                                          <Box
                                            className={classNames(
                                              styles.DailyConclusionStrategies_Item_Status,
                                              styles.DailyConclusionStrategies_Item_Status_Archive
                                            )}
                                          >
                                            {item?.doctor?.specialization?.name}
                                          </Box>
                                        </Box>
                                      </Box>
                                    )}
                                  </Box>
                                  <Box
                                    width="100%"
                                    className={classNames(
                                      styles.DailyConclusionStrategies_Item_Label,
                                      styles.DailyConclusionStrategies_Item_Name
                                    )}
                                  >
                                    <Box component="span" fontWeight={500} color="#B3BABD">
                                      Рекомендации:{' '}
                                    </Box>
                                    <Box component="div" p={2} width="100%" borderRadius={5} bgcolor="#efeff4">
                                      {item?.recommendations}
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                              <Box className={styles.DailyConclusionStrategies_Row_Cell}>
                                <Box display="flex" flexDirection="column">
                                  <Button
                                    className={classNames(
                                      styles.DailyConclusionStrategies_Button,
                                      styles.DailyConclusionStrategies_Button_Remove
                                    )}
                                    onClick={(event) => {
                                      event.preventDefault();
                                      event.stopPropagation();
                                      setRuleRemove(item);
                                    }}
                                    size="sm"
                                    color="default"
                                  >
                                    Удалить
                                  </Button>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        ))}
                      {(loading || errors) && (
                        <Box width="100%" p={2}>
                          <ContentLoading isLoading={loading} isError={errors} fetchData={() => getItems()} />
                        </Box>
                      )}
                    </>
                  </InfiniteScroll>
                )}
              </Box>
            </>
          </ContentCard.Main>
          {itemView && (
            <>
              <Box borderTop="1px solid #D8D8D8" p={2} display="flex" alignItems="center" justifyContent="center">
                <Button
                  size="md"
                  color="primary"
                  onClick={() => {
                    setRule({});
                  }}
                >
                  Создать новое правило
                </Button>
              </Box>
            </>
          )}
        </ContentCard>
      )}
      {rule && (
        <DailyConclusionStrategiesRule
          item={rule}
          id={itemView.id}
          onSave={() => {
            setRule(null);
            getItemsRules(true);
          }}
          onClose={() => setRule(null)}
        />
      )}
      {item !== null && (
        <AdminDailyConclusionView
          item={item}
          onSave={() => {
            setItem(null);
            getItems(true);
          }}
          onClose={() => setItem(null)}
        />
      )}
      {itemRemove !== null && (
        <AdminDailyConclusionDelete
          item={itemRemove}
          onDelete={() => {
            setItemRemove(null);
            getItems(true);
          }}
          onClose={() => setItemRemove(null)}
        />
      )}
      {ruleRemove !== null && (
        <AdminDailyConclusionDelete
          isDailyConclusion={false}
          item={ruleRemove}
          onDelete={() => {
            setRuleRemove(null);
            getItemsRules(true);
          }}
          onClose={() => setRuleRemove(null)}
        />
      )}
    </>
  );
};

export default DailyConclusionStrategies;
