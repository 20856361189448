import React, { PropsWithChildren } from 'react';
import { Backdrop, Box, Dialog } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import ContentCard from 'components/ContentCard';
import Button from 'components/Forms/Button';
import styles from './AdminStoriesConfirmDelete.module.sass';

interface PropsInterface extends PropsWithChildren<any> {
  title?: string;
  onConfirm?: (...args: any) => any;
  onClose?: (...args: any) => any;
}

const AdminStoriesConfirmDelete = (props: PropsInterface) => {
  const { onClose = () => {}, onConfirm = () => {}, title = null, children } = props;
  return (
    <Dialog
      PaperProps={{ className: classNames(styles.dialog) }}
      BackdropComponent={(props) => <Backdrop className={styles.backdrop} {...props} />}
      onClose={onClose}
      open
    >
      <ContentCard className={styles.root}>
        <ContentCard.Header className={styles.root_header}>
          <h6 className="page-header-title">Подтвердить удаление</h6>
          <CloseIcon onClick={onClose} className={styles.root_close} />
        </ContentCard.Header>
        <ContentCard.Main className={styles.root_content}>
          <Box className={styles.root_text}>{`Вы уверены, что хотите удалить пост "${title}"?`}</Box>
          <Box className={styles.root_actions}>
            <Button className="mr-2" color="default" block onClick={onClose}>
              Отмена
            </Button>
            <Button type="submit" color="primary" block onClick={onConfirm}>
              Удалить
            </Button>
          </Box>
        </ContentCard.Main>
      </ContentCard>
    </Dialog>
  );
};
export default AdminStoriesConfirmDelete;
