import React, { useCallback, useState } from 'react';
import { Backdrop, Box, Dialog } from '@material-ui/core';
import classNames from 'classnames';
import CloseIcon from '@material-ui/icons/Close';
import { toast } from 'react-toastify';
import ContentCard from '../ContentCard';
import Button from '../Forms/Button';
import styles from './UpdateDoctorStatus.module.sass';
import { updateDoctorStatus } from '../../api/updateDoctorStatus';

interface Props {
  status: boolean;
  disabled?: boolean;
  doctorId: string;
  onUpdateDoctorStatus: (status?: boolean) => void;
}

const UpdateDoctorStatus = (props: Props) => {
  const { status, disabled = false, doctorId, onUpdateDoctorStatus } = props;
  const [confirmModal, setConfirmModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const onChangeStatus = useCallback(() => {
    setLoading(true);
    updateDoctorStatus(doctorId, !status)
      .then((data) => {
        if (data.data.code === 'success') {
          setLoading(false);
          setConfirmModal(false);
          onUpdateDoctorStatus(!status);
        } else {
          setLoading(false);
          toast.error('Ошибка при запросе. Попробуйте еще раз');
        }
      })
      .catch(() => {
        setLoading(false);
        toast.error('Ошибка при запросе. Попробуйте еще раз');
      });
  }, [doctorId, status]);
  return (
    <>
      <Button
        isLoading={loading}
        disabled={disabled}
        className={styles.Button}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          setConfirmModal(!confirmModal);
        }}
        size="sm"
        color={status === true ? 'primary' : 'default'}
      >
        {status === true ? 'Забрать доступ' : 'Восстановить'}
      </Button>
      {confirmModal && (
        <Dialog
          PaperProps={{ className: classNames(styles.Dialog) }}
          BackdropComponent={(props) => <Backdrop className={styles.Backdrop} {...props} />}
          onClose={() => setConfirmModal(false)}
          open
        >
          <ContentCard className={styles.UpdateDoctorStatus}>
            <ContentCard.Header className={styles.UpdateDoctorStatus_Header}>
              <h6 className="page-header-title">{status === true ? 'Забрать доступ у' : 'Восстановить'} доктора</h6>
              <CloseIcon onClick={() => setConfirmModal(false)} className={styles.UpdateDoctorStatus_CloseIcon} />
            </ContentCard.Header>
            <ContentCard.Main className={styles.UpdateDoctorStatus_Content}>
              <Box component="p" width="100%" className="align-items-center justify-content-start d-flex p-3">
                Вы уверены что хотите {status === true ? 'забрать доступ у' : 'восстановить'} доктора?
              </Box>
              <Box width="100%" className="d-flex justify-content-between align-items-center p-3">
                <Button
                  type="button"
                  block
                  size="md"
                  className="mr-1"
                  onClick={() => setConfirmModal(false)}
                  color="default"
                >
                  Отмена
                </Button>
                <Button
                  type="button"
                  block
                  size="md"
                  className="ml-1"
                  disabled={loading}
                  isLoading={loading}
                  onClick={() => onChangeStatus()}
                  color="primary"
                >
                  {status === true ? 'Забрать доступ' : 'Восстановить'}
                </Button>
              </Box>
            </ContentCard.Main>
          </ContentCard>
        </Dialog>
      )}
    </>
  );
};

export default UpdateDoctorStatus;
