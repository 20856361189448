import React, { useState, useEffect, useCallback } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import ContentCard from 'components/ContentCard';

import { ReactComponent as AngleLeftIcon } from 'assets/img/icons/angle-left-icon.svg';
import { useHistory } from 'react-router-dom';
import { resetRedirect } from 'store/actions/redirect.actions';
import { Box } from '@material-ui/core';
import Button from '../../components/Forms/Button';

interface PropsInterface {
  isFail?: boolean;
}
const PaymentSuccessPage = (props: PropsInterface) => {
  const { isFail = false } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [countTimer, setCountTimer] = useState(7);
  const [url] = useState('/packages');
  const appointment = useSelector((state: RootStateOrAny) => state?.storage?.newAppointment?.doctor?.id || null);
  useEffect(() => {
    const timer =
      !isFail && appointment
        ? setTimeout(() => {
            history.push('/doctor/payment');
          }, 7000)
        : null;
    return () => {
      timer && clearTimeout(timer);
    };
  }, [appointment, history, isFail]);
  useEffect(() => {
    const timer =
      !isFail && appointment
        ? setInterval(() => {
            setCountTimer((t) => t - 1);
          }, 1000)
        : null;
    return () => {
      timer && clearInterval(timer);
    };
  }, [appointment, history, isFail]);
  const handleBack = useCallback(() => {
    dispatch(resetRedirect());
    history.push(appointment ? '/doctor/payment' : url);
  }, [appointment, dispatch, history, url]);
  const handleBackToPayment = useCallback(() => {
    history.push('/doctor/payment');
  }, [history]);
  return (
    <ContentCard>
      <ContentCard.Header>
        <div className="row">
          <div className="col-xs-4 text-center text-xs-left mb-2 mb-xs-0">
            <div className="page-header-back-link" onClick={handleBack}>
              <AngleLeftIcon className="page-header-back-link-icon" />
              Назад
            </div>
          </div>
          <div className="col-xs-8 col-md-4 text-center text-xs-right text-md-center">
            <h6 className="page-header-title">Оплата</h6>
          </div>
        </div>
      </ContentCard.Header>

      <ContentCard.Main className="text-center">
        <Box className="d-flex align-items-center flex-column">
          <h3>{!isFail ? 'Оплата прошла успешно' : 'Ошибка при оплате'}</h3>

          <img
            src={
              !isFail
                ? require('../../assets/img/success-check-img.png')
                : require('../../assets/img/fail-cross-img.png')
            }
            alt="Ошибка при оплате"
            className="mt-4 img-fluid"
          />
          {appointment && (
            <>
              <Button color="primary" className="mt-4 pl-5 pr-5" onClick={handleBackToPayment}>
                Вернуться к оплате консультации
              </Button>
              {!isFail && (
                <p className="mt-5">
                  Вы будете автоматически перенаправлены на оплату консультации через {countTimer} с.
                </p>
              )}
            </>
          )}
        </Box>
      </ContentCard.Main>
    </ContentCard>
  );
};

export default PaymentSuccessPage;
