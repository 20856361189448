import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ContentLoading from 'components/ContentLoading';
import { changeSetting, fetchPackagesMy, servicePackagesReset } from 'store/actions';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { patientPurchasedOfTariff } from 'api';
import { updateRedirect } from 'store/actions/redirect.actions';
import PackageItem from 'components/PackageItem/PackageItem';
import styles from '../ServicePackagesPage.module.sass';

const PackagesAll = (): JSX.Element => {
  const dispatch = useDispatch();
  const getPackagesMy = useCallback(() => dispatch(fetchPackagesMy(true)), [dispatch]);

  const reset = useCallback(() => dispatch(servicePackagesReset()), [dispatch]);

  const { packgesMyList, packgesMyLoading, packgesMyLoadingError } = useSelector((state: any) => ({
    packgesMyList: state.profile.packgesMyList,
    packgesMyLoading: state.profile.packgesMyLoading,
    packgesMyLoadingError: state.profile.packgesMyLoadingError,
  }));

  useEffect(() => {
    getPackagesMy();
  }, [getPackagesMy]);

  const handleBuy = useCallback(
    (id: string) => {
      dispatch(changeSetting('newAppointment', {}));
      patientPurchasedOfTariff(id)
        .then((data) => {
          if (data?.data?.data) {
            const win = window;
            dispatch(updateRedirect(window.location.pathname));
            win.open(data?.data?.data, '_self');
            win.focus();
          } else {
            toast.error('Ошибка при запросе');
          }
        })
        .catch(() => toast.error('Ошибка при запросе'));
    },
    [dispatch]
  );

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  return (
    <>
      <ContentLoading isLoading={packgesMyLoading} isError={packgesMyLoadingError} fetchData={() => getPackagesMy}>
        <div className={styles.packagesView}>
          <div className={classNames(styles.packagesList, styles.packagesListAll)}>
            {packgesMyList.map((packageItem) => (
              <PackageItem key={packageItem?.id} all item={packageItem} onBuy={handleBuy} />
            ))}
          </div>
        </div>
      </ContentLoading>
    </>
  );
};

export default PackagesAll;
