import {EnumDictionary} from "../../../../types/EnumDictionary";

class ReportItemUtils {

  static getLabelRole = (role: string): string => {
    switch (role) {
      case 'ADMIN':
        return 'Администратор';
      case 'PATIENT':
        return 'Пациент';
      case 'TECH':
        return 'Система';
      case 'AGENT':
        return 'Внешний';
      default:
        return role;
    }
  };

   static getLabelTransactionStatus = (status: string): string => {
     switch (status) {
       case 'NORMAL':
         return 'Обычная операция';
       case 'IGNORED':
         return 'Игнорируется';
       case 'IGNORED_AFTER_CANCELLATION':
         return 'Отменена';
       case 'IGNORED_WITH_PROMOTION':
         return 'Акция';
       default:
         return status;
     }
   };

   static getTransactionStatusOptions:Array<EnumDictionary<string>> = [
     {name: 'Обычная операция', value: 'NORMAL'},
     {name: 'Игнорируется', value: 'IGNORED'},
     {name: 'Отменена', value: 'IGNORED_AFTER_CANCELLATION'},
     {name: 'Акция', value: 'IGNORED_WITH_PROMOTION'}
   ]

   static transactionTypeOptions:Array<EnumDictionary<string>> = [
     {name: 'Начисление по тарифу', value: 'PACKAGE_CHARGE'},
     {name: 'Оплата от агента', value: 'CLIENT_AGENT_PAYMENT'},
     {name: 'Оплата через МЕДЕСТ', value: 'CLIENT_DIRECT_PAYMENT'},
     {name: 'Оплата с баланса пациента', value: 'CLIENT_BALANCE_PAYMENT'},
   ]
}

export default ReportItemUtils
