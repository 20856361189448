import { AxiosResponse } from 'axios';
import Manage from '../../types/manage';
import { getCategories } from '../../api/getCategories';

export const MANAGE_CATEGORIES_LOADING = 'MANAGE_CATEGORIES_LOADING';
export const MANAGE_CATEGORIES_SUCCESS = 'MANAGE_CATEGORIES_SUCCESS';
export const MANAGE_CATEGORIES_ERRORS = 'MANAGE_CATEGORIES_ERRORS';

export const setManageCategoriesLoading = (loading: boolean) => ({
  type: MANAGE_CATEGORIES_LOADING,
  loading,
});

export const setManageCategoriesErrors = (errors: any) => ({ type: MANAGE_CATEGORIES_ERRORS, errors });

export const setManageCategories = (categories: Manage.Category.Item[]) => ({
  type: MANAGE_CATEGORIES_SUCCESS,
  categories,
});

export const fetchManageCategories = (active?: boolean): any => {
  return (dispatch) => {
    dispatch(setManageCategoriesLoading(true));
    getCategories(null, active)
      .then((response: AxiosResponse<Manage.Category.Response>) => {
        dispatch(setManageCategoriesLoading(false));
        if (response.data.code === 'success') {
          dispatch(setManageCategories(response.data.data.items));
        } else {
          dispatch(setManageCategoriesErrors(response.data));
        }
      })
      .catch((error: any) => {
        dispatch(setManageCategoriesErrors(error));
        dispatch(setManageCategoriesLoading(false));
      });
  };
};
