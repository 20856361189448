import axios from 'utils/axios';

export const findDoctors = (query?: string, availability?: string, page?: number, pageSize?: number) => {
  query = query || null;
  page = page || 0;
  pageSize = pageSize || 50;
  availability = availability || null;
  return axios.get(`/api/v1/doctors`, {
    params: {
      query,
      availability,
      page,
      pageSize,
    },
  });
};
