import { AxiosResponse } from 'axios';
import Manage from '../../types/manage';
import { findPatientServicePackageReport } from '../../api/findPatientServicePackageReport';

export const MANAGE_REPORT_SERVICE_PACKAGES_LOADING = 'MANAGE_REPORT_SERVICE_PACKAGES_LOADING';
export const MANAGE_REPORT_SERVICE_PACKAGES_SUCCESS = 'MANAGE_REPORT_SERVICE_PACKAGES_SUCCESS';
export const MANAGE_REPORT_SERVICE_PACKAGES_ERRORS = 'MANAGE_REPORT_SERVICE_PACKAGES_ERRORS';

export const setManageReportServicePackagesPatientsLoading = (loading: boolean, reset?: boolean) => ({
  type: MANAGE_REPORT_SERVICE_PACKAGES_LOADING,
  loading,
  reset,
});

export const setManageReportServicePackagesPatientsErrors = (errors: any) => ({
  type: MANAGE_REPORT_SERVICE_PACKAGES_ERRORS,
  errors,
});

export const setManageReportServicePackagesPatients = (
  items: Manage.ServicePackages.Patient.Item[],
  page: number,
  pageSize: number,
  totalItems: number
) => ({
  type: MANAGE_REPORT_SERVICE_PACKAGES_SUCCESS,
  items,
  page,
  pageSize,
  totalItems,
});

export const fetchManageReportServicePackagesPatients = (
  query?: string,
  groupId?: string,
  statuses?: string[],
  purchasedFrom?: Date,
  purchasedTo?: Date,
  categoryBenefits?: string,
  signOnline?: any,
  page?: number,
  pageSize?: number,
  reset?: boolean
): any => {
  return (dispatch) => {
    dispatch(setManageReportServicePackagesPatientsLoading(true, reset));
    findPatientServicePackageReport(
      query,
      groupId,
      statuses,
      purchasedFrom,
      purchasedTo,
      categoryBenefits,
      signOnline,
      page,
      pageSize
    )
      .then((response: AxiosResponse<any>) => {
        dispatch(setManageReportServicePackagesPatientsLoading(false));
        if (response.data.code === 'success') {
          dispatch(
            setManageReportServicePackagesPatients(
              response.data.data.items,
              response.data.data.page,
              response.data.data.pageSize,
              response.data.data.totalItems
            )
          );
        } else {
          dispatch(setManageReportServicePackagesPatientsErrors(response.data));
        }
      })
      .catch((error: any) => {
        dispatch(setManageReportServicePackagesPatientsErrors(error));
        dispatch(setManageReportServicePackagesPatientsLoading(false));
      });
  };
};
