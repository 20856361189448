import axios from 'utils/axios';

export const getDoctorFutureSchedule = (doctorId: string, timeFrom?: Date, timeTo?: Date, busy?: boolean) => {
  busy = busy !== false;
  return axios.get(`/api/v1/doctors/${doctorId}/fullschedule`, {
    params: {
      busy,
      timeFrom,
      timeTo,
    },
  });
};
